import React, { createContext, useContext, useState } from "react";

// Create the context
const FormContext = createContext();

// Create a provider component
export const FormProvider = ({ children, initialValues }) => {
  const [formData, setFormData] = useState({
    keyword: Array.isArray(initialValues?.keyword)
      ? initialValues.keyword
      : initialValues?.keyword
      ? [initialValues.keyword]
      : [],
    minimumEducation: "",
    activeIn: "",
    minExperience: initialValues?.minExperience || "",
    maxExperience: initialValues?.maxExperience || "",
    department: Array.isArray(initialValues?.department)
      ? initialValues.department
      : [],
    minSalary: initialValues?.minSalary || "",
    maxSalary: initialValues?.maxSalary || "",
    skills: Array.isArray(initialValues?.skills) ? initialValues.skills : [],
    preferredLocation: Array.isArray(initialValues?.preferredLocation)
      ? initialValues.preferredLocation
      : [],
    currentLocation: Array.isArray(initialValues?.currentLocation)
      ? initialValues.currentLocation
      : [],
    industry: Array.isArray(initialValues?.industry)
      ? initialValues.industry
      : [],
    noticePeriod: Array.isArray(initialValues?.noticePeriod)
      ? initialValues.noticePeriod
      : [],
    booleanSearch: initialValues?.booleanSearch || false,
    minAge: initialValues?.minAge || "",
    maxAge: initialValues?.maxAge || "",
    preferredJobType: initialValues?.preferredJobType || "",
    experienceType: initialValues?.experienceType || "",
    gender: initialValues?.gender || "",
    shouldntHave: Array.isArray(initialValues?.shouldntHave)
      ? initialValues.shouldntHave
      : [],
    ugDegree: Array.isArray(initialValues?.ugDegree)
      ? initialValues.ugDegree
      : [],
    pgDegree: Array.isArray(initialValues?.pgDegree)
      ? initialValues.pgDegree
      : [],
    ugStartYear: initialValues?.ugStartYear || "",
    ugEndYear: initialValues?.ugEndYear || "",
    pgStartYear: initialValues?.pgStartYear || "",
    pgEndYear: initialValues?.pgEndYear || "",
    ugInstitute: initialValues?.ugInstitute || "",
    pgInstitute: initialValues?.pgInstitute || "",
  });

  return (
    <FormContext.Provider value={{ formData, setFormData }}>
      {children}
    </FormContext.Provider>
  );
};

// Custom hook for using the context
export const useFormContext = () => {
  return useContext(FormContext);
};
