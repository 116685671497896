import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import "./utils/firebaseConfig";
import { SearchProviderResults } from "./context/SearchResults"; // Import the provider
import { ScrollProvider } from "./context/ScrollContext";
// import { SearchProvider } from "./context/SearchContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { OpenStateProvider } from "./context/OpenStateContext";
import { ModalProvider } from "./context/ModelContext";
import { OrgProvider } from "./context/OrgContext";
import { FormProvider } from "./context/FormContext";
import { UserProvider } from "./context/UserContext";
import { ChatProvider } from "./context/ChatContext";


// Create the Material-UI theme
const theme = createTheme({
  palette: {
    text: {
      primary: "#000000",
    },
  },
});

// Create the root element
const root = ReactDOM.createRoot(document.getElementById("root"));
const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

// Render the app
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={googleClientId}>
      <ScrollProvider>
        <ModalProvider>
          <OpenStateProvider>
            <SearchProviderResults>
              <FormProvider>
                <UserProvider>
                  <ChatProvider>
                  <OrgProvider>
                    <ThemeProvider theme={theme}>
                      <CssBaseline />
                      <App />
                    </ThemeProvider>
                  </OrgProvider>
                  </ChatProvider>
                 
                </UserProvider>
              </FormProvider>
            </SearchProviderResults>
          </OpenStateProvider>
        </ModalProvider>
      </ScrollProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);

reportWebVitals();
