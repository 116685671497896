// RouteComponent.jsx
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoginPage from "../pages/JobSeekerPages/SignInSignUp/SignInSignUp";
import EmployerSignIn from "../pages/EmployerPages/EmployerLogin/EmployerSignIn";
import HomePage from "../pages/Home/Home";
import JobSeekerRegestrationProfile from "../pages/JobSeekerPages/JobseekerRegestrationProfile/jobSeekerRegestrationProfile";
import JobSeekerFilter from "../pages/JobSeekerPages/JobSeekerFilters/JobSeekerFilters";
import JobSeekerJobDetails from "../pages/JobSeekerPages/JobSeekerJobDetails/JobSeekerJobDetails"; // Import correct component
import EmployersRegistrationProfile from "../pages/EmployerPages/EmployersRegistrationProfile/EmployersRegistrationProfile";
import JobSeekerPersonalProfile from "../pages/JobSeekerPages/JobSeekerPersonalProfiles/JobSeekerPersonalProfile";

// import UnlockCandidates from "../pages/EmployerPages/EmployerDatabases/unlockCandidates";

import JobSeekerAppliedJobs from "../pages/JobSeekerPages/JobSeekerAppliedJobs/JobSeekerAppliedJobs";
import JobSeekerSavedJobs from "../pages/JobSeekerPages/JobSeekerSavedJobs/JobSeekerSavedJobs";
import EmployerRegistration from "../pages/EmployerPages/EmployerRegistration/EmployerRegistration";
import JobAppliedDetails from "../pages/JobSeekerPages/JobSeekerAppliedJobs/JobAppliedDetails";
import JobSavedDetails from "../pages/JobSeekerPages/JobSeekerSavedJobs/JobSavedDetails";

import ManageQuota from "../pages/EmployerPages/ManageQuota/ManageQuota";

import RegisterForm from "../pages/JobSeekerPages/SignInSignUp/RegisterForm";

import EmployerResetPassword from "../components/EmployerComponents/EmployerLoginSignup/EmployerResetPassword";
// import JobSeekeerSubscription from "../pages/JobSeekerPages/JobSeekerSubscription/JobSeekerSubscription";
import JobSeekerInvoice from "../pages/JobSeekerPages/JobSeekerInvoice/JobSeekerInvoice";
import PaymentCallback from "../pages/Payment/PaymentCallback";
import PaymentFailure from "../pages/Payment/PaymentFailure";
import PaymentSuccess from "../pages/Payment/PaymentSuccess";
import JobSeekerPaymentVerification from "../components/JobSeekerSubscription/JobSeekerPaymentVerification";
import JobSeekerPaymentSuccessful from "../components/JobSeekerSubscription/JobSeekerPaymentSuccessful";
import JobSeekerPaymentFailed from "../components/JobSeekerSubscription/JobSeekerPaymentFailed";

import JobSeekerSupport from "../pages/JobSeekerPages/JobSeekerSupport/JobSeekerSupport";
import ProtectedJobSeekerRoute from "../utils/protectedJobSeekerRoute";
import ProtectedEmployerRoute from "../utils/protectedEmployerRoute";
import JobSeekerTopIndustries from "../components/JobSeekerComponents/JobSeekerIndustries/JobSeekerTopIndustries";
import TopHiringCompanies from "../pages/JobSeekerPages/TopHiringCompanies/TopHiringCompanies";
import JobSeekerProfileDetail from "../pages/JobSeekerPages/JobSeekerProfile/JobSeekerProfileDetails";
import OrgOverView from "../pages/JobSeekerPages/OrganizationOverView/OrganizationOverView";

import EmployerInvoice from "../pages/EmployerPages/SubscriptionStatus/EmployerInvoice";

import JobSeekerFAQ from "../components/JobSeekerComponents/JobSeekerContentManageMent/jobSeekerFAQ";
import JobSeekerPaymentPolicy from "../components/JobSeekerComponents/JobSeekerContentManageMent/JobSeeekerPaymentPolicy";
import JobSeekerPrivacyPolicy from "../components/JobSeekerComponents/JobSeekerContentManageMent/JobSeeekerPrivacyPolicy";
import JobSeekerTermsAndCons from "../components/JobSeekerComponents/JobSeekerContentManageMent/JobSeeekerTermsAndCons";
import EmployerFAQ from "../components/EmployerComponents/EmployerContentManageMent/EmployerFAQ";

import JobSeekerNetwork from "../pages/JobSeekerPages/JobSeekerNetworks/JobSeekerNetwork";
import Invites from "../pages/JobSeekerPages/JobSeekerInvites/Invites";
import JobseekerWallet from "../pages/JobseekerWallet/JobseekerWallet";
import JobSeekerCarerrs from "../components/JobSeekerComponents/JobSeekerContentManageMent/Carerrs/JobSeekerCarerrs";
import FraudAlertPage from "../pages/Common/FraudAlertPage";
import TrustAndSafetyPage from "../pages/Common/TrustAndSafetyPage";
import AboutUs from "../pages/JobSeekerPages/AboutUs/AboutUs";
import Connect from "../pages/Common/Connect/Connect";
import ConnectCounsellorProfile from "../pages/Common/Connect/ConnectCounsellorProfile";
import JobSeekerDelete from "../pages/JobSeekerPages/JobSeekerDelete/JobSeekerDelete";
import PublicRoute from "../utils/publicRoute";
import EmployerLayout from "../layouts/EmployerLayout";
import EmployerRoute from "./EmployerRoute";
import CourseHome from "../pages/Common/Courses/CourseHome";
import MarketHome from "../pages/Common/Marketing/MarketHome";
import Notifications from "../components/AdvancedComponents/Notifications";
import JobSeekerBlogs from "../components/JobSeekerComponents/JobSeekerContentManageMent/Blogs/JobSeekerBlog";
import JobSeekerArticles from "../components/JobSeekerComponents/JobSeekerContentManageMent/Blogs/JobSeekerArticles";
import JobSeekerBlogDetails from "../components/JobSeekerComponents/JobSeekerContentManageMent/Blogs/JobSeekerBlogDetails";
import JobSeekerArticleDetails from "../components/JobSeekerComponents/JobSeekerContentManageMent/Blogs/JobSeekerArticleDetails";

const RouteComponent = () => (
  <Router>
    <Routes>
      <Route path="/" element={<HomePage />} />
      {/* Employer Routes */}
      <Route path="/employerSignIn" element={<EmployerSignIn />} />
      <Route
        path="/employersRegistrationProfile"
        element={<EmployersRegistrationProfile />}
      />
      <Route
        path="/employersResetPassword"
        element={
          <ProtectedEmployerRoute>
            <EmployerResetPassword />
          </ProtectedEmployerRoute>
        }
      />
      <Route
        path="/employerRegistration"
        element={
          <ProtectedEmployerRoute>
            <EmployerRegistration />
          </ProtectedEmployerRoute>
        }
      />
      <Route
        path="/employer/manageQuota"
        element={
          <ProtectedEmployerRoute>
            <ManageQuota />
          </ProtectedEmployerRoute>
        }
      />
      <Route
        path="/jobSeeker/invoices"
        element={
          <ProtectedEmployerRoute>
            <JobSeekerInvoice />
          </ProtectedEmployerRoute>
        }
      />
      <Route
        path="/subscriptionInvoice/:invoiceId"
        element={
          <ProtectedEmployerRoute>
            <EmployerInvoice />
          </ProtectedEmployerRoute>
        }
      />
      <Route
        path="/paymentVerify"
        element={
          <ProtectedEmployerRoute>
            <PaymentCallback />
          </ProtectedEmployerRoute>
        }
      />
      <Route
        path="/paymentFailure"
        element={
          <ProtectedEmployerRoute>
            <PaymentFailure />
          </ProtectedEmployerRoute>
        }
      />
      <Route
        path="/paymentSuccess"
        element={
          <ProtectedEmployerRoute>
            <PaymentSuccess />
          </ProtectedEmployerRoute>
        }
      />
      <Route path="/employer-faq" element={<EmployerFAQ />} />
      <Route element={<EmployerLayout />}>
        <Route path="/*" element={<EmployerRoute />} />
      </Route>
      {/* JobSeeker Routes */}
      <Route
        path="/signinsignup"
        element={
          <PublicRoute>
            <LoginPage />
          </PublicRoute>
        }
      />
      <Route path="/registerFromfill" element={<RegisterForm />} />
      <Route
        path="/jobSeekerRegestrationProfile"
        element={<JobSeekerRegestrationProfile />}
      />
      <Route path="/JobSeekerFilter" element={<JobSeekerFilter />} />
      <Route
        path="/JobSeekerFilter/job/:id"
        element={<JobSeekerJobDetails />}
      />
      <Route path="/jobSeeker/support" element={<JobSeekerSupport />} />
      <Route
        path="/jobSeeker/deleteAccount"
        element={
          <ProtectedJobSeekerRoute>
            <JobSeekerDelete />
          </ProtectedJobSeekerRoute>
        }
      />
      <Route
        path="/jobSeekerWallet"
        element={
          <ProtectedJobSeekerRoute>
            {" "}
            <JobseekerWallet />
          </ProtectedJobSeekerRoute>
        }
      />
      <Route
        path="/invites"
        element={
          <ProtectedJobSeekerRoute>
            {" "}
            <Invites />
          </ProtectedJobSeekerRoute>
        }
      />
      <Route
        path="/jobSeeker/network"
        element={
          <ProtectedJobSeekerRoute>
            {" "}
            <JobSeekerNetwork />
          </ProtectedJobSeekerRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <ProtectedJobSeekerRoute>
            <JobSeekerPersonalProfile />
          </ProtectedJobSeekerRoute>
        }
      />
      <Route
        path="/jobSeeker-Profile"
        element={
          <ProtectedJobSeekerRoute>
            <JobSeekerProfileDetail />{" "}
          </ProtectedJobSeekerRoute>
        }
      />
      <Route path="/findJobs" element={<JobSeekerFilter />} />
      <Route
        path="/jobSeekerAppliedJobs"
        element={
          <ProtectedJobSeekerRoute>
            <JobSeekerAppliedJobs />
          </ProtectedJobSeekerRoute>
        }
      />
      <Route
        path="/jobSeekerSavedJobs"
        element={
          <ProtectedJobSeekerRoute>
            <JobSeekerSavedJobs />
          </ProtectedJobSeekerRoute>
        }
      />
      <Route
        path="/jobsApplied/:id"
        element={
          <ProtectedJobSeekerRoute>
            <JobAppliedDetails />
          </ProtectedJobSeekerRoute>
        }
      />
      <Route
        path="/jobsSaved/:id"
        element={
          <ProtectedJobSeekerRoute>
            <JobSavedDetails />
          </ProtectedJobSeekerRoute>
        }
      />
      <Route
        path="/jobSeekerPaymentVerify"
        element={
          <ProtectedJobSeekerRoute>
            <JobSeekerPaymentVerification />
          </ProtectedJobSeekerRoute>
        }
      />
      <Route
        path="/jobseekerpayment-successful"
        element={
          <ProtectedJobSeekerRoute>
            <JobSeekerPaymentSuccessful />
          </ProtectedJobSeekerRoute>
        }
      />
      <Route
        path="/jobseekerpayment-failed"
        element={
          <ProtectedJobSeekerRoute>
            <JobSeekerPaymentFailed />
          </ProtectedJobSeekerRoute>
        }
      />
      {/* <Route
        path="/jobSeekeerSubscription"
        element={
          <ProtectedJobSeekerRoute>
            <JobSeekeerSubscription />
          </ProtectedJobSeekerRoute>
        }
      /> */}
      <Route path="/top-hiring-companies" element={<TopHiringCompanies />} />
      <Route
        path="/organizations-by-industry"
        element={<TopHiringCompanies />}
      />
      <Route
        path="/top-industries-companies"
        element={<JobSeekerTopIndustries />}
      />
      <Route
        path="/organizationOverview"
        element={
          <ProtectedJobSeekerRoute>
            <OrgOverView />{" "}
          </ProtectedJobSeekerRoute>
        }
      />
      <Route
        path="/notifications"
        element={
          <ProtectedJobSeekerRoute>
            <Notifications />{" "}
          </ProtectedJobSeekerRoute>
        }
      />
      <Route path="/connect" element={<Connect />} />
      <Route
        path="/counsellorProfile/:counsellorId"
        element={<ConnectCounsellorProfile />}
      />
      <Route path="/job-seeker-faq" element={<JobSeekerFAQ />} />
      <Route path="/payment-policy" element={<JobSeekerPaymentPolicy />} />
      <Route path="/privacy-policy" element={<JobSeekerPrivacyPolicy />} />
      <Route path="/terms-&-cons" element={<JobSeekerTermsAndCons />} />
      <Route path="/careers" element={<JobSeekerCarerrs />} />
      <Route path="/blogs" element={<JobSeekerBlogs />} />
      <Route path="/blog/:blogId" element={<JobSeekerBlogDetails/>}/>
      <Route path="/articles" element={<JobSeekerArticles />} />
      <Route path="/article/:articleId" element={<JobSeekerArticleDetails/>}/>

      <Route path="/fraudAlerts" element={<FraudAlertPage />} />
      <Route path="/trustAndSafety" element={<TrustAndSafetyPage />} />
      <Route path="/skillup" element={<CourseHome />} />\
      <Route
        path="/marketing"
        element={
        
            <MarketHome />
        
        }
      />
      <Route path="/about-vacanzi" element={<AboutUs />} />
    </Routes>
  </Router>
);

export default RouteComponent;
