import baseURL from "./baseUrl";

export const createJobPost = async (orgId, token, formValues) => {
  try {
    const response = await fetch(`${baseURL}/jobpost/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(formValues),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const postJobApi = async (token, formValues) => {
  try {
    const formData = new FormData();
    const sanitizedFormValues = { ...formValues };

    // Handle interviewLocation
    if (
      !formValues.interviewLocation?.address?.addressLine1 &&
      !formValues.interviewLocation?.address?.addressLine2 &&
      !formValues.interviewLocation?.address?.landmark &&
      !formValues.interviewLocation?.address?.city &&
      !formValues.interviewLocation?.address?.postalCode
    ) {
      sanitizedFormValues.interviewLocation = null;
    } else {
      sanitizedFormValues.interviewLocation = JSON.stringify(
        sanitizedFormValues.interviewLocation
      );
    }

    // Rename jobLocation to jobLocations and ensure it's an array
    if (
      sanitizedFormValues.jobLocation &&
      sanitizedFormValues.jobLocation.length > 0
    ) {
      sanitizedFormValues.jobLocations = sanitizedFormValues.jobLocation;
    } else {
      sanitizedFormValues.jobLocations = [];
    }
    delete sanitizedFormValues.jobLocation; // Remove the original jobLocation field

    // Ensure valid date or remove
    if (!formValues.interviewStartDate)
      delete sanitizedFormValues.interviewStartDate;
    if (!formValues.interviewEndDate)
      delete sanitizedFormValues.interviewEndDate;

    // Append non-file fields
    Object.keys(sanitizedFormValues).forEach((key) => {
      if (key !== "jobVideo" && key !== "jobLocations") {
        if (
          sanitizedFormValues[key] !== null &&
          sanitizedFormValues[key] !== undefined
        ) {
          formData.append(key, sanitizedFormValues[key]);
        }
      }
    });

    // Append jobLocations as a JSON string since FormData can't handle nested objects directly
    if (sanitizedFormValues.jobLocations.length > 0) {
      formData.append(
        "jobLocations",
        JSON.stringify(sanitizedFormValues.jobLocations)
      );
    }

    // Append file separately
    if (sanitizedFormValues.jobVideo) {
      formData.append("jobVideo", sanitizedFormValues.jobVideo);
    }

    // Debug FormData
    for (let pair of formData.entries()) {
      console.log(`${pair[0]}: ${pair[1]}`);
    }

    const response = await fetch(`${baseURL}/jobpost/create`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || "Network response was not ok");
    }

    return { status: response.status, data };
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const saveJobAsDraftApi = async (token, formValues) => {
  try {
    const formData = new FormData();
    const sanitizedFormValues = { ...formValues };

    // Handle interviewLocation
    if (
      !formValues.interviewLocation?.address?.addressLine1 &&
      !formValues.interviewLocation?.address?.addressLine2 &&
      !formValues.interviewLocation?.address?.landmark &&
      !formValues.interviewLocation?.address?.city &&
      !formValues.interviewLocation?.address?.postalCode
    ) {
      sanitizedFormValues.interviewLocation = null;
    } else {
      sanitizedFormValues.interviewLocation = JSON.stringify(
        sanitizedFormValues.interviewLocation
      );
    }

    // Rename jobLocation to jobLocations and ensure it's an array
    if (
      sanitizedFormValues.jobLocation &&
      sanitizedFormValues.jobLocation.length > 0
    ) {
      sanitizedFormValues.jobLocations = sanitizedFormValues.jobLocation;
    } else {
      sanitizedFormValues.jobLocations = [];
    }
    delete sanitizedFormValues.jobLocation; // Remove the original jobLocation field

    // Ensure valid date or remove
    if (!formValues.interviewStartDate)
      delete sanitizedFormValues.interviewStartDate;
    if (!formValues.interviewEndDate)
      delete sanitizedFormValues.interviewEndDate;

    // Append non-file fields
    Object.keys(sanitizedFormValues).forEach((key) => {
      if (key !== "jobVideo" && key !== "jobLocations" && key !== "interviewLocation") {
        const value = sanitizedFormValues[key];
        if (value !== null && value !== undefined) {
          if (typeof value === 'object' && !(value instanceof File)) {
            formData.append(key,value); // This preserves nested arrays
          } else {
            formData.append(key, value);
          }
        }
      }
    });

    if (sanitizedFormValues.interviewLocation && sanitizedFormValues.interviewLocation.address) {
      const { address } = sanitizedFormValues.interviewLocation;
      if (address.addressLine1) formData.append("interviewLocation[address][addressLine1]", address.addressLine1);
      if (address.addressLine2) formData.append("interviewLocation[address][addressLine2]", address.addressLine2);
      if (address.landmark) formData.append("interviewLocation[address][landmark]", address.landmark);
      if (address.city) formData.append("interviewLocation[address][city]", address.city);
      if (address.postalCode) formData.append("interviewLocation[address][postalCode]", address.postalCode);
      if (address.state) formData.append("interviewLocation[address][state]", address.state);
    }

    // Append jobLocations as a JSON string since FormData can't handle nested objects directly
    if (sanitizedFormValues.jobLocations.length > 0) {
      formData.append(
        "jobLocations",
        JSON.stringify(sanitizedFormValues.jobLocations)
      );
    }

    // Append file separately
    if (sanitizedFormValues.jobVideo) {
      formData.append("jobVideo", sanitizedFormValues.jobVideo);
    }

    // Debug FormData
    for (let pair of formData.entries()) {
      console.log(`${pair[0]}: ${pair[1]}`);
    }

    const response = await fetch(`${baseURL}/jobpost/saveDraft`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || "Network response was not ok");
    }

    return {
      status: response.status,
      data: data,
    };
  } catch (error) {
    console.error("Error:", error);

    throw error;
  }
};

export const updateJobPostApi = async (jobId, token, formValues) => {
  try {
    const formData = new FormData();
    const sanitizedFormValues = { ...formValues };

    // Rename jobLocation to jobLocations and ensure it's an array
    if (sanitizedFormValues.jobLocation && sanitizedFormValues.jobLocation.length > 0) {
      sanitizedFormValues.jobLocations = sanitizedFormValues.jobLocation;
    } else {
      sanitizedFormValues.jobLocations = [];
    }
    delete sanitizedFormValues.jobLocation;

    // Check if interviewLocation is empty
    if (
      !sanitizedFormValues.interviewLocation?.address?.addressLine1 &&
      !sanitizedFormValues.interviewLocation?.address?.addressLine2 &&
      !sanitizedFormValues.interviewLocation?.address?.landmark &&
      !sanitizedFormValues.interviewLocation?.address?.city &&
      !sanitizedFormValues.interviewLocation?.address?.postalCode &&
      !sanitizedFormValues.interviewLocation?.address?.state
    ) {
      sanitizedFormValues.interviewLocation = null;
    }

    // Ensure valid date or remove
    if (!formValues.interviewStartDate)
      delete sanitizedFormValues.interviewStartDate;
    if (!formValues.interviewEndDate)
      delete sanitizedFormValues.interviewEndDate;

    // Keep original handling for all fields except jobVideo, jobLocations, and interviewLocation
    Object.keys(sanitizedFormValues).forEach((key) => {
      if (key !== "jobVideo" && key !== "jobLocations" && key !== "interviewLocation") {
        const value = sanitizedFormValues[key];
        if (value !== null && value !== undefined) {
          if (typeof value === 'object' && !(value instanceof File)) {
            formData.append(key,value); // This preserves nested arrays
          } else {
            formData.append(key, value);
          }
        }
      }
    });

    // Handle interviewLocation with bracket notation
    if (sanitizedFormValues.interviewLocation && sanitizedFormValues.interviewLocation.address) {
      const { address } = sanitizedFormValues.interviewLocation;
      if (address.addressLine1) formData.append("interviewLocation[address][addressLine1]", address.addressLine1);
      if (address.addressLine2) formData.append("interviewLocation[address][addressLine2]", address.addressLine2);
      if (address.landmark) formData.append("interviewLocation[address][landmark]", address.landmark);
      if (address.city) formData.append("interviewLocation[address][city]", address.city);
      if (address.postalCode) formData.append("interviewLocation[address][postalCode]", address.postalCode);
      if (address.state) formData.append("interviewLocation[address][state]", address.state);
    }

    // Append jobLocations as JSON (preserving array structure)
    if (sanitizedFormValues.jobLocations.length > 0) {
      formData.append("jobLocations", JSON.stringify(sanitizedFormValues.jobLocations));
    }

    // Append file
    if (sanitizedFormValues.jobVideo) {
      formData.append("jobVideo", sanitizedFormValues.jobVideo);
    }

    const response = await fetch(`${baseURL}/jobpost/${jobId}/update`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || "Network response was not ok");
    }

    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
export const getSecretKey = async (token, recruiterId) => {
  try {
    const response = await fetch(
      `${baseURL}/recruiter/createSecret/${recruiterId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const getJobByIdApi = async (jobId, token) => {
  try {
    const response = await fetch(`${baseURL}/jobpost/rc/${jobId}`, {
      method: "GET",
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error("network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export const shortListApi = async (jobId, token, formValues) => {
  try {
    const response = await fetch(`${baseURL}/shortlist/${jobId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(formValues),
    });
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message || "Failed to shortlist the candidate");
    }

    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const getShortListedUsersApi = async (jobId, token) => {
  try {
    const response = await fetch(`${baseURL}/shortlistApplicants/${jobId}`, {
      method: "GET",
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error("network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};
export const getAppliedUsersApi = async (
  jobId,
  token,
  experienceType,
  skills,
  totalExpInYearsMin,
  totalExpInYearsMax,
  currentLocation,
  preferredLocation,
  gender,
  expectedCtcMin,
  expectedCtcMax
) => {
  try {
    const url = `${baseURL}/applicants/${jobId}?experienceType=${experienceType}&skills=${skills}&totalExpInYearsMin=${totalExpInYearsMin}&totalExpInYearsMax=${totalExpInYearsMax}&currentLocation=${currentLocation}&preferredLocation=${preferredLocation}&gender=${gender}&expectedCtcMin=${expectedCtcMin}&expectedCtcMax=${expectedCtcMax}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching applied users:", error.message);
    throw error;
  }
};

export const deleteJobApi = async (token, jobId) => {
  try {
    const response = await fetch(`${baseURL}/jobpost/${jobId}/delete`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message || "Failed delete ");
    }
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const changeJobStatusApi = async (jobId, token, formValues) => {
  try {
    const response = await fetch(`${baseURL}/jobpost/status/${jobId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(formValues),
    });
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message || "network response was not ok");
    }

    return data;
  } catch (error) {
    console.log(error.message);
    throw error;
  }
};

export const generateSecretApi = async (token) => {
  try {
    const response = await fetch(`${baseURL}/jobpost/generateKey`, {
      method: "POST",
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message || "network response was not ok");
    }

    return data;
  } catch (error) {
    console.log(error.message);
    throw error;
  }
};
