import React from "react";
import { Snackbar, SnackbarContent, IconButton } from "@mui/material";
import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  ErrorOutline as ErrorOutlineIcon,
  Close as CloseIcon,
} from "@mui/icons-material";

const CustomSnackbar = ({ open, message, type, handleClose }) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={1000} // Auto-close after 3 seconds
      onClose={handleClose} // Ensure it closes on timeout
      anchorOrigin={{ vertical: "top", horizontal: "right" }} // Position: Top-Right
    >
      <SnackbarContent
        sx={{
          backgroundColor: "#000", // Black background
          color: "white",
          borderRadius: 1,
          p: 1,
        }}
        message={
          <span style={{ display: "flex", alignItems: "center" }}>
            {type === "success" ? (
              <CheckCircleOutlineIcon
                sx={{ marginRight: 1, color: "darkgreen" }}
              />
            ) : (
              <ErrorOutlineIcon sx={{ marginRight: 1, color: "red" }} />
            )}
            <p style={{ fontWeight: "bold", color: "white", margin: 0 }}>
              {message}
            </p>
          </span>
        }
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            onClick={handleClose} // Close when clicking on X button
          >
            <CloseIcon />
          </IconButton>
        }
      />
    </Snackbar>
  );
};

export default CustomSnackbar;
