import React, { createContext, useState, useContext } from "react";

const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedChatId, setSelectedChatId] = useState(null); // Add selectedChatId
    const [selectedCounsellor, setSelectedCounsellor] = useState(null);

  return (
    <ChatContext.Provider value={{ isOpen, setIsOpen, selectedChatId, setSelectedChatId ,selectedCounsellor,setSelectedCounsellor}}>
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error("useChat must be used within a ChatProvider");
  }
  return context;
};
