import React, { useEffect, useState, useMemo } from "react";
import {
  Autocomplete,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Chip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getTopCitiesApi } from "../../../../api/common";
import { debounce } from "@mui/material/utils";

const CandidatePrefferedCity = ({ formData, setFormData }) => {
  const [locations, setLocations] = useState([]); // List of city options
  const [inputValue, setInputValue] = useState(""); // Track user input
  const [isTopCity, setIsTopCity] = useState(true); // Track if top cities should be shown

  // Debounced function to fetch cities
  const fetchCities = useMemo(
    () =>
      debounce(async (query) => {
        try {
          const response = await getTopCitiesApi(query, isTopCity);
          if (response && response.cities) {
            setLocations(response.cities);
          }
        } catch (error) {
          console.log(error.message);
        }
      }, 300),
    [isTopCity] // Recalculate when `isTopCity` changes
  );

  useEffect(() => {
    fetchCities(inputValue);
    return () => fetchCities.clear();
  }, [inputValue, fetchCities, isTopCity]);

  // Handle focus to load top cities
  const handleFocus = () => {
    setIsTopCity(true);
    fetchCities(""); // Fetch top cities when input is focused
  };

  // Handle city selection
  const handleCityChange = (event, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      preferredLocation: value, // Update selected cities
    }));
  };

  // Handle input change for dynamic search
  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    setIsTopCity(false); // Switch to search mode after input change
  };

  return (
    <Accordion
      defaultExpanded
      sx={{
        boxShadow: "none",
        backgroundColor: "transparent",
        "&:before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: "#666", opacity: 0.7 }} />}
        sx={{
          padding: "8px 0",
          minHeight: "40px",
          "& .MuiAccordionSummary-content": {
            margin: "0",
          },
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "bold",
            fontFamily: "IBM Plex Sans, sans-serif",
            color: "#333",
          }}
        >
          Preferred City
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{ padding: "0 0 16px 0", backgroundColor: "transparent" }}
      >
        <Autocomplete
          multiple // Allow multiple selections
          options={locations.map((city) => city.name)} // City options
          disableCloseOnSelect
          value={formData.preferredLocation || []} // Selected cities
          onChange={handleCityChange} // Handle selection
          onInputChange={handleInputChange} // Handle user input
          getOptionLabel={(option) => option}
          onFocus={handleFocus} 
          ListboxProps={{
            style: {
              maxHeight: "200px",
              fontFamily: "Satoshi",
              fontWeight: "bold",
              overflowY: "auto",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Search Preferred Cities"
              variant="outlined"
              size="small"
            />
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip label={option} {...getTagProps({ index })} key={index} />
            ))
          }
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default CandidatePrefferedCity;
