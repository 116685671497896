import React from 'react'
import MarketPage from './MarketPage'
import Footer from '../../../components/JobSeekerComponents/Footer/Footer'

const MarketHome = () => {
  return (
    <div style={{display:"flex",flexDirection:"column"}}>
      <MarketPage/>
      <Footer/>
    </div>

  )
}

export default MarketHome