import React from "react";
import { Box, Typography, Grid, Card, CardContent } from "@mui/material";
import { Container } from "@mui/system";

const CourseAdvantage = () => {
  return (
    <Container maxWidth="lg" sx={{mb:5}}>
      <Box sx={{ background: "#3D596CFF", color: "#fff", p: 4, borderRadius: 4 }}>
        <Grid container spacing={2} alignItems="center">
          {/* Left Side - Vacanzi Skillup */}
          <Grid item xs={12} md={5}>
            <Card sx={{ background: "transparent", boxShadow: "none" }}>
              <CardContent>
                <Typography variant="h4" fontWeight={700} gutterBottom sx={{ fontFamily: "gilroy", mb: 3, color: "#fff" }}>
                  <span style={{ color: "#0800FFFF" }}>Vacanzi</span>{" "}
                  <span style={{ color: "#D6C80AFF", fontWeight: 700 }}>Skillup</span>
                </Typography>

                <Typography variant="h6" fontWeight={700} sx={{ fontFamily: "gilroy", mb: 1, color: "#fff" }}>
                  ✪ Guaranteed interviews
                </Typography>
                <Typography variant="body1" sx={{ fontFamily: "gilroy", mb: 3, color: "#fff" }}>
                  5 guaranteed interview opportunities at companies like Google, Amazon, Swiggy, etc.
                </Typography>

                <Typography variant="h6" fontWeight={700} sx={{ fontFamily: "gilroy", mb: 1, color: "#fff" }}>
                  ✪ Free skill-building courses
                </Typography>
                <Typography variant="body1" sx={{ fontFamily: "gilroy", mb: 3, color: "#fff" }}>
                  Additional courses worth ₹1 Lakh to future-proof your career.
                </Typography>

                <Typography variant="h6" fontWeight={700} sx={{ fontFamily: "gilroy", mb: 1, color: "#fff" }}>
                  ✪ Monthly coffee meet-ups
                </Typography>
                <Typography variant="body1" sx={{ fontFamily: "gilroy", mb: 3, color: "#fff" }}>
                  Networking events with batchmates to foster community building.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Middle VS Section */}
          <Grid item xs={12} md={2} display="flex" justifyContent="center" alignItems="center">
            <Typography
              variant="h5"
              sx={{
                background: "#fff",
                color: "#6a0dad",
                borderRadius: "50%",
                width: 50,
                height: 50,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: 700,
              }}
            >
              vs
            </Typography>
          </Grid>

          {/* Right Side - Other Platforms */}
          <Grid item xs={12} md={5}>
            <Card sx={{ background: "transparent", boxShadow: "none" }}>
              <CardContent>
                <Typography variant="h4" fontWeight={700} sx={{ fontFamily: "gilroy", mb: 3, color: "#fff" }}>
                  Other Platforms
                </Typography>

                <Typography variant="h6" fontWeight={700} sx={{ fontFamily: "gilroy", mb: 1, color: "#fff" }}>
                  ● Limited placement assistance
                </Typography>
                <Typography variant="body1" sx={{ fontFamily: "gilroy", mb: 3, color: "#fff" }}>
                  Placement support is available but with limited opportunities and guidance.
                </Typography>

                <Typography variant="h6" fontWeight={700} sx={{ fontFamily: "gilroy", mb: 1, color: "#fff" }}>
                  ● Up skilling - at an additional cost
                </Typography>
                <Typography variant="body1" sx={{ fontFamily: "gilroy", mb: 3, color: "#fff" }}>
                  Up skilling available but not included with your online degree.
                </Typography>

                <Typography variant="h6" fontWeight={700} sx={{ fontFamily: "gilroy", mb: 1, color: "#fff" }}>
                  ● No offline networking
                </Typography>
                <Typography variant="body1" sx={{ fontFamily: "gilroy", mb: 3, color: "#fff" }}>
                  Limited to online interactions, missing in-person meet-ups.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default CourseAdvantage;
