import { useState, useEffect } from "react";
import { Paper, Typography, Avatar, IconButton, Box } from "@mui/material";
import { ArrowBack, ArrowForward, Star } from "@mui/icons-material";
import VacanziLogo from "../../../assets/icons/logos/vacanzi.png";
import { allTestimonialsApi } from "../../../api/jobseeker";

const JobSeekerTestimonialCard = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    const getAllTestimonials = async () => {
      try {
        const response = await allTestimonialsApi();
        setTestimonials(response?.testimonials || []); // Ensure it's always an array
      } catch (error) {
        console.log(error.message);
      }
    };
    getAllTestimonials();
  }, []);

  const handleNext = () => {
    if (testimonials.length > 0) {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    }
  };

  const handlePrev = () => {
    if (testimonials.length > 0) {
      setCurrentIndex(
        (prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length
      );
    }
  };

  if (!testimonials.length) {
    return (
      <Typography variant="h6" align="center">
        No testimonials available.
      </Typography>
    );
  }

  const currentTestimonial = testimonials[currentIndex];
  
  return (
    <Paper
      elevation={0}
      sx={{
        p: 4,
        maxWidth: 450,
        bgcolor: "#f7f7f9",
        mt: 10,
        mb: 10,
        borderRadius: "1.5rem",
        position: "relative",
      }}
    >
      {/* Testimonial Text */}
      <Typography variant="h5" fontWeight="bold" color="text.primary">
        “{currentTestimonial?.testimonial}”
      </Typography>

      {/* Rating and Logo */}
      <Box display="flex" alignItems="center" mt={3}>
        <img src={VacanziLogo} alt="Vacanzi Logo" style={{ height: 28 }} />
        <Box display="flex" ml={1} color="gold">
          {[...Array(Math.round(currentTestimonial?.rating || 0))].map(
            (_, index) => (
              <Star key={index} sx={{ color:'#423bfa'}} fontSize="medium" />
            )
          )}
        </Box>
        <Typography ml={1} fontSize="1.1rem" fontWeight="bold" color="text.secondary">
          {currentTestimonial?.rating}
        </Typography>
      </Box>

      {/* User Profile */}
      <Box display="flex" alignItems="center" mt={4}>
        <Avatar
          src={currentTestimonial?.userId?.avatar || "/default-avatar.png"}
          alt="User Profile"
          sx={{ width: 56, height: 56 }}
        />
        <Box ml={2}>
          <Typography fontSize="1.2rem" fontWeight="bold">
            {currentTestimonial?.userId?.fullName}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {currentTestimonial?.designation} • {currentTestimonial?.location}
          </Typography>
        </Box>
      </Box>

      {/* Navigation Buttons */}
      <Box
        display="flex"
        justifyContent="flex-end"
        position="absolute"
        bottom={16}
        right={16}
      >
        <IconButton
          onClick={handlePrev}
          sx={{ bgcolor: "grey.200", "&:hover": { bgcolor: "grey.300" } }}
        >
          <ArrowBack />
        </IconButton>
        <IconButton
          onClick={handleNext}
          sx={{
            bgcolor: "grey.200",
            ml: 1,
            "&:hover": { bgcolor: "grey.300" },
          }}
        >
          <ArrowForward />
        </IconButton>
      </Box>
    </Paper>
  );
};

export default JobSeekerTestimonialCard;
