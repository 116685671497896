import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Grid,
  TextField,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  FormControl,
  Select,
  MenuItem,
  Autocomplete,
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Chip,
  Checkbox,
  FormHelperText,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { debounce } from "lodash"; // Import debounce from lodash

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import dayjs from "dayjs";
import RoomIcon from "@mui/icons-material/Room"; // Location icon
import CloseIcon from "@mui/icons-material/Close";
import SchoolIcon from "@mui/icons-material/School";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { getTopCitiesApi } from "../../../../api/common";

const debouncedFetchCities = debounce(
  async (query, isTopCity, setLocations) => {
    try {
      const response = await getTopCitiesApi(query, isTopCity);
      if (response?.cities) {
        setLocations(response.cities);
      }
    } catch (error) {
      console.log("Error fetching cities:", error.message);
    }
  },
  300
);

const JobSeekerCaseZero = ({
  formData,
  setFormData,
  handleGenderChange,
  handleExperienceChange,
  handleCurrentlyPursuingChange,
  handlePursuingEducationChange,
  setInputValue,
  locations,
  ugEducations,
  pgEducations,
  formDataErrors,
  setLocations,
  inputValue,
  formErrors,
  setFormErrors,
}) => {
  const location = useLocation();
  const { signupEmail, signupName, signupPhone } = location.state || {};
  const [openModal, setOpenModal] = useState(false);
  const [preferredModal, setPreferredModal] = useState(false);

  const [isTopCity, setIsTopCity] = useState(true);

  // Use useEffect to set initial form data from location.state
  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      email: signupEmail || prevFormData.email,
      fullName: signupName || prevFormData.fullName,
      phone: signupPhone || prevFormData.phone,
    }));
  }, [signupEmail, signupName, signupPhone, setFormData]);

  const handleChange = (e) => {
    if (!e) return; // Guard clause to handle null or undefined event

    const { name, value } = e.target || {}; // Safely destructure target
    if (name) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));

      // Full name validation
      if (name === "fullName") {
        const trimmedValue = value.trim(); // Trim leading and trailing spaces
        const regex = /^[A-Za-z\s]+$/; // Letters and spaces only
        if (trimmedValue === "") {
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            fullNameError:
              "Full name cannot be empty or contain only characters.",
          }));
        } else if (!regex.test(trimmedValue)) {
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            fullNameError: "Full name should only contain letters and spaces.",
          }));
        } else {
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            fullNameError: "",
          }));
        }
      }

      // Email validation
      if (name === "email") {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (value === "") {
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            emailError: "Email cannot be empty.",
          }));
        } else if (!emailRegex.test(value)) {
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            emailError: "Please enter a valid email address.",
          }));
        } else {
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            emailError: "",
          }));
        }
      }
    }
  };

  useEffect(() => {
    if (inputValue?.trim().length >= 1) {
      debouncedFetchCities(inputValue, isTopCity, setLocations);
    }
  }, [inputValue, isTopCity, setLocations]); // ✅ No warning now, since debouncedFetchCities is stable

  const handleFocus = () => {
    debouncedFetchCities("", true, setLocations); // ✅ Ensure top cities load on focus
  };

  // const handleLocationChange = (e) => {
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     currentLocation: e.target.value,
  //   }));
  // };

  // const handlePreferredLocationChange = (e) => {
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     preferredLocation: e.target.value,
  //   }));
  // };

  // const handleExpectedCtcChange = (e) => {
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     expectedCtc: e.target.value,
  //   }));
  // };

  const degreeTypes = [
    { label: "High School Diploma", value: "highSchoolDiploma" },
    { label: "Under Graduate", value: "underGraduate" },
    { label: "Post Graduate", value: "postGraduate" },
    { label: "Doctorate", value: "doctorate" },
    { label: "Technical Certification", value: "technicalCertification" },
    { label: "Vocational Training", value: "vocationalTraining" },
    { label: "GED", value: "ged" },
  ];

  const SelectionBox = ({ label, value, selectedValue, onClick, icon }) => {
    return (
      <Box
        onClick={() => onClick(value)}
        sx={{
          display: "flex",
          flexDirection: "column", // Stack text and icon vertically
          alignItems: "center",
          justifyContent: "center",
          padding: "12px",
          borderRadius: "12px",
          border: "1px solid",
          borderColor: selectedValue === value ? "#423BFA" : "#ccc",
          backgroundColor: selectedValue === value ? "#EBF3FF" : "transparent",
          cursor: "pointer",
          width: "200px",
          textAlign: "center",
        }}
      >
        <Typography fontWeight="bold" fontSize="14px">
          {label}
        </Typography>

        <Box sx={{ marginTop: "8px" }}>
          {React.isValidElement(icon) ? icon : null}{" "}
          {/* Check if icon is a valid element */}
        </Box>
      </Box>
    );
  };

  return (
    <Grid container spacing={2} sx={{ marginTop: 2 }}>
      <Grid item xs={12} md={4}>
        <Typography
          component="legend"
          sx={{ fontWeight: "bold", display: "flex", alignItems: "center" }}
        >
          Name
          <Typography component="span" sx={{ color: "red", marginLeft: 0.5 }}>
            *
          </Typography>
        </Typography>
        <TextField
          required
          fullWidth
          placeholder="Enter full name"
          name="fullName"
          variant="outlined"
          value={formData.fullName || ""}
          onChange={handleChange}
          size="small"
          sx={{ marginTop: 1 }}
          disabled={!!signupName}
          error={!!(formDataErrors?.fullName || formErrors.fullNameError)}
          helperText={formDataErrors?.fullName || formErrors.fullNameError}
        />
        <Typography
          style={{ fontSize: "12px", color: "gray", marginTop: "4px" }}
        >
          Eg. Ravi Kumar
        </Typography>
      </Grid>

      <Grid item xs={12} md={4}>
        <Typography component="legend" sx={{ fontWeight: "bold" }}>
          Email
          <Typography component="span" sx={{ color: "red", marginLeft: 0.5 }}>
            *
          </Typography>
        </Typography>
        <TextField
          required
          fullWidth
          placeholder="Enter email"
          name="email"
          variant="outlined"
          value={formData.email || ""}
          onChange={handleChange}
          size="small"
          sx={{ marginTop: 1 }}
          error={!!(formDataErrors?.email || formErrors.emailError)}
          helperText={formDataErrors?.email || formErrors.emailError}
        />

        <Typography
          style={{ fontSize: "12px", color: "gray", marginTop: "4px" }}
        >
          Eg. ravi.kumar@domain.com
        </Typography>
      </Grid>
      {/* <Grid item xs={12} md={4}>
        <Typography
          component="legend"
          
          sx={{ fontWeight: "bold" }}
        >
          Mobile
          <Typography component="span" sx={{ color: "red", marginLeft: 0.5 }}>
            *
          </Typography>
        </Typography>
        <TextField
          fullWidth
          type="tel"
          name="phone" // Ensure this matches the formData key
          variant="outlined"
          placeholder="Enter mobile number"
          value={formData.phone || ""} // Ensure this matches the formData key
          onChange={handleChange}
          size="small"
          sx={{ marginTop: 1 }}
          disabled
        />
      </Grid> */}
      {/* Gender Section */}
      <Grid item xs={12} sm={6} md={4}>
        <Typography
          component="legend"
          sx={{
            fontWeight: 500,
            fontFamily: "IBM Plex Sans",
            marginBottom: 1,
          }}
        >
          Gender
          <Typography component="span" sx={{ color: "red", marginLeft: 0.5 }}>
            *
          </Typography>
        </Typography>
        <FormControl
          component="fieldset"
          error={!!formDataErrors.gender} // Display error if gender is missing
        >
          <RadioGroup
            row
            value={formData.gender}
            onChange={(e) => handleGenderChange(e.target.value)}
            sx={{ justifyContent: "flex-start" }}
          >
            <FormControlLabel
              value="male"
              control={
                <Radio
                  sx={{
                    color: "#423BFA",
                    "&.Mui-checked": { color: "#423BFA" },
                  }}
                />
              }
              label="Male"
              sx={{
                ".MuiFormControlLabel-label": {
                  fontWeight: "bold",
                  fontSize: "14px",
                  marginLeft: 0.5,
                },
              }}
            />
            <FormControlLabel
              value="female"
              control={
                <Radio
                  sx={{
                    color: "#423BFA",
                    "&.Mui-checked": { color: "#423BFA" },
                  }}
                />
              }
              label="Female"
              sx={{
                ".MuiFormControlLabel-label": {
                  fontWeight: "bold",
                  fontSize: "14px",
                },
              }}
            />
          </RadioGroup>
          {/* Helper text for validation errors */}
          {formDataErrors.gender && (
            <FormHelperText>{formDataErrors.gender}</FormHelperText>
          )}
        </FormControl>
      </Grid>

      <Grid item xs={12} md={4}>
        <Typography
          component="legend"
          sx={{
            fontWeight: 500,
            fontFamily: "IBM Plex Sans",
            marginBottom: 1,
          }}
        >
          Date of Birth (DOB)
          <Typography component="span" sx={{ color: "red", marginLeft: 0.5 }}>
            *
          </Typography>
        </Typography>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MobileDatePicker
            value={formData.dob ? dayjs(formData.dob) : null}
            onChange={(newValue) => {
              const formattedDate = newValue
                ? dayjs(newValue).format("YYYY-MM-DD")
                : "";
              const minAllowedDate = dayjs().subtract(14, "year"); // 14 years ago
              const currentDate = dayjs(); // Today

              if (!newValue) {
                // Handle case where no date is selected (optional)
                setFormErrors((prevErrors) => ({
                  ...prevErrors,
                  dob: "",
                }));
              } else if (dayjs(newValue).isAfter(currentDate)) {
                // Disallow future dates
                setFormErrors((prevErrors) => ({
                  ...prevErrors,
                  dob: "Future dates are not allowed.",
                }));
              } else if (dayjs(newValue).isAfter(minAllowedDate)) {
                // Enforce minimum age of 14
                setFormErrors((prevErrors) => ({
                  ...prevErrors,
                  dob: "You must be at least 14 years old.",
                }));
              } else {
                // Clear error if date is valid
                setFormErrors((prevErrors) => ({
                  ...prevErrors,
                  dob: "",
                }));
              }

              // Update form data regardless of validation (to reflect user input)
              handleChange({
                target: {
                  name: "dob",
                  value: formattedDate,
                },
              });
            }}
            maxDate={dayjs()} // Restrict picker to today and earlier
            slotProps={{
              textField: {
                fullWidth: true,
                size: "small",
                variant: "outlined",
                sx: { fontSize: "12px" },
                error: Boolean(formDataErrors.dob || formErrors.dob),
                helperText: formDataErrors.dob || formErrors.dob,
              },
            }}
          />
        </LocalizationProvider>
      </Grid>

      {/* Work Experience Section */}
      {/* <Grid item xs={12} md={4} sm={6}>
        <Typography
          component="legend"
          
          sx={{
                fontWeight: 500, // Slightly lighter than bold
                fontFamily: 'IBM Plex Sans',
                marginBottom: 1,
              }}
        >
          Work Experience
        </Typography>
        <RadioGroup
          row
          value={formData.hasWorkExperience}
          onChange={(e) => handleExperienceChange(e.target.value === "true")} // Convert string to boolean
          sx={{ justifyContent: "flex-start" }}
        >
          <FormControlLabel
            value={false}
            control={
              <Radio
                sx={{ color: "#423BFA", "&.Mui-checked": { color: "#423BFA" } }}
              />
            }
            label="Fresher"
            sx={{
              ".MuiFormControlLabel-label": {
                fontWeight: "bold",
                fontSize: "14px",
              },
            }}
          />
          <FormControlLabel
            value={true}
            control={
              <Radio
                sx={{ color: "#423BFA", "&.Mui-checked": { color: "#423BFA" } }}
              />
            }
            label="Experienced"
            sx={{
              ".MuiFormControlLabel-label": {
                fontWeight: "bold",
                fontSize: "14px",
              },
            }}
          />
        </RadioGroup>
      </Grid> */}

      <Grid item xs={12} md={4}>
        <Typography
          component="legend"
          sx={{
            fontWeight: 500, // Slightly lighter than bold
            fontFamily: "IBM Plex Sans",
            marginBottom: 1,
          }}
        >
          Current Location
          <Typography component="span" sx={{ color: "red", marginLeft: 0.5 }}>
            *
          </Typography>
        </Typography>

        {/* Input Field that triggers the modal */}
        <TextField
          variant="outlined"
          fullWidth
          size="small"
          value={formData?.currentLocation}
          placeholder="Search for a city"
          onClick={() => setOpenModal(true)} // Opens modal on click
          error={
            !!(formDataErrors.currentLocation || formErrors.currentLocation)
          }
          helperText={
            formDataErrors.currentLocation || formErrors.currentLocation
          }
        />
        <Typography
          style={{ fontSize: "12px", color: "gray", marginTop: "4px" }}
        >
          Eg. Bengaluru
        </Typography>

        {/* Modal for city selection */}
        <Dialog open={openModal} onClose={() => setOpenModal(false)} fullWidth>
          <DialogContent sx={{ p: 3, textAlign: "center" }}>
            {/* Header */}
            <Typography variant="h6" fontWeight="bold" align="left">
              Search your city
            </Typography>

            {/* Autocomplete Input Field */}
            <Autocomplete
              freeSolo
              options={locations?.map((city) => city.name) || []}
              value={formData?.currentLocation || ""}
              getOptionLabel={(option) => option}
              onChange={(event, newValue) => {
                setIsTopCity(false);
                setFormData((prev) => ({
                  ...prev,
                  currentLocation: newValue || "",
                }));
                setOpenModal(false);
              }}
              onInputChange={(event, newInputValue) => {
                if (newInputValue.trim().length === 0) {
                  setIsTopCity(true);
                } else {
                  setIsTopCity(false);
                }

                setInputValue(newInputValue);
              }}
              onFocus={handleFocus}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                  placeholder="Enter your City"
                  sx={{
                    mt: 2,
                    borderRadius: 2,
                    backgroundColor: "#F8F8F8",
                  }}
                />
              )}
            />

            {/* Location Icon and Message */}
            <Box mt={4}>
              <IconButton disableRipple>
                <RoomIcon sx={{ fontSize: 50, color: "#757575" }} />
              </IconButton>
              <Typography variant="h6" fontWeight="bold" mt={1}>
                Choose your city
              </Typography>
              {/* <Typography variant="body2" color="textSecondary">
                Help us know where you currently live
              </Typography>
              <Typography variant="caption" color="textSecondary">
                We personalize your experience based on where you live and show
                you the right jobs
              </Typography> */}
            </Box>

            {/* Close Button */}
            <Button
              onClick={() => setOpenModal(false)}
              sx={{
                mt: 3,
                width: "100%",
                borderRadius: 2,
                background: "#423bfa",
                fontWeight: "bold",
              }}
              variant="contained"
            >
              Close
            </Button>
          </DialogContent>
        </Dialog>
      </Grid>

      {/* Prefereed Location */}
      <Grid item xs={12} md={4}>
        {/* Label */}
        <Typography
          variant="body1"
          sx={{
            fontWeight: 500, // Slightly lighter than bold
            fontFamily: "IBM Plex Sans",
            marginBottom: 1,
          }}
        >
          Preferred Location
          <Typography component="span" sx={{ color: "red", marginLeft: 0.5 }}>
            *
          </Typography>
        </Typography>

        {/* TextField with Chips */}
        <TextField
          variant="outlined"
          fullWidth
          size="small"
          value={formData?.preferredLocation}
          placeholder="Search for a city"
          onClick={() => setPreferredModal(true)}
          deleteIcon={<CloseIcon sx={{ fontSize: "1rem", color: "red" }} />}
          error={
            !!(formDataErrors.preferredLocation || formErrors.preferredLocation)
          }
          helperText={
            formDataErrors.preferredLocation || formErrors.preferredLocation
          }
        />
        <Typography
          style={{ fontSize: "12px", color: "gray", marginTop: "4px" }}
        >
          Eg. Bengaluru
        </Typography>

        {/* Modal for city selection */}
        <Dialog
          open={preferredModal}
          onClose={() => setPreferredModal(false)}
          fullWidth
        >
          <DialogContent sx={{ p: 3, textAlign: "center" }}>
            {/* Header */}
            <Typography variant="h6" fontWeight="bold" align="left">
              Add Preferred City
            </Typography>

            {/* Multi-Select Autocomplete Input Field */}
            <Autocomplete
              multiple
              options={locations?.map((city) => city.name)}
              getOptionLabel={(option) => option}
              disableCloseOnSelect
              value={formData.preferredLocation || []}
              onChange={(event, newValue) => {
                setIsTopCity(false);

                setFormData((prev) => ({
                  ...prev,
                  preferredLocation: newValue,
                }));
              }}
              onInputChange={(event, newInputValue) => {
                if (newInputValue.trim().length === 0) {
                  setIsTopCity(true);
                } else {
                  setIsTopCity(false);
                }

                setInputValue(newInputValue); // Ensure filtering works when typing
              }}
              onFocus={handleFocus}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                  placeholder="Search Your Preferred Location"
                />
              )}
            />

            {/* Display selected locations as Chips inside the modal */}
            {/* <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
              <Chip
                key={formData?.currentLocation}
                label={formData?.currentLocation}
                size="small"
                onDelete={() =>
                  setFormData((prev) => ({ ...prev, currentLocation: "" }))
                } // Clear location on delete
                sx={{
                  fontSize: "1rem",
                  color: "green",
                  textAlign: "left",
                  mt: 1,
                  padding: 2,
                }}
                deleteIcon={
                  <CloseIcon sx={{ fontSize: "1rem", color: "red" }} />
                } // Custom cross icon
              />
            </Box> */}

            {/* Location Icon and Message */}
            <Box mt={4}>
              <IconButton disableRipple>
                <RoomIcon sx={{ fontSize: 50, color: "#757575" }} />
              </IconButton>
              <Typography variant="h6" fontWeight="bold" mt={1}>
                Choose your city
              </Typography>
              {/* <Typography variant="body2" color="textSecondary">
                Help us know where you currently live
              </Typography>
              <Typography variant="caption" color="textSecondary">
                We personalize your experience based on where you live and show
                you the right jobs
              </Typography> */}
            </Box>

            {/* Close Button */}
            <Button
              onClick={() => setPreferredModal(false)}
              sx={{
                mt: 3,
                width: "100%",
                borderRadius: 2,
                background: "#423bfa",
                fontWeight: "bold",
              }}
              variant="contained"
            >
              Done
            </Button>
          </DialogContent>
        </Dialog>
      </Grid>

      {/* Section 1: "Are you still pursuing your education?" */}
      <Grid item xs={12} md={12}>
        <Typography
          component="legend"
          sx={{
            fontWeight: 500, // Slightly lighter than bold
            fontFamily: "IBM Plex Sans",
            marginBottom: 1,
          }}
        >
          Are you still pursuing your education?
        </Typography>
        {formDataErrors.experienceType && (
          <FormHelperText sx={{ color: "red" }}>
            {formDataErrors.experienceType}
          </FormHelperText>
        )}
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            marginBottom: 3,
            paddingX: 2, // Add padding to the left and right of the container
          }}
        >
          <Grid
            item
            xs="auto"
            sx={{
              px: 2, // Add padding to both sides of each Grid item
            }}
          >
            <SelectionBox
              label="Fresher"
              value="fresher"
              selectedValue={formData?.experienceType}
              onClick={handlePursuingEducationChange}
              icon={<SchoolIcon />} // Pass the icon as a component
            />
          </Grid>

          <Grid
            item
            xs="auto"
            sx={{
              px: 2, // Add padding to both sides of each Grid item
            }}
          >
            <SelectionBox
              label="Experienced"
              value="experienced"
              selectedValue={formData?.experienceType}
              onClick={handlePursuingEducationChange}
              icon={<LaptopMacIcon />}
            />
          </Grid>

          <Grid
            item
            xs="auto"
            sx={{
              px: 2, // Add padding to both sides of each Grid item
            }}
          >
            <SelectionBox
              label="Pursuing Education"
              value="pursuingEducation"
              selectedValue={formData?.experienceType}
              onClick={handlePursuingEducationChange}
              icon={<MenuBookIcon />}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={6} sx={{ paddingInline: 2 }}>
        <Typography
          component="legend"
          sx={{
            fontWeight: 500, // Slightly lighter than bold
            fontFamily: "IBM Plex Sans",
            marginBottom: 1,
          }}
        >
          Education
          <Typography component="span" sx={{ color: "red", marginLeft: 0.5 }}>
            *
          </Typography>
        </Typography>

        <FormControl fullWidth size="small">
          <Select
            name="degreeType"
            value={formData.degreeType || ""}
            onChange={handleChange}
            sx={{
              fontSize: "0.9rem", // Improve the font size
              fontWeight: 500, // Add a semi-bold weight
              color: "#333", // Better text color

              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#005555", // Darker teal on hover
              },
              "& .MuiSvgIcon-root": {
                color: "#008080", // Teal dropdown arrow
              },
            }}
          >
            {degreeTypes.map((degree) => (
              <MenuItem
                key={degree.value}
                value={degree.value}
                sx={{
                  fontSize: "0.9rem", // Ensure the font size matches
                  fontWeight: 400, // Normal weight
                  color: "#444", // Softer text color for menu items
                  "&:hover": {
                    backgroundColor: "#f5f5f5", // Teal hover background
                  },
                }}
              >
                {degree.label}
              </MenuItem>
            ))}
          </Select>
          {formDataErrors.degreeType && (
            <FormHelperText sx={{ color: "red" }}>
              {formDataErrors.degreeType}
            </FormHelperText>
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography
          component="legend"
          sx={{
            fontWeight: 500, // Slightly lighter than bold
            fontFamily: "IBM Plex Sans",
            marginBottom: 1,
          }}
        >
          Institution Name
          <Typography component="span" sx={{ color: "red", marginLeft: 0.5 }}>
            *
          </Typography>
        </Typography>
        <TextField
          fullWidth
          placeholder="Enter institution name"
          name="institution"
          variant="outlined"
          value={formData.institution}
          onChange={handleChange}
          size="small"
          error={!!(formDataErrors.institution || formErrors.institution)}
          helperText={formDataErrors.institution || formErrors.institution}
        />
        <Typography style={{ fontSize: "12px", color: "gray" }}>
          Eg. IIT, NIT, IIM
        </Typography>
      </Grid>
      {formData?.degreeType === "underGraduate" && (
        <Grid item xs={12} md={6}>
          <Typography
            component="legend"
            sx={{
              fontWeight: 500, // Slightly lighter than bold
              fontFamily: "IBM Plex Sans",
              marginBottom: 1,
            }}
          >
            Highest Qualification
            <Typography component="span" sx={{ color: "red", marginLeft: 0.5 }}>
              *
            </Typography>
          </Typography>

          <FormControl fullWidth error={!!formDataErrors.highestEducation}>
            <Autocomplete
              freeSolo
              options={ugEducations?.map((education) => education.name) || []} // Map `ugEducations` array to names
              value={formData?.highestEducation || ""} // Ensure the value is properly set
              onChange={(event, newValue) => {
                setFormData((prev) => ({
                  ...prev,
                  highestEducation: newValue, // Update formData with selected value
                }));
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    sx={{ background: "lightgrey" }}
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  placeholder="Select Qualification"
                  fullWidth
                />
              )}
            />
          </FormControl>
          {formDataErrors.highestEducation && (
            <FormHelperText sx={{ color: "red" }}>
              {formDataErrors.highestEducation}
            </FormHelperText>
          )}
        </Grid>
      )}

      {formData?.degreeType === "postGraduate" && (
        <Grid item xs={12} md={6}>
          <Typography
            component="legend"
            sx={{
              fontWeight: 500, // Slightly lighter than bold
              fontFamily: "IBM Plex Sans",
              marginBottom: 1,
            }}
          >
            Highest Qualification
            <Typography component="span" sx={{ color: "red", marginLeft: 0.5 }}>
              *
            </Typography>
          </Typography>
          <Autocomplete
            freeSolo
            options={pgEducations?.map((education) => education.name) || []} // Map `ugEducations` array to names
            value={formData?.highestEducation || []} // Manage selected values as an array
            onChange={(event, newValue) => {
              setFormData((prev) => ({
                ...prev,
                highestEducation: newValue, // Update formData with the array of selected values
              }));
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  sx={{ background: "lightgrey" }}
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small" // Set the size of the input field
                placeholder="Select Qualification" // Placeholder text
                fullWidth // Ensure the input takes up full width
              />
            )}
          />
          {formDataErrors.highestEducation && (
            <FormHelperText sx={{ color: "red" }}>
              {formDataErrors.highestEducation}
            </FormHelperText>
          )}
        </Grid>
      )}

      <Grid item xs={12} md={6}>
        <Typography
          component="legend"
          sx={{
            fontWeight: 500, // Slightly lighter than bold
            fontFamily: "IBM Plex Sans",
            marginBottom: 1,
          }}
        >
          Year of Passing
          <Typography component="span" sx={{ color: "red", marginLeft: 0.5 }}>
            *
          </Typography>
        </Typography>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          sx={{ marginBottom: 2 }}
        >
          <MobileDatePicker
            value={
              formData.graduationYear ? dayjs(formData.graduationYear) : null
            }
            onChange={(newValue) => {
              handleChange({
                target: {
                  name: "graduationYear",
                  value: newValue ? newValue.format("YYYY-MM-DD") : "",
                },
              });
            }}
            slotProps={{
              textField: {
                fullWidth: true,
                size: "small",
                variant: "outlined",
                sx: {
                  fontSize: "12px", // Reduce font size
                },
              },
            }}
          />
        </LocalizationProvider>
        {formDataErrors.graduationYear && (
          <FormHelperText sx={{ color: "red" }}>
            {formDataErrors.graduationYear}
          </FormHelperText>
        )}
      </Grid>

      {/* Section 2: If pursuing education, show degree options */}
      {formData?.experienceType === "pursuingEducation" && (
        <>
          <Grid item xs={12}>
            {/* <Typography
            component="legend"
            sx={{
              fontWeight: 500, // Slightly lighter than bold
              fontFamily: "IBM Plex Sans",
              marginBottom: 1,
            }}
          >
            What are you currently pursuing?
          </Typography> */}
            {/* <Grid
            container
            spacing={0} // Remove unnecessary spacing
            direction="row"
            sx={{
              display: "flex",
              justifyContent: "flex-start", // Space between items
              alignItems: "center", // Center items vertically
            }}
          >
            {[
              { label: "Diploma", value: "diploma" },
              { label: "Under Graduate", value: "underGraduate" },
              { label: "Graduate", value: "graduate" },
              { label: "Post Graduate", value: "postGraduate" },
            ].map((option) => (
              <Grid
                key={option.value}
                spacing={3}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  margin: 1,
                }} // Ensure all items are in a row
              >
                <SelectionBox
                  label={option.label}
                  value={option.value}
                  selectedValue={formData?.currentlyPursuing}
                  onClick={handleCurrentlyPursuingChange} />
              </Grid>

            ))}
          </Grid> */}
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <Typography
              component="legend"
              sx={{
                fontWeight: 500, // Slightly lighter than bold
                fontFamily: "IBM Plex Sans",
                marginBottom: 1,
              }}
            >
              Institution Name
              <Typography
                component="span"
                sx={{ color: "red", marginLeft: 0.5 }}
              >
                *
              </Typography>
            </Typography>
            <TextField
              fullWidth
              placeholder="Enter institution name"
              name="institution"
              variant="outlined"
              value={formData.institution}
              onChange={handleChange}
              size="small"
              sx={{ marginBottom: 2 }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              component="legend"
              sx={{
                fontWeight: 500, // Slightly lighter than bold
                fontFamily: "IBM Plex Sans",
                marginBottom: 1,
              }}
            >
              Year of Passing
              <Typography
                component="span"
                sx={{ color: "red", marginLeft: 0.5 }}
              >
                *
              </Typography>
            </Typography>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              sx={{ marginBottom: 2 }}
            >
              <MobileDatePicker
                value={
                  formData.graduationYear
                    ? dayjs(formData.graduationYear)
                    : null
                }
                onChange={(newValue) => {
                  handleChange({
                    target: {
                      name: "graduationYear",
                      value: newValue ? newValue.format("YYYY-MM-DD") : "",
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    size="small"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      readOnly: true, // Make the input non-editable
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        height: 96, // Adjust height
                        padding: -10,
                        cursor: "pointer", // Pointer for clarity
                      },
                      "& .MuiOutlinedInput-input": {
                        padding: "8px 10px", // Compact padding
                        fontSize: "0.875rem", // Smaller font size
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid> */}
        </>
      )}

      {/* Section 4: Pre-University or Degree details */}

      {(formData?.experienceType === "fresher" ||
        formData?.experienceType === "pursuingEducation") && (
        <>
          <Grid item xs={12} md={6}>
            <Typography
              component="legend"
              sx={{ fontWeight: "bold", marginBottom: 1 }}
            >
              Preferred Job Role
              <Typography component="span" sx={{ color: "red" }}>
                *
              </Typography>
            </Typography>
            <TextField
              fullWidth
              placeholder="Enter Preferred Job Title"
              name="preferredJobTitle"
              variant="outlined"
              value={formData.preferredJobTitle}
              onChange={handleChange}
              size="small"
              sx={{ marginBottom: 2 }}
              error={
                !!(
                  formDataErrors.preferredJobTitle ||
                  formErrors.preferredJobTitle
                )
              }
              helperText={
                formDataErrors.preferredJobTitle || formErrors.preferredJobTitle
              }
            />
            <Typography style={{ fontSize: "12px", color: "gray" }}>
              Eg. Software Engineer,HR Manager,Sales Manager
            </Typography>
          </Grid>
          {/* <Grid item xs={12} md={4}>
            <Typography
              component="legend"
              
              sx={{ fontWeight: "bold" }}
            >
              Expected CTC
            </Typography>
            <TextField
              fullWidth
              placeholder="Enter your expected CTC"
              variant="outlined"
              value={formData.expectedCtc || ""}
              onChange={handleExpectedCtcChange}
              size="small"
            />
          </Grid> */}
        </>
      )}
    </Grid>
  );
};

export default JobSeekerCaseZero;
