import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  Typography,
  TextField,
  Autocomplete,
  Box,
  FormHelperText,
} from "@mui/material";
import { FormControl, Select, MenuItem, Chip } from "@mui/material";

const JobSeekerCaseTwo = ({
  formData,
  handleCurrentlyWorkingChange,
  setFormData,
  allIndustries,
  formDataErrors,
  setFormDataErrors,
  setIndustriesInput,
}) => {
  const [servingNoticePeriod, setServingNoticePeriod] = useState(
    formData.servingNoticePeriod || false
  );

  const handleTotalExpChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      totalExpInYears: e.target.value,
    }));
  };
  const [newSkill, setNewSkill] = useState("");

  const handleTotalExpInMonthsChange = (event) => {
    const value = event.target.value;
    setFormData((prev) => ({
      ...prev,
      totalExpInMonths: value,
    }));

    if (value === "") {
      setFormDataErrors((prev) => ({
        ...prev,
        totalExpInMonths: "Please select a number of months",
      }));
    } else {
      setFormDataErrors((prev) => {
        const newErrors = { ...prev };
        delete newErrors.totalExpInMonths;
        return newErrors;
      });
    }
  };

  const handleDesignationChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      currentDesignation: e.target.value,
    }));
  };

  const handleCompanyChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      currentCompany: e.target.value,
    }));
  };

  const handleCurrentCtcChange = (e) => {
    const value = e.target.value;
    // Allow letters, spaces, hyphens, and apostrophes; max 50 chars
    if (value === "" || /^\d{1,2}(\.)?$/.test(value) || /^\d{1,2}\.[0-9]$/.test(value)) {
      setFormData((prevData) => ({
        ...prevData,
        currentCtc: value,
      }));
    }
  };

  const handleAddSkill = () => {
    const trimmedSkill = newSkill.trim();
    if (!trimmedSkill || formData.skills.includes(trimmedSkill.toLowerCase()))
      return;

    // Validate skill: letters, numbers, spaces, and optional hyphen/parentheses only
    const skillRegex = /^[a-zA-Z0-9\s\-()]+$/;
    if (!skillRegex.test(trimmedSkill) || trimmedSkill.length < 2) return;

    setFormData((prev) => ({
      ...prev,
      skills: [...prev.skills, trimmedSkill.toLowerCase()],
    }));
    setNewSkill("");
  };

  const handleRemoveSkill = (skillToRemove) => {
    setFormData((prev) => ({
      ...prev,
      skills: prev.skills.filter((skill) => skill !== skillToRemove),
    }));
  };

  useEffect(() => {}, [formData.noticePeriod]);

  const handleNoticePeriodChange = (e) => {
    const value = e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      noticePeriod: value,
    }));
  };

  const handleDescriptionChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      jobDescription: e.target.value,
    }));
  };

  const handleServingNoticePeriodChange = (isServing) => {
    setServingNoticePeriod(isServing);
    setFormData((prevData) => ({
      ...prevData,
      servingNoticePeriod: isServing,
    }));
  };

  return (
    <Box sx={{ backgroundColor: "#ffffff", padding: 2, borderRadius: 4 }}>
      {formData.hasWorkExperience && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Typography
                component="legend"
                variant="bold"
                sx={{
                  fontWeight: 500,
                  fontFamily: "IBM Plex Sans",
                  marginBottom: 1,
                }}
              >
                Total Years of Experience
                <Typography
                  component="span"
                  sx={{ color: "red", marginLeft: 0.5 }}
                >
                  *
                </Typography>
              </Typography>
              <Select
                fullWidth
                value={formData.totalExpInYears}
                onChange={handleTotalExpChange}
                displayEmpty
                sx={{
                  fontWeight: 400,
                  color: "#444",
                  backgroundColor: "#fff",
                  height: "40px",
                  "& .MuiSelect-select": {
                    padding: "6px 12px",
                  },
                  "&:hover": {
                    backgroundColor: "#f5f5f5",
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      fontSize: "0.85rem",
                    },
                  },
                }}
              >
                <MenuItem value="" disabled>
                  Select Years
                </MenuItem>
                {[...Array(61).keys()].map((year) => (
                  <MenuItem key={year} value={year}>
                    {year} {year === 1 ? "Year" : "Years"}
                  </MenuItem>
                ))}
                <MenuItem value="60+">60+ Years</MenuItem>
              </Select>
              {formDataErrors.totalExpInYears && (
                <FormHelperText sx={{ color: "red" }}>
                  {formDataErrors.totalExpInYears}
                </FormHelperText>
              )}
            </Grid>

            <Grid item xs={12} md={4} sx={{ marginBottom: 1 }}>
              <Typography
                component="legend"
                variant="bold"
                sx={{
                  fontWeight: 500,
                  fontFamily: "IBM Plex Sans",
                  marginBottom: 1,
                }}
              >
                Total Months of Experience
                <Typography
                  component="span"
                  sx={{ color: "red", marginLeft: 0.5 }}
                >
                  *
                </Typography>
              </Typography>
              <Select
                fullWidth
                value={formData.totalExpInMonths}
                onChange={handleTotalExpInMonthsChange}
                displayEmpty
                sx={{
                  fontWeight: 400,
                  color: "#444",
                  backgroundColor: "#fff",
                  height: "40px",
                  "& .MuiSelect-select": {
                    padding: "6px 12px",
                  },
                  "&:hover": {
                    backgroundColor: "#f5f5f5",
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      fontSize: "0.85rem",
                    },
                  },
                }}
              >
                <MenuItem value="" disabled>
                  Select Months
                </MenuItem>
                {[...Array(12).keys()].map((month) => (
                  <MenuItem key={month} value={month}>
                    {month} {month === 1 ? "Month" : "Months"}
                  </MenuItem>
                ))}
              </Select>
              {formDataErrors.totalExpInMonths && (
                <FormHelperText sx={{ color: "red" }}>
                  {formDataErrors.totalExpInMonths}
                </FormHelperText>
              )}
            </Grid>

            <Grid item xs={12} md={4} sx={{ marginBottom: 1 }}>
              <Typography
                component="legend"
                variant="bold"
                sx={{
                  fontWeight: 500,
                  fontFamily: "IBM Plex Sans",
                  marginBottom: 1,
                }}
              >
                Job Title
                <Typography
                  component="span"
                  sx={{ color: "red", marginLeft: 0.5 }}
                >
                  *
                </Typography>
              </Typography>
              <TextField
                fullWidth
                placeholder="Enter job title"
                variant="outlined"
                value={formData.currentDesignation || ""}
                onChange={handleDesignationChange}
                size="small"
                sx={{
                  fontSize: "0.9rem",
                  fontWeight: 400,
                  color: "#444",
                  "&:hover": {
                    backgroundColor: "#f5f5f5",
                  },
                }}
                error={!!formDataErrors.currentDesignation}
                helperText={formDataErrors.currentDesignation}
              />
              <Typography
                style={{ fontSize: "12px", color: "gray", marginTop: "4px" }}
              >
                Eg. Software Engineer,Sales Manager
              </Typography>
            </Grid>

            <Grid item xs={12} md={4} sx={{ marginBottom: 1 }}>
              <Typography
                component="legend"
                variant="bold"
                sx={{
                  fontWeight: 500,
                  fontFamily: "IBM Plex Sans",
                  marginBottom: 1,
                }}
              >
                Company Name
                <Typography
                  component="span"
                  sx={{ color: "red", marginLeft: 0.5 }}
                >
                  *
                </Typography>
              </Typography>
              <TextField
                fullWidth
                placeholder="Enter company name"
                variant="outlined"
                value={formData.currentCompany || ""}
                onChange={handleCompanyChange}
                size="small"
                error={!!formDataErrors.currentCompany}
                helperText={formDataErrors.currentCompany}
              />
              <Typography
                style={{ fontSize: "12px", color: "gray", marginTop: "4px" }}
              >
                Eg. XYZ Pvt. Ltd
              </Typography>
            </Grid>
            <Grid item xs={12} md={4} sx={{ marginBottom: 1 }}>
              <Typography
                component="legend"
                variant="bold"
                sx={{
                  fontWeight: 500,
                  fontFamily: "IBM Plex Sans",
                  marginBottom: 1,
                }}
              >
                Industry
                <Typography
                  component="span"
                  sx={{ color: "red", marginLeft: 0.5 }}
                >
                  *
                </Typography>
              </Typography>
              <Autocomplete
                freeSolo
                options={allIndustries || []}
                value={formData.industry || []}
                onInputChange={(event, newInputValue) => {
                  setIndustriesInput(newInputValue);
                }}
                onChange={(event, newValue) => {
                  setFormData((prev) => ({
                    ...prev,
                    industry: newValue,
                  }));
                }}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      sx={{ background: "lightgrey" }}
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    placeholder="Select Industry"
                    fullWidth
                    error={!!formDataErrors.industry}
                    helperText={formDataErrors.industry}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4} sx={{ marginBottom: 1 }}>
              <Typography
                component="legend"
                variant="bold"
                sx={{
                  fontWeight: 500,
                  fontFamily: "IBM Plex Sans",
                  marginBottom: 1,
                }}
              >
                Current CTC Per Annum
                <Typography
                  component="span"
                  sx={{ color: "red", marginLeft: 0.5 }}
                >
                  *
                </Typography>
              </Typography>
              <TextField
                fullWidth
                placeholder="Enter current CTC"
                variant="outlined"
                name="ctc"
                value={formData.currentCtc || ""}
                onChange={handleCurrentCtcChange}
                size="small"
                error={!!formDataErrors.currentCtc}
                helperText={formDataErrors.currentCtc}
              />
            </Grid>
            <Grid item xs={12} sx={{ marginBottom: 1 }}>
              <Typography
                component="legend"
                sx={{
                  fontWeight: 500,
                  fontFamily: "IBM Plex Sans",
                  marginBottom: 1,
                }}
              >
                Job Description(One Liner)
                <Typography
                  component="span"
                  sx={{ color: "red", marginLeft: 0.5 }}
                >
                  *
                </Typography>
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  padding: "8px",
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "white",
                  minHeight: "50px",
                }}
              >
                <textarea
                  placeholder="Describe your experience in one line"
                  value={formData.jobDescription || ""}
                  onChange={handleDescriptionChange}
                  rows={2}
                  style={{
                    width: "100%",
                    border: "none",
                    outline: "none",
                    fontFamily: "IBM Plex Sans",
                    fontSize: "14px",
                    resize: "vertical",
                    backgroundColor: "transparent",
                    minHeight: "50px",
                    maxHeight: "200px",
                  }}
                  inputProps={{ maxLength: 360 }}
                />
              </Box>
              <p
                style={{ textAlign: "right", fontSize: "12px", color: "#888" }}
              >
                {(formData?.jobDescription || "").length}/360 characters
              </p>

              {formDataErrors.jobDescription && (
                <Typography variant="caption" color="error">
                  {formDataErrors.jobDescription}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} md={4} sx={{ marginBottom: 1 }}>
              <Typography
                component="legend"
                variant="bold"
                sx={{
                  fontWeight: 500,
                  fontFamily: "IBM Plex Sans",
                  marginBottom: 1,
                }}
              >
                Currently working in this company?
              </Typography>
              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    variant={
                      formData.currentlyWorking ? "contained" : "outlined"
                    }
                    onClick={() => handleCurrentlyWorkingChange(true)}
                    sx={{
                      borderRadius: 6,
                      backgroundColor: formData.currentlyWorking
                        ? "#423BFA"
                        : "transparent",
                      border: "1px solid #ccc",
                      color: formData.currentlyWorking ? "#fff" : "#000",
                    }}
                  >
                    <Typography fontWeight="bold" fontSize="12px">
                      Yes
                    </Typography>
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant={
                      !formData.currentlyWorking ? "contained" : "outlined"
                    }
                    onClick={() => handleCurrentlyWorkingChange(false)}
                    sx={{
                      borderRadius: 6,
                      backgroundColor: !formData.currentlyWorking
                        ? "#423BFA"
                        : "transparent",
                      border: "1px solid #ccc",
                      color: !formData.currentlyWorking ? "#fff" : "#000",
                    }}
                  >
                    <Typography fontWeight="bold" fontSize="12px">
                      No
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={4} sx={{ marginBottom: 1 }}>
              <Typography
                component="legend"
                variant="bold"
                sx={{
                  fontWeight: 500,
                  fontFamily: "IBM Plex Sans",
                  marginBottom: 1,
                }}
              >
                Serving Notice Period
              </Typography>
              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    variant={servingNoticePeriod ? "contained" : "outlined"}
                    onClick={() => handleServingNoticePeriodChange(true)}
                    sx={{
                      borderRadius: 6,
                      backgroundColor: servingNoticePeriod
                        ? "#423BFA"
                        : "transparent",
                      border: "1px solid #ccc",
                      color: servingNoticePeriod ? "#fff" : "#000",
                    }}
                  >
                    <Typography fontWeight="bold" fontSize="12px">
                      Yes
                    </Typography>
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant={!servingNoticePeriod ? "contained" : "outlined"}
                    onClick={() => handleServingNoticePeriodChange(false)}
                    sx={{
                      borderRadius: 6,
                      backgroundColor: !servingNoticePeriod
                        ? "#423BFA"
                        : "transparent",
                      border: "1px solid #ccc",
                      color: !servingNoticePeriod ? "#fff" : "#000",
                    }}
                  >
                    <Typography fontWeight="bold" fontSize="12px">
                      No
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            {!servingNoticePeriod && (
              <Grid item xs={12} md={4}>
                <Typography
                  component="legend"
                  sx={{
                    fontWeight: 500,
                    fontFamily: "IBM Plex Sans",
                    marginBottom: 1,
                  }}
                >
                  Notice Period
                </Typography>
                <FormControl fullWidth variant="outlined" size="small">
                  <Select
                    fullWidth
                    value={formData.noticePeriod || ""}
                    onChange={handleNoticePeriodChange}
                    displayEmpty
                    sx={{
                      fontWeight: 400,
                      color: "#444",
                      backgroundColor: "#fff",
                      height: "40px",
                      "& .MuiSelect-select": {
                        padding: "6px 12px",
                      },
                      "&:hover": {
                        backgroundColor: "#f5f5f5",
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          fontSize: "0.85rem",
                        },
                      },
                    }}
                  >
                    <MenuItem value="" disabled>
                      Select Notice Period
                    </MenuItem>
                    {[...Array(6).keys()].map((month) => (
                      <MenuItem key={month + 1} value={month + 1}>
                        {month + 1} {month + 1 === 1 ? "Month" : "Months"}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}

            {/* Skills Section */}
            <Grid item xs={12}>
              <Typography
                component="legend"
                sx={{
                  fontWeight: 500,
                  fontFamily: "IBM Plex Sans",
                  marginBottom: 1,
                }}
              >
                Skills
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexWrap: "nowrap",
                  gap: 1,
                  alignItems: "center",
                }}
              >
                <TextField
                  fullWidth
                  placeholder="Add Skills (e.g., CSS3, HTML5)"
                  value={newSkill}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const validInput = inputValue.replace(
                      /[^a-zA-Z0-9\s\-()]/g,
                      ""
                    );
                    setNewSkill(validInput.slice(0, 50));
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === ",") {
                      e.preventDefault();
                      handleAddSkill();
                    }
                  }}
                  size="small"
                  sx={{ flexGrow: 1, marginBottom: 1 }}
                  inputProps={{ maxLength: 50 }}
                  InputProps={{
                    startAdornment: (
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap", // Allow chips to wrap
                          gap: 0.5,
                          maxWidth:'100%',
                          padding:1,
                        }}
                      >
                        {formData.skills.map((skill) => (
                          <Chip
                            key={skill}
                            label={skill}
                            onDelete={() => handleRemoveSkill(skill)}
                            sx={{
                              backgroundColor: "#423bfa",
                              color: "#fff",
                              height: "24px",
                              fontFamily: "IBM Plex Sans",
                              fontSize: "12px",
                              "& .MuiChip-deleteIcon": {
                                color: "#fff",
                                fontSize: "17px",
                                "&:hover": {
                                  color: "#e0e0e0",
                                },
                              },
                            }}
                          />
                        ))}
                      </Box>
                    ),
                  }}
                />

                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddSkill}
                  sx={{
                    borderRadius: 6,
                    backgroundColor: "#423BFA",
                    fontWeight: "bold",
                    border: "1px solid #ccc",
                    color: "#fff",
                    height: "32px", // Match TextField height for alignment
                    minWidth: "60px",
                    padding: "0 16px",
                  }}
                >
                  Add
                </Button>
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default JobSeekerCaseTwo;
