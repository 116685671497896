import React, { useCallback, useEffect, useState } from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Pagination,
  CircularProgress,
  Box,
  Container,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import savedSearchStyles from "../../../styles/savedSearcheStyles";
import { getSavedSearchApi } from "../../../api/search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const SavedSearch = () => {
  const navigate = useNavigate();
  const [savedSearch, setSavedSearch] = useState([]);
  const [searchCount, setSearchCount] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [isReleasedAvailable] = useState(true); // Assuming true for this context
  const [orgData] = useState({ dataBaseSubscribed: true }); // Mocking for this example
  const [openSubscribeForm, setOpenSubscribeForm] = useState(false);
  const [validationError, setValidationError] = useState(false);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  console.log(openSubscribeForm,validationError)

  const handleSelectSearchCount = (e) => {
    setSearchCount(e.target.value);
    setCurrentPage(1);
  };

  const orgId = localStorage.getItem("organizationId");
  const token = localStorage.getItem("token");

  const splitStringToArray = (str) => {
    if (!str || typeof str !== "string" || str.trim() === "") {
      return [];
    }
    return str
      .split(",")
      .map((item) => item.trim())
      .filter(Boolean);
  };

  const buildSearchParams = useCallback((searchData) => {
    const { searchQuery } = searchData || {};
    const params = {
      experienceType: searchQuery?.experienceType || "",
      keyword: Array.isArray(searchQuery?.keyword)
        ? searchQuery.keyword.join(",")
        : searchQuery?.keyword || "",
      shouldntHave: Array.isArray(searchQuery?.shouldntHave)
        ? searchQuery.shouldntHave.join(",")
        : searchQuery?.shouldntHave || "",
      hideViewedJobseekers: searchQuery?.hideViewedJobseekers || "",
      currentLocation: Array.isArray(searchQuery?.currentLocation)
        ? searchQuery.currentLocation.join(",")
        : searchQuery?.currentLocation || "",
      noticePeriod: Array.isArray(searchQuery?.noticePeriod)
        ? searchQuery.noticePeriod.join(",")
        : searchQuery?.noticePeriod || "",
      skills: Array.isArray(searchQuery?.skills)
        ? searchQuery.skills.join(",")
        : searchQuery?.skills || "",
      preferredLocation: Array.isArray(searchQuery?.preferredLocation)
        ? searchQuery.preferredLocation.join(",")
        : searchQuery?.preferredLocation || "",
      ug: searchQuery?.ug || "",
      pg: searchQuery?.pg || "",
      currentCompany: searchQuery?.currentCompany ?? "",
      currentAndPreviousCompany: searchQuery?.currentAndPreviousCompany ?? "",
      industry: Array.isArray(searchQuery?.industry)
        ? searchQuery.industry.join(",")
        : searchQuery?.industry || "",
      booleanSearch: searchQuery?.booleanSearch || "",
      minimumEducation: searchQuery?.minimumEducation || "",
      activeIn: searchQuery?.activeIn || "",
      totalExpMin: searchQuery?.minExperience || "",
      totalExpMax: searchQuery?.maxExperience || "",
      minSalary: searchQuery?.minSalary || "",
      maxSalary: searchQuery?.maxSalary || "",
      minAge: searchQuery?.minAge || "",
      maxAge: searchQuery?.maxAge || "",
      jobType: searchQuery?.jobType || "",
      preferredJobType: searchQuery?.preferredJobType || "",
      gender: searchQuery?.gender || "",
      department: Array.isArray(searchQuery?.department)
        ? searchQuery.department.join(",")
        : searchQuery?.department || "",
      ugInstitute: searchQuery?.ugInstitute || "",
      pgInstitute: searchQuery?.pgInstitute || "",
      ugDegree: Array.isArray(searchQuery?.ugDegree)
        ? searchQuery.ugDegree.join(",")
        : searchQuery?.ugDegree || "",
      pgDegree: Array.isArray(searchQuery?.pgDegree)
        ? searchQuery.pgDegree.join(",")
        : searchQuery?.pgDegree || "",
      ugStartYear: searchQuery?.ugStartYear || "",
      ugEndYear: searchQuery?.ugEndYear || "",
      pgStartYear: searchQuery?.pgStartYear || "",
      pgEndYear: searchQuery?.pgEndYear || "",
    };
    const queryString = Object.entries(params)
      .filter(
        ([_, value]) => value !== "" && value !== null && value !== undefined
      ) // Remove empty values
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");
    return queryString;
  }, []);

  const handleSearchFromSaved = useCallback(
    (searchData) => {
      if (!isReleasedAvailable) {
        setOpenSubscribeForm(true);
        return;
      }
      const keywords = Array.isArray(searchData?.searchQuery?.keyword)
        ? searchData.searchQuery.keyword
        : splitStringToArray(searchData?.searchQuery?.keyword);
      if (keywords.length > 0) {
        setValidationError(false);
        const queryParams = buildSearchParams(searchData);
        if (orgData?.dataBaseSubscribed) {
          navigate(`/candidate-searchList?${queryParams}`);
        } else {
          console.log("Model should open here"); // Placeholder for handleModelOpen
        }
      } else {
        setValidationError(true);
      }
    },
    [buildSearchParams, navigate, orgData, isReleasedAvailable]
  );

  useEffect(() => {
    const fetchSavedSearches = async () => {
      const recruiterId = localStorage.getItem("recruiterId");
      try {
        setLoading(true);
        if (orgId && token) {
          const response = await getSavedSearchApi(token, recruiterId, orgId);
          setSavedSearch(response.data);
          setTotalPages(Math.ceil(response.data.length / searchCount));
        }
      } catch (error) {
        console.error("Error fetching saved searches:", error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchSavedSearches();
  }, [orgId, token, searchCount]);

  const handleFillSearch = useCallback(
    (searchData) => {
      localStorage.setItem("savedSearchData", JSON.stringify(searchData));
      navigate("/search-candidates");
    },
    [navigate]
  );

  const displayedRows = savedSearch?.slice(
    (currentPage - 1) * searchCount,
    currentPage * searchCount
  );

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      {loading ? (
        <Box sx={{ ...savedSearchStyles.parentBox, textAlign: "center" }}>
          <CircularProgress size={60} sx={{ mb: 2 }} />
        </Box>
      ) : (
        <>
          <Box>
            <Typography
              onClick={handleBack}
              gutterBottom
              sx={{
                cursor: "pointer",
                display: "flex",
                ml: 1,
                alignItems: "center",
                color: "grey",
                "&:hover": {
                  color: "primary.dark",
                },
              }}
            >
              <ArrowBackIcon sx={{ marginRight: "4px" }} />
            </Typography>
          </Box>
          <Container maxWidth="lg">
            <Box sx={{ ...savedSearchStyles.parentBox }}>
              <Box sx={{ ...savedSearchStyles.tableParent }}>
                <Box sx={{ ...savedSearchStyles.tableActionParent }}>
                  <Box sx={{ ...savedSearchStyles.pageFilterBox }}>
                    <Typography>Showing</Typography>
                    <FormControl sx={{ width: 100 }}>
                      <InputLabel id="demo-simple-select-label"></InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        sx={{ height: 40, bgcolor: "white" }}
                        value={searchCount}
                        onChange={handleSelectSearchCount}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                      </Select>
                    </FormControl>
                    <Typography>Results</Typography>
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Pagination
                      count={totalPages}
                      page={currentPage}
                      onChange={handlePageChange}
                      color="primary"
                    />
                  </Box>
                </Box>

                <TableContainer
                  component={Paper}
                  sx={{
                    borderRadius: "8px",
                    overflow: "hidden",
                    maxHeight: "600px",
                    width: "100%",
                    "&::-webkit-scrollbar": { display: "none" },
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",
                  }}
                >
                  <Table
                    sx={{
                      ...savedSearchStyles.table,
                      fontFamily: "IBM Plex Sans, sans-serif",
                      minWidth: "800px",
                    }}
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            ...savedSearchStyles.tableHeader,
                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                        >
                          Search Query
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            ...savedSearchStyles.tableHeader,
                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                        >
                          Fill Search
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            ...savedSearchStyles.tableHeader,
                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                        >
                          Search Candidates
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {displayedRows.map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            "&:hover": { backgroundColor: "#f5f5f5" },
                          }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{
                              ...savedSearchStyles.tablecell,
                              maxWidth: "200px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              fontSize: "14px",
                            }}
                          >
                            {Object.entries(row?.searchQuery || {})
                              .map(([key, value]) => `${key}: ${value}`)
                              .join(" | ")}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              ...savedSearchStyles.tablecell,
                              color: "blue",
                              cursor: "pointer",
                              fontSize: "14px",
                              fontWeight: "500",
                              textDecoration: "underline",
                            }}
                          >
                            <Typography onClick={() => handleFillSearch(row)}>
                              Fill Search
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              ...savedSearchStyles.tablecell,
                              color: "green",
                              cursor: "pointer",
                              fontSize: "14px",
                              fontWeight: "500",
                              textDecoration: "underline",
                            }}
                          >
                            <Typography
                              onClick={() => handleSearchFromSaved(row)}
                            >
                              Search Candidates
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </Container>
        </>
      )}
    </>
  );
};

export default SavedSearch;
