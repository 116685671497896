import React from "react";
import { Box, Skeleton, Card, Typography, keyframes } from "@mui/material";

// Define the shimmer animation
const shimmer = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
`;

const shimmerStyle = {
  animation: `${shimmer} 1.5s infinite linear`,
  background: "linear-gradient(to right, #C6C9C8FF 8%, #827979FF 18%, #C1C1C1FF 33%)",
  backgroundSize: "800px 104px",
  position: "relative",
};

const SimilarProfilesSkeleton = () => {
  // Number of skeleton items to show
  const skeletonCount = 3;

  return (
    <Box
      sx={{
        width: { xs: "100%", sm: 314, md: "100%" },
        position: "relative",
        overflow: "hidden",
        p: 1,
      }}
    >
      <Typography sx={{ fontFamily: "IBM plex sans", fontWeight: "bold", mb: 1 }}>
        Similar profiles
      </Typography>

      <Box
        sx={{
          display: "flex",
          width: "100%",
          gap: 1,
          transition: "transform 0.3s ease-in-out",
        }}
      >
        {/* Render skeletons instead of actual profiles */}
        {Array.from({ length: skeletonCount }).map((_, index) => (
          <Card
            key={index}
            sx={{
              maxWidth: { xs: "calc(100% / 3 - 4px)", sm: "calc(100% / 3 - 4px)", md: "calc(100% / 4 - 4px)" },
              borderRadius: 2,
              cursor: "pointer",
              flexShrink: 0,
              backgroundColor: "#EAFBF4FF",
              width: { xs: 120, sm: 150, md: 200 },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              overflow: "hidden", // Ensure shimmer doesn't overflow
              ...shimmerStyle, // Apply shimmer effect to the whole card
            }}
          >
            {/* Skeleton for the video image */}
            <Skeleton
              variant="rectangular"
              sx={{
                borderRadius: 2,
                height: { xs: 130, sm: 150, md: 180 },
                width: "100%",
                backgroundColor: "transparent", // Remove default background to show shimmer
              }}
            />

            <Box sx={{ textAlign: "center", p: 1, width: "100%" }}>
              {/* Skeleton for the name */}
              <Skeleton
                variant="text"
                sx={{
                  fontFamily: "IBM plex sans",
                  fontWeight: "bold",
                  fontSize: { xs: 14, sm: 16 },
                  width: "80%",
                  mx: "auto",
                  backgroundColor: "transparent", // Remove default background to show shimmer
                }}
              />
              <br />
              {/* Skeleton for the designation */}
              <Skeleton
                variant="text"
                sx={{
                  fontFamily: "IBM plex sans",
                  fontSize: { xs: 12, sm: 14 },
                  width: "60%",
                  mx: "auto",
                  backgroundColor: "transparent", // Remove default background to show shimmer
                }}
              />
            </Box>
          </Card>
        ))}
      </Box>

      {/* Left and right skeleton buttons */}
     
    </Box>
  );
};

export default SimilarProfilesSkeleton;