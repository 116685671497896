import React from "react";
import { Box, Button, Typography, Modal, TextField } from "@mui/material";
import { ThreeDot } from "react-loading-indicators";

const JobSeekerOtpModal = ({
  open,
  handleClose,
  handleBack,
  handleFieldChange,
  handleResendOtp,
  handleOtpSubmit,
  canResend,
  timer,
  email,
  otp,
  errors,
  loading,
}) => {
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleOtpSubmit(otp); // Pass otp on Enter key press
    }
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="otp-modal-title" closeAfterTransition>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 420,
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 24,
          p: 3,
        }}
      >
        {/* Modal Title */}
        <Typography
          variant="h6"
          sx={{ fontWeight: "bold", mb: 2, fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }}
        >
          Enter One-Time Password to proceed
        </Typography>

        {/* OTP Instructions */}
        <Typography variant="body2" sx={{ color: "#535252" }}>
          For enhanced security, we have sent a One-Time Password (OTP) to your <b>mobile or email ({email})</b>.
        </Typography>

        <Typography fontSize="14px" sx={{ color: "#535252", mt: 1 }}>
          Please enter the OTP below:
        </Typography>

        {/* OTP Input Field */}
        <TextField
          value={otp}
          size="small"
          fullWidth
          placeholder="Enter OTP received on mobile/email"
          onChange={(event) => handleFieldChange(event)}
          onKeyDown={handleKeyDown}
          error={!!errors.otp}
          inputProps={{
            inputMode: "numeric",
            pattern: "[0-9]*",
            style: { fontSize: "16px" }, // Adjust font size of input value
          }}
          sx={{
            mt: 1,
            "& input::placeholder": {
              fontSize: "13px", // Adjust placeholder font size
              color: "#535252", // Optional: Change placeholder color
            },
          }}
        />

        {/* Error Message */}
        {errors.otp && (
          <Typography color="error" sx={{ mt: 1 }}>
            {errors.otp}
          </Typography>
        )}

        {/* Verify Button */}
        <Button
          fullWidth
          variant="contained"
          sx={{
            mt: 2,
            backgroundColor: "#423bfa",
            ":hover": {
              backgroundColor: "#B0F0F0",
              color: "#000",
            },
            color: "white",
            fontWeight: "bold",
            padding: "0.5rem",
            textTransform: "none",
          }}
          onClick={() => handleOtpSubmit(otp)} // Pass otp on button click
          disabled={loading} // Disable button while loading
        >
          {loading ? (
            <ThreeDot color="#FFFFFF" size="small" text="" textColor="" />
          ) : (
            "Verify"
          )}
        </Button>

        {/* Resend OTP Section */}
        {canResend ? (
          <Typography fontSize="14px" sx={{ color: "#535252", mt: 1 }}>
            Didn't receive OTP?
            <Button color="primary" onClick={handleResendOtp} sx={{ textTransform: "none" }}>
              <Typography fontSize="14px" fontWeight="bold">
                Resend OTP
              </Typography>
            </Button>
          </Typography>
        ) : (
          <Typography fontSize="14px" sx={{ mt: 1 }}>
            Resend OTP in {timer}s
          </Typography>
        )}

        {/* Back Button */}
        <Button
          variant="outlined"
          fullWidth
          sx={{
            mt: 2,
            textTransform: "none",
            fontWeight: "bold",
            color: "#423bfa",
            borderColor: "#423bfa",
            ":hover": {
              backgroundColor: "#f0f0ff",
            },
          }}
          onClick={handleBack}
        >
          Back
        </Button>
      </Box>
    </Modal>
  );
};

export default JobSeekerOtpModal;