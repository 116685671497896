"use client";

import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Button,
  styled,
  Skeleton,
} from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container } from "@mui/system";
import CourseContactForm from "./CourseContactForm";
import { getAllCoursesApi } from "../../../api/common";
import SaveAltIcon from "@mui/icons-material/SaveAlt";

// Styled components
const CarouselContainer = styled(Box)(({ theme }) => ({
  overflow: "hidden",
  padding: theme.spacing(0, 2),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: "16px",
  backgroundColor: "#FFFFFF",
  width: "350px",
  paddingBottom:'20px',
  height: "480px",
  border: "1px solid",
  borderColor: "grey.100",
  transition: "transform 0.3s ease, box-shadow 0.3s ease",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));

const CourseTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "Gilroy, sans-serif",
  fontWeight: 700,
  fontSize: "1.5rem",
  color: "#1A1A1A",
  marginBottom: theme.spacing(1),
}));

const InfoText = styled(Typography)(({ theme }) => ({
  fontFamily: "Gilroy, sans-serif",
  fontWeight: 500,
  fontSize: "1rem",
  color: "#555",
  marginBottom: theme.spacing(0.5),
}));

const ApplyButton = styled(Button)(({ theme }) => ({
  background: "linear-gradient(45deg, #000 30%, #142023FF 90%)",
  borderRadius: 25,
  fontFamily: "gilroy",
  padding: "12px 30px",
  color: "white",
  fontWeight: 600,
  textTransform: "none",
  "&:hover": {
    background: "linear-gradient(45deg, #0B0B0BFF 30%, #262626FF 90%)",
    transform: "translateY(-2px)",
  },
  transition: "all 0.3s ease",
}));

const LogoContainer = styled(Box)(({ theme }) => ({
  width: "50px",
  height: "50px",
  borderRadius: "8px",
  overflow: "hidden",
  // boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
  marginBottom: theme.spacing(2),
}));

const DownloadButton = styled(Button)(({ theme }) => ({
  background: "linear-gradient(45deg, #000 30%, #142023FF 90%)",
  borderRadius: 25,
  fontFamily: "gilroy",
  padding: "12px 30px",
  color: "white",
  fontWeight: 600,
  textTransform: "none",
  "&:hover": {
    background: "linear-gradient(45deg, #0B0B0BFF 30%, #262626FF 90%)",
    transform: "translateY(-2px)",
  },
  transition: "all 0.3s ease",
}));

const CourseSkeleton = () => (
  <Box
    sx={{
      borderRadius: "16px",
      backgroundColor: "#FFFFFF",
      width: "350px",
      height: "480px",
      border: "1px solid",
      borderColor: "grey.100",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    }}
  >
    <Skeleton
      variant="rectangular"
      height={150}
      sx={{ borderRadius: "16px 16px 0 0" }}
    />
    <Box sx={{ p: 2, flexGrow: 1 }}>
      <Skeleton variant="rectangular" width={50} height={50} sx={{ mb: 2 }} />
      <Skeleton variant="text" width="80%" height={40} sx={{ mb: 1 }} />
      <Skeleton variant="text" width="60%" height={20} sx={{ mb: 0.5 }} />
      <Skeleton variant="text" width="50%" height={20} sx={{ mb: 0.5 }} />
      <Skeleton variant="text" width="70%" height={20} />
    </Box>
    <Box sx={{ p: 2, display: "flex", gap: 1 }}>
      <Skeleton
        variant="rectangular"
        height={48}
        sx={{ flex: 1, borderRadius: 25 }}
      />
      <Skeleton
        variant="rectangular"
        height={48}
        sx={{ flex: 1, borderRadius: 25 }}
      />
    </Box>
  </Box>
);

const CourseDetails = ({
  formData,
  setFormData,
  handleSubmitContactForm,
  setType,
  type,
}) => {
  const [courses, setCourses] = useState([]);
  const sliderRef = useRef(null);
  const [openContactForm, setOpenContactForm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pdfUrl, setPdfUrl] = useState();

  const handleOpenForm = (id) => {
    setType("apply");
    setFormData((prev) => ({
      ...prev,
      type: "apply",
      courseId: id,
    }));
    setOpenContactForm(true);
  };

  const handleBrochureOpen = () => {
    setType("download");
    setFormData((prev) => ({ ...prev, type: "download" }));
    setOpenContactForm(true);
  };

  const handleCloseForm = () => {
    setOpenContactForm(false);
  };

  const handleDownload = (pdfUrl) => {
    if (pdfUrl) {
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = pdfUrl.split("/").pop() || "course.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("No PDF URL provided for download");
    }
  };

  useEffect(() => {
    const getAllCourses = async () => {
      setLoading(true);
      try {
        const response = await getAllCoursesApi();
        console.log("API Response:", response);
        if (response && Array.isArray(response.courses)) {
          setCourses(response.courses);
        } else if (Array.isArray(response)) {
          setCourses(response);
        } else {
          console.error("Unexpected response format:", response);
          setCourses([]);
        }
      } catch (error) {
        console.log("Error fetching courses:", error.message);
        setCourses([]);
      } finally {
        setLoading(false);
      }
    };
    getAllCourses();
  }, []);

console.log("courses",courses)

  const settings = {
    dots: true,
    infinite: courses.length > 3, // Adjust infinite based on 3 cards
    speed: 500,
    slidesToShow: 3, // Fixed to 3 cards per row
    slidesToScroll: 1,
    autoplay: courses.length > 1,
    autoplaySpeed: 3000,
    arrows: false,
    centerMode: false,
    variableWidth: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: Math.min(courses.length, 2), // Show 2 cards at 1024px
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: Math.min(courses.length, 2), // Show 2 cards at 768px
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1, // Show 1 card at 480px
        },
      },
    ],
    customPaging: () => (
      <Box sx={{ width: 8, height: 8, backgroundColor: "grey.500", borderRadius: "50%", mt: 1 }} />
    ),
    afterChange: () => {
      if (sliderRef.current) {
        sliderRef.current.slickGoTo(sliderRef.current.innerSlider.state.currentSlide);
      }
    },
  };

  return (
    <Container maxWidth="xl">
      <CarouselContainer sx={{ px: 4, pb: 8 }}>
        <Typography
          variant="h4"
          align="center"
          sx={{
            fontFamily: "Gilroy, sans-serif",
            fontWeight: 700,
            color: "#1A1A1A",
            mb: 4,
          }}
        >
          Explore Our Courses
        </Typography>
        {loading ? (
          <Box sx={{ display: "flex", gap: 4, justifyContent: "center", flexWrap: "wrap" }}>
            {[...Array(3)].map((_, index) => ( // Show 3 skeletons during loading
              <CourseSkeleton key={index} />
            ))}
          </Box>
        ) : courses.length > 0 ? (
          <Slider ref={sliderRef} {...settings}>
            {courses.map((item, index) => (
              <Box key={item.id || index} sx={{ px: 2 }}>
                <StyledCard elevation={0}>
                  <CardMedia
                    component="img"
                    height="150"
                    image={"uni1.webp"}
                    alt={`${item.organization?.organizationName || "Course"} image`}
                    sx={{ borderRadius: "16px 16px 0 0" }}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <LogoContainer>
                      <img
                        src={"/vacanziLogo.png"}
                        alt={`${item.organization?.organizationName || "Course"} logo`}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </LogoContainer>
                    <CourseTitle>{item.degree}</CourseTitle>
                    <InfoText>Duration: {item.durationInYears}</InfoText>
                    <InfoText>Fee: {item.courseFees}₹</InfoText>
                    <InfoText>University: {item.organization?.organizationName}</InfoText>
                  </CardContent>
                  <Box sx={{ p: 2, display: "flex", gap: 1,mb:4 }}>
                    <DownloadButton
                      onClick={() => {
                        handleBrochureOpen();
                        setPdfUrl(item?.brochure);
                      }}
                      fullWidth
                      endIcon={<SaveAltIcon sx={{ color: "#fff" }} />}
                    >
                      Brochure
                    </DownloadButton>
                    <ApplyButton onClick={() => handleOpenForm(item?._id)} fullWidth>
                      Apply Now
                    </ApplyButton>
                  </Box>
                </StyledCard>
              </Box>
            ))}
          </Slider>
        ) : (
          <Typography align="center" color="textSecondary">
            No courses available
          </Typography>
        )}
      </CarouselContainer>
      <CourseContactForm
        formData={formData}
        setFormData={setFormData}
        handleSubmitContactForm={handleSubmitContactForm}
        pdfUrl={pdfUrl}
        type={type}
        handleDownload={handleDownload}
        open={openContactForm}
        onClose={handleCloseForm}
        isHero={false}
      />
    </Container>
  );
};

export default CourseDetails;