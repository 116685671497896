import { useContext, useRef } from "react";
import { createContext } from "react";

const ScrollContext = createContext();

export const ScrollProvider = ({ children }) => {
  const whyVacanziRef = useRef(null);

  const scrollToWhyVacanzi = () => {
    if (whyVacanziRef.current) {
      whyVacanziRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <ScrollContext.Provider value={{ scrollToWhyVacanzi, whyVacanziRef }}>
      {children}
    </ScrollContext.Provider>
  );
};

export const useScroll = () => useContext(ScrollContext);