import React, { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";

// import Button from "@mui/material/Button";

import subscribeList from "../../../styles/subscribeList";
import {
  contactSales,
  getAllSubsciptionsOrgApi,
  getAllSubsCommonApi,
} from "../../../api/subscriptions";

import {

  CircularProgress,
  styled,
  Tabs,
  Tab,

} from "@mui/material";
// import StillNotSure from "./StillNotSure";
import SubscriptionsCard from "./SubscriptionsCard";


// import { ThreeDot } from "react-loading-indicators";

import Faq from "../../../components/LoginPageComponents/Faq";
import Advertisements from "../../../components/JobSeekerComponents/JobSeekerHomePageBanners/Advertisements";
import { useLocation, useNavigate } from "react-router-dom";
import SubscribeForm from "../SubscriptionStatus/SubscribeForm";
import ConfirmationDialogBox from "../SubscriptionStatus/ConfirmationDialogBox";
import ContactForm from "../../../components/EmployerComponents/EmployerLoginSignup/ContactPopup";
import PopupSuccess from "../../../utils/popup/PopupSuccess";
import ReleaseJobPostModel from "../SubscriptionStatus/ReleaseJobPostModel";

function  SubScriptionPage() {
  const [subscriptionData, setsubscriptionData] = useState();
  const [openDialog, setOpenDialog] = useState(false); // State for dialog visibility
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [subscriptionId, setSubscriptionId] = useState();
  const [openConfirmModel, setOpenConfirmModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);
    const [isContactFormVisible, setIsContactFormVisible] = useState(false);

  const token = localStorage.getItem("token");
  const orgId = localStorage.getItem("organizationId");
  const location = useLocation();

  const redirectUrl = localStorage.getItem("redirectUrl");
  const navigate = useNavigate();

  const handleOpenConfirmModel = () => {
    setOpenConfirmModel(true);
    localStorage.removeItem("redirectUrl");
  };
  const handleCloseConfiemModel = () => {
    localStorage.removeItem("redirectUrl");
    setOpenConfirmModel(false);
  };
  useEffect(() => {
    if (redirectUrl && token) {
      handleOpenConfirmModel();
    }
  }, [redirectUrl, token]);

  const handleCancelConfirmBox = () => {
    navigate("/search-candidates");
  };

  
    const handleSuccess = useCallback((message) => {
      setSuccessMessage(message);
      setSuccessOpen(true);
      setTimeout(() => {
        setSuccessOpen(false);
        setSuccessMessage("");
      }, 2000);
    }, []);
  
    const handleError = useCallback((message) => {
      setErrorMessage(message);
      setErrorOpen(true);
      setTimeout(() => {
        setErrorOpen(false);
        setErrorMessage("");
      }, 2000);
    }, []);

  const toggleContactForm = () => {

    setIsContactFormVisible(true);
  };

  const closeContactForm = () => {
  
    setIsContactFormVisible(false);}


  const StyledTabs = styled(Tabs)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #e0e0e0",
    "& .MuiTabs-indicator": {
      backgroundColor: "#1a90ff",
    },
  });
  const StyledTab = styled(Tab)({
    textTransform: "none",
    fontWeight: 600,
    fontSize: "1rem",
    "&.Mui-selected": {
      color: "#1a90ff",
    },
  });

  const handleBuyNow = async (tier, id) => {
    // setSelectedPlan(tier); // Set the selected plan for confirmation
    setSubscriptionId(id);
    setOpenDialog(true); // Open the confirmation dialog
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  

  const handleContactSales = async () => {
    const recruiterId = localStorage.getItem("recruiterId");

    try {
      const response = await contactSales(token, recruiterId);

        handleSuccess(response.message || " Thank you for contacting we will get back to you soon")
        setOpenDialog(false); // Close dialog after payment
      
    } catch (error) {
      console.log(error.message);

      handleError(error.message || "Failed To Contact , Please Try Again")
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false); // Close the dialog without doing anything
  };
  const isSignInPage = location.pathname.includes("/employerSignIn");
  const isSearchPage = location.pathname.includes("/search-candidates");  

  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        const subType = tabValue === 0 ? "database" : "jobposting"; // Set type based on tab
        let subscriptions = [];

        if (isSignInPage) {
          // Call only the common API for employerSignIn route
          const commonResponse = await getAllSubsCommonApi(subType);
          subscriptions = commonResponse.data;
        } else {
          // Call organization-specific API
          const orgResponse = await getAllSubsciptionsOrgApi(
            orgId,
            token,
            subType
          );
          subscriptions = orgResponse.data;
        }

        setsubscriptionData(subscriptions);
      } catch (error) {
        console.log(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriptions();
  }, [orgId, token, tabValue, isSignInPage]); // Include isSignInPage in dependency array

  console.log("issgnpage", isSignInPage);

  return (
    <>
{/* {!isSignInPage && !isSearchPage && ( */}
  {/* <Box sx={{ position: "relative", width: "100%", height: "auto" }}>
  
    <img
      src="/subbg.webp"
      alt="Description"
      style={{
        width: "100%",
        height: "100%",
        display: "block",
        borderRadius: "40px",
      }}
    />


    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "15%",
        transform: "translateY(-50%)",
        width: "30%", // Adjust width to fit content
      }}
    >
      <Grid container spacing={1}>
        {[...Array(10)].map((_, index) => (
          <Grid item xs={6} key={index}>
            <Box
              sx={{
                background: "rgba(186, 212, 212, 0.4)", // Same color with transparency
                px: "1px",
                width: "140px",
                py: "18px", // Reduced padding
                borderRadius: "16px", // Smaller border-radius
                border: "1px solid rgba(186, 212, 212, 0.6)", // Semi-transparent border
                textAlign: "center",
                backdropFilter: "blur(5px)", // Blur effect for a glassy look
              }}
            >
           
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  </Box> */}
{/* )} */}
     

      <Box sx={{ ...subscribeList.parentBox }}>
        {/* <Box sx={{ ...subscribeList.mainTitleBox }}>
        <Typography
          sx={{ color: "red", ...subscribeList.contentText, fontSize: 25 }}
        >
          {tabValue === 0 ? "Database Search" : "Job Posting"}
        </Typography>
        <Typography sx={{ ...subscribeList.contentText, fontSize: 30 }}>
          Attract Candidates
        </Typography>
        <Typography sx={{ ...subscribeList.contentText }}>
          with quick and easy plans on India's 1st video based job site
        </Typography>
      </Box> */}
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",

            display: "flex",
            justifyContent: "center",
          }}
        >
          <StyledTabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="subscription tabs"
          >
            <StyledTab label="Database Search" />
            <StyledTab label="Job Posting" />
          </StyledTabs>
        </Box>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh", // Full viewport height to center vertically
            }}
          >
            <CircularProgress size={24} color="inherit" />
          </Box>
        ) : (
          <SubscriptionsCard
            subscriptionData={subscriptionData}
            handleBuyNow={handleBuyNow}
            handleContactSales={handleContactSales}
            tabValue={tabValue}
            toggleContactForm={toggleContactForm}
            
          />
        )}

        {/* <StillNotSure handleContactSales={handleContactSales} /> */}
        {!isSignInPage &&  ! isSearchPage &&(
          <>
            <Advertisements />
            <Faq category="Employers" />
          </>
        )}

        {/* Confirm Payment Dialog */}
        {/* <Dialog open={openDialog} onClose={handleCloseDialog} handleContactSales>
        <DialogTitle sx={{ fontFamily: "poppins" }}>
          Confirm Payment
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ fontFamily: "poppins" }}>
            Are you sure you want to upgrade your plan to
            <span style={{ marginLeft: 3, color: "red" }}>
              {selectedPlan} ?
            </span>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            sx={{
              bgcolor: "#000000",
              color: "white",
              fontFamily: "Poppins",
              borderRadius: "0.7rem",
              textTransform: "none",
              ":hover": {
                bgcolor: "#000000",
                color: "white",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmPayment}
            sx={{
              bgcolor: "#000000",
              color: "white",
              borderRadius: "0.7rem",
              fontFamily: "Poppins",
              textTransform: "none",
              ":hover": {
                bgcolor: "#000000",
                color: "white",
              },
            }}
          >
            {loading ? (
              <ThreeDot
               
                color="#00C711FF"
                size="small"
                text=""
                textColor=""
              />
            ) : (
              "Confirm Payment"
            )}
          </Button>
        </DialogActions>
    </Dialog> */}
        <SubscribeForm
          open={openDialog}

          handleError={handleError}
          onClose={handleCloseDialog}
          subscriptionId={subscriptionId}
        />
            {isContactFormVisible && <ContactForm onClose={closeContactForm} handleError={handleError} handleSuccess={handleSuccess}/>}

             <PopupSuccess
        modelOpen={successOpen}
        onClose={() => setSuccessOpen(false)}
        message={successMessage}
      />
      <ReleaseJobPostModel
        modelOpen={errorOpen}
        onClose={() => setErrorOpen(false)}
        message={errorMessage}
      />
        <ConfirmationDialogBox
          open={openConfirmModel}
          onClose={handleCloseConfiemModel}
          cancelPlan={handleCancelConfirmBox}
        />
      </Box>
    </>
  );
}

export default SubScriptionPage
