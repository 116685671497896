import React, { useEffect, useState, useRef, useMemo, useCallback } from "react";
import { Box, Button, Typography, IconButton, Avatar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import baseURL from "../../../api/baseUrl";
import { truncateText } from "../../../utils/helperFunctions/Format";

const JobSeekerCounsellorChatWindow = ({
  selectedChatData,
  chatData,
  onMessageSent,
  handleAccordionChange,
  socket,
  senderImage,
}) => {
  const [input, setInput] = useState("");
  const [isSocketConnected, setIsSocketConnected] = useState(socket?.connected || false);
  const currentUserId = localStorage.getItem("userId");
  const messagesEndRef = useRef(null);
  const [messages, setMessages] = useState(chatData || []);
  const [isCreatingChat, setIsCreatingChat] = useState(false);
  const hasSentMessage = useRef(false);
  const [chatId, setChatId] = useState(selectedChatData?.chatId || null);
  const token = localStorage.getItem("authToken")

  // Fetch counsellor chat messages using chatId


  // Fetch chat messages
  const fetchChatMessages = useCallback(async (id) => {
    if (!id) return;

    try {
      const response = await fetch(`${baseURL}/chat/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) throw new Error("Failed to fetch messages");
      const data = await response.json();
      setMessages(data.messages || []);
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  }, [token]);
  const createChat = useCallback(async () => {
    if (!selectedChatData?._id || !currentUserId || isCreatingChat) return;

    setIsCreatingChat(true);
    try {
      const response = await fetch(`${baseURL}/chat/get/chatId`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          users: [
            { userId: selectedChatData._id, userModel: "User" },
            { userId: currentUserId, userModel: "User" },
          ],
        }),
      });

      if (!response.ok) throw new Error("Failed to create chat");

      const data = await response.json();
      const newChatId = data.chatId || data.chat?._id;
      if (newChatId) {
        setChatId(newChatId);
        await fetchChatMessages(newChatId);
        return newChatId;
      }
      return null;
    } catch (error) {
      console.error("Error creating chat:", error);
      return null;
    } finally {
      setIsCreatingChat(false);
    }
  }, [selectedChatData?._id, currentUserId, token,fetchChatMessages,isCreatingChat]);

  // Initialize chat and messages
  useEffect(() => {
    if (selectedChatData?.chatId) {
      setChatId(selectedChatData.chatId);
      fetchChatMessages(selectedChatData.chatId);
    } else if (selectedChatData?._id) {
      createChat();
    }
  }, [selectedChatData, fetchChatMessages, createChat]);

  // Socket connection and message handling
  useEffect(() => {
    if (!socket) return;

    setIsSocketConnected(socket.connected);

    const handleConnect = () => setIsSocketConnected(true);
    const handleDisconnect = () => setIsSocketConnected(false);
    const handleNewMessage = (newMessage) => {
      if (newMessage.chatId === chatId) {
        setMessages((prev) => {
          const safePrev = Array.isArray(prev) ? prev : [];
          const messageExists = safePrev.some(msg => msg._id === newMessage._id);
          return messageExists ? safePrev : [...safePrev, newMessage];
        });
      }
    };

    socket.on("connect", handleConnect);
    socket.on("disconnect", handleDisconnect);
    socket.on("newMessage", handleNewMessage);

    return () => {
      socket.off("connect", handleConnect);
      socket.off("disconnect", handleDisconnect);
      socket.off("newMessage", handleNewMessage);
    };
  }, [socket, chatId]);

  // Send message handler
  const handleSendMessage = useCallback(async () => {
    if (!input.trim() || !socket || hasSentMessage.current) return;

    const messageToSend = input;
    setInput("");
    hasSentMessage.current = true;

    let currentChatId = chatId;
    if (!currentChatId) {
      currentChatId = await createChat();
      if (!currentChatId) {
        setInput(messageToSend);
        hasSentMessage.current = false;
        return;
      }
    }

    const payload = {
      senderId: currentUserId,
      receivers: [{ userId: selectedChatData._id, userModel: "User" }],
      senderModel: "User",
      message: messageToSend,
      chatId: currentChatId,
      timestamp: new Date().toISOString(),
      _id: `temp-${Date.now()}`,
    };

    socket.emit("sendMessage", payload, (response) => {
      if (response?.error) {
        console.error("Error sending message:", response.error);
        setInput(messageToSend);
      } else {
        onMessageSent(payload);
      }
      hasSentMessage.current = false;
    });
  }, [input, socket, chatId, createChat, currentUserId, selectedChatData?._id, onMessageSent]);

  // Sort messages by timestamp
  const sortedChatData = useMemo(() => {
    return [...messages].sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
  }, [messages]);

  // Scroll to bottom
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [sortedChatData]);

  const receiver = selectedChatData || {};
  let isMine;
  const counsellorName = selectedChatData?.fullName || "Counsellor";
  const counsellorImage = selectedChatData?.profileImage || null;

  console.log("messages",messages)

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: { xl: "67vh", lg: "67vh", md: "67vh", sm: "67vh", xs: "67vh" },
        padding: 1,
        borderRadius: 2,
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        position: "relative",
      }}
    >
      <IconButton
        onClick={handleAccordionChange}
        sx={{
          position: "absolute",
          top: 8,
          right: 8,
          color: "#4caf50",
          "&:hover": { color: "#388e3c" },
        }}
      >
        <CloseIcon />
      </IconButton>

      {/* Counsellor Info */}
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <Avatar src={counsellorImage} sx={{ mr: 2, width: 40, height: 40 }} />
     <Box>
               <Typography
                 variant="h6"
                 sx={{
                   fontWeight: "bold",
                   color: "#000",
                   fontSize: "16px",
                   fontFamily: "satoshi",
                 }}
               >
                 { receiver?.fullName} - { truncateText(receiver?.headline,25)  }
               </Typography>
               <Typography
                 variant="body2"
                 sx={{
                   color: "#000",
                   fontSize: "18px",
                   fontFamily: "satoshi",
                   fontWeight: "bold",
                 }}
               >
                Counsellor
               </Typography>
             </Box>
      </Box>


      {/* Messages Area */}
      <Box
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          padding: 2,
          marginTop: 1,
          borderTop: "0.1px solid #e0e0e0",
        }}
      >
        <Box sx={{ minHeight: "100%", display: "flex", flexDirection: "column" }}>
          {sortedChatData.length === 0 ? (
            <Typography sx={{ textAlign: "center", color: "gray", fontFamily: "satoshi" }}>
              No messages yet. Start the conversation!
            </Typography>
          ) : (
            sortedChatData.map((msg) => {
               isMine = msg.senderId === currentUserId || msg.sender?._id === currentUserId;
              return (
                <Box key={msg._id} sx={{ marginBottom: 2, width: "100%" }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Avatar
                      src={isMine ? senderImage : counsellorImage}
                      sx={{ width: 40, height: 40, mr: 2 }}
                    />
                    <Box sx={{ width: "100%" }}>
                      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                        <Typography
                          variant="body2"
                          sx={{
                            fontWeight: "bold",
                            color: "#000",
                            fontFamily: "poppins",
                          }}
                        >
                          {isMine ? "You" : counsellorName}
                        </Typography>
                        <Typography
                          variant="caption"
                          sx={{ color: "#666", fontFamily: "poppins" }}
                        >
                          {new Date(msg.timestamp).toLocaleTimeString()}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          padding: "1px",
                          borderRadius: "8px",
                          wordBreak: "break-word",
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{ color: "#333", fontFamily: "poppins" }}
                        >
                          {msg.message}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              );
            })
          )}
          <div ref={messagesEndRef} />
        </Box>
      </Box>

      {/* Input Area */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "10px 8px",
          backgroundColor: "#f9f8f6",
          boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
          gap: 1,
          width: "100%",
          position: "relative",
          borderTop: "2px solid green",
        }}
      >
        <TextareaAutosize
          minRows={3}
          maxRows={6}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Write a message..."
          style={{
            width: "100%",
            resize: "none",
            padding: "10px",
            fontSize: "16px",
            borderRadius: "8px",
            backgroundColor: "#f5f3ef",
            fontFamily: "poppins",
            border: "none",
            outline: "none",
          }}
          disabled={!isSocketConnected}
        />
        <Button
          variant="contained"
          sx={{
            backgroundColor: input.length >= 2 ? "#000000" : "#000",
            color: "#fff",
            borderRadius: "20px",
            padding: "1px",
            textTransform: "none",
            position: "absolute",
            fontFamily: "poppins",
            bottom: "10px",
            right: "10px",
            "&:hover": {
              backgroundColor: input.length >= 2 ? "#000000" : "#000",
            },
          }}
          onClick={handleSendMessage}
          disabled={!isSocketConnected || input.length < 2}
        >
          Send
        </Button>
      </Box>
    </Box>
  );
};

export default JobSeekerCounsellorChatWindow;