import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Button } from "@mui/material";
import VideoShortsScroll from "./VideoShortsScroll";
import { getTopIndustryMasters } from "../../../api/common";
import baseURL from "../../../api/baseUrl";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForward";

const JobSeekerTopIndustries = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [videoLinks, setVideoLinks] = useState([]);
  const [industryData, setIndustryData] = useState([]);

  useEffect(() => {
    const fetchTopIndustryMasters = async () => {
      try {
        const response = await getTopIndustryMasters();
        if (response) {
          const subItems = response.flatMap((resItem) =>
            resItem.items.flatMap((item) => item.name)
          );
          const allCategories = subItems.filter(Boolean);
          setCategories(allCategories);

          if (allCategories.length > 0) {
            setSelectedCategory("");
            fetchVideosByIndustry("");
          }
        }
      } catch (error) {
        console.error("Error fetching top industry masters:", error.message);
      }
    };

    fetchTopIndustryMasters();
  }, []);

  const fetchVideosByIndustry = async (industry) => {
    try {
      let url = `${baseURL}/dashboard/jobs-by-industry`;

      if (industry) {
        url += `?industry=${industry}`;
      }

      const response = await fetch(url);
      const data = await response.json();
      
      setVideoLinks(data.data || []);
      setIndustryData(data?.data);
    } catch (error) {
      console.error("Error fetching videos by industry:", error);
    }
  };

  console.log("industry jobs",industryData)

  const handleFilterChange = (category) => {
    if (category === "") {
      setSelectedCategory("");
      fetchVideosByIndustry("");
    } else {
      setSelectedCategory(category);
      fetchVideosByIndustry(category);
    }
  };

  const thumbNails = videoLinks?.map((link) => link.thumbnail);

  return (
    <Box sx={{ padding: { xs: 2, sm: 4 }, backgroundColor: "#ffffff", mt: 6 }}>
      <Typography
        variant="h3"
        fontWeight="bold"
        textAlign="center"
        mb={4}
        sx={{
          fontSize: { xs: "1.5rem", sm: "2rem", fontFamily: "IBM plex sans" },
          letterSpacing: "0.5px",
        }}
      >
        Top Jobs By Industry
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: { xs: 0.5, md: 1 }, // Smaller gap on small screens
          justifyContent: "center",
          mb: 4,
          paddingInline: { xs: 1, md: 20 }, // Reduced padding on small screens
          alignItems: "center",
        }}
      >
        {/* "All Industries" Button */}
        <Button
          key="all-industries"
          variant={selectedCategory === "" ? "contained" : "outlined"}
          onClick={() => handleFilterChange("")}
          sx={{
            fontFamily: "Poppins",
            fontSize: { xs: 14, md: 18 }, // Smaller font on small screens
            textTransform: "none",
            borderRadius: 3,
            padding: { xs: "4px 8px", md: "6px 12px" }, // Smaller padding on small screens
            border: "2px solid rgba(151, 151, 160, 0.94)",
            backgroundColor: selectedCategory === "" ? "#000" : "transparent",
            color: selectedCategory === "" ? "#fff" : "#000",
            "&:hover": {
              backgroundColor: "#eaeaf5",
              color: "#000",
            },
          }}
        >
          All
        </Button>

        {/* Dynamic Industry Buttons */}
        {categories.map((category) => (
          <Button
            key={category}
            variant={selectedCategory === category ? "contained" : "outlined"}
            onClick={() => handleFilterChange(category)}
            sx={{
              fontFamily: "Poppins",
              fontSize: { xs: 14, md: 18 }, // Smaller font on small screens
              textTransform: "none",
              borderRadius: 3,
              padding: { xs: "4px 8px", md: "6px 12px" }, // Smaller padding on small screens
              border: "2px solid rgba(151, 151, 160, 0.94)",
              backgroundColor:
                selectedCategory === category ? "#000" : "transparent",
              color: selectedCategory === category ? "#fff" : "#000",
              "&:hover": {
                backgroundColor: "#eaeaf5",
                color: "#000",
              },
            }}
          >
            {category}
          </Button>
        ))}
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <VideoShortsScroll
          videos={videoLinks}
          thumbNails={thumbNails}
          industryData={industryData}
        />
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <Button
          endIcon={<ArrowForwardIosIcon />}
          variant="outlined"
          onClick={() => navigate("/findJobs")}
          sx={{
            color: "#ffffff",
            padding: "0.375rem 0.75rem",
            fontSize: "1rem",
            borderRadius: 3,
            backgroundColor: "#18181b",
            fontFamily: "IBM plex sans",
            textTransform: "none",
            borderColor: "#0e0593",
            "&:hover": {
              backgroundColor: "#21867a",
              color: "white",
            },
          }}
        >
          View All Jobs
        </Button>
      </Box>
    </Box>
  );
};

export default JobSeekerTopIndustries;
