import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Typography,
  Card,
  CardMedia,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { useParams } from "react-router-dom"; // To get articleId from URL
import { getArticleByIdApi } from "../../../../api/blogs"; // API import
import NewNavBar from "../../NavBar/NewNavbar";

// Styles for the details page
const styles = {
  root: {
    backgroundColor: "#F5F5FB",
    minHeight: "100vh",
    padding: "60px 0",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  image: {
    width: "100%",
    maxHeight: 400,
    objectFit: "contain",
    borderRadius: "12px",
    marginBottom: "24px",
  },
  title: {
    fontWeight: 700,
    fontSize: "32px",
    color: "#1A1A1A",
    fontFamily: '"Montserrat", sans-serif',
    marginBottom: "16px",
    textAlign: "center",
  },
  meta: {
    fontSize: "14px",
    color: "#666",
    marginBottom: "24px",
    textAlign: "center",
  },
  introduction: {
    fontSize: "16px",
    color: "#333",
    lineHeight: 1.6,
    marginBottom: "32px",
  },
  sectionCard: {
    borderRadius: "12px",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.08)",
    padding: "16px",
    marginBottom: "24px",
    backgroundColor: "#fff",
  },
  sectionHeading: {
    fontSize: "24px",
    fontWeight: 600,
    color: "#1A1A1A",
    marginBottom: "12px",
  },
  sectionParagraph: {
    fontSize: "16px",
    color: "#333",
    lineHeight: 1.6,
    marginBottom: "12px",
  },
  sectionListItem: {
    fontSize: "16px",
    color: "#333",
    lineHeight: 1.6,
  },
  conclusion: {
    fontSize: "16px",
    color: "#333",
    lineHeight: 1.6,
    marginTop: "32px",
    fontStyle: "italic",
  },
};

const JobSeekerArticleDetails = () => {
  const { articleId } = useParams(); // Get articleId from URL
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const data = await getArticleByIdApi(articleId);
        setBlog(data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch blog:", error);
        setLoading(false);
      }
    };
    fetchBlog();
  }, [articleId]);

  if (loading) {
    return (
      <>
        <NewNavBar />
        <Box sx={styles.root}>
          <Container maxWidth="md">
            <Typography>Loading blog...</Typography>
          </Container>
        </Box>
      </>
    );
  }

  if (!blog) {
    return (
      <>
        <NewNavBar />
        <Box sx={styles.root}>
          <Container maxWidth="md">
            <Typography>Blog not found.</Typography>
          </Container>
        </Box>
      </>
    );
  }

  return (
    <>
      <NewNavBar />
      <Box sx={styles.root}>
        <Container maxWidth="md">
          <CardMedia
            component="img"
            sx={styles.image}
            image={blog.imageUrl || "default-image.jpg"}
            alt={blog.title}
          />
          <Typography sx={styles.title}>{blog.title}</Typography>
          <Typography sx={styles.meta}>
            By {blog.metadata.author} |{" "}
            {new Date(blog.metadata.publishDate).toLocaleDateString()} | Tags:{" "}
            {blog.metadata.tags.join(", ")}
          </Typography>
          <Typography sx={styles.introduction}>{blog.introduction}</Typography>
          {blog.sections.map((section) => (
            <Card key={section._id} sx={styles.sectionCard}>
              <Typography sx={styles.sectionHeading}>{section.heading}</Typography>
              <Typography sx={styles.sectionParagraph}>
                {section.content.paragraph}
              </Typography>
              <List>
                {section.content.list.map((item, index) => (
                  <ListItem key={index} disableGutters>
                    <ListItemText
                      primary={item}
                      primaryTypographyProps={{ sx: styles.sectionListItem }}
                    />
                  </ListItem>
                ))}
              </List>
            </Card>
          ))}
          <Typography sx={styles.conclusion}>{blog.conclusion}</Typography>
        </Container>
      </Box>
    </>
  );
};

export default JobSeekerArticleDetails;