import React from "react";
import AllQuota from "../../../components/EmployerComponents/ManageQuotaComponents/AllQuota";


const ManageQuota = ({handleError,handleSuccess}) => { 


  return (
    <>
      <AllQuota handleError={handleError} handleSuccess={handleSuccess}/>


    </>
  );
};
export default ManageQuota;
