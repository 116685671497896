import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import { styled, keyframes } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { ThreeDot } from "react-loading-indicators";

// Keyframe animation for fade-in effect
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Styled components with conditional styling based on isHero
const StyledDialog = styled(Dialog)(({ theme, isHero }) => ({
  "& .MuiDialog-paper": {
    borderRadius: 16,
    boxShadow: "0 8px 32px rgba(0, 0, 0, 0.1)",
    overflow: "hidden",
    animation: `${fadeIn} 0.3s ease-out`,
    maxWidth: "500px",
    width: "100%",
    backgroundColor: isHero ? "rgba(255, 255, 255, 0.1)" : "#ffffff", // Transparent if isHero, white if not
    backdropFilter: isHero ? "blur(10px)" : "none", // Glassy effect only if isHero
    border: isHero ? "1px solid rgba(255, 255, 255, 0.2)" : "none", // Border only if isHero
  },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme, isHero }) => ({
  color: isHero ? "#fff" : "#000", // White if isHero, black if not
  padding: theme.spacing(2, 3),
  textAlign: "center",
  fontFamily: "Gilroy, sans-serif",
  position: "relative",
}));

const StyledDialogContent = styled(DialogContent)(({ theme, isHero }) => ({
  padding: theme.spacing(3),
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  backgroundColor: "transparent",
}));

const StyledButton = styled(Button)(({ theme, isHero }) => ({
  borderRadius: "8px",
  padding: theme.spacing(1.5, 4),
  textTransform: "none",
  fontWeight: 600,
  fontFamily: "Gilroy, sans-serif",
  backgroundColor: "#000", // Always black button
  color: "#fff",
  "&:hover": {
    backgroundColor: "#333",
    transform: "translateY(-2px)",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
  },
  "&:disabled": {
    backgroundColor: "#ccc",
    color: "#888",
  },
}));

const CourseContactForm = ({
  open,
  onClose,
  isHero,
  setType,
  formData,
  setFormData,
  handleSubmitContactForm,
  type,
  handleDownload,
  pdfUrl,
}) => {
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    fullName: "",
    email: "",
    phone: "",
    agreeTerms: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const handleCheckboxChange = (e) => {
    setAgreeTerms(e.target.checked);
    if (e.target.checked) {
      setErrors((prev) => ({
        ...prev,
        agreeTerms: "",
      }));
    }
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    // Full Name validation
    if (!formData.fullname.trim()) {
      newErrors.fullname = "Full Name is required";
      isValid = false;
    }

    // Email validation
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Please enter a valid email address";
      isValid = false;
    }

    // Phone validation
    if (!formData.phone.trim()) {
      newErrors.phone = "Mobile Number is required";
      isValid = false;
    } else if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = "Please enter a valid 10-digit phone number";
      isValid = false;
    }

    // Terms checkbox validation
    if (!agreeTerms) {
      newErrors.agreeTerms = "You must agree to the Terms and Conditions";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      setLoading(true);
      try {
        handleSubmitContactForm();
      } catch (error) {
        console.log(error.message);
      } finally {
        setLoading(false);
        onClose();
        if (type === "download") {
          handleDownload(pdfUrl);
        }
      }
    }
  };

  return (
    <StyledDialog open={open} onClose={onClose} isHero={isHero}>
      <StyledDialogTitle isHero={isHero}>
        <Typography variant="h5" sx={{ fontWeight: 700 }}>
          Contact Us
        </Typography>
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: isHero ? "#fff" : "#000", // White if isHero, black if not
          }}
        >
          <CloseIcon />
        </IconButton>
      </StyledDialogTitle>
      <StyledDialogContent isHero={isHero}>
        <Box>
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: 600,
              mb: 1,
              fontFamily: "Gilroy, sans-serif",
              color: isHero ? "#fff" : "#000", // White if isHero, black if not
            }}
          >
            Full Name
          </Typography>
          <TextField
            fullWidth
            name="fullname"
            value={formData?.fullname}
            onChange={handleInputChange}
            placeholder="Enter your full name"
            variant="outlined"
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
                fontFamily: "Gilroy, sans-serif",
                backgroundColor: isHero ? "#fff" : "#f5f5f5", // White if isHero, light gray if not
                "& fieldset": {
                  borderColor: isHero ? "rgba(0, 0, 0, 0.2)" : "#ccc",
                },
                "&:hover fieldset": {
                  borderColor: isHero ? "rgba(0, 0, 0, 0.4)" : "#999",
                },
                "&.Mui-focused fieldset": {
                  borderColor: isHero ? "#fff" : "#000",
                },
              },
              "& .MuiInputBase-input": {
                color: "#000", // Always black text in inputs
              },
            }}
            error={!!errors.fullname}
            helperText={errors.fullname}
          />
        </Box>
        <Box>
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: 600,
              mb: 1,
              fontFamily: "Gilroy, sans-serif",
              color: isHero ? "#fff" : "#000",
            }}
          >
            Mobile Number
          </Typography>
          <TextField
            fullWidth
            name="phone"
            value={formData?.phone}
            onChange={handleInputChange}
            placeholder="Enter your mobile number"
            variant="outlined"
            type="tel"
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
                fontFamily: "Gilroy, sans-serif",
                backgroundColor: isHero ? "#fff" : "#f5f5f5",
                "& fieldset": {
                  borderColor: isHero ? "rgba(0, 0, 0, 0.2)" : "#ccc",
                },
                "&:hover fieldset": {
                  borderColor: isHero ? "rgba(0, 0, 0, 0.4)" : "#999",
                },
                "&.Mui-focused fieldset": {
                  borderColor: isHero ? "#fff" : "#000",
                },
              },
              "& .MuiInputBase-input": {
                color: "#000",
              },
            }}
            error={!!errors.phone}
            helperText={errors.phone}
          />
        </Box>
        <Box>
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: 600,
              mb: 1,
              fontFamily: "Gilroy, sans-serif",
              color: isHero ? "#fff" : "#000",
            }}
          >
            Email
          </Typography>
          <TextField
            fullWidth
            name="email"
            value={formData?.email}
            onChange={handleInputChange}
            placeholder="Enter your email"
            variant="outlined"
            type="email"
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
                fontFamily: "Gilroy, sans-serif",
                backgroundColor: isHero ? "#fff" : "#f5f5f5",
                "& fieldset": {
                  borderColor: isHero ? "rgba(0, 0, 0, 0.2)" : "#ccc",
                },
                "&:hover fieldset": {
                  borderColor: isHero ? "rgba(0, 0, 0, 0.4)" : "#999",
                },
                "&.Mui-focused fieldset": {
                  borderColor: isHero ? "#fff" : "#000",
                },
              },
              "& .MuiInputBase-input": {
                color: "#000",
              },
            }}
            error={!!errors.email}
            helperText={errors.email}
          />
        </Box>
        <FormControlLabel
          control={
            <Checkbox
              checked={agreeTerms}
              onChange={handleCheckboxChange}
              sx={{
                color: isHero ? "#fff" : "#000",
                "&.Mui-checked": { color: isHero ? "#fff" : "#000" },
              }}
            />
          }
          label={
            <Typography
              variant="body2"
              sx={{
                color: isHero ? "#fff" : "#000",
                fontFamily: "Gilroy, sans-serif",
              }}
            >
              I agree to the{" "}
              <a
                href="/terms"
                style={{
                  color: isHero ? "#fff" : "#000",
                  textDecoration: "underline",
                }}
                onClick={(e) => e.preventDefault()}
              >
                Terms and Conditions
              </a>
            </Typography>
          }
        />
      </StyledDialogContent>
      <DialogActions sx={{ padding: 3, justifyContent: "center" }}>
        <StyledButton
          onClick={handleSubmit}
          disabled={!agreeTerms}
          isHero={isHero}
        >
          {loading ? (
            <ThreeDot color="#fff" size="small" text="" textColor="" />
          ) : (
            "Submit"
          )}
        </StyledButton>
      </DialogActions>
    </StyledDialog>
  );
};

export default CourseContactForm;
