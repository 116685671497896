import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Typography,
  List,
  ListItem,
  Divider,
  Button,
} from "@mui/material";
import baseURL from "../../../api/baseUrl";
// import ResponsiveAppBar from "../NavBar/NavBar";
import NewNavBar from "../NavBar/NewNavbar";
import { useNavigate } from "react-router-dom";

const JobSeekerPrivacyPolicy = () => {
  const [policy, setPolicy] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate()

  const title = "Privacy Policy";

  const fetchPolicy = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await fetch(
        `${baseURL}/policy/${encodeURIComponent(title)}`
      );
      if (!response.ok) {
        throw new Error(`Failed to fetch policy: ${response.statusText}`);
      }
      if (!response.headers.get("content-type")?.includes("application/json")) {
        throw new Error("Invalid response format: Expected JSON");
      }
      const data = await response.json();
      setPolicy(data);
    } catch (err) {
      console.error("Error fetching policy:", err);
      setError(err.message || "An unexpected error occurred.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPolicy();
  }, [title]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
        <Typography sx={{ ml: 2 }}>Loading policy...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            textAlign: "center",
          }}
        >
          <Typography variant="h6" color="error">
            {error}
          </Typography>
          <Button variant="contained" color="primary" onClick={fetchPolicy}>
            Retry
          </Button>
        </Box>
      </>
    );
  }

  return (
    <>
      <NewNavBar />
       <Typography sx={{backgroundColor: "#ffffff",pt:2,textDecoration:"underline",color:"#270FFFFF",cursor:"pointer"}} textAlign='center' onClick={()=>navigate('/')}>
                        Close Window
                        </Typography>
      <Box
        sx={{
          maxWidth: "800px",
          margin: "0 auto",
          padding: "2rem 1rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: 2, // Adds consistent spacing between elements
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          sx={{
            textAlign: "center",
            fontWeight: "bold",
            color: "#007BFF",
            width: "100%", // Ensures text spans full width
          }}
        >
          {policy.title}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            textAlign: "center",
            color: "text.secondary",
            mb: 3,
            width: "100%",
          }}
        >
          Effective Date: {new Date(policy.effectiveDate).toLocaleDateString()}
        </Typography>
        <Box
          sx={{
            width: "100%",
            textAlign: "justify", // Justifies text for a cleaner look
          }}
        >
          {policy.content.map((item) => {
            switch (item.type) {
              case "header":
                return (
                  <Typography
                    key={item._id}
                    variant="h2"
                    sx={{
                      mt: 4,
                      mb: 2,
                      fontWeight: "bold",
                      color: "#444",
                      textAlign: "center",
                    }}
                  >
                    {item.value}
                  </Typography>
                );
              case "paragraph":
                return (
                  <Typography
                    key={item._id}
                    variant="body1"
                    sx={{
                      mb: 2,
                      color: "#555",
                      textAlign: "justify",
                    }}
                  >
                    {item.value}
                  </Typography>
                );
              case "list":
                return (
                  <List
                    key={item._id}
                    sx={{
                      pl: 4,
                      mb: 2,
                      listStylePosition: "inside",
                      width: "100%",
                    }}
                  >
                    {item.value.map((listItem, index) => (
                      <ListItem
                        key={`${item._id}-${index}`}
                        sx={{ listStyleType: "disc" }}
                      >
                        {listItem}
                      </ListItem>
                    ))}
                  </List>
                );
              default:
                return null;
            }
          })}
        </Box>
        <Divider sx={{ mt: 3, mb: 3, width: "100%" }} />
        
      </Box>
    </>
  );
};

export default JobSeekerPrivacyPolicy;
