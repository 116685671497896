import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Typography, Box, Container, Card } from "@mui/material";
import { VerifyPayementApi } from "../../api/payment";
import { Cancel, CheckCircle } from "@mui/icons-material";

const PaymentCallback = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [paymentStatus, setPaymentStatus] = useState("loading");
  const [countdown, setCountdown] = useState(3)
  const hasVerifiedPayment = useRef(false)

  useEffect(() => {
    const verifyPayment = async () => {
      // Check if the API has already been called
      if (hasVerifiedPayment.current) return;
      const merchantTransactionId = localStorage.getItem("merchantTransactionId");
      if (!merchantTransactionId) {
        setPaymentStatus("failure");
        return;
      }

      try {
        hasVerifiedPayment.current = true; // Mark API as called
        const response = await VerifyPayementApi(token, merchantTransactionId);
        if (response?.payment?.status === "success") {
          setPaymentStatus("success");
        } else {
          setPaymentStatus("failure");
        }
      } catch (error) {
        setPaymentStatus("failure");
      } finally {
        localStorage.removeItem("merchantTransactionId");
      }
    };

    verifyPayment();
  }, [token])

  useEffect(() => {
    if (paymentStatus !== "loading") {
      const interval = setInterval(() => {
        setCountdown((prev) => {
          if (prev === 1) {
            clearInterval(interval);
            navigate("/employerTransactions");
          }
          return prev - 1;
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [paymentStatus, navigate]);

  return (
    <Container maxWidth="sm" sx={{ minHeight: "100vh", display: "flex", alignItems: "center", justifyContent: "center", py: 4 }}>
      <Card elevation={5} sx={{ width: "100%", borderRadius: 2, overflow: "hidden" }}>
        {paymentStatus === "loading" && (
          <Box sx={{ p: 5, display: "flex", flexDirection: "column", alignItems: "center" }}>
            <CircularProgress size={70} sx={{ mb: 4 }} />
            <Typography variant="h5" fontWeight="bold">Processing Payment</Typography>
            <Typography variant="body1" color="text.secondary" align="center">Please wait while we verify your transaction...</Typography>
          </Box>
        )}

        {paymentStatus === "success" && (
          <Box sx={{ pt: 4, pb: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
            <CheckCircle sx={{ fontSize: 80, color: "success.main", mb: 2 }} />
            <Typography variant="h5" fontWeight="bold" sx={{ fontFamily: "poppins" }}>Payment Successful!</Typography>
            <Typography variant="body2" color="text.secondary">Redirecting in {countdown} seconds...</Typography>
          </Box>
        )}

        {paymentStatus === "failure" && (
          <Box sx={{ pt: 4, pb: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Cancel sx={{ fontSize: 80, color: "error.main", mb: 2 }} />
            <Typography variant="h5" fontWeight="bold" sx={{ fontFamily: "poppins" }}>Payment Failed</Typography>
            <Typography variant="body2" color="text.secondary">Redirecting in {countdown} seconds...</Typography>
          </Box>
        )}
      </Card>
    </Container>
  );
};

export default PaymentCallback;
