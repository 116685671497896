import baseURL from "./baseUrl";

export const getOrgDetails = async (orgId, token) => {
  try {
    const response = await fetch(`${baseURL}/organization/${orgId}`, {
      method: "GET",
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error("network response was not ok");
    }
    return response.json();
  } catch (error) {
    console.log(error.message);
  }
};

export const editAccountDetails = async (orgId, token, formValues) => {
  try {
    const response = await fetch(`${baseURL}/organization/${orgId}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formValues),

    });
    const data = await response.json()
    if (!response.ok) {
      throw new Error(data.message||"Failed Update Company Profile");
    } 
    return data
  } catch (error) {
    console.log(error.message);
    throw error
  }
};

export const editOrgLogoApi = async (orgId, token, formData) => {
  try {
    const response = await fetch(`${baseURL}/organization/updateOrgMedia/${orgId}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        // Do NOT set Content-Type manually for FormData
      },
      body: formData, // Send FormData directly
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json(); // Return the response data
  } catch (error) {
    console.log(error.message);
    throw error; // Re-throw the error for handling in the component
  }
};


export const getRecruiterDetailsApi = async (recruiterId,token) => {
  try {
    const response = await fetch(`${baseURL}/recruiter/${recruiterId}`, {
      method: "GET",
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error("network response was not ok");
    }
    return response.json();
  } catch (error) {
    console.log(error.message);
  }
};