import React, { useState, useCallback, useEffect, useMemo } from "react";
import {
  Box,
  TextField,
  Button,
  InputAdornment,
  useTheme,
  useMediaQuery,
  Autocomplete,
  CircularProgress,
  Chip,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { useLoadScript } from "@react-google-maps/api";
import { getTopCitiesApi } from "../../../api/common";

const libraries = ["places"];

const SearchBar = ({
  title,
  locations,
  onTitleChange,
  setLocations,
  onSearch,
  area,
  setArea,
  sx,
}) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
    libraries,
  });

  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [isTopCity, setIsTopCity] = useState(true);
  const [cityOptions, setCityOptions] = useState([]);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const fetchSuggestions = useCallback(
    (input) => {
      if (!input || !window.google || !isLoaded) return;
      setLoading(true);
      const service = new window.google.maps.places.AutocompleteService();
      service.getPlacePredictions(
        {
          input,
          componentRestrictions: { country: "in" },
        },
        (predictions, status) => {
          if (status === "OK" && predictions) {
            const allOptions = predictions.map(
              (prediction) => prediction.description
            );
            setOptions(allOptions);
          } else {
            setOptions([]);
          }
          setLoading(false);
        }
      );
    },
    [isLoaded]
  );

  const fetchCities = useMemo(
    () => async (query) => {
      try {
        const response = await getTopCitiesApi(query, isTopCity);
        if (response && response.cities) {
          setCityOptions(response.cities.map((city) => city.name));
        }
      } catch (error) {
        console.log(error.message);
      }
    },
    [isTopCity]
  );

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    if (newInputValue) {
      setIsTopCity(false);
      fetchSuggestions(newInputValue);
      fetchCities(newInputValue);
    } else {
      setIsTopCity(true);
      setOptions([]);
      fetchCities("");
    }
  };

  const handleChange = (event, newValue) => {
    setLocations(newValue);
  };

  const handleFocus = () => {
    setIsTopCity(true);
    fetchCities("");
  };

  useEffect(() => {
    fetchCities(inputValue);
  }, [inputValue, fetchCities]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onSearch();
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        display="flex"
        flexDirection={isSmallScreen ? "column" : "row"}
         alignItems="center"
        backgroundColor="#ffffff"
        borderRadius="0.7rem"
        gap={isSmallScreen ? 1.5 : 1} // Slightly reduced gap for small screens
        boxShadow="0 6px 10px -1px rgba(0, 0, 0, 0.2), 0 4px 6px -1px rgba(0, 0, 0, 0.08)"
        sx={{ ...sx, padding: "10px 10px", border: "2px solid #423bfa" }}

      >
        {/* Skill Search Input */}
        <TextField
          variant="standard"
          placeholder="Search Designation / Skills / Companies"
          value={title}
          onChange={(e) => onTitleChange(e.target.value)}
          onKeyDown={handleKeyDown}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon style={{ color: "#a3a3a3" }} />
              </InputAdornment>
            ),
            disableUnderline: true,
          }}
          sx={{
            flex: 1,
            backgroundColor: "#ffffff",
            width: isSmallScreen ? "100%" : "50%", // Full width on small screens
            borderRadius: "50px",
            fontWeight: 500,
            padding: isSmallScreen ? "6px 12px" : "8px 20px", // Adjust padding
            "& .MuiInputBase-input": { padding: 0 },
          }}
        />

        {/* Location Search Input with Google Maps Autocomplete */}
        <Autocomplete
          sx={{ width: isSmallScreen ? "100%" : "50%", flex: 1 }} // Full width on small screens
          multiple
          freeSolo
          options={isTopCity ? cityOptions : options}
          value={locations || []}
          onFocus={handleFocus}
          onChange={handleChange}
          inputValue={inputValue}
          onInputChange={handleInputChange}
          ListboxProps={{
            style: {
              maxHeight: "200px",
              fontFamily: "Satoshi",
              fontWeight: "bold",
              overflowY: "auto",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
              "&::-webkit-scrollbar": { display: "none" },
            },
          }}
          renderTags={(selected, getTagProps) =>
            selected.map((option, index) => (
              <Chip
                key={option}
                label={option}
                {...getTagProps({ index })}
                sx={{
                  margin: "2px",
                  backgroundColor: "#fff",
                  color: "#000",
                  fontFamily: "poppins",
                  borderRadius: "10px",
                  "& .MuiChip-deleteIcon": { color: "#000" },
                }}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              placeholder={
                locations && locations.length > 0
                  ? ""
                  : "Search for Area Or City"
              }
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    <InputAdornment position="start">
                      <Box
                        sx={{
                          borderLeft: "2px solid #e0e0e0",
                          paddingLeft: "8px",
                        }}
                      >
                        <LocationOnOutlinedIcon style={{ color: "#a3a3a3" }} />
                      </Box>
                    </InputAdornment>
                    {params.InputProps.startAdornment}
                  </>
                ),
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
                disableUnderline: true,
              }}
              sx={{
                flex: 1,
                backgroundColor: "#ffffff",
                borderRadius: "50px",
                padding: isSmallScreen ? "6px 12px" : "8px 20px", // Adjust padding
                "& .MuiInputBase-root": {
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  minHeight: "40px",
                },
                "& .MuiInputBase-input": {
                  padding: "0 8px",
                  width: "auto",
                  flexGrow: 1,
                },
              }}
            />
          )}
          noOptionsText="No locations found"
          loading={loading}
        />

        {/* Search Button */}
        <Button
          variant="contained"
          onClick={onSearch}
          sx={{
            backgroundColor: "#18181b",
            color: "#ffffff",
            borderRadius: "0.7rem",
            textTransform: "none",
            padding: isSmallScreen ? "8px 16px" : "0.375rem 0.75rem", // Consistent padding
            width: isSmallScreen ? "100%" : "auto", // Full width on small screens
            marginLeft: isSmallScreen ? 0 : "8px",
            "&:hover": { backgroundColor: "#18181b" },
          }}
        >
          Search jobs
        </Button>
      </Box>
      <Typography
        sx={{
          color: "#fff",
          fontSize: isSmallScreen ? "32px" : "48px", // Smaller font on small screens
          fontWeight: "bold",
          fontFamily: "gilroy",
          textAlign: "center",
          mt: 2, // Add some margin-top for spacing
        }}
      >
        <span>
          India’s growing{" "}
          <b style={{ color: "#a7f3d0" }}>Next-Gen</b> job app
        </span>
      </Typography>
    </Box>
  );
};

export default SearchBar;