import React, { useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

import baseURL from "../../../api/baseUrl";
import { truncateText } from "../../../utils/helperFunctions/Format";

const JobSeekerEducationProfile = ({ userData, setUserData }) => {
  const [openModal, setOpenModal] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false); // State for delete confirmation dialog
  const [educationToDelete, setEducationToDelete] = useState(null); // State to store education ID to delete
  const [editedDegree, setEditedDegree] = useState("");
  const [editedInstitution, setEditedInstitution] = useState("");
  const [editedDegreeType, setEditedDegreeType] = useState("highSchoolDiploma");
  const [editedGrade, setEditedGrade] = useState("");
  const [editedGraduationYear, setEditedGraduationYear] = useState("");
  const [editedFieldOfStudy, setEditedFieldOfStudy] = useState("");
  const [editedStartDate, setEditedStartDate] = useState("");
  const [editedEndDate, setEditedEndDate] = useState("");
  const [editedCurrentlyPursuing, setEditedCurrentlyPursuing] = useState(false);
  const [isAdding, setIsAdding] = useState(true);
  const [educationId, setEducationId] = useState(null);

  // Function to get the latest education
  const getLatestEducation = () => {
    if (!userData?.educations || userData.educations.length === 0) return null;

    // Sort educations by endDate or graduationYear in descending order
    const sortedEducations = [...userData.educations].sort((a, b) => {
      const dateA = a.endDate
        ? new Date(a.endDate)
        : new Date(a.graduationYear);
      const dateB = b.endDate
        ? new Date(b.endDate)
        : new Date(b.graduationYear);
      return dateB - dateA; // Latest education first
    });

    return sortedEducations[0]; // Return the latest education
  };

  const latestEducation = getLatestEducation();

  const handleAddClick = () => {
    setIsAdding(true);
    setEducationId(null);
    setEditedDegree("");
    setEditedInstitution("");
    setEditedDegreeType("highSchoolDiploma");
    setEditedGrade("");
    setEditedGraduationYear("");
    setEditedFieldOfStudy("");
    setEditedStartDate("");
    setEditedEndDate("");
    setEditedCurrentlyPursuing(false);
    setOpenModal(true);
  };

  const handleEditClick = (education) => {
    setIsAdding(false);
    setEducationId(education._id);
    setEditedDegree(education.degree);
    setEditedInstitution(education.institution);
    setEditedDegreeType(education.degreeType || "highSchoolDiploma");
    setEditedGrade(education.grade);
    setEditedGraduationYear(education.graduationYear);
    setEditedFieldOfStudy(education.fieldOfStudy);
    setEditedStartDate(education.startDate?.split("T")[0] || "");
    setEditedEndDate(education.endDate?.split("T")[0] || "");
    setEditedCurrentlyPursuing(education.currentlyPursuing || false);
    setOpenModal(true);
  };

  // Open delete confirmation dialog
  const handleDeleteClick = (educationId) => {
    setEducationToDelete(educationId);
    setDeleteDialogOpen(true);
  };

  // Close delete confirmation dialog
  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
    setEducationToDelete(null);
  };

  // Handle confirmed deletion
  const handleDeleteConfirm = async () => {
    if (educationToDelete) {
      const token = localStorage.getItem("authToken")?.replace(/"/g, "");

      try {
        const response = await fetch(
          `${baseURL}/deleteEducation/${educationToDelete}`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          setUserData((prevState) => ({
            ...prevState,
            educations: prevState.educations.filter(
              (edu) => edu._id !== educationToDelete
            ),
          }));
        } else {
          console.error("Failed to delete education:", response.statusText);
        }
      } catch (error) {
        console.error("Error deleting education:", error);
      }
    }
    setDeleteDialogOpen(false);
    setEducationToDelete(null);
  };

  const degreeTypes = [
    { label: "High School Diploma", value: "highSchoolDiploma" },
    { label: "UG Education", value: "underGraduate" },
    { label: "PG Education", value: "postGraduate" },
    { label: "Doctorate (PhD)", value: "doctorate" },
    { label: "Technical Certification", value: "technicalCertification" },
    { label: "Vocational Training", value: "vocationalTraining" },
    { label: "GED (General Educational Development)", value: "ged" },
  ];

  const handleModalClose = () => {
    setOpenModal(false);
  };
  const handleSaveEducation = async () => {
    const token = localStorage.getItem("authToken")?.replace(/"/g, "");
    const educationData = {
      degree: editedDegree,
      institution: editedInstitution,
      degreeType: editedDegreeType,
      grade: editedGrade,
      graduationYear: editedGraduationYear,
      fieldOfStudy: editedFieldOfStudy,
      startDate: editedStartDate ? `${editedStartDate}T00:00:00.000Z` : null,
      endDate: editedCurrentlyPursuing
        ? ""
        : editedEndDate
        ? `${editedEndDate}T00:00:00.000Z`
        : null,
      currentlyPursuing: editedCurrentlyPursuing,
    };

    try {
      const method = isAdding ? "POST" : "PUT";
      const url = isAdding
        ? `${baseURL}/addEducation`
        : `${baseURL}/editEducation/${educationId}`;

      const response = await fetch(url, {
        method,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(educationData),
      });

      if (response.ok) {
        const responseData = await response.json();
        const newEducation = responseData.education;

        // Ensure setUserData is properly updating the state
        setUserData((prevUserData) => ({
          ...prevUserData,
          educations: isAdding
            ? [...(prevUserData?.educations || []), newEducation]
            : prevUserData?.educations.map((education) =>
                education._id === educationId ? newEducation : education
              ),
        }));

        handleModalClose();
      } else {
        console.error("Error saving education:", response.statusText);
      }
    } catch (error) {
      console.error("Error saving education:", error);
    }
  };

  return (
    <>
      <Paper
        sx={{
          margin: "0 auto",
          borderRadius: "15px",
          fontFamily: "'Inter', sans-serif",
          padding: "20px",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        }}
        elevation={0}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6" sx={{ fontWeight: "bold", color: "#333" }}>
              Education
            </Typography>
          </Box>
          <Button
            variant="text"
            color="primary"
            onClick={handleAddClick}
            sx={{
              cursor: "pointer",
              fontWeight: "bold",
              marginRight: 2,
              fontFamily: "IBM Plex Sans",
              color: "blue",
              padding: "8px 16px",
              textTransform: "none",
              borderRadius: "0.7rem",
              transition: "background-color 0.3s ease",
            }}
          >
            + Add
          </Button>
        </Box>

        <Paper
          sx={{
            padding: "20px",
            margin: "0 auto",
            display: "flex",
            justifyContent: "space-between",
            borderRadius: "15px",
            border: "1px solid #e0e0e0",
            backgroundColor: "#fafafa",
          }}
          variant="outlined"
          elevation={0}
        >
          <Typography sx={{ fontWeight: "bold", color: "#666" }}>
            Highest Qualification:{" "}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Typography sx={{ fontWeight: "bold", color: "#333" }}>
              {latestEducation
                ? `${
                    degreeTypes.find(
                      (type) => type.value === latestEducation.degreeType
                    )?.label ??
                    latestEducation.degreeType ??
                    "No Degree"
                  } | ${latestEducation.fieldOfStudy ?? "No Field of Study"}`
                : "No Qualification Added"}
            </Typography>
          </Box>
        </Paper>

        <Box
          sx={{
            backgroundColor: "#f9f9f9",
            marginTop: 2,
            borderRadius: "15px",
          }}
        >
          <Paper
            sx={{
              padding: "20px",
              margin: "0 auto",
              display: "flex",
              flexDirection: "column",
              borderRadius: "15px",
              gap: 3,
              border: "1px solid #e0e0e0",
            }}
            variant="outlined"
            elevation={0}
          >
            {userData?.educations && userData.educations.length > 0 ? (
              userData.educations.map((education, index) => (
                <Box
                  key={education._id || index}
                  sx={{
                    borderRadius: "10px",
                    padding: "15px",
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    backgroundColor: "#fff",
                    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.05)",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: "bold", color: "#333" }}
                      >
                        {truncateText(education.degree, 30)}
                        {education.fieldOfStudy &&
                          ` | ${education.fieldOfStudy}`}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", gap: 2 }}>
                      <Button
                        variant="text"
                        onClick={() => handleEditClick(education)}
                        sx={{
                          color: "#ffffff",
                          textTransform: "none",
                          backgroundColor: "#000000",
                          borderRadius: "0.7rem",
                          fontWeight: "bold",
                          padding: "0.375rem 0.75rem",
                          "&:hover": {
                            backgroundColor: "#000000",
                            color: "#ffffff",
                          },
                        }}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="text"
                        onClick={() => handleDeleteClick(education._id)}
                        sx={{
                          color: "#000000",
                          textTransform: "none",
                          backgroundColor: "#CACACA", // Fixed incorrect HEX code
                          borderRadius: "0.7rem",
                          fontWeight: "bold",
                          padding: "0.375rem 0.75rem",
                          "&:hover": {
                            backgroundColor: "#CACACA",
                            color: "#000000",
                          },
                        }}
                      >
                        Delete
                      </Button>
                    </Box>
                  </Box>
                  <Typography variant="body1" sx={{ color: "#555" }}>
                    <strong>Institution:</strong>{" "}
                    {truncateText(education.institution, 70)}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#555" }}>
                    <strong>Grade:</strong> {education.grade || "N/A"}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#555" }}>
                    <strong>Batch:</strong>{" "}
                    {education.graduationYear
                      ? new Date(education.graduationYear).getFullYear()
                      : "N/A"}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#555" }}>
                    <strong>Start Date:</strong>{" "}
                    {education.startDate
                      ? new Date(education.startDate).toLocaleDateString()
                      : "N/A"}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#555" }}>
                    <strong>End Date:</strong>{" "}
                    {education.endDate
                      ? new Date(education.endDate).toLocaleDateString()
                      : "N/A"}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#555" }}>
                    <strong>Currently Pursuing:</strong>{" "}
                    {education.currentlyPursuing ? "Yes" : "No"}
                  </Typography>
                </Box>
              ))
            ) : (
              <Typography
                sx={{
                  fontWeight: "bold",
                  textAlign: "center",
                  fontFamily: "IBM Plex Sans",
                }}
              >
                No education details added yet.
              </Typography>
            )}
          </Paper>
        </Box>

        {/* Add/Edit Education Dialog */}
        <Dialog
          open={openModal}
          onClose={handleModalClose}
          maxWidth="sm" // Increase the width of the dialog
          fullWidth // Ensure the dialog takes up the maximum width
          sx={{
            fontFamily: "'Inter', sans-serif",
            "& .MuiDialog-paper": {
              overflowY: "hidden",
              borderRadius: "15px",
            },
          }}
        >
          <DialogTitle
            sx={{ fontWeight: "bold", color: "#333", fontSize: "1.5rem" }}
          >
            {educationId ? "Edit Education" : "Add Education"}
          </DialogTitle>
          <DialogContent>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              {/* Institution Field */}
              {/* Institution Field */}
              <Box>
                <Typography variant="body1" sx={{ fontWeight: "bold", mb: 1 }}>
                  Institution
                </Typography>
                <TextField
                  fullWidth
                  value={editedInstitution || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Allow letters, numbers, spaces, periods, and hyphens; max 100 chars
                    if (
                      value === "" ||
                      (value.length <= 100 && /^[A-Za-z0-9\s.-]+$/.test(value))
                    ) {
                      setEditedInstitution(value);
                    }
                  }}
                  variant="outlined"
                  placeholder="e.g., Harvard University, IIT Delhi"
                  error={
                    editedInstitution &&
                    !/^[A-Za-z0-9\s.-]+$/.test(editedInstitution)
                  }
                  helperText={
                    editedInstitution &&
                    !/^[A-Za-z0-9\s.,-]+$/.test(editedInstitution)
                      ? "Use letters, numbers, spaces, periods, or hyphens only"
                      : "Enter your institution name"
                  }
                  inputProps={{
                    maxLength: 100, // Reasonable limit for institution names
                  }}
                  sx={{
                    borderRadius: "8px",
                    "& .MuiOutlinedInput-root": { borderRadius: "8px" },
                  }}
                />
              </Box>

              {/* Degree Field */}
              <Box>
                <Typography variant="body1" sx={{ fontWeight: "bold", mb: 1 }}>
                  Degree
                </Typography>
                <TextField
                  fullWidth
                  value={editedDegree || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Allow letters, numbers, spaces, periods, forward slashes, parentheses, and commas; max 50 chars
                    if (
                      value === "" ||
                      (value.length <= 50 &&
                        /^[A-Za-z0-9\s.,()/]+$/.test(value))
                    ) {
                      setEditedDegree(value);
                    }
                  }}
                  variant="outlined"
                  placeholder="e.g., B.Tech, M.S., Ph.D., B.Sc./M.Sc."
                  inputProps={{
                    maxLength: 50, // Reasonable limit for degree names
                  }}
                  sx={{
                    borderRadius: "8px",
                    "& .MuiOutlinedInput-root": { borderRadius: "8px" },
                  }}
                />
              </Box>

              {/* Degree Type Field */}
              <Box>
                <Typography variant="body1" sx={{ fontWeight: "bold", mb: 1 }}>
                  Degree Type
                </Typography>
                <TextField
                  fullWidth
                  select
                  value={editedDegreeType || ""}
                  onChange={(e) => setEditedDegreeType(e.target.value)}
                  variant="outlined"
                  sx={{
                    borderRadius: "8px",
                    "& .MuiOutlinedInput-root": { borderRadius: "8px" },
                  }}
                >
                  {degreeTypes.map((degree) => (
                    <MenuItem key={degree.value} value={degree.value}>
                      {degree.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>

              {/* Field of Study Field */}
              <Box>
                <Typography variant="body1" sx={{ fontWeight: "bold", mb: 1 }}>
                  Field of Study
                </Typography>
                <TextField
                  fullWidth
                  value={editedFieldOfStudy || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Allow letters, spaces, and hyphens only; empty string is okay
                    if (value === "" || /^[A-Za-z\s-]+$/.test(value)) {
                      setEditedFieldOfStudy(value);
                    }
                  }}
                  variant="outlined"
                  placeholder="e.g., Computer Science, Bio-Technology" // Added placeholder
                  error={
                    editedFieldOfStudy &&
                    !/^[A-Za-z\s-]+$/.test(editedFieldOfStudy)
                  }
                  helperText={
                    editedFieldOfStudy &&
                    !/^[A-Za-z\s-]+$/.test(editedFieldOfStudy)
                      ? "Use only letters, spaces, and hyphens"
                      : "Enter your field of study"
                  }
                  inputProps={{
                    maxLength: 50, // Reasonable limit for field of study
                  }}
                  sx={{
                    borderRadius: "8px",
                    "& .MuiOutlinedInput-root": { borderRadius: "8px" },
                  }}
                />
              </Box>

              {/* Grade Field */}
              <Box>
                <Typography variant="body1" sx={{ fontWeight: "bold", mb: 1 }}>
                  Grade
                </Typography>
                <TextField
                  fullWidth
                  value={editedGrade || ""}
                  onChange={(e) => {
                    const value = e.target.value.toUpperCase();
                    // Allow:
                    // 1. Empty string
                    // 2. Letter grades (A-F with optional +/-)
                    // 3. Numbers (0-100, with optional decimal)
                    if (
                      value === "" ||
                      /^[A-F][+-]?$/.test(value) || // Letter grade format
                      (/^\d*\.?\d*$/.test(value) && // Number format (including decimals)
                        (value === "" ||
                          (Number(value) >= 0 && Number(value) <= 100)))
                    ) {
                      setEditedGrade(value);
                    }
                  }}
                  variant="outlined"
                  placeholder="e.g., A+, 85, 92.5" // Updated placeholder with examples
                  error={
                    editedGrade &&
                    !(
                      /^[A-F][+-]?$/.test(editedGrade) || // Valid letter grade
                      (/^\d*\.?\d*$/.test(editedGrade) && // Valid number format
                        Number(editedGrade) >= 0 &&
                        Number(editedGrade) <= 100)
                    )
                  }
                  helperText={
                    editedGrade &&
                    !(
                      /^[A-F][+-]?$/.test(editedGrade) || // A-F with optional +/-
                      (/^\d*\.?\d*%?$/.test(editedGrade) && // Number with optional decimal and optional %
                        Number(editedGrade.replace("%", "")) >= 0 && // Remove % and check range
                        Number(editedGrade.replace("%", "")) <= 100)
                    )
                      ? "Enter a valid grade (A-F with +/- or 0-100 with optional %)"
                      : "Enter your grade (letter or percentage)"
                  }
                  inputProps={{
                    maxLength: 5, // Increased to allow "100.0" or similar
                  }}
                  sx={{
                    borderRadius: "8px",
                    "& .MuiOutlinedInput-root": { borderRadius: "8px" },
                  }}
                />
              </Box>

              {/* Graduation Year Field */}
              <Box>
                <Typography variant="body1" sx={{ fontWeight: "bold", mb: 1 }}>
                  Graduation Year
                </Typography>
                <TextField
                  fullWidth
                  value={
                    editedGraduationYear
                      ? typeof editedGraduationYear === "string" &&
                        editedGraduationYear.includes("-")
                        ? editedGraduationYear.split("-")[0] // Extract year from ISO format
                        : editedGraduationYear
                      : ""
                  }
                  onChange={(e) => {
                    const value = e.target.value;
                    // Only allow numbers and limit to 4 digits
                    if (/^\d{0,4}$/.test(value)) {
                      setEditedGraduationYear(value);
                    }
                  }}
                  inputProps={{
                    maxLength: 4, // Limits input to 4 characters
                    inputMode: "numeric", // Shows numeric keyboard on mobile
                    pattern: "[0-9]*", // Helps with HTML5 validation
                  }}
                  placeholder="YYYY"
                  variant="outlined"
                  sx={{
                    borderRadius: "8px",
                    "& .MuiOutlinedInput-root": { borderRadius: "8px" },
                  }}
                />
              </Box>

              {/* Start Date Field */}
              <Box>
                <Typography variant="body1" sx={{ fontWeight: "bold", mb: 1 }}>
                  Start Date
                </Typography>
                <TextField
                  fullWidth
                  type="date"
                  value={editedStartDate || ""}
                  onChange={(e) => setEditedStartDate(e.target.value)}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  // Prevent typing
                  inputProps={{
                    style: { cursor: "pointer" }, // Indicates it's clickable
                  }}
                  // Prevent keyboard popup on mobile
                  onKeyDown={(e) => e.preventDefault()}
                  // Optional: Prevent paste
                  onPaste={(e) => e.preventDefault()}
                  sx={{
                    borderRadius: "8px",
                    "& .MuiOutlinedInput-root": { borderRadius: "8px" },
                    // Ensure the field still looks interactive
                    "& .MuiOutlinedInput-input": { cursor: "pointer" },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "inherit",
                      },
                  }}
                />
              </Box>

              {/* End Date Field */}
              <Box>
                <Typography variant="body1" sx={{ fontWeight: "bold", mb: 1 }}>
                  End Date
                </Typography>
                <TextField
                  fullWidth
                  type="date"
                  value={editedEndDate || ""}
                  onChange={(e) => setEditedEndDate(e.target.value)}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  disabled={editedCurrentlyPursuing}
                  // Prevent typing
                  inputProps={{
                    style: { cursor: "pointer" }, // Indicates it's clickable
                  }}
                  // Prevent keyboard popup on mobile
                  onKeyDown={(e) => e.preventDefault()}
                  // Optional: Prevent paste
                  onPaste={(e) => e.preventDefault()}
                  sx={{
                    borderRadius: "8px",
                    "& .MuiOutlinedInput-root": { borderRadius: "8px" },
                    // Ensure the field still looks interactive
                    "& .MuiOutlinedInput-input": { cursor: "pointer" },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "inherit",
                      },
                  }}
                />
              </Box>

              {/* Currently Pursuing Checkbox */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={editedCurrentlyPursuing}
                    onChange={(e) =>
                      setEditedCurrentlyPursuing(e.target.checked)
                    }
                    sx={{
                      color: "#423BFA",
                      "&.Mui-checked": { color: "#423BFA" },
                    }}
                  />
                }
                label="Currently Pursuing"
                sx={{ color: "#555", mt: 1 }}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                borderRadius: "0.7rem",
                textTransform: "none",
                fontWeight: "bold",
                color: "#ffffff",
                backgroundColor: "#000000",
                "&:hover": {
                  backgroundColor: "#000000",
                },
              }}
              onClick={handleModalClose}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              sx={{
                borderRadius: "0.7rem",
                textTransform: "none",
                background: "#423BFA",
                fontWeight: "bold",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#3127d6",
                },
              }}
              variant="contained"
              onClick={handleSaveEducation}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>

        {/* Delete Confirmation Dialog */}
        <Dialog
          open={deleteDialogOpen}
          onClose={handleDeleteDialogClose}
          maxWidth="sm"
          fullWidth
          sx={{
            "& .MuiDialog-paper": {
              borderRadius: "15px",
              fontFamily: "'Inter', sans-serif",
            },
          }}
        >
          <DialogTitle
            sx={{ fontFamily: "'Inter', sans-serif", fontWeight: "bold" }}
          >
            <b>Confirm Deletion</b>
          </DialogTitle>
          <DialogContent>
            <Typography sx={{ fontFamily: "'Inter', sans-serif" }}>
              Are you sure you want to delete this education? This action cannot
              be undone.
            </Typography>
          </DialogContent>
          <DialogActions sx={{ padding: 2 }}>
            <Button
              onClick={handleDeleteDialogClose}
              sx={{
                borderRadius: "0.7rem",
                textTransform: "none",
                fontWeight: "bold",
                fontFamily: "'Inter', sans-serif",
                backgroundColor: "black",
                color: "white",
                padding: "8px 16px",
                "&:hover": {
                  backgroundColor: "#333",
                },
              }}
              variant="contained"
            >
              Cancel
            </Button>
            <Button
              onClick={handleDeleteConfirm}
              sx={{
                borderRadius: "0.7rem",
                textTransform: "none",
                fontWeight: "bold",
                fontFamily: "'Inter', sans-serif",
                backgroundColor: "#423bfa",
                color: "white",
                padding: "8px 16px",
                "&:hover": {
                  backgroundColor: "#362fd9",
                },
              }}
              variant="contained"
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </>
  );
};

export default JobSeekerEducationProfile;
