import React from "react";
import { Box, Button, Typography } from "@mui/material";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import PsychologyIcon from "@mui/icons-material/Psychology";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";

import googlePlay from "../../../assets/images/googlePlay.png";
import appStore from "../../../assets/images/appStore.png";

const WhyVacanzi = () => {

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        paddingTop: 2,
        backgroundColor: "#ffffff",
        width: "100vw",
        mt: 6,
      }}
    >
      {/* <Typography
        variant="h3"
        sx={{ fontWeight: "bold", fontFamily: "IBM plex sans", pb: 2 }}
      >
        Why Vacanzi ?
      </Typography> */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingBottom: 3,
          width: "100%",
        }}
      >
        {/* Left Box - Video */}
        <Box
          sx={{
            flex: 1, // Takes equal width as the right box
            minWidth: "50%",
            display: "flex",
            justifyContent: "center",
            flexDirection:"column",
            alignItems: "center",
            background: "linear-gradient(to right, #f6fdfb, #ecfefa)",
            p: 5,
            height: "650px",
            gap:2,
          }}
        >
          <img
            src="/whyVacanziGif.gif"
            alt="Why Vacanzi"
            style={{
              width: "90%", // Ensures full width usage
              height: "80%",
              objectFit: "cover",
              mb:"10px",
              borderRadius: "12px",
            }}
          />
    <Box
     sx={{
       display: "flex",
    
       flexDirection: "column", // Stack items vertically
       alignItems: "center", // Align items to the left
       justifyContent: "space-around",
       gap: 2,
     }}
   >
     <Button
       variant="contained"
       sx={{
         borderRadius: "0.7rem",
         textTransform: "none",
         fontFamily: "IBM Plex Sans",
       
         backgroundColor: "#000",
         "&:hover": {
           backgroundColor: "#000",
         },
       }}
     >
       Download The App Now
     </Button>
   
     <Box
       sx={{
         display: "flex",
         gap: 2,
         justifyContent: "center",
         alignItems: "center",
       }}
     >
       <img
         src={appStore}
         alt="App Store"
         style={{ width: "150px" }}
       />
       <img
         src={googlePlay}
         alt="Google Play"
         style={{ width: "150px" }}
       />
     </Box>
   
     {/* QR Code below the buttons */}
     {/* <Box
       sx={{
         display: "flex",
         justifyContent: "center",
         alignItems: "center",
         marginTop: "1.5rem", // Space between the buttons and QR code
       }}
     >
       <img
         src={"./qrEmp.png"} // Replace with the actual path to your QR code image
         alt="QR Code"
         style={{ width: "100px", height: "100px" }} // Adjust size as needed
       />
     </Box> */}
   </Box>
        </Box>

        {/* Right Box - Employers Section */}
        <Box
          sx={{
            flex: 1,
            minWidth: "50%",
            display: "flex",
            flexDirection: "column",
            background: "linear-gradient(to right,#f0eeff, #ffffff )",
            height: "650px",
            paddingLeft: "8rem", // Moves content to the right
          }}
        >
          <Typography
            variant="h3"
            textAlign="start"
            sx={{
              ml: 3,
              fontWeight: "bold",
              fontFamily: "IBM Plex Sans",
              color: "#000",
              mt: 3,
              mb:-15,
            }}
          >
            For Employers
          </Typography>
          <Box
            sx={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              gap: "1rem",
              padding: "2rem",
              paddingLeft: "2rem", // Moves content inside to the right
              textAlign: "start",
              fontFamily: "IBM Plex Sans, sans-serif",
            }}
          >
            {/* AI-Driven Hiring */}
            <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
              <PsychologyIcon sx={{ color: "#000" }} />
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  fontFamily: "IBM Plex Sans",
                  color: "#000",
                }}
              >
                Beyond Resumes
              </Typography>
            </Box>
            <Typography
              variant="body1"
              sx={{
                fontFamily: "IBM Plex Sans",
                textAlign: "left",
                color: "#000",
              }}
            >
              See personality & skills instantly
            </Typography>

            {/* Smart Hiring */}
            <Box
                sx={{ display: "flex", alignItems: "center", gap: "1rem", mt: 2 }}
            >
              <RocketLaunchIcon sx={{ color: "#000" }} />
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  fontFamily: "IBM Plex Sans",
                  color: "#000",
                }}
              >
                Faster Hiring
              </Typography>
            </Box>
            <Typography
              variant="body1"
              sx={{
                fontFamily: "IBM Plex Sans",
                textAlign: "left",
                color: "#000",
              }}
            >
              Shortlist in seconds.
            </Typography>

            {/* Post Jobs for Free */}
            <Box
              sx={{ display: "flex", alignItems: "center", gap: "1rem", mt: 2 }}
            >
              <BusinessCenterIcon sx={{ color: "#000" }} />
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  fontFamily: "IBM Plex Sans",
                  color: "#000",
                }}
              >
                Perfect Matches
              </Typography>
            </Box>
            <Typography
              variant="body1"
              sx={{
                fontFamily: "IBM Plex Sans",
                textAlign: "left",
                color: "#000",
              }}
            >
              Hire culture-fit talent.
            </Typography>

            {/* Post a Job Button */}
   
          </Box>
        </Box>

      </Box>
    </Box>
  );
};

export default WhyVacanzi;
