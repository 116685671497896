import React, { useState, useRef } from "react";
import {
  Box,
  TextField,
  Chip,
  AccordionDetails,
  Typography,
  Accordion,
  AccordionSummary,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Keywords = ({ formData, setFormData }) => {
  const [keywordInput, setKeywordInput] = useState("");
  const [excludeKeywordInput, setExcludeKeywordInput] = useState("");

  const keywordInputRef = useRef(null);
  const excludeKeywordInputRef = useRef(null);

  const handleAddKeyword = () => {
    const trimmedKeyword = keywordInput.trim();
    if (!trimmedKeyword) return;

    if (!formData.keyword.includes(trimmedKeyword)) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        keyword: [...prevFormData.keyword, trimmedKeyword],
      }));
    }

    setKeywordInput(""); // Clear input
    setTimeout(() => keywordInputRef.current?.focus(), 0); // Retain focus
  };

  const handleRemoveKeyword = (option) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      keyword: prevFormData.keyword.filter((item) => item !== option),
    }));
  };

  const handleAddExcludeKeyword = () => {
    const trimmedKeyword = excludeKeywordInput.trim();
    if (!trimmedKeyword) return;

    if (!formData.shouldntHave.includes(trimmedKeyword)) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        shouldntHave: [...prevFormData.shouldntHave, trimmedKeyword],
      }));
    }

    setExcludeKeywordInput(""); // Clear input
    setTimeout(() => excludeKeywordInputRef.current?.focus(), 0); // Retain focus
  };

  const handleRemoveExcludeKeyword = (option) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      shouldntHave: prevFormData.shouldntHave.filter((item) => item !== option),
    }));
  };

  const handleKeywordKeyDown = (e) => {
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault();
      handleAddKeyword();
    }
  };

  const handleExcludeKeywordKeyDown = (e) => {
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault();
      handleAddExcludeKeyword();
    }
  };

  return (
    <Accordion
      defaultExpanded
      sx={{
        boxShadow: "none",
        backgroundColor: "transparent",
        "&:before": { display: "none" },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: "#666", opacity: 0.7 }} />}
        sx={{
          padding: "8px 0",
          minHeight: "40px",
          "& .MuiAccordionSummary-content": { margin: "0" },
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "bold",
            fontFamily: "IBM Plex Sans, sans-serif",
            color: "#333",
          }}
        >
          Keywords
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: "0 0 16px 0", backgroundColor: "transparent" }}>
        {/* Included Keywords Input Field */}
        <TextField
          fullWidth
          placeholder="Included Keywords"
          value={keywordInput}
          onChange={(e) => setKeywordInput(e.target.value)}
          onKeyDown={handleKeywordKeyDown}
          size="small"
          inputRef={keywordInputRef}
          sx={{ marginBottom: 1,}}
          InputProps={{
            startAdornment: (
              <Box sx={{  gap: 0.5,py:0.5}}>
                {formData?.keyword?.map((option) => (
                  <Chip
                    key={option}
                    label={option}
                    onDelete={() => handleRemoveKeyword(option)}
                    sx={{
                      backgroundColor: "#423bfa",
                      color: "#fff",
                      height: "24px",
                      fontFamily:"poppins",
                      fontSize: "12px",
                      mr:0.5
              
                    }}
                  />
                ))}
                <Box sx={{ width: "8px" }} /> {/* Adds a small gap between chips and input */}
              </Box>
            ),
          }} 
        />

        {/* Excluded Keywords Input Field */}
        <TextField
          fullWidth
          placeholder="Excluded Keywords"
          value={excludeKeywordInput}
          onChange={(e) => setExcludeKeywordInput(e.target.value)}
          onKeyDown={handleExcludeKeywordKeyDown}
          size="small"
          inputRef={excludeKeywordInputRef}
          sx={{ marginTop: 1 }}
          InputProps={{
            startAdornment: (
              <Box sx={{gap: 0.5 ,py:0.5}}>
                {formData.shouldntHave.map((option) => (
                  <Chip
                    key={option}
                    label={option}
                    onDelete={() => handleRemoveExcludeKeyword(option)}
                    sx={{
                      backgroundColor: "#423bfa",
                      color: "#fff",
                      height: "24px",
                      fontSize: "12px",
                      fontFamily:"poppins",
                      mr:0.5
                    }}
                  />
                ))}
              </Box>
            ),
          }}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default Keywords;
