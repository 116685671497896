import React, { useCallback, useState } from 'react';
import { Container, Box, TextField, Button, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import baseURL from '../../../api/baseUrl';

export default function RegisterForm() {
  const navigate = useNavigate();
  const location = useLocation();

  // State for form data
  const [formData, setFormData] = useState({
    username: location.state?.signupName || '',
    email: location.state?.signupEmail || '',
    phone: location.state?.loginPhone || '',
  });

  const [errors, setErrors] = useState({}); // For form validation errors

  const handleFieldChange = useCallback((field, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  }, []);

  // Validate form inputs
  const validateForm = () => {
    const newErrors = {};
    if (!formData.username) {
      newErrors.username = 'Username is required';
    }
    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Invalid email address';
    }
    return newErrors;
  };

  const callApiToSaveData = async () => {
    const requestBody = {
      phone: formData.phone,
      email: formData.email,
      fullName: formData.username,
    };

    try {
      const response = await fetch(`${baseURL}/js_register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();

      if (response.ok) {
        const userId = data.user._id;
        const authToken = data.token;

        // Store the token in localStorage
        localStorage.setItem('authToken', authToken);
        localStorage.setItem('userId', userId);

        // Navigate to the next page with user details
        navigate('/jobSeekerRegestrationProfile', {
          state: {
            signupEmail: formData.email,
            signupName: formData.username,
            loginPhone: formData.phone,
          },
        });
      } else {
        console.log('Registration failed:', data);
      }
    } catch (error) {
      console.log('Registration Error', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form fields
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    await callApiToSaveData();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: '#e8f5e9',
        fontFamily: "'Roboto', sans-serif",
        padding: 4,
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: '#fff',
          padding: 4,
          borderRadius: 3,
          boxShadow: 3,
        }}
      >
        <Box sx={{ width: '50%', paddingRight: 2 }}>
          <Typography variant="h4" gutterBottom color="primary" align="center">
            Create Your Account
          </Typography>

          <TextField
            fullWidth
            label="Full Name"
            variant="outlined"
            margin="normal"
            value={formData.username}
            onChange={(e) => handleFieldChange('username', e.target.value)}
            error={Boolean(errors.username)}
            helperText={errors.username}
            sx={{
              marginBottom: 2,
              '& .MuiInputLabel-root': { color: '#6c757d' },
              '& .MuiOutlinedInput-root': {
                borderRadius: 4,
                '& fieldset': { borderColor: '#6c757d' },
              },
            }}
          />

          <TextField
            fullWidth
            label="Email Address"
            variant="outlined"
            margin="normal"
            value={formData.email}
            onChange={(e) => handleFieldChange('email', e.target.value)}
            error={Boolean(errors.email)}
            helperText={errors.email}
            sx={{
              marginBottom: 2,
              '& .MuiInputLabel-root': { color: '#6c757d' },
              '& .MuiOutlinedInput-root': {
                borderRadius: 4,
                '& fieldset': { borderColor: '#6c757d' },
              },
            }}
          />

          <Button
            fullWidth
            variant="contained"
            color="primary"
            sx={{
              mt: 2,
              padding: '10px',
              borderRadius: 25,
              boxShadow: 3,
              '&:hover': {
                backgroundColor: '#0288d1',
              },
            }}
            onClick={handleSubmit}
          >
            Register Now
          </Button>
        </Box>

        <Box sx={{ width: '50%', paddingLeft: 2 }}>
          <img
            src="https://cdn.pixabay.com/photo/2020/04/12/07/18/post-it-5033079_1280.jpg"
            alt="Registration illustration"
            style={{
              width: '100%',
              height: 'auto',
              borderRadius: '8px',
              boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.1)',
            }}
          />
        </Box>
      </Container>
    </Box>
  );
}
