

const manageUserstyles = {
  checkBox: {
    "& .MuiFormControlLabel-label": {
      fontSize: "0.75rem",
    },
  },
  submitButton: {
    bgcolor: "#000000",
    textTransform:"none",
    color: "white",
    borderRadius: "0.7rem",
    ":hover": {
      backgroundColor: "#000000",
    },
  },
  select: {
    width: "100%",
    height: "60%",
  },
};
export default manageUserstyles;
