import React from "react";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { styled } from "@mui/system";

import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";

import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useLocation } from "react-router-dom";

const FeatureItem = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  mb: 3,
});

const StyledCard = styled(Card)({
  width: "436px",
  border: "1px solid #e1e9fe;",
  borderRadius: "24px",
  background: "linear-gradient(#f2f5fd, #ffffff 35%)",
  overflow: "visible",
});

const SubscriptionsCard = ({
  subscriptionData,
  handleBuyNow,
  handleContactSales,
  tabValue,
  toggleContactForm
}) => {
  const [selectedCounts, setSelectedCounts] = useState({});
  const location = useLocation();

  const handleCountChange = (planId, value) => {
    setSelectedCounts((prev) => ({ ...prev, [planId]: value })); // Update specific plan count
  };
  console.log("subscriptionData", subscriptionData);

  return (
    <Box
      sx={{
        display: "flex",
        gap: 3,
        flexWrap: "wrap",
        justifyContent: "center",

        px: 2,
        py: 10,
        mb: 4,
        background: "linear-gradient(#f2f5fd, #ffffff 35%)", // Use `background` for gradients
        borderRadius: "32px",
        border: "1px solid #e2e9fe",
      }}
    >
    {subscriptionData?.generalSubscriptions.map((item) => (
  <StyledCard
    elevation={0}
    sx={{
      position: "relative",
    
    }}
  >
    {/* Ribbon for Active Card */}
  
    <CardContent sx={{ p: 3 }}>
      {/* Header */}
      <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 1 }}>
        <Typography
          variant="h6"
          sx={{ fontWeight: 500, fontFamily: "poppins" }}
        >
          {item?.tier === "general" ? "General Plan" : ""}
        </Typography>
      </Box>

      {/* Price */}
      <Box sx={{ mb: 2 }}>
        <Box sx={{ display:'flex',justifyContent:'flex-start',flexDirection:'row', gap:2}}>
        <Typography
          variant="h4"
          component="div"
          sx={{ fontWeight: 500, fontFamily: "gilroy",textDecoration:'line-through' }}
        >
          ₹{item.subscriptionFee}
        </Typography>
        <Typography
          variant="h4"
          component="div"
          sx={{ fontWeight: 500, fontFamily: "gilroy",}}
        >
         ₹{0}
        </Typography>
        <Typography
          variant="secondary"
          sx={{ fontFamily: "gilroy",mt:1}}
        >
         For First 30 days
        </Typography>
        </Box>
        <Typography
          variant="secondary"
          sx={{ fontFamily: "gilroy",mt:1}}
        >
         (Apply Coupon Code - {item.subType === "jobposting"?"VFJP10":"VFDB200"})
        </Typography>
        {/* <Typography
          variant="caption"
          color="text.secondary"
          sx={{ fontFamily: "poppins" }}
        >
          {item.tier === "general"
            ? "GST as applicable"
            : "Based on your plan"}
        </Typography> */}
      </Box>

      {/* Features */}
      <Typography
        variant="subtitle2"
        sx={{ mb: 2, color: "text.secondary", fontFamily: "poppins" }}
      >
        Key Features
      </Typography>

      <FeatureItem
        sx={{
          mt: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          pb: 1,
          borderBottom: "1px solid #ddd",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <FiberManualRecordIcon sx={{ fontSize: "14px" }} />
          <Typography variant="body2" sx={{ fontFamily: "poppins" }}>
            {item?.subType === "jobposting"
              ? `${item?.jobPosts} Free Job Postings per month`
              : `${item?.userAccess} CV Views or Candidate Contact Details Per Month`}
          </Typography>
        </Box>
        <CheckCircleOutlineOutlinedIcon sx={{ fontSize: 30 }} />
      </FeatureItem>

      {item.benefits.map((c, idx) => (
        <FeatureItem
          key={idx}
          sx={{
            mt: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            pb: 1,
            borderBottom: "1px solid #ddd",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <FiberManualRecordIcon sx={{ fontSize: "14px" }} />
            <Typography variant="body2" sx={{ fontFamily: "poppins" }}>
              {c}
            </Typography>
          </Box>
          <CheckCircleOutlineOutlinedIcon sx={{ fontSize: 30 }} />
        </FeatureItem>
      ))}

      {/* Buy/Contact Sales Button */}
      <Box
        sx={{
          mt: 3,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          fullWidth
          variant="contained"
          onClick={() => {
            if (location.pathname.includes("/employerSignIn")) {
              localStorage.setItem(
                "redirectUrl",
                "/employer/subscriptionStatus"
              );
              window.scrollTo({ top: 0, behavior: "smooth" });
            } else {
              handleBuyNow(item.tier, item._id);
            }
          }}
          disabled={item.isActive} // Disable button if active
          sx={{
            bgcolor: item.isActive ? "#888" : "#000", // Gray out if active
            textTransform: "none",
            fontFamily: "poppins",
            py: 1,
            width: "600px",
            borderRadius: "0.7rem",
            ":hover": {
              bgcolor: item.isActive ? "#888" : "#000",
            },
          }}
        >
          {item.isActive ? "Subscribed" : "Subscribe"}
        </Button>
      </Box>
    </CardContent>
  </StyledCard>
))}

      {subscriptionData?.premiumSubscriptions.map((item) => (
        <StyledCard elevation={0}>
          {/* Ribbon for Active Card */}
          {/* {plan.isActive && (
   <Box
     sx={{
       position: "absolute",
       top: 16,
       right: -48,
       backgroundColor: "#4caf50", // Green color for the ribbon
       color: "#fff",
       transform: "rotate(45deg)",
       width: "140px",
       textAlign: "center",
       fontSize: "12px",
       fontWeight: 500,
       fontFamily: "poppins",
       py: 0.5,
       boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
     }}
   >
     Active
   </Box>
 )} */}

          <CardContent sx={{ p: 3 }}>
            {/* Header */}
            <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 1 }}>
              <Typography
                variant="h6"
                sx={{ fontWeight: 500, fontFamily: "poppins" }}
              >
                {item?.tier === "general" ? "Free Plan" : "Premium Plan"}
              </Typography>
              <InfoOutlinedIcon
                sx={{ fontSize: 18, color: "text.secondary" }}
              />
            </Box>

            {/* Price */}
            <Box sx={{ mb: 2 }}>
              <Typography
                variant="h4"
                component="div"
                sx={{ fontWeight: 500, fontFamily: "poppins" }}
              >
                {item.tier === "general" ? (
                  <>
                    <span
                      style={{ textDecoration: "line-through", color: "#666" }}
                    >
                      ₹{item.subscriptionFee}
                    </span>{" "}
                    ₹0
                  </>
                ) : (
                  ``
                )}
              </Typography>
              <Typography
                variant="caption"
                color="text.secondary"
                sx={{ fontFamily: "poppins" }}
              >
                {item.tier === "general"
                  ? "GST as applicable"
                  : "Based on your plan"}
              </Typography>
            </Box>

            {/* Features */}
            <Typography
              variant="subtitle2"
              sx={{ mb: 2, color: "text.secondary", fontFamily: "poppins" }}
            >
              Key Features
            </Typography>
            {item.benefits.map((c) => (
              <FeatureItem
                sx={{
                  mt: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  pb: 1, // Padding to avoid text touching the line
                  borderBottom: "1px solid #ddd", // Light gray underline
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <FiberManualRecordIcon sx={{ fontSize: "14px" }} />
                  <Typography variant="body2" sx={{ fontFamily: "poppins" }}>
                    {c}
                  </Typography>
                </Box>
                <CheckCircleOutlineOutlinedIcon sx={{ fontSize: 30 }} />
              </FeatureItem>
            ))}

            {/* Buy Button */}

            <Box
              sx={{
                mt: 3,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {item?.tier === "general" ? (
                <>
                  {location.pathname !== "/employerSignIn" && (
                    <FormControl fullWidth size="small">
                      <Select
                        disabled
                        value={selectedCounts[item._id] || 1}
                        onChange={(e) =>
                          handleCountChange(item._id, e.target.value)
                        }
                        sx={{
                          width: "100px",
                          borderRadius: "0.7rem",
                          fontFamily: "poppins",
                        }}
                      >
                        {[1, 2, 3, 4, 5].map((num) => (
                          <MenuItem key={num} value={num}>
                            {num}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}

                  <Button
                    fullWidth
                    variant="contained"
                    onClick={() => {
                      if (location.pathname.includes("/employerSignIn")) {
                        localStorage.setItem(
                          "redirectUrl",
                          "/employer/subscriptionStatus"
                        );
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      } else {
                        handleBuyNow(item.tier, item._id);
                      }
                    }}
                    sx={{
                      bgcolor: "#000",
                      textTransform: "none",
                      fontFamily: "poppins",
                      py: 1,
                      width: "700px",
                      borderRadius: "0.7rem",
                      ":hover": {
                        bgcolor: "#000",
                      },
                    }}
                  >
                    Subscribe
                  </Button>
                </>
              ) : (
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => {
                    if (location.pathname.includes("/employerSignIn")) {
                      localStorage.setItem(
                        "redirectUrl",
                        "/employer/subscriptionStatus"
                      );
                      window.scrollTo({ top: 0, behavior: "smooth" });
                    } else {
                      toggleContactForm()
                    }
                  }}
                  sx={{
                    bgcolor: "#000",
                    textTransform: "none",
                    fontFamily: "poppins",
                    py: 1,
                    width: "600px",
                    borderRadius: "0.7rem",
                    ":hover": {
                      bgcolor: "#000",
                    },
                  }}
                >
                  Contact Sales
                </Button>
              )}
            </Box>
            {/* )} */}
          </CardContent>
        </StyledCard>
      ))}
    </Box>
  );
};

export default SubscriptionsCard;
