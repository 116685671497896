import React, { useEffect, useRef, useState } from "react";
import FeaturedEmployers from "../../components/JobSeekerComponents/FeaturedEmployers/FeaturedEmployers";
import Footer from "../../components/JobSeekerComponents/Footer/Footer";
import JobSeekerJobSearch from "../../components/JobSeekerComponents/JobSeekerSearch/jobSeekerJobSearch";
import JobSeekerTrendingJobs from "../../components/JobSeekerComponents/JobSeekerTrendingJobs/JobSeekerTrendingJobs";
// import JobSeekerTopCompanies from "../../components/JobSeekerComponents/JobSeekerTopCompanies/JobSeekerTopCompanies";
import WhyVacanzi from "../../components/JobSeekerComponents/JobSeekerHomePageBanners/WhyVacanzi";
import JobSeekerTopIndustries from "../../components/JobSeekerComponents/JobSeekerIndustries/JobSeekerTopIndustries";
import JobSeekerImageCarousel from "../../components/JobSeekerComponents/JobSeekerHomePageBanners/JobSeekerImageCarousel";
import { useScroll } from "../../context/ScrollContext";
import { useLocation, useNavigate } from "react-router-dom";
import NewNavBar from "../../components/JobSeekerComponents/NavBar/NewNavbar";
import Faq from "../../components/LoginPageComponents/Faq";
import JobSeekerBottom from "../../components/JobSeekerComponents/JobSeekerHomePageBanners/JobSeekerBottom";
import Advertisements from "../../components/JobSeekerComponents/JobSeekerHomePageBanners/Advertisements";
// import Banners from "../../components/JobSeekerComponents/JobSeekerHomePageBanners/Banners";
import JobSeekerLetBuild from "../../components/JobSeekerComponents/JobSeekerHomePageBanners/JobSeekerLetBuild";
import JobSeekerWhyVacanziTestimonals from "../../components/JobSeekerComponents/JobSeekerHomePageBanners/JobSeekerWhyVacanziTestimonals";
import JobRecommendations from "../../components/JobSeekerComponents/JobSeekerHomePageBanners/JobRecommendations";

const Home = () => {
  const { whyVacanziRef, scrollToWhyVacanzi } = useScroll();
  const location = useLocation();
  const navigate = useNavigate();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isAfterAdvertisements, setIsAfterAdvertisements] = useState(false);

  const advertisementsRef = useRef(null);
  useEffect(() => {
    if (location.state?.scrollToWhyVacanzi) {
      // Add a small delay to ensure the DOM is fully rendered
      setTimeout(() => {
        scrollToWhyVacanzi(); // Scroll to the target section
      }, 100); // Adjust the delay as needed

      // Clear the state after scrolling
      navigate(".", { replace: true, state: {} });
    }
  }, [location, scrollToWhyVacanzi, navigate]);
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 60);

      if (advertisementsRef.current) {
        const rect = advertisementsRef.current.getBoundingClientRect();
        setIsAfterAdvertisements(rect.top <= 0); // Detect if user has scrolled past Ads
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <>
      <NewNavBar
        isScrolled={isScrolled}
        isAfterAdvertisements={isAfterAdvertisements}
        setIsScrolled={setIsScrolled}
      />
      <JobSeekerJobSearch />
      <div ref={advertisementsRef}>
        <Advertisements />
      </div>
      <JobRecommendations/>
      <JobSeekerTopIndustries />
      <JobSeekerTrendingJobs />
    
      <div ref={whyVacanziRef}>
        <JobSeekerWhyVacanziTestimonals />
      </div>
    
     
      {/* <JobSeekerTopCompanies /> */}
      <FeaturedEmployers />
      <JobSeekerImageCarousel />
      <JobSeekerBottom />
      <WhyVacanzi />
      <JobSeekerLetBuild />
      <Faq category="Job Seekers" />
      <Footer />
    </>
  );
};

export default Home;
