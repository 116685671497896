import baseURL from "./baseUrl";

export const getAllSubsciptionsOrgApi = async (orgId, token, subType) => {
  try {
    const response = await fetch(
      `${baseURL}/subscriptions/${orgId}?subType=${subType}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "Application/json",
        },
      }
    );
    if (!response.ok) {
      throw new Error("network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};


export const getAllSubsCommonApi = async (subType) => {
  try {
    const response = await fetch(
      `${baseURL}/org_subscriptions?subType=${subType}`,
      {
        method: "GET",
        headers: {
        
          "Content-Type": "Application/json",
        },
      }
    );
    if (!response.ok) {
      throw new Error("network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};


export const getAllPurchasedSubsriptions = async (orgId, token) => {
  try {
    const response = await fetch(`${baseURL}/purhasedsubscriptions/${orgId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      throw new Error("network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export const upgradePlanApi = async (token, formValues) => {
  try {
    const response = await fetch(`${baseURL}/upgrade_subscription`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formValues),
    });
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message||"Failed To Purchase");
    }
  
    return data;
  } catch (error) {
    console.log(error.message);
    throw error
  }
};

export const getInvoicesApi = async (orgId, token) => {
  try {
    const response = await fetch(`${baseURL}/orgInvoices/${orgId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      throw new Error("network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export const getInvoiceByIdApi = async (invoiceId, token) => {
  try {
    const response = await fetch(`${baseURL}/invoices/${invoiceId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      throw new Error("network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export const contactSales = async (token, recruiterId) => {
  try {
    const response = await fetch(`${baseURL}/createOrgSalesTicket`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ recruiterId }), // Send recruiterId in the request body
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};


export const getSubscriptionById = async (subId) => {
  try {
    const response = await fetch(`${baseURL}/org_subscriptions/${subId}`, {
      method: "GET",
      headers: {
     
        "Content-Type": "Application/json",
      },
    });
    const data = await response.json();
    if (!response.ok) {
      throw new Error("network response was not ok");
    }
  
    return data;
  } catch (error) {
    console.log(error.message);
  }
};


export const companyDataApi = async (orgId,formData) => {
  try {
    const response = await fetch(`${baseURL}/companyData/${orgId}`, {
      method: "POST",
      headers: {
     
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData), // Send recruiterId in the request body
    });
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message ||"Network response was not ok");
    }
    return data
  } catch (error) {
    console.log(error.message);
    throw error
  }
};

export const getAllPromocodeApi = async (subType) => {
  try {
    const response = await fetch(`${baseURL}/org/promocodes?promoType=${subType}`, {
      method: "GET",
      headers: {
     
        "Content-Type": "Application/json",
      },
    });
    const data = await response.json();
    if (!response.ok) {
      throw new Error("network response was not ok");
    }
  
    return data;
  } catch (error) {
    console.log(error.message);
  }
};
