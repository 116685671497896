import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import {
  Typography,
  IconButton,
  Box,
  Paper,
  Button,
  Container,
  Grid,
  Skeleton,
} from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import SaveIcon from "@mui/icons-material/Save";
import { useNavigate, useLocation } from "react-router-dom";

import CandidateProfileCard from "../../../components/AdvancedComponents/CandidateSearchLists/CandidateProfileCard";
import CandidateSearchFilters from "../../../components/AdvancedComponents/CandidateSearchLists/CandidateSearchFilter";
import CustomPagination from "../../../components/AdvancedComponents/CustomPagination";
import baseURL from "../../../api/baseUrl";
import "react-toastify/dist/ReactToastify.css";
import { addSavedSearchApi } from "../../../api/search";
import PopupSuccess from "../../../utils/popup/PopupSuccess";
import ReleaseJobPostModel from "../SubscriptionStatus/ReleaseJobPostModel";
import { ThreeDot } from "react-loading-indicators";

const CandidateSearchList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [shouldFetch, setShouldFetch] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [allData, setAllData] = useState();
  const [saveloading, setSaveLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [age, setAge] = useState({
    minAge: "",
    maxAge: "",
  });

  const token = localStorage.getItem("token");
  const hasFetched = useRef(false);
  const handleAgeChange = useCallback((event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  }, []);

  const displayedProfiles = useMemo(() => {
    if (!profiles?.length) return []; // Handle empty or undefined profiles early

    const startIndex = (currentPage - 1) * itemsPerPage;
    return profiles.slice(startIndex, startIndex + itemsPerPage);
  }, [profiles, currentPage, itemsPerPage]);

  const query = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);

  const experienceType = query.get("experienceType");
  const keyword = query.get("keyword") || "";
  const currentLocation = useMemo(() => {
    return query.get("currentLocation")
      ? query.get("currentLocation").split(",")
      : [];
  }, [query]);
  const preferredLocation = useMemo(() => {
    return query.get("preferredLocation")
      ? query.get("preferredLocation").split(",")
      : [];
  }, [query]);
  const skills = useMemo(() => {
    return query.get("skills") ? query.get("skills").split(",") : [];
  }, [query]);

  const minExperience = query.get("totalExpMin") || "";
  const maxExperience = query.get("totalExpMax") || "";
  const minSalary = query.get("minSalary") || "";
  const maxSalary = query.get("maxSalary") || "";
  const ugDegree = useMemo(() => {
    return query.get("ugDegree") ? query.get("ugDegree").split(",") : [];
  }, [query]);
  const pgDegree = useMemo(() => {
    return query.get("pgDegree") ? query.get("pgDegree").split(",") : [];
  }, [query]);
  const ug = query.get("ug") || "";
  const pg = query.get("pg") || "";
  const currentCompany = query.get("currentCompany");
  const currentAndPreviousCompany = query.get("currentAndPreviousCompany");
  const ugStartYear = query.get("ugStartYear") || "";
  const ugEndYear = query.get("ugEndYear") || "";
  const pgStartYear = query.get("pgStartYear") || "";
  const pgEndYear = query.get("pgEndYear") || "";
  const ugInstitute = query.get("ugInstitute") || "";
  const pgInstitute = query.get("pgInstitute") || "";
  const industry = useMemo(() => {
    return query.get("industry") ? query.get("industry").split(",") : [];
  }, [query]);
  const noticePeriod = useMemo(() => {
    return query.get("noticePeriod")
      ? query.get("noticePeriod").split(",")
      : [];
  }, [query]);
  const department = useMemo(() => {
    return query.get("department") ? query.get("department").split(",") : [];
  }, [query]);
  const booleanSearch = query.get("booleanSearch");
  const minAge = query.get("minAge");
  const maxAge = query.get("maxAge");
  const preferredJobType = query.get("preferredJobType");
  const hideViewedJobseekers = query.get("hideViewedJobseekers");
  const gender = query.get("gender");
  const shouldntHave =  query.get("shouldntHave") || ""

  const [formData, setFormData] = useState({
    keyword: Array.isArray(keyword) ? keyword : keyword ? [keyword] : [],
    minimumEducation: "",
    activeIn: "",
    minExperience: minExperience,
    maxExperience: maxExperience,
    department: Array.isArray(department) ? department : [],
    minSalary: minSalary,
    maxSalary: maxSalary,
    skills: Array.isArray(skills) ? skills : [],
    preferredLocation: Array.isArray(preferredLocation)
      ? preferredLocation
      : [],
    currentLocation: Array.isArray(currentLocation) ? currentLocation : [],

    industry: Array.isArray(industry) ? industry : [],
    noticePeriod: Array.isArray(noticePeriod) ? noticePeriod : [],
    booleanSearch: booleanSearch ?? "",
    minAge: minAge ? minAge : "",
    maxAge: maxAge ? maxAge : "",
    prefferedJobType: preferredJobType ?? "",
    experienceType: experienceType ?? "",
    gender: gender ?? "",
    shouldntHave:  Array.isArray(shouldntHave) ? shouldntHave : shouldntHave ? [shouldntHave] : [],
    ugDegree: Array.isArray(ugDegree) ? ugDegree : [],
    pgDegree: Array.isArray(pgDegree) ? pgDegree : [],
    ugStartYear: ugStartYear ? ugStartYear : "",
    ugEndYear: ugEndYear ? ugEndYear : "",
    pgStartYear: pgStartYear ? pgStartYear : "",
    pgEndYear: pgEndYear ? pgEndYear : "",
    ugInstitute: ugInstitute ? ugInstitute : "",
    pgInstitute: pgInstitute ? pgInstitute : "",
    currentCompany: currentCompany ? currentCompany : "",
    currentAndPreviousCompany: currentAndPreviousCompany
      ? currentAndPreviousCompany
      : "",
    hideViewedJobseekers: hideViewedJobseekers,
  });

  const apiURL = `${baseURL}/rc/jobseekers?experienceType=${formData.experienceType}&keyword=${formData.keyword}&currentLocation=${formData.currentLocation}&preferredLocation=${formData.preferredLocation}&totalExpMin=${formData.minExperience}&totalExpMax=${formData.maxExperience}&minSalary=${formData.minSalary}&maxSalary=${formData.maxSalary}&page=${currentPage}&industry=${formData.industry}&booleanSearch=${formData.booleanSearch}&minAge=${formData.minAge}&maxAge=${formData.maxAge}&prefferedJobType=${formData.prefferedJobType}&skills=${formData.skills}&gender=${formData.gender}&shouldntHave=${formData.shouldntHave}&department=${formData.department}&ugDegree=${formData.ugDegree}&pgDegree=${formData.pgDegree}&ugStartYear=${formData.ugStartYear}&ugEndYear=${formData.ugEndYear}&pgStartYear=${formData.pgStartYear}&pgEndYear=${formData.pgEndYear}&ugInstitute=${formData.ugInstitute}&pgInstitute=${formData.pgInstitute}&noticePeriod=${formData.noticePeriod}&currentCompany=${formData.currentCompany}&currentAndPreviousCompany=${formData.currentAndPreviousCompany}&hideViewedJobseekers=${formData.hideViewedJobseekers}&shouldntHave=${formData.shouldntHave}`;
  const instantQuerys = new URLSearchParams(location.search).get("query"); // e.g., "Sales,Marketing"
  const instantUrl = `${baseURL}/rc/instantSearch`;

  const fetchProfiles = useCallback(async () => {
    setLoading(true);
    try {
      let response;
      const isInstant = location.state?.instant || false;
      if (isInstant) {
        // POST request with query in body for instant search
        const requestBody = {
          query: instantQuerys,
          page: currentPage,
          limit: itemsPerPage,
        }; // Structure as object
        response = await fetch(instantUrl, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody), // Stringify the object
        });
      } else {
        // GET request for non-instant search
        response = await fetch(apiURL, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
      }

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setAllData(data);
      setProfiles(data?.jobseekers || []);
      setTotalPages(data?.pagination?.totalPages || 1);
      navigate(location.pathname + location.search, {
        replace: true,
        state: {},
      });
    } catch (error) {
      console.error("Error fetching profiles:", error);
    } finally {
      setLoading(false);
      navigate(location.pathname + location.search, {
        replace: true, // Replace current history entry
        state: {}, // Reset state to empty object
      });
    }
  }, [
    apiURL,
    token,

    instantQuerys,
    instantUrl,
    setAllData,
    setProfiles,
    setTotalPages,
    setLoading,
    currentPage,
    itemsPerPage,
    location,
    navigate,
  ]);

  useEffect(() => {
    if (!hasFetched.current || shouldFetch) {
      fetchProfiles();
      hasFetched.current = true; // Mark as fetched
      setShouldFetch(false); // Reset shouldFetch after fetching
    }
  }, [shouldFetch, fetchProfiles]);

  useEffect(() => {
    localStorage.setItem("profiles", JSON.stringify(profiles));
  }, [profiles]);

  const handleBack = () => {
    const searchData = {
      searchQuery: {
        experienceType: experienceType,
        noticePeriod: noticePeriod,
        department: department,
        keyword: keyword,
        shouldntHave: shouldntHave,
        currentLocation: formData.currentLocation,
        preferredLocation: formData.preferredLocation,
        industry: formData.industry,
        minExperience: formData.minExperience,
        maxExperience: formData.maxExperience,
        minSalary: formData.minSalary,
        maxSalary: formData.maxSalary,
        minAge: formData.minAge,
        booleanSearch: formData.booleanSearch,
        hideViewedJobseekers: formData.hideViewedJobseekers,
        maxAge: formData.maxAge,
        jobType: formData.jobType,
        preferredJobType: formData.prefferedJobType,
        gender: formData.gender,
        ug: ug,
        pg: pg,
        ugInstitute: ugInstitute,
        pgInstitute: pgInstitute,
        ugStartYear: ugStartYear,
        ugEndYear: ugEndYear,
        pgStartYear: pgStartYear,
        pgEndYear: pgEndYear,
        ugDegree: ugDegree,
        pgDegree: pgDegree,
        currentCompany: currentCompany,
        currentAndPreviousCompany: currentAndPreviousCompany,
      },
    };

    localStorage.setItem("savedSearchData", JSON.stringify(searchData));
    navigate(-1);
  };

  const handleSuccess = useCallback((message) => {
    setSuccessMessage(message);
    setSuccessOpen(true);
    setTimeout(() => {
      setSuccessOpen(false);
      setSuccessMessage("");
    }, 2000);
  }, []);

  const handleError = useCallback((message) => {
    setErrorMessage(message);
    setErrorOpen(true);
    setTimeout(() => {
      setErrorOpen(false);
      setErrorMessage("");
    }, 2000);
  }, []);

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
  };

  const handleItemsPerPageChange = useCallback((items) => {
    setItemsPerPage(items);
    setCurrentPage(1);
  }, []);

  const handleChange = ({ target: { name, value } }) =>
    setFormData((prev) => ({ ...prev, [name]: value }));

  const savedFormValues = useMemo(
    () => ({
      recruiterId: localStorage.getItem("recruiterId"),
      organizationId: localStorage.getItem("organizationId"),
      searchQuery: {
        experienceType: formData.experienceType,
        noticePeriod: formData.noticePeriod,
        department: formData.department,
        keyword: formData.keyword,
        shouldntHave: formData.shouldntHave,
        currentLocation: formData.currentLocation,
        preferredLocation: formData.preferredLocation,
        industry: formData.industry,
        minExperience: formData.minExperience,
        maxExperience: formData.maxExperience,
        minSalary: formData.minSalary,
        maxSalary: formData.maxSalary,
        minAge: formData.minAge,
        booleanSearch: formData.booleanSearch,
        hideViewedJobseekers: formData.hideViewedJobseekers,
        maxAge: formData.maxAge,
        jobType: formData.jobType,
        preferredJobType: formData.prefferedJobType,
        gender: formData.gender,
        ug: formData.ug,
        pg: formData.pg,
        ugInstitute: formData.ugInstitute,
        pgInstitute: formData.pgInstitute,
        ugStartYear: formData.ugStartYear,
        ugEndYear: formData.ugEndYear,
        pgStartYear: formData.pgStartYear,
        pgEndYear: formData.pgEndYear,
        ugDegree: formData.ugDegree,
        pgDegree: formData.pgDegree,
        currentCompany: formData.currentCompany,
        currentAndPreviousCompany: formData.currentAndPreviousCompany,
      },
    }),
    [formData]
  );

  const handleSaveSearch = useCallback(async () => {
    setSaveLoading(true);
    try {
      await addSavedSearchApi(token, savedFormValues);

      handleSuccess("Search saved successfully!");
    } catch (error) {
      console.error("Error saving search:", error);
      handleError("Failed to save search. Please try again.");
    } finally {
      setSaveLoading(false);
    }
  }, [token, savedFormValues, handleSuccess, handleError]);

  const handleModifySearch = () => {
    const searchData = {
      searchQuery: {
        experienceType: experienceType,
        noticePeriod: noticePeriod,
        department: department,
        keyword: keyword,
        shouldntHave: shouldntHave,
        hideViewedJobseekers: hideViewedJobseekers,
        currentLocation: formData.currentLocation,
        preferredLocation: formData.preferredLocation,
        industry: formData.industry,
        minExperience: formData.minExperience,
        maxExperience: formData.maxExperience,
        minSalary: formData.minSalary,
        maxSalary: formData.maxSalary,
        minAge: formData.minAge,
        booleanSearch: formData.booleanSearch,
        maxAge: formData.maxAge,
        jobType: formData.jobType,
        preferredJobType: formData.prefferedJobType,
        gender: formData.gender,
        ug: ug,
        pg: pg,
        ugInstitute: ugInstitute,
        pgInstitute: pgInstitute,
        ugStartYear: ugStartYear,
        ugEndYear: ugEndYear,
        pgStartYear: pgStartYear,
        pgEndYear: pgEndYear,
        ugDegree: ugDegree,
        pgDegree: pgDegree,
        currentCompany: currentCompany,
        currentAndPreviousCompany: currentAndPreviousCompany,
      },
    };

    localStorage.setItem("savedSearchData", JSON.stringify(searchData));

    navigate("/search-candidates");
  };
  const handleFetchClick = useCallback(() => setShouldFetch(true), []);
  console.log("profiles", profiles);
  return (
    <>
      {loading ? (
        <Box
          sx={{
            width: "100%",
            height: "100vh", // Full viewport height
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#FFFFFF",
          }}
        >
          <img
            src="/loading.gif"
            alt="Loading"
            style={{ width: 200, height: 200 }}
          />
        </Box>
      ) : (
        <Container
          sx={{ display: "flex", flexDirection: "column", height: "100vh" }}
        >
          <Paper
            sx={{
              padding: 2,
              backgroundColor: "#ffffff",
              width: "77vw",
              border: "1px solid #CECECEFF",
              borderRadius: "12px",
              mb: 2, // Margin bottom to separate from content
              position: "sticky",
              top: 72,
              zIndex: 10, // Ensure it stays above scrollable content
            }}
            elevation={0}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  gap: 2,
                }}
              >
                <IconButton onClick={handleBack}>
                  <ArrowBackIcon />
                </IconButton>
                <Typography variant="body2" sx={{ fontFamily: "poppins" }}>
                  <b>
                    {loading ? (
                      <Skeleton width={100} />
                    ) : (
                      allData?.pagination?.totalJobseekers
                    )}
                  </b>{" "}
                  Profiles found
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: "20px",
                    textTransform: "none",
                    fontFamily: "poppins",
                    color: "#ffffff",
                    backgroundColor: "#000000",
                    "&:hover": {
                      backgroundColor: "#000000",
                    },
                  }}
                  onClick={handleModifySearch}
                >
                  Modify Search
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#000000",
                    fontFamily: "poppins",
                    borderRadius: "20px",
                    textTransform: "none",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#000000",
                    },
                  }}
                  onClick={handleSaveSearch}
                >
                  {saveloading ? (
                    <ThreeDot color="#fff" size="small" text="" textColor="" />
                  ) : (
                    "  Save Search"
                  )}
                </Button>
              </Box>
            </Box>
          </Paper>

          <Grid container spacing={2} sx={{ flex: 1, overflow: "hidden" }}>
            {/* Fixed Filters Section */}
            <Grid
              item
              xs={6}
              md={4}
              sx={{
                position: "sticky",
                top: "calc(2rem + 64px)", // Adjust based on Paper height + some margin
                height: "calc(100vh - 64px - 2rem)", // Full height minus Paper
                overflowY: "auto", // Allow scrolling within filters if needed
                zIndex: 5, // Ensure it stays above scrollable content
                paddingRight: 2, // Add some spacing
                // Hide scrollbar for different browsers
                "&::-webkit-scrollbar": {
                  display: "none", // Chrome, Safari, Edge
                },
                scrollbarWidth: "none", // Firefox
                "-ms-overflow-style": "none", // Internet Explorer and Edge (legacy)
              }}
            >
              <Typography
                variant="h6"
                fontWeight="bold"
                sx={{
                  padding: 3,
                  visibility: "hidden",
                  fontFamily: "poppins",
                }}
              >
                Filters (
                {
                  Object.values(formData).filter((value) =>
                    Array.isArray(value) ? value.length > 0 : value
                  ).length
                }
                )
              </Typography>

              <CandidateSearchFilters
                formData={formData}
                handleChange={handleChange}
                handleAgeChange={handleAgeChange}
                setFormData={setFormData}
                setAge={setAge}
                age={age}
              />
              <Box
                sx={{
                  padding: 2,
                  backgroundColor: "#f5faf5",
                }}
              >
                <Button
                  onClick={handleFetchClick}
                  variant="contained"
                  color="primary"
                  sx={{
                    width: "100%",
                    fontFamily: "poppins",
                    borderRadius: "0.7rem",
                    textTransform: "none",
                    backgroundColor: "#000",
                    ":hover": {
                      backgroundColor: "#000",
                    },
                  }}
                >
                  Refine Search
                </Button>
              </Box>
            </Grid>

            {/* Scrollable Profiles Section */}
            <Grid
              item
              xs={12}
              md={8}
              sx={{
                height: "calc(100vh - 64px - 2rem)", // Match filters height
                overflowY: "auto", // Enable scrolling
                paddingLeft: 2,
                "&::-webkit-scrollbar": {
                  display: "none", // Chrome, Safari, Edge
                },
                scrollbarWidth: "none", // Firefox
                "-ms-overflow-style": "none",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 2,
                  padding: 2,
                }}
              >
                <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                  <CustomPagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                    itemsPerPage={itemsPerPage}
                    onItemsPerPageChange={handleItemsPerPageChange}
                  />
                </Box>
              </Box>

              {loading ? (
                <Grid container spacing={2}>
                  {Array.from({ length: itemsPerPage }).map((_, index) => (
                    <Grid item xs={12} md={12} key={index}>
                      <Skeleton variant="rectangular" height={300} />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <CandidateProfileCard
                  profiles={profiles}
                  setProfiles={setProfiles}
                  displayedProfiles={displayedProfiles}
                  keyword={keyword}
                />
              )}
            </Grid>
          </Grid>
        </Container>
      )}
      <PopupSuccess
        modelOpen={successOpen}
        onClose={() => setSuccessOpen(false)}
        message={successMessage}
      />
      <ReleaseJobPostModel
        modelOpen={errorOpen}
        onClose={() => setErrorOpen(false)}
        message={errorMessage}
      />
    </>
  );
};

export default CandidateSearchList;
