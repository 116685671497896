import React, { useState, useEffect } from "react";
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Typography,
  Divider,
  Box,
  Button,
  Paper,
  Container,
} from "@mui/material";
import NewNavBar from "../JobSeekerComponents/NavBar/NewNavbar";
import Footer from "../JobSeekerComponents/Footer/Footer";
import { getNotifications } from "../../api/common";

const Notifications = () => {
  const [activeTab, setActiveTab] = useState(0);
  const tabLabels = ["All", "Jobs", "My posts", "Mentions"];
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await getNotifications();
        // Set notifications from response.data.notifications instead of response.data
        setNotifications(response?.notifications || []);
      } catch (error) {
        console.error("Error fetching notifications:", error.message);
      }
    };
    fetchNotifications();
  }, []);

  // Function to calculate time difference
  const getTimeDifference = (createdAt) => {
    const now = new Date();
    const created = new Date(createdAt);
    const diffMs = now - created;
    const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
    return `${diffHours}h`;
  };

  console.log('notifications',notifications);
  

  // Filter notifications based on selected tab
  // You might want to adjust the filtering logic based on your notification types
  const filteredNotifications =
    activeTab === 0
      ? notifications
      : notifications.filter((notification) => {
          // Adjust this condition based on your actual category mapping
          switch (activeTab) {
            case 1: // Jobs
              return notification.type === "job";
            case 2: // My posts
              return notification.type === "post";
            case 3: // Mentions
              return notification.type === "mention";
            default:
              return true;
          }
        });

  return (
    <>
      <NewNavBar />
      <Paper
        elevation={0}
        sx={{
          position: "relative",
          backgroundColor: "#f5f5f5",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          pb: 10,
          pt:10,
        }}
      >

        <Container maxWidth="md">
        <Typography variant="h4" fontWeight='bold' gutterBottom>Notifications</Typography>

          <Paper
            elevation={0}
            sx={{
              bgcolor: "white",
              borderBottom: 1,
              borderColor: "divider",
              display: "flex",
              gap: 1,
              p: 3,
              justifyContent: "center",
            }}
          >

            {tabLabels.map((label, index) => (
              <Button
                key={label}
                onClick={() => setActiveTab(index)}
                variant="contained"
                sx={{
                  backgroundColor: activeTab === index ? "#423bfa" : "transparent",
                  color: activeTab === index ? "#fff" : "#423bfa",
                  fontWeight: "bold",
                  textTransform: "none",
                  fontFamily: "Arial, sans-serif",
                  fontSize: "0.9rem",
                  borderRadius: "1.5rem",
                  border: "none",
                  px: 3,
                  py: 0.5,
                  marginTop: { xs: 2, sm: 0 },
                  "&:hover": {
                    backgroundColor: activeTab === index ? "#423bfa" : "#e8f0fe",
                    color: activeTab === index ? "#fff" : "#423bfa",
                  },
                }}
              >
                {label}
              </Button>
            ))}
          </Paper>

          <List sx={{ bgcolor: "#f0eeff", minHeight: 200, mt: 2 }}>
            {filteredNotifications.length > 0 ? (
              filteredNotifications.map((notification) => (
                <React.Fragment key={notification._id}>
                  <ListItem
                    sx={{
                      cursor: "pointer",
                      py: 2,
                      "&:hover": { bgcolor: "#f3f2f1" },
                    }}
                  >
                     <Box
                      sx={{
                        width: 8,
                        height: 8,
                        bgcolor: notification.isRead ? "transparent" : "#423bfa",
                        borderRadius: "50%",
                        ml: "auto",
                        mr:2,
                      }}
                    />
                    <ListItemAvatar>
                      <Avatar
                        // You might want to add a proper src if available in your data
                        alt="User Avatar"
                        sx={{ width: 40, height: 40 }}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography
                          variant="body1"
                          sx={{
                            fontSize: "0.9rem",
                            color: "#000",
                            lineHeight: 1.4,
                            fontFamily: "Arial, sans-serif",
                          }}
                        >
                          {notification.message}
                        </Typography>
                      }
                     
                      sx={{ ml: 2 }}
                    />
                    <Typography
                          variant="caption"
                          sx={{
                            color: "#666",
                            fontSize: "0.75rem",
                            fontFamily: "Arial, sans-serif",
                          }}
                        >
                          {getTimeDifference(notification.createdAt)}
                        </Typography>
                   
                  </ListItem>
                  <Divider sx={{ mx: 2 }} />
                </React.Fragment>
              ))
            ) : (
              <Typography
                variant="body2"
                sx={{
                  textAlign: "center",
                  color: "text.secondary",
                  p: 2,
                  fontFamily: "Arial, sans-serif",
                }}
              >
                No notifications found.
              </Typography>
            )}
          </List>
        </Container>
      </Paper>
      <Footer />
    </>
  );
};

export default Notifications;