import React from "react";
import { Box, Typography, Grid, Container, Button } from "@mui/material";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css"; // Basic Swiper styles
import "swiper/css/autoplay"; // Autoplay module styles
import "swiper/css/pagination"; // Pagination styles
import { Autoplay, Pagination } from "swiper/modules"; // Corrected import
import slider1 from "../../../assets/images/slider1.png";
import slider2 from "../../../assets/images/slider2.png";
import slider3 from "../../../assets/images/slider3.png";
import slider4 from "../../../assets/images/slider4.png";
import slider5 from "../../../assets/images/slider5.png";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { useNavigate } from "react-router-dom";


const images = [slider1, slider2, slider3, slider4, slider5]; // Add your image imports here

const JobSeekerBottom = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{ backgroundColor: "#fff", py: 4 }}>
      <Container maxWidth="xl">
        <Box mt={4}>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  textAlign: "left",
                }}
              >
                <Typography
                  variant="h3"
                  component="h1"
                  gutterBottom
                  fontWeight="bold"
                  fontFamily={"IBM plex sans"}
                >
                  Short Video Profiles
                </Typography>
                <Typography
                  variant="h4"
                  component="h1"
                  gutterBottom
                  sx={{ fontFamily: "IBM plex sans" }}
                >
                  Get Hired Faster on Vacanzi
                </Typography>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ fontFamily: "IBM plex sans" }}
                >
                  Vacanzi streamlines the process, allowing employers to assess
                  your skills and personality instantly, leading to faster job
                  matches.
                </Typography>
              </Box>

              {/* <Box
                sx={{
                  display: "flex",
                  mt:6,
                  flexDirection: "column", // Stack items vertically
                  alignItems: "flex-start", // Center items horizontally
                  justifyContent: "space-around",
                  gap: 2,
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: "0.7rem",
                    textTransform: "none",
                    fontFamily: "IBM Plex Sans",
                    ml:8,
                    backgroundColor: "#000",
                    "&:hover": {
                      backgroundColor: "#000",
                    },
                  }}
                >
                  Download The App Now
                </Button>

                <Box
                  sx={{
                    display: "flex",
                    gap: 2,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={appStore}
                    alt="App Store"
                    style={{ width: "150px" }}
                  />
                  <img
                    src={googlePlay}
                    alt="Google Play"
                    style={{ width: "150px" }}
                  />
                </Box>
              </Box> */}
            </Grid>

            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  textAlign: "center",
                  backgroundColor: "#000", // Black background
                  padding: "1rem", // Small padding inside the black background
                  borderRadius: "1rem", // Rounded corners for the background
                  maxWidth: "500px", // Set a smaller max width
                  margin: "0 auto", // Center the box horizontally
                }}
              >
                <Swiper
                  spaceBetween={30}
                  slidesPerView={1}
                  autoplay={{ delay: 3000 }}
                  pagination={{ clickable: true }}
                  loop={true}
                  modules={[Autoplay, Pagination]} // Pass modules here
                >
                  {images.map((image, index) => (
                    <SwiperSlide key={index}>
                      <img
                        src={image}
                        alt={`slider-img-${index}`}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          borderRadius: "1rem",
                        }}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Box>
            </Grid>
          </Grid>
       
        </Box>
      </Container>
      <Box
        sx={{
          width: "100%", // Ensure the Box spans the full width of its container
          height: "auto", // Height will adjust based on the image's aspect ratio
          overflow: "hidden", // Hide any overflow (optional)
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mt:10,
          backgroundColor: "#ffffff", // Background color for the Box
          position: "relative", // Make the Box a positioning context for the absolute Button
        }}
      >
        <img
          src="/postjobsbanner.webp" // Replace with your image path
          alt="Post Job Banner" // Add a descriptive alt text
          style={{
            width: "75%", // Make the image fill the width of the Box
            height: "auto", // Maintain the image's aspect ratio
            display: "block", // Ensure the image behaves as a block element
          }}
        />
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#18181b",
            color: "#ffffff",
            borderRadius: "0.7rem",
            textTransform: "none",
            fontSize: "20px",
            position: "absolute", // Position the button absolutely within the Box
            top: "76%", // Adjust this value to position the button vertically
            left: "24%", // Adjust this value to position the button horizontally
            transform: "translate(-50%, -50%)", // Center the button
            mt: 3,
            padding: "0.5rem 2.5rem",
            fontFamily: "IBM Plex Sans",
            display: "flex",
            justifyContent: "space-between", // Push the text to the left and icon to the right
            alignItems: "center", // Vertically center text and icon
            ":hover": {
              backgroundColor: "#18181b",
              color: "#ffffff",
            },
          }}
          onClick={() => navigate("employerSignIn")}
        >
          Post a Job
          <ArrowForwardIosOutlinedIcon sx={{ ml: 3 }} />{" "}
          {/* Margin left to space the icon */}
        </Button>
      </Box>
    </Box>
  );
};

export default JobSeekerBottom;
