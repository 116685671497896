import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const JobseekerWalletSupport = () => {
  return (
    <Box
    sx={{
      backgroundColor: "#5115c3",
      borderRadius: "1.5rem",
    p:5,
      maxWidth: 900,
      mt: 2,
      color: "#fff",
    
      textAlign: "start",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    }}
  >
    {/* Left Side: Text and Button */}
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "center",
        flex: 1,
      }}
    >
      <Typography
        sx={{
          fontWeight: 200,
          fontSize: "40px",
          fontFamily: "gilroy",
          lineHeight: 1.1,
          mb: 4,
        }}
      >
        Career <br /> Counselor
      </Typography>
  
      <Button
        type="submit"
        variant="contained"
        sx={{
          backgroundColor: "#fff",
          color: "#000",
          padding: "0.5rem 0.75rem",
          fontSize: "1rem",
          borderRadius: 3,
          width: "50%",
          fontFamily: "IBM plex sans",
          textTransform: "none",
          borderColor: "#0e0593",
          "&:hover": {
            backgroundColor: "#EAE7E7FF",
            color: "#000",
          },
        }}
      >
        Contact Now
      </Button>
    </Box>
  
    {/* Right Side: Image */}
    <Box
      sx={{
        flex: 1,
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      <img
        src="/walletimg.png" // Replace with your image URL
        alt="A candidate preparing for an interview" // Provide meaningful alt text
        style={{
          width: "80%", // Adjust width as needed
          maxWidth: "500px", // Optional: limit the max width
        }}
      />
    </Box>
  </Box>
  );
};

export default JobseekerWalletSupport;
