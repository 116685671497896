import React from "react";
import {

  Box,
  Card,

  Divider,

} from "@mui/material";
import CandidateAge from "./CandidateFilterComponents/CandidateAge";
import CandidateAnnualSalary from "./CandidateFilterComponents/CandidateAnnualSalary";
import CandidateExperience from "./CandidateFilterComponents/CandidateExpierence";
import CandidateIndustries from "./CandidateFilterComponents/CandidateIndustries";
import CandidateCity from "./CandidateFilterComponents/CandidateCity";
import CandidatePrefferedCity from "./CandidateFilterComponents/CandidatePrefferedCity";
import Keywords from "./CandidateFilterComponents/Keywords";
import { Stack } from "@mui/system";

const CandidateSearchFilters = ({
  formData,
  handleChange,
  handleAgeChange,
  setFormData,
}) => {


  return (
<Card variant="outlined" sx={{ borderRadius: "10px" }}>
      <Box p={2}>
        <Stack
          spacing={2} // Adds consistent gap between each child
          divider={<Divider />} // Automatically places Divider between items
          sx={{ p: 1, borderRadius: "20px" }}
        >
          <Keywords formData={formData} setFormData={setFormData} />
          <CandidateAge handleAgeChange={handleAgeChange} formData={formData} />
          <CandidateAnnualSalary formData={formData} setFormData={setFormData} />
          <CandidateExperience formData={formData} setFormData={setFormData} />
          <CandidateIndustries formData={formData} setFormData={setFormData} />
          <CandidateCity formData={formData} setFormData={setFormData} /> 
          <CandidatePrefferedCity formData={formData} setFormData={setFormData} />
        </Stack>
      </Box>
    </Card>
  );
};

export default CandidateSearchFilters;
