import React from "react";
import { Box, IconButton, Card} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const JobSeekerVideoCarousel = ({ videos }) => {
  const [startIndex, setStartIndex] = React.useState(0);

  const handlePrev = () => {
    setStartIndex((prev) => Math.max(prev - 1, 0));
  };

  const handleNext = () => {
    setStartIndex((prev) => Math.min(prev + 1, videos?.length - 1));
  };

  return (
    <Box sx={{ width: "100%", position: "relative", overflow: "hidden", pt: 1 }}>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          gap: 1,
          transition: "transform 0.3s ease-in-out",
          transform: `translateX(-${startIndex * (100 / videos?.length)}%)`,
          borderRadius: 0,
        }}
      >
        {videos?.map((video) => (
          <Card
            key={video.id}
            sx={{
              minWidth: `calc(100% / 3 - 8px)`,
              borderRadius: 2,
              cursor: "pointer",
              flexShrink: 0,
              backgroundColor: "#EAFBF4FF",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <video
              src={video?.jobVideoUrl}
              controls
              style={{ borderRadius: 8, height: 200,  maxWidth: 150, objectFit: "cover" }}
            />
          </Card>
        ))}
      </Box>

      <IconButton
        onClick={handlePrev}
        disabled={startIndex === 0}
        sx={{
          position: "absolute",
          left: 8,
          top: "50%",
          transform: "translateY(-50%)",
          zIndex: 1,
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          "&:hover": { backgroundColor: "rgba(255, 255, 255, 1)" },
        }}
      >
        <ChevronLeftIcon />
      </IconButton>

      <IconButton
        onClick={handleNext}
        disabled={startIndex >= videos?.length - 1}
        sx={{
          position: "absolute",
          right: 8,
          top: "50%",
          transform: "translateY(-50%)",
          zIndex: 1,
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          "&:hover": { backgroundColor: "rgba(255, 255, 255, 1)" },
        }}
      >
        <ChevronRightIcon />
      </IconButton>
    </Box>
  );
};

export default JobSeekerVideoCarousel;
