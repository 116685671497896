import React, { useCallback, useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Autocomplete,
  TextField,
  Chip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getTopIndustryMasters } from "../../../../api/common";

const CandidateIndustries = ({ formData, setFormData }) => {
  const [industryList, setIndustryList] = useState([]);
  

  
  useEffect(() => {
    const getAllIndustries = async () => {
      try {
        const response = await getTopIndustryMasters();
        if (response) {
          const allCategories = response.flatMap(resItem =>
            resItem.items.map(item => item.name).filter(Boolean)
          );
  
          setIndustryList(allCategories);
        }
      } catch (error) {
        console.error("Error fetching top industry masters:", error.message);
      }
    };
  
    getAllIndustries();
  }, []); // Empty dependency array ensures it runs only once
  
  

    const handleIndustryChange = useCallback((event, newValue) => {
      setFormData((prevFormData) => ({
        ...prevFormData,
        industry: newValue, 
      }));
    }, [setFormData]);
    

  return (
    <Accordion
      defaultExpanded
      sx={{
        boxShadow: "none",
        backgroundColor: "transparent",
        "&:before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: "#666", opacity: 0.7 }} />}
        sx={{
          padding: "8px 0",
          minHeight: "40px",
          "& .MuiAccordionSummary-content": {
            margin: "0",
          },
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "bold",
            fontFamily: "IBM Plex Sans, sans-serif",
            color: "#333",
          }}
        >
          Industries
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{ padding: "0 0 16px 0", backgroundColor: "transparent" }}
      >
        <Autocomplete
          multiple // Allow multiple selections
          options={industryList} // List of options
          value={formData.industry || []} // Current selected industries (fallback to empty array if undefined)
          onChange={handleIndustryChange} // Handle selection changes
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Select industries"
              variant="outlined"
              size="small"
            />
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip label={option} {...getTagProps({ index })} key={index} />
            ))
          }
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default CandidateIndustries// Memoize the component to prevent unnecessary re-renders