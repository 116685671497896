import React, { createContext, useContext, useEffect, useState, useCallback } from "react";
import { getOrgDetails } from "../api/companyProfile";


const OrgContext = createContext();

export const OrgProvider = ({ children }) => {
  const [orgData, setOrgData] = useState(null);
  const token = localStorage.getItem("token");
  const orgId = localStorage.getItem("organizationId"); // Ensure this exists

  const fetchOrgDetails = useCallback(async () => {
    if (!orgId || !token) return;

    try {
      const companyDetails = await getOrgDetails(orgId, token);
      setOrgData(companyDetails);
    } catch (error) {
      console.error("Failed to fetch organization details:", error);
    }
  }, [orgId, token]);

  useEffect(() => {
    if (!orgData) {
      fetchOrgDetails();
    }
  }, [fetchOrgDetails, orgData]);

  return <OrgContext.Provider value={{ orgData ,setOrgData}}>{children}</OrgContext.Provider>;
};

export const useOrg = () => useContext(OrgContext);