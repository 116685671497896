import React from "react";
import { Navigate } from "react-router-dom";

const PublicRoute = ({ children }) => {
  const token = localStorage.getItem("authToken");
  const userId = localStorage.getItem("userId");

  if (token && userId) {
    return <Navigate to="/" replace />; // Redirect to the logged-in page
  }

  return children;
};

export default PublicRoute;
