import React from "react";
import { Container, Grid, Typography, Box } from "@mui/material";
import carerer1 from '../../../../assets/icons/logos/career1.png';
import carerer2 from '../../../../assets/icons/logos/career2.png';
import carerer3 from '../../../../assets/icons/logos/career3.png';
import carerer4 from '../../../../assets/icons/logos/career4.png';
import carerer5 from '../../../../assets/icons/logos/career5.png';
import carerer6 from '../../../../assets/icons/logos/career6.png';

// Sample data (Replace with actual icons)
const careerData = [
  {
    icon: carerer1,
    title: "Innovate & Lead",
    description: "Work on cutting-edge AI and video technologies shaping the future of recruitment."
  },
  {
    icon: carerer2,
    title: "Grow with Us",
    description: "We foster a culture of learning, upskilling, and career advancement."
  },
  {
    icon: carerer3,
    title: "Impact Lives",
    description: "Help job seekers find opportunities that match their skills and aspirations."
  },
  {
    icon: carerer4,
    title: "Dynamic Work Culture",
    description: "Experience a collaborative, diverse, and energetic workplace."
  },
  {
    icon: carerer5,
    title: "Career Acceleration",
    description: "Get opportunities to take on leadership roles and make a real impact from day one."
  },
  {
    icon: carerer6,
    title: "Employee-First Culture",
    description: "Enjoy work-life balance, competitive benefits, and a supportive team that values your growth."
  }
];


const CareerIcons = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 6 }}>
      <Typography variant="h4" fontWeight="bold" gutterBottom textAlign="center">
      Why Work at Vacanzi?
      </Typography>
      
      <Grid container spacing={6} justifyContent="center">
        {careerData.map((item, index) => (
          <Grid item xs={12} sm={12} md={4} key={index}>  
            {/* Now takes full width on small screens, half width on medium+ screens */}
            <Box
              sx={{
                width: "100%", 
                maxWidth: 700, // Increases item width
                mx: "auto", // Centers items if they don’t fill the full row
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                mt: 6
              }}
            >
              <img src={item.icon} alt={item.title} width={100} height={100} />
              <Typography variant="h6" fontWeight="bold" sx={{ mt: 2 }}>
                {item.title}
              </Typography>
              <Typography
                color="textSecondary"
                sx={{
                  mt: 2,
                  fontSize: "18px",
                  fontWeight: "500",
                  textAlign: "left",
                  maxWidth: "100%", // Ensures text fills available space
                }}
              >
                {item.description}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default CareerIcons;
