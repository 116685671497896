import { useState } from "react";

export const useFormErrors = () => {
  const [errors, setErrors] = useState({
    rcName: "",
    rcEmail: "",
    rcPhone: "",
    password: "",
    role: "",
    reportingManagerId: "",
  });

  return { errors, setErrors };
};

// Function to extract domain from a URL
const extractDomain = (url) => {
  if (!url) return null;

  // Remove protocol (http://, https://), www., and paths/query strings
  let cleanUrl = url
    .toLowerCase()
    .replace(/^(https?:\/\/)?(www\.)?/, "") // Remove protocol and www
    .replace(/\/.*$/, "") // Remove anything after the domain (paths, query)
    .replace(/:.+$/, ""); // Remove port numbers if present (e.g., :8080)

  // Split by dots and take the last two parts for domain + TLD (e.g., vacanzi.com, vacanzi.co.uk)
  const parts = cleanUrl.split(".");
  if (parts.length < 2) return cleanUrl; // Invalid URL, return as-is

  // Handle multi-part TLDs like .co.uk, .org.in
  const commonMultiTlds = ["co.uk", "org.uk", "gov.uk", "ac.uk", "org.in", "co.in"];
  const lastTwo = parts.slice(-2).join(".");
  const lastThree = parts.slice(-3).join(".");

  if (commonMultiTlds.includes(lastTwo)) {
    // For cases like vacanzi.co.uk, return last three parts if applicable
    return parts.length > 2 ? lastThree : lastTwo;
  }

  return lastTwo; // Default to domain.tld (e.g., vacanzi.com)
};

export const validateForm = (formDetails, setErrors, errors, websiteUrl) => {
  let formIsValid = true;
  let errorObj = { ...errors };

  // Extract domain from websiteUrl
  const websiteDomain = extractDomain(websiteUrl);
  console.log("Extracted websiteDomain:", websiteDomain);

  // Validation functions
  const isEmpty = (value) => !value || value.trim() === "";
  const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const isValidPhone = (phone) => /^\d{10}$/.test(phone);

  // Validate Name
  if (isEmpty(formDetails.rcName)) {
    errorObj.rcName = "Name is required";
    formIsValid = false;
  } else {
    errorObj.rcName = "";
  }

  // Validate Email
  if (isEmpty(formDetails.rcEmail)) {
    errorObj.rcEmail = "Email is required";
    formIsValid = false;
  } else if (!isValidEmail(formDetails.rcEmail)) {
    errorObj.rcEmail = "Invalid email format";
    formIsValid = false;
  } else if (!websiteDomain) {
    errorObj.rcEmail = "Organization website URL is invalid or missing";
    formIsValid = false;
  } else {
    const emailDomain = formDetails.rcEmail.split("@")[1]?.toLowerCase();
    console.log("Email domain:", emailDomain);

    // Check if email domain ends with the website domain (allows subdomains)
    if (!emailDomain || !emailDomain.endsWith(websiteDomain)) {
      errorObj.rcEmail = `Email must belong to the ${websiteDomain} domain`;
      formIsValid = false;
    } else {
      errorObj.rcEmail = "";
    }
  }

  // Validate Phone Number
  if (isEmpty(formDetails.rcPhone)) {
    errorObj.rcPhone = "Phone number is required";
    formIsValid = false;
  } else if (!isValidPhone(formDetails.rcPhone)) {
    errorObj.rcPhone = "Phone number must be exactly 10 digits";
    formIsValid = false;
  } else {
    errorObj.rcPhone = "";
  }

  // Validate Password
  if (isEmpty(formDetails.password)) {
    errorObj.password = "Password is required";
    formIsValid = false;
  } else {
    errorObj.password = "";
  }

  // Validate Role
  if (isEmpty(formDetails.role)) {
    errorObj.role = "Role is required";
    formIsValid = false;
  } else {
    errorObj.role = "";
  }

  // Validate Reporting Manager ID
  if (isEmpty(formDetails.reportingManagerId)) {
    errorObj.reportingManagerId = "Reporting Manager is required";
    formIsValid = false;
  } else {
    errorObj.reportingManagerId = "";
  }

  setErrors(errorObj);
  return formIsValid;
};

export default validateForm;