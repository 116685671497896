import React from 'react';
import { Box, Button, Typography, Modal, TextField } from '@mui/material';
import { ThreeDot } from 'react-loading-indicators';

const OTPModal = ({
    open,
    handleClose,
    handleFieldChange,
    handleLoginSubmit,
    canResend,
    timer,
    email,
    otp,
    errors,
    handleOtpSubmit,
    loading,
    formData,
    handleResendOtp
}) => {

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            handleOtpSubmit();
        }
    };

    // Handle OTP input change to restrict to 4 digits
    const handleOtpChange = (event) => {
        const value = event.target.value;
        // Allow only numeric input and limit to 4 characters
        const numericValue = value.replace(/\D/g, '').slice(0, 4);
        handleFieldChange('otp', numericValue);
    };

    console.log("formdata", formData);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="otp-modal-title"
            closeAfterTransition
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 420,
                    bgcolor: 'background.paper',
                    borderRadius: 2,
                    boxShadow: 24,
                    p: 3,
                }}
            >
                <Typography
                    variant="h6"
                    sx={{ fontWeight: 'bold', marginBottom: '16px', fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }}
                >
                    Enter One Time Password to proceed
                </Typography>

                <Typography variant="body2" sx={{ color: '#535252' }}>
                    For enhanced security of your account, we have sent a One-Time Password (OTP) to
                    your <b>mobile number or email ID {email}</b>.
                </Typography>
                <Typography fontSize='14px' sx={{ color: '#535252' }}>Please enter the OTP</Typography>
                <TextField
                    value={otp}
                    size="small"
                    fullWidth
                    placeholder="Enter 4-digit OTP"
                    onChange={handleOtpChange} // Use custom handler
                    onKeyDown={handleKeyDown}
                    error={!!errors.otp}
                    inputProps={{
                        maxLength: 4, // Restrict to 4 characters
                        inputMode: 'numeric', // Numeric keyboard on mobile
                        pattern: '[0-9]*', // Allow only numbers
                        style: {
                            fontSize: '16px',
                        },
                    }}
                    sx={{
                        '& input::placeholder': {
                            fontSize: '13px',
                            color: '#535252',
                        },
                        mt: 1,
                    }}
                />

                <Typography color="error" sx={{ mt: 1, fontSize: '12px' }}>
                    {errors.otp || (otp.length > 0 && otp.length < 4 ? "OTP must be exactly 4 digits" : "")}
                </Typography>

                <Button
                    fullWidth
                    variant="contained"
                    sx={{
                        mt: 2,
                        backgroundColor: '#423bfa',
                        ":hover": {
                            backgroundColor: "#B0F0F0",
                            color: "#000"
                        },
                        color: 'white',
                        fontWeight: 'bold',
                        padding: '0.5rem',
                        textTransform: 'none',
                    }}
                    onClick={handleOtpSubmit}
                    disabled={otp.length !== 4 || loading} // Disable until OTP is exactly 4 digits
                >
                    {loading ? (
                        <ThreeDot
                            color="#FFFFFFFF"
                            size="small"
                            text=""
                            textColor=""
                        />
                    ) : (
                        "Verify"
                    )}
                </Button>
                {canResend ? (
                    <>
                        <Typography fontSize='14px' sx={{ color: '#535252', mt: 1 }}>
                            Didn't Receive OTP?
                            <Button color="primary" onClick={handleResendOtp} sx={{ textTransform: 'none', p: 0, ml: 1 }}>
                                <Typography fontSize='14px' fontWeight='bold'>Resend OTP</Typography>
                            </Button>
                        </Typography>
                    </>
                ) : (
                    <Typography fontSize='14px' sx={{ mt: 1 }}>Resend OTP in {timer}s</Typography>
                )}
            </Box>
        </Modal>
    );
};

export default OTPModal;