import React from "react";
import { Box, Typography, Container,} from "@mui/material";
import NewNavBar from "../../components/JobSeekerComponents/NavBar/NewNavbar";
import { Grid } from "@mui/material";

import LockIcon from "@mui/icons-material/Lock";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";

import Footer from "../../components/JobSeekerComponents/Footer/Footer";
import { useNavigate } from "react-router-dom";


const styles = {
  wrapper: {
    backgroundColor: "#f8f9fa",
    py: 8,
  },
  container: {
    maxWidth: "lg",
  },
  gridItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent:"center",
    alignItems: "center",
    textAlign: "center",
    width:'100%',
    px: 3,
  },
  iconWrapper: {
    width: 80,
    height: 80,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    backgroundColor: "#e8f5e9",
    mb: 3,
  },
  icon: {
    fontSize: 40,
    color: "#2e7d32",
  },
  title: {
    fontSize: "1.5rem",
    fontFamily: "Satoshi",
    fontWeight: 600,
    mb: 2,
    color: "#1a1a1a",
  },
  description: {
    color: "#666666",
    fontFamily: "Satoshi",
    lineHeight: 1.6,
    maxWidth: 350,
    mx: "auto",
  },
  certImage: {
    width: 120,
    mt: 3,
  },
};

const securityItems = [
  {
    icon: <LockIcon sx={styles.icon} />,
    title: "Privacy You Can Count On",
    description:
      "Your personal information is secure with us, never shared without your consent.We use cutting-edge technology to keep your data private and protected.",
  },
  {
    icon: <ConnectWithoutContactIcon sx={styles.icon} />,
    title: "Seamless & Secure Connections",
    description:
      "Communicate with ease and confidence using Vacanzi’s secure messaging system .All interactions are monitored to uphold a safe and respectful platform.",

    showCert: true,
  },

];

const TrustAndSafetyPage = () => {
  const navigate = useNavigate()
  return (
    <>
      <NewNavBar />
  <Typography sx={{backgroundColor: "#fff",pt:2,textDecoration:"underline",color:"#270FFFFF",cursor:"pointer"}} textAlign='center' onClick={()=>navigate('/')}>
        Close Window
        </Typography>
      <Container
        maxWidth="lg"
        sx={{
          fontFamily: "'Satoshi', sans-serif",
          padding: "40px 20px",

          marginBottom: "40px",
          position: "relative",
          overflow: "hidden",
        }}
      >
        

        {/* Content Section */}
        <Box sx={styles.wrapper}>
          <Container sx={styles.container}>
            <Grid container spacing={2}>
              {securityItems.map((item, index) => (
                <Grid item xs={12} md={6} key={index}>
                  <Box sx={styles.gridItem}>
                    <Box sx={styles.iconWrapper}>{item.icon}</Box>
                    <Typography variant="h5" sx={styles.title}>
                      {item.title}
                    </Typography>
                    <Typography variant="body1" sx={styles.description}>
                      {item.description}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default TrustAndSafetyPage;
