const reportJobseekerOptions = [
    {
      value: "Harassment or Threats",
      label: "Harassment or Threats",
    },
    {
      value: "Discrimination",
      label: "Discrimination",
    },
    {
      value: "Inappropriate Content",
      label: "Inappropriate Content",
    },
    {
      value: "Spam or Fraud",
      label: "Spam or Fraud",
    },
    {
      value: "Impersonation",
      label: "Impersonation",
    },
    {
      value: "Data Misuse",
      label: "Data Misuse",
    },
  ];
  
  export default reportJobseekerOptions;
  