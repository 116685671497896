import { CalendarTodayOutlined, EmailOutlined, LocationOnOutlined, PhoneOutlined } from '@mui/icons-material'
import { Avatar, Button, Paper, Typography } from '@mui/material'
import { Box, styled } from '@mui/system'
import React  from 'react'
import { useNavigate } from 'react-router-dom'




const Title = styled(Typography)(({ theme }) => ({
    fontWeight: "bold",
    fontFamily: "poppins",
    whiteSpace: "nowrap",
    fontSize:"20px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  }));
  

const InvitedCandidates = ({jobData,}) => {
    const navigate = useNavigate()
 
  return (
    <Box
    sx={{
      display: "grid",
      gridTemplateColumns: "repeat(2, 1fr)", // Exactly 2 columns
      gap: "20px",
    }}
  >
    {jobData?.invites?.length > 0 ? (
      jobData?.invites?.map((candidate, index) => (
        <Paper
          key={index}
          elevation={0}
          sx={{
            padding: 3,
            backgroundColor: "#ffffff",
            cursor: "pointer",
            borderRadius: "0.7rem", // Updated from second design
            border: "1px solid",
            borderColor: "grey.400",
            width: "100%",
            fontFamily: "poppins",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            overflow: "hidden",
          
          }}
        >
          {/* Profile Image and Name */}
               <Box
                     sx={{
                       display: "flex",
                       alignItems: "center",
                       justifyContent: "space-between",
                       gap: 0.5,
                       mt: 1,
                     }}
                   >
                     <Title>{candidate?.user?.fullName}</Title>
                     <Avatar
                       alt={candidate?.userId?.fullName}
                       src={candidate?.user?.profileImage}
                       sx={{ width: 56, height: 56 }}
                     />
                   </Box>
  
          {/* Email and Contact Details */}
              {/* Email and Contact Details */}
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: { xs: "column", sm: "row" },
                          alignItems: { xs: "flex-start", sm: "center" },
                          justifyContent: "space-between",
                          mt: 2,
                        }}
                      >
                        {/* Email */}
                        <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                          <EmailOutlined fontSize="small" />
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            fontFamily="poppins"
                            sx={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "normal",
                              display: "-webkit-box",
                              WebkitLineClamp: 2,
                              WebkitBoxOrient: "vertical",
                            }}
                          >
                            {candidate?.user?.email}
                          </Typography>
                        </Box>
              
                        {/* Contact */}
                        <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                          <PhoneOutlined fontSize="small" />
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            fontFamily="poppins"
                          >
                            {candidate?.user?.phone || "Not Mentioned"}
                          </Typography>
                        </Box>
                      </Box>
              
                      {/* Location and Viewed On */}
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: { xs: "column", sm: "row" },
                          alignItems: { xs: "flex-start", sm: "center" },
                          justifyContent: "space-between",
                          mt: 2,
                        }}
                      >
                        {/* Location */}
                        <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                          <LocationOnOutlined fontSize="small" />
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            fontFamily="poppins"
                            sx={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "normal",
                              display: "-webkit-box",
                              WebkitLineClamp: 2,
                              WebkitBoxOrient: "vertical",
                            }}
                          >
                            {candidate?.user?.currentLocation || "Not Mentioned"}
                          </Typography>
                        </Box>
              
                        {/* Viewed On */}
                        <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                          <CalendarTodayOutlined fontSize="small" />
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            fontFamily="poppins"
                          >
                            {candidate?.viewedOn
                              ? new Date(candidate?.viewedOn).toLocaleDateString()
                              : "Not Available"}
                          </Typography>
                        </Box>
                      </Box>
  
          {/* View Full Profile Button */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mt: 2, // Added from second design
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#000", // Updated from second design
                textTransform: "none",
                fontFamily: "poppins",
                borderRadius: "0.7rem",
                "&:hover": { backgroundColor: "#000" }, // Updated from second design
                mt: 1, // Added from second design
              }}
              onClick={() =>
                navigate(`/viewJobSeekerProfile/${candidate?.user?._id}`)
              }
            >
              View Full Profile
            </Button>
          </Box>
        </Paper>
      ))
    ) : (
      <Typography
        variant="h6"
        sx={{
          textAlign: "center",
          fontFamily: "poppins",
          color: "gray",
        }}
      >
        No viewed candidates available.
      </Typography>
    )}
  </Box>
  )
}

export default InvitedCandidates