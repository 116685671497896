import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  IconButton,
  Snackbar,
  Typography,
  Container,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { jwtDecode } from "jwt-decode";


const EmployerProfile = () => {
  // const [isEditing, setIsEditing] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [showCompanyDetails, setShowCompanyDetails] = useState(false);
  const [userData, setUserData] = useState({}); // Initialize as empty object


  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setUserData(decodedToken); // Set user data from decoded token
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    }
  }, []);

  // const handleEditClick = () => {
  //   setIsEditing(true);
  // };

  // const handleSaveClick = () => {
  //   setIsEditing(false);
  //   setSnackbarOpen(true);
  // };

  const handleVerifyClick = () => {
    setShowCompanyDetails(true);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };



  return (
    
      <Container>
        <Box
          sx={{
            p: 3,
            bgcolor: "background.paper",
            boxShadow: 3,
            borderRadius: "16px",
            fontFamily: "'IBM Plex Sans', sans-serif",
          }}
        >
          {/* Header Section */}
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            mb={4}
          >
            <Grid item xs={12} sm={6}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  color: "text.primary",
                  fontFamily: "IBM plex sans",
                }}
              >
                Profile
              </Typography>
            </Grid>
            {/* <Grid item xs={12} sm="auto">
              {isEditing ? (
                <Button
                  variant="contained"
                  onClick={handleSaveClick}
                  sx={{
                    bgcolor: "#423bfa",
                    borderRadius: "0.7rem",
                    textTransform: "none",
                    fontFamily: "'IBM Plex Sans', sans-serif",
                    "&:hover": { bgcolor: "#362fd1" },
                  }}
                >
                  Save
                </Button>
              ) : (
                <Button
                disabled
                  variant="contained"
                  onClick={handleEditClick}
                  sx={{
                    bgcolor: "#423bfa",
                    borderRadius: "0.7rem",
                    textTransform: "none",
                    fontFamily: "'IBM Plex Sans', sans-serif",
                    "&:hover": { bgcolor: "#362fd1" },
                  }}
                >
                  Edit
                </Button>
              )}
            </Grid> */}
          </Grid>

          {/* Basic Details Section */}
          <Box mb={4}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                mb: 2,
                color: "text.primary",
                fontFamily: "IBM plex sans",
              }}
            >
              Basic Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="body1"
                  sx={{
                    mb: 1,
                    color: "text.primary",
                    fontFamily: "IBM plex sans",
                    fontWeight: "bold",
                  }}
                >
                  Name
                </Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  disabled
                  value={userData?.name || ""}
                  sx={{
                    fontFamily: "IBM plex sans",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "0.7rem",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="body1"
                  sx={{
                    mb: 1,
                    color: "text.primary",
                    fontFamily: "IBM plex sans",
                    fontWeight: "bold",
                  }}
                >
                  Email
                </Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  disabled
                  value={userData?.email || ""}
                  sx={{
                    fontFamily: "IBM plex sans",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "0.7rem",
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          {/* Mobile Section */}
          <Box mb={4}>
            <Typography
              variant="body1"
              sx={{
                mb: 1,
                color: "text.primary",
                fontFamily: "IBM plex sans",
                fontWeight: "bold",
              }}
            >
              Mobile
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  disabled
                  value={userData?.mobile || "9776170950"}
                  sx={{
                    fontFamily: "IBM plex sans",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "0.7rem",
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          {/* Security Key Section */}
          {/* <Box mb={4}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                mb: 2,
                color: "text.primary",
                fontFamily: "IBM plex sans",
              }}
            >
              Security Key
            </Typography>
            <Typography
              component="legend"
              sx={{
                fontSize: 16,
                display: "flex",
                alignItems: "center",
                fontFamily: "IBM plex sans",
              }}
            >
              Security Key:{" "}
              {securityKey ? (
                <Typography
                  sx={{
                    ml: 1,
                    fontWeight: "bold",
                    fontSize: 16,
                    color: "text.primary",
                    fontFamily: "IBM plex sans",
                  }}
                >
                  {securityKey}
                </Typography>
              ) : (
                <Button
                  variant="contained"
                  onClick={handleGenerateSecurity}
                  sx={{
                    ml: 2,
                    borderRadius: "0.7rem",
                    textTransform: "none",

                    fontFamily: "IBM plex sans",
                    bgcolor: "#423bfa",
                    "&:hover": { bgcolor: "#362fd1" },
                  }}
                >
                  Generate Security Key
                </Button>
              )}
            </Typography>
          </Box> */}

          {
            <Box mb={4}>
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", mb: 2, color: "text.primary" }}
              >
                GST Details
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="body1"
                    sx={{ mb: 1, color: "text.primary" }}
                  >
                    GST No.
                  </Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    disabled
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "0.7rem",
                      },
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Button
                    variant="outlined"
                    onClick={handleVerifyClick}
                    disabled
                    sx={{
                      borderRadius: "0.7rem",
                      textTransform: "none",
                      fontFamily: "'IBM Plex Sans', sans-serif",
                      color: "#423bfa",
                      borderColor: "#423bfa",
                      "&:hover": { borderColor: "#362fd1", color: "#362fd1" },
                    }}
                  >
                    Verify
                  </Button>
                </Grid>
              </Grid>

              {showCompanyDetails && (
                <Box
                  sx={{
                    mt: 2,
                    p: 2,
                    bgcolor: "background.default",
                    borderRadius: "0.7rem",
                    boxShadow: 1,
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ mb: 1, color: "text.primary" }}
                  >
                    <strong>Company Name:</strong> MILLION TALENTS INDIA PRIVATE
                    LIMITED
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ mb: 1, color: "text.primary" }}
                  >
                    <strong>Address:</strong> 10/1, V K PLAZA, RAMAMURTHY NAGAR
                    MAIN ROAD, BANASWADI, BENGALURU URBAN, Bengaluru Urban,
                    Karnataka 560043
                  </Typography>
                  <Typography variant="body2" sx={{ color: "text.secondary" }}>
                    I verify my company details and understand that the invoices
                    would be generated using the same information.
                  </Typography>
                </Box>
              )}
            </Box>
          }

          {/* Snackbar for Notifications */}
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            message={
              showCompanyDetails
                ? "Company details verified"
                : "Profile updated successfully"
            }
            action={
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleCloseSnackbar}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          />
        </Box>
      </Container>
    
  );
};

export default EmployerProfile;
