import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,

  // Divider,
  Grid,
  IconButton,
  Container,
  InputAdornment,
  Divider,
  Tooltip,
} from "@mui/material";
// import GoogleIconImg from "../../../assets/icons/logos/google-icon.png";
import searchCandidates from "../../../styles/searchCandidatesStyles";
import { forgotPasswordApi, resetPasswordApi } from "../../../api/employerAuth";

import PsychologyIcon from "@mui/icons-material/Psychology";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import googlePlay from "../../../assets/images/googlePlay.png";
import appStore from "../../../assets/images/appStore.png";
// import { useNavigate } from "react-router-dom";


// import PeopleIcon from "@mui/icons-material/People";
// import { ChevronRight, ChevronLeft } from "@mui/icons-material";
// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { useEffect } from "react";
// import CountUp from "react-countup";
// import { useInView } from "react-intersection-observer";
import Faq from "../../LoginPageComponents/Faq";

import { Visibility, VisibilityOff } from "@mui/icons-material";

// import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
// import ChatIcon from "@mui/icons-material/Chat";
import Employertestimonial from "./Employertestimonial";
import ContactForm from "./ContactPopup";

import { ThreeDot } from "react-loading-indicators";
import SubScriptionPage from "../../../pages/EmployerPages/EmployerJobPost/SubScriptionPage";


const stats = [
  {
    value: 6000 + "+",
    suffix: "",
    description: "Jobs Posted",
  },
  {
    value: 14,
    suffix: "Lakhs",
    description: "JobSeekers Registered.",
  },
  {
    value: 7.5,
    suffix: "Lakhs",
    description: "Short Videos Uploaded",
  },
  {
    value: 1.5,
    suffix: "Lakhs",
    description: "Conversations",
  },
];

// const videoLinks = [
//   "https://youtube.com/embed/Ume9BKap5I4",
//   "https://youtube.com/embed/xQhV1pD2u_w",
//   "https://youtube.com/embed/2VRTlTVGk0k",
//   "https://youtube.com/embed/Ume9BKap5I4",
//   "https://youtube.com/embed/xQhV1pD2u_w",
//   "https://youtube.com/embed/2VRTlTVGk0k",
//   "https://youtube.com/embed/Ume9BKap5I4",
//   "https://youtube.com/embed/xQhV1pD2u_w",
// ];

const EmployerLoginSignUp = ({
  handleFieldChange,
  errors,
  loginEmail,
  loginPassword,
  setErrors,
  handleSuccess,
  handleError,
  handleLoginSubmit,
  handleSignupSubmit,
  signupEmail,
  signupName,
  signupPhone,
  setValue,
  value,
  setLoading,
  loading,
}) => {
  // const [isInView, setIsInView] = useState(false);
  // const [counterValues, setCounterValues] = useState(stats.map(() => 0));
  const [forgotPassword, setForgotPassword] = useState(false);
  const [verifyEmail, setVerifyEmail] = useState({ rcEmail: "" });
  const [emailVerified, setEmailVerified] = useState(false);
  const [isContactFormVisible, setIsContactFormVisible] = useState(false);

  const token = localStorage.getItem("token");
  // const navigate = useNavigate();
  const [message, setMessage] = useState();
  const [resetData, setResetData] = useState({
    email: "",
    otp: "",
    password: "",
    confirmPassword: "",
  });

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const toggleContactForm = () => {
    setIsContactFormVisible(true);
  };

  const closeContactForm = () => {
    setIsContactFormVisible(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  const handleForgotPassword = () => {
    setForgotPassword(true);
  };
  const handleBack = () => {
    setForgotPassword(false);
  };

  const handleEmailVerification = (e) => {
    setVerifyEmail({ rcEmail: e.target.value });
  };

  const handleSendVerificationMail = async () => {
    setLoading(true);
    try {
      if (!verifyEmail.rcEmail) {

        setErrors((prev) => ({
          ...prev,
          verifyEmail: "Please enter your email",
        }));
        return;
      }

      // Simulate sending verification email
      const formValues = { rcEmail: verifyEmail.rcEmail };
      const response = await forgotPasswordApi(token, formValues);

      setMessage(response.message);
      setEmailVerified(true); // Set email as verified
      setResetData((prev) => ({ ...prev, email: verifyEmail.rcEmail })); // Pre-fill email in resetData
      handleSuccess(response.message || "OTP sent to your email")
    } catch (error) {
      console.log(error.message);
      setErrors((prev) => ({
        ...prev,
        verifyEmail:
          error.message || "Email verification failed, please try again",
      }));
      handleError(error.message || "Failed to send OTP. Please try again.")

    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleLoginSubmit();
    }
  };

  const handleResetData = (key, value) => {
    setResetData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  // const formValues = {
  //   rcEmail: resetData?.email,
  //   otp: resetData?.otp,
  //   newPassword: resetData?.password,
  // };

  const handleSubmitResetPassword = async () => {
    setLoading(true);
    try {
      if (
        !resetData.email ||
        !resetData.otp ||
        !resetData.password ||  
        !resetData.confirmPassword
      ) {

        handleError("Please fill all the fields")
      } else if (resetData.password !== resetData.confirmPassword) {

        handleError("Passwords do not match")
      } else {
        const response = await resetPasswordApi(token, {
          rcEmail: resetData.email,
          otp: resetData.otp,
          newPassword: resetData.password,
        });
 
        handleSuccess(response.message || "Password has been resetted")
     
      }
    } catch (error) {
      console.log(error.message);
 // Show error message
      handleSuccess(error.message || "Failed to reset password. Please try again.")
    } finally {
      setLoading(false);
      setTimeout(()=>{
        window.location.reload();
      },[2000])
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          fontFamily: "IBM Plex Sans",
          minHeight: "100vh",
          backgroundColor: "#f5faf5",
        }}
      >
        {/* login section starte*/}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
            justifyContent: "space-between",
            minHeight: "100vh",
            overflow: "hidden",
            width: "90vw",
            px: 3,
            backgroundColor: "#f5faf5", // Light grayish-white background
          }}
        >
          <Box>
            {value === 0 && !forgotPassword && (
              <Box
                sx={{
                  color: "black",
                  flex: 1,
                  maxWidth: 400,
                  padding: 4,
                  border: "1px solid #ddd",
                  borderRadius: 2,
                  backgroundColor: "#fff",
                  textAlign: "center",
                  boxShadow: "-1px 1px 10px 1px rgba(0, 0, 0, 0.1)", // Shadow effect
                }}
              >
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  gutterBottom
                  sx={{ fontFamily: "IBM Plex Sans" }}
                >
                  Employer Login/Register
                </Typography>

                {/* Email Field */}
                <TextField
                  fullWidth
                  placeholder="Email"
                  margin="normal"
                  sx={{
                    backgroundColor: "white",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "12px", // Apply border radius to the TextField
                    },
                  }}
                  value={loginEmail}
                  onChange={(e) => {
                    // Convert the input value to lowercase
                    const lowercaseValue = e.target.value.toLowerCase();
                    // Update the state with the lowercase value
                    handleFieldChange("loginEmail", lowercaseValue);
                  }}
                  error={!!errors.loginEmail}
                  helperText={errors.loginEmail}
                />

                {/* Password Field */}
                <TextField
                  fullWidth
                  placeholder="Password"
                  type={showPassword ? "text" : "password"}
                  margin="normal"
                  sx={{
                    backgroundColor: "white",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "12px", // Apply border radius to the TextField
                    },
                  }}
                  value={loginPassword}
                  onChange={(e) =>
                    handleFieldChange("loginPassword", e.target.value)
                  }
                  onKeyDown={handleKeyDown}
                  error={!!errors.loginPassword}
                  helperText={errors.loginPassword}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={togglePasswordVisibility}
                          edge="end"
                          aria-label="toggle password visibility"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <Box sx={{ ...searchCandidates.flexboxend }}>
                  <Typography
                    onClick={handleForgotPassword}
                    sx={{
                      fontSize: 13,
                      color: "blue",
                      fontFamily: "IBM Plex Sans",
                      mb: 1,
                      ":hover": {
                        textDecoration: "underline",
                        cursor: "pointer",
                      },
                    }}
                  >
                    Forgot Password?
                  </Typography>
                </Box>

                {/* Login Button */}
                <Button
                  fullWidth
                  variant="contained"
                  sx={{
                    backgroundColor: "#423bfa",
                    color: "white",
                    fontWeight: "bold",
                    padding: "0.5rem",
                    fontFamily: "IBM Plex Sans",
                    "&:hover": { backgroundColor: "#B0F0F0", color: "#000" },

                    textTransform: "none",
                    borderRadius: "10px",
                    mb: 2, // Add margin below the button
                  }}
                  onClick={handleLoginSubmit}
                >
                  {loading ? (
                    <ThreeDot
                      color="#FFFFFFFF"
                      size="small"
                      text=""
                      textColor=""
                    />
                  ) : (
                    "Login"
                  )}
                </Button>

                {/* Divider with "or" */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      mx: 2, // Add horizontal margin around the "or" text
                      color: "text.secondary",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    or
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    my: 2, // Add margin above and below the divider
                  }}
                >
                  <Divider sx={{ flex: 1 }} /> {/* Left divider */}
                  <Typography
                    variant="body2"
                    sx={{
                      mx: 2, // Add horizontal margin around the "or" text
                      color: "text.secondary",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    Don’t have a registered email ID?
                  </Typography>
                  <Divider sx={{ flex: 1 }} /> {/* Right divider */}
                </Box>

                {/* Create Account Button */}
                <Button
                  fullWidth
                  variant="outlined"
                  sx={{
                    backgroundColor: "white",
                    color: "#423bfa",
                    fontWeight: "bold",
                    padding: "0.5rem",
                    fontFamily: "IBM Plex Sans",
                    textTransform: "none",
                    borderRadius: "10px",
                    mb: 2, // Add margin below the button
                  }}
                  onClick={() => setValue(1)} // Navigate to the registration page
                >
                  Create Account
                </Button>

                {/* Contact Button */}
                <Button
                  fullWidth
                  variant="contained"
                  sx={{
                    backgroundColor: "#423bfa",
                    color: "white",
                    fontWeight: "bold",
                    padding: "0.5rem",
                    fontFamily: "IBM Plex Sans",
                    textTransform: "none",
                    borderRadius: "10px",
                    "&:hover": { backgroundColor: "#B0F0F0", color: "#000" },
                  }}
                  onClick={toggleContactForm}
                >
                  Contact Us
                </Button>
              </Box>
            )}
            {isContactFormVisible && <ContactForm onClose={closeContactForm} />}

            {/* Forgot Password Tab */}
            {forgotPassword && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 400,
                }}
              >
                <Box
                  sx={{ width: "100%", display: "flex", alignItems: "start" }}
                >
                  <Tooltip title="Back To Login Form">
                    <ArrowBackIcon
                      onClick={handleBack}
                      sx={{
                        fontSize: "30px",
                        opacity: 0.8,
                        cursor: "pointer",
                        mb: 3,
                      }}
                    />
                  </Tooltip>
                </Box>

                {!emailVerified && (
                  <>
                    <img
                      src="/vacanziLogo.png"
                      alt="Vacanzi Logo"
                      style={{ width: 50, height: 50, marginBottom: 10 }}
                    />
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontSize: 19,
                        fontFamily: "Satoshi",
                        fontWeight: "bold",
                      }}
                    >
                      Provide your Email for Reset Password
                    </Typography>
                  </>
                )}

                {!emailVerified ? (
                  // Show email verification form if email is not verified
                  <Box sx={{ maxWidth: 400, width: "100%" }}>
                    <TextField
                      fullWidth
                      placeholder="Email"
                      margin="normal"
                      sx={{
                        backgroundColor: "white",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "12px", // Apply border radius to the TextField
                        },
                      }}
                      value={verifyEmail.rcEmail}
                      required
                      onChange={handleEmailVerification}
                      error={!!errors.verifyEmail}
                      helperText={errors.verifyEmail}
                    />
                    {message && <Typography>{message}</Typography>}
                    <Box sx={{ ...searchCandidates.flexboxCenter }}>
                      <Button
                        fullWidth
                        variant="contained"
                        sx={{
                          backgroundColor: "#423bfa",
                          borderRadius: "0.7rem",
                          textTransform: "none",
                          color: "white",
                          fontWeight: "bold",
                          "&:hover": {
                            backgroundColor: "#B0F0F0",
                            color: "#000",
                          },

                          padding: "0.5rem",
                          mt: 1,
                        }}
                        onClick={handleSendVerificationMail}
                      >
                        {loading ? (
                          <ThreeDot
                            color="#FFFFFFFF"
                            size="small"
                            text=""
                            textColor=""
                          />
                        ) : (
                          "Verify"
                        )}
                      </Button>
                    </Box>
                  </Box>
                ) : (
                  // Show the reset password form after email verification
                  <Box
                    sx={{
                      maxWidth: 400,
                      width: "100%",
                      padding: 4,
                      border: "1px solid #ddd",
                      borderRadius: 2,
                      backgroundColor: "#fff",
                      textAlign: "center",
                      boxShadow: "-1px 1px 10px 1px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{ fontWeight: "bold" }}
                    >
                      Reset Your Password
                    </Typography>
                    <TextField
                      fullWidth
                      placeholder="Email"
                      margin="normal"
                      sx={{
                        backgroundColor: "white",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "12px",
                          fontFamily: "Satoshi", // Apply border radius to the TextField
                        },
                      }}
                      value={resetData.email}
                      disabled
                    />
                    <TextField
                      fullWidth
                      placeholder="OTP"
                      margin="normal"
                      type="number"
                      sx={{
                        backgroundColor: "white",

                        "& input[type=number]": {
                          MozAppearance: "textfield",
                        },
                        "& input[type=number]::-webkit-inner-spin-button": {
                          display: "none",
                        },
                        "& input[type=number]::-webkit-outer-spin-button": {
                          display: "none",
                        },
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "12px",
                          fontFamily: "Satoshi", // Apply border radius to the TextField
                        },
                      }}
                      value={resetData.otp}
                      onChange={(e) => handleResetData("otp", e.target.value)}
                    />
                    <TextField
                      fullWidth
                      placeholder="New Password"
                      margin="normal"
                      type="password"
                      sx={{
                        backgroundColor: "white",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "12px",
                          fontFamily: "Satoshi", // Apply border radius to the TextField
                        },
                      }}
                      value={resetData.password}
                      onChange={(e) =>
                        handleResetData("password", e.target.value)
                      }
                    />
                    <TextField
                      fullWidth
                      placeholder="Confirm New Password"
                      margin="normal"
                      type="password"
                      sx={{
                        backgroundColor: "white",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "12px",
                          fontFamily: "Satoshi", // Apply border radius to the TextField
                        },
                      }}
                      value={resetData.confirmPassword}
                      onChange={(e) =>
                        handleResetData("confirmPassword", e.target.value)
                      }
                    />
                    <Button
                      fullWidth
                      variant="contained"
                      sx={{
                        backgroundColor: "#423bfa",
                        borderRadius: "0.7rem",
                        textTransform: "none",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "#B0F0F0",
                          color: "#000",
                        },

                        fontWeight: "bold",
                        padding: "0.5rem",
                        mt: 2,
                      }}
                      onClick={handleSubmitResetPassword}
                    >
                      {loading ? (
                        <ThreeDot
                          color="#FFFFFFFF"
                          size="small"
                          text=""
                          textColor=""
                        />
                      ) : (
                        "Reset Password"
                      )}
                    </Button>
                  </Box>
                )}
        
              </Box>
            )}
            {/* Sign Up Tab */}
            {value === 1 && (
              <Box
                sx={{
                  color: "black",
                  flex: 1,
                  maxWidth: 400,
                  padding: 4,
                  border: "1px solid #ddd",
                  borderRadius: 2,
                  backgroundColor: "#fff",
                  textAlign: "center",
                  boxShadow: "1px 1px 7px 3px rgba(0, 0, 0, 0.1)", // Shadow effect
                }}
              >
                <Typography variant="h5" fontWeight="bold" gutterBottom>
                  Register
                </Typography>
                <TextField
                  fullWidth
                  placeholder="Full Name"
                  margin="normal"
                  sx={{
                    backgroundColor: "white",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "12px", // Apply border radius to the TextField
                    },
                  }}
                  value={signupName}
                  onChange={(e) =>
                    handleFieldChange("signupName", e.target.value)
                  }
                  error={!!errors.signupName}
                  helperText={errors.signupName}
                />
                <TextField
                  fullWidth
                  placeholder="Email"
                  margin="normal"
                  sx={{
                    backgroundColor: "white",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "12px", // Apply border radius to the TextField
                    },
                  }}
                  value={signupEmail}
                  onChange={(e) =>
                    handleFieldChange("signupEmail", e.target.value)
                  }
                  error={!!errors.signupEmail}
                  helperText={errors.signupEmail}
                />
                <TextField
                  fullWidth
                  placeholder="Phone"
                  type="text" // Change type to "text" to remove number input arrows
                  inputProps={{
                    maxLength: 10, // Restrict input to 10 digits
                    pattern: "[0-9]*", // Allow only numbers
                    inputMode: "numeric", // Open numeric keyboard on mobile
                  }}
                  margin="normal"
                  sx={{
                    backgroundColor: "white",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "12px",
                    },
                  }}
                  value={signupPhone}
                  onChange={(e) => {
                    const value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                    if (value.length <= 10) {
                      handleFieldChange("signupPhone", value);
                    }
                  }}
                  error={!!errors.signupPhone}
                  helperText={errors.signupPhone}
                />

                <Button
                  fullWidth
                  variant="contained"
                  sx={{
                    backgroundColor: "#423bfa",
                    color: "white",
                    fontWeight: "bold",
                    textTransform: "none",
                    mt: 1,
                    padding: "0.5rem",
                    "&:hover": { backgroundColor: "#B0F0F0", color: "#000" },
                  }}
                  onClick={handleSignupSubmit}
                >
                  {loading ? (
                    <ThreeDot
                      color="#FFFFFFFF"
                      size="small"
                      text=""
                      textColor=""
                    />
                  ) : (
                    "Sign Up"
                  )}
                </Button>

                {/* <Typography align="center" variant="body2" sx={{ mt: 2 }}>
                or continue with
              </Typography> */}
                {/* <Button fullWidth color="primary" sx={{ mt: 1 }}>
                <img src={GoogleIconImg} alt="google-logo" />
              </Button> */}
                <Typography align="center" variant="body2" sx={{ mt: 2 }}>
                  Already have an account?{" "}
                  <Button
                    onClick={() => setValue(0)}
                    sx={{
                      textTransform: "none",
                      ":hover": {
                        textDecoration: "underline",
                        backgroundColor: "#B0F0F0",
                        color: "#000",
                      },
                    }}
                  >
                    Login here
                  </Button>
                </Typography>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              flex: 1,
              textAlign: { xs: "center", md: "center" },
              marginLeft: { md: 5 },
              color: "#191a19",
            }}
          >
            <Typography
              variant="h3"
              fontWeight="regular"
              gutterBottom
              sx={{
                fontFamily: "IBM Plex Sans",
                fontWeight: 600,
                color: "#414b5d",
                fontSize: "42px",
              }}
            >
              <span style={{ color: "#423bfa" }}>Watch</span>{" "}
              <FiberManualRecordIcon />{" "}
              <span style={{ color: "darkgreen" }}>Decide</span>{" "}
              <FiberManualRecordIcon />{" "}
              <span style={{ color: "#15DE07FF" }}>Hire</span>
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                fontFamily: "IBM Plex Sans",
                color: "#8892a3",
                fontSize: "26px",
                fontWeight: "bold",
              }}
            >
              Visualize Talent - Short Videos for Swift Hiring Decisions
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                fontFamily: "IBM Plex Sans",
                color: "#8892a3",
                fontSize: "22px",
                fontWeight: "bold",
              }}
            >
              Find the Best Talent in Hours
            </Typography>

            {/* Image below typography */}
            <Box
              sx={{
                marginTop: 3, // Space between the text and the image
                textAlign: "center", // Center the image horizontally
              }}
            >
              <img
                src="employerHero.png" // Replace with your image URL
                alt="A candidate preparing for an interview" // Provide meaningful alt text
                style={{
                  width: "100%", // Adjust width as needed
                  maxWidth: "500px", // Optional: limit the max width
                  opacity: 0.5, // Adjust opacity here (lower value = more transparent)
                }}
              />
            </Box>
          </Box>
        </Box>

        {/* banner starts */}
        <Box
          sx={{
            width: "100%", // Ensure the Box spans the full width of its container
            height: "auto", // Height will adjust based on the image's aspect ratio
            overflow: "hidden", // Hide any overflow (optional)
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#f5faf5", // Background color for the Box
          }}
        >
          <Box
            sx={{
              position: "relative",
              display:"flex",
              justifyContent:"center",
              alignItems:"center", // Required for absolute positioning of the button
              width: "75%", // Match the image width
            }}
          >
            <img
              src="/employerbanner2.webp" // Replace with the path to your .webp image
              alt="" // Add a descriptive alt text
              style={{
                width: "50%", // Make the image fill the width of the Box
                height: "auto", // Maintain the image's aspect ratio
                display: "block", // Ensure the image behaves as a block element
              }}
            />

            {/* Button positioned at the bottom-right corner of the image */}
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#18181b",
                color: "#ffffff",
                borderRadius: "0.7rem",
                textTransform: "none",
                fontSize: "20px",
                position: "absolute", // Position the button absolutely within the Box
                top: "80%", // Adjust this value to position the button vertically
                right: "30%", // Adjust this value to position the button horizontally
                transform: "translate(-50%, -50%)", // Center the button
                mt: 3,
                padding: "0.5rem 2.5rem",
                fontFamily: "IBM Plex Sans",
                display: "flex",
                justifyContent: "space-between", // Push the text to the left and icon to the right
                alignItems: "center", // Vertically center text and icon
                ":hover": {
                  backgroundColor: "#18181b",
                  color: "#ffffff",
                },
              }}
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              Post a Job
              <ArrowForwardIosOutlinedIcon sx={{ ml: 3 }} />{" "}
              {/* Margin left to space the icon */}
            </Button>
          </Box>
        </Box>
        {/* banner ends */}

        {/* logo slide starts*/}
        <Box sx={{ bgcolor: "background.paper", width: "100vw" }}>
          <Container maxWidth="xl">
            <Grid
              container
              spacing={4}
              sx={{
                mt: 4, // Margin top
                mb: 4, // Added margin bottom to balance spacing
                "& .MuiGrid-item": {
                  borderRight: "1px solid #E2E8F0",
                  "&:last-child": {
                    borderRight: "none",
                  },
                },
              }}
            >
              {stats.map((stat, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  key={index}
                  sx={{
                    textAlign: "center",
                    "&:not(:last-child)": {
                      borderRight: { md: "1px solid #E2E8F0" },
                    },
                    borderRight: {
                      xs: "none",
                      md:
                        index < stats.length - 1 ? "1px solid #E2E8F0" : "none",
                    },
                  }}
                >
                  <Typography
                    variant="h3"
                    component="div"
                    sx={{
                      color: "#423bfa",
                      fontWeight: "bold",
                      mb: 2,
                      display: "flex",
                      alignItems: "center",

                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="h3"
                      sx={{ color: "#423bfa", fontWeight: "bold", mr: 2 }}
                    >
                      {stat.value && stat.value}
                    </Typography>
                    <Typography
                      variant="h3"
                      sx={{ color: "#423bfa", fontWeight: "bold" }}
                    >
                      {stat.suffix && stat.suffix}
                    </Typography>
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#475569",
                      maxWidth: 200,
                      mx: "auto",
                      ml: 6,
                    }}
                  >
                    {stat.description}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>

        {/* <ImageCarousel/> */}
        {/* <Box
          sx={{
            position: "relative",
            width: "100%", // Ensures the video takes full width
            height: "100%", // Make the container take full viewport height
            display: "flex", // Flexbox to center content
            justifyContent: "center", // Center video horizontally
            alignItems: "center",
            backgroundColor:"white",
            mt: 7, // Center video vertically
            border:"none"
          }}
        >
          <video
            src="sliderVideo.mp4" // Replace with your video URL
            autoPlay
            loop
            muted
          
            style={{
               // Make video fill the height of the container
              objectFit: "contain", // Ensure the video covers the whole container
              width:"50%",

              height:"60%"
            }}
          />
        </Box> */}

        <Box
          sx={{
            textAlign: "center",
            padding: 3,
            overflow: "hidden",
            backgroundColor: "white",
            color: "#191a19",
            borderRadius: "10px",
            margin: 0,
            marginTop: 13,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              marginBottom: 4,
              fontFamily: "IBM plex sans",
            }}
          >
            Building Careers with 3000+ Employers
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "white",
              animation: "scroll 15s linear infinite",
              "@keyframes scroll": {
                "0%": { transform: "translateX(100%)" },
                "100%": { transform: "translateX(-100%)" },
              },
            }}
          >
            {/* Logo Images */}
            {/* <img
              src="/logos1.png"
              alt="Flipkart"
              style={{ height: 80, marginRight: 30 }}
            /> */}
            <img
              src="/logos2.png"
              alt="BigBasket"
              style={{ height: 80, marginRight: 30 }}
            />
            <img
              src="/logos3.png"
              alt="HDFC Bank"
              style={{ height: 80, marginRight: 30 }}
            />
            <img
              src="/logos4.png"
              alt="Swiggy"
              style={{ height: 80, marginRight: 30 }}
            />
            <img
              src="/logos5.png"
              alt="Uber"
              style={{ height: 80, marginRight: 30 }}
            />
            <img
              src="/logos6.png"
              alt="Urban Company"
              style={{ height: 80, marginRight: 30 }}
            />
            <img
              src="/logos7.png"
              alt="Zomato"
              style={{ height: 80, marginRight: 30 }}
            />
            <img
              src="/logos8.png"
              alt="Amazon"
              style={{ height: 80, marginRight: 30 }}
            />
            <img
              src="/logos9.png"
              alt="Paytm"
              style={{ height: 80, marginRight: 30 }}
            />
            <img
              src="/logos10.png"
              alt="Myntra"
              style={{ height: 80, marginRight: 30 }}
            />
            <img
              src="/logos11.png"
              alt="Tata Motors"
              style={{ height: 80, marginRight: 30 }}
            />
          </Box>

          <Box
            sx={{
              textAlign: "center",
              marginBottom: 4,
              color: "black",
              backgroundColor: "white",
              marginTop: 4,
            }}
          ></Box>
        </Box>
        {/* image slide ends */}
        {/* banner image */}

        {/* why vacanzi start */}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            paddingTop: 2,
            backgroundColor: "#ffffff",
            width: "100vw",
          }}
        >
          <Typography
            variant="h3"
            sx={{ fontWeight: "bold", fontFamily: "IBM plex sans", mb: 2 }}
          >
            Why Vacanzi ?
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100vw", // Ensure the parent container spans the full width of the screen
              paddingBottom: 3,
            }}
          >
            {/* Left Box - Video Links */}
            <Box
              sx={{
                flex: "0 0 50%", // Set width to 50%
                display: "flex",
                flexWrap: "wrap",
                gap: "1rem",
                justifyContent: "space-between",
                background: "linear-gradient(to right, #f6fdfb, #ecfefa)",
                p: 5,
                height: "500px", // Ensure the height matches the right box
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 1,
                  mr: 3, // Slight gap between the video and app download buttons
                }}
              >
                <img
                  src="/whyVacanziGif.gif"
                  alt=""
                  style={{
                    width: "500px",
                    height: "100%", // Default for XL
                    objectFit: "cover",
                    borderRadius: "12px",
                  }}
                />
                  <Box
                                  sx={{
                                    display: "flex",
                                                     flexDirection: "column", // Stack items vertically
                                    alignItems: "flex-start", // Center items horizontally
                                    justifyContent: "space-around",
                                    gap: 2,
                                  }}
                                >
                                  <Button
                                    variant="contained"
                                    sx={{
                                      borderRadius: "0.7rem",
                                      textTransform: "none",
                                      fontFamily: "IBM Plex Sans",
                                      ml:8,
                                      backgroundColor: "#000",
                                      "&:hover": {
                                        backgroundColor: "#000",
                                      },
                                    }}
                                  >
                                    Download The App Now
                                  </Button>
                  
                                  <Box
                                    sx={{
                                      display: "flex",
                                      gap: 2,
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <img
                                      src={appStore}
                                      alt="App Store"
                                      style={{ width: "150px" }}
                                    />
                                    <img
                                      src={googlePlay}
                                      alt="Google Play"
                                      style={{ width: "150px" }}
                                    />
                                  </Box>
                                </Box>
              </Box>
              
            </Box>

            {/* Right Box - Employers Section */}
            <Box
              sx={{
                flex: "0 0 50%", // Set width to 50%
                display: "flex",
                flexDirection: "column",
                background: "linear-gradient(to right, #ffffff, #f0eeff)",
                height: "500px", // Ensure the height matches the left box
              }}
            >
              <Typography
                variant="h4"
                textAlign={"start"}
                sx={{
                  ml: 3,
                  fontFamily: "IBM plex sans",
                  color: "#000000",
                  mt: 3,
                }}
              >
               For Employers
              </Typography>
              <Box
                sx={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  gap: "1rem",
                  padding: "2rem",
                  textAlign: "start",
                  fontFamily: "IBM Plex Sans, sans-serif",
                }}
              >
                {/* Instantly Stand Out Section */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <PsychologyIcon sx={{ color: "#000" }} />
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      fontFamily: "IBM Plex Sans",
                      color: "#000",
                    }}
                  >
                    AI-Driven Hiring
                  </Typography>
                </Box>
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "IBM Plex Sans",
                    textAlign: "left",
                    color: "#000",
                  }}
                >
                  Assess Personality, Skills, and Communication in Seconds
                </Typography>

                {/* Fast-Track Your Job Search Section */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                    mt: 2,
                  }}
                >
                  <RocketLaunchIcon sx={{ color: "#000" }} />
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      fontFamily: "IBM Plex Sans",
                      color: "#000",
                    }}
                  >
                    Smart Hiring Made Easy
                  </Typography>
                </Box>
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "IBM Plex Sans",
                    textAlign: "left",
                    color: "#000",
                  }}
                >
                  AI + Short Videos for Faster, Better Talent Insights
                </Typography>

                {/* Quick Connections Section */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                    mt: 2,
                  }}
                >
                  <BusinessCenterIcon sx={{ color: "#000" }} />
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      fontFamily: "IBM Plex Sans",
                      color: "#000",
                    }}
                  >
                    Post Jobs for Free
                  </Typography>
                </Box>
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "IBM Plex Sans",
                    textAlign: "left",
                    color: "#000",
                  }}
                >
                  Harness AI to Streamline Recruitment Like Never Before
                </Typography>

                {/* Search Jobs Button */}
        
              </Box>
            </Box>
          </Box>
          {/* <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#f5faf5",
            width: "100vw",
             mb:6,
            px: "8rem",
            gap: "2rem",
          }}
        >
          {features.map((feature) => (
            <Grid item key={feature.title} sx={{ textAlign: "center" }}>
              {feature.icon}
              <Typography
                variant="h6"
                component="div"
                sx={{ mt: 2, fontFamily: "IBM Plex Sans" }}
              >
                {feature.title}
              </Typography>
              <Typography
                variant="body2"
                color="text.primary"
                sx={{ mt: 1, fontFamily: "IBM Plex Sans" }}
              >
                {feature.description}
              </Typography>
            </Grid>
          ))}
        </Box> */}
        </Box>
        {/* why vacanszi ends */}

        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#f1eafa",
            width: "100vw",
            height: 300,
            padding: "2rem",
            gap: "2rem",
          }}
        >
          {features.map((feature) => (
            <Grid item key={feature.title} sx={{ textAlign: "center" }}>
              {feature.icon}
              <Typography
                variant="h6"
                component="div"
                sx={{ mt: 2, fontFamily: "IBM Plex Sans" }}
              >
                {feature.title}
              </Typography>
              <Typography
                variant="body2"
                color="text.primary"
                sx={{ mt: 1, fontFamily: "IBM Plex Sans" }}
              >
                {feature.description}
              </Typography>
            </Grid>
          ))}
        </Box> */}
        <Employertestimonial />

        <Box
          sx={{
            py: 8,
            bgcolor: "background.paper",
            fontFamily: "IBM Plex Sans,",
            width: "100vw",
          }}
        >
          <SubScriptionPage/>
          <Faq category="Employers" />
        </Box>
           
      </Box>
    </>
  );
};

export default EmployerLoginSignUp;
