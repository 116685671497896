import React from "react";
import {
  Box,
  Skeleton,
  Card,
  CardContent,
} from "@mui/material";

const SupportSkeleton = () => {
  return (
    <Box p={5} bgcolor="#f5f5f5">
      <Box display="flex" justifyContent="center" flexWrap="wrap" gap={4}>
        {[1, 2, 3].map((item) => (
          <Card
            key={item}
            sx={{
              width: 320,
              borderRadius: 3,
              boxShadow: 3,
              textAlign: "center",
              bgcolor: "white",
            }}
          >
            <CardContent>
              <Skeleton variant="circular" width={135} height={135} sx={{ mx: 'auto' }} />
              <Skeleton variant="text" width="60%" height={40} sx={{ mx: 'auto', mt: 2 }} />
              <Skeleton variant="text" width="80%" height={24} sx={{ mx: 'auto', mb: 2 }} />
              <Skeleton variant="text" width="70%" height={20} sx={{ mx: 'auto' }} />
              <Skeleton variant="text" width="80%" height={24} sx={{ mx: 'auto' }} />
              <Skeleton variant="text" width="80%" height={24} sx={{ mx: 'auto' }} />
              <Box pt={3}>
                <Skeleton variant="rectangular" width={120} height={36} sx={{ mx: 'auto', borderRadius: 2 }} />
              </Box>
            </CardContent>
          </Card>
        ))}
      </Box>

    
    </Box>
  );
};

export default SupportSkeleton;