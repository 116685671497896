import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getUnlockedCandidatesApi } from "../../api/JobSeekerDetailsApi";
import CandidateProfileCard from "../../components/AdvancedComponents/CandidateSearchLists/CandidateProfileCard";
import { CircularProgress, Grid, Skeleton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import CustomPagination from "../../components/AdvancedComponents/CustomPagination";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const UnlockedCandidates = () => {
  const [profiles, setProfiles] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const orgId = localStorage.getItem("organizationId");
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const getUnlockedCandidates = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getUnlockedCandidatesApi(orgId, token);
      setProfiles(response?.seenUsers);
      console.log("response", response);
      setTotalPages(response?.pagination?.totalPages || 1);
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  }, [orgId, token]);

  useEffect(() => {
    getUnlockedCandidates();
  }, [getUnlockedCandidates]);

  const displayedProfiles = useMemo(() => {
    if (!profiles?.length) return [];
    const startIndex = (currentPage - 1) * itemsPerPage;
    return profiles.slice(startIndex, startIndex + itemsPerPage);
  }, [profiles, currentPage, itemsPerPage]);

  const handleItemsPerPageChange = (items) => {
    setItemsPerPage(items);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Box sx={{ position: "relative", minHeight: "100vh", padding: 2 }}>
      {/* Back Button in Top-Left Corner */}
      <Typography
        onClick={handleBack}
        sx={{
          position: "absolute",
          top: 16,
          left: 16,
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          color: "grey",
          "&:hover": {
            color: "primary.dark",
          },
          zIndex: 1, // Ensure it stays above other content
        }}
      >
        <ArrowBackIcon sx={{ marginRight: "4px" }} />
      </Typography>

      {/* Main Content */}
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            backgroundColor: "rgba(255, 255, 255, 0.8)",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={2} justifyContent="center" sx={{ mt: 4 }}>
          <Grid item xs={12} md={8}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 2,
                mb: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: 2,
                  width: "100%",
                }}
              >
                <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                  <CustomPagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                    itemsPerPage={itemsPerPage}
                    onItemsPerPageChange={handleItemsPerPageChange}
                  />
                </Box>
              </Box>
            </Box>

            {/* Profile Cards */}
            {loading ? (
              <Grid container spacing={2}>
                {Array.from({ length: itemsPerPage }).map((_, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Skeleton variant="rectangular" height={300} />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Box sx={{ maxWidth: "800px", margin: "0 auto" }}>
                <CandidateProfileCard
                  profiles={profiles}
                  setProfiles={setProfiles}
                  displayedProfiles={displayedProfiles}
                />
              </Box>
            )}
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default UnlockedCandidates;
