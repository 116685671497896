import { useState } from "react";
import {
  Card,
  CardContent,
  TextField,
  Button,
  Box,
  styled,
  Typography,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { getContactApi } from "../../../api/jobseeker";

import { ThreeDot } from "react-loading-indicators";

const StyledCard = styled(Card)({
  maxWidth: 400,
  margin: "40px auto",
  borderRadius: 16,
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
});

const StyledTextField = styled(TextField)({
  marginBottom: 12,
  fontFamily: "Poppins",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    fontFamily: "Poppins",
    "& fieldset": {
      borderColor: "#E0E0E0",
    },
    "&:hover fieldset": {
      borderColor: "#B0B0B0",
    },
    "&.Mui-error fieldset": {
      borderColor: "#d32f2f",
    },
  },
  "& .MuiFormHelperText-root": {
    color: "#d32f2f",
    fontFamily: "Poppins",
  },
});

const SubmitButton = styled(Button)({
  width: "100%",
  padding: "6px",
  borderRadius: "0.7rem",
  backgroundColor: "#000000",
  color: "white",
  textTransform: "none",
  fontSize: 16,
  fontFamily: "Poppins",
  "&:hover": {
    backgroundColor: "#000000",
  },
});

export default function ContactForm({ onClose, handleError, handleSuccess }) {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    company: "",
    email: "",
    phone: "", // Added phone field
    subject: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    company: "",
    email: "",
    phone: "", // Added phone error
    subject: "",
    message: "",
  });

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validatePhone = (phone) => {
    // Regex for Indian numbers: allows optional +91 followed by exactly 10 digits starting with 6-9
    const regex = /^(?:\+91)?[6-9]\d{9}$/;
    return regex.test(phone);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      company: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
    };

    if (!formData.company.trim()) {
      newErrors.company = "Company Name is required";
      isValid = false;
    }
    if (!formData.subject.trim()) {
      newErrors.subject = "Subject is required";
      isValid = false;
    }
    if (!formData.message.trim()) {
      newErrors.message = "Message is required";
      isValid = false;
    }
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
      isValid = false;
    } else if (!validateEmail(formData.email)) {
      newErrors.email = "Invalid email address";
      isValid = false;
    }
    if (!validatePhone(formData.phone)) {
      newErrors.phone =
        "Invalid Indian phone number (e.g., +919876543210 or 9876543210)";
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      try {
        await getContactApi(formData);

        handleSuccess(
          "Your request has been received! Our support team will reach out to you shortly"
        );
      } catch (error) {
        handleError(
          error.message || "Failed to submit the form. Please try again"
        );
      } finally {
        setLoading(false);
        onClose();
      }
    }
  };

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        backdropFilter: "blur(4px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1000,
      }}
    >
      <StyledCard>
        <CardContent sx={{ p: 3 }}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={onClose}
              sx={{ minWidth: 0, padding: 0, color: "#666" }}
            >
              <CloseOutlinedIcon />
            </Button>
          </Box>
          <Typography
            variant="h5"
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              mb: 3,
              fontFamily: "Poppins",
            }}
          >
            Contact
          </Typography>
          <form onSubmit={handleSubmit}>
            <Typography sx={{ fontFamily: "Poppins", mb: 1 }}>
              Company Name
            </Typography>
            <StyledTextField
              fullWidth
              placeholder="Enter Your Company Name"
              name="company"
              value={formData.company}
              onChange={handleInputChange}
              error={!!errors.company}
              helperText={errors.company}
            />
            <Typography sx={{ fontFamily: "Poppins", mb: 1 }}>Email</Typography>
            <StyledTextField
              fullWidth
              placeholder="Enter Your Email here"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              error={!!errors.email}
              helperText={errors.email}
            />
            <Typography sx={{ fontFamily: "Poppins", mb: 1 }}>Phone</Typography>
            <StyledTextField
              fullWidth
              placeholder="Enter Your Phone Number (e.g., 9876543210)"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              error={!!errors.phone}
              helperText={errors.phone}
              inputProps={{ maxLength: 10 }} // Limits input to 10 characters
              type="tel" // Use "tel" instead of "number" to avoid arrows and improve mobile keyboard
              sx={{
                "& input[type=number]": {
                  "-moz-appearance": "textfield", // Firefox
                },
                "& input[type=number]::-webkit-outer-spin-button": {
                  "-webkit-appearance": "none", // Chrome, Safari, Edge
                  margin: 0,
                },
                "& input[type=number]::-webkit-inner-spin-button": {
                  "-webkit-appearance": "none", // Chrome, Safari, Edge
                  margin: 0,
                },
              }}
            />
            <Typography sx={{ fontFamily: "Poppins", mb: 1 }}>
              Subject
            </Typography>
            <StyledTextField
              fullWidth
              placeholder="Enter Your Subject Here"
              name="subject"
              value={formData.subject}
              onChange={handleInputChange}
              error={!!errors.subject}
              helperText={errors.subject}
            />
            <Typography sx={{ fontFamily: "Poppins", mb: 1 }}>
              Message
            </Typography>
            <StyledTextField
              rows={2}
              fullWidth
              placeholder="Enter Your Message Here"
              name="message"
              value={formData.message}
              onChange={handleInputChange}
              error={!!errors.message}
              helperText={errors.message}
            />
            <SubmitButton variant="contained" disableElevation type="submit">
              {loading ? (
                <ThreeDot color="#FFFFFFFF" size="small" text="" textColor="" />
              ) : (
                "  Submit"
              )}
            </SubmitButton>
          </form>
        </CardContent>
      </StyledCard>
    </Box>
  );
}
