import * as React from "react";
import { useState, useEffect } from "react";
import { Button, Typography, Tooltip } from "@mui/material";
import { Box, Container } from "@mui/system";
import { getInvoicesApi } from "../../../api/subscriptions";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Link,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import SubScriptionPage from "../EmployerJobPost/SubScriptionPage";
import { FourSquare } from "react-loading-indicators";

const SubscriptionStatus = () => {
  const location = useLocation();
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(
    location?.state?.tabValue ? location?.state?.tabValue : 0
  ); // Track the selected tab
  const orgId = localStorage.getItem("organizationId");
  const token = localStorage.getItem("token");

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontFamily: "IBM Plex Sans, sans-serif",
    "&.MuiTableCell-head": {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.text.primary,
      fontWeight: 600,
      borderBottom: "2px solid #e0e0e0",
    },
  }));

  const StyledLink = styled(Link)({
    color: "#0078D4",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  });

  const navigate = useNavigate();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  };

  const redirectUrl = localStorage.getItem("redirectUrl");
  useEffect(() => {
    if (redirectUrl) {
      setSelectedTab(1);
    }
  }, [redirectUrl]);

  useEffect(() => {
    const fetchInvoices = async () => {
      setLoading(true);
      try {
        const response = await getInvoicesApi(orgId, token);
        setInvoices(response.invoices || []);
      } catch (error) {
        console.log(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchInvoices();
  }, [orgId, token]);

  const jsonToCSV = (data) => {
    const headers = Object.keys(data[0]).join(",") + "\n";
    const rows = data
      .map((row) =>
        Object.values(row)
          .map((value) => `"${value}"`) // Wrap values in quotes to handle commas
          .join(",")
      )
      .join("\n");
    return headers + rows;
  };

  const handleDownloadCSV = () => {
    try {
      const csvData = invoices.map((invoice) => ({
        Invoice: invoice.invoiceId,
        "Billing Date": formatDate(invoice.subscribedOn),
        Amount: `${invoice.amount} INR`,
        Status:
          new Date(invoice.validUntil) > new Date() ? "Active" : "Inactive",
        "Start Date": formatDate(invoice.startDate),
        "End Date": formatDate(invoice.endDate),
      }));

      const csvContent = jsonToCSV(csvData);
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.setAttribute("download", "Invoices.csv");
      link.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error generating CSV:", error);
    }
  };

  const handleBack = () => {
    navigate(-1); // Go back to the previous page
  };

  const handleButtonClick = (index) => {
    setSelectedTab(index); // Update selected tab based on button click
  };


  return (
    <>
      <Typography
        onClick={handleBack}
        gutterBottom
        sx={{
          cursor: "pointer",
          display: "flex",
          ml: 1,
          alignItems: "center",
          color: "grey",
          "&:hover": {
            color: "primary.dark",
          },
        }}
      >
        <ArrowBackIcon sx={{ marginRight: "4px" }} />
      </Typography>
      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
            flexWrap: "wrap",
            marginBottom: 3,
          }}
        >
          {/* First Button */}

           <Button
            onClick={() => handleButtonClick(1)} // Sets selectedTab to 1
            sx={{
              textTransform: "none",

              backgroundColor: selectedTab === 1 ? "#423bfa" : "#474747FF", // Blue if selected, light gray if not
              ":hover": {
                backgroundColor: selectedTab === 1 ? "#423bfa" : "#515151FF", // Black if first button is selected, dark gray if not
                color: "#FFFFFFFF", // White text on hover
              },
              color: selectedTab === 0 ? "#ffffff" : "#ffffff", // White if first button is selected, black if not
              padding: "8px 60px",
              borderRadius: 0,
            }}
          >
            Plans
          </Button>

           {/* Second Button */}
          <Button
            onClick={() => handleButtonClick(0)} // Sets selectedTab to 0
            sx={{
              textTransform: "none",
              backgroundColor: selectedTab === 0 ? "#423bfa" : "#474747FF", // Blue if selected, light gray if not
              color: selectedTab === 0 ? "#ffffff" : "#ffffff", // White if selected, black if not
              borderRadius: 0,
              padding: "8px 16px",
              ":hover": {
                backgroundColor: selectedTab === 0 ? "#515151FF" : "#515151FF", // Black if first button is selected, dark gray if not
                color: "#FFFFFFFF", // White text on hover
              },
            }}
          >
            Subscription Status
          </Button>

         
        </Box>

        {/* Tab content */}
        {selectedTab === 0 && invoices.length > 0 ? (
          <Box
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              borderRadius: "10px",
              p: 3,
              mt: 1,
            }}
          >
            <Box
              sx={{
                mt: 4,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Button
                  variant="contained"
                  onClick={handleDownloadCSV}
                  sx={{
                    backgroundColor: "#423bfa",
                    color: "#ffffff",
                    textTransform: "none",
                    fontFamily: "IBM plex sans",
                    borderRadius: "0.7rem",
                    padding: "8px 16px",
                    "&:hover": {
                      backgroundColor: "#544FE6FF",
                    },
                  }}
                >
                  Download as CSV
                </Button>
              </Box>
              {/* <Box sx={{ mr: 3 }}>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#423bfa",
                      textTransform: "none",
                      borderRadius: "0.7rem",
                    }}
                    onClick={() => navigate("/subcriptions")}
                  >
                    View plans
                  </Button>
                </Box> */}
            </Box>

            {loading ? (
              <Box
                sx={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  zIndex: 9999, // Ensure the spinner is above other content
                  backgroundColor: "#FFFFFF", // Optional: Add a semi-transparent background
                }}
              >
                <FourSquare color="#423bfa" size="small" text="" textColor="" />
                <Typography sx={{ fontFamily: "poppins", mt: 1 }}>
                  Fetching Subscription Details ....
                </Typography>
              </Box>
            ) : (
              <TableContainer component={Paper} elevation={0}>
                <Table sx={{ minWidth: 650 }}>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell padding="checkbox"></StyledTableCell>
                      <StyledTableCell>Invoice</StyledTableCell>
                      <StyledTableCell>Billing Date</StyledTableCell>
                      <StyledTableCell>Amount</StyledTableCell>
                      <StyledTableCell>Status</StyledTableCell>
                      <StyledTableCell>Start Date</StyledTableCell>
                      <StyledTableCell>End Date</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {invoices.map((invoice) => (
                      <TableRow
                        key={invoice.invoiceId}
                        sx={{ "&:hover": { backgroundColor: "#f5f5f5" } }}
                      >
                        <TableCell padding="checkbox"></TableCell>
                        <TableCell>
                          <Tooltip title="Preview and Download" arrow>
                            <StyledLink
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                navigate(`/subscriptionInvoice/${invoice._id}`);
                              }}
                            >
                              {invoice.invoiceId}
                            </StyledLink>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          {formatDate(invoice.subscribedOn)}
                        </TableCell>
                        <TableCell>
                          <Typography sx={{ fontFamily: "IBM Plex Sans" }}>
                            {invoice.amount}
                            <Typography
                              component="span"
                              sx={{ color: "#666", ml: 1 }}
                            >
                              INR
                            </Typography>
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {new Date(invoice.validUntil) > new Date() ? (
                            <span style={{ color: "green" }}>Active</span>
                          ) : (
                            <span style={{ color: "red" }}>Inactive</span>
                          )}
                        </TableCell>
                        <TableCell>{formatDate(invoice.createdAt)}</TableCell>
                        <TableCell>{formatDate(invoice.validUntil)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
        ) : selectedTab === 0 && invoices.length === 0 ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography
              sx={{ fontFamily: "IBM plex sans", fontSize: "30px", mb: 3 }}
            >
              No Purchases Found
            </Typography>
            <Box sx={{ mr: 3 }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#423bfa",
                  textTransform: "none",
                  borderRadius: 4,
                }}
                onClick={() => setSelectedTab(1)}
              >
                View plans
              </Button>
            </Box>
          </Box>
        ) : null}

        {/* Placeholder for Second Tab (Plans Tab) */}
        {selectedTab === 1 && (
          <Box>
            <SubScriptionPage />
          </Box>
        )}
      </Container>
    </>
  );
};

export default SubscriptionStatus;
