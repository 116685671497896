import React, { useCallback, useState } from "react";
import {
  Typography,
  Button,
  Box,
  Divider,
  Checkbox,
  FormControlLabel,
  Switch,
  Paper,
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { LocationOn, CurrencyRupee, WorkOutline } from "@mui/icons-material";


import { sendInviteApi } from "../../../api/sendInvite";
import SelectJobDialog from "../SelectJobDialog";
import {
  formatYear,
  truncateText,
} from "../../../utils/helperFunctions/Format";
import CandidateProfileActions from "./CandidateFilterComponents/CandidateProfileActions";
import CandidateContact from "./CandidateFilterComponents/CandidateContact";
import { useLocation, useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import PopupSuccess from "../../../utils/popup/PopupSuccess";
import ReleaseJobPostModel from "../../../pages/EmployerPages/SubscriptionStatus/ReleaseJobPostModel";

const ProfileContainer = styled(Paper)(({ theme }) => ({
  display: "flex",
  padding: theme.spacing(3),
  marginBottom: "30px",
  flexDirection: "column",
  borderRadius: theme.spacing(1),
  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
  position: "relative",
  gap: theme.spacing(3),
  cursor: "pointer",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const ProfileSection = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  gap: theme.spacing(3),
  width: "100%",
}));

const ProfileHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
}));

const LabelText = styled(Typography)(({ theme }) => ({
  minWidth: "90px",
  fontWeight: "bold",
  fontSize: "12px",
  fontFamily: "poppins",
  flexShrink: 0,
}));

const DiscriptionText = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  fontFamily: "poppins",
  wordWrap: "break-word",
  overflowWrap: "break-word",
  flex: 1,
  minWidth: 0,
}));

const CandidateProfileCard = ({
  profiles,
  setProfiles,
  keyword,
  displayedProfiles,
}) => {
  const [playingVideoId, setPlayingVideoId] = useState(null);
  const [openSelectJob, setOpenSelectJob] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [showVideo, setShowVideo] = useState(true);
  const [inviteLoading, setInviteLoading] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const token = localStorage.getItem("token");
  const location = useLocation();
  const navigate = useNavigate();
  const [inviteData, setInviteData] = useState({
    userId: [],
    jobId: "",
  });

  const handleSuccess = useCallback((message) => {
    setSuccessMessage(message);
    setSuccessOpen(true);
    setTimeout(() => {
      setSuccessOpen(false);
      setSuccessMessage("");
    }, 2000);
  }, []);

  const handleError = useCallback((message) => {
    setErrorMessage(message);
    setErrorOpen(true);
    setTimeout(() => {
      setErrorOpen(false);
      setErrorMessage("");
    }, 2000);
  }, []);

  const highlightKeyword = (text, keyword) => {
    text = String(text || "");
    if (!keyword) return text;

    const keywords = keyword.split(",").map((k) => k.trim());
    let highlightedText = text;
    keywords.forEach((kw) => {
      if (kw) {
        const regex = new RegExp(`(${kw})`, "gi");
        highlightedText = highlightedText.replace(
          regex,
          "<span style='background-color: #3187BCFF; color: #ffffff'>$1</span>"
        );
      }
    });
    return highlightedText;
  };

  const handleOpenDialog = useCallback(
    (e) => {
      e.stopPropagation();

      setOpenSelectJob(true);
    },
    [setOpenSelectJob]
  );

  const handleCheckboxChange = useCallback(
    (userId) => (e) => {
      e.stopPropagation();
      setInviteData((prevData) => {
        const isChecked = prevData.userId.includes(userId);
        return {
          ...prevData,
          userId: isChecked
            ? prevData.userId.filter((id) => id !== userId)
            : [...prevData.userId, userId],
        };
      });
    },
    []
  );

  const handleSelectJob = useCallback((event) => {
    setInviteData((prevData) => ({ ...prevData, jobId: event.target.value }));
  }, []);

  const handleCloseDialog = useCallback(() => {
    setOpenSelectJob(false);
    setInviteData((prev) => ({ ...prev, userId: [], jobId: "" }));
    setIsAllSelected(false);
  }, [setOpenSelectJob, setInviteData, setIsAllSelected]);

  const handleSelectAll = useCallback(
    (event) => {
      event.stopPropagation();
      const isChecked = event.target.checked;
      setIsAllSelected(isChecked);
      setInviteData((prevData) => ({
        ...prevData,
        userId: isChecked
          ? displayedProfiles.map((profile) => profile._id || profile.id)
          : [],
      }));
    },
    [displayedProfiles]
  );

  const sendInvite = useCallback(async () => {
    setInviteLoading(true);
    try {
      const { userId, jobId } = inviteData || {};
      const invitationData = { userIds: userId, jobId };

      if (!jobId) {
        handleError("Select one job for invite");

        return;
      }

      const response = await sendInviteApi(token, invitationData);
      handleSuccess(response.message || "Invite Send Successfully");
      handleCloseDialog();
      setIsAllSelected(false);
    } catch (error) {
      handleError(
        error.message || "Failed To  Send Invitation, Please Try Again"
      );
      handleCloseDialog();
    } finally {
      setInviteLoading(false);
    }
  }, [inviteData, token, handleCloseDialog, handleError, handleSuccess]);

  const handlePlay = (jobId) => (e) => {
    e.stopPropagation();
    setPlayingVideoId(jobId);
  };

  const handleViewFullProfile = (id) => {
    navigate(`/viewJobSeekerProfile/${id}`);
  };

  const isUnlockedRoute = location.pathname.includes("unlock-candidates");

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent={isUnlockedRoute ? "center" : "flex-end"}
        gap={1}
      >
        <Typography variant="body2" sx={{ fontFamily: "poppins" }}>
          {showVideo ? "Hide Video CV" : "Show Video CV"}
        </Typography>
        <Switch
          checked={showVideo}
          onChange={(e) => setShowVideo(e.target.checked)}
          sx={{
            "& .MuiSwitch-thumb": { color: "black" },
            "& .MuiSwitch-track": { backgroundColor: "black" },
            "& .Mui-checked": {
              "& .MuiSwitch-thumb": { color: "green" },
              "& + .MuiSwitch-track": { backgroundColor: "green" },
            },
          }}
        />
      </Box>

      {/* Select All */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 2,
            background: "white",
            width: isUnlockedRoute ? "100%" : "100%",
            p: 2,
            mb: 3,
            borderRadius: "20px",
            border: "1px solid",
            borderColor: "grey.300",
            position: "sticky",
            zIndex: 10,
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isAllSelected}
                  onChange={handleSelectAll}
                  color="primary"
                />
              }
              label="Select All"
              sx={{
                "& .MuiTypography-root": {
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  fontSize: "16px",
                },
              }}
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Typography sx={{ fontFamily: "Poppins", fontWeight: "bold" }}>
              {inviteData.userId.length} Candidate Selected
            </Typography>
            <Button
              disabled={inviteData.userId.length === 0}
              variant="contained"
              sx={{
                textTransform: "none",
                borderRadius: "0.7rem",
                backgroundColor: "#000",
                ":hover": { backgroundColor: "#000" },
              }}
              onClick={handleOpenDialog}
            >
              Send Invite
            </Button>
          </Box>
        </Box>
      </Box>

      {profiles?.map((profile) => (
        <ProfileContainer
          key={profile._id}
          onClick={() => handleViewFullProfile(profile._id)}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ flex: 1, minWidth: 0 }}>
              <ProfileHeader>
                <Box sx={{ display: "flex", alignItems: "start" }}>
                  <FormControlLabel
                    onClick={(e) => e.stopPropagation()}
                    control={
                      <Checkbox
                        checked={inviteData.userId.includes(profile._id)}
                        onChange={handleCheckboxChange(profile._id)}
                        color="primary"
                      />
                    }
                    sx={{
                      "& .MuiTypography-root": {
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        fontSize: "16px",
                      },
                    }}
                  />
                  <Box sx={{ flex: 1, mb: 1 }}>
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      sx={{
                        fontFamily: "poppins",
                        fontSize: "18px",
                        marginTop: "8px",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: highlightKeyword(
                          profile?.fullName ?? "",
                          keyword
                        ),
                      }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                        color: "text.secondary",
                        mt: 1,
                        gap: 2,
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <WorkOutline fontSize="small" sx={{ mr: 0.5 }} />
                        <Typography
                          variant="body2"
                          sx={{
                            fontFamily: "poppins",
                            px: 1,
                            py: 0.5,
                            borderRadius: 1,
                          }}
                        >
                          {profile?.totalExpInYears > 0
                            ? profile?.totalExpInYears + " years"
                            : "Fresher"}
                        </Typography>
                      </Box>
                      {profile?.experiences[0]?.ctc > 0 && (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <CurrencyRupee fontSize="small" />
                          <Typography
                            variant="body2"
                            sx={{
                              fontFamily: "poppins",
                              px: 1,
                              py: 0.5,
                              borderRadius: 1,
                            }}
                          >
                            {profile?.experiences[0]?.ctc + " LPA" ?? ""}
                          </Typography>
                        </Box>
                      )}
                      {profile?.currentLocation && (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <LocationOn fontSize="small" sx={{ mr: 0.5 }} />
                          <Typography
                            variant="body2"
                            sx={{ fontFamily: "poppins" }}
                          >
                            {profile?.currentLocation}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              </ProfileHeader>

              {profile?.experiences[0] && (
                <ProfileSection>
                  <LabelText>Current/Prior:</LabelText>
                  <DiscriptionText
                    dangerouslySetInnerHTML={{
                      __html: highlightKeyword(
                        profile?.experiences[0]?.designation +
                          " at " +
                          profile?.experiences[0]?.company ?? "",
                        keyword
                      ),
                    }}
                  />
                </ProfileSection>
              )}
              {profile?.educations[0] && (
                <ProfileSection>
                  <LabelText>Education:</LabelText>
                  <DiscriptionText
                    dangerouslySetInnerHTML={{
                      __html: highlightKeyword(
                        truncateText(
                          profile?.educations[0]?.degree
                            ? `${profile.educations[0].degree} at ${
                                profile.educations[0].institution
                              }${
                                profile.educations[0].currentlyPursuing
                                  ? " (Currently Pursuing)"
                                  : profile.educations[0].endDate
                                  ? ` (${formatYear(
                                      profile.educations[0].endDate
                                    )})`
                                  : ""
                              }`
                            : "Not Mentioned",
                          60
                        ),
                        keyword
                      ),
                    }}
                  />
                </ProfileSection>
              )}
              <ProfileSection>
                <LabelText>Pref. locations:</LabelText>
                <DiscriptionText
                  dangerouslySetInnerHTML={{
                    __html: highlightKeyword(
                      truncateText(
                        profile?.preferredLocation?.join(",") ??
                          "Not Mentioned",
                        40
                      ),
                      keyword
                    ),
                  }}
                />
              </ProfileSection>
              {profile?.skills.length > 0 && (
                <ProfileSection>
                  <LabelText>Key Skills:</LabelText>
                  <DiscriptionText
                    dangerouslySetInnerHTML={{
                      __html: highlightKeyword(
                        truncateText(
                          profile?.skills?.join(" | ") ?? "Not Mentioned"
                        ),
                        keyword
                      ),
                    }}
                  />
                </ProfileSection>
              )}
            </Box>
            <Box onClick={(e) => e.stopPropagation()}>
              <CandidateContact profile={profile} handleError={handleError} />
            </Box>
          </Box>

          {showVideo && (
            <>
              {profile?.videoCV?.[0]?.url && (
                <Box
                  sx={{
                    width: "100%",
                    height: "500px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderBottomRightRadius: "10px",
                    borderBottomLeftRadius: "10px",
                    overflow: "hidden",
                    bgcolor: "grey",
                    backdropFilter: "blur(10px)",
                    position: "relative",
                    mt: 2,
                  }}
                  onClick={(e) => e.stopPropagation()}
                >
                  {playingVideoId !== profile?._id ? (
                    <Box
                      component="img"
                      src={profile?.videoCV[0]?.thumbnail}
                      alt=""
                      sx={{
                        width: "50%",
                        height: "100%",
                        objectFit: "cover",
                        cursor: "pointer",
                      }}
                      onClick={handlePlay(profile._id)}
                    />
                  ) : (
                    <Box
                      component="video"
                      src={profile?.videoCV[0]?.url}
                      alt="video"
                      sx={{
                        width: "50%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      autoPlay
                      controls
                    />
                  )}
                  {playingVideoId !== profile._id && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        bgcolor: "rgba(0,0,0,0.6)",
                        borderRadius: "50%",
                        width: 40,
                        height: 40,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                      onClick={handlePlay(profile._id)}
                    >
                      <PlayArrowIcon sx={{ fontSize: 24, color: "white" }} />
                    </Box>
                  )}
                </Box>
              )}
            </>
          )}
          <Divider />
          <Box onClick={(e) => e.stopPropagation()}>
            <CandidateProfileActions
              handleError={handleError}
              handleSuccess={handleSuccess}
              profile={profile}
              setProfiles={setProfiles}
              token={token}
            />
          </Box>
        </ProfileContainer>
      ))}

      <SelectJobDialog
        openSelectJob={openSelectJob}
        inviteData={inviteData}
        handleSelectJob={handleSelectJob}
        handleCloseDialog={handleCloseDialog}
        inviteLoading={inviteLoading}
        setInviteLoading={setInviteLoading}
        type="invite"
        data={inviteData}
        onSuccess={handleSuccess}
        onError={handleError}
        sendInvite={sendInvite}
      />
      <PopupSuccess
        modelOpen={successOpen}
        onClose={() => setSuccessOpen(false)}
        message={successMessage}
      />
      <ReleaseJobPostModel
        modelOpen={errorOpen}
        onClose={() => setErrorOpen(false)}
        message={errorMessage}
      />
    </>
  );
};

export default CandidateProfileCard;
