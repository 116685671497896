import React, { useEffect, useState, useMemo,useCallback } from "react";
import {
  Box,
  Typography,
  Paper,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
  Chip,
  Autocomplete,
} from "@mui/material";
import { debounce } from "lodash";
import baseURL from "../../../api/baseUrl";
import { getAllIndustriesApi } from "../../../api/common";
import { getUserApi } from "../../../api/jobseeker";
import PopupSuccess from "../../../utils/popup/PopupSuccess";
import ReleaseJobPostModel from "../../../pages/EmployerPages/SubscriptionStatus/ReleaseJobPostModel";

const WorkExperience = () => {
  const [openModal, setOpenModal] = useState(false);
  const [experienceId, setExperienceId] = useState("");
  const [isAdding, setIsAdding] = useState(true);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [experienceToDelete, setExperienceToDelete] = useState(null);
  const [allIndustries, setAllIndustries] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const token = localStorage.getItem("authToken");
  const userId = localStorage.getItem("userId");
  const [userData, setUserData] = useState();
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSuccess = useCallback((message) => {
    setSuccessMessage(message);
    setSuccessOpen(true);
    setTimeout(() => {
      setSuccessOpen(false);
      setSuccessMessage("");
    }, 2000);
  }, []);

  const handleError = useCallback((message) => {
    setErrorMessage(message);
    setErrorOpen(true);
    setTimeout(() => {
      setErrorOpen(false);
      setErrorMessage("");
    }, 2000);
  }, []);


  useEffect(() => {
    if (token) {
      const getUserDetails = async () => {
        try {
          const response = await getUserApi(userId, token);
          setUserData(response?.user);
        } catch (error) {
          console.log(error.message);
        }
      };
      getUserDetails();
    }
  }, [token, userId]);

  const [formData, setFormData] = useState({
    designation: "",
    company: "",
    industry: "",
    skills: "",
    start: "",
    end: "",
    description: "",
    location: "",
    salary: "",
    responsibilities: "",
    currentJob: false,
    designationError: "",
    companyError: "",
    descriptionError: "",
    startError: "",
    endError: "",
  });

  // Fetch user data on mount if not already handled in context
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true);
        // Reset experiences to an empty array while fetching to avoid stale data
        setUserData((prev) => ({
          ...prev,
          experiences: [],
        }));

        const token = localStorage.getItem("authToken")?.replace(/"/g, "");
        const response = await fetch(`${baseURL}/user`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        if (response.ok) {
          const data = await response.json();
          setUserData((prev) => ({
            ...prev,
            experiences: data.experiences || [], // Ensure experiences is always an array
          }));
          setExperienceId(data.experiences?.[0]?._id || "");
        } else {
          // If the fetch fails, ensure experiences is empty
          setUserData((prev) => ({
            ...prev,
            experiences: [],
          }));
          handleError('Failed to load experiences');
          
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        setUserData((prev) => ({
          ...prev,
          experiences: [], // Reset on error
        }));
        handleError('Failed to load experiences')
        
      } finally {
        setLoading(false);
      }
    };

    // Always fetch user data on mount to ensure fresh data
    fetchUserData();
  }, [setUserData,handleError]); // Remove userData from dependencies to avoid infinite loops

  const handleAddExperience = () => {
    setIsAdding(true);
    setExperienceId(null);
    setFormData({
      designation: "",
      company: "",
      industry: "",
      description: "",
      skills: "",
      start: "",
      end: "",
      salary: "",
      responsibilities: "",
      currentJob: false,
    });
    setOpenModal(true);
  };

  const handleEdit = (experience) => {
    setIsAdding(false);
    setExperienceId(experience._id);
    const startDate = experience.startDate
      ? new Date(experience.startDate).toISOString().split("T")[0]
      : "";
    const endDate =
      experience.endDate === "Present"
        ? ""
        : experience.endDate
        ? new Date(experience.endDate).toISOString().split("T")[0]
        : "";
    setFormData({
      designation: experience.title
        ? experience.title
        : experience.designation || "",
      company: experience.company || "",
      industry: experience.industry || "",
      description: experience.jobDescription || "",
      skills: experience.skills?.join(", ") || "",
      start: startDate,
      end: endDate,
      salary: experience.ctc || "",
      responsibilities: experience.responsibilities || "",
      currentJob: experience.endDate === "Present",
    });
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setIsAdding(false);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
      [`${name}Error`]: "",
    }));
  };

  const fetchIndustries = useMemo(
    () =>
      debounce(async (searchQuery) => {
        try {
          const industries = await getAllIndustriesApi();
          const filteredIndustries = industries.filter((name) =>
            name.toLowerCase().includes(searchQuery.toLowerCase())
          );
          setAllIndustries(filteredIndustries);
        } catch (error) {
          console.error("Error fetching Industries:", error.message);
        }
      }, 300),
    []
  );

  useEffect(() => {
    fetchIndustries("");
  }, [fetchIndustries]);

  const handleDeleteClick = (experience) => {
    setExperienceToDelete(experience);
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
    setExperienceToDelete(null);
  };

  const handleDeleteConfirm = async () => {
    if (!experienceToDelete) return;
    const token = localStorage.getItem("authToken")?.replace(/"/g, "");
    try {
      const response = await fetch(
        `${baseURL}/deleteExperience/${experienceToDelete._id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        setUserData((prevState) => ({
          ...prevState,
          experiences:
            prevState?.experiences?.filter(
              (experience) => experience._id !== experienceToDelete._id
            ) || [],
        }));
        handleSuccess('Experience deleted successfully!')
       
      } else {
        handleError('Failed to delete experience')
       
      }
    } catch (error) {
      console.error("Error deleting experience:", error);
      handleError('Failed to delete experience');
      
    } finally {
      // Semicolon added here
      setDeleteDialogOpen(false);
      setExperienceToDelete(null);
    }
  };

  const handleSave = async () => {
    const authToken = localStorage.getItem("authToken");
    const errors = {
      designationError: !formData.designation ? "Job Title is required" : "",
      companyError: !formData.company ? "Company is required" : "",
      startError: !formData.start ? "Start Date is required" : "",
      endError:
        !formData.currentJob && !formData.end ? "End Date is required" : "",
    };
    if (formData.start && formData.end && formData.start > formData.end) {
      errors.startError = "Start Date cannot be after End Date";
    }
    const hasError = Object.values(errors).some((error) => error !== "");
    if (hasError) {
      setFormData((prev) => ({
        ...prev,
        ...errors,
      }));
      return;
    }
    const updatedData = {
      designation: formData.designation,
      company: formData.company,
      industry: formData.industry,
      startDate: formData.start,
      endDate: formData.end,
      jobRole: formData.description,
      jobDescription: formData.description,
      ctc: formData.salary,
      location: formData.location,
      responsibilities: formData.responsibilities,
      technologiesUsed: formData.skills
        ?.split(", ")
        .map((skill) => skill.trim()),
    };
    const method = isAdding ? "POST" : "PUT";
    const url = isAdding
      ? `${baseURL}/addExperience`
      : `${baseURL}/editExperience/${experienceId}`;
    try {
      const response = await fetch(url, {
        method: method,
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedData),
      });
      if (!response.ok) {
        throw new Error("Failed to多元 save experience");
      }
      const newExperience = await response.json();
      setUserData((prevUserData) => {
        const updatedExperiences = isAdding
          ? [...(prevUserData.experiences || []), newExperience.experience]
          : prevUserData.experiences.map((experience) =>
              experience._id === experienceId
                ? newExperience.experience
                : experience
            );
        return {
          ...prevUserData,
          experiences: updatedExperiences,
        };
      });
      handleSuccess(newExperience.message);

      setOpenModal(false);
    } catch (error) {
      console.error("Error saving experience:", error);
      handleError('Failed to save experience')
      
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
          alignItems: "center",
          mt: 2,
          fontFamily: "IBM Plex Sans",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="h6"
            fontWeight="bold"
            sx={{ fontFamily: "IBM Plex Sans" }}
          >
            Work Experience
          </Typography>
        </Box>
        <Typography
          variant="body1"
          color="primary"
          onClick={handleAddExperience}
          sx={{
            cursor: "pointer",
            fontWeight: "bold",
            marginRight: 2,
            fontFamily: "IBM Plex Sans",
            color: "blue",
            padding: "8px 16px",
            borderRadius: "0.7rem",
            transition: "background-color 0.3s ease",
          }}
        >
          + Add
        </Typography>
      </Box>

      <Paper
        sx={{
          padding: "20px",
          margin: "0 auto",
          borderRadius: "15px",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          backgroundColor: "#fff",
          boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
          fontFamily: "IBM Plex Sans",
        }}
        variant="outlined"
        elevation={1}
      >
        {loading ? (
          <Typography
            sx={{
              fontWeight: "bold",
              textAlign: "center",
              fontFamily: "IBM Plex Sans",
            }}
          >
            Loading experiences...
          </Typography>
        ) : userData?.experiences?.length > 0 ? (
          userData.experiences.map((experience) => (
            <Box
              key={experience._id}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "15px",
                borderRadius: "10px",
                backgroundColor: "#f9f9f9",
                transition: "background-color 0.3s ease",
                "&:hover": {
                  backgroundColor: "#f0f0f0",
                },
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1.2rem",
                    color: "#333",
                    fontFamily: "IBM Plex Sans",
                  }}
                >
                  {experience?.title || experience?.designation}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "1rem",
                    color: "#666",
                    fontFamily: "IBM Plex Sans",
                  }}
                >
                  <strong>Company:</strong> {experience?.company}
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>

                <Typography
                    variant="body1"
                    sx={{
                      fontSize: "1rem",
                      color: "#555",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    <strong>Job Role:</strong> {experience?.jobRole || "N/A"}
                  </Typography>

                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "1rem",
                      color: "#555",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    <strong>Industry:</strong> {experience?.industry || "N/A"}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "1rem",
                      color: "#555",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    <strong>CTC:</strong> {experience?.ctc || "N/A"} LPA
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "1rem",
                      color: "#555",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    <strong>Start Date:</strong>{" "}
                    {experience?.startDate
                      ? new Date(experience.startDate).toLocaleDateString(
                          "en-US",
                          { month: "long", year: "numeric" }
                        )
                      : "not mentioned"}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "1rem",
                      color: "#555",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    <strong>End Date:</strong>{" "}
                    {experience?.endDate === "Present"
                      ? "Present"
                      : experience?.endDate
                      ? new Date(experience.endDate).toLocaleDateString(
                          "en-US",
                          { month: "long", year: "numeric" }
                        )
                      : "Currently Working"}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Button
                  onClick={() => handleEdit(experience)}
                  sx={{
                    backgroundColor: "black",
                    color: "white",
                    borderRadius: "0.7rem",
                    textTransform: "none",
                    fontFamily: "IBM Plex Sans",
                    fontWeight: "bold",
                    padding: "8px 16px",
                    "&:hover": { backgroundColor: "#333" },
                  }}
                >
                  Edit
                </Button>
                <Button
                  onClick={() => handleDeleteClick(experience)}
                  sx={{
                    backgroundColor: "#CACACAFF",
                    color: "black",
                    borderRadius: "0.7rem",
                    textTransform: "none",
                    fontFamily: "IBM Plex Sans",
                    fontWeight: "bold",
                    padding: "8px 16px",
                    "&:hover": { backgroundColor: "#e0e0e0" },
                  }}
                >
                  Delete
                </Button>
              </Box>
            </Box>
          ))
        ) : (
          <Typography
            sx={{
              fontWeight: "bold",
              textAlign: "center",
              fontFamily: "IBM Plex Sans",
            }}
          >
            No experiences added yet.
          </Typography>
        )}
      </Paper>

      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        maxWidth="sm"
        fullWidth
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "15px",
            fontFamily: "IBM Plex Sans",
          },
        }}
      >
        <DialogTitle sx={{ fontFamily: "IBM Plex Sans", fontWeight: "bold" }}>
          <b>Confirm Deletion</b>
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ fontFamily: "IBM Plex Sans" }}>
            Are you sure you want to delete this experience? This action cannot
            be undone.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ padding: 2 }}>
          <Button
            onClick={handleDeleteDialogClose}
            sx={{
              borderRadius: "0.7rem",
              textTransform: "none",
              fontWeight: "bold",
              fontFamily: "IBM Plex Sans",
              backgroundColor: "black",
              color: "white",
              padding: "8px 16px",
              "&:hover": { backgroundColor: "#333" },
            }}
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            sx={{
              borderRadius: "0.7rem",
              textTransform: "none",
              fontWeight: "bold",
              fontFamily: "IBM Plex Sans",
              backgroundColor: "#423bfa",
              color: "white",
              padding: "8px 16px",
              "&:hover": { backgroundColor: "#362fd9" },
            }}
            variant="contained"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        fullWidth
        maxWidth="sm"
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "15px",
            fontFamily: "IBM Plex Sans",
          },
        }}
      >
        <DialogTitle sx={{ fontFamily: "IBM Plex Sans", fontWeight: "bold" }}>
          <b>{isAdding ? "Add Work Experience" : "Edit Work Experience"}</b>
        </DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ mt: 2 }}>
            <Typography
              sx={{ fontFamily: "IBM Plex Sans", fontWeight: "bold" }}
            >
              Job Title
            </Typography>
            <TextField
              fullWidth
              value={formData?.designation}
              name="designation"
              onChange={handleFormChange}
              onBlur={handleFormChange}
              variant="outlined"
              margin="normal"
              error={!!formData.designationError}
              helperText={formData.designationError || ""}
              sx={{ "& .MuiOutlinedInput-root": { borderRadius: "10px" } }}
            />
            <Typography
              sx={{ fontFamily: "IBM Plex Sans", fontWeight: "bold" }}
            >
              Company
            </Typography>
            <TextField
              fullWidth
              value={formData?.company}
              name="company"
              onChange={handleFormChange}
              onBlur={handleFormChange}
              variant="outlined"
              margin="normal"
              error={!!formData.companyError}
              helperText={formData.companyError || ""}
              sx={{ "& .MuiOutlinedInput-root": { borderRadius: "10px" } }}
            />
            <Typography
              sx={{ fontFamily: "IBM Plex Sans", fontWeight: "bold" }}
            >
              Industry
            </Typography>
            <Autocomplete
              freeSolo
              options={allIndustries || []}
              value={formData?.industry || ""}
              onChange={(event, newValue) => {
                setFormData((prev) => ({ ...prev, industry: newValue }));
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    sx={{ background: "lightgrey" }}
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  margin="normal"
                  placeholder="Select Industry"
                  fullWidth
                  sx={{ "& .MuiOutlinedInput-root": { borderRadius: "10px" } }}
                />
              )}
            />
            <Typography
              sx={{ fontFamily: "IBM Plex Sans", fontWeight: "bold" }}
            >
              Job Role
            </Typography>
            <TextField
              fullWidth
              value={formData.description}
              name="description"
              onChange={handleFormChange}
              variant="outlined"
              margin="normal"
              sx={{ "& .MuiOutlinedInput-root": { borderRadius: "10px" } }}
            />
            <Typography
              sx={{ fontFamily: "IBM Plex Sans", fontWeight: "bold" }}
            >
              Start Date and End Date
            </Typography>
            <Box sx={{ display: "flex", gap: 2, alignItems: "center", mb: 2 }}>
              <TextField
                type="date"
                value={formData.start}
                name="start"
                onChange={handleFormChange}
                onBlur={handleFormChange}
                variant="outlined"
                margin="normal"
                fullWidth
                error={!!formData.startError}
                helperText={formData.startError || ""}
                InputLabelProps={{ shrink: true }}
                inputProps={{ style: { cursor: "pointer" } }}
                onKeyDown={(e) => e.preventDefault()}
                onPaste={(e) => e.preventDefault()}
                sx={{
                  borderRadius: "8px",
                  "& .MuiOutlinedInput-root": { borderRadius: "8px" },
                  "& .MuiOutlinedInput-input": { cursor: "pointer" },
                  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "inherit",
                    },
                }}
              />
              <TextField
                type="date"
                value={formData.end}
                name="end"
                onChange={handleFormChange}
                onBlur={handleFormChange}
                variant="outlined"
                margin="normal"
                fullWidth
                disabled={formData.currentJob}
                error={!!formData.endError}
                helperText={formData.endError || ""}
                InputLabelProps={{ shrink: true }}
                inputProps={{ style: { cursor: "pointer" } }}
                onKeyDown={(e) => e.preventDefault()}
                onPaste={(e) => e.preventDefault()}
                sx={{
                  borderRadius: "8px",
                  "& .MuiOutlinedInput-root": { borderRadius: "8px" },
                  "& .MuiOutlinedInput-input": { cursor: "pointer" },
                  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "inherit",
                    },
                }}
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
              <Checkbox
                checked={formData?.currentJob} // Default to true if end is undefined
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    currentJob: e.target.checked,
                    end: e.target.checked ? "" : prev.end,
                  }))
                }
              />
              <Typography sx={{ fontFamily: "IBM Plex Sans" }}>
                This is my current job
              </Typography>
            </Box>
            <Typography
              sx={{ fontFamily: "IBM Plex Sans", fontWeight: "bold" }}
            >
              Current CTC in LPA
            </Typography>
            <TextField
              fullWidth
              value={formData?.salary || ""}
              name="salary"
              type="number"
              onChange={(e) => {
                const value = e.target.value;
                if (
                  value === "" ||
                  (/^\d*\.?\d*$/.test(value) &&
                    Number(value) >= 0 &&
                    Number(value) <= 1000)
                ) {
                  handleFormChange(e);
                }
              }}
              variant="outlined"
              margin="normal"
              placeholder="Enter salary in LPA (e.g., 5.5)"
              error={
                formData?.salary &&
                (formData.salary < 0 || formData.salary > 1000)
              }
              helperText={
                formData?.salary && formData.salary < 0
                  ? "Salary cannot be negative"
                  : formData?.salary && formData.salary > 1000
                  ? "Please enter a realistic salary (max 1000 LPA)"
                  : "Enter CTC in Lakhs Per Annum"
              }
              inputProps={{
                min: 0,
                max: 1000,
                step: "0.1",
                style: {
                  MozAppearance: "textfield",
                  WebkitAppearance: "none",
                  appearance: "none",
                },
              }}
              sx={{
                "& .MuiOutlinedInput-root": { borderRadius: "10px" },
                "& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button":
                  {
                    WebkitAppearance: "none",
                    margin: 0,
                  },
              }}
            />
            <Typography
              sx={{ fontFamily: "IBM Plex Sans", fontWeight: "bold" }}
            >
              Responsibilities
            </Typography>
            <TextField
              fullWidth
              value={formData?.responsibilities || ""}
              name="responsibilities"
              onChange={handleFormChange}
              variant="outlined"
              margin="normal"
              multiline
              rows={3}
              inputProps={{ maxLength: 500 }}
              helperText={`${
                (formData?.responsibilities || "").length
              }/500 characters`}
              sx={{ "& .MuiOutlinedInput-root": { borderRadius: "10px" } }}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: 2 }}>
          <Button
            onClick={handleCloseModal}
            sx={{
              borderRadius: "0.7rem",
              textTransform: "none",
              fontWeight: "bold",
              fontFamily: "IBM Plex Sans",
              backgroundColor: "black",
              color: "white",
              padding: "8px 16px",
              "&:hover": { backgroundColor: "#333" },
            }}
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            sx={{
              borderRadius: "0.7rem",
              textTransform: "none",
              fontWeight: "bold",
              fontFamily: "IBM Plex Sans",
              backgroundColor: "#423bfa",
              color: "white",
              padding: "8px 16px",
              "&:hover": { backgroundColor: "#362fd9" },
            }}
            variant="contained"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <PopupSuccess
          modelOpen={successOpen}
          onClose={() => setSuccessOpen(false)}
          message={successMessage}
        />
        <ReleaseJobPostModel
          modelOpen={errorOpen}
          onClose={() => setErrorOpen(false)}
          message={errorMessage}
        />
    </>
  );
};

export default WorkExperience;
