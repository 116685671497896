import { Box, Typography } from "@mui/material";
import React from "react";

const Hero = () => {
  return (
    <Box
      sx={{
        position: "relative",
        textAlign: "center",
        background: "#000",
        padding: 5,
        borderRadius: 10,
      }}
    >
      {/* Background Image */}
      <Box
        component="img"
        src="/imgLeft.png"
        alt="Background Image"
        sx={{
          width: "100%",
          height: "auto",
          display: "block",
        }}
      />

      {/* Content Box */}
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
          background: "#000", // Slight background transparency
          borderRadius: "15px",
          maxWidth: "800px",
        }}
      >
        {/* First Typography - Keep its position */}
        <Typography
          variant="h2"
          sx={{
            fontWeight: 700,
            mb: 10,
            fontSize: { xs: "1.5rem", md: "2rem", color: "#fff" },
          }}
        >
          Empowering Job Seekers
        </Typography>

        {/* Centered Typography */}
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Typography
            variant="h1"
            sx={{
              fontWeight: 700,
              fontSize: { xs: "1.5rem", md: "2rem" },
              color: "#fff",
            }}
          >
            Revolutionizing Recruitment with AI & Video-First Hiring!
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Hero;
