import React, { useEffect, useState, useCallback } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
  Stack,
  Avatar,
} from "@mui/material";
import { getChats, getChatById } from "../../../api/chat";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import avatarmen from "../../../assets/images/avatarmen.jpg";
import EmployerJobSeekerChatList from "../../../components/EmployerComponents/EmployerJobSeekerChat/EmployerJobSeekerChatList";
import EmployerJobSeekerChatWindow from "../../../components/EmployerComponents/EmployerJobSeekerChat/EmployerJobSeekerChatWindow";
import { getRecruiterApi } from "../../../api/payment";
import initializeSocket, { getSocket } from "../../../utils/socket/socket";

const EmployerToJobSeekerSupport = ({ inviteChatId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedChatId, setSelectedChatId] = useState(inviteChatId);
  const [chatList, setChatList] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [recruiterDetails, setRecruiterDetails] = useState("");

  const recruiterId = localStorage.getItem("recruiterId");
  const token = localStorage.getItem("token");

  const fetchChatList = useCallback(async () => {
    try {
      const response = await getChats();
      setChatList(response?.chats || []);
    } catch (error) {
      console.error("Failed to fetch chat list:", error.message);
    }
  }, []);

  const fetchSelectedChat = useCallback(async (chatId) => {
    if (!chatId) return;
    try {
      const chatData = await getChatById(chatId);
      setSelectedChat(Array.isArray(chatData) ? chatData : chatData.messages || []);
    } catch (error) {
      console.error("Failed to fetch chat details:", error.message);
    }
  }, []);

  useEffect(() => {
    if (recruiterId && token) {
      initializeSocket(recruiterId, "Recruiter", token);
    }

    fetchChatList();

    const socket = getSocket();
    if (socket) {
      const handleNewMessageSocket = (newMessage) => {
        console.log("New message received (Recruiter):", newMessage);

        setChatList((prevChatList) => {
          const updatedChatList = [...prevChatList];
          const chatIndex = updatedChatList.findIndex((chat) => chat._id === newMessage.chatId);

          if (chatIndex !== -1) {
            const updatedChat = { ...updatedChatList[chatIndex] };
            updatedChat.messages = updatedChat.messages
              ? [...updatedChat.messages]
              : [];
            if (!updatedChat.messages.some((msg) => msg._id === newMessage._id)) {
              updatedChat.messages.push(newMessage);
            }
            updatedChat.lastMessage = newMessage;
            updatedChat.lastMessageTimestamp = newMessage.timestamp;
            updatedChatList.splice(chatIndex, 1);
            updatedChatList.unshift(updatedChat);
          } else {
            updatedChatList.unshift({
              _id: newMessage.chatId,
              participants: [
                { userId: newMessage.senderId, userModel: newMessage.senderModel },
                { userId: newMessage.receivers[0]?.userId, userModel: newMessage.receivers[0]?.userModel },
              ],
              messages: [newMessage],
              lastMessage: newMessage,
              lastMessageTimestamp: newMessage.timestamp,
            });
          }
          return updatedChatList;
        });

        if (selectedChatId === newMessage.chatId) {
          setSelectedChat((prevChat) => {
            const safePrev = Array.isArray(prevChat) ? prevChat : [];
            const messageExists = safePrev.some((msg) => msg._id === newMessage._id);
            if (messageExists) {
              console.log("Duplicate message detected, skipping:", newMessage._id);
              return safePrev;
            }
            return [...safePrev, newMessage];
          });
        }
      };

      socket.on("newMessage", handleNewMessageSocket);
      socket.on("connect_error", (error) => {
        console.error("Socket.IO connection error:", error);
      });

      return () => {
        socket.off("newMessage", handleNewMessageSocket);
        socket.off("connect_error");
      };
    }
  }, [fetchChatList, selectedChatId, recruiterId, token]);

  useEffect(() => {
    if (selectedChatId) fetchSelectedChat(selectedChatId);
  }, [selectedChatId, fetchSelectedChat]);

  useEffect(() => {
    if (inviteChatId) {
      setSelectedChatId(inviteChatId);
      setIsOpen(true);
    }
  }, [inviteChatId]);

  useEffect(() => {
    const getRecruiter = async () => {
      try {
        const response = await getRecruiterApi();
        setRecruiterDetails(response?.recruiter);
      } catch (error) {
        console.log(error.message);
      }
    };
    getRecruiter();
  }, []);

  const handleChatSelection = (chatId) => {
    setSelectedChatId(chatId);
    setIsOpen(true);
    console.log("chatId selected:", chatId);
  };

  const handleAccordionChange = (_, expanded) => {
    setIsOpen(expanded);
    if (!expanded) {
      setSelectedChatId(null);
      setSelectedChat(null);
    }
  };

  const handleNewMessage = (newMessage) => {
    console.log("New message prepared (Recruiter):", newMessage);
    // Do not re-emit; rely on EmployerJobSeekerChatWindow's socket.emit
  };

  const profileImage = recruiterDetails?.organization?.companyLogo?.url;

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        right: 16,
        zIndex: 1000,
        width: { xs: "34%", sm: "30%", md: "25%", lg: "21%", xl: "16%" },
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Accordion
        expanded={isOpen}
        onChange={handleAccordionChange}
        sx={{
          borderRadius: 2,
          boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
          overflow: "hidden",
          height: isOpen ? "67vh" : "8vh",
          transition: "height 0.3s ease-in-out",
        }}
      >
        <AccordionSummary
          expandIcon={
            <Stack direction="row" spacing={1} alignItems="center">
              {isOpen ? (
                <ExpandLessIcon sx={{ color: "#000", fontSize: 26 }} />
              ) : (
                <ExpandMoreIcon sx={{ color: "#000", fontSize: 26 }} />
              )}
            </Stack>
          }
          sx={{
            backgroundColor: "#f5f5f5",
            color: "#000",
            borderRadius: isOpen ? "8px 8px 0 0" : "8px",
            padding: "4px 12px",
            minHeight: "8vh",
            "& .MuiAccordionSummary-content": {
              margin: 0,
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            },
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Avatar
              alt="User"
              src={profileImage || avatarmen}
              sx={{ height: 50, width: 50 }}
            />
            <Typography variant="body2" sx={{ fontWeight: 800, fontFamily: "poppins" }}>
              Messaging
            </Typography>
          </Box>
        </AccordionSummary>

        <AccordionDetails
          sx={{
            padding: 0,
            backgroundColor: "#f9f9f9",
            height: "calc(67vh - 8vh)",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              overflowY: "auto",
              backgroundColor: "#fff",
              borderRadius: "0 0 8px 8px",
              padding: 1,
              "&::-webkit-scrollbar": { width: "0px" },
              "&::-webkit-scrollbar-thumb": { backgroundColor: "transparent" },
              scrollbarWidth: "none",
              "-ms-overflow-style": "none",
            }}
          >
            <EmployerJobSeekerChatList
              chatList={chatList}
              onChatSelect={handleChatSelection}
            />
          </Box>
        </AccordionDetails>
      </Accordion>

      {selectedChat && (
        <Box
          sx={{
            position: "absolute",
            right: "350px",
            width: "450px",
            backgroundColor: "#fff",
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
            height: "600px",
          }}
        >
          <EmployerJobSeekerChatWindow
            chatData={selectedChat}
            onMessageSent={handleNewMessage}
            handleAccordionChange={handleAccordionChange}
            profileImage={profileImage}
            socket={getSocket()}
          />
        </Box>
      )}
    </Box>
  );
};

export default EmployerToJobSeekerSupport;