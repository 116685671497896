

const deleteAccount = {
  parentBox: {
    fontFamily: "GlanceSansRegular",
    width:'full',
    height:'auto',
    display:"flex",
    justifyContent: "center",
    alignItems:'center',

  },
  formBox:{
    display: "flex",
    justifyContent: "center",
    alignItems:'center',
    flexDirection:"column",
    bgcolor:"white",
    padding:3,
    borderRadius:'10px',
    boxShadow:'1px 0px 10px 3px lightgray',
    gap:2,
    
  },
  textFiled:{
    width:350,
    fontFamily:"poppins",
    "& .MuiInputBase-input": {
      fontFamily: "Poppins, sans-serif",
    },

  },
  multiline:{
   width:350,
   "& .MuiInputBase-input": {
    fontFamily: "Poppins, sans-serif",
  },
  
  },
  typography:{
    fontSize:25,
    fontWeight:'regular',
    fontFamily:"poppins",
    mb:1,
  }

};

export default deleteAccount