import React from "react";
import {
  Modal,
  Box,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import secondsLogo from "../assets/images/60secondslogo.png";

// Data for the modal
const vacanziBenefits = [
  {
    label: "Save Time & Avoid Unnecessary Travel",
    content:
      "Recruiters assess your personality upfront, reducing rejection due to communication barriers.",
  },
  {
    label: "Showcase Yourself",
    content: "Highlight confidence, communication & personality.",
  },
  {
    label: "Get Noticed Fast",
    content: "Recruiters assess you in seconds.",
  },
  {
    label: "Stand Out",
    content: "Beat the competition with a unique profile.",
  },
  {
    label: "Smarter Matches",
    content: "AI connects you to the right jobs.",
  },
  {
    label: "Faster Hiring",
    content: "Direct recruiter access & quicker responses.",
  },
  {
    label: "Effortless Job Search",
    content:
      "Skip multiple screening rounds & land relevant interviews faster.",
  },
];

// Reusable Modal Component
const VacanziBenefitsModal = ({ open, onClose }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="vacanzi-benefits-modal"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backdropFilter: "blur(5px)", // Adds a subtle blur to the background
      }}
    >
      <Box
        sx={{
          bgcolor: "white",
          borderRadius: "20px",
          p: 4,
          width: "90%",
          maxWidth: "600px",
          maxHeight: "80vh",
          position: "relative",
          boxShadow: "0 8px 32px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* Close Button */}
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: 12,
            right: 12,
            bgcolor: "rgba(0, 0, 0, 0.05)",
            "&:hover": { bgcolor: "rgba(0, 0, 0, 0.1)" },
          }}
        >
          <CloseIcon sx={{ fontSize: "28px", color: "#333" }} />
        </IconButton>

        {/* Logo */}
        <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
          <img src={secondsLogo} height="12%" width="20%" alt="logo" />
        </Box>

        {/* Modal Header */}
        <Typography
          id="vacanzi-benefits-modal"
          sx={{
            fontSize: "28px",
            fontFamily: "IBM Plex Sans",
            fontWeight: "bold",
            textAlign: "center",
            mb: 3,
            color: "#1a1a1a",
          }}
        >
          Why a 60-Second Video on Vacanzi? 🎥
        </Typography>

        {/* Scrollable Benefits List */}
        <Box
          sx={{
            maxHeight: "50vh", // Limits the height of the scrollable area
            overflowY: "auto", // Enables scrolling
            // Custom scrollbar for Firefox
            scrollbarWidth: "thin",
            scrollbarColor: "rgba(0, 0, 0, 0.3) transparent",
            // Custom scrollbar for Webkit browsers (Chrome, Safari)
            "&::-webkit-scrollbar": {
              width: "6px",
            },
            "&::-webkit-scrollbar-track": {
              background: "transparent",
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "rgba(0, 0, 0, 0.3)",
              borderRadius: "10px",
              "&:hover": {
                background: "rgba(0, 0, 0, 0.5)",
              },
            },
          }}
        >
          <List sx={{ pt: 0 }}>
            {vacanziBenefits.map((benefit, index) => (
              <ListItem
                key={index}
                sx={{
                  alignItems: "flex-start",
                  py: 1,
                  "&:hover": {
                    bgcolor: "rgba(25, 118, 210, 0.05)",
                    borderRadius: "8px",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "24px",
                    fontFamily: "IBM Plex Sans",
                    fontWeight: "bold",
                    color: "#000",
                    mr: 2,
                    minWidth: "24px",
                  }}
                >
                  {index + 1}
                </Typography>
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontFamily: "IBM Plex Sans",
                        fontWeight: "bold",
                        color: "#333",
                      }}
                    >
                      {benefit.label}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontFamily: "IBM Plex Sans",
                        color: "text.secondary",
                        mt: 0.3,
                      }}
                    >
                      {benefit.content}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </Modal>
  );
};

export default VacanziBenefitsModal;