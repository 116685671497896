import React, { memo, useCallback, useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  Box,
  TextField,
  IconButton,
} from "@mui/material";
import { Search, Tune } from "@mui/icons-material";

const EmployerJobSeekerChatList = memo(({ chatList, onChatSelect }) => {
  const userId = localStorage.getItem("recruiterId");
  const [searchQuery, setSearchQuery] = useState("");

  const handleChatSelect = useCallback(
    (chatId) => {
      const chat = chatList.find((c) => c._id === chatId);
      const receiver = chat?.participants?.find((p) => p.userId !== userId);
      onChatSelect(chatId, receiver?.userId);
    },
    [onChatSelect, chatList, userId]
  );

  const filteredChatList = chatList?.filter((chat) =>
    chat?.lastMessage?.message?.toLowerCase().includes(searchQuery.toLowerCase()) ||
    chat?.participants?.some((p) =>
      (p.userInfo?.fullName || p.userInfo?.rcName || "User")
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    )
  );

  return (
    <Box
           sx={{
             width: "100%",
             zIndex: 1000,
             borderRadius: "12px",
             overflow: "hidden",
             background: "#fff",
             boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.1)",
             display: "flex",
             flexDirection: "column",
             height: "100%",
             overflowY: "auto",
            
           }}
         >
      {/* Header */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "#f5f5f5",
          padding: "4px 6px",
          borderRadius: "10px",
          mb: 2,
          zIndex: 1000,
          border: "1px solid #e0e0e0",
          transition: "all 0.3s ease-in-out",
          minHeight: "38px",
          "&:hover": {
            backgroundColor: "#e0e0e0",
            borderRadius: "10px",
            boxShadow: "0 0 4px rgba(0,0,0,0.2)",
          },
        }}
      >
        <Search sx={{ color: "black", fontWeight: "bold" }} />
        <TextField
          placeholder="Search messages"
          variant="standard"
          size="small"
          fullWidth
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            disableUnderline: true,
            sx: {
              paddingX: "8px",
              fontSize: "14px",
              fontFamily: "poppins",
              fontWeight: "bold",
              color: "#374151",
            },
          }}
          sx={{
            flexGrow: 1,
            "& .MuiInputBase-root": {
              backgroundColor: "transparent",
            },
          }}
        />
        <IconButton size="small">
          <Tune sx={{ color: "#000", fontSize: "18px" }} />
        </IconButton>
      </Box>

      {/* Chat List */}
      <List
             sx={{ padding: 0, flexGrow: 1, backgroundColor: "#fff" }}
      >
        {filteredChatList?.map((chat) => {
          const jobseeker = chat.participants.find(
            (p) => p.userModel?.toLowerCase() === "user" && p.userId !== userId
          ) || {};

          const receiverName = jobseeker?.userInfo?.fullName || "User";
          const profileImage = jobseeker?.userInfo?.profileImage || null;

          return (
            <ListItem
              key={chat._id}
              onClick={() => handleChatSelect(chat._id)}
              sx={{
                padding: "2px 0px",
                transition: "background 0.2s",
                fontFamily: "poppins",
                "&:hover": { backgroundColor: "#e0e0e0", cursor: "pointer" },
                borderBottom: "1px solid #e5e5e5",
                display: "flex",
                alignItems: "center",
              }}
            >
              <ListItemAvatar>
                <Avatar
                  alt={receiverName}
                  src={profileImage}
                  sx={{ width: 42, height: 42 }}
                />
              </ListItemAvatar>

              <ListItemText
                primary={
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#000",
                        fontSize: "0.90rem",
                        fontWeight: 400,
                        fontFamily: "poppins",
                      }}
                    >
                      {receiverName}
                      {chat.isSponsored && (
                        <Typography
                          component="span"
                          variant="caption"
                          sx={{
                            color: "#0073b1",
                            fontWeight: "bold",
                            marginLeft: "4px",
                            fontFamily: "poppins",
                          }}
                        >
                          Sponsored
                        </Typography>
                      )}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{ color: "#6d6d6d", fontSize: "0.65rem", fontFamily: "poppins" }}
                    >
                      {chat.lastMessageTimestamp
                        ? new Date(chat.lastMessageTimestamp).toLocaleDateString("en-US", {
                            month: "short",
                            day: "numeric",
                          })
                        : ""}
                    </Typography>
                  </Box>
                }
                secondary={
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "0.85rem",
                      color: "#6d6d6d",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      fontFamily: "poppins",
                    }}
                  >
                    {chat.lastMessage?.message || "No messages yet"}
                  </Typography>
                }
              />
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
});

export default EmployerJobSeekerChatList;