import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Typography, Container, Paper, Button } from "@mui/material";
import { styled, keyframes } from "@mui/system";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

const AnimatedErrorIcon = styled(ErrorOutlineIcon)(({ theme }) => ({
  fontSize: 80,
  color: theme.palette.error.main,
  animation: `${pulseAnimation} 2s infinite`,
}));

const PaymentFailure = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const errorMessage =
    location.state?.errorMessage || "An unknown error occurred.";

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
        }}
      >
        <Paper
          elevation={3}
          sx={{
            p: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "background.paper",
          }}
        >
          <AnimatedErrorIcon />
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            sx={{ mt: 2, color: "error.main" }}
          >
            Payment Failed
          </Typography>
          <Typography
            variant="body1"
            sx={{ mt: 2, mb: 3, textAlign: "center" }}
          >
            {errorMessage}
          </Typography>
          <Button
            href="/"
            variant="contained"
            sx={{
              px: 4,
              py: 1,
              backgroundColor: "black",
              textTransform: "none",
              fontWeight:'bold'
            }}
            onClick={() => navigate("/employerJobListings")}
          >
            Return to Home
          </Button>
        </Paper>
      </Box>
    </Container>
  );
};

export default PaymentFailure;
