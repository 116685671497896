import React, { useState } from "react";
import {
  Box,
  Paper,
  Button,
  CardContent,
  Collapse,
  Typography,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KYCDetailsModel from "../../../../components/EmployerComponents/CompanyProfileComponents/KYCDdetailsModel";

const KYCComplainsDetails = ({
  openKYCDialog,
  handleKYCDialogClose,
  handleSaveDialog,
  handleKYCEdit,
  orgData,
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <Paper
      elevation={3}
      sx={{
        backgroundColor: "#FFF",
        borderRadius: "12px",
        overflow: "hidden",
        width: "100%",
        mt: 2,
        mb:10,
      }}
    >
      <CardContent sx={{ cursor: "pointer" }} onClick={handleToggleExpand}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" sx={{  fontWeight: "bold",fontFamily:"poppins" }}>
            KYC Compliance Details
          </Typography>
          {expanded ? (
            <KeyboardArrowDownIcon
              sx={{
                fontSize: "40px",
                cursor: "pointer",
                color: "gray",
                transition: "transform 0.3s ease",
              }}
            />
          ) : (
            <ArrowForwardIosIcon
              sx={{
                cursor: "pointer",
                color: "gray",
                transition: "transform 0.3s ease",
              }}
            />
          )}
        </Box>
      </CardContent>

      <Collapse in={expanded}>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "starts",
              justifyContent: "start",
              mb: 2,
            }}
          >
            <Typography
              gutterBottom
              variant="body2"
              sx={{ fontSize: "1rem", color: "#000", fontFamily: "poppins" }}
            >
              <strong>Name:</strong> {orgData?.creatorFullName}
            </Typography>

            <Typography
              gutterBottom
              variant="body2"
              sx={{ fontSize: "1rem", color: "#000", fontFamily: "poppins" }}
            >
              <strong>Address:</strong> {orgData?.organizationAddress}
            </Typography>

            <Typography
              gutterBottom
              variant="body2"
              sx={{ fontSize: "1rem", color: "#000", fontFamily: "poppins" }}
            >
              <strong>Country:</strong> {orgData?.orgCountry}
            </Typography>

            <Typography
              gutterBottom
              variant="body2"
              sx={{ fontSize: "1rem", color: "#000", fontFamily: "poppins" }}
            >
              <strong>State:</strong> {orgData?.orgState}
            </Typography>

            <Typography
              gutterBottom
              variant="body2"
              sx={{ fontSize: "1rem", color: "#000", fontFamily: "poppins" }}
            >
              <strong>City:</strong> {orgData?.orgCity}
            </Typography>

            <Typography
              gutterBottom
              variant="body2"
              sx={{ fontSize: "1rem", color: "#000", fontFamily: "poppins" }}
            >
              <strong>Pincode:</strong> {orgData?.orgPinCode}
            </Typography>
          </Box>

          <KYCDetailsModel
            openKYCDialog={openKYCDialog}
            handleKYCDialogClose={handleKYCDialogClose}
            handleSaveDialog={handleSaveDialog}
          />

          <Box sx={{ display: "flex", justifyContent: "end", mt: 2 }}>
            <Button
                       sx={{ borderRadius: "0.7rem", backgroundColor: "#423bfa",textTransform:"none",fontWeight:"bold",fontFamily:"poppins" }}
              variant="contained"
              onClick={handleKYCEdit}
            >
              Edit
            </Button>
          </Box>
        </CardContent>
      </Collapse>
    </Paper>
  );
};

export default KYCComplainsDetails;
