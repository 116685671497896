import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import WorkIcon from '@mui/icons-material/Work';
import MessageIcon from '@mui/icons-material/Message';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PsychologyIcon from '@mui/icons-material/Psychology';
import JobseekerWalletSupport from "./JobseekerWalletSupport";
// Custom styled components
const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#000000",
  color: "white",
  padding: "12px 24px",
  borderRadius: "0.7rem",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#000000",
  },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  width: 50,
  height: 50,
  borderRadius: 8,
  backgroundColor: "#f0f7ff",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& svg": {
    color: "#5115c3",
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(1),
  border: "none",
  cursor: "pointer",
  transition: "box-shadow 0.3s ease-in-out",
  "&:hover": {
  },
}));

const TickMark = styled(CheckCircleOutlineIcon)(({ theme }) => ({
  opacity: 1,
  transition: "opacity 0.3s ease-in-out",
  color: theme.palette.text.secondary,
}));

export default function JobseekerWalletLandingPage() {
  const apps = [
    {
      icon: <ConnectWithoutContactIcon sx={{fontSize:"35px"}}/>,
      title: "Instant Contact to Counselors ",
      description: "Get expert career guidance anytime. First 60 minutes free, then ₹1/min.",
    },
    {
      icon: <VisibilityIcon  sx={{fontSize:"35px"}}/>,
      title: "Higher Visibility ",
      description: "Automatic refresh boost for better search rankings.",
    },
    {
      icon:  <FlashOnIcon  sx={{fontSize:"35px"}}/>,
      title: "Priority Access ",
      description: "View & apply to new jobs within 24 hours of posting.",
    },
    {
      icon: <MessageIcon sx={{fontSize:"35px"}}/>,
      title: "Direct Messaging ",
      description: "Instantly connect with recruiters",
    },
    {
      icon: <WorkIcon sx={{fontSize:"35px"}}/>,
      title: "Guaranteed Interviews  ",
      description: "Higher chances of job offers & interview calls.",
    },
    {
      icon: <PsychologyIcon sx={{fontSize:"35px"}}/>,
      title: "AI-Powered Job Matching ",
      description: "Smart recommendations, resume insights, interview scheduling & verified recruiter access.",
    },
   
  ];

  return (
    <Container maxWidth="xl" sx={{ paddingTop:8 ,backgroundColor:"#FCFCFCFF"}}>
      <Grid container spacing={8}>
        {/* Left Column */}
        <Grid item xs={12} md={6}>
          <Box sx={{ maxWidth: 600,display:"flex",alignItems:"center",justifyContent:"center",width:"100%",flexDirection:"column" }}>
            <Typography
              textAlign={"center"}
              sx={{
                fontWeight: 200,
                fontSize:"40px",
                fontFamily: "gilroy",
                lineHeight: 1.1,
                mb: 4,
              }}
            >
             Get Hired Faster   <br /> Instant Guidance, Higher Visibility
              & Smart Job Matches
            </Typography>

            <StyledButton
              variant="contained"
              endIcon={<ArrowForwardIcon />}
              size="large"
            >
              Get Started - 60 Minutes For Free
            </StyledButton>
            {/* <Typography
              sx={{
                fontFamily: "satoshi",
                fontWeight: "bold",
                fontSize: "14px",
                color: "#000",
                ml: 2,
                mt: 0.5,
              }}
            >
              Post 60 Minutes ₹ 1 / Minute
            </Typography> */}
          </Box>
        <JobseekerWalletSupport/>
        </Grid>

        {/* Right Column */}
        <Grid item xs={12} md={6} >
          <Typography
            variant="h6"
            color="text.secondary"
            textAlign="center"
            sx={{ mb: 1, fontWeight: 600 ,fontFamily:"satoshi",}}
          >
            Premium Plan
          </Typography>

          <Box sx={{ display: "flex", flexDirection: "column", gap: 3,border:"1px solid",borderColor:"grey.300",padding:2 }}>
            {apps.map((app, index) => (
              <StyledCard key={index} elevation={0}>
                <Box sx={{ display: "flex", gap: 1 ,alignItems:"center",justifyContent:"center"}}>
                  <IconWrapper>{app.icon}</IconWrapper>
                  <Box sx={{ flex: 1 }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        px:2
                      }}
                    >
                      <Typography variant="h6" sx={{ fontWeight: 600,fontFamily:"satoshi" }}>
                        {app.title}
                      </Typography>
                      <TickMark className="arrow-icon" />
                    </Box>
                    <Typography variant="body2" color="text.secondary" sx={{fontFamily  :"satoshi",px:2}}>
                      {app.description}
                    </Typography>
                  </Box>
                </Box>
              </StyledCard>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
