import baseURL from "./baseUrl";

export const getFeaturedJobSeekers = async () => {
  try {
    const response = await fetch(`${baseURL}/featured/jobseekers`, {
      method: "GET",
      headers: {
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      throw new Error("network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export const allTestimonialsApi = async () => {
  try {
    const response = await fetch(`${baseURL}/testimonials/user`, {
      method: "GET",
      headers: {
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      throw new Error("network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export const getJobsByOrgApi = async (orgId) => {
  try {
    const response = await fetch(
      `${baseURL}/organization/getJobsByOrg/${orgId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "Application/json",
        },
      }
    );
    if (!response.ok) {
      throw new Error("network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export const getOrganizaionDetailsApi = async (orgId) => {
  const authToken = localStorage.getItem("authToken")?.replace(/"/g, "");

  try {
    const response = await fetch(`${baseURL}/jobs/org/${orgId}`, {
      method: "GET",
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${authToken}`,

      },
    });
    if (!response.ok) {
      throw new Error("network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export const getJobseekerProfileApi = async (id, token) => {
  try {
    const response = await fetch(`${baseURL}/view/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message || "network response was not ok");
    }

    return data;
  } catch (error) {
    console.log(error.message);
    throw error;
   
   
  }
};

export const generateRefereshTokenApi = async (formValues) => {
  try {
    const response = await fetch(`${baseURL}/generateAuthToken`, {
      method: "POST",
      headers: {
        "Content-Type": "Application/json",
      },
      body: JSON.stringify(formValues),
    });
    if (!response.ok) {
      throw new Error("network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export const getProfilePercentageApi = async (userId, token) => {
  try {
    const response = await fetch(`${baseURL}/getProfilePercentage/${userId}`, {
      method: "GET",
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error("network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export const getUserApi = async (userId, token) => {
  try {
    const response = await fetch(`${baseURL}/jobseeker/${userId}`, {
      method: "GET",
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    if (!response.ok) {
      throw new Error("network response was not ok");
    }

   
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export const addNewResumeApi = async (userId, token, formData) => {
  try {
    const response = await fetch(`${baseURL}/addResume/${userId}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData, // Send FormData directly
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
    throw error; // Re-throw the error to handle it in the component
  }
};

export const addNewCertificateApi = async (userId, token, formData) => {
  try {
    const response = await fetch(`${baseURL}/addCertifications/${userId}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData, // Send FormData directly
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
    throw error; // Re-throw the error to handle it in the component
  }
};

export const addNewVideoCV = async (token, userId, formData) => {
  try {
    const response = await fetch(`${baseURL}/addVideo/${userId}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData, // Send FormData directly
    });
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message||"Network response was not ok");
    }


    return data;
  } catch (error) {
    console.log(error.message);
    throw error; // Re-throw the error to handle it in the component
  }
};

export const getContactApi = async (formData) => {
  try {
    const response = await fetch(`${baseURL}/createOrgSalesTicket`, {
      headers: {
        "Content-Type": "Application/json",
      },
      method: "POST",
      body: JSON.stringify(formData),
    });
    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || "Failed to create org ticket");
    }

    return data;
  } catch (error) {
    console.log(error.message);
    throw error; // Re-throw the error to handle it in the component
  }
};

export const resendOtpApi = async (formData) => {
  try {
    const response = await fetch(`${baseURL}/resend_otp`, {
      headers: {
        "Content-Type": "Application/json",
      },
      method: "POST",
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
    throw error; // Re-throw the error to handle it in the component
  }
};

export const getAllInvitesApi = async (userId) => {
  try {
    const response = await fetch(`${baseURL}/jobs/invites/${userId}`, {
      method: "GET",
      headers: {
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      throw new Error("network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export const updateInviteStatusAoi = async (inviteId, formData) => {
  try {
    const response = await fetch(`${baseURL}/jobs/status/${inviteId}`, {
      headers: {
        "Content-Type": "Application/json",
      },
      method: "POST",
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
    throw error;
  }
};

export const markAsDefaultCV = async (userId, payload) => {
  const authToken = localStorage.getItem("authToken")?.replace(/"/g, "");

  try {
    const response = await fetch(`${baseURL}/markDefault/${userId}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload), // Pass the payload (fileId, fileType)
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error:", error.message);
  }
};

export const deleteCV = async (userId, payload) => {
  const authToken = localStorage.getItem("authToken")?.replace(/"/g, "");

  try {
    const response = await fetch(`${baseURL}/deleteFile/${userId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload), // Pass the payload (fileId, fileType)
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error:", error.message);
  }
};
export const getChatIdApi = async (formData) => {
  try {
    const response = await fetch(`${baseURL}/chat/get/chatId`, {
      headers: {
        "Content-Type": "Application/json",
      },
      method: "POST",
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
    throw error;
  }
};

export const getWalletApi = async (userId) => {
  try {
    const response = await fetch(`${baseURL}/getWallet/${userId}`, {
      method: "GET",
      headers: {
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      throw new Error("network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export const reportJobPostApi = async (formData, token) => {
  try {
    const response = await fetch(`${baseURL}/jobs/reportJobPost`, {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${token}`,
      },
      method: "POST",
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
    throw error;
  }
};


 export const getSimilarJobsApi = async(jobId,token) =>{
  try {
    const response = await fetch(`${baseURL}/jobs/similarJobs/${jobId}`, {
      method: "GET",
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    if (!response.ok) {
      throw new Error("network response was not ok");
    }

   
    return data;
  } catch (error) {
    console.log(error.message);
  }
}

export const applyJobApi = async (token,formValues) => {
  try {
    const response = await fetch(`${baseURL}/jobs/apply`, {
      method: "POST",
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${token}`,
      },
   
      body: JSON.stringify(formValues),
    });
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data?.message||"Network response was not ok");
    }
    return data;
  } catch (error) {
    console.log(error.message);
    throw error;
  }
};