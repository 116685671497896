import React, { createContext, useContext, useState } from 'react';

// Create the context
const ModalContext = createContext();

// ModalProvider component to wrap around your app
export const ModalProvider = ({ children }) => {
  // State for the general modal
  const [modelOpen, setModelOpen] = useState(false);
  // State for the release job post modal
  const [releaseJobPostOpen, setReleaseJobPostOpen] = useState(false);

  // Functions to open and close the general modal
  const handleModelOpen = () => {
    setModelOpen(true);
  };

  const handleModelClose = () => {
    setModelOpen(false);
  };

  // Functions to open and close the release job post modal
  const handleReleaseJobPostOpen = () => {
    setReleaseJobPostOpen(true);
  };

  const handleReleaseJobPostClose = () => {
    setReleaseJobPostOpen(false);
  };

  return (
    <ModalContext.Provider
      value={{
        modelOpen,
        handleModelOpen,
        handleModelClose,
        releaseJobPostOpen,
        handleReleaseJobPostOpen,
        handleReleaseJobPostClose,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

// Custom hook to access modal context
export const useModal = () => {
  return useContext(ModalContext);
};
