const manageQuota = {
  internalCss: {
    marginRight: 2,
    fontWeight: "bold",
    textTransform: "none",
    borderRadius: "0.7rem",
    border: "1px solid black",
    backgroundColor: "#423bfa",
    fontSize: 12,
    padding: 1,
    alignItems: "center",
    marginTop: { xs: 2, sm: 0 },
    "&:hover": {
      backgroundColor: "#423bfa",
      color: "white",
      border: "1px solid #423bfa",
    },
  },
  buttonBox: {
    marginTop: 2,
    padding: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
  },
  closeicon: {
    fontSize: 19,
    mt: 1,
    opacity: 0.7,
    cursor: "pointer",
    ":hover": {
      color: "#423bfa",
      opacity: 1,
    },
  },
  textField: {
    height: "2.5ch",
    width: "7ch",
    input: {
      padding: "2px 8px",
    },
    transition: "opacity 0.3s ease, transform 0.3s ease",
  },
  editicon: {
    fontSize: 15,
    opacity: 0.7,
    ml: 1,
    cursor: "pointer",
    ":hover": {
      color: "#423bfa",
      opacity: 1,
    },
  },
  paper: {
    padding: 1,
    width: "60%",
    boxShadow: "2px 1px 7px 0px #9695c3",
  },
  typography: {
    fontWeight: "semibold",
    color: "gray",
    marginBottom: 2,
    fontSize: 12,
  },
  otpInput: {
    width: "50px", // Increase width of individual input boxes
    height: "50px", // Increase height of input boxes
    fontSize: "20px", // Adjust font size
    textAlign: "center", // Center the text in the input field
    border: "1px solid #ccc", // Border style
    borderRadius: "4px", // Rounded corners
  },
};
export default manageQuota;
