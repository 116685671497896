import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import findWork from "../../../assets/images/findwork.png";

const FindWork = () => {
  return (
    <Box sx={{ mb: 1, mt: 1 }}>
      <Grid
        container
        spacing={0}
        alignItems="stretch" // Ensure both sides stretch to the same height
      >
        {/* Left Side: Image */}
        <Grid item xs={12} md={6}>
          {" "}
          {/* Remove padding */}
          <img
            src={findWork} // Replace with your image URL
            alt="Talent"
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "0.01rem",
              objectFit: "cover",
            }}
          />
        </Grid>


        {/* Right Side: Text Content */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            background: "#423bfa",
            display: "flex",
            borderRadius: "0.1rem",
            flexDirection: "column",
            justifyContent: "center",
            padding: 3,
          }}
        >
          {/* Title */}
<Typography
            variant="h2"
            gutterBottom
            textAlign='center'
            fontWeight="normal"
            maxWidth="300px"
            sx={{ marginTop: 0, textAlign: "left", color: "#fff" }}
          >
            Our Vision
          </Typography>
          {/* Subtitle */}
          

          {/* Body Text */}
          <Typography
            variant="h6"
            paragraph
            sx={{ marginBottom: "1rem", textAlign: "left", color: "#fff" }}
          >
            To revolutionize the job market by creating a world where talent is
            recognized beyond paper resumes, connecting individuals with the
            right employers and opportunities that align with their passion,
            personality, and potential, enhanced by AI-driven innovation.
          </Typography>

         
        </Grid>
      </Grid>
    </Box>
  );
};

export default FindWork;
