import React, { useState, useEffect, useCallback } from "react";
import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  Divider,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";

// import signupVideo from "../../../assets/videos/videoplayback2.mp4";
import NonRegisteredNavBar from "../../../components/NavBarNonRegistered/NonRegisteredNavBar";
import JobSeekerCaseZero from "../../../components/JobSeekerComponents/JobSeekerRegistrationCases/JobSeekerCaseZero/JobSeekerCaseZero";
import JobSeekerCaseTwo from "../../../components/JobSeekerComponents/JobSeekerRegistrationCases/JobSeekerCaseTwo/JobSeekerCaseTwo";
// import JobSeekerCaseThree from "../../../components/JobSeekerComponents/JobSeekerRegistrationCases/JobSeekerCaseThree/JobSeekerCaseThree";
import JobSeekerCaseFour from "../../../components/JobSeekerComponents/JobSeekerRegistrationCases/JobSeekerCaseFour/JobSeekerCaseFour";
// import JobSeekerCaseFive from "../../../components/JobSeekerComponents/JobSeekerRegistrationCases/JobSeekerCaseFive/JobSeekerCaseFive";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import baseUrl from "../../../api/baseUrl";
import { debounce } from "lodash"; // Import debounce from lodash
import {
  getAllUgEducationsApi,
  getTopCitiesApi,
  getAllPgEducationsApi,
  getAllIndustriesApi,
} from "../../../api/common";
import googlePlay from "../../../assets/images/googlePlay.png";
import appStore from "../../../assets/images/appStore.png";

import SuccessModal from "../../../components/AdvancedComponents/SuccessModal";
import PopupSuccess from "../../../utils/popup/PopupSuccess";
import ReleaseJobPostModel from "../../EmployerPages/SubscriptionStatus/ReleaseJobPostModel";


export default function Job() {
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState(false);

  const navigate = useNavigate();

  const location = useLocation();
  const phone = location.state?.loginPhone || "";
  const [locations, setLocations] = useState([]);
  const [inputValue, setInputValue] = useState();
  const [ugEducations, setUgEducations] = useState([]);
  const [uGeducationInput, setUGeducationInput] = useState();
  const [pgEducations, setPgEducations] = useState([]);
  const [pGeducationInput, setpGeducationInput] = useState();
  const [allIndustries, setAllIndustries] = useState([]);
  const [industriesInput, setIndustriesInput] = useState();
  const [isUserRegistered, setIsUserRegistered] = useState(false);
  const [isVideoButtonDisabled, setIsVideoButtonDisabled] = useState(false);
  const [loading, setLoading] = useState(false); // New loading state
  const [modalOpen, setModalOpen] = useState(false);

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: phone,
    highestEducation: "",
    dob: "",
    currentCompany: "",
    dateOfJoining: "",
    dateOfExit: null,
    noticePeriod: "",
    servingNoticePeriod: true,
    gender: "",
    totalExp: 0,
    videoCV: "",
    audioCV: "",
    resume: "",

    degreeType: "",
    institution: "",
    industry: "",
    startDate: "",
    fieldOfStudy: "",
    currentlyWorking: true,
    endDate: "",
    grade: "",
    jobRole: [],
    experienceType: "",
    expectedCtc: "",
    totalExpInYears: 0,
    totalExpInMonths: 0,
    currentCtc: "",
    skills: [],
    jobDescription: "",
    currentDesignation: "",
    pursuingEducation: false,
    currentlyPursuing: "",
    profileImage: null,
    knownLanguage: [],
    preferredJobType: [], // Initialize as array
    preferredShift: [], // Initialize as array
    preferredWorkplace: [], // Initialize as array
    preferredEmploymentType: "",
    preferredLocation: [],
    currentLocation: "",
    graduationYear: null,
    hasWorkExperience: true,
    preferredJobTitle: "",
  });

  const [formDataErrors, setFormDataErrors] = useState({}); // State to store errors
  const [formErrors, setFormErrors] = useState({
    fullNameError: "",
    emailError: "", // Add this to match your validation
    // Other field errors if needed...
  });
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const steps = [
    "Basic Details",
    "",
    "Job Details",
    "",
    "Profile Photo",
    "Resume Upload",
  ];
  const handleNext = async () => {
    // Step 0: Validate, register user if needed, submit, and then proceed based on experienceType
    if (activeStep === 0) {
      if (!validateStep()) {
        return; // Stop if validation fails
      }

      if (!isUserRegistered) {
        const registered = await registerUser(formData);
        if (!registered) return;
        setIsUserRegistered(true);
      }

      // Submit the form data after Step 0 validation passes
      await handleSubmit();

      // Proceed based on experienceType (same as original logic)
      if (formData?.experienceType !== "experienced") {
        setActiveStep(4);
      } else {
        setActiveStep(2);
      }
      return;
    }

    // Step 2: For experienced users, validate, submit, and then proceed to Step 4
    if (activeStep === 2 && formData?.experienceType === "experienced") {
      if (!validateStep2()) return;

      // Submit the form data after Step 2 validation passes
      await handleSubmit();

      // Proceed to Step 4 (same as original logic)
      setActiveStep(4);
      return;
    }

    // Step 4: Call handleSubmit and set completion state
    if (activeStep === 4) {
      if (!formData?.profileImage) {
        handleError('Please upload a Profile Image before submitting.')
        return;
      }
      await handleSubmit(true); // Pass a flag to indicate this is the final step
      return;
    }

    // For other steps, just proceed to the next step
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleSuccess = useCallback((message) => {
    setSuccessMessage(message);
    setSuccessOpen(true);
    setTimeout(() => {
      setSuccessOpen(false);
      setSuccessMessage("");
    }, 2000);
  }, []);

  const handleError = useCallback((message) => {
    setErrorMessage(message);
    setErrorOpen(true);
    setTimeout(() => {
      setErrorOpen(false);
      setErrorMessage("");
    }, 2000);
  }, []);

  const registerUser = async (formData) => {
    try {
      const registerUserUrl = `${baseUrl}/js_register`;
      const registerPayload = {
        phone: formData.phone,
        email: formData.email,
        fullName: formData.fullName,
        platform: "web",
      };
      const registerResponse = await fetch(registerUserUrl, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(registerPayload),
      });
      const registerData = await registerResponse.json();
      if (!registerResponse.ok) {
        throw new Error(registerData.message || "Error Registering User");
      }
      handleSuccess("User registered successfully");
      localStorage.setItem("authToken", registerData.token || "");
      localStorage.setItem("refreshToken", registerData.refreshToken || "");
      localStorage.setItem("userId", registerData.user?._id || "");
      return true;
    } catch (error) {  
      handleError(error.message || "User registration failed!"); // Show error message first
  
      if (error?.message?.toLowerCase().includes("session expired")) {
        setTimeout(() => {
          localStorage.removeItem("authToken");
          navigate("/signinsignup");
        }, 2000); // Navigate after a slight delay
      }
      console.log("Error in log:", error?.message);
      return false;
    }
  };

  useEffect(() => {
    // Push the current state to the history when the component mounts
    window.history.pushState(null, document.title, window.location.pathname);

    // Add event listener for popstate (back/forward button)
    const handlePopState = (event) => {
      // Prevent back navigation by pushing the current page again
      window.history.pushState(null, document.title, window.location.pathname);
      // Optional: Show a message or trigger an action
    };

    window.addEventListener("popstate", handlePopState);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []); // Empty dependency array ensures this runs only on mount/unmount

  const handleBack = () => {
    if (activeStep === 4) {
      if (formData?.experienceType !== "experienced") {
        setActiveStep(0);
        return;
      } else if (formData?.experienceType === "experienced") {
        setActiveStep(2);
        return;
      }
    }

    if (activeStep === 2 && formData?.experienceType === "experienced") {
      setActiveStep(0);
      return;
    }

    if (activeStep === 0) {
      setActiveStep(0);
      return;
    }

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    // If the value is an array (for Autocomplete), update state directly
    if (Array.isArray(value)) {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    } else {
      // Handle other types like string values for Select
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const fetchCities = useMemo(
    () =>
      debounce(async (query) => {
        try {
          if (query?.trim().length >= 2) {
            const response = await getTopCitiesApi(query);
            if (response && response.cities) {
              setLocations(response.cities);
            }
          } else {
            setLocations([]);
          }
        } catch (error) {
          console.log(error.message);
        }
      }, 300),
    []
  );

  useEffect(() => {
    fetchCities(inputValue);
  }, [inputValue, fetchCities]);

  const fetchUgEducation = useMemo(
    () =>
      debounce(async (searchQuery) => {
        try {
          const response = await getAllUgEducationsApi();
          if (response && response[0]?.items) {
            const filteredEducations = response[0].items.filter((item) =>
              item?.name?.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setUgEducations(filteredEducations);
          } else {
            setUgEducations([]);
          }
        } catch (error) {
          console.error("Error fetching UG education:", error.message);
          setUgEducations([]); // Reset on error
        }
      }, 300),
    []
  );

  useEffect(() => {
    fetchUgEducation(uGeducationInput || "");
  }, [uGeducationInput, fetchUgEducation]);

  const fetchPgEducation = useMemo(
    () =>
      debounce(async (searchQuery) => {
        try {
          // Fetch the education data
          const response = await getAllPgEducationsApi();

          // Filter the response based on the search query
          if (response && response[0]?.items) {
            const filteredEducations = response[0].items.filter((item) =>
              item?.name?.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setPgEducations(filteredEducations);
          } else {
            setPgEducations([]);
          }
        } catch (error) {
          console.error("Error fetching PG education:", error.message);
        }
      }, 300), // Debounce with 300ms delay
    []
  );

  useEffect(() => {
    fetchPgEducation(pGeducationInput || "");
  }, [pGeducationInput, fetchPgEducation]);

  const fetchIndustries = useMemo(
    () =>
      debounce(async (searchQuery) => {
        try {
          const industries = await getAllIndustriesApi();

          // Filter industries based on search query
          const filteredIndustries = searchQuery
            ? industries.filter((name) =>
                name?.toLowerCase().includes(searchQuery.toLowerCase())
              )
            : industries;

          setAllIndustries(filteredIndustries);
        } catch (error) {
          console.error("Error fetching Industries:", error.message);
        }
      }, 300), // Debounce with 300ms delay
    [] // Ensure debounce updates with input changes
  );

  useEffect(() => {
    fetchIndustries(industriesInput || "");
  }, [industriesInput, fetchIndustries]);

  const handleLanguageChange = (event, newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      knownLanguage: newValue, // Directly set the new value (array of selected languages)
    }));
  };

  const handleGenderChange = (gender) => {
    setFormData({
      ...formData,
      gender: gender,
    });
  };

  const handleJobRoleChange = (event, newValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      experiences: [
        {
          ...prevFormData.experiences[0],
          jobRole: newValue,
        },
      ],
    }));
  };

  const toSentenceCase = (fieldName) => {
    // Replace camelCase with spaces and capitalize the first letter
    const result = fieldName
      .replace(/([A-Z])/g, " $1")
      .toLowerCase()
      .trim();
    return result.charAt(0).toUpperCase() + result.slice(1);
  };

  const validateStep = () => {
    const errors = {}; // Object to store required field errors
    let isValid = true;

    const requiredFields = new Set([
      "fullName",
      "email",
      "gender",
      "dob",
      "experienceType",
      "currentLocation",
      "preferredLocation",
    ]);

    if (["postGraduate", "underGraduate"].includes(formData?.degreeType)) {
      requiredFields.add("highestEducation");
    }

    if (formData?.experienceType === "fresher") {
      requiredFields.add("institution");
      requiredFields.add("graduationYear");
      requiredFields.add("degreeType");
      requiredFields.add("preferredJobTitle");
    }

    if (formData?.experienceType === "pursuingEducation") {
      requiredFields.add("institution");
      requiredFields.add("graduationYear");
      requiredFields.add("degreeType");
      requiredFields.add("preferredJobTitle");
    }

    if (formData?.experienceType === "experienced") {
      requiredFields.add("institution");
      requiredFields.add("graduationYear");
      requiredFields.add("degreeType");


    }

    // Validate required fields
    requiredFields.forEach((field) => {
      if (!formData[field] || String(formData[field]).trim() === "") {
        // Use toSentenceCase to format the field name
        errors[field] = `${toSentenceCase(field)} is required`;
        isValid = false;
      }
    });

    // Check real-time validation errors from formErrors (child component)
    const hasFormErrors = Object.values(formErrors).some(
      (error) => error !== ""
    );
    if (hasFormErrors) {
      isValid = false; // Fail validation if there are any formErrors
    }

    setFormDataErrors(errors); // Update required field error state
    return isValid;
  };
  
  const validateStep2 = () => {
    const errors = {}; // Object to store errors
    let isValid = true;

    const requiredFields = new Set([
      "currentDesignation",
      "currentCompany",
      "industry",
      "jobDescription",
      "currentCtc",
      "currentLocation",
      "preferredLocation",
      "degreeType",
    ]);

    // Validate required fields
    requiredFields.forEach((field) => {
      if (!formData[field] || String(formData[field]).trim() === "") {
        // Use toSentenceCase to format the field name
        errors[field] = `${toSentenceCase(field)} is required`;
        isValid = false;
      }
    });

    setFormDataErrors(errors); // Update error state
    return isValid;
  };

  const handleExperienceChange = (experience) => {
    handleChange({
      target: {
        name: "pursuingEducation",
        value: experience,
      },
    });

    handleChange({
      target: {
        name: "experienceType",
        value: experience ? "experienced" : "fresher",
      },
    });
  };

  const handlePursuingEducationChange = (value) => {
    // Automatically update experienceType based on pursuingEducation
    handleChange({
      target: {
        name: "experienceType",
        value: value,
      },
    });
  };

  const handleCurrentlyPursuingChange = (value) => {
    handleChange({
      target: {
        name: "currentlyPursuing",
        value: value,
        experienceType:
          formData?.pursuingEducation === "experienced"
            ? "Expierenced"
            : "Fresher",
      },
    });
  };

  const handleIndustryChange = (event, newValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      industry: newValue,
    }));
  };

  const handleCurrentlyWorkingChange = (workingStatus) => {
    handleChange({
      target: {
        name: "currentlyWorking",
        value: workingStatus,
      },
    });
  };

  const handleSkillsChange = (event, newValue) => {
    // Update the formData with the selected skills
    setFormData((prevFormData) => ({
      ...prevFormData,
      skills: newValue, // skills is now just an array of strings
    }));
  };

  const handleProceed = () => {
    setCompleted(false);
    setActiveStep(0);
    setFormData({
      fullName: "",
      email: "",
      phone: phone,
      highestEducation: "",
      dob: "",
      currentCompany: "",
      dateOfJoining: "",
      dateOfExit: null,
      noticePeriod: "",
      gender: "male",
      // totalExp: 0,
      totalExpInMonths: 0,
      currentCtc: "",
      videoCV: "",
      audioCV: "",
      resume: "",
      skills: [],
      jobDescription: "",
      currentDesignation: "",
      pursuingEducation: false,
      currentlyPursuing: "NA",
      currentlyWorking: true,
      profileImage: null,
      preferredJobType: [], // Initialize as array
      preferredShift: [], // Initialize as array
      preferredWorkplace: [], // Initialize as array
      preferredEmploymentType: "",
      graduationYear: null,
      hasWorkExperience: true,
      preferredJobTitle: "",
    });
    setModalOpen(true);

    navigate("/JobSeekerFilter");
  };

  // useEffect(() => {
  //   const registerUser = async () => {
  //     try {
  //       const registerUserUrl = `${baseUrl}/js_register`;
  //       const registerPayload = {
  //         phone: formData.phone,
  //         email: formData.email,
  //         fullName: formData.fullName,
  //       };

  //       const registerResponse = await fetch(registerUserUrl, {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify(registerPayload),
  //       });

  //       if (!registerResponse.ok) {
  //         throw new Error("Error Registering User");
  //       }
  //       const registerData = await registerResponse.json();
  //       toast.success("User registered successfully");

  //       console.log("User registered successfully:", registerData);

  //       // ** Clear old storage before setting new values **
  //       localStorage.removeItem("authToken");
  //       localStorage.removeItem("refreshToken");
  //       localStorage.removeItem("userId");

  //       // Assuming the response contains a token, save it to localStorage
  //       if (registerData.token) {
  //         localStorage.setItem("authToken", registerData.token);
  //         localStorage.setItem("refreshToken", registerData.refreshToken);
  //         localStorage.setItem("userId", registerData.user._id);
  //       }
  //     } catch (error) {
  //       console.error("Error registering user:", error);
  //     }
  //   };

  //   // Call registerUser only when activeStep === 4
  //   if (activeStep === 4) {
  //     registerUser();
  //   }
  // }, [activeStep, formData.phone, formData.email, formData.fullName]);
  const stepsNew =
    formData.experienceType === "experienced" ? [0, 2, 4] : [0, 4];

  const stepsStepper = (
    <Grid item xs={6} sx={{ textAlign: "right", mb: 2 }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          gap: 1,
        }}
      >
        {stepsNew.map((step) => (
          <Box
            key={step}
            sx={{
              width: 100, // Adjust width for step indicators
              height: 10, // Maintain progress bar height
              borderRadius: 5,
              backgroundColor: step <= activeStep ? "#423BFA" : "#E0E0E0", // Active and inactive steps
            }}
          />
        ))}
      </Box>
    </Grid>
  );

  const handleSubmit = async (isFinalStep = false) => {
    const authToken = localStorage.getItem("authToken");
    if (!authToken) {
      handleError("No authentication token found");

      return;
    }

    setLoading(true); // Start loading
    try {
      const apiEndpoint = `${baseUrl}/registerJsDetails/web`;
      const formDataToSend = new FormData();

      Object.keys(formData).forEach((key) => {
        const value = formData[key];
        if (Array.isArray(value)) {
          value.forEach((item) => formDataToSend.append(`${key}[]`, item));
        } else {
          formDataToSend.append(key, value);
        }
      });

      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        body: formDataToSend,
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Form submitted successfully:", data);

        // Only set completion state and proceed if this is the final step (Step 4)
        if (isFinalStep) {
          setCompleted(true);
          setModalOpen(true);
          setTimeout(() => {
            handleProceed();
          }, 2000);
          setActiveStep(steps.length); // Move to the last step to reflect completion
          // setActiveStep(steps.length); // Move to the last step to reflect completion
        }
        handleSuccess(
          isFinalStep
            ? "Profile created successfully!"
            : "Data saved successfully!"
        );
      } else {
        const errorData = await response.json();
        throw new Error(errorData?.message || "Failed to create profile");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      handleError(error.message || "Failed to create profile")
     
    } finally {
      setLoading(false); // Stop loading regardless of success or failure
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <JobSeekerCaseZero
            formData={formData}
            handleChange={handleChange}
            setFormData={setFormData}
            handleGenderChange={handleGenderChange}
            handleExperienceChange={handleExperienceChange}
            handleCurrentlyPursuingChange={handleCurrentlyPursuingChange}
            handlePursuingEducationChange={handlePursuingEducationChange}
            locations={locations}
            setInputValue={setInputValue}
            ugEducations={ugEducations}
            setUGeducationInput={setUGeducationInput}
            setpGeducationInput={setpGeducationInput}
            pgEducations={pgEducations}
            formDataErrors={formDataErrors}
            setLocations={setLocations}
            inputValue={inputValue}
            formErrors={formErrors}
            setFormErrors={setFormErrors}
          />
        );
      case 2: // Skip case 1
        return (
          <JobSeekerCaseTwo
            steps={steps}
            formData={formData}
            setFormData={setFormData}
            handleSkillsChange={handleSkillsChange}
            handleLanguageChange={handleLanguageChange}
            handleJobRoleChange={handleJobRoleChange}
            handleIndustryChange={handleIndustryChange}
            handleChange={handleChange}
            handleCurrentlyWorkingChange={handleCurrentlyWorkingChange}
            locations={locations}
            setInputValue={setInputValue}
            allIndustries={allIndustries}
            setIndustriesInput={setIndustriesInput}
            formDataErrors={formDataErrors}
            setFormDataErrors={setFormDataErrors}
          />
        );
      // case 3:
      //   return (
      //     <JobSeekerCaseThree
      //       formData={formData}
      //       handleCurrentlyPursuingChange={handleCurrentlyPursuingChange}
      //       handlePursuingEducationChange={handlePursuingEducationChange}
      //       handleChange={handleChange}
      //     />
      //   );
      case 4:
        return (
          <JobSeekerCaseFour
            formData={formData}
            handleChange={handleChange}
            handleNext={handleNext}
            isVideoButtonDisabled={isVideoButtonDisabled}
            setIsVideoButtonDisabled={setIsVideoButtonDisabled}
            handleError={handleError}
            handleSuccess={handleSuccess}
          />
        );
      // case 5:
      //   return (
      //     <ThemeProvider theme={theme}>
      //       <JobSeekerCaseFive
      //         formData={formData}
      //         handleChange={handleChange}
      //         setFormData={setFormData}
      //       />
      //     </ThemeProvider>
      //   );
      default:
        return "Unknown step";
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "#f5faf5",
        minHeight: "100vh",
        mb: "20px",
      }}
    >
      <NonRegisteredNavBar />
      <Container maxWidth="xl" sx={{ marginTop: "4rem" }}>
        <Grid container spacing={10}>
          <Grid item xs={12} md={8}>
            {completed ? (
              <SuccessModal open={modalOpen} handleClose={handleCloseModal} />
            ) : (
              <Container>
                <Grid
                  sx={{
                    backgroundColor: "#ffffff",
                    padding: 2,
                    borderRadius: 4,
                  }}
                >
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: "bold", mb: 2 }}
                      >
                        <KeyboardBackspaceIcon
                          disabled={activeStep === steps + 1}
                          onClick={handleBack}
                          sx={{ mr: 2, color: activeStep === 0 ? "grey" : "" }}
                        />
                        {steps[activeStep]}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: "right", mb: 2 }}>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          gap: 1,
                        }}
                      >
                        {stepsStepper}
                      </Box>
                    </Grid>
                  </Grid>
                  <Divider />
                  {renderStepContent(activeStep)}
                  <Divider />
                  {/* {activeStep === 4 && (
                  <Box mt={2}>
                    <Button
                      sx={{
                        backgroundColor: "transparent",
                        padding: "8px",
                        borderRadius: 2,
                        border: "1px solid #423BFA",
                        "&:hover": {
                          backgroundColor: "#4CAF50",
                          borderColor: "#4CAF50",
                        },
                      }}
                      type="submit"
                      fullWidth
                      onClick={handleNext}
                    >
                      <Typography
                        sx={{ fontWeight: "bold" }}
                        variant="subtitle2"
                        color="#423BFA"
                      >
                        Skip
                      </Typography>
                    </Button>
                  </Box
                )} */}
                  <Box
                    sx={{
                      mt: activeStep !== 4 ? 4 : 0,
                      position: "sticky",
                      bottom: 0,
                      zIndex: 10,
                    }}
                  >
                    <Button
                      sx={{
                        mt: 2,
                        backgroundColor: "#423bfa",
                        color: "white",
                        fontWeight: "bold",
                        borderRadius: "0.7rem",
                        padding: "0.8rem",
                        fontSize: "1rem",
                        "&:hover": {
                          backgroundColor: "#B0F0F0",
                          color: "#000",
                        },
                        mb: 3,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      fullWidth
                      variant="contained"
                      onClick={handleNext} // Always call handleNext, which will decide when to submit
                      disabled={loading}
                    >
                      {loading ? (
                        <CircularProgress size={24} sx={{ color: "white" }} />
                      ) : (
                        <Typography
                          sx={{ fontWeight: "bold" }}
                          variant="subtitle2"
                        >
                          {activeStep === 4 ? "Submit" : "Next"}
                        </Typography>
                      )}
                    </Button>
                  </Box>
                </Grid>
              </Container>
            )}
            <Typography
              variant="h3"
              component="h1"
              sx={{
                color: "#1b1919",
                textAlign: "center",
                padding: 4,
                fontWeight: 600,
                marginBottom: { xs: 2, md: 3 },
                fontSize: { xs: "2rem", md: "2.2rem" },
                fontFamily: "IBM Plex Sans",
                lineHeight: 1.3,
                textShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
              }}
            >
              A Job Tailored to Your Personality, Driven by AI
            </Typography>
          </Grid>

          <Grid item xs={12} md={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                justifyContent: "flex-start",
                gap: 2, // Controls the spacing between video and images
                alignItems: "center",
              }}
            >
              {/* Video Section */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 1, // Slight gap between the video and app download buttons
                }}
              >
                <video
                  src="/heroVid.mp4"
                  autoPlay
                  muted
                  loop
                  style={{
                    width: "100%",
                    maxWidth: "320px",
                    height: "auto",
                    objectFit: "cover",
                    borderRadius: "12px",
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: 2,
                  }}
                >
                  <img
                    src={appStore}
                    alt="App Store"
                    style={{ width: "150px" }}
                  />
                  <img
                    src={googlePlay}
                    alt="Google Play"
                    style={{ width: "150px" }}
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <PopupSuccess
        modelOpen={successOpen}
        onClose={() => setSuccessOpen(false)}
        message={successMessage}
      />
      <ReleaseJobPostModel
        modelOpen={errorOpen}
        onClose={() => setErrorOpen(false)}
        message={errorMessage}
      />
    </Box>
  );
}
