import { Box, Typography, Card, CardContent, Avatar,Button } from "@mui/material"




const styles = {
  container: {
    bgcolor: "rgb(247, 245, 238)",
    borderRadius: 10,
    p: 3,
    mb:4
   
  },
  followButton: {
    textTransform: "none",
    py: 0,
    width: "100%",
    borderRadius: 4,
    fontFamily: "satoshi",
    fontWeight: 600,
    mt:2,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    mb: 3,
  },
  title: {
    fontSize: "1.5rem",
    fontFamily:"Satoshi",
    fontWeight: 600,
    color: "#000",
  },
  dismissButton: {
    fontSize: "0.875rem",
    color: "rgba(0, 0, 0, 0.6)",
    textTransform: "none",
    "&:hover": {
      bgcolor: "rgba(0, 0, 0, 0.04)",
    },
  },
  cardsContainer: {
    display: "flex",
    gap: 2,
    overflowX: "auto",
    pb: 1,
    "::-webkit-scrollbar": {
      width: 0, // This hides the scrollbar
      display:"none"
    },
    "::-webkit-scrollbar-track": {
      bgcolor: "rgba(0, 0, 0, 0.05)",
      borderRadius: 3,
    },
    "::-webkit-scrollbar-thumb": {
      bgcolor: "rgba(0, 0, 0, 0.2)",
      borderRadius: 3,
    },
  },
  profileCard: {
    minWidth: 240,
    cursor:"pointer",
    bgcolor: "#fff",
    border: "1px solid",
    borderColor: "grey.200",
    borderRadius: 3,
    boxShadow: "none",
    "&:hover": {
      boxShadow: "0 2px 8px rgba(0,0,0,0.08)",
    },
  },
  cardContent: {
    fontFamily:"Satoshi",
    padding: 2.5,
    "&:last-child": {
      paddingBottom: 2.5,
    },
  },
  avatar: {
    width: 80,
    height: 80,
    mb: 2,
  },
  name: {
    fontSize: "1rem",
    fontFamily:"Satoshi",
    fontWeight: 600,
    color: "#000",
    mb: 1,
  },
  description: {
    fontSize: "0.875rem",
    fontFamily: "Satoshi",
    color: "rgba(0, 0, 0, 0.6)",
    lineHeight: 1.4,
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2, // Limits to 2 lines
    overflow: "hidden",
    textOverflow: "ellipsis",
  }
}



const SimilarProfiles = ({counsellors,counsellorId}) => {
   
    
    const handleNavigate = (id) => {
        window.open(`/counsellorProfile/${id}`, "_blank");
      };
  return (
    <Box sx={styles.container}>
      <Box sx={styles.header}>
        <Typography sx={styles.title}>Similar Profiles</Typography>
        {/* <Button sx={styles.dismissButton}>Dismiss</Button> */}  
      </Box>

      <Box sx={styles.cardsContainer}>
        {counsellors?.filter((profile)=>profile._id !== counsellorId).map((profile) => (
          <Card key={profile._id} sx={styles.profileCard} onClick = {()=>handleNavigate(profile._id)}>
            <CardContent sx={styles.cardContent}>
              <Avatar src={profile.profileImage} alt={profile.name} sx={styles.avatar} />
              <Typography sx={styles.name}>{profile.fullName}</Typography>
              <Typography sx={styles.description}>{profile.bio}</Typography>
              <Button
                          variant="outlined"
                          fullWidth
                          sx={styles.followButton}
                          startIcon={<span>+</span>}
                        >
                          Contact Now
                        </Button>    
            </CardContent>
          </Card>
        ))}
      </Box>
    </Box>
  )
}

export default SimilarProfiles
