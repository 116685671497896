import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Button, Box, TextField, Modal } from '@mui/material';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const JobSeekerSupportModal = ({ open, handleClose, handleSave }) => {
  const [topic, setTopic] = useState('');
  const [message, setMessage] = useState('');

  const handleTopicChange = (event) => {
    setTopic(event.target.value);

  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  }

  const handleSaveClick = () => {
    if (topic.trim()) {
      handleSave(topic, message);
      handleClose();
    } else {
      alert('Topic cannot be empty');
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={modalStyle}>
        <Typography id="modal-title" variant="h6">
          Create Support Ticket
        </Typography>

        <TextField
          placeholder="Topic"
          variant="outlined"
          fullWidth
          margin="normal"
          size="small"
          value={topic}
          onChange={handleTopicChange}
        />
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Enter Message"
          value={message}
          onChange={handleMessageChange}
          size="small"
          sx={{ marginTop: 1 }}
        />

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button
            sx={{
              backgroundColor: "#423BFA",
              padding: "8px",
              borderRadius: 2,
              "&:hover": {
                backgroundColor: "#4CAF50",
              },
            }}
            type="submit"
            fullWidth
            onClick={handleSaveClick}
          >
            <Typography
              sx={{ fontWeight: "bold" }}
              variant="subtitle2"
              color="white"
            >
              Submit
            </Typography>
          </Button>



        </Box>
      </Box>
    </Modal>
  );
};

JobSeekerSupportModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
};

export default JobSeekerSupportModal;
