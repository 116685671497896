import React, { useState } from "react";
import {
  Box,
  Paper,
  Typography,
  Tooltip,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  Menu,
} from "@mui/material";
import {
  PinDropOutlined as PinDropOutlinedIcon,
  DateRangeOutlined as DateRangeOutlinedIcon,
  Groups2Outlined as Groups2OutlinedIcon,
  PersonOutlineOutlined as PersonOutlineOutlinedIcon,
  RemoveRedEyeOutlined as RemoveRedEyeOutlinedIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { ThreeDot } from "react-loading-indicators";
import { changeJobStatusApi, deleteJobApi } from "../../../api/jobPostAPI";

import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

import { truncateText } from "../../../utils/helperFunctions/Format";

const JobCard = ({ job, fetchJobPostings, handleError, handleSuccess }) => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openStatusDialog, setOpenStatusDialog] = useState(false); // New state for status dialog
  const [loading, setLoading] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false); // New loading state for status change
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(""); // Track selected status

  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const handleEditJob = () => {
    localStorage.setItem("job", JSON.stringify(job));
    navigate(`/employerPostedJobDetails/${job?.jobId}`);
  };

  const handleTemplateJob = () => {
    if (!job?.jobId) {
      console.error("Job ID is undefined. Cannot navigate to template.");
      return;
    }
    localStorage.setItem("job", JSON.stringify(job));
    navigate(`/applicationTemplate/${job.jobId}`);
  };

  const handleDeleteConfirmBox = (jobId) => {
    setOpenDeleteDialog(true);
    setSelectedJobId(jobId);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleDeleteJob = async () => {
    setLoading(true);
    try {
      const response = await deleteJobApi(token, selectedJobId);

      handleSuccess(response.message || "Job Deleted Successfully");
    } catch (error) {
      handleError(error.message || "Failed to delete job post");
      console.log(error.message);
    } finally {
      fetchJobPostings();
      setLoading(false);
      setOpenDeleteDialog(false);
    }
  };

  const handleOpenStatusMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseStatusMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenStatusDialog = (status, jobId) => {
    setSelectedStatus(status);
    setSelectedJobId(jobId);
    setOpenStatusDialog(true);
    handleCloseStatusMenu();
  };

  const handleCloseStatusDialog = () => {
    setOpenStatusDialog(false);
    setSelectedStatus("");
    setSelectedJobId(null);
  };

  const handleChangeJobStatus = async () => {
    setStatusLoading(true);
    try {
      const formValues = {
        status: selectedStatus,
        endDate: job?.endDate, // Use existing endDate from job object
      };
      const response = await changeJobStatusApi(
        selectedJobId,
        token,
        formValues
      );

      handleSuccess(response.message || "Job Status Changed Successfully");
    } catch (error) {

      handleError(error.message || "Failed To Change Job Status")
      console.log(error.message);
    } finally {
      setStatusLoading(false);
      fetchJobPostings();
      handleCloseStatusDialog();
    }
  };

  return (
    <Paper
      elevation={0}
      sx={{
        position: "relative",
        padding: 3,
        backgroundColor: "#ffffff",
        cursor: "pointer",
        borderRadius: "12px",
        border: "1px solid",
        borderColor: "grey.400",
        width: "100%",
        fontFamily: "poppins",
        height: "340px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        overflow: "hidden",
      }}
    >
      <Paper
        elevation={0}
        sx={{
          position: "absolute",
          top: 48,
          left: 8,
          backgroundColor:
            job?.status === "active"
              ? "#4CAF50"
              : job?.status === "inactive"
              ? "#22B2FFFF"
              : job?.status === "draft"
              ? "#757575"
              : job?.status === "expired"
              ? "#000000FF"
              : "#757575",
          color: "#fff",
          fontSize: "12px",
          fontWeight: "bold",
          borderRadius: 0,
          px: 2,
          py: 1,
          transform: "rotate(45deg)",
          transformOrigin: "top left",
          zIndex: 0,
          fontFamily: "Poppins, sans-serif",
          width: "190px",
          textAlign: "center",
          marginLeft: "-38px",
          marginTop: "160px",
          boxShadow: "1px 1px 2px rgba(0, 0, 0, 0.2)",
        }}
      >
        {job?.status}
      </Paper>

      {/* Job Details */}
      <Box sx={{ flexGrow: 1 }} onClick={handleEditJob}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            fontFamily: "poppins",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            mb: 1,
          }}
        >
          {job?.title}
        </Typography>

        {job?.company && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: 1,
              mb: 2,
            }}
          >
            <Typography
              variant="body2"
              color="textSecondary"
              fontFamily="poppins"
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                flex: 1,
              }}
            >
              {job?.company}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              fontFamily="poppins"
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {job.walkIn ? "Walk in Interview" : "Virtual Interview"}
            </Typography>
          </Box>
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1.5,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <PinDropOutlinedIcon color="action" />
            <Typography
              variant="body2"
              color="textSecondary"
              fontFamily="Poppins, sans-serif"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {truncateText(
                Array.isArray(job?.location) && job?.location.length > 0
                  ? job.location.join(", ")
                  : "Location Not Available",
                60
              )}
            </Typography>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <DateRangeOutlinedIcon color="action" />
            <Typography
              variant="body2"
              color="textSecondary"
              fontFamily="poppins"
            >
              Posted on: {job?.postedOn}
            </Typography>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <PersonOutlineOutlinedIcon color="action" />
            <Typography
              variant="body2"
              color="textSecondary"
              fontFamily="poppins"
            >
              Posted By: {job?.postedBy}
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* Metrics and Actions */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1.5,
          mt: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Groups2OutlinedIcon color="action" />
            <Typography
              variant="body2"
              color="textSecondary"
              fontFamily="poppins"
            >
              Applications: {job?.applicationCount}
            </Typography>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <RemoveRedEyeOutlinedIcon color="action" />
            <Typography
              variant="body2"
              color="textSecondary"
              fontFamily="poppins"
            >
              Views: {job?.viewCount}
            </Typography>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography
              variant="body2"
              color="textSecondary"
              fontFamily="poppins"
            >
              Status:
            </Typography>
            <Typography
              fontWeight="bold"
              variant="body2"
              color="textSecondary"
              fontFamily="poppins"
            >
              {job?.status.charAt(0).toUpperCase() + job?.status.slice(1)}
            </Typography>
          </Box>
        </Box>

        {job?.jobRole && (
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <DescriptionOutlinedIcon color="action" />
            <Typography
              variant="body2"
              color="textSecondary"
              fontFamily="poppins"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              Role: {truncateText(job?.jobRole || "", 60)}
            </Typography>
          </Box>
        )}

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 1,
            mt: 1,
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <Button
            onClick={handleTemplateJob}
            sx={{
              bgcolor: "#000000",
              color: "white",
              borderRadius: "0.5rem",
              fontFamily: "Poppins",
              textTransform: "none",
              marginBottom: "5px",
              fontSize: "0.75rem",
              padding: "6px 12px",
              ":hover": { bgcolor: "#000000", color: "white" },
            }}
          >
            Select Template
          </Button>

          {job?.status && (
            <Tooltip title="Change Job Status">
              <IconButton
                onClick={handleOpenStatusMenu}
                sx={{ borderRadius: "0.7rem" }}
              >
                <ChangeCircleIcon sx={{ color: "#423bfa", fontSize: "30px" }} />
              </IconButton>
            </Tooltip>
          )}

          <Tooltip title="Delete This Job">
            <IconButton
              onClick={() => handleDeleteConfirmBox(job?.jobId)}
              sx={{ borderRadius: "0.7rem" }}
            >
              <DeleteIcon sx={{ color: "#000000", fontSize: "30px" }} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      {/* Status Menu */}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseStatusMenu}
        PaperProps={{
          sx: {
            borderRadius: "0.7rem",
            minWidth: 120,
            fontFamily: "Poppins, sans-serif",
          },
        }}
      >
        {["active", "expired", "draft", "inactive"].map((status) => (
          <MenuItem
            onClick={() => handleOpenStatusDialog(status, job?.jobId)}
            key={status}
            sx={{ fontFamily: "Poppins, sans-serif" }}
          >
            {status.charAt(0).toUpperCase() + status.slice(1)}
          </MenuItem>
        ))}
      </Menu>

      {/* Delete Dialog */}
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle sx={{ fontFamily: "poppins" }}>
          Delete Job Post
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ fontFamily: "poppins" }}>
            Are you sure you want to delete this job post?{" "}
            <span style={{ fontWeight: "bold", fontSize: "18px" }}>
              This can't be undone!!
            </span>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDeleteDialog}
            sx={{
              bgcolor: "#000000",
              color: "white",
              fontFamily: "Poppins",
              borderRadius: "0.7rem",
              textTransform: "none",
              ":hover": { bgcolor: "#000000", color: "white" },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteJob}
            sx={{
              bgcolor: "#000000",
              color: "white",
              borderRadius: "0.7rem",
              fontFamily: "Poppins",
              textTransform: "none",
              ":hover": { bgcolor: "#000000", color: "white" },
            }}
          >
            {loading ? (
              <ThreeDot color="#FFFFFFFF" size="small" text="" textColor="" />
            ) : (
              "Delete Job"
            )}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Status Confirmation Dialog */}
      <Dialog open={openStatusDialog} onClose={handleCloseStatusDialog}>
        <DialogTitle sx={{ fontFamily: "poppins" }}>
          Change Job Status
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ fontFamily: "poppins" }}>
            Are you sure you want to change the status of this job to{" "}
            <span style={{ fontWeight: "bold", fontSize: "18px" }}>
              {selectedStatus.charAt(0).toUpperCase() + selectedStatus.slice(1)}
            </span>
            ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseStatusDialog}
            sx={{
              bgcolor: "#000000",
              color: "white",
              fontFamily: "Poppins",
              borderRadius: "0.7rem",
              textTransform: "none",
              ":hover": { bgcolor: "#000000", color: "white" },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleChangeJobStatus}
            sx={{
              bgcolor: "#000000",
              color: "white",
              borderRadius: "0.7rem",
              fontFamily: "Poppins",
              textTransform: "none",
              ":hover": { bgcolor: "#000000", color: "white" },
            }}
          >
            {statusLoading ? (
              <ThreeDot color="#FFFFFFFF" size="small" text="" textColor="" />
            ) : (
              "Confirm"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default JobCard;
