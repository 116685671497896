import {
  Card,
  Typography,
  Box,
  Chip,
  Avatar,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { getRecommendedJobsApi } from "../../../api/common";

const styles = {
  card: {
    width: "500px",
    border: "1px solid",
    borderColor: "grey.300",
    borderRadius: "15px",
    overflow: "visible",
    flex: "0 0 auto",
    position: "relative",
    cursor: "pointer",
  },
  header: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    padding: "16px 16px 0 16px",
    gap: "10px",
  },
  headerText: {
    fontSize: "25px",
    fontFamily: "satoshi",
    fontWeight: "bold",
  },
  logo: {
    width: 60,
    height: 60,
    backgroundColor: "#423bfa",
    borderRadius: "4px",
  },
  headerActions: {
    display: "flex",
    gap: "8px",
  },
  title: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    marginTop: "16px",
    paddingLeft: "16px",
    fontFamily: "satoshi",
    paddingRight: "16px",
  },
  titleText: {
    fontWeight: "bold",
    fontSize: "1.25rem",
    color: "#333",
    fontFamily: "satoshi",
  },
  location: {
    display: "flex",
    paddingLeft: "16px",
    paddingRight: "16px",
    color: "#666",
    fontFamily: "satoshi",
    fontSize: "0.875rem",
  },
  stats: {
    color: "#00693e",
    fontWeight: 500,
    fontSize: "0.875rem",
    paddingLeft: "16px",
    paddingRight: "16px",
    fontFamily: "satoshi",
    marginTop: "4px",
  },
  tagContainer: {
    display: "flex",
    gap: "8px",
    padding: "16px 16px 8px 16px",
    fontFamily: "satoshi",
  },
  tag: {
    borderRadius: "16px",
    backgroundColor: "#f5f5f5",
    color: "#333",
    fontFamily: "satoshi",
    fontSize: "0.75rem",
  },
  buttonContainer: {
    display: "flex",
    gap: "12px",
    padding: "8px 16px 16px 16px",
  },
  applyButton: {
    backgroundColor: "#423bfa",
    color: "white",
    fontFamily: "satoshi",
    textTransform: "none",
    borderRadius: "0.7rem",
    padding: "6px 16px",
    "&:hover": {
      backgroundColor: "#423bfa",
    },
  },
  navButton: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    "&:hover": { backgroundColor: "rgba(255, 255, 255, 1)" },
    zIndex: 10,
  },
  recommendedText: {
    position: "absolute",
    top: "10px",
    right: "10px",
    color: "#0AF625FF",
    fontSize: "14px",
    fontFamily: "Poppins, sans-serif",
    fontWeight: 500,
    zIndex: 2,
  },
};

const StyledChip = styled(Chip)(styles.tag);

const transformApiResponseToJobData = (apiData) => {
  return apiData.map((job) => {
    const isConsultingJob = job.jobPost?.forConsultantCompany;
    const companyName = isConsultingJob 
      ? job.jobPost?.consultantOrgName 
      : job.organizationName || "Unknown Company";
      
    const companyLogo = isConsultingJob
      ? job.jobPost?.consultantCompanyLogo?.url
      : job.companyLogo?.url;

    return {
      id: job.jobPost?._id || job._id,
      title: job.jobPost?.jobTitle || "Job Title Not Available",
      company: companyName,
      companyLogo: companyLogo,
      location: job.jobPost?.jobLocations?.length > 0
        ? `${job.jobPost.jobLocations[0].area}, ${job.jobPost.jobLocations[0].location}`
        : "Location not specified",
      stats: `${job.jobPost?.applicationCount || 0} people clicked apply`,
      tags: [
        job.jobPost?.jobType === "fullTime" ? "Full-time" : job.jobPost?.jobType,
        job.jobPost?.walkIn ? "Walk-in" : job.jobPost?.jobLocations?.length > 0 ? "On-site" : "Remote",
      ],
      posted: `Posted on ${new Date(job.jobPost?.postDate || job.createdAt).toLocaleDateString()}`,
      matchScore: job.matchScore || 0,
    };
  });
};

const JobCard = ({ job, onClick }) => {
  return (
    <Card sx={styles.card} elevation={0} onClick={onClick}>
      {job.matchScore > 0 && (
        <Typography sx={styles.recommendedText}>
          {`${Math.round(job.matchScore * 100)}% Match`}
        </Typography>
      )}
      <Box sx={styles.header}>
        <Avatar 
          sx={styles.logo} 
          variant="square" 
          src={job.companyLogo}
          alt={job.company}
        />
        <Box sx={styles.headerActions}>
          <Typography sx={styles.headerText}>{job.company}</Typography>
        </Box>
      </Box>
      <Box sx={styles.title}>
        <Typography sx={styles.titleText}>{job.title}</Typography>
      </Box>
      <Box sx={styles.location}>
        <Typography variant="body2">
          {job.location} • {job.posted}
        </Typography>
      </Box>
      <Box sx={styles.tagContainer}>
        {job.tags.map((tag) => (
          <StyledChip key={tag} label={tag} size="small" />
        ))}
      </Box>
      <Box sx={styles.buttonContainer}>
        <Typography sx={{ color: '#423bfa', fontWeight: 'bold' }}>
          Apply
        </Typography>
      </Box>
    </Card>
  );
};

const JobRecommendations = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [jobs, setJobs] = useState([]);
  const navigate = useNavigate();
  const visibleCards = 3;
  const cardWidth = 500 + 16; // Card width + gap
  const totalItems = jobs.length;
  const duplicatedJobs = [...jobs, ...jobs];
  const carouselRef = useRef(null);

  useEffect(() => {
    const fetchRecommendedJobs = async () => {
      try {
        const token = localStorage.getItem("authToken");
        const response = await getRecommendedJobsApi(token);
         console.log("rec response",response)
        if (response && response.recommendations) {
          const transformedJobs = transformApiResponseToJobData(response.recommendations);
          setJobs(transformedJobs);

        } else {
          console.log("No recommended jobs found");
          setJobs([]);
        }
      } catch (error) {
        console.log("Error fetching recommended jobs:", error.message);
        setJobs([]);
      }
    };

    fetchRecommendedJobs();
  }, []);

  console.log("rec jobs",jobs)

  useEffect(() => {
    if (totalItems <= visibleCards || !carouselRef.current) return;

    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        const nextIndex = prevIndex + 1;
        if (nextIndex >= totalItems && carouselRef.current) {
          // Reset transition and index after animation completes
          carouselRef.current.style.transition = "transform 0.5s ease-in-out";
          setTimeout(() => {
            if (carouselRef.current) {
              carouselRef.current.style.transition = "none";
              setCurrentIndex(0);
            }
          }, 500); // Match transition duration
        }
        return nextIndex % totalItems;
      });
    }, 3000);

    return () => clearInterval(interval);
  }, [totalItems, visibleCards]);

  const handlePrev = () => {
    if (!carouselRef.current) return;

    setCurrentIndex((prev) => {
      const newIndex = prev - 1 < 0 ? totalItems - 1 : prev - 1;
      return newIndex;
    });
  };

  const handleNext = () => {
    if (!carouselRef.current) return;

    setCurrentIndex((prev) => {
      const nextIndex = (prev + 1) % totalItems;
      return nextIndex;
    });
  };

  const handleCardClick = (jobId) => {
    navigate(`/JobSeekerFilter/job/${jobId}`);
  };

  if (!jobs || jobs.length === 0) {
    return null;
  }

  return (
    <Box
      sx={{
        width: "100vw",
        backgroundColor: "#f5f5f5",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: 0,
        padding: "3rem 0",
      }}
    >
      <Typography
        variant="h4"
        fontWeight="bold"
        textAlign="center"
        color="#000000"
        mt={3}
        mb={4}
        fontFamily="IBM Plex Sans"
      >
        Recommended Jobs For You
      </Typography>
      <Box
        sx={{
          position: "relative",
          overflow: "hidden",
          width: `${visibleCards * cardWidth}px`,
          maxWidth: "100%",
        }}
      >
        <IconButton
          onClick={handlePrev}
          sx={{
            ...styles.navButton,
            left: "10px",
          }}
          aria-label="Previous"
        >
          <ArrowBackIosIcon />
        </IconButton>
        <Box
          ref={carouselRef}
          sx={{
            display: "flex",
            flexWrap: "nowrap",
            gap: "16px",
            transition: "transform 0.5s ease-in-out",
            transform: `translateX(-${currentIndex * cardWidth}px)`,
          }}
        >
          {duplicatedJobs.map((job, index) => (
            <JobCard
              key={`${job.id}-${index}`}
              job={job}
              onClick={() => handleCardClick(job.id)}
            />
          ))}
        </Box>
        <IconButton
          onClick={handleNext}
          sx={{
            ...styles.navButton,
            right: "10px",
          }}
          aria-label="Next"
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default JobRecommendations;