import React, { useState, useEffect } from "react";
import { Card, CardMedia, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Box } from "@mui/system";

const images = [
  "/slider1.png",
  "/slider3.png",
  "/slider4.png",
  "/slider5.png",
  "/slider6.png",
  "/slider12.png",
  "/slider8.png",
  "/slider9.png",
  "/slider10.png",
  "/slider11.png",
  "/slider13.png",
  "/slider2.png",
  "/slider14.png",
  "/slider15.png",
  "/slider16.png",
];

export default function JobSeekerImageCarousel() {
  const [centerIndex, setCenterIndex] = useState(4);
  const [isHovered, setIsHovered] = useState(false);
  
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm")); // Mobile
  const isSm = useMediaQuery(theme.breakpoints.between("sm", "md")); // Tablet
  // const isMd = useMediaQuery(theme.breakpoints.up("md")); // Desktop

  const handlePrev = () => {
    setCenterIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : images.length - 1
    );
  };

  const handleNext = () => {
    setCenterIndex((prevIndex) =>
      prevIndex < images.length - 1 ? prevIndex + 1 : 0
    );
  };

  // Auto-scroll logic
  useEffect(() => {
    if (!isHovered) {
      const interval = setInterval(() => {
        handleNext();
      }, 500); // Change slide every 3 seconds

      return () => clearInterval(interval);
    }
  }, [centerIndex, isHovered]); // Re-run when index or hover state changes

  return (
    <Box
      sx={{
        width: "100vw",
        background: "#000000",
        backdropFilter: "blur(10px)",
        padding: "50px",
        mt: 3,
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Header Text */}
      <Typography
        sx={{
          textAlign: "center",
          fontWeight: "bold",
          fontFamily: "IBM Plex Sans",
          color: "#ffffff",
          fontSize: isXs ? "24px" : isSm ? "32px" : "40px", // Adjust font size dynamically
          lineHeight: isXs ? "32px" : isSm ? "40px" : "48px", // Adjust line height
          px: isXs ? 2 : 0, // Add padding on mobile for better spacing
        }}
        variant="h3"
      >
        Job seekers get hired faster, recruiters hire smarter!
      </Typography>

      <div
        style={{
          height: "50vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          perspective: "2000px",
          overflow: "hidden",
          marginTop: "30px",
        }}
      >
        {/* Left Button */}
        <IconButton
          style={{
            position: "absolute",
            left: "20px",
            zIndex: 20,
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            borderRadius: "50%",
            padding: "12px",
          }}
          onClick={handlePrev}
          aria-label="Previous slide"
        >
          <ChevronLeft />
        </IconButton>

        {/* Image Carousel */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          {images.map((image, index) => {
            const offset =
              ((index - centerIndex + images.length) % images.length) -
              Math.floor(images.length / 2);
            return (
              <Card
                key={index}
                style={{
                  position: "absolute",
                  width: "200px",
                  height: "300px",
                  transition: "all 0.5s ease",
                  transform: `
                  translateX(${offset * 102}px)
                  scale(${1 - Math.abs(offset) * 0.1})
                  translateZ(${-Math.abs(offset) * 60}px)
                `,
                  zIndex: 9 - Math.abs(offset),
                  opacity: Math.max(1 - Math.abs(offset) * 0.2, 1),
                  boxShadow:
                    offset === 0
                      ? "0 20px 40px rgba(0,0,0,0.5)"
                      : "0 10px 20px rgba(0,0,0,0.3)",
                }}
              >
                <CardMedia
                  component="img"
                  height="100%"
                  width="100vw"
                  image={image}
                  alt={`Slide ${index + 1}`}
                  style={{
                    objectFit: "cover",
                    borderRadius: "4px",
                  }}
                />
              </Card>
            );
          })}
        </div>

        {/* Right Button */}
        <IconButton
          style={{
            position: "absolute",
            right: "20px",
            zIndex: 20,
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            borderRadius: "50%",
            padding: "12px",
          }}
          onClick={handleNext}
          aria-label="Next slide"
        >
          <ChevronRight />
        </IconButton>
      </div>
    </Box>
  );
}
