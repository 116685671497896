import {
  Box,
  Card,
  Typography,
  Divider,
  Button,
  styled,
  Fade,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { useNavigate, useParams } from "react-router-dom";
import {
  getAllPromocodeApi,
  getSubscriptionById,
  upgradePlanApi,
} from "../../../api/subscriptions";
import { useCallback, useEffect, useState } from "react";
import { FourSquare, ThreeDot } from "react-loading-indicators";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

import ReleaseJobPostModel from "./ReleaseJobPostModel";

const StyledCard = styled(Card)({
  padding: 24,
  borderRadius: 12,
  border: "1px solid",
  borderColor: "#D4E7EEFF",
});

const FeatureItem = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  mb: 3,
});

export default function EmployerPlanPaymentPage() {
  const [loading, setLoading] = useState(false);

  const [paymentLoading, setPaymentLoading] = useState(false);
  const [planData, setPlanData] = useState([]);
  const [promocodes, setPromocodes] = useState([]);
  const [isApplied, setIsApplied] = useState(false); // Track if discount is applied
  const [discountedAmount, setDiscountedAmount] = useState(0); // Store final amount after discount
  const [selectedPromoCode, setSelectedPromoCode] = useState(null); // Track selected promocode
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [code, setCode] = useState();

  const { subscriptionId } = useParams();

  const orgId = localStorage.getItem("organizationId");
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  // Fetch plan details and promocodes sequentially
  useEffect(() => {
    const getPlanDetails = async () => {
      setLoading(true);
      try {
        const response = await getSubscriptionById(subscriptionId);
        setPlanData(response?.data);
        console.log("subtype", response.data);
        const subtype = response?.data?.subType;

        const promocodeResponse = await getAllPromocodeApi(subtype);
        setPromocodes(promocodeResponse.promoCodes || []);
      } catch (error) {
        console.log(error.message);
      } finally {
        setLoading(false);
      }
    };
    getPlanDetails();
  }, [subscriptionId]);

  // GST and Total Amount Calculations
  const GSTRate = 18;
  const basePrice = planData?.subscriptionFee || 0;
  const GSTAmount = (GSTRate / 100) * basePrice;
  const totalAmount = basePrice + GSTAmount;

  // Handle discount application
  const handleApplyDiscount = (item) => {
    const discount = (totalAmount * item.discountValue) / 100;
    const finalAmount = totalAmount - discount;
    setDiscountedAmount(finalAmount);
    setSelectedPromoCode(item);
    setIsApplied(true);
    setCode(item.code);
  };

  const handleError = useCallback((message) => {
    setErrorMessage(message);
    setErrorOpen(true);
    setTimeout(() => {
      setErrorOpen(false);
      setErrorMessage("");
    }, 2000);
  }, []);
  console.log("itemcode", code);

  const handleConfirmPayment = async () => {
    setPaymentLoading(true);
    try {
      const formValues = {
        subType: planData?.subType,
        newTier: planData?.tier,
        organizationId: orgId,
        subscriptionId: subscriptionId,
        promoCode: code,
      };
      await upgradePlanApi(token, formValues);

      navigate("/employer/success_subscription");
    } catch (error) {
      handleError(
        error?.message || "Subscription Upgrade Failed, Please Try Again"
      );
    } finally {
      setPaymentLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            zIndex: 9999,
            backgroundColor: "#FFFFFF",
          }}
        >
          <FourSquare color="#423bfa" size="small" text="" textColor="" />
          <Typography sx={{ fontFamily: "poppins", mt: 1 }}>
            Calculating Payment Details....
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            gap: 3,
            p: 3,
            maxWidth: 1200,
            margin: "0 auto",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          {/* Left Section */}
          <Box sx={{ flex: 1 }}>
            <StyledCard elevation={0}>
              <Typography variant="h4" sx={{ mb: 2, fontFamily: "poppins" }}>
                Plan Info
              </Typography>
              <Divider sx={{ my: 2 }} />
              <FeatureItem
                sx={{
                  mt: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  pb: 1,
                  borderBottom: "1px solid #ddd",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <FiberManualRecordIcon sx={{ fontSize: "14px" }} />
                  <Typography variant="h6" sx={{ fontFamily: "poppins" }}>
                    {planData?.subType === "jobposting"
                      ? `${planData?.jobPosts} Free Job Postings per month`
                      : `${planData?.userAccess} Free Candidate Searches per month`}
                  </Typography>
                </Box>
                <CheckCircleOutlineOutlinedIcon sx={{ fontSize: 30 }} />
              </FeatureItem>
              {planData?.benefits?.map((item) => (
                <FeatureItem
                  key={item}
                  sx={{
                    mt: 2,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    pb: 1,
                    borderBottom: "1px solid #ddd",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <FiberManualRecordIcon sx={{ fontSize: "14px" }} />
                    <Typography variant="h6" sx={{ fontFamily: "poppins" }}>
                      {item}
                    </Typography>
                  </Box>
                  <CheckCircleOutlineOutlinedIcon sx={{ fontSize: 30 }} />
                </FeatureItem>
              ))}
            </StyledCard>
          </Box>

          {/* Right Section */}
          <Box sx={{ width: { xs: "100%", md: 360 } }}>
            <StyledCard elevation={0}>
              <Typography variant="h6" sx={{ mb: 2, fontFamily: "poppins" }}>
                Price Details
              </Typography>
              <Box sx={{ mb: 3 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mb: 1,
                  }}
                >
                  <Typography variant="body2" sx={{ fontFamily: "poppins" }}>
                    Base Price
                  </Typography>
                  <Typography variant="body2" sx={{ fontFamily: "satoshi" }}>
                    ₹{basePrice}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mb: 1,
                  }}
                >
                  <Typography variant="body2" sx={{ fontFamily: "poppins" }}>
                    GST @ 18%
                  </Typography>
                  <Typography variant="body2" sx={{ fontFamily: "satoshi" }}>
                    ₹{GSTAmount.toFixed(2)}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mb: 1,
                  }}
                >
                  <Typography variant="body2" sx={{ fontFamily: "poppins" }}>
                    Total Amount
                  </Typography>
                  <Typography variant="body2" sx={{ fontFamily: "satoshi" }}>
                    ₹{totalAmount.toFixed(2)}
                  </Typography>
                </Box>

                {/* Promocode Section */}
                {promocodes?.map((item) => (
                  <Card
                    key={item.code}
                    sx={{
                      bgcolor: "#f0f7ff",
                      borderRadius: 2,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mb: 1.5,
                      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.05)",
                      transition: "transform 0.2s, box-shadow 0.2s",
                      "&:hover": {
                        transform: "translateY(-2px)",
                        boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.1)",
                      },
                    }}
                  >
                    <CheckCircleIcon color="#423bfa" sx={{ fontSize: 24 }} />
                    <Box sx={{ flexGrow: 1, ml: 1 }}>
                      <Typography
                        variant="body1"
                        sx={{
                          fontFamily: "poppins",
                          fontWeight: 600,
                          color: "#333",
                        }}
                      >
                        {item.code}
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{ fontFamily: "poppins", color: "#666" }}
                      >
                        {item.discountValue}% off
                      </Typography>
                    </Box>
                    <Fade
                      in={!isApplied || selectedPromoCode?.code !== item.code}
                    >
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => handleApplyDiscount(item)}
                        sx={{
                          textTransform: "none",
                          fontFamily: "satoshi",
                          fontWeight: 600,
                          backgroundColor: "#423bfa",
                          color: "#fff",
                          borderRadius: 1,
                          py: 0.5,
                          "&:hover": { backgroundColor: "#1565c0" },
                        }}
                      >
                        Apply
                      </Button>
                    </Fade>
                    <Fade
                      in={isApplied && selectedPromoCode?.code === item.code}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontFamily: "satoshi",
                          fontWeight: 600,
                          color: "#2e7d32",
                        }}
                      >
                        Applied
                      </Typography>
                    </Fade>
                  </Card>
                ))}

                {/* Discount and Final Amount */}
                {isApplied && (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mb: 1,
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{ fontFamily: "poppins" }}
                      >
                        Discount ({selectedPromoCode?.discountValue}%)
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ fontFamily: "satoshi" }}
                      >
                        -₹
                        {(
                          totalAmount *
                          (selectedPromoCode?.discountValue / 100)
                        ).toFixed(2)}
                      </Typography>
                    </Box>
                    <Divider sx={{ my: 2 }} />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mb: 1,
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{ fontFamily: "poppins", fontWeight: 500 }}
                      >
                        Final Amount
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        sx={{ fontFamily: "satoshi", fontWeight: 500 }}
                      >
                        ₹{discountedAmount.toFixed(2)}
                      </Typography>
                    </Box>
                  </>
                )}

                <Button
                  onClick={handleConfirmPayment}
                  fullWidth
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    borderRadius: "0.7rem",
                    py: 1.5,
                    fontFamily: "poppins",
                    bgcolor: "#000",
                    "&:hover": { bgcolor: "#000" },
                  }}
                >
                  {paymentLoading ? (
                    <ThreeDot
                      color="#FFFFFFFF"
                      size="small"
                      text=""
                      textColor=""
                    />
                  ) : (
                    "Subscribe For Free"
                  )}
                </Button>
              </Box>
            </StyledCard>
          </Box>
        </Box>
      )}
      <ReleaseJobPostModel
        modelOpen={errorOpen}
        onClose={() => setErrorOpen(false)}
        message={errorMessage}
      />
    </>
  );
}
