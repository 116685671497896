import React from "react";
import { Box, Skeleton, Divider,  } from "@mui/material";


const DrawerSkeleton = ({ open }) => {
  return (
    <Box
      sx={{
        width: 240,
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        padding: 2,
      }}
    >
      {/* Drawer Header */}
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <Skeleton variant="circle" width={40} height={40} />
        <Skeleton variant="rectangular" width={50} height={25} />
      </Box>

      {/* Drawer Title */}
      <Skeleton variant="text" sx={{ width: "80%", height: 30, marginBottom: 2 }} />

      <Divider />

      {/* Menu Items */}
      <Box sx={{ flexGrow: 1 }}>
        {["Search Candidates", "Post a Job", "Saved Searches", "Viewed Candidates", "All Jobs", "Reports"].map((item, index) => (
          <Box key={index} sx={{ mb: 2 }}>
            <Skeleton variant="text" sx={{ width: "60%", height: 25, marginBottom: 1 }} />
            <Skeleton variant="rectangular" sx={{ width: "100%", height: 48 }} />
          </Box>
        ))}
      </Box>

      {/* About Us Section */}
      <Box sx={{ textAlign: "center", marginTop: "auto", paddingBottom: 3 }}>
        <Skeleton variant="circle" width={60} height={60} sx={{ margin: "auto", marginBottom: 2 }} />
        <Skeleton variant="text" sx={{ width: "50%", height: 20, marginBottom: 1 }} />
        <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
          <Skeleton variant="text" sx={{ width: 60, height: 15 }} />
          <Skeleton variant="text" sx={{ width: 60, height: 15 }} />
          <Skeleton variant="text" sx={{ width: 60, height: 15 }} />
        </Box>
      </Box>
    </Box>
  );
};

export default DrawerSkeleton;
