import React, { useState, useEffect, useCallback } from "react";
import {
  fetchJobSeekerSupport,
  fetchJobSeekerSupportById,
  jobSeekerSendMessage,
  jobSeekerUpdateChatStatus,
} from "../../../api/suport";
import JobSeekerChat from "./JobSeekerChat";
import { Typography, Box } from "@mui/material";
import { jobSeekerCreateTicket } from "../../../api/suport";
import NewNavBar from "../../../components/JobSeekerComponents/NavBar/NewNavbar";
import JobSeekerContactUs from "./JobSeekerContactUs";
import JobSeekerSupportForm from "./JobSeekerSupportForm";
import { getUserApi } from "../../../api/jobseeker";
import Footer from "../../../components/JobSeekerComponents/Footer/Footer";
import JobSeekerSupportModal from "./JobSeekerSupportModal";
import PopupSuccess from "../../../utils/popup/PopupSuccess";
import ReleaseJobPostModel from "../../../pages/EmployerPages/SubscriptionStatus/ReleaseJobPostModel";

const JobSeekerSupport = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [supportUser, setSupportUser] = useState();
  const [chatMessages, setChatMessages] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [messageData, setMessageData] = useState("");
  const [statusMessage, setStatusMessage] = useState("Open");

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedSupportType, setSelectedSupportType] = useState("");
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSuccess = useCallback((message) => {
    setSuccessMessage(message);
    setSuccessOpen(true);
    setTimeout(() => {
      setSuccessOpen(false);
      setSuccessMessage("");
    }, 2000);
  }, []);

  const handleError = useCallback((message) => {
    setErrorMessage(message);
    setErrorOpen(true);
    setTimeout(() => {
      setErrorOpen(false);
      setErrorMessage("");
    }, 2000);
  }, []);

  const handleModalOpen = (supportType) => {
    console.log("Opening modal with supportType:", supportType);
    setSelectedSupportType(supportType);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    console.log("Closing modal");
    setModalOpen(false);
  };

  const token = localStorage.getItem("authToken");
  const userId = localStorage.getItem("userId");
  const [userData, setUserData] = useState();

  useEffect(() => {
    if (token) {
      const getUserDetails = async () => {
        try {
          const response = await getUserApi(userId, token);
          setUserData(response?.user);
        } catch (error) {
          console.log(error.message);
        }
      };
      getUserDetails();
    }
  }, [token, userId]);

  const handleSave = async (payload) => {
    console.log('payload', payload);
    
    try {
        const { topic, messageContent, supportType, name, email, phone } = payload;
        const response = await jobSeekerCreateTicket({ topic, messageContent, supportType, name, email, phone });
        console.log('response in crea', response);
        
        // Use the message from the response if available, otherwise a default success message
        handleSuccess(response?.message || "Support ticket created successfully");

    } catch (error) {
        handleError(error.message || "Failed to create the support ticket");
    } finally {
        const updatedSupportData = await fetchJobSeekerSupport();
        setData(updatedSupportData);
        setSelectedChat(updatedSupportData);
        handleModalClose();
    }
};

  const handleChatClick = async (chat) => {
    setSelectedChat(chat._id);
    try {
      const messages = await fetchJobSeekerSupportById(chat._id);
      setChatMessages(messages?.messages);
      setSupportUser(messages?.supportTicket?.user);
      setStatusMessage(messages?.supportTicket?.status);
    } catch (error) {
      handleError(error.message);
    }
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const supportData = await fetchJobSeekerSupport();
        setData(supportData);
        if (supportData.length > 0) {
          setSelectedChat(supportData);
        }
      } catch (error) {
        // handleErrorMessage(error);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, []);


  const handleInputChange = (event) => {
    setMessageData(event.target.value);
  };

  const handleSend = async () => {
    if (!messageData.trim()) return;
    const payload = { messageContent: messageData };
    try {
      const response = await jobSeekerSendMessage(payload, selectedChat);
      setChatMessages([...chatMessages, response]);
      setMessageData("");
      handleSuccess("Message sent successfully");
    } catch (error) {
      handleError(error);
    }
  };

  const handleStatusChange = async (status) => {
    const payload = { status };
    try {
      const response = await jobSeekerUpdateChatStatus(payload, selectedChat);
      setStatusMessage(response?.status);
      handleSuccess(`Status updated to ${status}`);
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <>
      <NewNavBar />

      {/* Always render JobSeekerContactUs to ensure the modal is available */}
      <JobSeekerContactUs
        userData={userData}
        open={modalOpen}
        handleClose={handleModalClose}
        handleSave={handleSave}
        handleModalOpen={handleModalOpen}
        selectedSupportType={selectedSupportType}
      />

      {loading ? (
        <Typography>Loading...</Typography>
      ) : data?.message ? (
        <Box
          sx={{
            background:
              "linear-gradient(258.98deg, #1546f1 0%, #6915f1 100.44%)",
            pb: 10,
            pt: 10,
          }}
        >
          <JobSeekerSupportForm userData={userData} handleSave={handleSave} />
        </Box>
      ) : (
        <Box
          sx={{
            background:
              "linear-gradient(258.98deg, #1546f1 0%, #6915f1 100.44%)",
            pb: 10,
            pt: 10,
          }}
        >
          <JobSeekerSupportForm userData={userData} handleSave={handleSave} />
          {data?.length > 0 && (
            <JobSeekerChat
              users={data}
              selectedChat={selectedChat}
              handleChatClick={handleChatClick}
              chatMessages={chatMessages}
              handleSend={handleSend}
              handleInputChange={handleInputChange}
              messageData={messageData}
              supportUser={supportUser}
              handleStatusChange={handleStatusChange}
              statusMessage={statusMessage}
              handleModalOpen={handleModalOpen}
            />
          )}
        </Box>
      )}
      <PopupSuccess
        modelOpen={successOpen}
        onClose={() => setSuccessOpen(false)}
        message={successMessage}
      />
      <ReleaseJobPostModel
        modelOpen={errorOpen}
        onClose={() => setErrorOpen(false)}
        message={errorMessage}
      />
      <Footer />
      <JobSeekerSupportModal
        handleClose={handleModalClose}
        handleSave={handleSave}
      />
    </>
  );
};

export default JobSeekerSupport;
