import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, Typography, Box, styled } from "@mui/material";
import SuccessGif from '../../assets/images/success.gif';

const StyledDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    borderRadius: "16px",
    padding: "16px",
    maxWidth: "400px",
    width: "90%",
  },
});


const IconWrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
  marginBottom: "20px",
  "& svg": {
    width: "64px",
    height: "64px",
    color: "#423bfa",
  },
});

const PopupSuccess = ({ modelOpen, onClose, message }) => {
  const [localMessage, setLocalMessage] = useState(""); // Persist message locally

  useEffect(() => {
    if (message) {
      console.log("message in ReleaseJobPostModel (useEffect):", message);
      setLocalMessage(message); // Update only when message is non-empty
    }
  }, [message]);

  // Log message during render
  console.log("message in ReleaseJobPostModel (render):", message);

  return (
    <StyledDialog
      open={modelOpen}
      onClose={() => {
        onClose();
        setLocalMessage(""); // Reset localMessage when closing
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <IconWrapper>
          <img src={SuccessGif} height="100px" weight="100px" alt="giffy"/>
        </IconWrapper>
        <Typography
          variant="body1"
          align="center"
          gutterBottom
          sx={{
            fontWeight: 600,
            color: "#333",
            mb: 2,
          }}
        >
          {localMessage || "An error occurred"}
        </Typography>
      </DialogContent>
    </StyledDialog>
  );
};

export default PopupSuccess;