import { Button, Grid, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import signupVideo from "../../../assets/videos/videoplayback2.mp4";
import { toast, ToastContainer } from "react-toastify";
import { resetPasswordApi } from "../../../api/employerAuth";
import { useNavigate } from "react-router-dom";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";

const EmployerResetPassword = () => {
  const [resetData, setResetData] = useState({
    email: "",
    otp: "",
    password: "",
    confirmPassword: "",
  });
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const handleResetData = (key, value) => {
    setResetData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const formValues = {
    rcEmail: resetData?.email,
    otp: resetData?.otp,
    newPassword: resetData?.password,
  };

  const handleSubmitResetPassword = async () => {
    try {
      if (
        !resetData?.email ||
        !resetData?.otp ||
        !resetData?.password ||
        !resetData?.confirmPassword
      ) {
        toast.dark(
          <div style={{ display: "flex", alignItems: "center" }}>
            <ErrorOutlineIcon sx={{ marginRight: 1, color: "red" }} />
            <p style={{ fontWeight: "bold" }}>Please fill all the fields</p>
          </div>
        ); // Show error message
      } else if (resetData?.password !== resetData?.confirmPassword) {
        toast.dark(
          <div style={{ display: "flex", alignItems: "center" }}>
            <ErrorOutlineIcon sx={{ marginRight: 1, color: "red" }} />
            <p style={{ fontWeight: "bold" }}>Passwords does not match</p>
          </div>
        ); // Show error message
      } else {
        const response = await resetPasswordApi(token, formValues);

        toast.dark(
          <div style={{ display: "flex", alignItems: "center" }}>
            <CheckCircleOutline sx={{ marginRight: 4, color: "darkgreen" }} />
            <p style={{ fontWeight: "bold" }}>{response?.message}</p>
          </div>
        );

        navigate("/employerSignIn");
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <Grid container sx={{ height: "100vh" }}>
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <video
            autoPlay
            muted
            loop
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          >
            <source src={signupVideo} type="video/mp4" />
          </video>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "100%",
            justifyContent: "center",
            p: 4,
          }}
        >
          <Box
            sx={{
              maxWidth: 400,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <img
              src="/vacanziLogo.png"
              style={{ width: 50, height: 50, marginBottom: 10 }}
              alt="#vacanziLogo"
            />
            <Typography variant="h6" gutterBottom>
              Reset Your Password Here
            </Typography>
            <TextField
              fullWidth
              placeholder="Email"
              margin="normal"
              sx={{ backgroundColor: "white" }}
              value={resetData.email}
              onChange={(e) => handleResetData("email", e.target.value)}
            />
            <TextField
              fullWidth
              placeholder="Otp"
              margin="normal"
              type="number"
              sx={{
                backgroundColor: "white",
                "& input[type=number]::-webkit-inner-spin-button": {
                  display: "none",
                },
                "& input[type=number]::-webkit-outer-spin-button": {
                  display: "none",
                },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },
              }}
              value={resetData.otp}
              onChange={(e) => handleResetData("otp", e.target.value)}
            />
            <TextField
              fullWidth
              placeholder="Password"
              margin="normal"
              type="password"
              sx={{ backgroundColor: "white" }}
              value={resetData.password}
              onChange={(e) => handleResetData("password", e.target.value)}
            />
            <TextField
              fullWidth
              placeholder="Confirm Password"
              margin="normal"
              type="password"
              sx={{ backgroundColor: "white" }}
              value={resetData.confirmPassword}
              onChange={(e) =>
                handleResetData("confirmPassword", e.target.value)
              }
            />

            <Button
              fullWidth
              variant="contained"
              sx={{
                backgroundColor: "#423bfa",
                color: "white",
                fontWeight: "bold",
                padding: "0.5rem",
              }}
              onClick={handleSubmitResetPassword}
            >
              Reset Password
            </Button>
            {/* <Typography align="center" variant="body2" sx={{ mt: 2 }}>
              or continue with
            </Typography>
            <Button fullWidth color="primary" sx={{ mt: 1 }}>
              <img src={GoogleIconImg} alt="google-logo" />
            </Button> */}
            {/* <Typography align="center" variant="body2" sx={{ mt: 2 }}>
              Already have an account?{" "}
              <Button
                onClick={() => setTabValue(0)}
                sx={{
                  textTransform: "none",
                  ":hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                Login here
              </Button>
            </Typography> */}
          </Box>
        </Box>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          closeOnClick
          pauseOnHover
          draggable
          theme="colored"
        />
      </Grid>
    </Grid>
  );
};

export default EmployerResetPassword;
