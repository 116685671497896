import { Grid, Paper, Skeleton } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

const JobPostSkeleton = () => {
  return (
    <Paper
      elevation={0}
      style={{
        padding: "20px",
        maxWidth: "90%",
        margin: "12px auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
<Box sx={{ width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              mb: 3,
            }}
          >
            <Skeleton variant="text" width="30%" height={40} />
            <Skeleton variant="rectangular" width="15%" height={40} />
          </Box>

          <Grid container spacing={2}>
            {/* Job Title */}
            <Grid item xs={12} sm={6}>
              <Skeleton variant="text" width="50%" height={20} />
              <Skeleton variant="rectangular" width="100%" height={40} />
            </Grid>

            {/* Map Component */}
            <Grid item xs={12} sm={6}>
              <Skeleton variant="text" width="50%" height={20} />
              <Skeleton variant="rectangular" width="100%" height={60} />
            </Grid>

            {/* Role Description */}
            <Grid item xs={12}>
              <Skeleton variant="text" width="30%" height={20} />
              <Skeleton variant="rectangular" width="100%" height={100} />
            </Grid>

            {/* Education */}
            <Grid item xs={12} sm={6}>
              <Skeleton variant="text" width="50%" height={20} />
              <Skeleton variant="rectangular" width="100%" height={20} />
              <Skeleton variant="rectangular" width="100%" height={40} sx={{ mt: 1 }} />
            </Grid>

            {/* Experience */}
            <Grid item xs={12} sm={6}>
              <Skeleton variant="text" width="50%" height={20} />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Skeleton variant="rectangular" width="100%" height={40} />
                </Grid>
                <Grid item xs={6}>
                  <Skeleton variant="rectangular" width="100%" height={40} />
                </Grid>
              </Grid>
            </Grid>

            {/* CTC Range */}
            <Grid item xs={12} sm={6}>
              <Skeleton variant="text" width="50%" height={20} />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Skeleton variant="rectangular" width="100%" height={40} />
                </Grid>
                <Grid item xs={6}>
                  <Skeleton variant="rectangular" width="100%" height={40} />
                </Grid>
              </Grid>
            </Grid>

            {/* Department */}
            <Grid item xs={12} sm={6}>
              <Skeleton variant="text" width="50%" height={20} />
              <Skeleton variant="rectangular" width="100%" height={40} />
            </Grid>
          </Grid>
        </Box>

    </Paper>

  )
}

export default JobPostSkeleton