import { Button} from '@mui/material'
import { Box } from '@mui/system'
import React, {  useState } from 'react'
import BookmarkAddedOutlinedIcon from "@mui/icons-material/BookmarkAddedOutlined";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";
import AccountCircleOutlined from "@mui/icons-material/AccountCircleOutlined";
import ReportOutlined from "@mui/icons-material/ReportOutlined"

import { saveProfilesApi, unSaveProfilesApi } from '../../../../api/JobSeekerDetailsApi';
import { reportJobseekerApi } from '../../../../api/suport';
import ReportJobDialogBox from '../../../JobSeekerComponents/FindJob/ReportJobDialogBox';
import reportJobseekerOptions from '../../../../data/reportDatas';

import { ThreeDot } from 'react-loading-indicators';

const CandidateProfileActions = ({profile,setProfiles,token, handleError
 , handleSuccess}) => {
      const [candidateId, setCandidateId] = useState();
        const [openReportDialog, setOpenReportDialog] = useState(false);
          const [loading, setLoading] = useState(false);
          const [saveLoading,setSaveLoading] = useState(false)
            const [selectedReason, setSelectedReason] = useState("");
             const [customReason, setCustomReason] = useState("");
    
    const handleViewFullProfile = (id) => {
        const profileUrl = `/viewJobSeekerProfile/${id}`;
        window.open(profileUrl, "_blank", "noopener,noreferrer");
      };




      const handleOpenReportDialog = (jobId) => {
        setCandidateId(jobId);
        setOpenReportDialog(true);
      };
      
      const saveCandidate = async (candidateId) => {
        const formValues = {
          candidateId: candidateId,
        };
        setSaveLoading(true)
        try {
          // Find the candidate in the profiles array to check its current saved status
     
          const isCurrentlySaved = profile?.savedCandidate
      
          // Call the appropriate API based on the current saved status
          const response = isCurrentlySaved
            ? await unSaveProfilesApi(token, formValues) // Unsave if already saved
            : await saveProfilesApi(token, formValues);  // Save if not saved
      
          // Update the profiles state
          setProfiles((prev) => 
            prev.map((p) =>
              p._id === candidateId
                ? { ...p, savedCandidate: !isCurrentlySaved } // Toggle the saved status
                : p
            )
          );
      
          // Show success toast with appropriate message
          handleSuccess(response.message || "Candidate Saved Succesfully")
        } catch (error) {
          // Show error toast
         handleError(error.message || "Failed To Save Candidate, Please Try Again")
        }
        finally{
          setSaveLoading(false)
        }
      };

  const reportJobseeker = async () => {
    const formData = {
      messageContent: `Reported Jobseeker with ID: ${candidateId} , Topic: ${selectedReason} , Reason: ${customReason}`,
    };
    setLoading(true);
    try {
      const response = await reportJobseekerApi(formData, token);
        handleSuccess(response.message )
        handleCloseReportDialog();
      
    } catch (error) {
    handleError(error.message)
    } finally {
      setLoading(false);
    }
  };


  const handleCloseReportDialog = () => {
    setOpenReportDialog(false);
    setSelectedReason("");
    setCustomReason("");
  };

  return (
    <>
     <Box
   display="flex"
   justifyContent="space-around"
   alignItems="center"
 
   sx={{
     bgcolor: "white",
     borderRadius: "8px",
    
   }}
 >
   {/* Profile Views */}
   <Button
     startIcon={
       <VisibilityOutlined sx={{ fontSize: 22, color: "#666" }} />
     }
     sx={{
       color: "#444",
       textTransform: "none",
       fontSize: 15,
       fontFamily:"poppins",
       fontWeight: 500,
       p: "8px 12px",
       borderRadius: "8px",
     }}
   >
     {profile?.profileViewCount} Views
   </Button>

   {/* View Profile */}
   <Button
     onClick={() => handleViewFullProfile(profile._id)}
     startIcon={
       <AccountCircleOutlined sx={{ fontSize: 22, color: "#666" }} />
     }
     sx={{
       color: "#444",
       textTransform: "none",
       fontFamily:"poppins",
       fontSize: 15,
       fontWeight: 500,
       p: "8px 12px",
       borderRadius: "8px",
     }}
   >
     View Profile
   </Button>
   {saveLoading ? (
    <ThreeDot color="#666" size="small" text="" textColor="" />
  ):(
    <Button
    onClick={() => saveCandidate(profile._id)}
    startIcon={
      profile.savedCandidate ? (
        <BookmarkAddedOutlinedIcon sx={{ fontSize: 22, color: "#666" }} />
      ) : (
        <BookmarkBorderOutlinedIcon sx={{ fontSize: 22, color: "#666" }} />
      )
    }
    sx={{
      color: "#444",
      textTransform: "none",
      fontSize: 15,
      fontWeight: 500,
      fontFamily: "poppins",
      p: "8px 12px",
      borderRadius: "8px",
    }}
  >
   { profile.savedCandidate ? 
      "Saved"
     : 
      "Save Profile"
    }
  </Button>
  )}
     <Button
     onClick={() => handleOpenReportDialog(profile._id)}
     startIcon={<ReportOutlined sx={{ fontSize: 22, color: "#666" }} />}
     sx={{
       color: "#444",
       textTransform: "none",
       fontSize: 15,
       fontWeight: 500,
       fontFamily:"poppins",
       p: "8px 12px",
       borderRadius: "8px",
     }}
   >
     Report
   </Button>
 </Box>
      
 <ReportJobDialogBox
        openReportDialog={openReportDialog}
        handleCloseReportDialog={handleCloseReportDialog}
        selectedReason={selectedReason}
        setSelectedReason={setSelectedReason}
        handleError={handleError}
        reportJobPost={reportJobseeker}
        customReason={customReason}
        setCustomReason={setCustomReason}
        loading={loading}
        reportOptions={reportJobseekerOptions}
      />
         {/* <ReleaseJobPostModel
        modelOpen={errorOpen}
        onClose={() => setErrorOpen(false)}
        message={errorMessage}
      />
          <PopupSuccess
        modelOpen={successOpen}
        onClose={() => setSuccessOpen(false)}
        message={successMessage}
      /> */}
    </>
   

  )
}

export default CandidateProfileActions