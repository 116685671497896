import * as React from "react";
import { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Autocomplete,
} from "@mui/material";
import { editAccountDetails } from "../../../api/companyProfile";
import { industryOptions } from "../../../pages/EmployerPages/EmployerJobPost/jobPostStaticData";
import { ThreeDot } from "react-loading-indicators";
import { toast } from "react-toastify";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

function CompanyDetailsModel({
  openCompanyDialog,
  handleCompanyDialogClose,
  orgData,
  fetchOrgDetails,
}) {
  const [loading, setLoading] = useState(false);
  // Initialize formDetails state
  const [formDetails, setFormDetails] = useState({
    industry: "",
    directorName: "",
    website: "",
    hrName: "",
    hrPhone: "",
  });

  const [errors, setErrors] = useState({
    industry: "",
    directorName: "",
    website: "",
    hrName: "",
    hrPhone: "",
  });

  const validateForm = () => {
    let isValid = true;
    let newErrorState = { ...errors };

    // Reset previous error messages
    Object.keys(newErrorState).forEach(key => {
        newErrorState[key] = "";
    });

    // Validate industry
    if (!formDetails.industry) {
        newErrorState.industry = "Industry is required.";
        isValid = false;
    }

    // Validate director name
    if (!formDetails.directorName) {
        newErrorState.directorName = "Director Name is required.";
        isValid = false;
    }
    if (!formDetails.website) {
      newErrorState.website = "Website is required.";
      isValid = false;
    } else {
      // Allow URLs with http, https, www, or direct domain names
      const websiteRegex = /(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.(com|in|net|org|co|edu|gov|info|biz)(\/.*)?$/;
    
      if (!websiteRegex.test(formDetails.website)) {
        newErrorState.website = "Please enter a valid website URL (example: vacanzi.com, https://vacanzi.in, www.vacanzi.net)";
        isValid = false;
      }
    }
    

 
    // Validate TA Manager Name
    if (!formDetails.hrName) {
        newErrorState.hrName = "TA Manager Name is required.";
        isValid = false;
    }

    // Validate TA Manager Contact
    if (!formDetails.hrPhone) {
        newErrorState.hrPhone = "TA Manager Contact is required.";
        isValid = false;
    } else if (formDetails.hrPhone.length !== 10 || !/^\d{10}$/.test(formDetails.hrPhone)) {
        newErrorState.hrPhone = "Phone number must be exactly 10 digits.";
        isValid = false;
    }

    // Validate email
    if (formDetails.email) {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailRegex.test(formDetails.email)) {
            newErrorState.email = "Please enter a valid email address.";
            isValid = false;
        }
    }

    setErrors(newErrorState);
    return isValid;
};

  // Update formDetails state when orgData changes
  useEffect(() => {
    if (orgData) {
      setFormDetails({
        industry: orgData.industry || "",
        directorName: orgData.directorName || "",
        website: orgData.website || "",
        hrName: orgData.hrName || "",
        hrPhone: orgData.hrPhone || "",
      });
    }
  }, [orgData]);

  // Handle input changes and update formDetails state
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormDetails((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle selection from Autocomplete
  const handleIndustryChange = (event, newValue) => {
    setFormDetails((prevData) => ({
      ...prevData,
      industry: newValue || "", // Ensure fallback to empty string
    }));
  };

  // Function to handle saving company details
  const editCompanyDetails = async () => {
    if (!validateForm()) return;
    setLoading(true);

    const orgId = localStorage.getItem("organizationId");
    const token = localStorage.getItem("token");

    if (orgId) {
      try {
        const response = await editAccountDetails(orgId, token, formDetails);
        toast.dark(
          <div style={{ display: "flex", alignItems: "center" }}>
            <CheckCircleOutline sx={{ marginRight: 4, color: "darkgreen" }} />
            <p style={{ fontWeight: "bold" }}>
              {response.message || "Company Details Updated Successfully"}
            </p>
          </div>
        );
        fetchOrgDetails();
        setFormDetails({
          industry: "",
          directorName: "",
          website: "",
          hrName: "",
          hrPhone: "",
        })
      } catch (error) {
        toast.dark(
          <div style={{ display: "flex", alignItems: "center" }}>
            <ErrorOutlineIcon sx={{ marginRight: 1, color: "red" }} />
            <p style={{ fontWeight: "bold" }}>
              {error.message || "Failed To Edit Company Details"}
            </p>
          </div>
        );
        console.log(error.message);
      } finally {
        
        setLoading(false);
        handleCompanyDialogClose();
      }
    } else {
      throw new Error("No organization ID");
    }
  };

  return (
    <Dialog open={openCompanyDialog} onClose={handleCompanyDialogClose}>
      <DialogTitle sx={{ fontFamily: "poppins", fontWeight: "bold" }}>
        Edit Company Details
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography
              component="legend"
              sx={{ fontSize: 12, fontFamily: "poppins", fontWeight: "bold" }}
            >
              Industry Type
            </Typography>
            <Autocomplete
  fullWidth
  options={industryOptions} // Use predefined industry options
  value={formDetails.industry}
  onChange={handleIndustryChange} // Handle selection
  renderInput={(params) => (
    <TextField
      {...params} // Ensure you're spreading the params correctly
      variant="outlined"
      size="small"
      name="industry" // Adjust the field name accordingly
      inputProps={{
        ...params.inputProps, // Make sure inputProps are passed correctly
        style: {
          fontFamily: "poppins", // Apply custom styles
        },
      }}
    />
  )}
/>

          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              component="legend"
              sx={{ fontSize: 12, fontFamily: "poppins", fontWeight: "bold" }}
            >
              Director's Name
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              name="directorName"
              value={formDetails.directorName}
              onChange={handleInputChange}
              error={Boolean(errors.directorName)}
              helperText={errors.directorName}
              inputProps={{
               
                style:{
                  fontFamily:"poppins"
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              component="legend"
              sx={{ fontSize: 12, fontFamily: "poppins", fontWeight: "bold" }}
            >
              Website URL
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              name="website"
              value={formDetails.website}
              onChange={handleInputChange}
              error={Boolean(errors.website)}
              helperText={errors.website}
              inputProps={{
               
                style:{
                  fontFamily:"poppins"
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              component="legend"
              sx={{ fontSize: 12, fontFamily: "poppins", fontWeight: "bold" }}
            >
              TA Manager
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              name="hrName"
              value={formDetails.hrName}
              onChange={handleInputChange}
              error={Boolean(errors.hrName)}
              helperText={errors.hrName}
              inputProps={{
               
                style:{
                  fontFamily:"poppins"
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              component="legend"
              sx={{ fontSize: 12, fontFamily: "poppins", fontWeight: "bold" }}
            >
              TA Manager Contact No:
            </Typography>
            <TextField
            sx={{fontFamily:"poppins"}}
              fullWidth
              variant="outlined"
              size="small"
              name="hrPhone"
              value={formDetails.hrPhone}
              onChange={handleInputChange}
              error={Boolean(errors.hrPhone)} // Show error if there's an error message
              helperText={errors.hrPhone} // Display the error message
              inputProps={{
                maxLength: 10, // Limit the input to 10 characters
                style:{
                  fontFamily:"poppins"
                }
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          sx={{
            bgcolor: "#000000",
            fontFamily:"poppins",
            textTransform: "none",
            borderRadius: "0.7rem",
            ":hover": { backgroundColor: "#000000" },
          }}
          onClick={handleCompanyDialogClose}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          sx={{
            bgcolor: "#000000",
            color: "white",
            fontFamily:"poppins",
            textTransform: "none",
            borderRadius: "0.7rem",
            "&:hover": { backgroundColor: "#000000", color: "#fff" },
          }}
          onClick={editCompanyDetails}
        >
          {loading ? (
            <ThreeDot color="#ffffff" size="small" text="" textColor="" />
          ) : (
            "Save"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CompanyDetailsModel;
