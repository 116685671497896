import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Card,
  Avatar,
  Typography,
  Button,
  IconButton,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const NetworkListModal = ({ open, handleClose, users }) => {
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontWeight: "bold",
          color: "#333",
        }}
      >
        People you may know from Selfeey Infotech
        <IconButton onClick={handleClose} sx={{ color: "#555" }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ p: 3 }}>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 2,
            justifyContent: "center",
          }}
        >
          {users.map((user, index) => (
            <Card
              key={index}
              elevation={0}
              sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                p: 2,
                borderRadius: 5,
                border: "1px solid #E0E0E0",
                backgroundColor: "#F9F9F9",
                boxShadow: "0px 2px 8px rgba(0,0,0,0.05)",
                transition: "transform 0.2s ease, box-shadow 0.2s ease",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: "0px 4px 12px rgba(0,0,0,0.1)",
                },
              }}
            >
              <Avatar src={user.avatar} sx={{ width: 64, height: 64, mb: 1 }} />
              <Typography sx={{ fontWeight: "bold", color: "#333" }}>
                {user.name}
              </Typography>
              <Typography color="textSecondary" sx={{ textAlign: "center" }}>
                {user.status}
              </Typography>
              <Button
                variant="outlined"
                sx={{
                  mt: 1,
                  color: "#000",
                  textTransform: "none",
                  fontSize: "14px",
                  fontWeight: "bold",
                  borderColor: "#000",
                  "&:hover": { backgroundColor: "#000", color: "#fff" },
                }}
                fullWidth
              >
                + Connect
              </Button>
            </Card>
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default NetworkListModal;
