import React, { useCallback, useEffect, useState } from "react";
import { Button, Typography, Box, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";

// import AllUser from "../../../components/EmployerComponents/ManageUserComponents/AllUsers";
import AddUser from "./AddUser";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import MemberStats from "./MemebersStats";
import MemberTable from "./MemberTable";
import ManageQuota from "../ManageQuota/ManageQuota";
import { allUsers } from "../../../api/manageUsers";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getOrgDetails } from "../../../api/companyProfile";
import { useOrg } from "../../../context/OrgContext";
import PopupSuccess from "../../../utils/popup/PopupSuccess";
import ReleaseJobPostModel from "../SubscriptionStatus/ReleaseJobPostModel";

const ManageUsers = () => {
  const [openAddUserDialog, setOpenAddUserDialog] = useState(false);

  const { orgData, setOrgData } = useOrg();
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [manageUsers, setManageUsers] = useState([]);

  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [loading, setLoading] = useState(false);

  const handleSuccess = useCallback((message) => {
    setSuccessMessage(message);
    setSuccessOpen(true);
    setTimeout(() => {
      setSuccessOpen(false);
      setSuccessMessage("");
    }, 2000);
  }, []);

  const handleError = useCallback((message) => {
    setErrorMessage(message);
    setErrorOpen(true);
    setTimeout(() => {
      setErrorOpen(false);
      setErrorMessage("");
    }, 2000);
  }, []);

  const handlOpenDialog = () => {
    setOpenAddUserDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenAddUserDialog(false);
  };

  const navigate = useNavigate();

  const token = localStorage.getItem("token");
  const orgId = localStorage.getItem("organizationId");

  const fetchUsers = useCallback(async () => {
    setLoading(true);
    try {
      const manageUserData = await allUsers(orgId, token);
      setManageUsers(manageUserData.recruiters);
      console.log("manageuser", manageUserData);
      
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  }, [orgId, token]);
  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const handleBack = () => {
    navigate(-1); // Go back to the previous page
  };

  const fetchOrgDetails = useCallback(async () => {
    try {
      if (orgId) {
        const response = await getOrgDetails(orgId, token);
        setOrgData(response);
      } else {
        throw new Error("No organization id");
      }
    } catch (error) {
      console.log(error.message);
    }
  }, [orgId, token, setOrgData]);

  useEffect(() => {
    fetchOrgDetails();
  }, [fetchOrgDetails]);

  return (
    <>
      <Typography
        onClick={handleBack}
        gutterBottom
        sx={{
          cursor: "pointer",
          display: "flex",
          ml: 1,
          alignItems: "center",

          color: "grey", // Adjust color for better visibility
          "&:hover": {
            color: "primary.dark", // Hover effect
          },
        }}
      >
        <ArrowBackIcon sx={{ marginRight: "4px" }} />
      </Typography>
      <Container sx={{ borderRadius: "5px" }}>
        <Box sx={{ padding: "1rem", height: "100%" }}>
          {/* Header Section */}
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
              alignItems: { xs: "flex-start", sm: "center" },
              marginBottom: 3,
              borderRadius: "10px",
            }}
          >
            <Box>
              {/* Team Members Header */}
              <Typography
                variant="h5"
                gutterBottom
                sx={{ fontWeight: "bold", lineHeight: 0.5 }}
              >
                Team members
              </Typography>

              {/* Invite New Members Text */}
              <Typography
                variant="body2"
                sx={{ marginTop: 0, lineHeight: 1.5 }}
              >
                Invite new members to your team and assign them different roles
                and permissions.
              </Typography>
            </Box>

            {/* Add Users Button */}
            <Button
              variant="contained"
              onClick={handlOpenDialog}
              startIcon={<PersonAddIcon />}
              sx={{
                backgroundColor: "#000000",
                color: "#ffffff",
                fontWeight: "bold",
                textTransform: "none",
                borderRadius: "0.7rem",
                "&:hover": {
                  backgroundColor: "#000000",
                  color: "#ffffff",
                  border: "1px solid #000000",
                },
              }}
            >
              Add Member
            </Button>
          </Box>

          <Container
            elevation={0}
            sx={{
              padding: 2,
              backgroundColor: "#f9f9f9",
            }}
          >
            <MemberStats orgData={manageUsers} />
            <ManageQuota
              handleError={handleError} 
              handleSuccess={handleSuccess}
            />
            <MemberTable
              handleSuccess={handleSuccess}
              handleError={handleError}
              manageUsers={manageUsers}
              setManageUsers={setManageUsers}
            
              loading={loading}
            />
          </Container>
          {/* Tabs Section */}
          {/* <AllUser /> */}
        </Box>

        {/* Add User Dialog */}
        <AddUser
          openAddUserDialog={openAddUserDialog}
          handleCloseDialog={handleCloseDialog}
          orgData={orgData}
          handleSuccess={handleSuccess}
          handleError={handleError}
          fetchUsers={fetchUsers}
        />
      </Container>
      <PopupSuccess
        modelOpen={successOpen}
        onClose={() => setSuccessOpen(false)}
        message={successMessage}
      />
      <ReleaseJobPostModel
        modelOpen={errorOpen}
        onClose={() => setErrorOpen(false)}
        message={errorMessage}
      />
    </>
  );
};
export default ManageUsers;
