import React, { useEffect, useState, useRef } from "react";
import {
  Paper,
  Avatar,
  Typography,
  Box,
  TextField,
  IconButton,
} from "@mui/material";
import avatarmen from "../../../assets/images/avatarmen.jpg";
import SendIcon from "@mui/icons-material/Send";

const JobSeekerChatDetails = ({
  chatMessages,
  supportUser,
  messageData,
  handleInputChange,
  handleSend,
  statusMessage,
}) => {
  const [selectedChat, setSelectedChat] = useState(null);
  const chatContainerRef = useRef(null); // Ref to scroll to bottom

  useEffect(() => {
    if (chatMessages?.length > 0) {
      setSelectedChat(chatMessages[chatMessages.length - 1]);
      // Scroll to the bottom when messages update
      if (chatContainerRef.current) {
        chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
      }
    }
  }, [chatMessages]);

  // Sort messages by createdAt in ascending order
  const sortedMessages = chatMessages
    ? [...chatMessages].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
    : [];

  return (
    supportUser && (
      <Paper
        elevation={0}
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "80vh",
          borderRadius: "8px",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            padding: 2,
            borderBottom: "1px solid #ddd",
            background: "#eaeaea",
          }}
        >
          <Avatar sx={{ marginRight: 2 }} src={supportUser?.profileImage}>
            P
          </Avatar>
          <Box>
            <Typography variant="body1" fontWeight="bold">
              {supportUser?.fullName || "Unknown Organization"}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Last seen{" "}
              {selectedChat?.updatedAt
                ? new Date(selectedChat.updatedAt).toLocaleString()
                : "unknown"}
            </Typography>
          </Box>
        </Box>

        <Box
          ref={chatContainerRef} // Attach ref to scrollable container
          sx={{
            flexGrow: 1,
            overflowY: "auto",
            padding: 2,
            background: "#f2f2f2",
          }}
        >
          {sortedMessages.length > 0 ? (
            sortedMessages.map((chat, index) => {
              const sender = chat.sender || {};
              const isSenderUser = ["jobseeker"].includes(chat.senderRole);
              return (
                <Box
                  key={index}
                  sx={{
                    marginBottom: 2,
                    display: "flex",
                    flexDirection: isSenderUser ? "row-reverse" : "row",
                    alignItems: "center",
                  }}
                >
                  <Avatar
                    sx={{ margin: isSenderUser ? "0 12px 0 0" : "0 0 0 12px" }}
                    src={
                      sender.rcEmail
                        ? `https://www.gravatar.com/avatar/${sender.rcEmail}`
                        : avatarmen
                    }
                  >
                    {sender.rcName?.charAt(0) || "P"}
                  </Avatar>
                  <Box
                    sx={{ display: "flex", flexDirection: "column", maxWidth: "70%" }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        backgroundColor: isSenderUser ? "#f0f0f0" : "#d1e7dd",
                        padding: "8px 12px",
                        borderRadius: "8px",
                        wordBreak: "break-word",
                      }}
                    >
                      {chat.messageContent}
                    </Typography>
                    <Typography
                      variant="caption"
                      color="text.secondary"
                      sx={{
                        marginTop: "4px",
                        alignSelf: isSenderUser ? "flex-start" : "flex-end",
                      }}
                    >
                      {new Date(chat.createdAt).toLocaleTimeString()}
                    </Typography>
                  </Box>
                </Box>
              );
            })
          ) : (
            <Typography variant="h6" color="text.secondary" textAlign="center">
              Loading conversation...
            </Typography>
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            padding: "0.5rem",
            borderTop: "1px solid #ddd",
            backgroundColor: "#f9f9f9",
          }}
        >
          <TextField
            fullWidth
            placeholder="Enter message here..."
            variant="outlined"
            value={messageData}
            onChange={handleInputChange}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleSend();
              }
            }}
            sx={{ borderRadius: "2rem", backgroundColor: "white" }}
          />
          {statusMessage !== "closed" && (
            <IconButton onClick={handleSend} sx={{ marginLeft: "0.5rem" }}>
              <SendIcon color="primary" />
            </IconButton>
          )}
        </Box>
      </Paper>
    )
  );
};

export default JobSeekerChatDetails;