import React from "react";
import { Box } from "@mui/material";
import JobSeekerLetBuildForm from "./JobSeekerLetBuildForm";
import JobSeekerTestiMonalCard from "./JobSeekerTestiMonalCard";

const JobSeekerLetBuild = () => {
  return (
    <Box sx={{ display:'flex',justifyContent:'center',gap:2,mb:-8}}>
      <JobSeekerLetBuildForm />
      <JobSeekerTestiMonalCard />
    </Box>
  );
};

export default JobSeekerLetBuild;
