import React, { useState } from "react";
import {
  Button,
  Dialog,

  DialogContent,
  DialogTitle,

  Typography,
  IconButton,
  TextField,
  Box,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { styled } from "@mui/material/styles";
import { paymentInitiateApi } from "../../api/payment";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    borderRadius: 20,
    maxWidth: 400,
    width: "100%",
    margin: 16,
  },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  padding: theme.spacing(2),
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  borderBottom: "none",
}));

const AmountButton = styled(Button)(({ theme }) => ({
  borderRadius: 20,
  border: "1px solid #e0e0e0",
  color: "#1976d2",
  backgroundColor: "transparent",
  padding: "8px 16px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#f5f5f5",
    borderColor: "#1976d2",
  },
}));

const AddMoneyButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#000",
  color: "white",
  borderRadius: "0.7rem",
  padding: "12px",
  width: "100%",
  textTransform: "none",
  fontSize: 16,
  "&:hover": {
    backgroundColor: "#000",
  },
}));

const PaymentDialog = ({ openPayDialog, handlePayMenuClose }) => {
  const [amount, setAmount] = useState(""); // To store the custom amount
  const [selectedAmount, setSelectedAmount] = useState(null);

  const presetAmounts = [49, 99, 199, 499];

  const token = localStorage.getItem("token");
  const orgId = localStorage.getItem("organizationId");

  const handleContinuePayment = async () => {
    try {
      // Define the base URLs
      const localhostUrl = "http://localhost:3000/paymentVerify"; // Local development URL
      const liveSiteUrl = "https://demo.vacanzi.com/paymentVerify"; // Live production URL

      const redirectUrl =
        window.location.hostname === "localhost" ? localhostUrl : liveSiteUrl;

      const formValues = {
        amount: (selectedAmount || amount) * 100,
        organizationId: orgId,
        redirectUrl: redirectUrl,
      };

      const response = await paymentInitiateApi(token, formValues);

      if (response) {
        localStorage.setItem(
          "merchantTransactionId",
          response?.merchantTransactionId
        );
      }

      if (response?.paymentUrl) {
        window.location.href = response.paymentUrl;
      } else {
        console.error("Payment URL not found in the response");
      }
    } catch (error) {
      console.error("Error during payment initiation:", error);
    }
  };

  const handlePresetAmountClick = (presetAmount) => {
    setSelectedAmount(presetAmount);
    setAmount(presetAmount);
  };

  return (
    <StyledDialog
      open={openPayDialog}
      onClose={handlePayMenuClose}
      fullWidth
    >
      <StyledDialogTitle>
        <IconButton edge="start" onClick={handlePayMenuClose} sx={{ mr: 1 }}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6" sx={{fontFamily:"poppins"}}>Add Money</Typography>
      </StyledDialogTitle>

      <DialogContent sx={{ pt: 2, pb: 3 }}>
        <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
          Enter Amount
        </Typography>

        <TextField
  fullWidth
  variant="outlined"
  placeholder="0"
  InputProps={{
    startAdornment: <Typography sx={{ mr: 1 }}>₹</Typography>,
  }}
  sx={{
    mb: 2,
    "& .MuiOutlinedInput-root": {
      borderRadius: 2,
    },
  }}
  value={selectedAmount || ""}
  onChange={(e) => {
    const enteredAmount = Number(e.target.value);
    if (enteredAmount <= 50000) {
      setSelectedAmount(enteredAmount);
    }
  }}
/>

        <Box sx={{ display: "flex", gap: 1, mb: 3 }}>
          {presetAmounts.map((amount) => (
            <AmountButton
              key={amount}
              onClick={() => handlePresetAmountClick(amount)}
            >
              + ₹{amount.toLocaleString()}
            </AmountButton>
          ))}
        </Box>

        <AddMoneyButton variant="contained" onClick={handleContinuePayment}>
          Recharge
        </AddMoneyButton>
      </DialogContent>
    </StyledDialog>
  );
};

export default PaymentDialog;
