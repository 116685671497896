"use client";

import { useState, useRef, useCallback } from "react";
import {
  Box,
  Typography,
  Paper,
  Button,
  createTheme,
  ThemeProvider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { addNewCertificateApi } from "../../../api/jobseeker";
import PopupSuccess from "../../../utils/popup/PopupSuccess";
import ReleaseJobPostModel from "../../../pages/EmployerPages/SubscriptionStatus/ReleaseJobPostModel";

// All styles in one section
const styles = {
  container: {
    padding: "24px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginBottom: "16px",
  },
  headerText: {
    display: "flex",
    flexDirection: "column",
  },
  resumeFile: {
    padding: "16px",
    display: "flex",
    marginTop: 2,
    alignItems: "center",
    gap: "16px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#ffffff",
    },
  },
  fileInfo: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  pdfIcon: {
    color: "#dc2626",
    fontSize: 40,
  },
  uploadButton: {
    marginLeft: "4px",
    textTransform: "none",
    backgroundColor: "#000000",
    color: "white",
    borderRadius: "0.7rem",
    padding: "0.375rem 0.75rem",
    fontFamily: "IBM plex sans",
    "&:hover": {
      backgroundColor: "#000000",
    },
  },
};

// Theme configuration
const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
  },
  typography: {
    fontFamily: "inherit",
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
  },
});

export default function JobSeekerCertifications({
  userData,
  onDelete,
  setUserData,
}) {
  const [previewResume, setPreviewResume] = useState(""); // State for previewing the resume
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false); // State for preview modal
  const fileInputRef = useRef(null); // Ref for file input
  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem("authToken");
  const [selectedFile, setSelectedFile] = useState(null); // Store the actual file
  const [successOpen, setSuccessOpen] = useState(false);

  const [successMessage, setSuccessMessage] = useState("");

  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSuccess = useCallback((message) => {
    setSuccessMessage(message);
    setSuccessOpen(true);
    setTimeout(() => {
      setSuccessOpen(false);
      setSuccessMessage("");
    }, 2000);
  }, []);

  const handleError = useCallback((message) => {
    setErrorMessage(message);
    setErrorOpen(true);
    setTimeout(() => {
      setErrorOpen(false);
      setErrorMessage("");
    }, 2000);
  }, []);

  // Function to handle file upload
  const handleResumeUpload = (event) => {
    const file = event.target.files[0];

    if (!file) {
      handleError("No File Selected");

      return;
    }

    const allowedTypes = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];

    if (!allowedTypes.includes(file.type)) {
      handleError("Please upload a valid PDF, DOC, or DOCX file.");

      return;
    }

    setSelectedFile(file); // Store the actual file
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewResume(reader.result); // Store preview
      setIsPreviewModalOpen(true); // Open modal automatically after selecting file
    };
    reader.readAsDataURL(file);
  };

  // Handle resume update
  const handleUpdateResume = async () => {
    if (!selectedFile) {
      handleError("No file selected.");

      return;
    }

    try {
      const formData = new FormData();
      formData.append("certifications", selectedFile); // Ensure correct key name

      const response = await addNewCertificateApi(userId, token, formData);

      // Update userData with the new certificate
      setUserData((prevUserData) => ({
        ...prevUserData,
        certifications:
          response.user.certifications || prevUserData.certifications,
      }));

      // Clear stored file and preview
      setSelectedFile(null);
      setPreviewResume("");
      setIsPreviewModalOpen(false);
      handleSuccess(" Certificate uploaded successfully!");
    } catch (error) {
      console.error("Failed to update certificate:", error.message);
      handleError("Failed to update certificate. Please try again.");
    }
  };

  // Function to format date
  const formatDate = (dateString) => {
    const date = new Date(dateString);

    // Extract day, month, and year
    const day = String(date.getDate()).padStart(2, "0"); // Ensure 2 digits
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  // Sort and get latest certifications, limiting to 5
  const allCertifications = userData?.certifications?.length
    ? [...userData.certifications]
        .sort((a, b) => new Date(b.uploadedAt) - new Date(a.uploadedAt))
        .slice(0, 5) // Limit to 5 items
    : [];

  return (
    <ThemeProvider theme={theme}>
      <Paper
        elevation={0}
        sx={{
          borderRadius: "15px",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
        }}
      >
        <Box sx={styles.container}>
          <Box sx={styles.header}>
            <Box sx={styles.headerText}>
              <Typography
                variant="h5"
                fontWeight={500}
                fontFamily={"IBM plex sans"}
              >
                Certifications
              </Typography>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Button
                variant="contained"
                component="label"
                sx={styles.uploadButton}
              >
                Upload New
                <input
                  type="file"
                  hidden
                  onChange={handleResumeUpload}
                  ref={fileInputRef}
                />
              </Button>
            </Box>
          </Box>
          {allCertifications.length > 0 ? (
            allCertifications.map((cert, index) => (
              <Paper
                key={index}
                elevation={1}
                sx={styles.resumeFile}
                style={{ cursor: "pointer" }} // Make it look clickable
              >
                <PictureAsPdfIcon
                  sx={styles.pdfIcon}
                  onClick={() => window.open(cert.url, "_blank")}
                  style={{ cursor: "pointer" }}
                />
                <Box sx={styles.fileInfo}>
                  <Typography
                    variant="body1"
                    fontWeight={500}
                    fontFamily={"IBM plex sans"}
                  >
                    {cert.filename}
                  </Typography>
                  <Typography
                    sx={{ fontSize: "13px" }}
                    fontWeight={500}
                    fontFamily={"IBM plex sans"}
                  >
                    Updated at {formatDate(cert.uploadedAt)}
                  </Typography>
                </Box>
                <Button
                  variant="contained"
                  component="label"
                  sx={styles.uploadButton}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevents triggering the preview on delete click
                    onDelete(cert, "certifications");
                  }}
                >
                  Delete
                </Button>
              </Paper>
            ))
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  padding: "20px",
                  margin: "0 auto",
                  backgroundColor: "#fff",
                  justifyContent: "space-between",
                  borderRadius: "15px",
                  marginTop: 1,
                  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
                  cursor: "pointer", // Indicate clickable area
                  transition: "background-color 0.3s ease", // Smooth hover effect
                  "&:hover": {
                    backgroundColor: "#f5f5f5", // Light hover effect
                  },
                  fontFamily: "IBM Plex Sans",
                }}
              >
                <Typography variant="body1" color="text.secondary">
                  No certifications uploaded yet.
                </Typography>
              </Box>
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{ marginTop: 1 }}
              >
                Allowed file types: PDF, DOC, DOCX
              </Typography>
            </>
          )}
        </Box>
      </Paper>
      {/* Preview Modal */}
      <Dialog
        open={isPreviewModalOpen}
        onClose={() => setIsPreviewModalOpen(false)}
      >
        <DialogTitle>Preview Certificate</DialogTitle>
        <DialogContent>
          <iframe
            src={previewResume}
            width="100%"
            height="500px"
            title="Certificate Preview"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsPreviewModalOpen(false)}
            sx={{ color: "#666", textTransform: "none" }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleUpdateResume}
            sx={{
              backgroundColor: "#000000",
              borderRadius: "0.7rem",
              color: "white",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#000000",
              },
            }}
          >
            Confirm Upload
          </Button>
        </DialogActions>
      </Dialog>
      <PopupSuccess
        modelOpen={successOpen}
        onClose={() => setSuccessOpen(false)}
        message={successMessage}
      />
      <ReleaseJobPostModel
        modelOpen={errorOpen}
        onClose={() => setErrorOpen(false)}
        message={errorMessage}
      />
    </ThemeProvider>
  );
}
