import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Typography,
  Container,
  Grid,
  CardContent,
  Card,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DangerousIcon from "@mui/icons-material/Dangerous";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import CreditCardOffIcon from "@mui/icons-material/CreditCardOff";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
// import ReportIcon from "@mui/icons-material/Report";

import Footer from "../../components/JobSeekerComponents/Footer/Footer";
import { useNavigate } from "react-router-dom";
import JobSeekerSupportForm from "../JobSeekerPages/JobSeekerSupport/JobSeekerSupportForm";
import { getUserApi } from "../../api/jobseeker";
import { jobSeekerCreateTicket, fetchJobSeekerSupport } from "../../api/suport";
import PopupSuccess from "../../utils/popup/PopupSuccess";
import ReleaseJobPostModel from "../EmployerPages/SubscriptionStatus/ReleaseJobPostModel";

const styles = {
  wrapper: {
    backgroundColor: "#f8f9fa",
    py: 8,
  },
  container: {
    maxWidth: "xl",
  },
  gridItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    width: "100%",
    height: "400px",
    px: 3,
    py: 4, // Add vertical padding
    backgroundColor: "#ffffff", // White background
    borderRadius: "12px", // Rounded corners
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Subtle shadow
    marginBottom: 3, // Add margin between boxes
  },
  iconWrapper: {
    width: 80,
    height: 80,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    backgroundColor: "#e8f5e9",
    mb: 3,
  },
  icon: {
    fontSize: 40,
    color: "#666",
  },
  title: {
    fontSize: "1.5rem",
    fontFamily: "Satoshi",
    fontWeight: 600,
    mb: 2,
    color: "#1a1a1a",
  },
  description: {
    color: "#666666",
    fontFamily: "Satoshi",
    lineHeight: 1.6,
    maxWidth: 350,
    mx: "auto",
  },
  certImage: {
    width: 120,
    mt: 3,
  },
};

const cons = [
  {
    icon: <DangerousIcon sx={styles.icon} />,
    title: " Fake Job Offers",
    description:
      "Scammers may send emails, messages, or calls pretending to be recruiters from Vacanzi, offering guaranteed jobs in exchange for money. Vacanzi never charges job seekers for job applications, interviews, or employment.",
  },
  {
    icon: <PersonOffIcon sx={styles.icon} />,
    title: " Unverified Recruiters",
    description:
      " Fraudsters may create fake social media profiles or send messages impersonating Vacanzi representatives. Always verify recruiter details through Vacanzi’s official platform before proceeding.",
  },
  {
    icon: <CreditCardOffIcon sx={styles.icon} />,
    title: " Requests for Personal & Financial Information",
    description:
      "Be cautious if someone asks for sensitive information like, Bank details ,OTPs or passwords ,Identity documents (Aadhaar, PAN, Social Security Number, etc.) Vacanzi will never ask for such details through calls, emails, or messages",
  },
  {
    icon: <CurrencyRupeeIcon sx={styles.icon} />,
    title: " Payment Requests for Job Placements",
    description:
      "Any request for advance payments, processing fees, or security deposits in exchange for a job is a scam. Vacanzi does not charge job seekers for applying or getting hired.",
  },
];

const FraudAlertPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const token = localStorage.getItem("authToken");
  const userId = localStorage.getItem("userId");
  const [userData, setUserData] = useState();

  const handleModalClose = () => {
    console.log("Closing modal");
  };

  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSuccess = useCallback((message) => {
    setSuccessMessage(message);
    setSuccessOpen(true);
    setTimeout(() => {
      setSuccessOpen(false);
      setSuccessMessage("");
    }, 2000);
  }, []);

  const handleError = useCallback((message) => {
    setErrorMessage(message);
    setErrorOpen(true);
    setTimeout(() => {
      setErrorOpen(false);
      setErrorMessage("");
    }, 2000);
  }, []);

  useEffect(() => {
    if (token) {
      const getUserDetails = async () => {
        try {
          const response = await getUserApi(userId, token);
          setUserData(response?.user);
        } catch (error) {
          console.log(error.message);
        }
      };
      getUserDetails();
    }
  }, [token, userId]);

  const handleSave = async (topic, messageContent, supportType) => {
    try {
      const payload = { topic, messageContent, supportType };
      await jobSeekerCreateTicket(payload);
      const updatedSupportData = await fetchJobSeekerSupport();
      console.log("updatedsupportData", updatedSupportData);
      handleSuccess("Support ticket created successfully");
    } catch (error) {
      handleError("Failed to create the support ticket.");
    } finally {
      handleModalClose();
    }
  };

  return (
    <>
      <Typography
        sx={{
          pt: 2,
          textDecoration: "underline",
          color: "#270FFFFF",
          cursor: "pointer",
        }}
        textAlign="center"
        onClick={() => navigate(-1)}
      >
        Close Window
      </Typography>

      <Container
        maxWidth="xl"
        sx={{
          fontFamily: "Satoshi",
          padding: "40px 20px",
          borderRadius: "12px",
          marginTop: "40px",
          marginBottom: "80px",
        }}
      >
        <Typography
          variant="body1"
          sx={{
            fontSize: "1.4rem",
            lineHeight: 1.6,
            color: "#333",
            fontFamily: "Satoshi",
            marginBottom: "30px",
          }}
        >
          At Vacanzi, your safety is our top priority. As we grow, we want to
          ensure that job seekers and employers remain protected from fraudulent
          activities. Scammers may attempt to misuse our name to deceive users
          into sharing personal details or making payments for fake job
          opportunities.
        </Typography>

        <Typography
          variant="h5"
          textAlign="center"
          component="h2"
          sx={{
            fontWeight: 600,
            color: "#000000FF",
            marginBottom: "20px",
            fontFamily: "Satoshi",
          }}
        >
          Beware of Fraudulent Activities
        </Typography>

        <Box sx={styles.wrapper}>
          <Container sx={styles.container}>
            <Grid container spacing={2}>
              {cons.map((item, index) => (
                <Grid item xs={12} md={6} key={index}>
                  <Box sx={styles.gridItem}>
                    <Box sx={styles.iconWrapper}>{item.icon}</Box>
                    <Typography variant="h5" sx={styles.title}>
                      {item.title}
                    </Typography>
                    <Typography variant="body1" sx={styles.description}>
                      {item.description}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
        {/* Content Section */}

        {/* How to Stay Safe Section */}
        {/* How to Stay Safe Section */}
        <Box sx={{ marginBottom: "30px", fontFamily: "Satoshi" }}>
          <Typography
            variant="h5"
            component="h2"
            textAlign="center"
            sx={{
              fontWeight: 600,
              color: "#000000",
              mt: 2,
              marginBottom: "20px",
              fontFamily: "Satoshi",
            }}
          >
            How to Stay Safe
          </Typography>

          {/* Card Grid Container */}
          <Grid container spacing={3}>
            {[
              "Verify job postings and recruiters – Always check job listings directly on www.vacanzi.com before engaging with recruiters.",
              "Do not share sensitive information – Keep your passwords, OTPs, and financial details private.",
              "Be cautious of unsolicited offers – If it sounds too good to be true, it probably is.",
              "Use Vacanzi’s official channels – Communicate only through Vacanzi’s platform to ensure authenticity.",
              "Report any suspicious activity immediately – Help us keep the community safe.",
            ].map((text, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                {/* Card Component */}
                <Card
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "12px",
                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                    transition: "transform 0.2s, box-shadow 0.2s",
                    "&:hover": {
                      transform: "translateY(-4px)",
                      boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
                    },
                  }}
                >
                  <CardContent sx={{ flexGrow: 1 }}>
                    {/* Check Icon */}
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "12px",
                      }}
                    >
                      <CheckCircleIcon
                        sx={{ color: "#28a745", marginRight: "8px" }}
                      />
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontWeight: 600,
                          color: "#28a745",
                          fontFamily: "Satoshi",
                        }}
                      >
                        Tip {index + 1}
                      </Typography>
                    </Box>

                    {/* Safety Tip Text */}
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "1rem",
                        color: "#333",
                        fontFamily: "Satoshi",
                      }}
                    >
                      {text}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
        <JobSeekerSupportForm userData={userData} handleSave={handleSave} />
      </Container>
      <PopupSuccess
        modelOpen={successOpen}
        onClose={() => setSuccessOpen(false)}
        message={successMessage}
      />
      <ReleaseJobPostModel
        modelOpen={errorOpen}
        onClose={() => setErrorOpen(false)}
        message={errorMessage}
      />
      <Footer />
    </>
  );
};

export default FraudAlertPage;
