import React, { useState, useEffect, Suspense } from "react";
import { Typography, Button, Container, Avatar } from "@mui/material";
import ConstructionOutlinedIcon from "@mui/icons-material/ConstructionOutlined";
import StarIcon from "@mui/icons-material/Star";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Footer from "../../../components/JobSeekerComponents/Footer/Footer";
import { jwtDecode } from "jwt-decode";
import baseURL from "../../../api/baseUrl";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import { lazy } from "react";
import EditIcon from "@mui/icons-material/Edit";
import ReviewModal from "../../../components/JobSeekerComponents/Reviews/ReviewModal";

const JobCardSkeleton = lazy(() =>
  import("../../../utils/lazy/JobCardSkeleton")
);

const JobsApplied = () => {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [openModalJobId, setOpenModalJobId] = useState(null);

  const fetchAppliedJobs = async () => {
    try {
      const authToken = localStorage.getItem("authToken");
      if (!authToken) throw new Error("User not authenticated");

      const decodedToken = jwtDecode(authToken);
      const userId = decodedToken.id;

      const response = await fetch(`${baseURL}/jobs/${userId}/applied`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setJobs(data.appliedJobs || []);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAppliedJobs();
  }, []);

  const postedDateConvert = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  if (loading) {
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <JobCardSkeleton />
      </Suspense>
    );
  }

  if (error) {
    return (
      <Typography
        variant="body1"
        color="error"
        sx={{ mt: 4, textAlign: "center" }}
      >
        {error}
      </Typography>
    );
  }

  const profileImage = localStorage.getItem("profileImage");
  const userName = localStorage.getItem("userName");

  return (
    <>
      <Box
        sx={{
          mb: "30vh",
          padding: "30px",
          background: "linear-gradient(135deg, #F8F9FA, #CED4DA)",
          minHeight: "100vh",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            textAlign: "center",
            mb: 4,
            fontFamily: "IBM Plex Sans",
            fontWeight: 600,
          }}
        >
          <span style={{ color: "#423bfa" }}>{userName || "User"}</span>,
          Applied Jobs
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Avatar
            sx={{ width: "100px", height: "100px", mb: 1 }}
            src={profileImage}
          />
        </Box>
        <Container maxWidth="xl">
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              background: "transparent",
              padding: 2,
              justifyContent: "center",
              gap: 2,
            }}
          >
            {jobs.length > 0 ? (
              jobs.map((job) => {
                const averageRating =
                  job?.jobId?.jsReviews && job.jobId.jsReviews.length > 0
                    ? (
                      job.jobId.jsReviews.reduce(
                        (sum, review) => sum + review.rating,
                        0
                      ) / job.jobId.jsReviews.length
                    ).toFixed(1)
                    : null;

                return (
                  <Box
                    key={job.jobId?._id}
                    sx={{
                      flex: {
                        xs: "1 1 100%", // Single column on mobile
                        sm: "0 1 calc(50% - 10px)", // Two items per row on larger screens
                      },
                      mt: 1,
                      p: 3,
                      background: "#ffffff",
                      borderRadius: "20px",
                      boxShadow: "0 6px 12px rgba(30, 10, 58, .04)",
                      marginBottom: 3,
                      padding: "24px",
                      transition: "box-shadow .2s linear",
                      fontFamily: "IBM Plex Sans, sans-serif",
                      position: "relative",
                      display: "flex",
                      gap: 3,
                      maxWidth: "600px",
                      minHeight: "220px",
                      "&:hover": {
                        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                      },
                    }}
                  >
                    {/* Left Side - Image and Review Button */}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: 1,
                        flexShrink: 0,
                      }}
                    >
                      <Box
                        sx={{
                          width: "160px",
                          height: "190px",
                          borderRadius: 4,
                          overflow: "hidden",
                          bgcolor: "black",
                          flexShrink: 0,
                        }}
                      >
                        <Box
                          component="img"
                          src={job.jobId?.organizationId?.companyLogo?.url}
                          alt="Company Logo"
                          sx={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            navigate(`/JobSeekerFilter/job/${job.jobId?._id}`)
                          }
                        />
                      </Box>
                      <Button
                        onClick={() => setOpenModalJobId(job.jobId?._id)}
                        size="small"
                        endIcon={<EditIcon sx={{ fontSize: "16px" }} />}
                        sx={{
                          mt: 2,
                          backgroundColor: "#423bfa",
                          color: "white",
                          fontWeight: "bold",
                          borderRadius: "0.7rem",
                          padding: "4px 12px", // Adjusted padding for better spacing
                          textTransform: "none",
                          fontSize: "0.8rem",
                          "&:hover": {
                            backgroundColor: "#B0F0F0",
                            color: "#000",
                          },
                          display: "flex", // Ensure flex for internal alignment
                          alignItems: "center", // Vertically center text and icon
                          justifyContent: "center", // Horizontally center content
                          minWidth: "auto", // Prevent excessive width
                        }}
                        variant="contained"
                      >
                        {job?.jobId?.jsReviews && job.jobId.jsReviews.length > 0
                          ? "Reviewed"
                          : "Write a Review"}
                      </Button>
                    </Box>
                    <ReviewModal
                      open={openModalJobId === job.jobId?._id}
                      onClose={() => setOpenModalJobId(null)}
                      companyName={job?.jobId?.orgName}
                      jobPostId={job?.jobId?._id}
                      averageRating={averageRating}
                      onRatingSubmitted={fetchAppliedJobs}
                    />

                    {/* Right Side - Job Content */}
                    <Box sx={{ flex: 1, minWidth: 0 }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "flex-start",
                          mb: 1,
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          navigate(`/JobSeekerFilter/job/${job.jobId?._id}`)
                        }
                      >
                        <Box sx={{ flex: 1, minWidth: 0 }}>
                          <Typography
                            variant="h6"
                            sx={{
                              fontFamily: "IBM Plex Sans, sans-serif",
                              fontWeight: 600,
                              color: "#1A1A1A",
                              fontSize: "1.1rem",
                              mb: 0.5,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {job.jobId?.jobTitle || "Untitled Job"}
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                              mb: 0.5,
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: "IBM Plex Sans, sans-serif",
                                color: "#484848",
                                fontSize: "0.95rem",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {job.jobId?.organizationId?.organizationName ||
                                "Unknown Company"}
                            </Typography>
                            {job?.jobId?.jsReviews &&
                              job.jobId.jsReviews.length > 0 && (
                                <Typography
                                  sx={{
                                    fontFamily: "Satoshi",
                                    color: "#423bfa",
                                    fontWeight: "bold",
                                    px: 0.8,
                                    py: 0.2,
                                    borderRadius: 1,
                                    fontSize: "0.8rem",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <StarIcon
                                    sx={{ fontSize: "0.9rem", mr: 0.3 }}
                                  />
                                  {averageRating}
                                </Typography>
                              )}
                          </Box>
                        </Box>
                        <Box
                          component="img"
                          src={
                            job.jobId?.organizationId?.companyLogo?.url ||
                            "/placeholder-logo.png"
                          }
                          sx={{
                            width: 45,
                            height: 45,
                            borderRadius: 1,
                            flexShrink: 0,
                            objectFit: "cover",
                            ml: 2,
                          }}
                        />
                      </Box>

                      {/* Job Details */}
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 2,
                          mb: 2,
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                        >
                          <WorkOutlineOutlinedIcon
                            sx={{ color: "#666", fontSize: "1.1rem" }}
                          />
                          <Typography
                            sx={{
                              color: "#484848",
                              fontSize: "0.9rem",
                              fontFamily: "IBM Plex Sans, sans-serif",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {job.jobId?.requiredExperienceMin || "Not Mentioned"} - {job.jobId?.requiredExperienceMax || "Not Mentioned"}
                          </Typography>

                        </Box>
                        <Typography
                          sx={{
                            color: "#484848",
                            fontSize: "0.9rem",
                            fontFamily: "IBM Plex Sans, sans-serif",
                            whiteSpace: "nowrap",
                          }}
                        >
                          ₹ {job.jobId?.ctcMin || "Not Disclosed"} - {job.jobId?.ctcMax || "Not Disclosed"}
                        </Typography>
                        <br />

                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                        >
                          <LocationOnOutlinedIcon
                            sx={{ color: "#666", fontSize: "1.1rem" }}
                          />
                          <Typography
                            sx={{
                              color: "#484848",
                              fontSize: "0.9rem",
                              fontFamily: "IBM Plex Sans, sans-serif",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              maxWidth: "200px",
                            }}
                          >
                            {Array.isArray(job.jobId?.jobLocations) && job.jobId.jobLocations.length > 0
                              ? job.jobId.jobLocations.map((loc) => loc.location).join(", ")
                              : "Not Specified"}
                          </Typography>
                        </Box>

                      </Box>

                      {/* Role Description */}
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 0.5, mb: 1 }}
                      >
                        <DescriptionOutlinedIcon
                          sx={{ color: "#666", fontSize: "1.1rem" }}
                        />
                        <Typography
                          sx={{
                            color: "#484848",
                            fontSize: "0.9rem",
                            fontFamily: "IBM Plex Sans, sans-serif",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Role: {job.jobId?.jobRole || "Not Provided"}
                        </Typography>
                      </Box>

                      {/* Skills Section */}
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 0.5, mb: 1 }}
                      >
                        <ConstructionOutlinedIcon
                          sx={{ color: "#666", fontSize: "1.1rem" }}
                        />
                        <Typography
                          sx={{
                            fontSize: "0.9rem",
                            color: "#000",
                            fontFamily: "Satoshi",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            maxWidth: "100%",
                          }}
                        >
                          Skills:{" "}
                          {(Array.isArray(job.jobId?.skillPreferences)
                            ? job.jobId.skillPreferences
                            : job.jobId?.skillPreferences?.split(",")
                          )?.map((tag) => tag.trim()).join(", ") || "Not Listed"}
                        </Typography>
                      </Box>

                      {/* Footer Section */}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          mt: 1,
                        }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                          <AccessTimeIcon
                            sx={{ color: "#666", fontSize: "0.9rem" }}
                          />
                          <Typography
                            sx={{
                              color: "#666",
                              fontSize: "0.85rem",
                              fontFamily: "IBM Plex Sans, sans-serif",
                              whiteSpace: "nowrap",
                            }}
                          >
                            Posted: {postedDateConvert(job.jobId?.postDate)}
                          </Typography>
                          <Typography
                            sx={{
                              color: "#666",
                              fontSize: "0.85rem",
                              fontFamily: "IBM Plex Sans, sans-serif",
                              whiteSpace: "nowrap",
                            }}
                          >
                            Applied: {postedDateConvert(job?.appliedAt)}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                );
              })
            ) : (
              <Box sx={{ textAlign: "center", py: 4 }}>
                <Typography variant="h5" component="h2" mb={2}>
                  No Applied Jobs Found
                </Typography>
                <Typography variant="body1" color="text.secondary" mb={4}>
                  You haven’t applied to any jobs yet. Start exploring and apply
                  to jobs that interest you!
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#000000",
                    borderRadius: "0.7rem",
                    textTransform: "none",
                    "&:hover": { backgroundColor: "#000000" },
                  }}
                  onClick={() => navigate("/findJobs")}
                >
                  Find Jobs
                </Button>
              </Box>
            )}
          </Box>
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default JobsApplied;