import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  IconButton,
  Button,
  Container,
  CircularProgress,
  Avatar,
} from "@mui/material";
import CompanyDetails from "./CompanyForms/CompanyDetails";
import AccountDetails from "./CompanyForms/AccountDetails";
import KYCComplainsDetails from "./CompanyForms/KYCComplainsDetails";
import { editOrgLogoApi, getOrgDetails } from "../../../api/companyProfile";
import EditIcon from "@mui/icons-material/Edit";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useOrg } from "../../../context/OrgContext";

const CompanyProfile = () => {
  const { orgData, setOrgData } = useOrg();
  const [openDialog, setOpenDialog] = useState(false);
  const [openKYCDialog, setOpenKYCDialog] = useState(false);
  const [openCompanyDialog, setOpenCompanyDialog] = useState(false);
  const [logo, setLogo] = useState(""); // State for company logo
  const [previewLogo, setPreviewLogo] = useState(""); // State for previewing the selected logo
  const fileInputRef = useRef(null); // Ref for the file input element
  const token = localStorage.getItem("token");
  const orgId = localStorage.getItem("organizationId");
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const fetchOrgDetails = useCallback(async () => {
    try {
      if (orgId) {
        const companyDetails = await getOrgDetails(orgId, token);
        setOrgData(companyDetails);
        setLogo(companyDetails?.logo || ""); // Set the logo from API response
        console.log("orgdata fetchted")
      } else {
        throw new Error("No organization id");
      }
    } catch (error) {
      console.log(error.message);
    }
  }, [orgId, token, setOrgData]); // Dependencies of useCallback



  useEffect(() => {
    fetchOrgDetails(); 
  }, [fetchOrgDetails]); 

  // Handle logo edit
  const handleLogoEdit = () => {
    fileInputRef.current.click(); // Trigger the file input dialog
  };
  console.log(logo);
  // Handle logo upload
  const handleLogoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewLogo(reader.result); // Set the preview logo to the uploaded image
        setIsPreviewModalOpen(true); // Open the preview modal
      };
      reader.readAsDataURL(file); // Read the file as a data URL
    }
  };

  // Handle logo update
  const handleUpdateLogo = async () => {
    if (previewLogo) {
      setLoading(true);
      try {
        setIsPreviewModalOpen(false); // Close the preview modal
        const file = fileInputRef.current.files[0]; // Get the selected file
        const formData = new FormData();
        formData.append("companyLogo", file); // Use the key "companyLogo"

        const response = await editOrgLogoApi(orgId, token, formData);

        console.log("Logo update response:", response);

        // Fetch the updated organization details
        const updatedOrgDetails = await getOrgDetails(orgId, token);
        setOrgData(updatedOrgDetails); // Update the orgData state

        setLogo(updatedOrgDetails?.companyLogo?.url || ""); // Update the logo in the UI
        setPreviewLogo(""); // Clear the preview
      } catch (error) {
        console.error("Failed to update logo:", error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  // account details state,functions
  const handleEdit = () => {
    setOpenDialog(true); //open dialog box
  };
  const handleCloseDialog = () => {
    setOpenDialog(false); //close dialog box
  };

  const handleSaveDialog = () => {
    setOpenDialog(false);
  };

  // company details state,functions
  const handleCompanyEdit = () => {
    setOpenCompanyDialog(true); //edit company details
  };
  const handleCompanyDialogClose = () => {
    setOpenCompanyDialog(false); //close company details
  };

  const handleSaveCompanyDetails = () => {
    setOpenCompanyDialog(false);
  };

  // KYC details states,functions
  const handleKYCEdit = () => {
    setOpenKYCDialog(true); //open KYC Edit
  };

  const handleKYCDialogClose = () => {
    setOpenKYCDialog(false); // close KYC edit
  };

  const handleBack = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <>
      <Container>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "starts",
            justifyContent: "start",
            mb: 2,
          }}
        >
          <Typography
            onClick={handleBack}
            gutterBottom
            sx={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              mb: "15px",
              color: "grey", // Adjust color for better visibility
              "&:hover": {
                color: "primary.dark", // Hover effect
              },
            }}
          >
            <ArrowBackIcon />
          </Typography>
          <Typography variant="h6" sx={{ opacity: 0.6, fontFamily: "poppins" }}>
            Company Profile
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h4"
              sx={{ fontFamily: "poppins", fontWeight: "bold" }}
            >
              {orgData?.organizationName}
            </Typography>
            <Box
              sx={{
                position: "relative",
                cursor: "pointer",
                mr: 3,
                "&:hover .edit-icon": {
                  opacity: 1,
                },
              }}
            >
              {loading ? (
                <Avatar
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // Full viewport height to center vertically
                  }}
                >
                  <CircularProgress
                    size={24}
                    sx={{ color: "#000" }}
                    thickness={6}
                  />{" "}
                  {/* Increase thickness here */}
                </Avatar>
              ) : (
                <img
                  src={orgData?.companyLogo?.url} // Use the uploaded logo or a default logo
                  alt="Company Logo"
                  style={{
                    width: "80px",
                    height: "80px",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                />
              )}
              {!loading && (
                <IconButton
                  className="edit-icon"
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    backgroundColor: "#666",
                    opacity: 1,
                    transition: "opacity 0.3s",
                    "&:hover": {
                      backgroundColor: "#666",
                    },
                  }}
                  onClick={handleLogoEdit} // Trigger file input on icon click
                >
                  <EditIcon fontSize="small" sx={{ color: "#fff" }} />
                </IconButton>
              )}
            </Box>
          </Box>
          {/* Hidden file input */}
          <input
            type="file"
            accept="image/*"
            ref={fileInputRef}
            style={{ display: "none" }} // Hide the file input
            onChange={handleLogoUpload}
          />
          {/* Preview Modal */}
          {isPreviewModalOpen && (
            <Box
              sx={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
                backdropFilter: "blur(5px)", // Blur effect
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1300, // Ensure it's above everything
              }}
            >
              <Box
                sx={{
                  backgroundColor: "white",
                  borderRadius: "8px",
                  padding: "24px",
                  textAlign: "center",
                  maxWidth: "300px",
                }}
              >
                <Typography variant="h6" sx={{ mb: 2, fontFamily: "poppins" }}>
                  Preview
                </Typography>
                <img
                  src={previewLogo}
                  alt="Preview Logo"
                  style={{
                    width: "100%",
                    height: "auto",
                    borderRadius: "8px",
                    objectFit: "cover",
                  }}
                />
                <Box
                  sx={{
                    mt: 3,
                    display: "flex",
                    justifyContent: "center",
                    gap: 2,
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={() => setIsPreviewModalOpen(false)}
                    sx={{
                      backgroundColor: "#000000",
                      fontFamily: "poppins",
                      textTransform: "none",
                      borderRadius: "0.7rem",
                      ":hover": {
                        backgroundColor: "#000000",
                      },
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleUpdateLogo}
                    sx={{
                      backgroundColor: "#000000",
                      fontFamily: "poppins",
                      textTransform: "none",
                      borderRadius: "0.7rem",
                      ":hover": {
                        backgroundColor: "#000000",
                      },
                    }}
                  >
                    Upload Logo
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </Box>

        <AccountDetails
          openDialog={openDialog}
          handleCloseDialog={handleCloseDialog}
          handleSaveDialog={handleSaveDialog}
          handleEdit={handleEdit}
          orgData={orgData}
        />
        <CompanyDetails
          openCompanyDialog={openCompanyDialog}
          handleCompanyDialogClose={handleCompanyDialogClose}
          handleSaveCompanyDetails={handleSaveCompanyDetails}
          handleCompanyEdit={handleCompanyEdit}
          orgData={orgData}
          fetchOrgDetails={fetchOrgDetails}
        />
        <KYCComplainsDetails
          openKYCDialog={openKYCDialog}
          handleKYCDialogClose={handleKYCDialogClose}
          handleSaveDialog={handleSaveDialog}
          handleKYCEdit={handleKYCEdit}
          orgData={orgData}
        />
      </Container>
    </>
  );
};

export default CompanyProfile;
