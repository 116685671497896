import React from "react";
import { Box, Typography, Avatar } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { truncateText } from "../../../utils/helperFunctions/Format";
import { useNavigate } from "react-router-dom";

const NextArrow = ({ className, style, onClick }) => (
  <ArrowForwardIcon
    className={className}
    style={style}
    onClick={onClick}
    sx={{
      fontSize: "2rem",
      color: "#052341FF",
      cursor: "pointer",
      ":hover": { color: "#59A3EDFF" },
      transform: "translateY(-100%)",
      zIndex: 1,
    }}
  />
);

const PrevArrow = ({ className, style, onClick }) => (
  <ArrowBackIcon
    className={className}
    style={style}
    onClick={onClick}
    sx={{
      fontSize: "2rem",
      color: "#052341FF",
      cursor: "pointer",
      ":hover": { color: "#59A3EDFF" },
      transform: "translateY(-100%)",
      zIndex: 1,
    }}
  />
);

const VideoShortsScroll = ({ videos }) => {
  const navigate = useNavigate();

  const calculateSlidesToShow = () => {
    if (videos.length <= 1) return 1;
    if (videos.length <= 3) return videos.length;
    return 5;
  };

  console.log("videos", videos);

  const settings = {
    dots: false,
    infinite: videos.length > 1,
    speed: 500,
    slidesToShow: calculateSlidesToShow(),
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: Math.min(4, calculateSlidesToShow()),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: Math.min(3, calculateSlidesToShow()),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: Math.min(2, calculateSlidesToShow()),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box
      sx={{
        width: "80vw",
        maxWidth: "1200px",
        margin: "0 auto",
        paddingInline: { xs: 2, sm: 0 },
        flexDirection: "row",
      }}
    >
      {videos.length === 0 ? (
        <Typography
          variant="h6"
          sx={{ textAlign: "center", color: "#6B7280", mt: 4 }}
        >
          No videos found
        </Typography>
      ) : (
        <Slider {...settings}>
          {videos.map((item, index) => (
            <Box
              key={index}
              sx={{
                padding: "10px",
             
                textAlign: "center",
                width: "100%",
                cursor: "pointer",
              }}
              onClick={() =>
                navigate(`/JobSeekerFilter/job/${item?.fullJobDetails?._id}`)
              }
            >
              <Box
                sx={{
                  position: "relative",
                  height: "280px",
                  width: "200px",
                  background: "#f0eeff",
                  border:"1px solid",
                  borderRadius: "10px",
                  margin: "0 auto",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                {/* Centered Avatar */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    flex: 1,
                    mb: 6,
                    width: "100%",
                  }}
                >
                  <Avatar
                    src={
                      item?.fullJobDetails?.forConsultantCompany
                        ? item?.fullJobDetails?.consultantCompanyLogo?.url ||
                          item?.fullJobDetails?.organizationDetails?.companyLogo
                            ?.url
                        : item?.fullJobDetails?.organizationDetails?.companyLogo
                            ?.url || "/placeholder.svg"
                    }
                    alt={
                      item?.organizationDetails?.organizationName || "Company"
                    }
                    sx={{ width: 100, height: 100 }}
                  />
                </Box>

                {/* Bottom Left Details Box */}
                <Box
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    // border:"1px solid",
                    alignItems: "start",
                    background: "#f0eeff",
                    color: "white",
                    p: 1,
                    borderRadius: "0 0 10px 10px",
                    zIndex: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex", // Makes the box wrap around content
                      width: "150px", // Prevents overflow
                      fontFamily: "poppins",
                      border: "1px solid #000",
                      borderRadius: "0.6rem",
                      px: "10px",
                      mb: 1,
                      whiteSpace: "normal",
                      alignItems:"center",
                      justifyContent:"center",
                      wordBreak: "break-word",
                      textAlign: "start",
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: "poppins",
                        color:"#000",
                        display: "inline", // Helps with proper text wrapping
                      }}
                    >
                      {truncateText(item?.fullJobDetails?.jobTitle, 15)}
                      <br />
                      {truncateText(
                        item?.fullJobDetails?.forConsultantCompany
                          ? item?.fullJobDetails?.consultantOrgName
                          : item?.fullJobDetails?.orgName,
                        13
                      )}
                    </Typography>
                  </Box>

                  <Typography
                    variant="caption"
                    sx={{ fontFamily: "poppins", display: "block" ,color:"#000"}}
                    textAlign="start"
                  >
                    {truncateText(
                      item?.fullJobDetails?.jobLocations
                        ?.map((loc) => loc.location)
                        .join(", "),
                      20
                    )}
                  </Typography>

                  {item?.fullJobDetails?.ctcMin &&
                    item?.fullJobDetails?.ctcMax && (
                      <Typography
                        variant="caption"
                        sx={{ display: "block", fontFamily: "poppins" ,color:"#000"}}
                        textAlign="start"
                      >
                        {`${String(item.fullJobDetails.ctcMin).replace(
                          /\s*LPA\s*/gi,
                          ""
                        )} - ${String(item.fullJobDetails.ctcMax).replace(
                          /\s*LPA\s*/gi,
                          ""
                        )} LPA`}
                      </Typography>
                    )}
                </Box>
              </Box>
            </Box>
          ))}
        </Slider>
      )}
    </Box>
  );
};

export default VideoShortsScroll;
