import baseURL from "./baseUrl";


export const sendInviteApi = async (token, formValues) => {
  try {
    const response = await fetch(`${baseURL}/sendInvite`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formValues),
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || "Error While Processing Invites");
    }

    if (data.invites.length > 0) {
     console.log(data.message)
    } else if (data.errors.length > 0) {
      data.errors.forEach((error) => {
      throw error
      });
    }

    return data;
  } catch (error) {
    throw error;
  }
};

export const removedShortlistedApi = async (jobId, token, formValues) => {
  try {
    const response = await fetch(`${baseURL}/remove_shortlist/${jobId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(formValues),
    });
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message || "Failed to shortlist the candidate");
    }


    return data;
  } catch (error) {

    console.error("Error:", error);
    throw error;
  }
};


export const rejectCandidateApi = async (jobId, token,candidateId) => {
  try {
    const response = await fetch(`${baseURL}/applicants/${jobId}/${candidateId}/reject`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    
    });
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message || "Failed to shortlist the candidate");
    }


    return data;
  } catch (error) {

    console.error("Error:", error);
    throw error;
  }
};

