import React from "react";
import { Box, Button, Typography } from "@mui/material";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import PsychologyIcon from "@mui/icons-material/Psychology";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";

import googlePlay from "../../../assets/images/googlePlay.png";
import appStore from "../../../assets/images/appStore.png";

const JobSeekerWhyVacanziTestimonals = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#ffffff",
        width: "100%",
        overflow: "hidden",
        px: 2,
      }}
    >
      <Typography
        variant="h3"
        sx={{
          fontWeight: "bold",
          fontFamily: "IBM Plex Sans",
          pb: 3,
          textAlign: "center",
        }}
      >
        Why Vacanzi ?
      </Typography>
      <Typography
        variant="h4"
        sx={{
          fontWeight: "bold",
          fontFamily: "IBM Plex Sans",
          pb: 3,
          textAlign: "center",
        }}
      >
        Quick & Effective
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          width: "100%",
        }}
      >
        {/* Job Seekers Section */}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            minHeight: "100%",
            width: "100%",
          }}
        >
          {/* Job Seekers Info Section */}
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              background: "linear-gradient(to right, #ffffff, #f0eeff)",
              p: { xs: 2, md: 4 },
              textAlign: "center",
              minHeight: "100%",
            }}
          >
            <Typography
              variant="h3"
              sx={{
                fontWeight: "bold",
                fontFamily: "IBM Plex Sans",
                color: "#000",
                mb: 2,
              }}
            >
              For JobSeekers
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                fontFamily: "IBM Plex Sans",
                color: "#000",
                mb: 2,
              }}
            >
              No wasted interviews, Efforts or Travel!
            </Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 2,
              }}
            >
              {[
                {
                  icon: <PsychologyIcon sx={{ color: "#000" }} />,
                  title: "Get Hired in Hours",
                  desc: "Your video speaks for you.",
                },
                {
                  icon: <RocketLaunchIcon sx={{ color: "#000" }} />,
                  title: "Stand Out Instantly",
                  desc: "Catch recruiter attention fast.",
                },
                {
                  icon: <BusinessCenterIcon sx={{ color: "#000" }} />,
                  title: "Apply in One Tap",
                  desc: "Land your dream job, hassle-free.",
                },
              ].map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.75rem",
                    }}
                  >
                    {item.icon}
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "bold",
                        fontFamily: "IBM Plex Sans",
                        color: "#000",
                      }}
                    >
                      {item.title}
                    </Typography>
                  </Box>
                  <Typography
                    variant="body1"
                    sx={{ fontFamily: "IBM Plex Sans", color: "#000" }}
                  >
                    {item.desc}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>

          {/* Image & Download Section */}
        
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              background: "linear-gradient(to right, #E3FFF9FF, #ffffff)",
              p: { xs: 2, md: 4 },
              textAlign: "center",
              minHeight: "100%",
            }}
          >
            {/* Enlarged Mobile View Image */}
            <img
              src={"./twomobile.webp"}
              alt="Mobile view"
              style={{ width: "90%", maxWidth: "350px", height: "auto" }} // Increased width
            />

            <Button
              variant="contained"
              sx={{
                borderRadius: "0.7rem",
                textTransform: "none",
                fontFamily: "IBM Plex Sans",
                backgroundColor: "#000",
                mt: 2,
                "&:hover": { backgroundColor: "#000" },
              }}
            >
              Download The App Now
            </Button>

            {/* Enlarged Store Buttons */}
            <Box
              sx={{
                display: "flex",
                gap: 2,
                justifyContent: "center",
                alignItems: "center",
                mt: 2,
              }}
            >
              <img src={appStore} alt="App Store" style={{ width: "150px" }} />{" "}
              {/* Enlarged */}
              <img
                src={googlePlay}
                alt="Google Play"
                style={{ width: "150px" }}
              />{" "}
              {/* Enlarged */}
            </Box>

            {/* Enlarged QR Code */}
            <img
              src={"./qrJobseeker.png"}
              alt="QR Code"
              style={{ width: "100px", height: "100px", marginTop: "1rem" }} // Increased size
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default JobSeekerWhyVacanziTestimonals;
