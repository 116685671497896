import React, { useEffect, useState } from "react";
import { Box} from "@mui/system";
import {
  Button,
  Card,
  CardContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { formatDate } from "../../utils/helperFunctions/Format";
import { getOrgWalletApi } from "../../api/payment";
import PaymentDialog from "../../components/AdvancedComponents/PaymentModel";

const styles = {
  card: {
    marginBottom: "20px",
    border: "1px solid",
    borderColor: "grey.300",
    borderRadius: "0.8rem",
  },
  walletInfo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  amount: {
    fontSize: "2rem",
    fontWeight: "bold",
    fontFamily: "poppins",
  },
  addButton: {
    marginLeft: "10px",
    fontFamily: "poppins",
  },
  table: {
    minWidth: 650,
  },
  tableHeader: {
    backgroundColor: "#f5f5f5",
    fontFamily: "poppins",
  },
};

const EmployerTransactions = () => {
  const [wallet, setWallet] = useState();
  const [openPayDialog, setOpenPayDialog] = useState(false);
  const orgId = localStorage.getItem("organizationId");
  const token = localStorage.getItem("token");

  const handleOpenPayMenu = () => {
    setOpenPayDialog(true);
  };
  const handlePayMenuClose = () => {
    setOpenPayDialog(false);
  };
  useEffect(() => {
    const getOrgWallet = async () => {
      try {
        const response = await getOrgWalletApi(orgId, token);
        setWallet(response);
      } catch (error) {
        console.log(error.message);
      }
    };
    getOrgWallet();
  }, [orgId, token]);
  console.log("wallet", wallet);
  return (
    <Box sx={{ maxWidth: 800, margin: "0 auto", padding: "20px" }}>
      <Card
        sx={{
          ...styles.card,

          position: "sticky", // Make the card sticky
          top: 64, // Stick to the top of the page
          zIndex: 1, // Ensure it stays above other content
          backgroundColor: "#fff", // Add background color to ensure it stays visible
        }}
        elevation={0}
      >
        <CardContent>
          <Box sx={styles.walletInfo}>
            <Typography
              variant="h5"
              component="div"
              sx={{ fontFamily: "poppins" }}
            >
              Wallet Balance
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 2,
              }}
            >
              <Typography variant="h4" component="span" sx={styles.amount}>
                ₹ {wallet?.balance}
              </Typography>
              <Button
                  onClick={handleOpenPayMenu}
                variant="contained"
                sx={{
                  textTransform: "none",
                  backgroundColor: "#000000",
                  color: "#ffffff",
                  fontFamily: "poppins",
                  ":hover": {
                    backgroundColor: "#000000",
                    color: "#ffffff",
                  },
                  borderRadius: "0.7rem",
                }}
              >
                Add Money
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>

      <TableContainer
        component={Paper}
        sx={{
          maxHeight: 400,
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            display: "none", // Hide the scrollbar
          },
        }}
      >
        <Table sx={styles.table} aria-label="transaction table">
          <TableHead
            sx={{
              position: "sticky",
              top: 0,
              fontFamily: "poppins",
              backgroundColor: "#fff",
              zIndex: 1,
            }}
          >
            <TableRow sx={styles.tableHeader}>
              <TableCell sx={{ fontFamily: "poppins" }}>Date</TableCell>
              <TableCell sx={{ fontFamily: "poppins" }}>Description</TableCell>
              <TableCell align="right" sx={{ fontFamily: "poppins" }}>
                Amount
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {wallet?.transactions?.map((transaction) => (
              <TableRow key={transaction.id}>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ fontFamily: "poppins" }}
                >
                  {formatDate(transaction.date)}
                </TableCell>
                <TableCell sx={{ fontFamily: "poppins" }}>
                  {transaction.description}
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    fontFamily: "poppins",
                    color: transaction.type === "credit" ? "green" : "red",
                  }}
                >
                  ₹{Math.abs(transaction.amount).toFixed(2)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <PaymentDialog
        openPayDialog={openPayDialog}
        handlePayMenuClose={handlePayMenuClose}
      />
    </Box>
  );
};

export default EmployerTransactions;
