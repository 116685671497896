import { Box, Typography, styled, Tooltip } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import StarIcon from "@mui/icons-material/Star"; // For star rating
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { allTestimonialsApi } from "../../api/jobseeker";
import { useEffect } from "react";
import { useState } from "react";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import StarBorderIcon from "@mui/icons-material/StarBorder";

const StyledSwiper = styled(Swiper)({
  padding: "8px",
  "& .swiper-button-next, & .swiper-button-prev": {
    color: "white",
    width: "40px",
    "&::after": {
      display: "none",
    },
  },
});

const Testimonials = () => {
  const [testimonials, setTestimonials] = useState();
  useEffect(() => {
    const getAllTestimonials = async () => {
      try {
        const response = await allTestimonialsApi();
        setTestimonials(response);
      } catch (error) {
        console.log(error.message);
      }
    };
    getAllTestimonials();
  }, []);

  return (
    <Box sx={{ backgroundColor: "#EAFBF4FF", width: "100vw" }}>
      <Typography
        variant="h3"
        sx={{
          fontFamily: "IBM Plex Sans",
          textAlign: "center",
          padding: "20px",
          fontWeight: "bold",
          color: "#03030CFF",
        }}
      >
        Testimonials
      </Typography>
      <Box sx={{ py: 6, px: 4 }}>
        <StyledSwiper
          modules={[Navigation, Autoplay]}
          navigation={{
            prevEl: ".swiper-button-prev",
          }}
          slidesPerView={3} // Display only 3 slides at a time
          loop={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          spaceBetween={30} // Keep spacing consistent
        >
          {testimonials?.testimonials?.map((user, index) => {
            const fullStars = Math.floor(user.rating);
            const hasHalfStar = user.rating % 1 >= 0.5;
            const totalStars = 5;

            return (
              <SwiperSlide key={index}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "16px",
                    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "white",
                    p: 3,
                    textAlign: "start",
                    height: "220px", // Adjusted height for better alignment
                    justifyContent: "space-between",
                  }}
                >
                  {/* Profile Section */}
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <img
                      src="/slider7.png"
                      alt="Profile"
                      style={{
                        width: "70px",
                        height: "70px",
                        borderRadius: "1rem",
                        marginRight: "10px",
                      }}
                    />
                    <Box>
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: "bold",
                          fontSize: "15px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {user?.userId?.fullName}
                      </Typography>
                      <Tooltip title={user.designation} arrow>
                        <Typography
                          variant="h6"
                          sx={{
                            color: "gray",
                            fontSize: "13px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {user.designation}
                        </Typography>
                      </Tooltip>

                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          variant="body2"
                          sx={{ fontWeight: "bold", mr: 2 }}
                        >
                          {user.rating}
                        </Typography>
                        {[...Array(totalStars)].map((_, i) => {
                          if (i < fullStars) {
                            return (
                              <StarIcon
                                key={i}
                                sx={{ fontSize: "18px", color: "#423bfa" }}
                              />
                            );
                          } else if (i === fullStars && hasHalfStar) {
                            return (
                              <StarHalfIcon
                                key={i}
                                sx={{ fontSize: "18px", color: "#423bfa" }}
                              />
                            );
                          } else {
                            return (
                              <StarBorderIcon
                                key={i}
                                sx={{ fontSize: "18px", color: "grey" }}
                              />
                            );
                          }
                        })}
                      </Box>
                    </Box>
                  </Box>

                  {/* Testimonial */}
                  <Typography
                    variant="body2"
                    sx={{
                      color: "black",
                      fontSize: "14px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 3, // Keep it within 3 lines
                    }}
                  >
                    {user.testimonial}
                  </Typography>
                </Box>
              </SwiperSlide>
            );
          })}

          {/* Navigation Buttons */}
          <Box
            className="swiper-button-prev"
            sx={{
              left: 0,
              zIndex: 10,
              "&::after": { display: "none" },
            }}
          >
            <ArrowBackIosIcon sx={{ color: "gray", opacity: 0.6 }} />
          </Box>
          <Box
            className="swiper-button-next"
            sx={{
              right: 0,
              zIndex: 10,
              "&::after": { display: "none" },
            }}
          >
            <ArrowForwardIosIcon sx={{ color: "gray", opacity: 0.6 }} />
          </Box>
        </StyledSwiper>
      </Box>
    </Box>
  );
};

export default Testimonials;
