import React, { useState, useEffect } from "react";
import {
  Avatar,
  Button,
  Typography,
  TextField,
  Paper,
  Container,
  Grid,
  Box,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Footer from "../../../components/JobSeekerComponents/Footer/Footer";
import baseURL from "../../../api/baseUrl";
import CustomSnackbar from "../../../components/AdvancedComponents/CustomSnackbar";
import NewNavBar from "../../../components/JobSeekerComponents/NavBar/NewNavbar";
import { getUserApi } from "../../../api/jobseeker";

const JobSeekerDelete = () => {
  const [fullName, setFullName] = useState("");
  const [profilePhoto, setProfilePhoto] = useState("");
  const [reason, setReason] = useState("");
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    type: "success",
  });
  const token = localStorage.getItem("authToken");
  const userId = localStorage.getItem("userId");
  const [userData, setUserData] = useState();

  useEffect(() => {
    setFullName(localStorage.getItem("userName") || "");
    setProfilePhoto(localStorage.getItem("profileImage") || "");
  }, []);

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  useEffect(() => {
    if (token) {
      const getUserDetails = async () => {
        try {
          const response = await getUserApi(userId, token);
          setUserData(response?.user);
        } catch (error) {
          console.log(error.message);
        }
      };
      getUserDetails();
    }
  }, [token, userId]);

  const handleDeleteAccount = async () => {
    const email = userData?.email;

    const authToken = localStorage.getItem("authToken");
    if (!authToken) {
      setSnackbar({
        open: true,
        message: "User not authenticated. Please log in.",
        type: "error",
      });
      return;
    }

    try {
      const response = await fetch(`${baseURL}/deleteAccount`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({ fullName, email, reason }),
      });

      if (!response.ok) throw new Error("Failed to delete account");
      const result = await response.json();
      setSnackbar({
        open: true,
        message:
          result?.message || "Your account deletion request has been received.",
        type: "success",
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Failed to delete account. Please try again.",
        type: "error",
      });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh", // Ensures the page takes up full viewport height
      }}
    >
      <NewNavBar />
      <Container
        maxWidth="sm"
        sx={{
          flex: "1 0 auto", // Allows the container to grow and push the footer down
          display: "flex",
          alignItems: "center",
          mt:10,
          justifyContent: "center",
        }}
      >
        <Paper
          elevation={4}
          sx={{
            p: 4,
            borderRadius: "1rem",
            maxWidth: 500,
            backgroundColor: "#fff",
          }}
        >
          <Typography
            textAlign="center"
            variant="h5"
            sx={{ mb: 3, fontWeight: 700, color: "#000" }}
          >
            Delete Account
          </Typography>
          <Avatar
            src={profilePhoto}
            alt={fullName}
            sx={{
              width: 80,
              height: 80,
              mx: "auto",
              mb: 2,
              border: "2px solid #000",
            }}
          />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 500,
                  fontFamily: "IBM Plex Sans",
                  marginBottom: 1,
                }}
              >
                Full Name
                <Typography
                  component="span"
                  sx={{ color: "red", marginLeft: 0.5 }}
                >
                  *
                </Typography>
              </Typography>
              <TextField
                disabled={userData?.fullName}
                fullWidth
                size="small"
                value={userData?.fullName || ""}
                sx={{
                  mt: 1,
                  backgroundColor: "#f5f5f5",
                  borderRadius: "0.5rem",
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                Email
              </Typography>
              <TextField
                disabled={userData?.email}
                fullWidth
                size="small"
                value={userData?.email || ""}
                sx={{
                  mt: 1,
                  backgroundColor: "#f5f5f5",
                  borderRadius: "0.5rem",
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                Reason for Deletion (Optional)
              </Typography>
              <TextField
                multiline
                rows={3}
                fullWidth
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                sx={{ mt: 1, borderRadius: "0.5rem" }}
                placeholder="Provide a reason for deleting your account"
              />
            </Grid>
          </Grid>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#000",
              color: "#fff",
              textTransform: "none",
              fontWeight: 600,
              padding: "0.8rem 2rem",
              borderRadius: "0.8rem",
              fontSize: "1rem",
              mt: 3,
              fontFamily: "IBM Plex Sans, sans-serif",
              "&:hover": {
                backgroundColor: "#333", // Slightly lighter black on hover
              },
            }}
            startIcon={<DeleteIcon />}
            onClick={handleDeleteAccount}
            fullWidth
          >
            Delete Your Account
          </Button>
        </Paper>
      </Container>
      <Box sx={{ mt:10}}>
      <Footer sx={{ flexShrink: 0 }} /> {/* Ensures footer stays at the bottom */}
      </Box>
      <CustomSnackbar
        open={snackbar.open}
        message={snackbar.message}
        type={snackbar.type}
        handleClose={handleSnackbarClose}
      />
    </div>
  );
};

export default JobSeekerDelete;