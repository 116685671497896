import React, { lazy, useEffect, useState } from "react";
import {
  Tabs,
  Tab,
  Box,
  Typography,
  Paper,
  CircularProgress,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { getJobByIdApi } from "../../../api/jobPostAPI";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AppliedCandidates from "./AppliedCandidates";  
import ViewedCandidates from "./ViewedCandidates";
import InvitedCandidates from "./InvitedCandidates";
import ShortlistedCandidates from "./ShortlistedCandidates";
import savedSearchStyles from "../../../styles/savedSearcheStyles";
import { useCallback } from "react";
import { Suspense } from "react";
import JobPostSkeleton from "../../../utils/lazy/JobPostSkeleton";
const EmployerEditJob = lazy(() => import("./EmployerEditJob"));
const EmployerPostedJobDetails = () => {
  const { jobId } = useParams();
  const [jobData, setJobData] = useState();
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  const [tabValue, setTabValue] = useState(0); // State for managing active tab
  const navigate = useNavigate(); // Hook for navigation

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue); // Update state when tab changes
  };

  const getJobDetails = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getJobByIdApi(jobId, token);
      setJobData(response.job);
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  }, [jobId, token]);

  useEffect(() => {
    getJobDetails();
  }, [getJobDetails]);
  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      {loading ? (
        <Box sx={{ ...savedSearchStyles.parentBox, textAlign: "start" }}>
          <CircularProgress size={40} sx={{ mb: 2 }} />
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "start",
            minHeight: "100vh",
         
            padding: "20px",
          }}
        >
                <Typography
        onClick={handleBack}
        gutterBottom
        sx={{
          cursor: "pointer",
          display: "flex",
          mr: 4,
          alignItems: "center",
          color: "grey",
          "&:hover": {
            color: "primary.dark",
          },
        }}
      >
        <ArrowBackIcon sx={{ marginRight: "4px" }} />
      </Typography>
          <Paper
            elevation={0}
            sx={{
              width: "90%",
              padding: "20px",
              borderRadius: "8px",
            }}
          >
            <Typography
              variant="h4"
              gutterBottom
              sx={{ fontWeight: "bold", color: "#000", fontFamily: "poppins" }}
            >
              {jobData?.jobTitle}
            </Typography>
            <>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="job details tabs"
                sx={{
                  borderBottom: 1,
                  marginBottom: 2,
                  borderColor: "divider",
                  "& .MuiTab-root": {
                    color: "#000",
                    fontFamily: "Poppins, sans-serif",
                  },
                  "& .Mui-selected": {
                    color: "#423bfa",
                  },
                }}
              >
                <Tab
                  label="Edit Job"
                  id="tab-0"
                  sx={{ textTransform: "none", fontFamily: "poppins" }}
                />
                <Tab
                  label="Viewed Candidates"
                  id="tab-1"
                  sx={{ textTransform: "none", fontFamily: "poppins" }}
                />
                <Tab
                  label="Applications"
                  id="tab-2"
                  sx={{ textTransform: "none", fontFamily: "poppins" }}
                />
                <Tab
                  label="Invites Details"
                  id="tab-3"
                  sx={{ textTransform: "none", fontFamily: "poppins" }}
                />
                <Tab
                  label="ShortListed"
                  id="tab-4"
                  sx={{ textTransform: "none", fontFamily: "poppins" }}
                />
              </Tabs>

              {/* Tab Panels */}
              {tabValue === 0 && (
                <Suspense fallback={<JobPostSkeleton />}>
                  <EmployerEditJob
                    jobData={jobData}
                    setJobData={setJobData}
                    setTabValue={setTabValue}
                    getJobDetails={getJobDetails}
                  />
                </Suspense>
              )}
              {tabValue === 1 && <ViewedCandidates jobData={jobData} />}
              {tabValue === 2 && (
                <AppliedCandidates
                  jobData={jobData}
                  setJobData={setJobData}
                  token={token}
                  jobId={jobId}
                />
              )}
              {tabValue === 3 && (
                <InvitedCandidates
                  jobData={jobData}
                  getJobDetails={getJobDetails}
                  setTabValue={setTabValue}
                />
              )}
              {tabValue === 4 && (
                <ShortlistedCandidates
                  jobData={jobData}
                  token={token}
                  setJobData={setJobData}
                  jobId={jobId}
                />
              )}
            </>
          </Paper>
        </Box>
      )}
    </>
  );
};

export default EmployerPostedJobDetails;
