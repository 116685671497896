"use client";

import { useCallback, useEffect, useState } from "react";
import {
  Box,
  Avatar,
  Typography,
  Button,
  Card,
  CardContent,
  Stack,
  Grid,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Modal,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import NewNavBar from "../../../components/JobSeekerComponents/NavBar/NewNavbar";
import { Container } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import StandOut from "../../../components/JobSeekerComponents/Footer/StandOut";
import baseURL from "../../../api/baseUrl";

import { getCunsellorsApi, getCunsellorsByIdApi } from "../../../api/common";
import { useParams } from "react-router-dom";

import SimilarProfiles from "./SimilarProfiles";
import AboutCounsellor from "./AboutCounsellor";
import ConnectChatModel from "./ConnectChatModel";
import PopupSuccess from "../../../utils/popup/PopupSuccess";
import ReleaseJobPostModel from "../../../pages/EmployerPages/SubscriptionStatus/ReleaseJobPostModel";

const styles = {
  container: {
    display: "flex",
    "::-webkit-scrollbar": {
      width: 0, // This hides the scrollbar
      display: "none",
    },
  },
  sidebar: {
    "::-webkit-scrollbar": {
      width: 0, // This hides the scrollbar
      display: "none",
    },
    width: 400,
    bgcolor: "#F0EEEEFF",
    border: "1px thin",
    borderColor: "grey.400",
    color: "#000",
    pt: 10,
    px: 4,
    height: "80%",
    position: "fixed", // Sidebar stays fixed
    right: 0, // Move sidebar to the right
    borderTopLeftRadius: "5rem",
    borderBottomLeftRadius: "5rem",
    overflow: "hidden", // Prevent scrolling in sidebar
    flexShrink: 0, // Prevents sidebar from shrinking
  },
  mainContent: {
    flex: 1,
    p: 4,
    "::-webkit-scrollbar": {
      width: 0, // This hides the scrollbar
      display: "none",
    },
    mr: "400px", // Push content to the left of the sidebar
    overflowY: "auto", // Enable scrolling only in main content
    height: "100vh",
  },
  avatar: {
    width: 120,
    height: 120,
    mb: 2,
  },
  name: {
    fontSize: "2rem",
    fontWeight: "bold",
    fontFamily: "Satoshi",
    mb: 2,
  },
  followButton: {
    textTransform: "none",
    py: 0,
    px: 2,
    width: "70%",
    borderRadius: 4,
    fontFamily: "satoshi",
    fontWeight: 600,
    mb: 2,
    mt: 10,
  },
  bio: {
    fontSize: "1rem",
    lineHeight: 1.6,
    fontFamily: "Satoshi",
    mb: 2,
  },
  contact: {
    fontSize: "1rem",
    lineHeight: 1.6,
    opacity: 0.7,
    fontFamily: "Satoshi",
    fontWeight: 600,
    mb: 2,
  },
  statsContainer: {
    display: "flex",
    gap: 1,
    mb: 3,
  },
  statBadge: {
    p: 1,
    borderRadius: 1,
    display: "flex",
    alignItems: "center",
    gap: 1,
    width: 40,
    height: 40,
  },
  filterButton: {
    bgcolor: "transparent",
    color: "black",
    borderRadius: 20,
    px: 3,
    "&.active": {
      bgcolor: "black",
      color: "white",
    },
    "&:hover": {
      bgcolor: "rgba(0,0,0,0.1)",
    },
  },
  card: {
    bgcolor: "#F0EEEEFF",
    borderRadius: 10,
    mb: 3,
    px: 1,
    boxShadow: "none",
  },
  serviceCard: {
    bgcolor: "#f8f8f8",
    borderRadius: 7,
    px: 2,
    py: 2,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    mt: 2,
  },
  priceButton: {
    borderRadius: 20,
    fontFamily: "Satoshi",
    textTransform: "none",
    bgcolor: "white",
    color: "black",
    border: "1px solid #ddd",
    "&:hover": {
      bgcolor: "rgba(0,0,0,0.05)",
    },
  },
};

const ConnectCounsellorProfile = () => {
  //   const [activeFilter, setActiveFilter] = useState("All")
  const { counsellorId } = useParams();
  const [loading, setLoading] = useState(false);
  const [counsellorData, setCounsellorData] = useState();
  const [counsellors, setCounsellors] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const authToken = localStorage.getItem("authToken");
  const userId = localStorage.getItem("userId");
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");


    const handleSuccess = useCallback((message) => {
      setSuccessMessage(message);
      setSuccessOpen(true);
      setTimeout(() => {
        setSuccessOpen(false);
        setSuccessMessage("");
      }, 2000);
    }, []);
  
    const handleError = useCallback((message) => {
      setErrorMessage(message);
      setErrorOpen(true);
      setTimeout(() => {
        setErrorOpen(false);
        setErrorMessage("");
      }, 2000);
    }, []);
  
  const handleOpenDialogBox = () => {
    setOpenDialog(true);
  };

  const handleCloseDialogBox = () => {
    setOpenDialog(false);
  };

  const handleSendAppLink = async (phoneNumber) => {
    try {
      // Retrieve authToken and userId from localStorage

      if (!authToken || !userId) {
        console.log(
          "authToken or userId is missing. Calling sendAppLinkToNewUser."
        );

        if (!phoneNumber) {
          handleError('Please enter a valid phone number.')
          
          return;
        }

        const apiUrl = `${baseURL}/sendAppLinkToNewUser`;

        // Call the API with phone number
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ phoneNumber }),
        });

        if (!response.ok) {
          throw new Error("Failed to send app link to new user.");
        }

        const data = await response.json();
        console.log("App link sent successfully to new user:", data);
        handleSuccess('App link sent successfully')
       
        return;
      }

      // Existing logic for authenticated users
      const apiUrl = `${baseURL}/sendAppLink/${userId}`;
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to send app link.");
      }

      const data = await response.json();
      console.log("App link sent successfully:", data);
      handleSuccess('App link sent successfully')
     
    } catch (error) {
      console.error("Error sending app link:", error);
      handleError('Error sending app link')
      
    }
  };

  useEffect(() => {
    setLoading(true);
    const getCounsellorProfile = async () => {
      try {
        const response = await getCunsellorsByIdApi(counsellorId);
        setCounsellorData(response);
      } catch (error) {
        console.log(error.message);
      } finally {
        setLoading(false);
      }
    };
    getCounsellorProfile();
  }, [counsellorId]);

  useEffect(() => {
    setLoading(true);
    const fetchCounsellors = async () => {
      try {
        const response = await getCunsellorsApi();
        setCounsellors(response);
      } catch (error) {
        console.log(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchCounsellors();
  }, []);

  const handleOpenChat = useCallback((profileId) => {
    setCurrentId(profileId);
    setIsModalOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
    setCurrentId(null);
  }, []);

  console.log("counsellor chat", counsellorData);

  return (
    <>
      <NewNavBar />
      {loading ? (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            zIndex: 9999, // Ensure the spinner is above other content
            backgroundColor: "#FFFFFF", // Optional: Add a semi-transparent background
          }}
        >
          <img
            src="/loading.gif"
            alt="Loading"
            style={{ width: 200, height: 200 }}
          />
        </Box>
      ) : (
        <Container maxWidth="xl">
          <Box sx={styles.container}>
            <Box sx={styles.mainContent}>
              {/* Filter Buttons */}
              {/* <ButtonGroup sx={{ mb: 4 }}>
            {["All", "1:1 Call", "Priority DM"].map((filter) => (
              <Button
                key={filter}
                sx={{
                  ...styles.filterButton,
                  ...(activeFilter === filter && { "&.active": styles.filterButton["&.active"] }),
                }}
                onClick={() => setActiveFilter(filter)}
                className={activeFilter === filter ? "active" : ""}
              >
                {filter}
              </Button>
            ))}
          </ButtonGroup> */}

              {/* Service Cards */}
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Card sx={styles.card}>
                    <CardContent>
                      <Typography
                        variant="h6"
                        sx={{ fontFamily: "Satoshi", fontWeight: "bold" }}
                      >
                        Video Call
                      </Typography>
                      <Box sx={styles.serviceCard}>
                        <Stack direction="row" spacing={2} alignItems="center">
                          <CalendarTodayIcon />
                          <Box>
                            <Typography
                              variant="subtitle1"
                              sx={{ fontFamily: "Satoshi", fontWeight: "bold" }}
                            >
                              60 mins
                            </Typography>
                            {/* <Typography
                              variant="body2"
                              color="text.secondary"
                              sx={{ fontFamily: "Satoshi" }}
                            >
                              Video Meeting
                            </Typography> */}
                          </Box>
                        </Stack>
                        <Button
                          endIcon={<ArrowForwardIcon />}
                          sx={styles.priceButton}
                          onClick={handleOpenDialogBox}
                        >
                          Free
                        </Button>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Card sx={styles.card}>
                    <CardContent>
                      <Typography
                        variant="h6"
                        sx={{ fontFamily: "Satoshi", fontWeight: "bold" }}
                      >
                        Chat Now
                      </Typography>
                      <Box sx={styles.serviceCard}>
                        <Stack direction="row" spacing={2} alignItems="center">
                          <SendIcon />
                          <Box>
                            <Typography
                              variant="subtitle1"
                              sx={{ fontFamily: "Satoshi", fontWeight: "bold" }}
                            >
                              Chat With Counsellors
                            </Typography>
                          </Box>
                        </Stack>
                        <Button
                          endIcon={<ArrowForwardIcon />}
                          sx={styles.priceButton}
                          onClick={handleOpenChat}
                          // onClick={handleOpenDialogBox}
                        >
                          Free
                        </Button>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>

                {/* Additional cards for "Ask me anything" and "Have a question?" */}
              </Grid>
              <SimilarProfiles
                counsellors={counsellors}
                counsellorId={counsellorId}
              />
              <AboutCounsellor counsellorData={counsellorData} />
            </Box>

            {/* profile section */}
            {counsellorData?.map((couns) => (
              <Box sx={{ ...styles.sidebar }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <Avatar src={couns.profileImage} sx={styles.avatar} />
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      ...styles.priceButton,
                      boxShadow: "none",
                      fontWeight: 600,
                    }}
                    onClick={handleOpenChat}
                  >
                    Chat Now
                  </Button>
                </Box>
                <Typography sx={styles.name}>{couns.fullName} </Typography>
                <Typography sx={styles.contact}>
                  {couns.email} | {couns.phone} | {couns.currentLocation}
                </Typography>
                <Typography
                  sx={{
                    ...styles.bio,
                    display: "-webkit-box",
                    overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 4,
                  }}
                >
                  {couns.bio}
                </Typography>
              </Box>
            ))}

            <Dialog
              open={openDialog}
              onClose={handleCloseDialogBox}
              sx={styles.dialog}
            >
              <DialogTitle sx={styles.dialogTitle}>
                <IconButton
                  onClick={handleCloseDialogBox}
                  sx={styles.closeButton}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <StandOut handleSendAppLink={handleSendAppLink} />
              </DialogContent>
            </Dialog>
          </Box>
        </Container>
      )}
      {isModalOpen && currentId && (
        <Modal
          open={isModalOpen}
          onClose={handleCloseModal}
          sx={{
            position: "fixed",
            bottom: 20,
            right: 20,
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <Box sx={{ width: 400, height: 500 }}>
            <ConnectChatModel
              data={counsellorData}
              currentId={counsellorId}
              onClose={handleCloseModal}
            />
          </Box>
        </Modal>
      )}
       <PopupSuccess
          modelOpen={successOpen}
          onClose={() => setSuccessOpen(false)}
          message={successMessage}
        />
        <ReleaseJobPostModel
          modelOpen={errorOpen}
          onClose={() => setErrorOpen(false)}
          message={errorMessage}
        />
    </>
  );
};

export default ConnectCounsellorProfile;
