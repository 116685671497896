import { Box, Typography } from "@mui/material";

const styles = {
  container: {
    bgcolor: "rgb(247, 245, 238)",
    borderRadius: 10,
    p: 3,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    mb: 3,
  },
  title: {
    fontSize: "2rem",
    fontFamily: "Satoshi",
    fontWeight: 600,
    color: "#000",
  },
  bio: {
    fontSize: "1.2rem",
    fontFamily: "Satoshi",
    fontWeight: 550,
    color: "#666",
  },
  headline: {
    fontSize: "1.5rem",
    fontFamily: "Satoshi",
    fontWeight: 550,  
    color: "#000",
  },

  exp: {
    fontSize: "1.2rem",
    fontFamily: "Satoshi",
    fontWeight: 550,
    color: "#000",
    mt:1,
    mb:1,
  },
  dismissButton: {
    fontSize: "0.875rem",
    color: "rgba(0, 0, 0, 0.6)",
    textTransform: "none",
    "&:hover": {
      bgcolor: "rgba(0, 0, 0, 0.04)",
    },
  },
  cardsContainer: {
    display: "flex",
    gap: 2,
    overflowX: "auto",
    pb: 1,
    "::-webkit-scrollbar": {
      width: 0, // This hides the scrollbar
      display: "none",
    },
    "::-webkit-scrollbar-track": {
      bgcolor: "rgba(0, 0, 0, 0.05)",
      borderRadius: 3,
    },
    "::-webkit-scrollbar-thumb": {
      bgcolor: "rgba(0, 0, 0, 0.2)",
      borderRadius: 3,
    },
  },
  profileCard: {
    minWidth: 240,
    cursor: "pointer",
    bgcolor: "#fff",
    borderRadius: 3,
    boxShadow: "none",
    "&:hover": {
      boxShadow: "0 2px 8px rgba(0,0,0,0.08)",
    },
  },
  cardContent: {
    fontFamily: "Satoshi",
    padding: 2.5,
    "&:last-child": {
      paddingBottom: 2.5,
    },
  },
  avatar: {
    width: 80,
    height: 80,
    mb: 2,
  },
  name: {
    fontSize: "1rem",
    fontFamily: "Satoshi",
    fontWeight: 600,
    color: "#000",
    mb: 1,
  },
  description: {
    fontSize: "0.875rem",
    fontFamily: "Satoshi",
    color: "rgba(0, 0, 0, 0.6)",
    lineHeight: 1.4,
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2, // Limits to 2 lines
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
};

const AboutCounsellor = ({ counsellorData }) => {
  
  return (
    <Box sx={styles.container}>
      {counsellorData?.map((profile) => (
        <>
        <Box sx={styles.header}>
          <Typography sx={styles.title}>About Me</Typography>
        
          {/* <Button sx={styles.dismissButton}>Dismiss</Button> */}
        </Box>
        <Typography sx={styles.headline}>{profile.headline}</Typography>
          <Typography sx={styles.exp}>Total Experience : {profile?.totalExpInYears} Years {profile?.totalExpInMonths} Months</Typography>
          <Typography sx={styles.bio}>{profile.about}</Typography>
        </>
        
      ))}
    </Box>
  );
};

export default AboutCounsellor;
