import React, { useState, useEffect, useRef } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Button,
  MenuItem,
  Avatar,
  Divider,
  Popover,
} from "@mui/material";
import {
  Menu as MenuIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from "@mui/icons-material";
import { getTopOrgs, getTopIndustryMasters } from "../../../api/common";
import { useLocation, useNavigate } from "react-router-dom";
import { useScroll } from "../../../context/ScrollContext";
import NotificationsIcon from "@mui/icons-material/Notifications";
import JobSeekerToJobSeekerSupport from "../../../pages/JobSeekerPages/JobSeekerToJobSeekerSupport/JobSeekerToJobSeekerSupport";
import { getUserApi } from "../../../api/jobseeker";

const NewNavBar = ({
  onIndustrySelect,
  isScrolled,
  setIsScrolled,
  isAfterAdvertisements,
  chatId,
  counsellorData,
}) => {
  const [mobileMenuAnchor, setMobileMenuAnchor] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const [companies, setCompanies] = useState([]);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [selectedIndustry, setSelectedIndustry] = useState(null);
  const [allIndustries, setAllIndustries] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const { scrollToWhyVacanzi } = useScroll();
  const token = localStorage.getItem("authToken");
  const userId = localStorage.getItem("userId");
  const [userData, setUserData] = useState();

  // Scroll effect for homepage
  useEffect(() => {
    if (location.pathname !== "/") return;

    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [location.pathname, setIsScrolled]);

  useEffect(() => {
    if (token) {
      const getUserDetails = async () => {
        try {
          const response = await getUserApi(userId, token);
          setUserData(response?.user);
        } catch (error) {
          console.log(error.message);
        }
      };
      getUserDetails();
    }
  }, [token, userId]);

  // Fetch companies
  useEffect(() => {
    const fetchOrgDetails = async () => {
      try {
        const response = await getTopOrgs();
        setCompanies(response?.data || []);
      } catch (error) {
        console.error("Error fetching organizations:", error.message);
      }
    };
    fetchOrgDetails();
  }, []);
  // Fetch industries
  const hasFetchedRef = useRef(false);
  useEffect(() => {
    // Ref to track if API has been called

    const fetchTopIndustryMasters = async () => {
      if (hasFetchedRef.current) return; // Skip if already fetched
      hasFetchedRef.current = true; // Mark as fetched

      try {
        const response = await getTopIndustryMasters();
        if (response) {
          const allCategories = response.reduce((acc, resItem) => {
            resItem.items.forEach((item) => {
              if (item.name) {
                acc.push({
                  name: item.name,
                  count: item.organizationCount || 0,
                });
              }
            });
            return acc;
          }, []);
          setAllIndustries(allCategories);
        }
      } catch (error) {
        console.error("Error fetching top industry masters:", error.message);
      }
    };

    fetchTopIndustryMasters();
  }, []); // Empty dependency array ensures useEffect runs only once on mount

  // Fetch user data

  // Handle scroll to "Why Vacanzi" section
  useEffect(() => {
    if (location.state?.scrollToWhyVacanzi) {
      scrollToWhyVacanzi();
    }
  }, [location.state, scrollToWhyVacanzi]);

  const handleOpenMobileMenu = (event) =>
    setMobileMenuAnchor(event.currentTarget);
  const handleCloseMobileMenu = () => setMobileMenuAnchor(null);
  const handleOpenUserMenu = (event) => setAnchorElUser(event.currentTarget);
  const handleCloseUserMenu = () => setAnchorElUser(null);

  const handleMouseOver = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMouseLeaveButton = () => {
    // Do nothing here; let handleMenuLeave handle the close
  };

  const handleNavigation = (path, industry) => {
    if (industry) setSelectedIndustry(industry);
    if (onIndustrySelect) onIndustrySelect(industry || selectedIndustry);
    navigate(path);
    setAnchorEl(null);
  };

  const handleWhyVacanziClick = () => {
    if (location.pathname === "/") {
      scrollToWhyVacanzi();
    } else {
      navigate("/", { state: { scrollToWhyVacanzi: true } });
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };

  const isActiveRoute = (path) => window.location.pathname === path;

  const truncateText = (text, maxLength = 20) =>
    text?.includes("@") && text.length > maxLength
      ? text.slice(0, maxLength) + "..."
      : text;

  return (
    <>
      <AppBar
        position="sticky"
        sx={{
          backgroundColor: isAfterAdvertisements
            ? "rgba(255, 255, 255, 1)"
            : isScrolled
            ? "rgba(32, 32, 32, 0.8)"
            : "rgba(255, 255, 255, 1)",
          backdropFilter: "blur(10px)",
          top: 0,
          zIndex: 1000,
          transition: "all 0.3s ease",
          boxShadow: isScrolled ? "0px 2px 4px rgba(0, 0, 0, 0.1)" : "none",
          padding: "8px 0",
        }}
      >
        <Container maxWidth="xl">
          <Toolbar>
            {/* Logo */}
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                display: "flex",
                alignItems: "center",
                flexGrow: 1,
                fontWeight: 700,
                color: "inherit",
                textDecoration: "none",
                gap: "2px",
              }}
            >
              <img
                src="/vacanziLogo.png"
                alt="Vacanzi Logo"
                style={{
                  width: 45,
                  height: 45,
                  display: { xs: "none", md: "flex" },
                }}
              />
              <span
                style={{
                  color: isAfterAdvertisements
                    ? "#423bfa"
                    : isScrolled
                    ? "#ffffff"
                    : "#423bf9",
                  fontSize: "42px",
                  fontFamily: "GlanceSansBlack",
                }}
              >
                Vacanzi
              </span>
            </Typography>

            {/* Mobile Menu */}
            <Box
              sx={{
                display: { xs: "flex", md: "none" },
                justifyContent: "center",
                alignItems: "center", // Ensure vertical alignment
                flexGrow: 1,
              }}
            >
              <IconButton
                size="large"
                onClick={handleOpenMobileMenu}
                sx={{
                  backgroundColor: isAfterAdvertisements
                    ? "rgba(255, 255, 255, 1)"
                    : isScrolled
                    ? "rgba(32, 32, 32, 0.8)"
                    : "rgba(255, 255, 255, 1)",
                  borderRadius: "8px", // Rounded edges for better UI
                }}
              >
                <MenuIcon />
              </IconButton>

              <Menu
                anchorEl={mobileMenuAnchor}
                open={Boolean(mobileMenuAnchor)}
                onClose={handleCloseMobileMenu}
                anchorOrigin={{
                  vertical: "bottom", // Align menu at the top
                  horizontal: "center", // Center it horizontally
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                sx={{
                  display: { xs: "block", md: "none" },
                  mt: 1, // Adjust margin for better spacing
                }}
              >
                <MenuItem onClick={() => handleNavigation("/organizations")}>
                  Companies
                </MenuItem>
                <MenuItem onClick={() => handleNavigation("/findJobs")}>
                  Find Jobs
                </MenuItem>
                <MenuItem onClick={handleWhyVacanziClick}>Why Vacanzi</MenuItem>
                {token && [
                  <MenuItem
                    key="applied-jobs"
                    onClick={() => handleNavigation("/jobSeekerAppliedJobs")}
                  >
                    Applied Jobs
                  </MenuItem>,
                  <MenuItem
                    key="saved-jobs"
                    onClick={() => handleNavigation("/jobSeekerSavedJobs")}
                  >
                    Saved Jobs
                  </MenuItem>,
                ]}
              </Menu>
            </Box>

            {/* Desktop Navigation */}
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                gap: 2,
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#ffffff",
                  borderRadius: "12px",
                  padding: "4px 16px",
                  display: "flex",
                  alignItems: "center",
                  gap: "16px",
                  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                  border: "1px solid #C6C6C8FF",
                  position: "relative",
                }}
              >
                {/* Wrap Companies button in a Box to handle hover state */}
                <Box
                  sx={{ position: "relative" }}
                  onMouseEnter={() => setAnchorEl(true)} // Open dropdown on hover
                  onMouseLeave={() => setAnchorEl(false)} // Close dropdown when leaving
                >
                  <Button
                    onMouseEnter={handleMouseOver}
                    onMouseLeave={handleMouseLeaveButton}
                    endIcon={<KeyboardArrowDownIcon />}
                    sx={{
                      textTransform: "none",
                      color: "#000000",
                      fontSize: {
                        xs: "14px",
                        md: "14px",
                        lg: "14px",
                        xl: "19px",
                      },
                      "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.05)" },
                    }}
                  >
                    Companies
                  </Button>

                  {/* Companies Dropdown using div */}
                  {anchorEl && (
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                        position: "absolute",
                        top: "100%", // Position below the button
                        left: 0,
                        backgroundColor: "#ffffff",
                        borderRadius: 4,
                        padding: 2,
                        marginTop: "3px",
                        minWidth: 650,
                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                        zIndex: 1000,
                        opacity: anchorEl ? 1 : 0, // Fade effect
                        transform: anchorEl
                          ? "translateY(0)"
                          : "translateY(-10px)", // Slide effect
                        transition:
                          "opacity 200ms ease-in-out, transform 200ms ease-in-out", // Smooth transition
                        pointerEvents: anchorEl ? "auto" : "none", // Prevent interaction when hidden
                      }}
                      onMouseEnter={() => setAnchorEl(true)} // Keep open when hovering over dropdown
                      onMouseLeave={() => setAnchorEl(false)} // Close when leaving dropdown
                    >
                      <Box sx={{ flex: 1 }}>
                        <Box sx={{ paddingY: 0.5 }}>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: "0.875rem",
                              color: "#000",
                              fontFamily: "satoshi",
                            }}
                          >
                            Industries
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 0.5,
                          }}
                        >
                          {allIndustries?.length > 0 ? (
                            allIndustries.map((industry, index) => (
                              <Box
                                key={index}
                                sx={{
                                  paddingY: 0.5,
                                  paddingX: 2,
                                  cursor: "pointer",
                                  "&:hover": {
                                    backgroundColor: "rgba(0, 0, 0, 0.05)",
                                  },
                                  position: "relative",
                                  color: "#000",
                                }}
                                onClick={() =>
                                  handleNavigation(
                                    `/organizations-by-industry?industry=${industry?.name}`,
                                    industry?.name
                                  )
                                }
                              >
                                <Typography
                                  variant="body2"
                                  sx={{
                                    fontSize: "0.875rem",
                                    color: "#000",
                                    fontFamily: "satoshi",
                                    fontWeight: 400,
                                    ":hover": {
                                      fontWeight: 600,
                                    },
                                  }}
                                >
                                  {industry?.name || "Unnamed Industry"}
                                </Typography>
                                {isActiveRoute(
                                  `/organizations-by-industry?industry=${industry?.name}`
                                ) && (
                                  <Box
                                    sx={{
                                      position: "absolute",
                                      bottom: 0,
                                      left: 0,
                                      right: 0,
                                      height: 2,
                                      backgroundColor: "#423BFA",
                                      borderRadius: 2,
                                    }}
                                  />
                                )}
                              </Box>
                            ))
                          ) : (
                            <Typography
                              sx={{
                                paddingX: 2,
                                fontSize: "0.875rem",
                                color: "#000",
                                fontFamily: "satoshi",
                              }}
                            >
                              No industries available
                            </Typography>
                          )}
                        </Box>
                      </Box>
                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={{ mx: 0.5 }}
                      />
                      <Box sx={{ flex: 1 }}>
                        <Box sx={{ paddingY: 0.5 }}>
                          <Typography
                            variant="subtitle2"
                            sx={{
                              fontWeight: "bold",
                              fontSize: "0.875rem",
                              color: "#000",
                              fontFamily: "satoshi",
                            }}
                          >
                            Explore Companies
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 0.5,
                          }}
                        >
                          {companies?.length > 0 ? (
                            companies.slice(0, 5).map((company, index) => (
                              <Box
                                key={index}
                                sx={{
                                  paddingY: 0.5,
                                  paddingX: 2,
                                  cursor: "pointer",
                                  "&:hover": {
                                    backgroundColor: "rgba(0, 0, 0, 0.05)",
                                  },
                                  position: "relative",
                                  color: "#000",
                                }}
                                onClick={() =>
                                  handleNavigation(
                                    `/organizationOverview?companyId=${company?._id}`
                                  )
                                }
                              >
                                <Typography
                                  variant="body2"
                                  sx={{
                                    fontSize: "0.875rem",
                                    color: "#000",
                                    fontFamily: "satoshi",
                                    fontWeight: 400,
                                    ":hover": {
                                      fontWeight: 600,
                                    },
                                  }}
                                >
                                  {company?.organizationName ||
                                    "Unnamed Company"}
                                </Typography>
                                {isActiveRoute(
                                  `/organizationOverview?companyId=${company?._id}`
                                ) && (
                                  <Box
                                    sx={{
                                      position: "absolute",
                                      bottom: 0,
                                      left: 0,
                                      right: 0,
                                      height: 2,
                                      backgroundColor: "#423BFA",
                                      borderRadius: 2,
                                    }}
                                  />
                                )}
                              </Box>
                            ))
                          ) : (
                            <Typography
                              sx={{
                                paddingX: 2,
                                fontSize: "0.875rem",
                                color: "#000",
                              }}
                            >
                              No companies available
                            </Typography>
                          )}
                        </Box>
                      </Box>
                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={{ mx: 0.5 }}
                      />
                      <Box sx={{ flex: 1 }}>
                        <Box sx={{ paddingY: 0.5 }}>
                          <Typography
                            variant="subtitle2"
                            sx={{
                              fontWeight: "bold",
                              fontSize: "0.875rem",
                              color: "#000",
                              fontFamily: "satoshi",
                            }}
                          >
                            Top Companies
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 0.5,
                          }}
                        >
                          <Box
                            sx={{
                              paddingY: 0.75,
                              paddingX: 2,
                              cursor: "pointer",
                              "&:hover": {
                                backgroundColor: "rgba(0, 0, 0, 0.05)",
                              },
                              position: "relative",
                              color: "#000",
                            }}
                            onClick={() =>
                              handleNavigation(`/top-hiring-companies`)
                            }
                          >
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: "0.875rem",
                                color: "#000",
                                fontFamily: "satoshi",
                                fontWeight: 400,
                                ":hover": {
                                  fontWeight: 600,
                                },
                              }}
                            >
                              Top IT Companies
                            </Typography>
                            {isActiveRoute(`/top-hiring-companies`) && (
                              <Box
                                sx={{
                                  position: "absolute",
                                  bottom: 0,
                                  left: 0,
                                  right: 0,
                                  height: 2,
                                  backgroundColor: "#423BFA",
                                  borderRadius: 2,
                                }}
                              />
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>

                <Button
                  onClick={() => handleNavigation("/findJobs")}
                  sx={{
                    textTransform: "none",
                    color: "#000000",
                    fontSize: {
                      xs: "14px",
                      md: "14px",
                      lg: "14px",
                      xl: "19px",
                    },
                    "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.05)" },
                  }}
                >
                  Find Jobs
                </Button>
                <Button
                  onClick={handleWhyVacanziClick}
                  sx={{
                    textTransform: "none",
                    color: "#000000",
                    fontSize: {
                      xs: "14px",
                      md: "14px",
                      lg: "14px",
                      xl: "19px",
                    },
                    "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.05)" },
                  }}
                >
                  Why Vacanzi
                </Button>
                <Button
                  onClick={() => handleNavigation("/connect")}
                  sx={{
                    textTransform: "none",
                    color: "#000000",
                    fontSize: {
                      xs: "14px",
                      md: "14px",
                      lg: "14px",
                      xl: "19px",
                    },
                    "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.05)" },
                  }}
                >
                  Connect
                </Button>
                {token && (
                  <>
                    <Button
                      onClick={() => handleNavigation("/jobSeekerAppliedJobs")}
                      sx={{
                        textTransform: "none",
                        color: "#000000",
                        fontSize: {
                          xs: "14px",
                          md: "14px",
                          lg: "14px",
                          xl: "19px",
                        },
                        "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.05)" },
                      }}
                    >
                      Applied Jobs
                    </Button>
                    <Button
                      onClick={() => handleNavigation("/jobSeekerSavedJobs")}
                      sx={{
                        textTransform: "none",
                        color: "#000000",
                        fontSize: {
                          xs: "14px",
                          md: "14px",
                          lg: "14px",
                          xl: "19px",
                        },
                        "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.05)" },
                      }}
                    >
                      Saved Jobs
                    </Button>
                  </>
                )}
              </Box>
              {token ? (
                <Typography>
                  <IconButton
                    color="black"
                    onClick={() => navigate("/notifications")}
                  >
                    <NotificationsIcon />
                  </IconButton>
                </Typography>
              ) : null}
              {/* User Menu */}
              {token ? (
                <Box
                  sx={{
                    backgroundColor: "#ffffff",
                    borderRadius: "12px",
                    width: { xl: "300px", lg: "220px", md: "200px" },
                    padding: "4px 16px",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                    display: "inline-flex",
                    alignItems: "center",
                    gap: "4px",
                    "&:hover": { cursor: "pointer" },
                  }}
                  onMouseEnter={handleOpenUserMenu}
                >
                  <Typography
                    sx={{
                      color: "#18181b",
                      fontSize: {
                        xs: "14px",
                        md: "14px",
                        lg: "14px",
                        xl: "19px",
                      },
                    }}
                  >
                    {truncateText(userData?.email || "", 18)}
                  </Typography>
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt="User Avatar" src={userData?.profileImage} />
                  </IconButton>
                  <Popover
                    sx={{
                      mt: "45px",
                      "& .MuiPopover-paper": {
                        minWidth: "200px",
                        backgroundColor: "#ffffff",
                        borderRadius: "8px",
                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                      },
                    }}
                    open={Boolean(anchorElUser)}
                    anchorEl={anchorElUser}
                    onClose={handleCloseUserMenu}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                  >
                    <div onMouseLeave={handleCloseUserMenu}>
                      <MenuItem
                        onClick={() => handleNavigation("/jobSeeker-Profile")}
                        sx={{ py: 2, borderBottom: "1px solid #e0e0e0" }}
                      >
                        <Avatar
                          alt="User Avatar"
                          src={userData?.profileImage || ""}
                          sx={{ width: 32, height: 32, mr: 1 }}
                        />
                        <Typography sx={{ fontSize: "13px" }}>
                          {userData?.email}
                        </Typography>
                      </MenuItem>
                      {[
                        { label: "Profile", path: "/jobSeeker-Profile" },
                        { label: "Invites", path: "/invites" },
                        {
                          label: "Applied Jobs",
                          path: "/jobSeekerAppliedJobs",
                        },
                        { label: "Notifications", path: "/notifications" },
                        { label: "Support", path: "/jobSeeker/support" },
                        {
                          label: "Delete Account",
                          path: "/jobSeeker/deleteAccount",
                        },
                        { label: "Logout", action: handleLogout },
                      ].map((item, index) => (
                        <MenuItem
                          key={index}
                          onClick={
                            item.action
                              ? item.action
                              : () => handleNavigation(item.path)
                          }
                          sx={{
                            py: 1.5,
                            "&:hover": {
                              "& .MuiTypography-root": { color: "#423BFA" },
                            },
                          }}
                        >
                          <Typography textAlign="center">
                            {item.label}
                          </Typography>
                        </MenuItem>
                      ))}
                    </div>
                  </Popover>
                </Box>
              ) : (
                <Box
                  sx={{
                    backgroundColor: "#ffffff",
                    borderRadius: "12px",
                    padding: "8px 16px",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                    display: "inline-flex",
                    gap: "4px",
                  }}
                >
                  <Button
                    onClick={() => handleNavigation("/signinsignup")}
                    sx={{
                      textTransform: "none",
                      color:
                        location.pathname === "/employerSignIn"
                          ? "#18181b"
                          : "#ffffff",
                      backgroundColor:
                        location.pathname === "/employerSignIn"
                          ? "transparent"
                          : "#18181b",
                      fontSize: {
                        xs: "14px",
                        md: "14px",
                        lg: "14px",
                        xl: "19px",
                      },
                      borderRadius: "0.7rem",
                      height: "2rem",
                      padding: "0.375rem 0.75rem",
                      "&:hover": {
                        backgroundColor:
                          location.pathname === "/employerSignIn"
                            ? "rgba(0, 0, 0, 0.05)"
                            : "rgba(0, 0, 0, 0.8)",
                      },
                    }}
                  >
                    Jobseeker Login
                  </Button>
                  <Button
                    onClick={() => handleNavigation("/employerSignIn")}
                    sx={{
                      textTransform: "none",
                      color:
                        location.pathname === "/employerSignIn"
                          ? "#ffffff"
                          : "#18181b",
                      backgroundColor:
                        location.pathname === "/employerSignIn"
                          ? "#18181b"
                          : "transparent",
                      fontSize: {
                        xs: "14px",
                        md: "14px",
                        lg: "14px",
                        xl: "19px",
                      },
                      borderRadius: "0.7rem",
                      height: "2rem",
                      padding: "0.375rem 0.75rem",
                      "&:hover": {
                        backgroundColor:
                          location.pathname === "/employerSignIn"
                            ? "rgba(0, 0, 0, 0.8)"
                            : "rgba(0, 0, 0, 0.05)",
                      },
                    }}
                  >
                    Employer Login
                  </Button>
                </Box>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      {userId && <JobSeekerToJobSeekerSupport chatId={chatId} />}
    </>
  );
};

export default NewNavBar;
