import React, { useState } from "react";
import { Typography, Box, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SearchBar from "../LandingSearchBar/SearchBar";
// import ConnectionPopupBox from "../../../pages/Common/Connect/ConnectionPopupBox";
// import { getCunsellorsApi } from "../../../api/common";
import LandingPageCards from "./LandingPageCards";

const JobSeekerJobSearch = () => {
  const [titleSearch, setTitleSearch] = useState("");
  const [locations, setLocations] = useState([]); // Already an array for multi-selection
  // const [openDialog, setOpenDialog] = useState(false);
  const [area, setArea] = useState([]); // Kept for compatibility, not used in SearchBar
  // const [counsellors, setCounsellors] = useState([]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  // useEffect(() => {
  //   setTimeout(() => {
  //     const hasPopupBeenShown = sessionStorage.getItem("popupShown");

  //     if (!hasPopupBeenShown) {
  //       handleOpenDialogBox();
  //       sessionStorage.setItem("popupShown", "true");
  //     }
  //   }, 1500);
  // }, []);

  const handleSearch = () => {
    navigate("/findJobs", {
      state: {
        keyword: titleSearch,
        jobCity: locations,
        jobArea: area,
      },
    });
  };

  // const handleOpenDialogBox = () => {
  //   setOpenDialog(true);
  // };

  // const handleCloseDialogBox = () => {
  //   setOpenDialog(false);
  // };

  // useEffect(() => {
  //   const fetchCounsellors = async () => {
  //     try {
  //       const response = await getCunsellorsApi();
  //       setCounsellors(response);
  //     } catch (error) {
  //       console.log(error.message);
  //     }
  //   };
  //   fetchCounsellors();
  // }, []);

  return (
    <>
      <Box sx={{ position: "relative", overflow: "hidden" }}>
        {/* Background Video */}
        <video
          autoPlay
          muted
          loop
          playsInline
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            opacity: 1,
            objectFit: "cover",
            zIndex: -1,
          }}
        >
          <source src="/landBg.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        {/* Content Container */}
        <Box
          sx={{
            position: "relative",
            zIndex: 1,
            padding: { xs: "1rem", sm: "2rem", md: "3rem" }, // Reduced padding on small screens
            minHeight: { xs: "auto", md: "90vh" }, // Auto height on small screens for flexibility
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
              width: "100%",
              maxWidth: { xs: "100%", md: "1100px" }, // Full width on small screens
              margin: "0 auto",
              gap: { xs: 1.5, md: 2 }, // Smaller gap on small screens
              mt: { xs: 6, md: 12 }, // Reduced top margin on small screens
              mb: { xs: 2, md: 4 }, // Reduced bottom margin on small screens
            }}
          >
            <Typography
              variant={isSmallScreen ? "h6" : "h2"}
              sx={{
                color: "#fff",
                fontWeight: "bold",
                fontFamily: "gilroy",
                fontSize: { xs: "1.25rem", sm: "2rem", md: "3rem" }, // Custom scaling for better control
              }}
            >
              Find Jobs Faster, Connect Instantly with Recruiters
            </Typography>

            <SearchBar
              title={titleSearch}
              area={area}
              setArea={setArea}
              locations={locations}
              onTitleChange={setTitleSearch}
              setLocations={setLocations}
              onSearch={handleSearch}
              sx={{
                width: { xs: "100%", sm: "80%", md: "100%" }, // Already responsive
              }}
            />
          </Box>

          <Typography
            sx={{
              color: "#fff",
              padding: { xs: 2, md: 5 }, // Reduced padding on small screens
              mt: { xs: 4, md: 8 }, // Reduced top margin on small screens
              fontSize: { xs: "1.5rem", sm: "2rem", md: "40px" }, // Scaled font size
              fontWeight: "bold",
              fontFamily: "gilroy",
              textAlign: "center",
              lineHeight: 1.2, // Improved readability on small screens
            }}
          >
            No more rejections! <br />
            Shine in a <b style={{ color: "#a7f3d0" }}>30-sec video</b> - Get
            Hired
          </Typography>

          <LandingPageCards />
        </Box>
      </Box>
    </>
  );
};

export default JobSeekerJobSearch;