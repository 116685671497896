import React, { useEffect, useState, useRef } from "react";
import { Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { generateRefereshTokenApi } from "../api/jobseeker";

const TOKEN_EXPIRY_THRESHOLD = 300; // 5 minutes in seconds

const ProtectedJobSeekerRoute = ({ children }) => {
  const [isValidToken, setIsValidToken] = useState(null);
  const isRefreshing = useRef(false);

  useEffect(() => {
    const checkAndRefreshToken = async () => {
      if (isRefreshing.current) return; // ✅ Prevent multiple calls
      isRefreshing.current = true;

      const token = localStorage.getItem("authToken");
      const refreshToken = localStorage.getItem("refreshToken");

      if (!token) {
        setIsValidToken(false);
        isRefreshing.current = false;
        return;
      }

      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Math.floor(Date.now() / 1000);

        if (decodedToken.exp <= currentTime) {
          // Token is expired, attempt to refresh
          const response = await generateRefereshTokenApi({ refreshToken });
          const newToken = response?.accessToken;

          if (newToken) {
            localStorage.setItem("authToken", newToken);
            setIsValidToken(true);
          } else {
            setIsValidToken(false);
            localStorage.removeItem("authToken"); // Clear expired token
          }
        } else if (decodedToken.exp <= currentTime + TOKEN_EXPIRY_THRESHOLD) {
          // Token is about to expire, attempt to refresh
          const response = await generateRefereshTokenApi({ refreshToken });
          const newToken = response?.accessToken;

          if (newToken) {
            localStorage.setItem("authToken", newToken);
            setIsValidToken(true);
          } else {
            setIsValidToken(false);
            localStorage.removeItem("authToken"); // Clear expired token
          }
        } else {
          // Token is still valid
          setIsValidToken(true);
        }
      } catch (error) {
        setIsValidToken(false);
        localStorage.removeItem("authToken");
      } finally {
        isRefreshing.current = false; // ✅ Reset after completion
      }
    };

    checkAndRefreshToken();
  }, []); // ✅ Only on mount

  if (isValidToken === null) {
    return null; // Loading state
  }

  if (!isValidToken) {
    return <Navigate to="/signinsignup" />;
  }

  return children;
};

export default ProtectedJobSeekerRoute;
