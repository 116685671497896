import React, { useState, useCallback, memo } from "react";
import { Button, Typography, Box, Modal, Avatar } from "@mui/material";


import { ThreeDot } from "react-loading-indicators";
import { getJobseekerProfileApi } from "../../../../api/jobseeker";
import CandidateChatModal from "../CandidateChatModal";
import { styled } from "@mui/system";

const CandidateContact = ({ profile, handleError }) => {
  const [loading, setLoading] = useState(false);
  const [showContactDetails, setShowContactDetails] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const token = localStorage.getItem("token");

  const handleOpenChat = useCallback((profileId) => {
    setCurrentId(profileId);
    setIsModalOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
    setCurrentId(null);
  }, []);

  const getCandidateProfile = useCallback(
    async (currentId) => {
      setLoading(true);
      try {
        const response = await getJobseekerProfileApi(currentId, token);
        if (response) {
          setShowContactDetails(true);
        }
      } catch (error) {
        handleError(
          error.message || "Failed To View Contact Details, Please Try Again"
        );
      } finally {
        setLoading(false);
      }
    },
    [token, handleError]
  );
  const designation =
    profile?.experiences?.[0]?.designation ||
    profile?.preferredJobTitle ||
    "Designation Not Available";

  // Styled components
  const RightColumn = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(2),
    minWidth: "180px",
  }));

  const ActionButton = styled(Button)(({ theme }) => ({
    borderRadius: "10px",
    textTransform: "none",
    boxShadow: "none",
    fontFamily: "poppins",
    fontSize: "12px",
    padding: theme.spacing(1, 2),
    width: "100%",
    whiteSpace: "nowrap",
  }));

  const ProfileAvatar = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: theme.spacing(2),
  }));

  return (
    <RightColumn>
      <ProfileAvatar>
        <Avatar
          src={profile.profileImage || "/placeholder.svg?height=80&width=80"}
          alt={profile.fullName}
          sx={{
            width: 80,
            height: 80,
            border: "2px solid #f0f0f0",
            alignSelf: "center",
          }}
        />

        {!showContactDetails ? (
          <ActionButton
            variant="outlined"
            color="primary"
            onClick={() => getCandidateProfile(profile._id)}
            disabled={loading}
          >
            {loading ? (
              <ThreeDot color="#423bfa" size="medium" text="" textColor="" />
            ) : (
              "View Contact"
            )}
          </ActionButton>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              width: "100%",
              p: 1,
              bgcolor: "#f5f5f5",
              borderRadius: "8px",
            }}
          >
            <Typography sx={{ fontSize: "12px", fontFamily: "poppins" }}>
              Phone: {profile?.phone || "Not available"}
            </Typography>
            <Typography sx={{ fontSize: "12px", fontFamily: "poppins" }}>
              Email: {profile?.email || "Not available"}
            </Typography>
          </Box>
        )}

        <ActionButton
          variant="contained"
          color="primary"
          onClick={() => handleOpenChat(profile._id)}
        >
          Chat
        </ActionButton>
      </ProfileAvatar>

      {/* Chat Modal */}
      {isModalOpen && currentId && (
        <Modal
          open={isModalOpen}
          onClose={handleCloseModal}
          sx={{
            position: "fixed",
            bottom: 20,
            right: 20,
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <Box sx={{ width: 400, height: 500 }}>
            <CandidateChatModal
              name={profile?.fullName || "Unknown"}
              profileImage={
                profile?.profileImage || "/placeholder.svg?height=80&width=80"
              }
              designation={designation}
              currentId={currentId}
              onClose={handleCloseModal}
            />
          </Box>
        </Modal>
      )}
    </RightColumn>
  );
};

export default memo(
  CandidateContact,
  (prevProps, nextProps) => prevProps.profile._id === nextProps.profile._id
);
