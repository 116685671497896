import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, TextField, Button, Typography, Divider } from "@mui/material";
import JobSeekerOTPpage from "./JobSeekerOTPpage";
import CustomSnackbar from "../../../components/AdvancedComponents/CustomSnackbar";
import baseURL from "../../../api/baseUrl";
import { Link } from "react-router-dom";
// import { getUserIdFromToken } from "../../../utils/userAuth";
// import ImageCorousel from "../../../components/LoginPageComponents/ImageCorousel";
// import MobileScreenBanner from "../../../components/LoginPageComponents/MobileScreenBanner";
// import Features from "../../../components/LoginPageComponents/Features";
// import AvailableFeatures from "../../../components/LoginPageComponents/AvailableFeatures";
// import VacanziCounselors from "../../../components/LoginPageComponents/VacanziCounselors";
import Testimonials from "../../../components/LoginPageComponents/Testimonials";
// import NewsSection from "../../../components/LoginPageComponents/NewsSection";
import Faq from "../../../components/LoginPageComponents/Faq";
import Footer from "../../../components/JobSeekerComponents/Footer/Footer";
// import ResponsiveAppBar from "../../../components/JobSeekerComponents/NavBar/NavBar";
import GoogleSignIn from "./GoogleSignIn";
import NewNavBar from "../../../components/JobSeekerComponents/NavBar/NewNavbar";
// import WhyVacanzi from "../../../components/JobSeekerComponents/JobSeekerHomePageBanners/WhyVacanzi";
import Advertisements from "../../../components/JobSeekerComponents/JobSeekerHomePageBanners/Advertisements";
// import TwoMobilesBanner from "../../../components/LoginPageComponents/TwoMobilesBanner";
import { ThreeDot } from "react-loading-indicators";
// import Checkbox from "@mui/material/Checkbox";
import { useJobseeker } from "../../../context/UserContext";
import JobSeekerWhyVacanziTestimonals from "../../../components/JobSeekerComponents/JobSeekerHomePageBanners/JobSeekerWhyVacanziTestimonals";
// import JobSeekerWhyVacanziTestimonals from "../../../components/JobSeekerComponents/JobSeekerHomePageBanners/JobSeekerWhyVacanziTestimonals";
import FormGroup from "@mui/material/FormGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";

export default function SignInSignUp() {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [showOtpBox, setShowOtpBox] = useState(false);
  const [formData, setFormData] = useState({
    loginPhone: "",
    signupEmail: "",
    signupName: "",
    signupPhone: "",
    otp: "",
  });
  const [errors, setErrors] = useState({});

  const [canResend, setCanResend] = useState(true);
  const [timer, setTimer] = useState(60);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("");
  const [loading, setLoading] = useState(false);
  const { setUserData } = useJobseeker();
  // const [isFirstChecked, setIsFirstChecked] = React.useState(false); // Track checkbox state
  // const [isSecondChecked, setIsSecondChecked] = React.useState(false);

  useEffect(() => {
    if (timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(countdown); // Clear interval on unmount
    }
  }, [timer]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  const phoneNumberRegex = /^[6-9]\d{9}$/; // Regex for Indian phone numbers

  const validatePhoneNumber = (number) => phoneNumberRegex.test(number);

  const handleFieldChange = (field, value) => {
    // Ensure phone number is at least 10 digits and does not exceed 10 digits
    if (
      (field === "loginPhone" || field === "signupPhone") &&
      value.length > 10
    ) {
      return; // Prevent further updates if number exceeds 10 digits
    }

    setFormData((prevState) => {
      const newState = { ...prevState, [field]: value };

      if (field === "loginPhone" || field === "signupPhone") {
        if (value.length === 10 && validatePhoneNumber(value)) {
          setErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [field]: "Enter a valid 10-digit phone number",
          }));
        }
      }

      if (field === "signupEmail") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          signupEmail: value ? "" : "Email is required",
        }));
      }

      if (field === "signupName") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          signupName: value ? "" : "Full Name is required",
        }));
      }

      return newState;
    });
  };

  const handleLoginSubmit = async () => {
    if (!validatePhoneNumber(formData.loginPhone)) {
      setErrors({ ...errors, loginPhone: "Invalid phone number" });
      return;
    }
    setLoading(true);
    try {
      const response = await fetch(`${baseURL}/js_login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          phone: formData.loginPhone,
          // acceptedTCs: isFirstChecked,
          // whatsappConsent: isSecondChecked,
        }),
      });

      const result = await response.json();

      if (response.ok) {
        setShowOtpBox(true);
        showSnackbar("OTP sent successfully", "success");
      } else {
        showSnackbar(result.message, "error");
        if (result.message === "User does not exist, please sign up") {
          setErrors({ ...errors, login: result.message });
          setTimeout(() => {
            setValue(1); // Switch to Sign-Up tab after 2 seconds
            setSnackbarOpen(false);
          }, 2000);
        } else {
          setErrors({ ...errors, login: result.message });
        }
      }
      setTimer(30);
    } catch (error) {
      console.error("Error during login:", error);
      showSnackbar("Error during login", "error");
    } finally {
      setLoading(false);
    }
  };

  const handleResendOtp = () => {
    setTimer(60); // Reset timer
    setCanResend(false);
  };

  const showSnackbar = (message, type) => {
    setSnackbarMessage(message);
    setSnackbarType(type);
    setSnackbarOpen(true);
    // Automatically close the snackbar after 2 seconds
    setTimeout(() => {
      setSnackbarOpen(false);
    }, 2000);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleOtpSubmit = async () => {
    const phone = value === 0 ? formData.loginPhone : formData.signupPhone;
    const url =
      value === 0
        ? `${baseURL}/validatejs_register`
        : `${baseURL}/auth/validatejs_register`;

    setLoading(true);
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ phone, otp: formData.otp }),
      });

      const result = await response.json();
      if (response.ok) {
        // getUserIdFromToken();
        showSnackbar("OTP validated successfully", "success");

        if (result.existingUser === true) {
          localStorage.setItem("authToken", result.token);
          localStorage.setItem("refreshToken", result.refreshToken);
          localStorage.setItem("userId", result.user._id);
          localStorage.setItem("profileImage", result.user.profileImage);
          localStorage.setItem("userName", result.user.fullName);
          setUserData(result?.user);

          navigate("/", { replace: true });
        } else {
          navigate("/jobSeekerRegestrationProfile", {
            state: {
              signupEmail: formData.signupEmail,
              signupName: formData.signupName,
              loginPhone: formData.loginPhone,
            },
          });
        }
      } else {
        showSnackbar(result.message || "Invalid OTP", "error");
        setErrors({ ...errors, otp: "Invalid OTP" });
      }
    } catch (error) {
      console.error("Error during OTP validation:", error);
      showSnackbar("Error during OTP validation", "error");
      setErrors({ ...errors, otp: "Invalid OTP" });
    } finally {
      setLoading(false);
    }
  };

  // const handleScrollTop = () => {
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  // };

  // Handle Enter key press
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleLoginSubmit();
    }
  };
  const handleBack = () => {
    setFormData({
      ...formData,
      otp: "", // Clear the OTP field
    });
    setShowOtpBox(false);
    setErrors({ ...errors, otp: " " });
    // You can add additional logic here if needed
  };

  return (
    <>
      <NewNavBar />
      <Box
        sx={{
          width: "100vw",
          backgroundColor: "#fff",
          marginLeft: 0,
          padding: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#fff",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#fff",
              display: "flex",
              flexDirection: { xs: "column", sm: "row" }, // Stack on xs, row on sm and above
              justifyContent: "space-between",
              alignItems: "center",
              height: "100vh",
              width: "100vw",
            }}
          >
            {/* Image Container */}

            <Box
              component="img"
              src="/heroMobile.avif"
              alt=""
              sx={{
                maxWidth: "80%",
                pl: 10,
                width: "100%",
                maxHeight: "300px",
                "@media (min-width: 600px)": {
                  maxWidth: "70%",
                  width: "35%",
                  maxHeight: "400px",
                },
                "@media (min-width: 900px)": {
                  maxWidth: "60%",
                  width: "35%",
                  maxHeight: "500px",
                },
                "@media (min-width: 1200px)": {
                  width: "34%",
                  maxHeight: "500px",
                },
                "@media (min-width: 1536px)": {
                  maxWidth: "38%",
                  width: "38%",
                  maxHeight: "550px",
                },
              }}
            />

            {/* Text Container */}
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: { xs: "center", sm: "flex-start" }, // Center text on xs, left-align on sm and above
                padding: { xs: "20px 0", sm: "0 20px" }, // Add padding for spacing
                textAlign: { xs: "center", sm: "left" }, // Center text on xs, left-align on sm and above
              }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: {
                    xs: "1.5rem",
                    sm: "1.7rem",
                    md: "1.7rem",
                    xl: "1.7rem",
                  },
                  fontFamily: "IBM plex sans",
                  opacity: 0.7, // Responsive font size
                }}
              >
                I Found the Perfect Job That Matched My Personality in <br />{" "}
                Just Hours
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              width: {
                xs: "90vw",
                sm: "80vw",
                md: "50vw",
                lg: "50vw",
                xl: "100vw",
              },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: {
                xs: "90vh",
                sm: "40vh",
                md: "30vh",
                lg: "30vh",
                xl: "100vh",
              },
              backgroundColor: "#fff",
            }}
          >
            {!showOtpBox ? (
              <>
                <Box
                  sx={{
                    color: "black",
                    flex: 1,
                    width: {
                      xs: "90%",
                      sm: "85%",
                      md: "70%",
                      lg: "70%",
                      xl: "80%",
                    }, // Responsive width
                    maxWidth: {
                      xs: "400px",
                      sm: "400px",
                      md: "400px",
                      lg: "400px",
                      xl: "500px",
                    }, // Responsive maxWidth
                    padding: {
                      xs: "1rem",
                      sm: "1.5rem",
                      md: "1.5rem",
                      lg: "1.5rem",
                      xl: "2rem",
                    }, // Responsive padding
                    minHeight: {
                      xs: "400px",
                      sm: "500px",
                      md: "300px",
                      lg: "300px",
                      xl: "600px",
                    }, // Responsive minHeight
                    border: "1px solid #ddd",
                    borderRadius: "16px",
                    backgroundColor: "#fff",
                    textAlign: "center",
                    boxShadow: "-1px 1px 15px 2px rgba(0, 0, 0, 0.1)",
                    margin: "0 auto", // Center the Box horizontally
                  }}
                >
                  <Typography
                    variant="h4" // Make the heading slightly larger
                    fontWeight="bold"
                    gutterBottom
                    sx={{
                      fontFamily: "IBM Plex Sans",
                      mb: 4, // Add more margin below the heading
                    }}
                  >
                    {loading ? (
                      <ThreeDot
                        color="#FFFFFFFF"
                        size="small"
                        text=""
                        textColor=""
                      />
                    ) : (
                      "Login"
                    )}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "start",
                      flexDirection: "column",
                      marginBottom: 4,
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "IBM Plex Sans",
                        fontWeight: "bold",
                        fontSize: "14px",
                        mb: -1,
                      }}
                    >
                      Mobile Number
                    </Typography>
                    <TextField
                      fullWidth
                      placeholder="Eg. 8904241444"
                      size="medium"
                      margin="normal"
                      type="tel"
                      inputProps={{
                        maxLength: 10,
                        pattern: "[0-9]{10}",
                        inputMode: "numeric",
                        style: {
                          appearance: "none",
                          MozAppearance: "textfield",
                          WebkitAppearance: "none",
                        },
                      }}
                      sx={{
                        backgroundColor: "white",
                        opacity: 0.9,
                      }}
                      error={Boolean(errors.loginPhone)}
                      helperText={errors.loginPhone}
                      onChange={(e) => {
                        const numericValue = e.target.value.replace(
                          /[^0-9]/g,
                          ""
                        );
                        handleFieldChange("loginPhone", numericValue);
                      }}
                      onKeyPress={(e) => {
                        if (!/[0-9]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onPaste={(e) => {
                        const pasteData = e.clipboardData.getData("text");
                        if (!/^\d*$/.test(pasteData)) {
                          e.preventDefault();
                        }
                      }}
                      onKeyDown={handleKeyDown} // Directly use handleKeyDown
                      required
                    />
                    <Typography
                      sx={{
                        color: "black",
                        fontSize: "14px",
                        opacity: 0.8,
                        fontFamily: "IBM Plex Sans",
                        mt: -0.5,
                        ml: "4px",
                      }}
                    >
                      You will receive an OTP on this number
                    </Typography>
                  </Box>
                  <Button
                    // disabled={!isFirstChecked}
                    sx={{
                      mt: 2,
                      backgroundColor: "#423bfa",
                      color: "white",
                      fontWeight: "bold",
                      borderRadius: "0.7rem",
                      padding: "0.8rem", // Increase padding for larger button
                      fontSize: "1rem", // Larger font size
                      "&:hover": { backgroundColor: "#B0F0F0", color: "#000" },
                      mb: 3, // Add space below
                    }}
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={handleLoginSubmit}
                  >
                    {loading ? (
                      <ThreeDot
                        color="#FFFFFFFF"
                        size="small"
                        text=""
                        textColor=""
                      />
                    ) : (
                      "Get OTP"
                    )}
                  </Button>
                  <Divider
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      color: "gray",
                      fontSize: "14px",
                      "&::before, &::after": {
                        borderColor: "lightgray",
                      },
                      mb: 3, // Add space below
                    }}
                  >
                    Or
                  </Divider>
                  {/* Google Sign-In Button */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Box sx={{  display: "flex",
                     
                        justifyContent: "center",
                        alignItems:"center"}}>
                        <GoogleSignIn />
                      </Box>

                      <FormGroup
                        sx={{
                          marginTop: "20px",
                        }}
                      >
              
                            <Typography
                              sx={{
                                fontSize: "10px",
                                textAlign: "left",
                                marginTop: "10px",
                                display: "inline",
                                justifyContent: "center",
                              }}
                            >
                              {" "}
                              {/* 'body2' makes text smaller */}
                              <span style={{ color: "red", fontSize: "14px" }}>
                                *{" "}
                              </span>{" "}
                              By proceeding, you acknowledge and agree to
                              Vacanzi's
                              <Link
                                to="/terms-&-cons"
                                sx={{ display: "inline-flex" }}
                              >
                                {" "}
                                Terms and Conditions{" "}
                              </Link>
                              and{" "}
                              <Link
                                to="/privacy-policy"
                                sx={{ display: "inline-flex" }}
                              >
                                {" "}
                                Privacy Policy.
                              </Link>
                            </Typography>
                          
                        
                        {/* <FormControlLabel
                   
                          label={
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Typography
                                sx={{
                                  fontSize: "10px",
                                  textAlign: "left",
                                  display: "inline",
                                }}
                              >
                                Please allow us to send you WhatsApp
                                notifications.
                              </Typography>
                              <img
                                src="./whatsapppic.png"
                                alt="WhatsApp Icon"
                                style={{
                                  width: "35px",
                                  height: "35px",
                                  marginLeft: "5px",
                                }}
                              />
                            </Box>
                          }
                          sx={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                          }}
                        /> */}
                      </FormGroup>
                    </Box>
                  </Box>
                </Box>
              </>
            ) : (
              <JobSeekerOTPpage
                handleBack={handleBack}
                phoneNumber={
                  value === 0 ? formData.loginPhone : formData.signupPhone
                }
                signupEmail={formData.signupEmail}
                handleFieldChange={handleFieldChange}
                handleResendOtp={handleResendOtp}
                canResend={canResend}
                timer={timer}
                setFormData={setFormData}
                otp={formData.otp}
                errors={errors}
                handleOtpSubmit={handleOtpSubmit}
                setTimer={setTimer}
              />
            )}
            <CustomSnackbar
              open={snackbarOpen}
              message={snackbarMessage}
              type={snackbarType}
              onClose={handleCloseSnackbar}
            />
          </Box>
        </Box>
        <Advertisements />
        <JobSeekerWhyVacanziTestimonals /> {/* <MobileScreenBanner /> */}
        {/* <TwoMobilesBanner /> */}
        {/* <AvailableFeatures handleScrollTop={handleScrollTop} /> */}
        {/* <ImageCorousel /> */}
        {/* <Features /> */}
        {/* <VacanziCounselors /> */}
        <Testimonials />
        {/* <NewsSection /> */}
        <Faq category="Job Seekers" />
        <Footer />
      </Box>
    </>
  );
}
