import React, { useState, useCallback } from "react";
import {
  Box,
  Container,
  Grid,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
  Avatar,
} from "@mui/material";
import { Instagram, Facebook, Twitter, YouTube } from "@mui/icons-material";
import VacanziLogo from "../../../assets/icons/logos/vacanzi.png";
import StandOut from "./StandOut";
import baseURL from "../../../api/baseUrl";


import ReportIssueDialogBox from "../../../pages/Common/ReportIssueDialogBox";
import PopupSuccess from "../../../utils/popup/PopupSuccess";
import ReleaseJobPostModel from "../../../pages/EmployerPages/SubscriptionStatus/ReleaseJobPostModel";

const reportOptions = [
  {
    value: "How to update your resume",
    label: "How to update your resume",
  },
  {
    value: "Forgot username and password",
    label: "Forgot username and password",
  },
  {
    value: "How To Block Companies",
    label: "How To Block Companies",
  },
  {
    value: "How To Create and Edit Job messenger",
    label: "How To Create and Edit Job messenger",
  },
  {
    value: "How To Create Cover Letter",
    label: "How To Create Cover Letter",
  },
  {
    value: "How to create edit and delete search agents",
    label: "How to create edit and delete search agents",
  },
  {
    value: "How to change password",
    label: "How to change password",
  },
  {
    value: "How to mark your CV as not searchable",
    label: "How to mark your CV as not searchable",
  },
  {
    value: "How to search and apply",
    label: "How to search and apply",
  },
  {
    value: "Unable to Login",
    label: "Unable to Login",
  },
  {
    value: "How to create new profile",
    label: "How to create new profile",
  },
  {
    value: "Problem in Recruiters Preview",
    label: "Problem in Recruiters Preview",
  },
  {
    value: "Unable to attach resume",
    label: "Unable to attach resume",
  },
  {
    value: "Getting run time/java script error",
    label: "Getting run time/java script error",
  },
  {
    value: "How to delete my account",
    label: "How to delete my account",
  },
  {
    value: "Mobile/email verification",
    label: "Mobile/email verification",
  },
];

const Footer = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [openReportDialog, setOpenReportDialog] = useState(false);

  const authToken = localStorage.getItem("authToken");
  const userId = localStorage.getItem("userId");
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const handleOpenReportDialog = () => {
    setOpenReportDialog(true);
  };
  const handleCloseReportDialog = () => {
    setOpenReportDialog(false);
  };

  const handleSendAppLink = async (phoneNumber) => {
    try {
      // Retrieve authToken and userId from localStorage

      if (!authToken || !userId) {
        console.log(
          "authToken or userId is missing. Calling sendAppLinkToNewUser."
        );

        if (!phoneNumber) {
          handleError("Please enter a valid phone number.");
          return;
        }

        const apiUrl = `${baseURL}/sendAppLinkToNewUser`;

        // Call the API with phone number
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ phoneNumber }),
        });

        if (!response.ok) {
          throw new Error("Failed to send app link to new user.");
        }

        const data = await response.json();
        console.log("App link sent successfully to new user:", data);
        <p style={{ fontWeight: "bold" }}>App link sent successfully</p>;
        handleSuccess("App link sent successfully");

        return;
      }

      // Existing logic for authenticated users
      const apiUrl = `${baseURL}/sendAppLink/${userId}`;
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to send app link.");
      }

      const data = await response.json();
      console.log("App link sent successfully:", data);
      handleSuccess("App link sent successfully");
    } catch (error) {
      console.error("Error sending app link:", error);
      handleError("Error sending app link");
    }
  };

  const handleSuccess = useCallback((message) => {
    setSuccessMessage(message);
    setSuccessOpen(true);
    setTimeout(() => {
      setSuccessOpen(false);
      setSuccessMessage("");
    }, 2000);
  }, []);

  const handleError = useCallback((message) => {
    setErrorMessage(message);
    setErrorOpen(true);
    setTimeout(() => {
      setErrorOpen(false);
      setErrorMessage("");
    }, 2000);
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        color: "#423bfa",
        width: "100%",
      }}
    >
      <Container maxWidth="xl">
        <Grid
          container
          spacing={2}
          justifyContent={isSmallScreen ? "center" : "flex-start"}
          sx={{ marginRight: 20 }}
          alignItems="flex-start" // Align items to the top
        >
          {/* About Us Section */}
          <Grid item xs={12} md={4}>
            <StandOut handleSendAppLink={handleSendAppLink} />
          </Grid>
          <Grid item xs={12} md={1.5}>
            <Link
              variant="h6"
              sx={{
                mb: 2,
                textAlign: isSmallScreen ? "center" : "left",
                fontFamily: "IBM Plex Sans",
                fontWeight: "bold",
                textDecoration: "none",
                color: "#000",
                height: "100%", // Ensure consistent height
                display: "flex",
                alignItems: "center", // Vertically center the text
              }}
            >
              About Us
            </Link>
            <Link
              href="/about-vacanzi"
              sx={{
                display: "block",
                mb: 1,
                color: "#000",

                textAlign: isSmallScreen ? "center" : "left",
                textDecoration: "none",
                fontFamily: "IBM Plex Sans",
              }}
            >
              About Vacanzi
            </Link>
            <Link
              href="/careers"
              sx={{
                display: "block",
                mb: 1,
                color: "#000",

                textAlign: isSmallScreen ? "center" : "left",
                textDecoration: "none",
                fontFamily: "IBM Plex Sans",
              }}
            >
              Careers
            </Link>
            <Link
              href="/blogs"
              sx={{
                display: "block",
                mb: 1,
                color: "#000",

                textAlign: isSmallScreen ? "center" : "left",
                textDecoration: "none",
                fontFamily: "IBM Plex Sans",
              }}
            >
              Blog
            </Link>
            <Link
              href="/articles"
              sx={{
                display: "block",
                mb: 1,
                color: "#000",

                textAlign: isSmallScreen ? "center" : "left",
                textDecoration: "none",
                fontFamily: "IBM Plex Sans",
              }}
            >
              Articles
            </Link>
            <Link
              href="/trustAndSafety"
              sx={{
                display: "block",
                mb: 1,
                color: "#000",

                textAlign: isSmallScreen ? "center" : "left",
                textDecoration: "none",
                fontFamily: "IBM Plex Sans",
              }}
            >
              Trust & safety
            </Link>
            <Link
              href="/fraudAlerts"
              sx={{
                display: "block",
                mb: 1,
                color: "#000",

                textAlign: isSmallScreen ? "center" : "left",
                textDecoration: "none",
                fontFamily: "IBM Plex Sans",
              }}
            >
              Fraud alert
            </Link>
            <Link
              onClick={handleOpenReportDialog}
              sx={{
                display: "block",
                mb: 1,
                color: "#000",
                cursor: "pointer",
                textAlign: isSmallScreen ? "center" : "left",
                textDecoration: "none",
                fontFamily: "IBM Plex Sans",
              }}
            >
              Report issue
            </Link>
          </Grid>

          {/* For Job Seekers Section */}
          <Grid item xs={12} md={1.5}>
            <Typography
              variant="h6"
              sx={{
                mb: 2,
                textAlign: isSmallScreen ? "center" : "left",
                fontFamily: "IBM Plex Sans",
                fontWeight: "bold",
                color: "#000",
                height: "100%", // Ensure consistent height
                display: "flex",
                alignItems: "center", // Vertically center the text
              }}
            >
              For Job Seekers
            </Typography>
            {}
            <Link
              href={userId ? "/" : "/signinsignup"}
              sx={{
                display: "block",
                mb: 1,
                color: "#000",

                textAlign: isSmallScreen ? "center" : "left",
                textDecoration: "none",
                fontFamily: "IBM Plex Sans",
              }}
            >
              JobSeeker Login
            </Link>
            <Link
              href="/job-seeker-faq"
              sx={{
                display: "block",
                mb: 1,
                color: "#000",

                textAlign: isSmallScreen ? "center" : "left",
                textDecoration: "none",
                fontFamily: "IBM Plex Sans",
              }}
            >
              FAQ
            </Link>
            <Link
              href="/privacy-policy"
              sx={{
                display: "block",
                mb: 1,
                color: "#000",

                textAlign: isSmallScreen ? "center" : "left",
                textDecoration: "none",
                fontFamily: "IBM Plex Sans",
              }}
            >
              Privacy Policy
            </Link>
            {/* <Link
              href="/payment-policy"
              sx={{
                display: "block",
                mb: 1,
                color: "#000",

                textAlign: isSmallScreen ? "center" : "left",
                textDecoration: "none",
                fontFamily: "IBM Plex Sans",
              }}
            >
              Payment Policy
            </Link> */}
            <Link
              href="/terms-&-cons"
              sx={{
                display: "block",
                mb: 1,
                color: "#000",

                textAlign: isSmallScreen ? "center" : "left",
                textDecoration: "none",
                fontFamily: "IBM Plex Sans",
              }}
            >
              Terms & Conditions
            </Link>
            <Link
              href="/skillup"
              sx={{
                display: "block",
                mb: 1,
                color: "#000",

                textAlign: isSmallScreen ? "center" : "left",
                textDecoration: "none",
                fontFamily: "IBM Plex Sans",
              }}
            >
              Study
            </Link>
            <Link
              href="/marketing"
              sx={{
                display: "block",
                mb: 1,
                color: "#000",

                textAlign: isSmallScreen ? "center" : "left",
                textDecoration: "none",
                fontFamily: "IBM Plex Sans",
              }}
            >
              Marketing
            </Link>
          </Grid>

          {/* For Job Seekers Section */}
          <Grid item xs={12} md={1.5}>
            <Typography
              variant="h6"
              sx={{
                mb: 2,
                textAlign: isSmallScreen ? "center" : "left",
                fontFamily: "IBM Plex Sans",
                fontWeight: "bold",
                color: "#000",
                height: "100%", // Ensure consistent height
                display: "flex",
                alignItems: "center", // Vertically center the text
              }}
            >
              For Employers
            </Typography>
            <Link
              href={authToken ? "/search-candidates" : "/employerSignIn"}
              sx={{
                display: "block",
                mb: 1,
                color: "#000",

                textAlign: isSmallScreen ? "center" : "left",
                textDecoration: "none",
                fontFamily: "IBM Plex Sans",
              }}
            >
              Employer Login
            </Link>

            <Link
              href="/employer-faq"
              sx={{
                display: "block",
                mb: 1,
                color: "#000",

                textAlign: isSmallScreen ? "center" : "left",
                textDecoration: "none",
                fontFamily: "IBM Plex Sans",
              }}
            >
              FAQ
            </Link>
            <Link
              href="/privacy-policy"
              sx={{
                display: "block",
                mb: 1,
                color: "#000",

                textAlign: isSmallScreen ? "center" : "left",
                textDecoration: "none",
                fontFamily: "IBM Plex Sans",
              }}
            >
              Privacy Policy
            </Link>
            {/* <Link
              href="/payment-policy"
              sx={{
                display: "block",
                mb: 1,
                color: "#000",

                textAlign: isSmallScreen ? "center" : "left",
                textDecoration: "none",
                fontFamily: "IBM Plex Sans",
              }}
            >
              Payment Policy
            </Link> */}
            <Link
              href="/terms-&-cons"
              sx={{
                display: "block",
                mb: 1,
                color: "#000",

                textAlign: isSmallScreen ? "center" : "left",
                textDecoration: "none",
                fontFamily: "IBM Plex Sans",
              }}
            >
              Terms & Conditions
            </Link>
          </Grid>
          {/* Contact Us Section */}
          <Grid item xs={12} md={1.5}>
            <Typography
              variant="h6"
              sx={{
                mb: 2,
                textAlign: isSmallScreen ? "center" : "left",
                fontFamily: "IBM Plex Sans",
                fontWeight: "bold",
                color: "#000",

                height: "100%", // Ensure consistent height
                display: "flex",
                alignItems: "center", // Vertically center the text
              }}
            >
              Contact Us
            </Typography>
            <Link
              href="/jobSeeker/support"
              sx={{
                display: "block",
                mb: 1,
                color: "#000",
                fontSize: "16px",

                textAlign: isSmallScreen ? "center" : "left",
                textDecoration: "none",
                fontFamily: "IBM Plex Sans",
              }}
            >
              <Typography>Support</Typography>
            </Link>

            <Typography
              variant="body2"
              sx={{
                mb: 1,
                textAlign: isSmallScreen ? "center" : "left",
                fontFamily: "IBM Plex Sans",
                color: "#000",
              }}
            >
              <Link
                href="https://mail.google.com/mail/?view=cm&fs=1&to=sales@vacanzi.com"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ color: "inherit", textDecoration: "none" }}
              >
                sales@vacanzi.com
              </Link>
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "#000",
                textAlign: isSmallScreen ? "center" : "left",
                fontFamily: "IBM Plex Sans",
              }}
            >
              <Link
                href="https://mail.google.com/mail/?view=cm&fs=1&to=support@vacanzi.com"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ color: "inherit", textDecoration: "none" }}
              >
                support@vacanzi.com
              </Link>
            </Typography>
          </Grid>

          {/* Logo and Social Media Section */}
          <Grid
            item
            xs={12}
            md={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                mt: 2,
                flexDirection: isSmallScreen ? "column" : "row",
                alignItems: "center",
                mb: isSmallScreen ? 2 : 2,
              }}
            >
              <Avatar
                src={VacanziLogo}
                alt="Vacanzi Logo"
                sx={{
                  width: 66,
                  height: 66,
                  mr: isSmallScreen ? 0 : -1,
                  mb: isSmallScreen ? 1 : 0,
                }}
              />
              <Typography
                variant="h2"
                noWrap
                component="a"
                href="#app-bar-with-responsive-menu"
                sx={{
                  mr: 1,
                  display: { xs: "none", md: "flex" },
                  fontFamily: "GlanceSansBold",
                  color: "#423bfa",
                  textDecoration: "none",
                  fontSize: "2.125rem",
                }}
              >
                Vacanzi
              </Typography>
            </Box>
            <Typography variant="h6" color="#000" gutterBottom>
              Let's Connect
            </Typography>
            <Box
              sx={{ display: "flex", justifyContent: "center", gap: 2, ml: 0 }}
            >
              <Link
                href="https://www.instagram.com/vacanzi102/"
                color="inherit"
                aria-label="Instagram"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Instagram sx={{ color: "#000", fontSize: "50px" }} />
              </Link>
              <Link
                href="https://www.facebook.com/" // Placeholder, update if you have a specific URL
                color="inherit"
                aria-label="Facebook"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Facebook sx={{ color: "#000", fontSize: "50px" }} />
              </Link>
              <Link
                href="https://x.com/vacanzi102"
                color="inherit"
                aria-label="Twitter"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Twitter sx={{ color: "#000", fontSize: "50px" }} />
              </Link>
              <Link
                href="https://www.youtube.com/channel/UCrR4GwWL3xJV2h0eBp8lR6Q"
                color="inherit"
                aria-label="YouTube"
                target="_blank"
                rel="noopener noreferrer"
              >
                <YouTube sx={{ color: "#000", fontSize: "50px" }} />
              </Link>
            </Box>
          </Grid>
        </Grid>

        {/* Footer Copyright Section */}
        <Box sx={{ pt: 2 }}>
          <Typography
            variant="body2"
            align="center"
            sx={{
              fontFamily: "IBM Plex Sans",
              color: "#000",
              fontWeight: "bold",
            }}
          >
            ©2025 Vacanzi Rights Reserved
          </Typography>
        </Box>
      </Container>
      <ReportIssueDialogBox
        openReportDialog={openReportDialog}
        handleCloseReportDialog={handleCloseReportDialog}
        reportOptions={reportOptions}
        userId={userId}
        authToken={authToken}
      />
      <PopupSuccess
        modelOpen={successOpen}
        onClose={() => setSuccessOpen(false)}
        message={successMessage}
      />
      <ReleaseJobPostModel
        modelOpen={errorOpen}
        onClose={() => setErrorOpen(false)}
        message={errorMessage}
      />
    </Box>
  );
};

export default Footer;
