import React from 'react';
import {
  Accordion, AccordionSummary, AccordionDetails, Typography, Grid, Autocomplete, TextField
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { minSalaryOptions, getMaxSalaryOptions } from '../../../../pages/EmployerPages/EmployerJobPost/jobPostStaticData';

const CandidateAnnualSalary = ({ formData, setFormData }) => {
  // Find the matching option object for minSalary and maxSalary from minSalaryOptions
  const selectedMinSalaryOption = minSalaryOptions.find(
    (option) => option.value === formData?.minSalary?.toString()
  ) || null;
  const selectedMaxSalaryOption = minSalaryOptions.find(
    (option) => option.value === formData?.maxSalary?.toString()
  ) || null;

  return (
    <Accordion
      defaultExpanded
      sx={{
        boxShadow: "none",
        backgroundColor: "transparent",
        "&:before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: "#666", opacity: 0.7 }} />}
        sx={{
          padding: "8px 0",
          minHeight: "40px",
          "& .MuiAccordionSummary-content": {
            margin: "0",
          },
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "bold",
            fontFamily: "IBM Plex Sans, sans-serif",
            color: "#333",
          }}
        >
          Annual Salary
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{ padding: "0 0 16px 0", backgroundColor: "transparent" }}
      >
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Autocomplete
              options={minSalaryOptions}
              value={selectedMinSalaryOption} // Use the full option object
              onChange={(event, newValue) => {
                // Extract the numeric value (e.g., "5" from { label: "5 LPA", value: "5" })
                const numericValue = newValue ? newValue.value : "";
                setFormData((prev) => ({
                  ...prev,
                  minSalary: numericValue,
                }));
              }}
              getOptionLabel={(option) => option.label} // Display the "X LPA" string
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Min salary"
                  size="small"
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              options={getMaxSalaryOptions(formData?.minSalary?.toString())} // Pass minSalary as string
              value={selectedMaxSalaryOption} // Use the full option object
              onChange={(event, newValue) => {
                // Extract the numeric value (e.g., "10" from { label: "10 LPA", value: "10" })
                const numericValue = newValue ? newValue.value : "";
                setFormData((prev) => ({
                  ...prev,
                  maxSalary: numericValue,
                }));
              }}
              getOptionLabel={(option) => option.label} // Display the "X LPA" string
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Max salary"
                  size="small"
                />
              )}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default CandidateAnnualSalary;