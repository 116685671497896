import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  CircularProgress,
  Avatar,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import initializeSocket from "../../../utils/socket/socket";
import debounce from "lodash/debounce";
import { truncateText } from "../../../utils/helperFunctions/Format";
import baseURL from "../../../api/baseUrl";

const CandidateChatModal = ({
  currentId,
  onClose,
  name,
  profileImage,
  designation,
}) => {
  const [chatId, setChatId] = useState(null);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState("");
  const [isSocketConnected, setIsSocketConnected] = useState(false);
  const [isCreatingChat, setIsCreatingChat] = useState(false);
  const recruiterId = localStorage.getItem("recruiterId");
  const token = localStorage.getItem("token");
  const messagesEndRef = useRef(null);
  const hasInitializedChat = useRef(false);
  const hasInitializedSocket = useRef(false);
  const socketRef = useRef(null);
  const hasSentMessage = useRef(false);

  const fetchChatMessages = useCallback(
    async (chatId) => {
      if (!chatId || !token) {
        console.log("No chatId or token, skipping fetch:", { chatId, token });
        return;
      }
      setLoading(true);
      try {
        const response = await fetch(`${baseURL}/chat/${chatId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        console.log("Fetched messages response:", data);
        const messagesArray = Array.isArray(data) ? data : data.messages || [];
        setMessages(messagesArray);
      } catch (error) {
        console.error("Error fetching chat messages:", error.message);
        setMessages([]);
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  const initializeChat = useCallback(async () => {
    if (hasInitializedChat.current || !token || !currentId || !recruiterId) {
      console.log("Skipping chat initialization:", {
        hasInitialized: hasInitializedChat.current,
        token: !!token,
        currentId,
        recruiterId,
      });
      return;
    }

    hasInitializedChat.current = true;
    try {
      const response = await fetch(`${baseURL}/chat/get/chatId`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          users: [
            { userId: currentId, userModel: "User" },
            { userId: recruiterId, userModel: "Recruiter" },
          ],
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log("initializeChat response:", data);
      const existingChatId = data.chatId || data.chat?._id;
      if (existingChatId) {
        setChatId(existingChatId);
        console.log("Fetching messages for chatId:", existingChatId);
        await fetchChatMessages(existingChatId);
      } else {
        console.error("No chatId returned from initializeChat:", data);
      }
    } catch (error) {
      console.error("Error initializing chat:", error.message);
    }
  }, [currentId, recruiterId, token, fetchChatMessages]);

  const createChatId = useCallback(async () => {
    if (isCreatingChat || !token) {
      console.log("Chat creation in progress or no token, skipping...");
      return chatId;
    }

    if (chatId) {
      console.log("Chat ID already exists, reusing:", chatId);
      return chatId;
    }

    setIsCreatingChat(true);
    try {
      const response = await fetch(`${baseURL}/chat/get/chatId`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          users: [
            { userId: currentId, userModel: "User" },
            { userId: recruiterId, userModel: "Recruiter" },
          ],
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log("createChatId response:", data);
      const newChatId = data.chatId || data.chat?._id;
      if (newChatId) {
        setChatId(newChatId);
        await fetchChatMessages(newChatId);
        return newChatId;
      }
      return null;
    } catch (error) {
      console.error("Error creating chat ID:", error.message);
      return null;
    } finally {
      setIsCreatingChat(false);
    }
  }, [
    currentId,
    recruiterId,
    chatId,
    isCreatingChat,
    token,
    fetchChatMessages,
  ]);

  useEffect(() => {
    if (hasInitializedSocket.current || !recruiterId || !token) return;

    socketRef.current = initializeSocket(
      recruiterId,
      "Recruiter",
      token,
      setIsSocketConnected
    );
    hasInitializedSocket.current = true;
    setIsSocketConnected(socketRef.current.connected);

    initializeChat();

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, [recruiterId, token, initializeChat]);

  useEffect(() => {
    if (!chatId || !socketRef.current) return;

    const socket = socketRef.current;

    const handleConnect = () => {
      console.log("Socket connected");
      setIsSocketConnected(true);
    };

    const handleDisconnect = () => {
      console.log("Socket disconnected");
      setIsSocketConnected(false);
    };

    const handleConnectError = (error) => {
      console.error("Socket.IO connection error:", error);
      setIsSocketConnected(false);
    };

    const handleNewMessage = (newMessage) => {
      console.log("Received newMessage:", newMessage);
      if (newMessage.chatId === chatId) {
        setMessages((prev) => {
          const safePrev = Array.isArray(prev) ? prev : [];
          const messageExists = safePrev.some(
            (msg) => msg._id === newMessage._id
          );
          if (messageExists) {
            console.log(
              "Duplicate message detected, skipping:",
              newMessage._id
            );
            return safePrev;
          }
          return [
            ...safePrev,
            {
              ...newMessage,
              senderModel: newMessage.senderModel,
              timestamp: new Date(newMessage.timestamp),
            },
          ];
        });
      }
    };

    socket.on("connect", handleConnect);
    socket.on("disconnect", handleDisconnect);
    socket.on("connect_error", handleConnectError);
    socket.on("newMessage", handleNewMessage);

    return () => {
      socket.off("connect", handleConnect);
      socket.off("disconnect", handleDisconnect);
      socket.off("connect_error", handleConnectError);
      socket.off("newMessage", handleNewMessage);
    };
  }, [chatId]);

  useEffect(() => {
    if (!isSocketConnected && socketRef.current) {
      const reconnectTimeout = setTimeout(() => {
        console.log("Attempting to reconnect socket...");
        socketRef.current.connect();
      }, 1000);
      return () => clearTimeout(reconnectTimeout);
    }
  }, [isSocketConnected]);

  const handleSendMessage = useCallback(async () => {
    if (!input.trim() || !socketRef.current) {
      console.log("Invalid input or no socket, skipping send");
      return;
    }

    if (hasSentMessage.current) {
      console.log("Message already sent, preventing duplicate");
      return;
    }
    hasSentMessage.current = true;

    let currentChatId = chatId;

    if (!currentChatId) {
      currentChatId = await createChatId();
      if (!currentChatId) {
        console.error("Failed to create chat ID, aborting message send");
        hasSentMessage.current = false;
        return;
      }
    }

    const payload = {
      senderId: recruiterId,
      receivers: [{ userId: currentId, userModel: "User" }],
      senderModel: "Recruiter",
      message: input,
      chatId: currentChatId,
      timestamp: new Date().toISOString(),
    };

    if (!isSocketConnected) {
      console.warn("Socket is disconnected, cannot send message");
      hasSentMessage.current = false;
      return;
    }

    console.log("Sending message payload:", payload);
    socketRef.current.emit("sendMessage", payload);

    setInput("");
    setTimeout(() => {
      hasSentMessage.current = false;
    }, 300);
  }, [chatId, currentId, recruiterId, input, isSocketConnected, createChatId]);

  const debouncedSendMessage = useMemo(
    () => debounce(handleSendMessage, 300),
    [handleSendMessage]
  );

  const handleSendMessageDebounced = useCallback(() => {
    debouncedSendMessage();
  }, [debouncedSendMessage]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  console.log("messages:", messages);
  console.log("chatId:", chatId);
  console.log("isSocketConnected:", isSocketConnected);

  let isMine;

  return (
    <Box
      sx={{
        width: 400,
        height: 500,
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#f8f9fa",
        borderRadius: "12px",
        boxShadow: "0 8px 30px rgba(0, 0, 0, 0.12)",
        overflow: "hidden",
        position: "relative",
      }}
    >
      {/* Header */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "16px",
          backgroundColor: "#fff",
          borderBottom: "1px solid #e9ecef",
          boxShadow: "0 1px 4px rgba(0, 0, 0, 0.05)",
        }}
      >
        <IconButton onClick={onClose} sx={{ mr: 1 }}>
          <CloseIcon />
        </IconButton>
        <Avatar
          src={profileImage} // No profile image provided in props, using null
          alt={name}
          sx={{ width: 40, height: 40 }}
        />
        <Box sx={{ ml: 2, flexGrow: 1 }}>
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: 600,
              fontFamily: "satoshi",
              color: "#212529",
            }}
          >
            {truncateText(name, 25)}
          </Typography>
          <Typography
            variant="caption"
            sx={{
              display: "block",
              color: "#6c757d",
              fontFamily: "satoshi",
              fontSize: "0.8rem",
            }}
          >
            {truncateText(designation, 28)}
          </Typography>
        </Box>
      </Box>

      {/* Messages */}
      <Box
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          padding: "16px",
          backgroundColor: "#f8f9fa",
          backgroundImage: "linear-gradient(#f1f3f5 1px, transparent 1px)",
          backgroundSize: "100% 12px",
        }}
      >
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress size={24} />
          </Box>
        ) : messages.length > 0 ? (
          messages.map((msg, index) => {
            isMine =
              msg?.senderModel === "Recruiter" ||
              msg?.sender?.userModel === "Recruiter";

            return (
              <Box
                key={msg._id || index}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: isMine ? "flex-end" : "flex-start",
                  mb: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    maxWidth: "80%",
                    flexDirection: isMine ? "row-reverse" : "row",
                    alignItems: "flex-end",
                    gap: 1,
                  }}
                >
                  <Box
                    sx={{
                      padding: "10px 14px",
                      borderRadius: isMine
                        ? "18px 4px 18px 18px"
                        : "4px 18px 18px 18px",
                      backgroundColor: isMine ? "#000" : "#ffffff",
                      color: isMine ? "#ffffff" : "#212529",
                      boxShadow: "0 1px 2px rgba(0, 0, 0, 0.08)",
                      wordBreak: "break-word",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "satoshi",
                        fontSize: "0.875rem",
                        lineHeight: 1.4,
                      }}
                    >
                      {msg.message}
                    </Typography>
                  </Box>
                </Box>
                <Typography
                  variant="caption"
                  sx={{
                    display: "block",
                    color: "#6c757d",
                    fontFamily: "'Inter', sans-serif",
                    fontSize: "0.7rem",
                    mt: 0.5,
                    ml: isMine ? 0 : 6,
                    mr: isMine ? 6 : 0,
                  }}
                >
                  {new Date(msg.timestamp).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </Typography>
              </Box>
            );
          })
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              textAlign: "center",
              color: "#6c757d",
            }}
          >
            <Typography variant="body2" sx={{ mb: 1 }}>
              No Conversations Yet
            </Typography>
          </Box>
        )}
        <div ref={messagesEndRef} />
      </Box>

      {/* Input area */}
      <Box
        sx={{
          padding: "12px 16px",
          backgroundColor: "#ffffff",
          borderTop: "1px solid #e9ecef",
        }}
      >
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Type a message..."
            disabled={!isSocketConnected || isCreatingChat}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "20px",
                backgroundColor: "#f1f3f5",
                "& fieldset": {
                  borderColor: "transparent",
                },
                "&:hover fieldset": {
                  borderColor: "transparent",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#423bfa",
                  borderWidth: "1px",
                },
              },
              "& .MuiInputBase-input": {
                fontFamily: "satoshi",
                fontSize: "0.875rem",
                padding: "10px 16px",
              },
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                handleSendMessageDebounced();
              }
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSendMessageDebounced}
            disabled={!isSocketConnected || !input.trim() || isCreatingChat}
            sx={{
              minWidth: "auto",
              width: 40,
              height: 40,
              borderRadius: "50%",
              backgroundColor: "#000",
              "&:hover": {
                backgroundColor: "#000",
              },
              "&:disabled": {
                backgroundColor: "#e9ecef",
              },
            }}
          >
            <SendIcon sx={{ fontSize: 18 }} />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CandidateChatModal;
