import { IconButton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useRef } from 'react';
import Slider from 'react-slick';
import { ChevronRight, ChevronLeft } from "@mui/icons-material";
import Testimonal1 from '../../../assets/images/testimonal1.png';
import Testimonal2 from '../../../assets/images/testimonal2.png';
import Testimonal3 from '../../../assets/images/testimonals3.png';
import Testimonal4 from '../../../assets/images/testimonals4.png';
import Testimonal5 from '../../../assets/images/testimonal5.png';
import Testimonal6 from '../../../assets/images/testimonal6.png';
import VacanziLogo from '../../../assets/icons/logos/vacanzi.png';

const testimonials = [
  {
    quote:
      "Vacanzi has completely transformed how we approach hiring. The video-first feature allowed us to evaluate candidates' communication skills and personalities before interviews, saving us countless hours. It’s a game-changer for modern recruitment!",
    author: "Sonal Mehta",
    position: "CHRO",
    company: "Zenith Technologies",
    image:Testimonal1,
    logo: VacanziLogo,
  },
  
  {
    quote:
      "Vacanzi makes hiring personal and efficient. The video CVs allowed us to understand candidates beyond their resumes, leading to better cultural fits and improved retention rates. It's innovative and human-centric.",
    author: "Riya Singh",
    position: "Talent Acquisition Lead",
    company: "VirtuNova Corp",
    image:Testimonal2,
    logo: VacanziLogo,
    
    },
    {
      quote:
        "As someone managing large-scale recruitment drives, Vacanzi has been a blessing. The platform’s simplicity and AI-powered matching have drastically reduced our time-to-hire while helping us find top-quality candidates.",
      author: "Arun Kumar",
      position: "VP of HR",
      company: "NexGen Solutions",
      image:Testimonal3,
      logo: VacanziLogo,
    },
  
  {
    quote:
      "The ability to see candidates' personalities shine through video resumes has been revolutionary. It’s a platform that brings talent to life, helping us make confident hiring decisions with much greater ease.",
    author: "Anjali Verma",
    position: "Senior HR Manager",
    company: "Synergy Systems",
    image:Testimonal4,
    logo: VacanziLogo,
    
    },
    {
      quote:
        "With Vacanzi, our recruitment team could screen more candidates in less time. The platform’s interactive features, like direct chat with candidates, have enhanced collaboration and efficiency in ways we hadn’t imagined.",
      author: "Prakash Sharma",
      position: "Director of HR",
      company: "AxisGlobal",
      image:Testimonal5,
      logo: VacanziLogo,
    
      },
  {
    quote:
      "Vacanzi has streamlined our recruitment process like never before. The AI-powered recommendations and ability to evaluate soft skills upfront have significantly cut down on wasted interviews and improved our hiring outcomes.",
    author: "Ramesh Gupta",
    position: "Head of Recruitment",
    company: "TalentAxis Inc.",
    image:Testimonal6,
    logo: VacanziLogo,
   
    },
];



const Employertestimonial = () => {
  const sliderRef = useRef(null);
 

  // Slider settings
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 2500, // Set autoplay speed to 3 seconds
  };

  const goToNext = () => {
    sliderRef.current.slickNext();
  };

  const goToPrev = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <Box sx={{ bgcolor: "#ffffff", py: 8, px: { xs: 2, md: 6 } }}>
      <Typography
        variant="h2"
        component="h2"
        align="center"
        sx={{
          mb: 6,
          fontWeight: "bold",
          color: "#1A1A1A",
          fontFamily: "IBM Plex Sans",
        }}
      >
        Testimonials
      </Typography>

      <Box sx={{ position: "relative", maxWidth: 1200, mx: "auto" }}>
        {/* Previous Button */}
        <IconButton
          onClick={goToPrev}
          sx={{
            position: "absolute",
            left: { xs: -16, md: -24 },
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: 1,
            bgcolor: "white",
            "&:hover": { bgcolor: "grey.100" },
          }}
        >
          <ChevronLeft />
        </IconButton>

        {/* Slider */}
        <Slider ref={sliderRef} {...settings}>
          {testimonials.map((testimonial, index) => (
            <Box key={index}>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: { xs: "1fr", md: "400px 1fr" },
                  gap: 4,
                  alignItems: "center",
                  px: { xs: 2, md: 4 },
                }}
              >
                {/* Image Section */}
                <Box
                  sx={{
                    borderRadius: 2,
                    overflow: "hidden",
                    height: { xs: 300, md: 400 },
                    bgcolor: "#FFE4D6",
                  }}
                >
                  <Box
                    component="img"
                    src={testimonial.image}
                    alt="Testimonial"
                    sx={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Box>

                {/* Content Section */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 3,
                    fontFamily: "IBM Plex Sans",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      color: "#1A1A1A",
                      fontWeight: 500,
                      lineHeight: 1.6,
                      mb: 2,
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    {testimonial.quote}
                  </Typography>

                  <Box
                    sx={{
                      width: "100%",
                      height: 1,
                      borderBottom: "2px dashed #E0E0E0",
                      my: 2,
                    }}
                  />

                  <Box>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "bold",
                        color: "#1A1A1A",
                        mb: 0.5,
                        fontFamily: "IBM Plex Sans",
                      }}
                    >
                      {testimonial.author}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        color: "#666",
                        mb: 2,
                        fontFamily: "IBM Plex Sans",
                      }}
                    >
                      {testimonial.position}
                    </Typography>
                    <Box
                      component="img"
                      src={testimonial.logo}
                      alt={testimonial.company}
                      sx={{
                        height: 40,
                        width: "auto",
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
        </Slider>

        {/* Next Button */}
        <IconButton
          onClick={goToNext}
          sx={{
            position: "absolute",
            right: { xs: -16, md: -24 },
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: 1,
            bgcolor: "white",
            "&:hover": { bgcolor: "grey.100" },
          }}
        >
          <ChevronRight />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Employertestimonial;