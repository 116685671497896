import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import baseURL from "../../../api/baseUrl";
import CompanyOverView from "../CompanyOverView/CompanyOverView";
import CircularProgress from "@mui/material/CircularProgress"; // Import a loading spinner
import { Box } from "@mui/system"; // Import Box for centering
import { useRef } from "react";

const JobSeekerJobDetails = () => {
  const { id } = useParams();
  const [job, setJob] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true); // Add a loading state

  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem("authToken");

  const hasFetched = useRef(false);

  useEffect(() => {
    if (hasFetched.current) return; // Skip if already fetched
    hasFetched.current = true;
  
    const fetchJob = async () => {
      try {
        const endpoint = !userId
          ? `${baseURL}/jobs/${id}`
          : `${baseURL}/jobs/incrementview/${id}`;
  
        const method = userId ? "POST" : "GET";
  
        const headers = {
          "Content-Type": "application/json",
        };
  
        if (userId) {
          headers.Authorization = `Bearer ${token}`;
        }
  
        const response = await fetch(endpoint, {
          method,
          headers,
        });
  
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
  
        const data = await response.json();
        setJob(data?.job);
      } catch (error) {
        console.error("Error fetching job:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
  
    fetchJob();
  }, [id, userId, token]);

  if (error) {
    return <p>Error: {error}</p>; // Display an error message if there's an error
  }

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh" // Center the spinner vertically and horizontally
      >
        <CircularProgress /> {/* Display a loading spinner */}
      </Box>
    );
  }

  return (
    <div>
      {job ? (
        <CompanyOverView job={job} setJob={setJob} />
      ) : (
        <p>No job data found.</p> // Display a message if no job data is found
      )}
    </div>
  );
};

export default JobSeekerJobDetails;