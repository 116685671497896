import React from "react";
import {
  Dialog,
  DialogTitle,
  Typography,
  Box,
} from "@mui/material";
import successlogo from "../../assets/images/success.gif";

const SuccessModal = ({ open, handleClose }) => {
  return (
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm" // Control the max width (e.g., "xs", "sm", "md")
        fullWidth={true} // Allow the modal to take full width up to maxWidth
        PaperProps={{
          sx: {
            padding: 4, // Add padding inside the modal
            borderRadius: "12px", // Rounded corners for a smoother look
            width: "auto", // Make sure width adjusts to content
            minWidth: "300px", // Optional: set a minimum width for a more consistent look
            backgroundColor: "rgba(255, 255, 255, 0.9)", // Slightly transparent white for a clean look
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
          },
        }}
        BackdropProps={{
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.4)", // Semi-transparent gray overlay
            backdropFilter: "blur(8px)", // Blur effect for the background
          },
        }}
      >
        <DialogTitle align="center" sx={{ paddingBottom: 2 }}>
          <Box sx={{ marginBottom: 2 }}>
            <img
              src={successlogo}
              alt="successlogo"
              style={{
                width: 150, // Adjust the size of the gif
                height: 150,
                objectFit: "contain",
              }}
            />
          </Box>
          <Typography variant="h5" gutterBottom sx={{fontFamily:'poppins'}}>
          Registration Successful! <br />
          Thank you for signing up! Your account has been successfully created.
          </Typography>
        </DialogTitle>
      
      </Dialog>
  );
};

export default SuccessModal;
