import React, { useState,useCallback } from "react";
import CourseHero from "./CourseHero";
// import CourseTopUniversities from './CourseTopUniversities'
import CourseDetails from "./CourseDetails";
import CourseAdvantage from "./CourseAdvantage";
import { useRef } from "react";
import { courseFormApi } from "../../../api/common";
import Footer from "../../../components/JobSeekerComponents/Footer/Footer";
import PopupSuccess from "../../../utils/popup/PopupSuccess";
import ReleaseJobPostModel from "../../../pages/EmployerPages/SubscriptionStatus/ReleaseJobPostModel";

const CourseHome = () => {
  const [type, setType] = useState("");
  const courseSectionRef = useRef(null);
  const token = localStorage.getItem("authToken");
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  // Get the 0th index resume

  const handleSuccess = useCallback((message) => {
    setSuccessMessage(message);
    setSuccessOpen(true);
    setTimeout(() => {
      setSuccessOpen(false);
      setSuccessMessage("");
    }, 2000);
  }, []);

  const handleError = useCallback((message) => {
    setErrorMessage(message);
    setErrorOpen(true);
    setTimeout(() => {
      setErrorOpen(false);
      setErrorMessage("");
    }, 2000);
  }, []);

  const scrollToCourseDetails = () => {
    courseSectionRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    phone: "",
    type: type,
    courseId: "",
  });

  const handleSubmitContactForm = async () => {
    try {
      const response = await courseFormApi(token, formData);
      if (response) {
        handleSuccess(response.message || "Campaign created successfully.")
        
      }
      // setFormData({
      //   fullname:"",
      //   email:"",
      //   phone:'',
      //   type:""
      // })
    } catch (error) {
      handleError(error.message || "Failed to create campaign.")
         }
  };

  return (
    <>
      <CourseHero
        scrollToCourseDetails={scrollToCourseDetails}
        setType={setType}
        formData={formData}
        setFormData={setFormData}
        handleSubmitContactForm={handleSubmitContactForm}
      />
      <div
        style={{
          background: "linear-gradient(180deg, #F9FBFB 45%, #E3F0F0 100%)", // Very light grayish-white to soft teal
          minHeight: "100vh",
          padding: "20px 0",
        }}
      >
        {/* <CourseTopUniversities setFormData={setFormData} formData={formData}/> */}
        <div ref={courseSectionRef}>
          <CourseDetails
            setType={setType}
            formData={formData}
            setFormData={setFormData}
            handleSubmitContactForm={handleSubmitContactForm}
            type={type}
          />
        </div>

        <CourseAdvantage />
      </div>
      <PopupSuccess
        modelOpen={successOpen}
        onClose={() => setSuccessOpen(false)}
        message={successMessage}
      />
      <ReleaseJobPostModel
        modelOpen={errorOpen}
        onClose={() => setErrorOpen(false)}
        message={errorMessage}
      />
      <Footer />
    </>
  );
};

export default CourseHome;
