import { Button, CircularProgress } from "@mui/material";
import React from "react";
import GoogleIconImg from "../../../assets/icons/logos/google-icon.png";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import baseURL from "../../../api/baseUrl";
import { getToken } from "firebase/messaging";
import { useState } from "react";
import { useEffect } from "react";
import { messaging } from "../../../utils/firebaseConfig";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import { useJobseeker } from "../../../context/UserContext";




const useFCMToken = () => {
  const [fcmToken, setFcmToken] = useState();
  
  useEffect(() => {
    const fetchFcmToken = async () => {
      try {
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
          const token = await getToken(messaging, {
            vapidKey: process.env.REACT_APP_PRIVATE_VAPID_KEY,
          });
         
          setFcmToken(token);
        } else {
          console.log("notification permission denied");
        }
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchFcmToken();
  }, []);
  return fcmToken;
};

const GoogleSignIn = () => {
    const [isLoading, setIsLoading] = useState(false); // Loading state
    const fcmToken = useFCMToken(); // FCM token from custom hook
    const navigate = useNavigate(); // Navigation hook
    const {setUserData} = useJobseeker()
    // Handle Google login success
    const handleSuccess = async (credentialResponse) => {
     
  
      // Decode the JWT token
      const decoded = jwtDecode(credentialResponse.credential);

  
  
      // Extract required data from the decoded token
      const { email, picture: googleProfileURL, name: fullName } = decoded;
  
      // Prepare payload for the API
      const payload = {
        email,
        googleProfileURL,
        fullName,
        fcm_token: fcmToken,
        platform: "web",
        version: "1.0.0",
        app_version: "1.0.0",
        googleToken:credentialResponse.credential,
      };
  
      
  
      try {
        setIsLoading(true); // Set loading state to true
  
        // Send the payload to the API
        const response = await fetch(`${baseURL}/signinGoogle`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });
  
        // Handle API response
        if (response.ok) {
          const data = await response.json();
         
  
          // Save the token to localStorage
          localStorage.setItem("authToken", data.token);
          localStorage.setItem('refreshToken',data.refreshToken)
          localStorage.setItem('userId',data.user._id)
          localStorage.setItem('profileImage',data.user.profileImage)
          localStorage.setItem("userName",data.user.fullName)
          setUserData(data.user)
  
          // Redirect based on whether the user is new or existing
          if (data.isNewUser) {
            navigate("/jobSeekerRegestrationProfile");
          } else {
            navigate('/',{replace:true});
          }
        } else {
          const errorData = await response.json();
          console.error("Google login failed:", errorData.message);
        }
      } catch (error) {
        console.error("Error:", error.message);
      } finally {
        setIsLoading(false); // Set loading state to false
      }
    };
  
    // Handle Google login error
    const handleError = () => {
      console.log("Login Failed");
    };
  
  return (

    <GoogleLogin
    onSuccess={handleSuccess}
    onError={handleError}
    useOneTap
    render={({ onClick }) => (
      <Button
        variant="contained"
        fullWidth
        sx={{
          backgroundColor: "#fff",
          color: "black",
          fontWeight: "bold",
          borderRadius: "30px",
          fontFamily: "IBM Plex Sans, sans-serif",
          textTransform: "none",
          position: "relative",
          opacity: 0.8,
          mb: 2,
          ":hover": {
            backgroundColor: "#f5f5f5",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          },
          ":active": {
            backgroundColor: "#e0e0e0",
          },
        }}
        onClick={onClick}
        disabled={isLoading}
        TouchRippleProps={{
          style: {
            color: "#4285F4",
          },
        }}
      >
        {isLoading ? (
          <CircularProgress size={24} sx={{ color: "black" }} />
        ) : (
          <>
            <Box
              sx={{
                position: "absolute",
                left: "16px",
                top: "50%",
                transform: "translateY(-50%)",
                transition: "transform 0.2s ease-in-out",
                ":hover": {
                  transform: "translateY(-50%) scale(1.1)",
                },
              }}
            >
              <img
                src={GoogleIconImg}
                alt="Google logo"
                style={{
                  width: "24px",
                  height: "24px",
                }}
              />
            </Box>
            Sign in with Google
          </>
        )}
      </Button>
    )}
  />
    
  );
};

export default GoogleSignIn;
