import React from "react";
import { Box, Typography, Grid, Container } from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";
import chatGif from "../../../assets/images/chat.gif";

const FeaturedEmployers = () => {
  const theme = useTheme();
  const isXl = useMediaQuery(theme.breakpoints.up("xl"));
  const isLg = useMediaQuery(theme.breakpoints.up("lg"));
  const isMd = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box sx={{ backgroundColor: "#fff", py: 4 }}>
      <Container maxWidth="xl">
        <Grid container spacing={4} alignItems="center">
          {/* Left Section - Chat Image */}
          <Grid item xs={12} md={6} display="flex" flexDirection="column" alignItems="center">
            <Typography
              sx={{
                fontFamily: "Satoshi",
                backgroundColor: "#0074fd",
                px: 2,
                py: "4px",
                borderRadius: "12px",
                color: "#fff",
                fontWeight: "bold",
                fontSize: 14,
                mb: 1,
              }}
            >
              Instant Chat
            </Typography>

            <img
              src={chatGif}
              alt="Chat Animation"
              style={{
                width: "100%",

                maxWidth: isXl
                  ? "300px"
                  : isLg
                  ? "230px"
                  : isMd
                  ? "230px"
                  : "150px", // Adjust maxWidth based on the screen size
                height: isXl ? "500px" : "450px",
                objectFit: "cover",
                borderRadius: "12px",
                margin: 0,
              }}
            />

            <Typography
              sx={{
                fontFamily: "Satoshi",
                backgroundColor: "#0074fd",
                px: 2,
                py: "4px",
                borderRadius: "12px",
                color: "#fff",
                fontWeight: "bold",
                fontSize: 14,
                mt: 1,
              }}
            >
              Live Conversations
            </Typography>
          </Grid>

          {/* Right Section - Text Content */}
          <Grid item xs={12} md={6}>
            <Typography
              variant="h3"
              textAlign={isMd ? "start" : "center"}
              sx={{
                fontWeight: "bold",
                fontFamily: "IBM Plex Sans",
                color: "#000",
              }}
            >
              Instantly chat & call recruiters
            </Typography>
            <Typography
              variant="h6"
              textAlign="center"
              sx={{
                fontFamily: "IBM Plex Sans",
                mt: 2,
              }}
            >
              We connect you to the right organization & best opportunities!
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default FeaturedEmployers;
