import React, { useEffect } from "react";
import {
  Typography,
  Grid,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
} from "@mui/material";
import NewNavBar from "../../../components/JobSeekerComponents/NavBar/NewNavbar";
import { useNavigate } from "react-router-dom";
import { getCunsellorsApi } from "../../../api/common";
import { useState } from "react";
// import { FourSquare } from "react-loading-indicators";
import { styled } from "@mui/system";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import connectbg from "../../..//assets/videos/connectBg.mp4";
import Footer from "../../../components/JobSeekerComponents/Footer/Footer";

const styles = {
  pageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 2,
    p: 2,
    overflowX: "auto",
  },
  card: {
    minWidth: 250,
    p: 2,
    cursor: "pointer",
    border: "1px solid",
    borderColor: "grey.300",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 2,
  },
  subtitle: {
    fontSize: "0.875rem",
    color: "#666666",
  },
  cardTitle: {
    fontSize: "30px",
    fontFamily: "Satoshi",
    fontWeight: 600,
    color: "#fff",
  },
  gridIcon: {
    width: 40,
    height: 40,
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: 0.5,
    padding: 0.5,
    border: "1px solid #e0e0e0",
    borderRadius: 1,
  },
  gridIcon3x3: {
    width: 40,
    height: 40,
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: 0.5,
    padding: 0.5,
    border: "1px solid #e0e0e0",
    borderRadius: 1,
  },
  gridCell: {
    borderRadius: 0.5,
  },
  followButton: {
    textTransform: "none",
    py: 0,
    px: 2,
    width: "70%",
    borderRadius: 4,
    fontFamily: "satoshi",
    fontWeight: 600,
    marginLeft: "30px",
    mb: 2,
  },
};

const StyledCard = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "350px",
  height: "140px",
  padding: "32px",
  background: "rgba(255, 255, 255, .08)",
  backdropFilter: "blur(24px)",
  WebkitBackdropFilter: "blur(24px)",
  border: "1px solid rgba(255, 255, 255, 0.2)",
  boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
  cursor: "pointer",
  transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
  "&:hover": {
    transform: "scale(1.02)",
    boxShadow: "0 6px 35px rgba(0, 0, 0, 0.15)",
  },
}));

const Simstyles = {
  container: {
    bgcolor: "rgb(247, 245, 238)",
    borderRadius: 10,
    p: 3,
    mb: 4,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    mb: 3,
  },
  title: {
    fontSize: "1.5rem",
    fontFamily: "Satoshi",
    fontWeight: 600,
    color: "#000",
  },
  dismissButton: {
    fontSize: "0.875rem",
    color: "rgba(0, 0, 0, 0.6)",
    textTransform: "none",
    "&:hover": {
      bgcolor: "rgba(0, 0, 0, 0.04)",
    },
  },
  gridContainer: {
    mt: 1,
    padding: 2,
    borderRadius: 2,
  },
  profileCard: {
    minWidth: 150,
    cursor: "pointer",
    bgcolor: "#fff",
    border: "1px solid",
    borderColor: "grey.300",
    borderRadius: 3,
    boxShadow: "none",
    "&:hover": {
      boxShadow: "0 2px 8px rgba(0,0,0,0.08)",
    },
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "220px",
    justifyContent: "space-between", // Space between content and button
  },
  cardContent: {
    fontFamily: "Satoshi",
    padding: 1.5,
    "&:last-child": {
      paddingBottom: 1.5,
    },
    display: "flex",
    flexDirection: "column", // Changed to row to align avatar on the left
    alignItems: "start",
    textAlign: "left",
    gap: 1.5, // Space between avatar and text
    flexGrow: 1, // Allow content to grow and fill space
  },
  avatar: {
    width: 60,
    height: 60,
    mb: 0, // Remove bottom margin since we're aligning horizontally
  },
  name: {
    fontSize: "0.9rem",
    fontFamily: "Satoshi",
    fontWeight: 600,
    color: "#000",
    mb: 0.5,
  },
  description: {
    fontSize: "0.75rem",
    fontFamily: "Satoshi",
    color: "rgba(0, 0, 0, 0.6)",
    lineHeight: 1.3,
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
};

const Connect = () => {
  const [counsellors, setCounsellors] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const service = [
    {
      subtitle: "Harmonize the grid",
      title: "Review you",
      icon: <PersonSearchIcon sx={{ color: "#fff", fontSize: "35px" }} />,
      type: "2x2",
    },
    {
      subtitle: "Crown each region",
      title: "Prepare you",
      icon: <ThumbUpIcon sx={{ color: "#fff", fontSize: "35px" }} />,
      type: "2x2",
    },
    {
      subtitle: "Guess the category",
      title: "Connect you",
      icon: (
        <ConnectWithoutContactIcon sx={{ color: "#fff", fontSize: "35px" }} />
      ),
      type: "3x3",
    },
  ];

  useEffect(() => {
    setLoading(true);
    const fetchCounsellors = async () => {
      try {
        const response = await getCunsellorsApi();
        setCounsellors(response);
      } catch (error) {
        console.log(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchCounsellors();
  }, []);

  const handleNavigate = (id) => {
    navigate(`/counsellorProfile/${id}`);
  };

  return (
    <>
      <NewNavBar />
      {loading ? (
        <Box
          sx={{
            width: "100%",
            height: "100vh", // Full viewport height
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#FFFFFF",
          }}
        >
          <img
            src="/loading.gif"
            alt="Loading"
            style={{ width: 200, height: 200 }}
          />
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "start",
            justifyContent: "center",
            zIndex: 1,
            position: "relative",
          }}
        >
          <Box sx={styles.pageContainer}>
            <Box
              sx={{
                position: "relative",
                overflow: "hidden",
                minHeight: "100vh",
                minWidth: "100vw",
              }}
            >
              <video
                autoPlay
                loop
                muted
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "88%",
                  objectFit: "cover",
                  zIndex: -9999,
                }}
              >
                <source src={connectbg} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  p: 20,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "space-between",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="h2"
                    sx={{
                      mt: 5,
                      color: "#ffffff",
                      fontFamily: "gilroy",
                    }}
                  >
                    Perfect Job & Employer Match <br /> In Just 10 Minutes
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#fff",
                      color: "#000",
                      borderRadius: "0.7rem",
                      textTransform: "none",
                      fontSize: "14px",
                      padding: "10px 18px",
                      mt: 3,
                      fontWeight: "bold",
                      fontFamily: "satoshi",
                      ":hover": {
                        backgroundColor: "#fff",
                        color: "#000",
                      },
                    }}
                    onClick={() => navigate("/signInSignup")}
                  >
                    Get Started - 60 Minutes For Free
                  </Button>
                  {/* <Typography
                    sx={{
                      fontFamily: "satoshi",
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "#fff",
                      ml: 2,
                      mt: 0.5,
                    }}
                  >
                    Post 60 Minutes ₹ 1 / Minute
                  </Typography> */}
                </Box>
                <Box sx={styles.container}>
                  {service.map((item, index) => (
                    <StyledCard
                      key={index}
                      sx={{ ...styles.card, borderRadius: "24px", mt: 10 }}
                      elevation={0}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 2,
                        }}
                      >
                        <Typography sx={styles.cardTitle}>
                          {item.title}
                        </Typography>
                      </Box>
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 2 }}
                      >
                        {item.icon}
                      </Box>
                    </StyledCard>
                  ))}
                </Box>
              </Box>
            </Box>
            <Container maxWidth="xl">
              <Box sx={Simstyles.container}>
                <Typography variant="h4"  sx={{ fontWeight: "bold",mb:'10px' }}>
                  Accelerate Your Career for Free
                </Typography>
                <Typography sx={{ fontSize: "1.4rem" }}>
                  Gain Exclusive Guidance from Top Industry Experts & Career
                  Counsellors
                </Typography>

                <Grid
                  container
                  spacing={2}
                  justifyContent="center"
                  sx={Simstyles.gridContainer}
                >
                  {counsellors?.map((person) => (
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      md={2.4}
                      key={person._id}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <Card
                        sx={Simstyles.profileCard}
                        onClick={() => handleNavigate(person._id)}
                      >
                        <CardContent sx={Simstyles.cardContent}>
                          <Avatar
                            src={person.profileImage}
                            alt={person.name}
                            sx={Simstyles.avatar}
                          />
                          <Box sx={{ flexGrow: 1, overflow: "hidden" }}>
                            <Typography sx={Simstyles.name}>
                              {person.fullName}
                            </Typography>
                            <Typography sx={Simstyles.description}>
                              {person.bio}
                            </Typography>
                          </Box>
                        </CardContent>
                        <Button
                          variant="outlined"
                          fullWidth
                          sx={styles.followButton}
                          startIcon={<span>+</span>}
                        >
                          Contact Now
                        </Button>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Container>
          </Box>
        </Box>
      )}
      <Footer />
    </>
  );
};

export default Connect;
