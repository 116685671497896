import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Box } from "@mui/system";

function KYCDetailsModel({ openKYCDialog, handleKYCDialogClose }) {
  return (
    <Dialog open={openKYCDialog} onClose={handleKYCDialogClose}>
      <DialogTitle>Edit KYC Details</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            width: "full",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "red",
            fontSize: 13,
          }}
        >
          Complete KYC verification to access Vacanzi features
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          sx={{
            bgcolor: "#000000",
            textTransform:"none",
            borderRadius: "0.7rem",
            color: "white",
            ":hover": {
              backgroundColor: "#000000",
            },
          }}
          onClick={handleKYCDialogClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default KYCDetailsModel;
