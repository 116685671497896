import {
  CalendarTodayOutlined,
  EmailOutlined,
  LocationOnOutlined,
  PhoneOutlined,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Paper,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { removedShortlistedApi, sendInviteApi } from "../../../api/sendInvite";
import { getShortListedUsersApi } from "../../../api/jobPostAPI";
import { ThreeDot } from "react-loading-indicators";
import savedSearchStyles from "../../../styles/savedSearcheStyles";
import PopupSuccess from "../../../utils/popup/PopupSuccess";
import ReleaseJobPostModel from "../SubscriptionStatus/ReleaseJobPostModel";

const ShortlistedCandidates = ({ jobData, token, jobId, setJobData }) => {
  const [shortlist, setShortlist] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingStates, setLoadingStates] = useState({});
  const [shortlistLoading, setShortlistLoading] = useState({});

  const [errorOpen, setErrorOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleSuccess = useCallback((message) => {
    setSuccessMessage(message);
    setSuccessOpen(true);
    setTimeout(() => {
      setSuccessOpen(false);
      setSuccessMessage("");
    }, 3000);
  }, []);

  const handleError = useCallback((message) => {
    setErrorMessage(message);
    setErrorOpen(true);
    setTimeout(() => {
      setErrorOpen(false);
      setErrorMessage("");
    }, 3000);
  }, []);

  const fetchShortlisted = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getShortListedUsersApi(jobId, token);
      setShortlist(response);
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  }, [jobId, token]);

  useEffect(() => {
    fetchShortlisted();
  }, [fetchShortlisted]);

  console.log("shortlist", shortlist);

  const sendInvite = useCallback(
    async (userId) => {
      setLoadingStates((prev) => ({ ...prev, [userId]: true }));
      try {
        const invitationData = {
          userIds: [userId],
          jobId: jobData?._id,
        };
        const response = await sendInviteApi(token, invitationData);

        if (response) {
          setJobData((prevJobData) => ({
            ...prevJobData,
            invites: [
              ...(prevJobData.invites || []),
              { user: { _id: userId } },
            ],
          }));
          // Trigger success popup for invite
        handleSuccess("Invitation sent successfully!") // Auto-close after 2 seconds
        }
      } catch (error) {
        console.error("Error sending invite:", error);
        // Trigger error popup for invite
            handleError(error.message || "Failed to send invite")
      } finally {
        setLoadingStates((prev) => ({ ...prev, [userId]: false }));
      }
    },
    [token, jobData?._id, setJobData,handleError,handleSuccess]
  );

  const removeShortlist = async (userId) => {
    const formValues = {
      userIds: [userId],
    };
    setShortlistLoading((prev) => ({ ...prev, [userId]: true }));
    try {
      const response = await removedShortlistedApi(jobId, token, formValues);
      if (response) {
        setJobData((prevJobData) => ({
          ...prevJobData,
          shortlistApplicants: (prevJobData.shortlistApplicants || []).filter(
            (applicant) => applicant.userId !== userId
          ),
        }));
        setShortlist((prevShortlist) => ({
          ...prevShortlist,
          shortlistedApplicants: (
            prevShortlist.shortlistedApplicants || []
          ).filter((candidate) => candidate?.userId?._id !== userId),
        }));

        handleSuccess(
          response.message || "Candidate removed from shortlist successfully!"
        );
      }
    } catch (error) {
      console.log(error.message);
      // Trigger error popup for remove
      handleError(error.message || "Failed To Remove , Please Try Again"); // Auto-close after 2 seconds
    } finally {
      setShortlistLoading((prev) => ({ ...prev, [userId]: false }));
    }
  };

  return (
    <>
      {loading ? (
        <Box sx={{ ...savedSearchStyles.parentBox, textAlign: "start" }}>
          <CircularProgress size={40} sx={{ mb: 2 }} />
        </Box>
      ) : (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "20px",
          }}
        >
          {shortlist?.shortlistedApplicants?.length > 0 ? (
            shortlist?.shortlistedApplicants.map((candidate, index) => {
              const isLoading = loadingStates[candidate?._id] || false;
              const isRemoving = shortlistLoading[candidate?._id] || false;
              const isInvited = jobData?.invites?.some(
                (item) => item?.user?._id === candidate?.userId?._id
              );

              return (
                <Paper
                  key={index}
                  elevation={0}
                  sx={{
                    padding: 3,
                    backgroundColor: "#ffffff",
                    cursor: "pointer",
                    borderRadius: "0.7rem",
                    border: "1px solid",
                    borderColor: "grey.400",
                    width: "100%",
                    fontFamily: "poppins",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    overflow: "hidden",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: 0.5,
                      mt: 1,
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: "bold", fontFamily: "poppins" }}
                    >
                      {candidate?.userId?.fullName}
                    </Typography>
                    <Avatar
                      alt={candidate?.fullName}
                      src={candidate?.userId?.profileImage}
                      sx={{ width: 56, height: 56 }}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", sm: "row" },
                      alignItems: { xs: "flex-start", sm: "center" },
                      justifyContent: "space-between",
                      mt: 2,
                    }}
                  >
                    <Box
                      sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                    >
                      <EmailOutlined fontSize="small" />
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        fontFamily="poppins"
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "normal",
                          display: "-webkit-box",
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {candidate?.userId?.email}
                      </Typography>
                    </Box>

                    <Box
                      sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                    >
                      <PhoneOutlined fontSize="small" />
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        fontFamily="poppins"
                      >
                        {candidate?.userId?.phone || "Not Mentioned"}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", sm: "row" },
                      alignItems: { xs: "flex-start", sm: "center" },
                      justifyContent: "space-between",
                      mt: 2,
                    }}
                  >
                    <Box
                      sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                    >
                      <LocationOnOutlined fontSize="small" />
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        fontFamily="poppins"
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "normal",
                          display: "-webkit-box",
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {candidate?.userId?.currentLocation || "Not Mentioned"}
                      </Typography>
                    </Box>

                    <Box
                      sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                    >
                      <CalendarTodayOutlined fontSize="small" />
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        fontFamily="poppins"
                      >
                        {candidate?.shortlistedAt
                          ? new Date(
                              candidate?.shortlistedAt
                            ).toLocaleDateString()
                          : "Not Available"}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mt: 2,
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#000",
                        textTransform: "none",
                        fontFamily: "poppins",
                        borderRadius: "0.7rem",
                        "&:hover": { backgroundColor: "#000" },
                        mt: 1,
                      }}
                      onClick={() =>
                        navigate(`/viewJobSeekerProfile/${candidate?.userId?._id}`)
                      }
                    >
                      View Full Profile
                    </Button>

                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 3,
                      }}
                    >
                      <Button
                        sx={{
                          backgroundColor: "#000",
                          textTransform: "none",
                          fontFamily: "poppins",
                          color: "#fff",
                          borderRadius: "0.7rem",
                          "&:hover": { backgroundColor: "#000" },
                          mt: 1,
                        }}
                        onClick={() => removeShortlist(candidate?.userId?._id)}
                      >
                        {isRemoving ? (
                          <ThreeDot color="#fff" size="small" />
                        ) : (
                          "Remove"
                        )}
                      </Button>
                      <Button
                        sx={{
                        
                          textTransform: "none",
                          fontFamily: "poppins",
                          color: "#fff",
                          mt: 1, 
                          borderRadius: "0.7rem",
                          backgroundColor: isInvited ? "#B9B9B9FF" : "#000000",
                          ":hover": { backgroundColor: "#000000" },
                        }}
                        onClick={() => sendInvite(candidate?.userId?._id)}
                        disabled={isInvited || isLoading}
                      >
                        {isLoading ? (
                          <ThreeDot color="#fff" size="small" />
                        ) : isInvited ? (
                          "Invited"
                        ) : (
                          "Send Invite"
                        )}
                      </Button>
                    </Box>
                  </Box>
                </Paper>
              );
            })
          ) : (
            <Typography
              variant="h6"
              sx={{
                textAlign: "center",
                fontFamily: "poppins",
                color: "gray",
              }}
            >
              No Candidate ShortListed
            </Typography>
          )}
        </Box>
      )}
      {/* Popup for Send Invite */}
      <ReleaseJobPostModel
        modelOpen={errorOpen}
        onClose={() => setErrorOpen(false)}
        message={errorMessage}
      />
      <PopupSuccess
        modelOpen={successOpen}
        onClose={() => setSuccessOpen(false)}
        message={successMessage}
      />
    </>
  );
};

export default ShortlistedCandidates;
