import baseURL from "./baseUrl";

export const getTopOrgs = async () => {
  const authToken = localStorage.getItem("authToken")?.replace(/"/g, "");

  try {
    const response = await fetch(`${baseURL}/org/getFeaturedOrg`, {
      method: "GET",
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      throw new Error("network response was not ok");
    }
    return response.json();
  } catch (error) {
    console.log(error.message);
  }
};

export const getTopIndustries = async () => {
  const authToken = localStorage.getItem("authToken")?.replace(/"/g, "");

  try {
    const response = await fetch(`${baseURL}/jobpost/industry`, {
      method: "GET",
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      throw new Error("network response was not ok");
    }
    return response.json();
  } catch (error) {
    console.log(error.message);
  }
};

export const getFeaturedJobSeekers = async () => {
  const authToken = localStorage.getItem("authToken")?.replace(/"/g, "");

  try {
    const response = await fetch(`${baseURL}/featured/jobseekers`, {
      method: "GET",
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      throw new Error("network response was not ok");
    }
    return response.json();
  } catch (error) {
    console.log(error.message);
  }
};

export const getTrendingJobs = async () => {
  const authToken = localStorage.getItem("authToken")?.replace(/"/g, "");

  try {
    const response = await fetch(`${baseURL}/organization/trending-jobs`, {
      method: "GET",
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      throw new Error("network response was not ok");
    }
    return response.json();
  } catch (error) {
    console.log(error.message);
  }
};

export const getTopIndustryMasters = async () => {
  const authToken = localStorage.getItem("authToken")?.replace(/"/g, "");

  try {
    const response = await fetch(`${baseURL}/Industry`, {
      method: "GET",
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      throw new Error("network response was not ok");
    }
    return response.json();
  } catch (error) {
    console.log(error.message);
  }
};

export const getTopCitiesApi = async (query = "", isTopCity = false) => { 
  try {
    // Construct query parameters
    const params = new URLSearchParams();
    if (query) params.append("query", query); // Add query parameter if provided
    if (isTopCity) params.append("topCity", true); // Add topCity parameter if true

    // Make the API call with query parameters
    const response = await fetch(`${baseURL}/get/cities?${params.toString()}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

 
    return data; // Return the parsed JSON data
  } catch (error) {
    console.error("Error in getTopCitiesApi:", error.message);
    throw error; // Re-throw the error to handle it in the calling function
  }
};

export const getAllUgEducationsApi = async () => {
  try {
    const response = await fetch(`${baseURL}/ugEducation`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await response.json();
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return data;
  } catch (error) {
    console.error("Error in get education API:", error.message);
    throw error;
  }
};
export const getAllPgEducationsApi = async () => {
  try {
    const response = await fetch(`${baseURL}/pgEducation`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

  

    // Assuming the API response is an array, and items are inside the first element.
     
    return data;
  } catch (error) {
    console.error("Error in get education API:", error.message);
    throw error;
  }
};
export const getAllIndustriesApi = async () => {
    try {
      const response = await fetch(`${baseURL}/Industry`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
  
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
  
      const data = await response.json();
  
      if (!Array.isArray(data)) {
        throw new Error("Unexpected API response structure");
      }
  
      // Extract all industry names from nested items
      const allIndustries = data.flatMap((industry) => industry.items.map((item) => item.name));
  
      return allIndustries; // Return an array of industry names
    } catch (error) {
      console.error("Error in get industry API:", error.message);
      throw error;
    }
  };
  

export const allDepartmentsApi = async () => {
  try {
    const response = await fetch(`${baseURL}/departments`, {
      method: "GET",
      headers: {
        "Content-Type": "Application/json",
      },
    });

    if (!response.ok) {
      throw new Error("network response was not ok");
    }
    return response.json();
  } catch (error) {
    console.log(error.message);
  }
};

export const getCunsellorsApi = async () => {
  try {
    const response = await fetch(`${baseURL}/get/counsellors`, {
      method: "GET",
      headers: {
        "Content-Type": "Application/json",
      },
    });

    if (!response.ok) {
      throw new Error("network response was not ok");
    }
    return response.json();
  } catch (error) {
    console.log(error.message);
  }
};


export const getReleventJobSeekersApi = async (userId) => {
  try {
    const response = await fetch(`${baseURL}/relevantJs/${userId}`, {
      method: "GET",
      headers: {
        "Content-Type": "Application/json",
      },
    });

    if (!response.ok) {
      throw new Error("network response was not ok");
    }
    return response.json();
  } catch (error) {
    console.log(error.message);
  }
};



export const getCunsellorsByIdApi = async (counsellorId) => {
  try {
    const response = await fetch(`${baseURL}/get/counsellor/${counsellorId}`, {
      method: "GET",
      headers: {
        "Content-Type": "Application/json",
      },
    });

    if (!response.ok) {
      throw new Error("network response was not ok");
    }
    return response.json();
  } catch (error) {
    console.log(error.message);
  }
};

export const getAllOrganizationsApi = async () => {
  try {
    const response = await fetch(
      `${baseURL}/organization/organizations-by-industry`,
      {
        method: "GET",
        headers: {
          "Content-Type": "Application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return response.json(); // Return the parsed JSON data
  } catch (error) {
    console.error("Error fetching organizations:", error.message);
    throw error; // Re-throw the error to handle it in the calling function
  }
};


export const getDepartmentsApi = async () => {
  try {
    const response = await fetch(
      `${baseURL}/departments`,
      {
        method: "GET",
        headers: {
          "Content-Type": "Application/json",
          

        },
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return response.json(); // Return the parsed JSON data
  } catch (error) {
    console.error("Error fetching organizations:", error.message);
    throw error; // Re-throw the error to handle it in the calling function
  }
};


export const reportAnIssueApi = async (token, formValues) => {
  try {
    const response = await fetch(`${baseURL}/createTicket`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify(formValues),
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || "Login failed. Please try again.");
    }

    return data;
  } catch (error) {
    console.error("Login Error:", error);
    throw error;
  }
};


export const getAllCoursesApi = async () => {
  try {
    const response = await fetch(
      `${baseURL}/courses/all`,
      {
        method: "GET",
        headers: {
          "Content-Type": "Application/json",
        },
      }
    );
   const data = await response.json()
    if (!response.ok) {
      throw new Error(data.message || "Network response was not ok");
    }

    return data
  } catch (error) {
    console.error("Error fetching organizations:", error.message);
    throw error; // Re-throw the error to handle it in the calling function
  }
};


export const createCampaignApi = async (token, formValues) => {
  try {
    const response = await fetch(`${baseURL}/create`, {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${token}`,
        // Don't set "Content-Type": "multipart/form-data" manually
        // fetch will automatically set it with the correct boundary when using FormData
      },
      body: formValues, // Pass FormData directly, no JSON.stringify
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || "Failed to create campaign.");
    }

    return data;
  } catch (error) {
    console.error("Campaign Creation Error:", error);
    throw error;
  }
};

export const courseFormApi = async(token,formValues) =>{
  try{
    const response = await fetch(`${baseURL}/applyOrDownload`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify(formValues),
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || "Login failed. Please try again.");
    }

    return data;
  }
  catch(error){
    console.log(error.message)
  }
}

export const followCounsellorApi = async(token,fallowersId) =>{
  try{
    const response = await fetch(`${baseURL}/follow`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify(fallowersId),
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || "Login failed. Please try again.");
    }

    return data;
  }
  catch(error){
    console.log(error.message)
  }
}

export const unFollowCounsellorApi = async(token,fallowersId) =>{
  try{
    const response = await fetch(`${baseURL}/unfollow`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify(fallowersId),
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || "Login failed. Please try again.");
    }

    return data;
  }
  catch(error){
    console.log(error.message)
  }
}

export const getNotifications = async () => {
  const authToken = localStorage.getItem("authToken")?.replace(/"/g, "");

  try {
    const response = await fetch(`${baseURL}/jsSub/user/notif`, {
      method: "GET",
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      throw new Error("network response was not ok");
    }
    return response.json();
  } catch (error) {
    console.log(error.message);
  }
};


export const getExistingConsultantApi = async (orgId) => {
  try {
    const response = await fetch(
      `${baseURL}/jobpost/consultingCompanies/${orgId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "Application/json",
        },
      }
    );
   const data = await response.json()
    if (!response.ok) {
      throw new Error(data.message || "Network response was not ok");
    }

    return data
  } catch (error) {
    console.error("Error fetching organizations:", error.message);
    throw error; // Re-throw the error to handle it in the calling function
  }
};



export const getRecommendedJobsApi = async (token) => {
  try {
    const response = await fetch(
      `${baseURL}/jobs/recomendedJobs`,
      {
        method: "GET",
        headers: {
          "Content-Type": "Application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
   const data = await response.json()
    if (!response.ok) {
      throw new Error(data.message || "Network response was not ok");
    }

    return data
  } catch (error) {
    console.error("Error fetching organizations:", error.message);
    throw error; // Re-throw the error to handle it in the calling function
  }
};