import React from 'react';
import { TextField, Autocomplete } from '@mui/material';

const CustomAutocomplete = ({ placeholder, options, value, onChange }) => {
  // Ensure value is always handled correctly
  const handleChange = (event, newValue) => {
    onChange(newValue);
  };

  return (
    <Autocomplete
    size="small"
    fullWidth
    options={options}
    value={options.find((opt) => opt === value) || null} // Ensure a valid option
    onChange={handleChange}
    isOptionEqualToValue={(option, value) => option === value} // Custom equality check
    renderInput={(params) => (
      <TextField {...params} placeholder={placeholder} />
    )}
  />
  );
};

export default CustomAutocomplete;
