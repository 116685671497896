import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Typography,
  Container,
  Button,
  CircularProgress,
  Avatar,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { jwtDecode } from "jwt-decode";
import JobCard from "../../../components/EmployerComponents/EmployerJobListingComponents/JobCard";
import JobFilterButtons from "../../../components/EmployerComponents/EmployerJobListingComponents/EmployerJobFilterTabs";
import baseURL from "../../../api/baseUrl";
import EmployerJobSeekerSupport from "../EmployerJobSeekerSupport/EmployerJobSeekerSupport";
import { getOrgDetails } from "../../../api/companyProfile";
import { useModal } from "../../../context/ModelContext";
import SubscriptionModal from "../SubscriptionStatus/SubcriptionModel";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import savedSearchStyles from "../../../styles/savedSearcheStyles";
import { useOrg } from "../../../context/OrgContext";
import PopupSuccess from "../../../utils/popup/PopupSuccess";
import ReleaseJobPostModel from "../SubscriptionStatus/ReleaseJobPostModel";

import { truncateText } from "../../../utils/helperFunctions/Format";
import { Pause, PlayArrow } from "@mui/icons-material";

const EmployerJobListings = () => {
  const navigate = useNavigate();
  const [activeFilter, setActiveFilter] = useState(0);
  const [jobs, setJobs] = useState([]);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { orgData, setOrgData } = useOrg();
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const token = localStorage.getItem("token");
  const [speakingJobId, setSpeakingJobId] = useState(null);
  const [voices, setVoices] = useState([]);

  const orgId = localStorage.getItem("organizationId");
  const { modelOpen, handleModelOpen, handleModelClose } = useModal();

  const isReleasedAvailable = orgData?.jobPosting?.releasedJobPosting > 0;
  let timeoutResumeInfinity;

  const handleSuccess = useCallback((message) => {
    setSuccessMessage(message);
    setSuccessOpen(true);
    setTimeout(() => {
      setSuccessOpen(false);
      setSuccessMessage("");
    }, 2000);
  }, []);

  const handleError = useCallback((message) => {
    setErrorMessage(message);
    setErrorOpen(true);
    setTimeout(() => {
      setErrorOpen(false);
      setErrorMessage("");
    }, 2000);
  }, []);

  useEffect(() => {
    const organizationId = localStorage.getItem("organizationId");
    const role = localStorage.getItem("role");
    const token = localStorage.getItem("token");

    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const userData = {
          organizationId: decodedToken.organizationId || organizationId,
          role: decodedToken.role || role,
          token,
          id: decodedToken.id,
          fullName: decodedToken.name,
        };
        setUserData(userData);
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    } else {
      navigate("/employerSignIn");
    }
  }, [navigate]);

  console.log("userData", userData);
  const fetchJobPostings = useCallback(async () => {
    if (!userData) return;

    const { role, id, token, organizationId } = userData;
    let url = "";

    if (role === "recruiter_admin") {
      url = `${baseURL}/jobpost/${organizationId}`;
    } else if (role === "recruiter") {
      url = `${baseURL}/jobpost/by/${id}`;
    } else {
      console.error("Invalid role:", role);
      return;
    }
    setLoading(true);
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();

      if (response.ok) {
        setJobs(data);
      } else {
        console.error("Error fetching job postings:", data.message);
      }
    } catch (error) {
      console.error("Error fetching job postings:", error);
    } finally {
      setLoading(false);
    }
  }, [userData]);

  useEffect(() => {
    fetchJobPostings();
  }, [userData, fetchJobPostings]);

  const handleFilterButtonClick = (index) => {
    setActiveFilter(index);
  };

  const filteredJobs = jobs?.filter((job) => {
    switch (activeFilter) {
      case 1:
        return job.status === "active";
      case 2:
        return job.status === "inactive";
      case 3:
        return job.status === "draft";
      case 4:
        return job.status === "expired";
      default:
        return true;
    }
  });

  useEffect(() => {
    const getOrganizationDetails = async () => {
      try {
        const response = await getOrgDetails(orgId, token);
        setOrgData(response);
      } catch (error) {
        console.log(error.message);
      }
    };
    getOrganizationDetails();
  }, [orgId, token, setOrgData]);

  const handleBack = () => {
    navigate(-1);
  };

  function resumeInfinity() {
    window.speechSynthesis.resume();
    timeoutResumeInfinity = setTimeout(resumeInfinity, 1000);
  }

  useEffect(() => {
    const loadVoices = () => {
      const availableVoices = window.speechSynthesis.getVoices();
      setVoices(availableVoices);
      console.log("Available voices:", availableVoices);
    };

    loadVoices();
    window.speechSynthesis.onvoiceschanged = loadVoices;

    return () => {
      window.speechSynthesis.onvoiceschanged = null;
    };
  }, []);

  const textToSpeech = (job) => {
    const jobText = `
      Job Title: ${job.jobTitle}. 
      Location: ${
        job?.jobLocations?.map((item) => item.location).join(", ") ||
        "Not specified"
      }. 
      Open Positions: ${job.noOfPostionsOpen || "Not mentioned"}. 
      Experience: ${
        job.requiredExperienceMin +
          "To" +
          job.requiredExperienceMax +
          "years" || "Not specified"
      }.
      CTC: ${job.ctcMin + "To" + job.ctcMax + "Lakhs" || "Not disclosed"}. 
      Role: ${job.jobRole}. 
      Industry: ${job.industry || "Not specified"}. 
      Department: ${job.department || "Not specified"}. 
      Skills: ${job.skillPreferences?.join(", ") || "Not specified"}. 
      Open Positions: ${job.noOfPostionsOpen || "Not mentioned"}. 
      Posted On: ${new Date(job.postDate).toLocaleDateString()}. 
      Posted By: ${job?.postedBy?.rcName || "Unknown"}. 
      Company: ${job?.organizationId?.organizationName || "Not specified"}.
    `;

    const utterance = new SpeechSynthesisUtterance(jobText);
    utterance.lang = "hi-IN";
    utterance.volume = 1;
    utterance.rate = 1;
    utterance.pitch = 1;

    const gbFemaleVoice = voices.find(
      (voice) =>
        voice.name === "Microsoft Zira - English (United States)" &&
        voice.lang === "en-US"
    );
    utterance.voice = gbFemaleVoice;

    utterance.onstart = function (event) {
      resumeInfinity();
    };
    utterance.onend = function (event) {
      clearTimeout(timeoutResumeInfinity);
      setSpeakingJobId(null);
    };

    window.speechSynthesis.cancel();
    window.speechSynthesis.speak(utterance);
    setSpeakingJobId(job._id);
  };

  const stopSpeech = () => {
    window.speechSynthesis.cancel();
    setSpeakingJobId(null);
  };

  const toggleSpeech = (job) => {
    if (speakingJobId === job._id) {
      stopSpeech();
    } else {
      textToSpeech(job);
    }
  };

  console.log("voices", voices);
  console.log("jobs", jobs);

  return (
    <>
      <Box>
        <Typography
          onClick={handleBack}
          gutterBottom
          sx={{
            cursor: "pointer",
            display: "flex",
            ml: 1,
            alignItems: "center",
            color: "grey",
            "&:hover": {
              color: "primary.dark",
            },
          }}
        >
          <ArrowBackIcon sx={{ marginRight: "4px" }} />
        </Typography>
      </Box>
      <Container>
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 2,
            }}
          >
            <Typography
              variant="h5"
              fontWeight="bold"
              sx={{ textAlign: { xs: "center", sm: "left" } }}
            >
              All Jobs
            </Typography>
            <Button
              onClick={() => {
                if (isReleasedAvailable) {
                  navigate("/employerJobPosting");
                } else {
                  handleModelOpen();
                }
              }}
              sx={{
                backgroundColor: "#423bfa",
                color: "#fff",
                fontWeight: "bold",
                textTransform: "none",
                borderRadius: "0.7rem",
                border: "1px solid black",
                display: "flex",
                alignItems: "center",
                marginTop: { xs: 2, sm: 0 },
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "#423bfa",
                  border: "1px solid #423bfa",
                },
              }}
            >
              <AddIcon sx={{ marginRight: 1 }} />
              Post a new job
            </Button>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 5,
            }}
          >
            <JobFilterButtons
              activeFilter={activeFilter}
              onFilterClick={handleFilterButtonClick}
            />
          </Box>
          {loading ? (
            <Box sx={{ ...savedSearchStyles.parentBox, textAlign: "start" }}>
              <CircularProgress size={40} sx={{ mb: 2 }} />
            </Box>
          ) : (
            <>
              {jobs.length > 0 ? (
                <Grid container spacing={2}>
                  {filteredJobs?.slice().map((job) => (
                    <Grid item xs={12} sm={8} key={job._id}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "flex-start",
                          gap: 2,
                        }}
                      >
                        <Box
                          sx={{
                            position: "relative",
                            borderRadius: "10px",
                            overflow: "hidden",
                            width: "200px",
                            height: "280px",
                            backgroundColor: job?.jobVideoUrl?.url
                              ? "#f0f0f0"
                              : "#000",
                            cursor: "pointer",
                            flexShrink: 0,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (!job?.jobVideoUrl?.url) {
                              toggleSpeech(job);
                            }
                          }}
                        >
                          {job?.jobVideoUrl?.url ? (
                            <video
                              width="100%"
                              height="100%"
                              autoPlay
                              muted
                              style={{
                                objectFit: "cover",
                                borderRadius: "10px",
                              }}
                            >
                              <source
                                src={job.jobVideoUrl.url}
                                type="video/mp4"
                              />
                              Your browser does not support HTML video.
                            </video>
                          ) : (
                            <Box
                              sx={{
                                position: "relative",
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {/* Play/Pause Button in top-left corner */}
                              <IconButton
                                sx={{
                                  position: "absolute",
                                  top: 8,
                                  left: 8,
                                  zIndex: 3,
                                  color: "white",
                                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                                  "&:hover": {
                                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                                  },
                                }}
                              >
                                {speakingJobId === job._id ? (
                                  <Pause fontSize="small" />
                                ) : (
                                  <PlayArrow fontSize="small" />
                                )}
                              </IconButton>

                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  gap: 1,
                                  zIndex: 2,
                                }}
                              >
                                <Avatar
                                  src={
                                    job?.forConsultantCompany
                                      ? job?.consultantCompanyLogo?.url ? job?.consultantCompanyLogo?.url:job?.organizationId?.companyLogo?.url
                                      : job?.organizationId?.companyLogo?.url
                                  }
                                  sx={{ width: 60, height: 60 }}
                                />
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  {speakingJobId === job._id
                                    ? "Listening..."
                                    : "Click to hear"}
                                </Typography>
                              </Box>

                              <Box
                                sx={{
                                  position: "absolute",
                                  bottom: 0,
                                  left: 0,
                                  width: "100%",
                                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                                  color: "white",
                                  p: 1,
                                  borderRadius: "0 0 10px 10px",
                                  zIndex: 1,
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  noWrap
                                  sx={{
                                    fontFamily: "poppins",
                                    border: "1px solid #fff",
                                    borderRadius: "0.6rem",
                                    px: "10px",
                                  }}
                                >
                                  {job?.forConsultantCompany
                                    ? job?.consultantOrgName
                                    : job?.organizationId?.organizationName}
                                  <br />
                                  {job?.jobTitle}
                                </Typography>

                                <Typography
                                  variant="caption"
                                  noWrap
                                  sx={{ fontFamily: "poppins", ml: 1 }}
                                >
                                  {truncateText(
                                    job?.jobLocations?.map(
                                      (item) => item.location
                                    ),
                                    24
                                  )}
                                </Typography>

                                <br />
                                <Typography
                                  variant="caption"
                                  noWrap
                                  sx={{ fontFamily: "poppins", ml: 1 }}
                                >
                                  {`${job?.ctcMin} - ${job?.ctcMax} Lakhs`}
                                </Typography>
                              </Box>
                            </Box>
                          )}
                        </Box>
                        <Box>
                          <JobCard
                            job={{
                              title: job.jobTitle,
                              status: job.status,
                              industry: job.industry,
                              jobRole: job.jobRole,
                              department: job.department,
                              skillPreferences: job.skillPreferences,
                              ctc: job.ctc,
                              location: job?.jobLocations?.map(
                                (item) => item.location
                              ),
                              noOfPostionsOpen: job.noOfPostionsOpen,
                              postedOn: new Date(
                                job.postDate
                              ).toLocaleDateString(),
                              postedBy: job?.postedBy?.rcName,
                              company: job?.forConsultantCompany
                                ? job?.consultantOrgName
                                : job?.organizationId?.organizationName,
                              actionPending: job?.actionPending,
                              allCandidates: job?.allCandidates,
                              planRequired: job?.planStatus,
                              applicationCount: job?.applicationCount,
                              viewCount: job.viewCount,
                              walkIn: job.walkIn,
                              jobId: job._id,
                            }}
                            fetchJobPostings={fetchJobPostings}
                            handleError={handleError}
                            handleSuccess={handleSuccess}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "auto",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      fontFamily: "Poppins",
                      position: "absolute",
                      top: "50%",
                      left: "60%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    No Posted Jobs Found
                  </Typography>
                </Box>
              )}
            </>
          )}
        </Box>
      </Container>
      <EmployerJobSeekerSupport />
      <SubscriptionModal modelOpen={modelOpen} onClose={handleModelClose} />
      <PopupSuccess
        modelOpen={successOpen}
        onClose={() => setSuccessOpen(false)}
        message={successMessage}
      />
      <ReleaseJobPostModel
        modelOpen={errorOpen}
        onClose={() => setErrorOpen(false)}
        message={errorMessage}
      />
    </>
  );
};

export default EmployerJobListings;
