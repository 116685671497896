import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { Box } from "@mui/system";
import React, {  useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

import {
  addUser,
  getUsersOfOrg,
  verifyRecruiterApi,
} from "../../../api/manageUsers";
import { useFormErrors, validateForm } from "./addUserValidation"; // Import validation logic
import VerifyOtpModal from "./VerifyOtpModal";
import { ThreeDot } from "react-loading-indicators";

import { useOrg } from "../../../context/OrgContext";


const TextFieldStyles = {
  fontSize: "small",
  "& .MuiInputBase-input": {
    fontSize: "small",
  },
  "& .MuiInputBase-input::placeholder": {
    fontSize: "0.8rem",
  },
};

const AddUser = ({
  openAddUserDialog,
  handleCloseDialog,
  handleSuccess,
  handleError,
  fetchUsers
}) => {
  const [reportingManagers, setReportingManagers] = useState([]);

  const [formDetails, setFormDetails] = useState({
    rcName: "",
    rcEmail: "",
    password: "",
    role: "",
    reportingManagerId: "",
    rcPhone: "",
    permissions: {
      reviewCandidates: false,
      jobPosting: false,
      databaseSearch: false,
    },
  });

  const { errors, setErrors } = useFormErrors();
  const [otp, setOtp] = useState("");
  const [verifyModalOpen, setVerifyModalOpen] = useState(false);
  const [mainDialogOpen, setMainDialogOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const { orgData, setOrgData } = useOrg();
  const websiteUrl = orgData?.website;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormDetails((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormDetails((prevData) => ({
      ...prevData,
      permissions: {
        ...prevData.permissions,
        [name]: checked,
      },
    }));
  };

  const addNewUser = async () => {
    if (validateForm(formDetails, setErrors, errors, websiteUrl)) {
      setLoading(true);
      try {
        await addUser(
          localStorage.getItem("organizationId"),
          localStorage.getItem("token"),
          formDetails
        );

        setVerifyModalOpen(true); // Open OTP Modal after successful user addition
      } catch (error) {
        handleError(
          error.message || "Failed to add user,Please try again later"
        );
      } finally {
        setLoading(false);
      }
    }
  };

  // Handle Field Change
  const handleFieldChange = (field, value) => {
    if (field === "otp") setOtp(value);
    setErrors((prev) => ({ ...prev, [field]: "" })); // Clear error for the field
  };

  const handleOtpSubmit = async () => {
    if (!otp) {
      handleError("OTP is required");
      return;
    }
    setLoading(true);
    try {
      const response = await verifyRecruiterApi(
        localStorage.getItem("organizationId"),
        localStorage.getItem("token"),
        {
          rcEmail: formDetails?.rcEmail,
          otp,
        }
      );
      setVerifyModalOpen(false); // Close Verify OTP Modal
      setMainDialogOpen(false); // Close the main dialog
      setOrgData(response.organization);

      handleCloseDialog();
      const responseOrg = await getUsersOfOrg(
        localStorage.getItem("organizationId"),
        localStorage.getItem("token")
      );

      handleSuccess(response.message || "User added successfully");
      setReportingManagers(responseOrg.recruiterAdmins);

      fetchUsers()
    } catch (error) {
      console.error("Verification failed:", error.message);
      handleError(error.message || "Failed add User,Please try again later");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const getReportingManagers = async () => {
      try {
        const response = await getUsersOfOrg(
          localStorage.getItem("organizationId"),
          localStorage.getItem("token")
        );
        setReportingManagers(response.recruiterAdmins);
      } catch (error) {
        console.log(error.message);
      }
    };
    getReportingManagers();
  }, []);

  return (
    <Dialog open={openAddUserDialog} onClose={handleCloseDialog}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          pr: 4,
        }}
      >
        <DialogTitle>Add User</DialogTitle>
        <CloseIcon
          sx={{ opacity: 0.6, color: "gray", cursor: "pointer" }}
          onClick={handleCloseDialog}
        />
      </Box>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography component="legend" sx={{ fontSize: 12 }}>
              Name
            </Typography>
            <TextField
              fullWidth
              required
              variant="outlined"
              size="small"
              name="rcName"
              placeholder="Enter the name of the user"
              value={formDetails.rcName}
              onChange={handleInputChange}
              sx={TextFieldStyles}
              error={Boolean(errors.rcName)}
              helperText={errors.rcName}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography component="legend" sx={{ fontSize: 12 }}>
              Work Email Id
            </Typography>
            <TextField
              fullWidth
              required
              variant="outlined"
              size="small"
              name="rcEmail"
              placeholder="Enter the work email ID of the user"
              value={formDetails.rcEmail}
              onChange={handleInputChange}
              sx={TextFieldStyles}
              error={Boolean(errors.rcEmail)}
              helperText={errors.rcEmail}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography component="legend" sx={{ fontSize: 12 }}>
              Mobile Number
            </Typography>
            <TextField
              fullWidth
              required
              variant="outlined"
              size="small"
              name="rcPhone"
              placeholder="Enter the mobile number of the user"
              value={formDetails.rcPhone}
              onChange={(e) => {
                // Allow only numeric values and limit to 10 digits
                const value = e.target.value
                  .replace(/[^0-9]/g, "")
                  .slice(0, 10);
                handleInputChange({ target: { name: "rcPhone", value } });
              }}
              sx={TextFieldStyles}
              error={Boolean(errors.rcPhone)}
              helperText={
                errors.rcPhone ||
                (formDetails.rcPhone && formDetails.rcPhone.length < 10
                  ? "Please enter a valid 10-digit phone number."
                  : "")
              }
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography component="legend" sx={{ fontSize: 12 }}>
              Password
            </Typography>
            <TextField
              fullWidth
              required
              variant="outlined"
              type="password"
              size="small"
              name="password"
              placeholder="Enter the password"
              value={formDetails.password}
              onChange={handleInputChange}
              sx={TextFieldStyles}
              error={Boolean(errors.password)}
              helperText={errors.password}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography component="legend" sx={{ fontSize: 12 }}>
              Role
            </Typography>
            <Select
              labelId="role-select-label"
              id="role-select"
              name="role"
              value={formDetails.role}
              size="small"
              onChange={handleInputChange}
              sx={{
                width: "100%",
                height: "60%",
              }}
              error={Boolean(errors.role)}
            >
              <MenuItem value="recruiter">Recruiter</MenuItem>
              <MenuItem value="manager">Manager</MenuItem>
            </Select>
            {errors.role && (
              <FormHelperText error>{errors.role}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography component="legend" sx={{ fontSize: 12 }}>
              Reporting Manager
            </Typography>
            <Select
              labelId="manager-select-label"
              id="manager-select"
              name="reportingManagerId"
              value={formDetails.reportingManagerId}
              size="small"
              onChange={handleInputChange}
              sx={{
                width: "100%",
                height: "60%",
              }}
              error={Boolean(errors.reportingManagerId)}
            >
              {reportingManagers.map((manager) => (
                <MenuItem key={manager._id} value={manager._id}>
                  {manager.rcName}
                </MenuItem>
              ))}
            </Select>
            {errors.reportingManagerId && (
              <FormHelperText error>{errors.reportingManagerId}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} sm={8}>
            <Typography
              variant="subtitle2"
              sx={{ fontWeight: "bold", marginTop: 2, fontSize: "small" }}
            >
              Permissions
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formDetails.permissions.jobPosting}
                  onChange={handleCheckboxChange}
                  name="jobPosting"
                  color="primary"
                />
              }
              label="Job Posting"
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: "0.75rem",
                },
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formDetails.permissions.reviewCandidates}
                  onChange={handleCheckboxChange}
                  name="reviewCandidates"
                  color="primary"
                />
              }
              label="Invites"
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: "0.75rem",
                },
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formDetails.permissions.databaseSearch}
                  onChange={handleCheckboxChange}
                  name="databaseSearch"
                  color="primary"
                />
              }
              label="Database Search"
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: "0.75rem",
                },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} marginTop={2}>
            <DialogActions>
              <Button
                color="primary"
                sx={{
                  backgroundColor: "#000000",
                  color: "#ffffff",
                  fontWeight: "bold",
                  textTransform: "none",
                  borderRadius: "0.7rem",
                  "&:hover": {
                    backgroundColor: "#000000",
                    color: "#ffffff",
                    border: "1px solid #000000",
                  },
                }}
                onClick={addNewUser}
              >
                {loading ? (
                  <ThreeDot
                    color="#FFFFFFFF"
                    size="small"
                    text=""
                    textColor=""
                  />
                ) : (
                  "Save"
                )}
              </Button>
            </DialogActions>
          </Grid>

          {/* Verify OTP Modal */}
          <VerifyOtpModal
            open={verifyModalOpen}
            mainDialogOpen={mainDialogOpen}
            handleClose={() => setVerifyModalOpen(false)}
            handleFieldChange={handleFieldChange}
            handleOtpSubmit={handleOtpSubmit}
            otp={otp}
            errors={errors}
            email={formDetails?.rcEmail}
            loading={loading}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default AddUser;
