import baseURL from "./baseUrl";

export const getAllBlogs = async () => {
  const authToken = localStorage.getItem("authToken")?.replace(/"/g, "");

  try {
    const response = await fetch(`${baseURL}/blog/all`, {
      method: "GET",
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      throw new Error("network response was not ok");
    }
    return response.json();
  } catch (error) {
    console.log(error.message);
  }
};

export const getBlogByIdApi = async (blogId) => {
    try {
      const response = await fetch(`${baseURL}/blog/${blogId}`, {
        method: "GET",
        headers: {
          "Content-Type": "Application/json",
        },
      });
  
      if (!response.ok) {
        throw new Error("network response was not ok");
      }
      return response.json();
    } catch (error) {
      console.log(error.message);
    }
  };

  export const getAllArticles = async () => {
    const authToken = localStorage.getItem("authToken")?.replace(/"/g, "");
  
    try {
      const response = await fetch(`${baseURL}/article/all`, {
        method: "GET",
        headers: {
          "Content-Type": "Application/json",
          Authorization: `Bearer ${authToken}`,
        },
      });
  
      if (!response.ok) {
        throw new Error("network response was not ok");
      }
      return response.json();
    } catch (error) {
      console.log(error.message);
    }
  };
  
  export const getArticleByIdApi = async (articleId) => {
      try {
        const response = await fetch(`${baseURL}/article/${articleId}`, {
          method: "GET",
          headers: {
            "Content-Type": "Application/json",
          },
        });
    
        if (!response.ok) {
          throw new Error("network response was not ok");
        }
        return response.json();
      } catch (error) {
        console.log(error.message);
      }
    };