



const searchCandidates = {
  fontStyles: {
    fontSize: 16,
    fontWeight: "regular",
    fontFamily:"poppins"
  },
  button: {
    backgroundColor: "#423bfa",
    borderRadius:'2rem',
    color: "white",
  },
  parentBox: {
    
    display: "flex",
    justifyContent:'center',
    alignItems: "flex-start",
    height: "auto",
    backgroundColor: "#EFFAFCFF",
    gap:3,
    mx:3
  },
  paper: {
    padding: 3,
    height: "auto",
    border:"1px solid",
    borderColor:"grey.300",
    borderRadius: "20px",
    backgroundColor: "#ffffff",
    mb: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  flexBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  flexboxCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  flexboxColumn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  flexboxend: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
  },
  flexboxStart: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
  },
    searchParentBox: {
      p: 2,
      mt: 2,
      mb: 2,
      gap:2,
      display: "flex",
      alignItems: "center",
      justifyContent: "start",
      position: "sticky",
      bottom: 0,
      zIndex: 10,
      backgroundColor: "#fff",
    },
    searchButton: {
      backgroundColor: "#000000",
      "&:hover": {
        backgroundColor: "#000000",
      },
      textTransform: "none",
      display: "flex",
      alignItems: "center",
      borderRadius: "0.7rem",
      width: "200px",
      height: "50px",
      gap: 1,
    },
  activeFilter: {
    fontSize: 14,
    cursor: "pointer",
    border: "none",
    outline: "none",
    boxShadow: "none",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&:focus": {
      border: "none",
    },
    "& .MuiSelect-icon": {
      color: "black",
    },
  },
  addItskills: {
    fontSize: 13,
    mt:1,
    fontWeight: "bold",
    color: "#0B59BEFF",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ":hover": {
      color: "#4A88DAFF",
    },
    cursor: "pointer",
    marginLeft: 1,
  },
};

export default searchCandidates;
