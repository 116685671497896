import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useLocation } from "react-router-dom";

const Advertisements = () => {
  const location = useLocation();
  const plansRoute = location.pathname.includes("/employer/subscriptionStatus");

  // Array of logo sources (for simplicity, extracted from the original code)
  const logos = [
    "/logos1.png",
    "/logos2.png",
    "/logos3.png",
    "/logos5.png",
    "/logos6.png",
    "/logos7.png",
    "/logos8.png",
    "/logos9.png",
    "/logos10.png",
    "/logos11.png",
    "/logos12.png",
    "/logos13.png",
    "/logos14.png",
    "/logos15.png",
    "/logos16.png",
  ];

  return (
    <Box
      sx={{
        textAlign: "center",
        padding: 3,
        overflow: "hidden",
        backgroundColor: plansRoute ? "transparent" : "#fff", // Fixed "none" to "transparent"
        color: "#191a19",
        borderRadius: "10px",
        margin: 0,
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontWeight: "bold",
          marginBottom: 4,
          fontFamily: "IBM Plex Sans, sans-serif",
        }}
      >
        Building Careers with 3000+ Employers
      </Typography>
      <Box
        sx={{
          overflow: "hidden",
          position: "relative",
          height: 80, // Match logo height
        }}
      >
        <Box
          sx={{
            display: "inline-flex",
            whiteSpace: "nowrap",
            animation: "scroll 20s linear infinite", // Increased duration for smoother effect
            "&:hover": {
              animationPlayState: "paused", // Optional: pause on hover
            },
            "@keyframes scroll": {
              "0%": { transform: "translateX(0)" },
              "100%": { transform: "translateX(-50%)" }, // Move only half the width due to duplication
            },
          }}
        >
          {/* Render logos twice for seamless looping */}
          {[...logos, ...logos].map((src, index) => (
            <img
              key={index}
              src={src}
              alt={`Logo ${index + 1}`}
              style={{ height: 80, marginRight: 30 }}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Advertisements;