import React from 'react';
import {
    Container,
    Typography,
    Grid,
    Paper,
    CardContent,
} from "@mui/material";

const CareerCards = ({ jobCategories }) => {
  return (
    <Container maxWidth="xl" sx={{ py: 6 }}>
            <Typography variant='h4' fontWeight='bold' sx={{ textAlign:'center',mb:4}}>Vacanzi, Current Openings</Typography>

      <Grid container spacing={3} justifyContent="center">

        {jobCategories.map((job, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            
            <Paper 
              elevation={0} 
              sx={{ 
                borderRadius: 3, 
                height: '120px',  // Slightly taller
                padding: 2, 
                maxWidth: 600, // Prevents it from being too wide
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between"
              }}
            >
              <CardContent>
                <Typography variant="h5" fontWeight="bold" gutterBottom>
                  {job.title}
                </Typography>
                <Typography 
                  variant="body2" 
                  color="textSecondary" 
                  gutterBottom 
                  sx={{ mb: 2, fontSize: '16px' }}
                >
                  {job.openings} openings
                </Typography>
                <Typography sx={{ mt: 1, fontSize: '18px' }} gutterBottom>
                  {job.description}
                </Typography>
              </CardContent>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default CareerCards;
