import React from "react";

import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

const UsageReport = ({ orgData }) => {
  return (
    <Paper
      sx={{
        padding: 1,
        boxShadow: "2px 1px 7px 0px #9695c3",
        overflow: "hidden",
      }}
    >
      {/* Table for User Permissions */}
      <Paper>
        <Table sx={{ minHeight: 200 }} size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "medium", opacity: "0.8" }}>
                Category
              </TableCell>
              <TableCell sx={{ fontWeight: "medium", opacity: "0.8" }}>
                User Access
              </TableCell>
              <TableCell sx={{ fontWeight: "medium", opacity: "0.8" }}>
                Invite
              </TableCell>
              <TableCell sx={{ fontWeight: "medium", opacity: "0.8" }}>
                Job Posting
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {/* User Details Column */}
              <TableCell component="th" scope="row">
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={{ fontSize: 13, fontWeight: "light" }}>
                    Default
                  </Typography>
                </Box>
              </TableCell>
              <TableCell component="th" scope="row">
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={{ fontSize: 13, fontWeight: "light" }}>
                    {orgData?.userAccess?.defaultUserAccess}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell component="th" scope="row">
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={{ fontSize: 13, fontWeight: "light" }}>
                    {orgData?.invites?.defaultInvites}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell component="th" scope="row">
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={{ fontSize: 13, fontWeight: "light" }}>
                    {orgData?.jobPosting?.defaultJobPosting}
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
            <TableRow>
              {/* User Details Column */}
              <TableCell component="th" scope="row">
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={{ fontSize: 13, fontWeight: "light" }}>
                    Purchased
                  </Typography>
                </Box>
              </TableCell>
              <TableCell component="th" scope="row">
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={{ fontSize: 13, fontWeight: "light" }}>
                    {orgData?.userAccess?.purchasedUserAccess}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell component="th" scope="row">
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={{ fontSize: 13, fontWeight: "light" }}>
                    {orgData?.invites?.purchasedInvites}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell component="th" scope="row">
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={{ fontSize: 13, fontWeight: "light" }}>
                    {orgData?.jobPosting?.purchasedJobPosting}
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>

            <TableRow>
              {/* User Details Column */}
              <TableCell component="th" scope="row">
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={{ fontSize: 13, fontWeight: "light" }}>
                    Total
                  </Typography>
                </Box>
              </TableCell>
              <TableCell component="th" scope="row">
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={{ fontSize: 13, fontWeight: "light" }}>
                    {orgData?.userAccess?.totalUserAccess}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell component="th" scope="row">
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={{ fontSize: 13, fontWeight: "light" }}>
                    {orgData?.invites?.totalInvites}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell component="th" scope="row">
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={{ fontSize: 13, fontWeight: "light" }}>
                    {orgData?.jobPosting?.totalJobPostings}
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
            <TableRow>
              {/* User Details Column */}
              <TableCell component="th" scope="row">
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={{ fontSize: 13, fontWeight: "light" }}>
                    Released
                  </Typography>
                </Box>
              </TableCell>
              <TableCell component="th" scope="row">
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={{ fontSize: 13, fontWeight: "light" }}>
                    {orgData?.userAccess?.releasedUserAccess}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell component="th" scope="row">
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={{ fontSize: 13, fontWeight: "light" }}>
                    {orgData?.invites?.releasedInvites}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell component="th" scope="row">
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={{ fontSize: 13, fontWeight: "light" }}>
                    {orgData?.jobPosting?.releasedJobPosting}
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
            <TableRow>
              {/* User Details Column */}
              <TableCell component="th" scope="row">
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={{ fontSize: 13, fontWeight: "light" }}>
                    Remaining
                  </Typography>
                </Box>
              </TableCell>
              <TableCell component="th" scope="row">
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={{ fontSize: 13, fontWeight: "light" }}>
                    {orgData?.userAccess?.remainingUserAccess}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell component="th" scope="row">
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={{ fontSize: 13, fontWeight: "light" }}>
                    {orgData?.invites?.remainingInvites}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell component="th" scope="row">
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={{ fontSize: 13, fontWeight: "light" }}>
                    {orgData?.jobPosting?.remainingJobPosting}
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
            <TableRow>
              {/* User Details Column */}
              <TableCell component="th" scope="row">
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={{ fontSize: 13, fontWeight: "light" }}>
                    Used
                  </Typography>
                </Box>
              </TableCell>
              <TableCell component="th" scope="row">
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={{ fontSize: 13, fontWeight: "light" }}>
                    {orgData?.userAccess?.usedUserAccess}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell component="th" scope="row">
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={{ fontSize: 13, fontWeight: "light" }}>
                    {orgData?.invites?.usedInvites}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell component="th" scope="row">
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={{ fontSize: 13, fontWeight: "light" }}>
                    {orgData?.jobPosting?.usedJobPosting}
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    </Paper>
  );
};

export default UsageReport;
