import React from 'react';
import { Box, Skeleton, Divider } from '@mui/material';

const DescriptionBox = () => {
  return (
    <Box
      sx={{
      background: "linear-gradient(to right, #C6C9C8FF 8%, #827979FF 18%, #C1C1C1FF 33%)",
        borderRadius: 4,
        p: 3,
        width: { xs: '100%', sm: '400px', md: '800px' },
        height: 'auto',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        position: 'relative',
      }}
    >
      {/* Shimmer Effect Styles */}
      <style>
        {`
          @keyframes shimmer {
            0% { background-position: -200% 0; }
            100% { background-position: 200% 0; }
          }

          .shimmer {
            background: "linear-gradient(to right, #C6C9C8FF 8%, #827979FF 18%, #C1C1C1FF 33%)",
            background-size: 200% 100%;
            animation: shimmer 5s infinite;
          }
        `}
      </style>

      {/* Job Title Skeleton */}
      <Skeleton
        variant="text"
        width="60%"
        height={40}
        sx={{
          mb: 2,
          animation: 'shimmer 1.5s infinite',
          background: 'linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)',
          backgroundSize: '200% 100%',
        }}
      />

      {/* Company Name and Rating Skeleton */}
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
        <Skeleton
          variant="text"
          width="40%"
          height={20}
          sx={{
            mr: 1,
            animation: 'shimmer 1.5s infinite',
            background: 'linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)',
            backgroundSize: '200% 100%',
          }}
        />
        <Skeleton
          variant="text"
          width="20%"
          height={20}
          sx={{
            animation: 'shimmer 1.5s infinite',
            background: 'linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)',
            backgroundSize: '200% 100%',
          }}
        />
      </Box>

      {/* Job Details Skeleton */}
      <Skeleton
        variant="text"
        width="50%"
        height={20}
        sx={{
          mt: 2,
          animation: 'shimmer 1.5s infinite',
          background: 'linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)',
          backgroundSize: '200% 100%',
        }}
      />
      <Skeleton
        variant="text"
        width="40%"
        height={20}
        sx={{
          mt: 2,
          animation: 'shimmer 1.5s infinite',
          background: 'linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)',
          backgroundSize: '200% 100%',
        }}
      />

      {/* Posted Date, Openings, and Applicants Skeleton */}
      <Box sx={{ mt: 2 }}>
        <Skeleton
          variant="text"
          width="80%"
          height={20}
          sx={{
            animation: 'shimmer 1.5s infinite',
            background: 'linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)',
            backgroundSize: '200% 100%',
          }}
        />
      </Box>

      {/* Action Buttons Skeleton */}
      <Box sx={{ mt: 2, display: 'flex', gap: 2, justifyContent: 'end' }}>
        <Skeleton
          variant="rectangular"
          width={120}
          height={40}
          sx={{
            borderRadius: '20px',
            animation: 'shimmer 1.5s infinite',
            background: 'linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)',
            backgroundSize: '200% 100%',
          }}
        />
      </Box>

      <Divider sx={{ marginTop: 4 }} />

      {/* Job Description Skeleton */}
      <Skeleton
        variant="text"
        width="30%"
        height={40}
        sx={{
          mt: 3,
          animation: 'shimmer 1.5s infinite',
          background: 'linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)',
          backgroundSize: '200% 100%',
        }}
      />
      <Skeleton
        variant="text"
        width="100%"
        height={20}
        sx={{
          mt: 2,
          animation: 'shimmer 1.5s infinite',
          background: 'linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)',
          backgroundSize: '200% 100%',
        }}
      />
      <Skeleton
        variant="text"
        width="80%"
        height={20}
        sx={{
          mt: 1,
          animation: 'shimmer 1.5s infinite',
          background: 'linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)',
          backgroundSize: '200% 100%',
        }}
      />

      {/* Key Skills Skeleton */}
      <Skeleton
        variant="text"
        width="20%"
        height={40}
        sx={{
          mt: 3,
          animation: 'shimmer 1.5s infinite',
          background: 'linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)',
          backgroundSize: '200% 100%',
        }}
      />
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 2 }}>
        {[...Array(4)].map((_, index) => (
          <Skeleton
            key={index}
            variant="rectangular"
            width={80}
            height={32}
            sx={{
              borderRadius: '16px',
              animation: 'shimmer 1.5s infinite',
              background: 'linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)',
              backgroundSize: '200% 100%',
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default DescriptionBox;