import { Box, Typography, Container, CssBaseline } from "@mui/material";
import React from "react";
import peoplestand from '../../../assets/images/peoplestand.png';

const EnterPrise = () => {
  return (
    <>
      <CssBaseline />
      <Box
        sx={{
          width:"auto",
          overflow: "hidden",
          backgroundColor: "#f5faf5",
          borderRadius: "12px",
          marginBottom: "10px",
          
        }}
      >
        <Container maxWidth="xl">
          <Box display="flex" flexDirection={{ xs: "column", md: "row" }}>
            {/* Left Content */}
            <Box  
              sx={{
                padding: { xs: "24px", md: "32px 20px" },
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >

              <Box sx={{ display: "flex", flexDirection: "column", gap: "16px", marginBottom: "16px" }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
                  <Typography variant="h6" sx={{ color: "#000" }}>Vacanzi revolutionizes job-seeking by bridging talent and opportunity through AI-powered, video-first recruitment. Job seekers can showcase their personality and skills via video resumes, while employers benefit from AI-driven candidate matching and engaging video job postings.</Typography>

                </Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
                  <Typography variant="h6" sx={{ color: "#000" }}>
                  We go beyond traditional platforms, offering free support, instant recruiter interaction, and direct employer connections for faster hiring. By leveraging AI insights and simplifying the hiring journey, Vacanzi ensures a more innovative, inclusive, and human-centric recruitment experience

                  </Typography>
                </Box>
               
              </Box>

             
            </Box>

            {/* Right Image */}
            <Box
              sx={{
                position: "relative",
                width: "100%",
                height: { xs: "300px", md: "600px" },
                overflow: "hidden", // Ensure the image doesn't overflow
              }}
            >
              <img
                src={peoplestand}
                alt="Person working at a desk"
                style={{
                  objectFit: "contain",
                  width: "100%",
                  height: "100%",
                  margin:0
                }}
              />
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default EnterPrise;