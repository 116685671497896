import React, { useState, useEffect, useCallback, lazy } from "react";
import { styled, ThemeProvider, useTheme } from "@mui/material/styles";
import {
  Box,
  CssBaseline,
  Typography,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  AppBar as MuiAppBar,
  Drawer as MuiDrawer,
  Avatar,
  MenuItem,
  Button,
  Menu,
} from "@mui/material";

// import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useNavigate } from "react-router-dom";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
// import avatarmen from "../../../../assets/images/avatarmen.jpg";
// import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import SupportIcon from "@mui/icons-material/Support";
import { jwtDecode } from "jwt-decode"; // Import jwt-decode
// import EmployerDrawerList from "./EmployerDrawerList";

// import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { logoutApi } from "../../../../api/employerAuth";
// import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import PaymentDialog from "../../../AdvancedComponents/PaymentModel";

// import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// import ExitToAppIcon from "@mui/icons-material/ExitToApp";
// import SettingsIcon from "@mui/icons-material/Settings";
// import HelpIcon from "@mui/icons-material/Help";
// import FeedbackIcon from "@mui/icons-material/Feedback";

import EmployerJobSeekerSupport from "../../../../pages/EmployerPages/EmployerJobSeekerSupport/EmployerJobSeekerSupport";
// import zIndex from "@mui/material/styles/zIndex";
// import { borderRadius, fontFamily } from "@mui/system";
// import { getOrgWalletApi } from "../../../../api/payment";

import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import MeetingRoomOutlinedIcon from "@mui/icons-material/MeetingRoomOutlined";
import SubscriptionsOutlinedIcon from "@mui/icons-material/SubscriptionsOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useOpenState } from "../../../../context/OpenStateContext";
import { useOrg } from "../../../../context/OrgContext";
import { getOrgDetails } from "../../../../api/companyProfile";
import { Suspense } from "react";
import DrawerSkeleton from "../../../../utils/lazy/DrawerSkeleton";
const EmployeeDrawerList = lazy(() => import("./EmployerDrawerList"));
const drawerWidth = 300;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: 10,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const RightDrawer = styled(MuiDrawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    width: 300,
    boxSizing: "border-box",
    fontFamily: "Satoshi",
    zIndex: 11, // Ensure it's above everything
    position: "fixed",
    backgroundColor: "#ffffff", // White background
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Add shadow for depth
    borderRadius: "20px 0 10px 0",
  },
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  zIndex: 9,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  overflowY: "auto", // Ensure the drawer is scrollable
  scrollbarWidth: "none", // Hide scrollbar for Firefox
  "&::-webkit-scrollbar": {
    display: "none", // Hide scrollbar for Chrome, Safari, and Opera
  },
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": {
      ...openedMixin(theme),
      overflowY: "auto", // Ensure the paper is scrollable
      scrollbarWidth: "none", // Hide scrollbar for Firefox
      "&::-webkit-scrollbar": {
        display: "none", // Hide scrollbar for Chrome, Safari, and Opera
      },
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": {
      ...closedMixin(theme),
      overflowY: "auto", // Ensure the paper is scrollable
      scrollbarWidth: "none", // Hide scrollbar for Firefox
      "&::-webkit-scrollbar": {
        display: "none", // Hide scrollbar for Chrome, Safari, and Opera
      },
    },
  }),
}));
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const EmployerDrawer = ({ children }) => {
  const theme = useTheme();
  const { open, setOpen } = useOpenState();
  // const [wallet, setWallet] = useState();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [userData, setUserData] = useState(null); // State to store user data
  // const [loggedHours, setLoggedHours] = useState();
  const [openPayDialog, setOpenPayDialog] = useState(false);
  const [openRightDrawer, setOpenRightDrawer] = useState(false);
  const navigate = useNavigate();
  const [menuAnchor, setMenuAnchor] = useState(null); // Changed state name to 'menuAnchor'

  const orgId = localStorage.getItem("organizationId");
  const token = localStorage.getItem("token");
  const recruiterId = localStorage.getItem("recruiterId");
  const { orgData, setOrgData } = useOrg();

  // const recruiterId = localStorage.getItem("recruiterId");

  const fetchOrgDetails = useCallback(async () => {
    try {
      if (orgId) {
        const companyDetails = await getOrgDetails(orgId, token);
        setOrgData(companyDetails);
        console.log("orgdata fetchted");  
      } else {
        throw new Error("No organization id");
      }
    } catch (error) {
      console.log(error.message);
    }
  }, [orgId, token, setOrgData]); // Dependencies of useCallback

  useEffect(() => {
    fetchOrgDetails();
  }, [fetchOrgDetails]);

  const handleOpenPayMenu = () => {
    setOpenPayDialog(true);
  };
  const handlePayMenuClose = () => {
    setOpenPayDialog(false);
  };

  // const handleClick = (event) => {
  //   setMenuAnchor(event.currentTarget); // Open the menu when button is clicked
  // };

  const handleClose = () => {
    setMenuAnchor(null); // Close the menu
  };

  useEffect(() => {
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setUserData(decodedToken); // Set user data from decoded token
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    } else {
      navigate("/employerSignIn"); // Redirect to login if no token found
    }
  }, [navigate, token]);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleNavigation = (path, index) => {
    setSelectedIndex(index);
    setOpen(true);
    navigate(path);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  // const handleMenuOpen = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfileClick = () => {
    handleMenuClose();
    setOpenRightDrawer(false);
    navigate("/employerProfile");
  };

  //signout functionality
  const handleSignOutClick = async () => {
    try {
      const formValues = {
        recruiterId: recruiterId,
      };
      await logoutApi(formValues);
      // handleMenuClose();
      navigate("/");
      localStorage.clear();
    } catch (error) {
      console.log(error.message);
    }
  };

  //   useEffect(()=>{
  //     const getEmployerDetails = async()=>{
  //       try{
  //      const response = await getRecruiterDetailsApi(recruiterId,token)
  //      setRecruiterData(response)
  //       }
  //       catch(error){
  //         console.log(error.message)
  //       }
  //     }
  //     getEmployerDetails()
  //   },[])
  // console.log('recruiterDta',recruiterData)

  // useEffect(() => {
  //   const totalLoggedHours = async () => {
  //     try {
  //       const response = await getloggedHours(token, recruiterId); // Pass recruiterId
  //       setLoggedHours(response);
  //     } catch (error) {
  //       console.log(error.message);
  //     }
  //   };
  //   totalLoggedHours();
  // }, [token, recruiterId]);

  // useEffect(() => {
  //   const getOrgWallet = async () => {
  //     try {
  //       const response = await getOrgWalletApi(orgId, token);
  //       setWallet(response);
  //     } catch (error) {
  //       console.log(error.message);
  //     }
  //   };
  //   getOrgWallet();
  // }, [orgId, token]);

  const profileImage = orgData?.companyLogo?.url;
  const orgName = orgData?.organizationName;

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex", backgroundColor: "#f5f5f5" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            backgroundColor: "#ffffff",
            boxShadow: "none",
            borderBottom: "1px solid #e0e0e0",
          }}
        >
          <Toolbar>
            <img
              style={{ height: 50, width: 50 }}
              src="/vacanziLogo.png"
              alt="Vacanzi Logo"
            />
            <span
              style={{
                color: "#423bfa",
                fontSize: "42px",
                fontFamily: "GlanceSansBlack",
              }}
            >
              Vacanzi
            </span>

            <IconButton
              aria-label="toggle drawer"
              onClick={handleDrawerToggle}
              edge="start"
              sx={{
                color: "black",
                ml: 2,
                ":hover": {
                  background: "none", // Removes background on hover
                },
                ":active": {
                  background: "none", // Removes background on click
                },
                ":focus": {
                  background: "none", // Removes background when focused
                },
              }}
            >
              <div>
                {open ? (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <ArrowBackIosOutlinedIcon
                      sx={{ fontSize: 23, opacity: 0.8 }}
                    />{" "}
                    <Typography
                      sx={{ fontFamily: "poppins", fontWeight: "bold" }}
                    >
                      Close
                    </Typography>
                  </Box>
                ) : (
                  // Show this if open is true
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <ArrowForwardIosOutlinedIcon
                      sx={{ fontSize: 23, opacity: 0.8 }}
                    />{" "}
                    <Typography
                      sx={{ fontFamily: "poppins", fontWeight: "bold" }}
                    >
                      Open
                    </Typography>
                  </Box>
                )}
              </div>
            </IconButton>
            {/* <MenuItem
            onClick={() => handleNavigation("/jobseekerProfile")}
            sx={{ ml: 2 }}
          >
            <Typography variant="body2" sx={{ color: "#423BFA" }}>
              JobSeeker Profiles
            </Typography>
          </MenuItem> */}
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <>
                {userData?.role === "recruiter_admin" && (
                  <div>
                    {/* <Button
                      sx={{
                        marginRight: 5,
                        display: "flex",
                        alignItems: "center",
                        color: "black",
                        textTransform: "none",
                        backgroundColor: "transparent",
                        "&:hover": { backgroundColor: "transparent" },
                      }}
                      disableRipple
                      onClick={handleClick} // Open the menu on button click
                    >
                      <AccountBalanceWalletIcon sx={{ mr: 0.5 }} />{" "}
                      <span
                        style={{
                          fontWeight: "bold",
                          fontFamily: "poppins",
                          fontSize: 17,
                          marginRight: 4,
                        }}
                      >
                        Rs.{" "}
                      </span>
                      <Typography
                        sx={{
                          fontFamily: "poppins",
                          fontWeight: "bold",
                          color: "green",
                        }}
                      >
                        {wallet?.balance}
                      </Typography>
                    </Button> */}

                    <Menu
                      anchorEl={menuAnchor} // Menu anchored to the button using the new state name
                      open={Boolean(menuAnchor)} // Check if the menu is open
                      onClose={handleClose} // Close the menu when clicked outside
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          handleOpenPayMenu();
                          handleClose();
                        }}
                        sx={{ fontFamily: "poppins" }}
                      >
                        {" "}
                        Add Money
                      </MenuItem>{" "}
                      <MenuItem
                        onClick={() => {
                          navigate("/employerTransactions");
                          handleClose();
                        }}
                        sx={{ fontFamily: "poppins" }}
                      >
                        {" "}
                        Transactions
                      </MenuItem>{" "}
                    </Menu>
                  </div>
                )}
                {/* <Button
                  sx={{
                    marginRight: 5,
                    display: "flex",
                    alignItems: "center",
                    color: "black",
                    textTransform: "none",
                    backgroundColor: "transparent",
                    "&:hover": { backgroundColor: "transparent" },
                  }}
                  disableRipple
                >
                  <MonetizationOnIcon sx={{ mr: 0.5 }} />
                  Available Credits
                </Button> */}
                <Button
                  sx={{
                    marginRight: 2,
                    display: "flex",
                    alignItems: "center",
                    color: "black",
                    textTransform: "none",
                    backgroundColor: "transparent",
                    fontFamily:'poppins',
                    "&:hover": { backgroundColor: "transparent" },
                  }}
                  disableRipple
                  onClick={() => navigate("/employerSupport")}
                >
                  <SupportIcon sx={{ mr: 0.5 }} />
                  Support
                </Button>
              </>
            </Box>

            <IconButton
              onClick={() => setOpenRightDrawer(true)}
              sx={{ color: "inherit" }}
            >
              <Box
                sx={{
                  backgroundColor: "black", // Black background
                  borderRadius: "50%", // Round shape
                  padding: "2px", // Optional: Add padding to create space around the Avatar
                }}
              >
                <Avatar src={profileImage} alt="User Avatar" />
              </Box>
            </IconButton>

            <Menu
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleMenuClose}
              PaperProps={{
                sx: {
                  borderRadius: 2,
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  minWidth: 250,
                },
              }}
            >
              <MenuItem sx={{ display: "flex", alignItems: "center", px: 2 }}>
                <Avatar
                  src={profileImage}
                  alt="User Avatar"
                  sx={{
                    width: 40,
                    height: 40,
                    backgroundColor: "black", // Black background
                    padding: "4px", // Optional: Add padding to create space around the image
                  }}
                />

                <Box sx={{ ml: 2 }}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {userData?.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Role: {userData?.role}
                  </Typography>
                </Box>
              </MenuItem>
              <Divider />
              {/* <MenuItem sx={{ px: 2, "&:hover": { bgcolor: "action.hover" } }}>
              <ListItemIcon>
                <AccessTimeIcon color="primary" />
              </ListItemIcon>
              <ListItemText
                primary="Logged Hours"
                secondary={`${loggedHours?.totalHours} hrs`}
                primaryTypographyProps={{
                  variant: "body2",
                  fontWeight: "bold",
                  color: "text.primary",
                }}
                secondaryTypographyProps={{
                  variant: "caption",
                  color: "text.secondary",
                }}
              />
            </MenuItem> */}
              {/* <MenuItem
              onClick={handleProfileClick}
              sx={{ px: 2, "&:hover": { bgcolor: "action.hover" } }}
            >
              <ListItemIcon>
                <AccountCircleIcon color="action" />
              </ListItemIcon>
              <ListItemText
                primary="View Profile"
                primaryTypographyProps={{
                  variant: "body2",
                  fontWeight: "bold",
                  color: "text.primary",
                }}
              />
            </MenuItem> */}
              {/* <MenuItem
              onClick={() => navigate("/deleteAccount")}
              sx={{
                px: 2,
                "&:hover": { bgcolor: "rgba(255, 0, 0, 0.1)" },
              }}
            >
              <ListItemIcon>
                <DeleteIcon sx={{ color: "red" }} />
              </ListItemIcon>
              <ListItemText
                primary="Delete Account"
                primaryTypographyProps={{
                  variant: "body2",
                  fontWeight: "bold",
                  color: "red",
                }}
              />
            </MenuItem> */}
              <MenuItem
                onClick={handleSignOutClick}
                sx={{
                  px: 2,
                  "&:hover": { bgcolor: "rgba(255, 0, 0, 0.1)" },
                }}
              >
                <ListItemIcon>
                  <ExitToAppIcon color="action" />
                </ListItemIcon>
                <ListItemText
                  primary="Sign Out"
                  primaryTypographyProps={{
                    variant: "body2",
                    fontWeight: "bold",
                    color: "text.primary",
                  }}
                />
              </MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
        <Suspense fallback={<DrawerSkeleton />}>
          <EmployeeDrawerList
            Drawer={Drawer}
            orgData={orgData}
            userRole={userData?.role}
            userData={userData}
            profileImage={profileImage}
            orgName={orgName}
            theme={theme}
            DrawerHeader={DrawerHeader}
            open={open}
            selectedIndex={selectedIndex}
            handleDrawerToggle={handleDrawerToggle}
            handleNavigation={handleNavigation}
          />
        </Suspense>

        <RightDrawer
          anchor="right"
          open={openRightDrawer}
          onClose={() => setOpenRightDrawer(false)}
        >
          <Box sx={{ p: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <Avatar
                src={profileImage}
                sx={{ width: 56, height: 56, bgcolor: "primary.main" }} // Customize avatar
              ></Avatar>
              <Box sx={{ ml: 2 }}>
                <Typography
                  variant="subtitle1"
                  fontFamily="Satoshi"
                  sx={{ fontWeight: "bold" }}
                >
                  {userData?.name}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ fontWeight: "bold", fontFamily: "Satoshi" }}
                >
                  {userData?.email}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ fontWeight: "bold", fontFamily: "Satoshi" }}
                >
                  {userData?.role === "recruiter_admin"
                    ? "Recruiter Admin"
                    : userData?.role === "recruiter"
                    ? "Recruiter"
                    : userData?.role === "manager"
                    ? "Manager"
                    : userData?.role || "Unknown Role"}
                </Typography>
              </Box>
            </Box>
            <Divider />
          </Box>

          {/* Menu Items */}
          <MenuItem
            sx={{ px: 2, mb: 1, "&:hover": { bgcolor: "action.hover" } }}
            onClick={handleProfileClick}
          >
            <ListItemIcon>
              <Person2OutlinedIcon sx={{ color: "action.active" }} />
            </ListItemIcon>
            <ListItemText
              primary="View Profile"
              primaryTypographyProps={{
                variant: "body2",
                fontFamily: "Satoshi",
                fontWeight: "bold",
                color: "text.primary",
              }}
            />
          </MenuItem>
          <MenuItem
            sx={{ px: 2, mb: 1, "&:hover": { bgcolor: "action.hover" } }}
            onClick={() => {
              setOpenRightDrawer(false);
              navigate("/employer/companyProfile");
            }}
          >
            <ListItemIcon>
              <MeetingRoomOutlinedIcon sx={{ color: "action.active" }} />
            </ListItemIcon>
            <ListItemText
              primary="Company Profile"
              primaryTypographyProps={{
                variant: "body2",
                fontFamily: "Satoshi",
                fontWeight: "bold",
                color: "text.primary",
              }}
            />
          </MenuItem>
          <MenuItem
            sx={{ px: 2, mb: 1, "&:hover": { bgcolor: "action.hover" } }}
            onClick={() => {
              setOpenRightDrawer(false);
              navigate("/employer/subscriptionStatus");
            }}
          >
            <ListItemIcon>
              <SubscriptionsOutlinedIcon sx={{ color: "action.active" }} />
            </ListItemIcon>
            <ListItemText
              primary="My Subscriptions"
              primaryTypographyProps={{
                variant: "body2",
                fontFamily: "Satoshi",
                fontWeight: "bold",
                color: "text.primary",
              }}
            />
          </MenuItem>
          <MenuItem
            sx={{ px: 2, mb: 1, "&:hover": { bgcolor: "action.hover" } }}
            onClick={() => {
              setOpenRightDrawer(false);
              navigate("/deleteAccount");
            }}
          >
            <ListItemIcon>
              <DeleteOutlinedIcon sx={{ color: "action.active" }} />
            </ListItemIcon>
            <ListItemText
              primary="Delete Account"
              primaryTypographyProps={{
                variant: "body2",
                fontFamily: "Satoshi",
                fontWeight: "bold",
                color: "text.primary",
              }}
            />
          </MenuItem>
          <Divider sx={{ mb: 2 }} />

          <MenuItem
            onClick={handleSignOutClick}
            sx={{
              px: 2,
              mt: 1,
              mb: 1,
              fontFamily: "IBM Plex Sans",
              "&:hover": { bgcolor: "action.hover" },
            }}
          >
            <ListItemIcon>
              <ExitToAppIcon sx={{ color: "text.secondary" }} />
            </ListItemIcon>
            <ListItemText
              primary="Logout from Vacanzi"
              primaryTypographyProps={{
                variant: "body2",
                fontFamily: "Satoshi",
                fontWeight: "bold",
                color: "text.primary",
              }}
            />
          </MenuItem>
        </RightDrawer>

        <Box
          component="main"
          sx={{
            width: "100%",
            minHeight: "100vh",
            bgcolor: "#EFFAFCFF",
            py: 1,
            px: 0,
            margin: 0,
          }}
        >
          <DrawerHeader />
          {children}
        </Box>
        <PaymentDialog
          openPayDialog={openPayDialog}
          handlePayMenuClose={handlePayMenuClose}
        />
      </Box>
      <EmployerJobSeekerSupport />
    </ThemeProvider>
  );
};

export default EmployerDrawer;
