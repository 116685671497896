import React, { useEffect } from "react";
import {
  Box,
  Card,
  CardMedia,
  IconButton,
  Modal,
  Paper,
  Typography,
  Button,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import VideoCameraFrontOutlinedIcon from "@mui/icons-material/VideoCameraFrontOutlined";

const JobSeekerVideoGallery = ({
  open,
  setOpen,
  videoList,
  onDelete,
  handleVideoUpload,
  isUploading,
}) => {
  useEffect(() => {
    console.log("Video list updated:", videoList);
  }, [videoList]);

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Paper
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "70%",
          maxHeight: "90vh",
          bgcolor: "white",
          borderRadius: "12px",
          p: 3,
          overflowY: "auto",
          boxShadow: 24,
          "& .MuiDialog-paper": {
            backgroundColor: "#FFFFFFFF",
            color: "#000000FF",
            borderRadius: "12px",
            maxWidth: "850px",
          },
        }}
      >
        <IconButton
          onClick={() => setOpen(false)}
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
            color: "#333",
          }}
        >
          <CloseIcon />
        </IconButton>

        <Typography
          variant="h6"
          textAlign="center"
          mb={2}
          sx={{ fontFamily: "poppins", fontWeight: 500 }}
        >
          Video Gallery
        </Typography>

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: 2,
            overflowX: "auto",
          }}
        >
          {videoList?.map((video, index) => (
            <Card
              key={index}
              sx={{
                position: "relative",
                borderRadius: "8px",
                boxShadow: "none",
                width: "100%",
                height: "180px",
                backgroundColor: "#000000",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CardMedia
                component="video"
                src={video?.url}
                controls
                sx={{
                  borderRadius: "8px",
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: 5,
                  right: 5,
                  backgroundColor: "rgba(0, 0, 0, 0.6)",
                  borderRadius: "5px",
                  padding: "2px",
                }}
              >
                <IconButton
                  size="small"
                  sx={{ color: "white" }}
                  onClick={() => onDelete(video, "videoCV")}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Box>
            </Card>
          ))}
        </Box>

        <Box textAlign="center" mt={3}>
          <Button
            component="label"
            variant="outlined"
            startIcon={<VideoCameraFrontOutlinedIcon />}
            sx={{
              color: "#fff",
              fontFamily: "poppins",
              textTransform: "none",
              backgroundColor: "#353232FF",
              borderColor: "rgba(255, 255, 255, 0.2)",
              "&:hover": {
                borderColor: "#00897b",
                backgroundColor: "rgba(0, 137, 123, 0.1)",
                color: "#000",
              },
              "&.Mui-disabled": {
                backgroundColor: "grey", // Change this to your desired disabled background color
                color: "#d3d3d3", // Optional: Adjust text color for disabled state
              },
              padding: "10px",
              borderRadius: "8px",
            }}
            disabled={isUploading}
          >
            {isUploading ? "Uploading..." : "Upload Video"}
            <input
              accept="video/mp4,video/avi,video/webm"
              type="file"
              hidden
              onChange={(e) => {
                const file = e.target.files[0];
                if (file) {
                  handleVideoUpload(file);
                }
              }}
            />
          </Button>
        </Box>
      </Paper>
    </Modal>
  );
};

export default JobSeekerVideoGallery;
