import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  Typography,
  Box,
  TextField,
} from "@mui/material";
import { styled, keyframes } from "@mui/system";
import { ThreeDot } from "react-loading-indicators";

import { useState } from "react";


// Keyframe animation for fade-in effect
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Styled components
const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    borderRadius: 16,
    boxShadow: "0 8px 32px rgba(0, 0, 0, 0.1)",
    overflow: "hidden",
    animation: `${fadeIn} 0.3s ease-out`,
    maxHeight: "90vh",
    "&::-webkit-scrollbar": { display: "none" },
    msOverflowStyle: "none",
    scrollbarWidth: "none",
  },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: "#423bfa",
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(3),
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(4),
}));

const StyledRadioGroup = styled(RadioGroup)(({ theme }) => ({
  "& .MuiFormControlLabel-root": {
    marginBottom: theme.spacing(2),
  },
}));

const StyledRadio = styled(Radio)(({ theme }) => ({
  "&.Mui-checked": {
    color: theme.palette.secondary.main,
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: "0.7rem",
  padding: theme.spacing(1, 4),
  textTransform: "none",
  color: "#fff",
  backgroundColor: "#000",
  fontWeight: 600,
  transition: "all 0.3s ease",
  "&:hover": {
    transform: "translateY(-2px)",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
    color: "#fff",
    backgroundColor: "#000",
  },
}));

const ReportJobDialogBox = ({
  openReportDialog,
  handleCloseReportDialog,
  reportJobPost,
  loading,
  reportOptions,
  handleError
}) => {
  const [selectedReason, setSelectedReason] = useState("");
  const [customReason, setCustomReason] = useState("");

  const handleReasonChange = (event) => {
    const value = event.target.value;
    setSelectedReason(value);
    if (value !== "other") setCustomReason(""); // Clear custom reason if not "other"
  };

  const handleReport = async() => {
    const reasonToSubmit = selectedReason === "other" ? customReason : selectedReason;
    try{
      if (!reasonToSubmit.trim()) {
     
        handleError("Please enter a valid reason")
        return;
      }
      await reportJobPost(reasonToSubmit);
    }
    catch(error){
     console.log(error.message)
    }
    finally{
      handleCloseReportDialog();
    }
 

    // Pass the final reason to the parent


   
  };

  return (
    <StyledDialog open={openReportDialog} onClose={handleCloseReportDialog} fullWidth maxWidth="sm">
      <StyledDialogTitle sx={{ fontFamily: "Satoshi" }}>
        Report Issue
      </StyledDialogTitle>
      <StyledDialogContent>
        <Typography variant="subtitle1" gutterBottom sx={{ fontFamily: "Satoshi", mt: 2 }}>
          Please select a reason for your report:
        </Typography>

        <StyledRadioGroup value={selectedReason} onChange={handleReasonChange}>
          {reportOptions.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={<StyledRadio />}
              label={
                <Box>
                  <Typography variant="body1" sx={{ fontFamily: "Satoshi" }}>
                    {option.label}
                  </Typography>
                </Box>
              }
            />
          ))}
          <FormControlLabel
            value="other"
            control={<StyledRadio />}
            label={
              <Box>
                <Typography variant="body1" sx={{ fontFamily: "Satoshi" }}>
                  Other (please specify)
                </Typography>
              </Box>
            }
          />
          {selectedReason === "other" && (
            <TextField
              fullWidth
              placeholder="Enter your reason"
              value={customReason}
              onChange={(e) => setCustomReason(e.target.value)}
              sx={{ mt: 2 }}
            />
          )}
        </StyledRadioGroup>
      </StyledDialogContent>

      <DialogActions sx={{ padding: 3, justifyContent: "space-between" }}>
        <StyledButton onClick={handleCloseReportDialog} color="inherit" variant="outlined">
          Cancel
        </StyledButton>
        <StyledButton
          onClick={handleReport}
          color="primary"
          variant="contained"
          disabled={!selectedReason}
        >
          {loading ? <ThreeDot color="#FFFFFFFF" size="small" /> : "Report"}
        </StyledButton>
      </DialogActions>
    </StyledDialog>
  );
};

export default ReportJobDialogBox;