import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import ProtectedEmployerRoute from "../utils/protectedEmployerRoute";
// import EmployerJobPostPage from "../pages/EmployerPages/EmployerJobPost/EmployerJobPostPage";
import EmployerPostedJobDetails from "../pages/EmployerPages/EmployerJobPost/EmployerPostedJobDetails";
import EmployerJobListings from "../pages/EmployerPages/EmployerJobList/EmployerJobListings";
// import SearchCandidate from "../pages/EmployerPages/EmployerDatabases/searchCandidate";
import SavedSearch from "../pages/EmployerPages/EmployerDatabases/SavedSearch";
import UnlockedCandidates from "../pages/unlockedCandidates/UnlockedCandidates";
import CandidateSearchList from "../pages/EmployerPages/CandidateSearchList/CandidateSearchList";
import EmployerProfile from "../pages/EmployerPages/EmployerPersonalProfile/EmployerProfile";
import EmployerTeamMembers from "../pages/EmployerPages/EmployerTeamMemebers/EmployerTeamMembers";
import ManageUsers from "../pages/EmployerPages/ManageUsers/ManageUsers";
import CompanyProfile from "../pages/EmployerPages/CompanyProfile/CompanyProfile";
import SubscriptionStatus from "../pages/EmployerPages/SubscriptionStatus/SubscriptionStatus";
import EmployerPlanPaymentPage from "../pages/EmployerPages/SubscriptionStatus/EmployerPlanPaymentPage";
import SubscriptionSuccessPage from "../pages/EmployerPages/SubscriptionStatus/SubscriptionSuccessPage";
import EmployerReports from "../pages/EmployerPages/EmployerReports/EmployerReports";
import DeleteAccount from "../pages/EmployerPages/EmployerPersonalProfile/DeleteAccount";
// import EmployerSupport from "../pages/EmployerPages/EmployerSupport/EmployerSupport";
import EmployerTransactions from "../pages/EmployerPages/EmployerTransactions";
import { Suspense } from "react";
import PaperSkeleton from "../utils/lazy/PaperSkeleton";
import JobPostSkeleton from "../utils/lazy/JobPostSkeleton";
// import ApplicationTemplate from "../pages/EmployerPages/EmployerJobPost/ApplicationTemplate";
import ViewJobSeekerProfile from "../pages/EmployerPages/JobSeeekerProfile/ViewJobSeekerProfile";
import SupportSkeleton from "../utils/lazy/SupportSkeleton";
const SearchCandidate = lazy(() =>
  import("../pages/EmployerPages/EmployerDatabases/searchCandidate")
);
const EmployerJobPostPage = lazy(() =>
  import("../pages/EmployerPages/EmployerJobPost/EmployerJobPostPage")
);
const EmployerSupport = lazy(() =>
  import("../pages/EmployerPages/EmployerSupport/EmployerSupport")
);
const ApplicationTemplate = lazy(() =>
  import("../pages/EmployerPages/EmployerJobPost/ApplicationTemplate")
);


const EmployerRoute = () => {
  return (
    <Routes>
      <Route
        path="/employerJobPosting"
        element={
          <ProtectedEmployerRoute>
            <Suspense fallback={<JobPostSkeleton />}>
              <EmployerJobPostPage />
            </Suspense>
          </ProtectedEmployerRoute>
        }
      />
      <Route
        path="/viewJobSeekerProfile/:id"
        element={
          <ProtectedEmployerRoute>
            <ViewJobSeekerProfile />
          </ProtectedEmployerRoute>
        }
      />
      <Route
        path="/employerPostedJobDetails/:jobId"
        element={
          <ProtectedEmployerRoute>
            <EmployerPostedJobDetails />
          </ProtectedEmployerRoute>
        }
      />
      <Route
        path="/applicationTemplate/:jobId"
        element={
          <ProtectedEmployerRoute>
            <Suspense fallback={<JobPostSkeleton />}>
              <ApplicationTemplate />
            </Suspense>
          </ProtectedEmployerRoute>
        }
      />
      <Route
        path="/employerJobListings"
        element={
          <ProtectedEmployerRoute>
            <EmployerJobListings />
          </ProtectedEmployerRoute>
        }
      />

      <Route
        path="/search-candidates"
        element={
          <ProtectedEmployerRoute>
            <Suspense fallback={<PaperSkeleton />}>
              <SearchCandidate />
            </Suspense>
          </ProtectedEmployerRoute>
        }
      />

      <Route
        path="saved-searches"
        element={
          <ProtectedEmployerRoute>
            <SavedSearch />
          </ProtectedEmployerRoute>
        }
      />

      <Route
        path="/unlock-candidates"
        element={
          <ProtectedEmployerRoute>
            <UnlockedCandidates />
          </ProtectedEmployerRoute>
        }
      />
      <Route
        path="/candidate-searchList"
        element={
          <ProtectedEmployerRoute>
            <CandidateSearchList />
          </ProtectedEmployerRoute>
        }
      />
      <Route
        path="/employerProfile"
        element={
          <ProtectedEmployerRoute>
            <EmployerProfile />
          </ProtectedEmployerRoute>
        }
      />
      <Route
        path="/team-members"
        element={
          <ProtectedEmployerRoute>
            <EmployerTeamMembers />
          </ProtectedEmployerRoute>
        }
      />
      <Route
        path="/employer/manageUsers"
        element={
          <ProtectedEmployerRoute>
            <ManageUsers />
          </ProtectedEmployerRoute>
        }
      />
      <Route
        path="/employer/companyProfile"
        element={
          <ProtectedEmployerRoute>
            <CompanyProfile />
          </ProtectedEmployerRoute>
        }
      />
      <Route
        path="/employer/subscriptionStatus"
        element={
          <ProtectedEmployerRoute>
            <SubscriptionStatus />
          </ProtectedEmployerRoute>
        }
      />
      <Route
        path="/employer/paymentPage/:subscriptionId"
        element={
          <ProtectedEmployerRoute>
            <EmployerPlanPaymentPage />
          </ProtectedEmployerRoute>
        }
      />

      <Route
        path="/employer/success_subscription"
        element={
          <ProtectedEmployerRoute>
            <SubscriptionSuccessPage />
          </ProtectedEmployerRoute>
        }
      />
      <Route path="/employer-reports" element={<EmployerReports />} />
      <Route
        path="/deleteAccount"
        element={
          <ProtectedEmployerRoute>
            <DeleteAccount />
          </ProtectedEmployerRoute>
        }
      />
      <Route
        path="/employerSupport"
        element={
            <Suspense fallback={<SupportSkeleton />}>
              <EmployerSupport />
            </Suspense>
        }
      />
      <Route
        path="/employerTransactions"
        element={
          <ProtectedEmployerRoute>
            <EmployerTransactions />
          </ProtectedEmployerRoute>
        }
      />
    </Routes>
  );
};

export default EmployerRoute;
