import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Modal,
  TextField,
  IconButton,
  Grid,
} from "@mui/material";
import { PersonAdd, Settings, CreditCard } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";

const JobSeekerContactUs = ({ open, handleClose, handleSave, userData, handleModalOpen, selectedSupportType }) => {
  const [topic, setTopic] = useState("");
  const [message, setMessage] = useState("");
  const [name, setName] = useState(userData?.user?.fullName || ""); // Initialize with userData if available
  const [email, setEmail] = useState(userData?.user?.email || "");
  const [phone, setPhone] = useState(userData?.user?.phone || ""); // Assuming phone might be in userData
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    phone: "",
    topic: "",
    message: "",
  });

  const handleTopicChange = (event) => {
    setTopic(event.target.value);
    setErrors((prev) => ({ ...prev, topic: "" }));
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
    setErrors((prev) => ({ ...prev, message: "" }));
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
    setErrors((prev) => ({ ...prev, name: "" }));
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setErrors((prev) => ({ ...prev, email: "" }));
  };

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
    setErrors((prev) => ({ ...prev, phone: "" }));
  };

  const handleSaveClick = (e) => {
    e.preventDefault();
    let validationErrors = {};

    if (!name.trim()) validationErrors.name = "Name cannot be empty";
    if (!email.trim()) {
      validationErrors.email = "Email cannot be empty";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      validationErrors.email = "Email is invalid";
    }
    if (!phone.trim()) {
      validationErrors.phone = "Phone cannot be empty";
    } else if (!/^\d{10}$/.test(phone)) {
      validationErrors.phone = "Phone must be a 10-digit number";
    }
    if (!topic.trim()) validationErrors.topic = "Topic cannot be empty";
    if (!message.trim()) validationErrors.message = "Message cannot be empty";

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      const payload = {
        name,
        email,
        phone,
        topic,
        messageContent: message,
        supportType: selectedSupportType,
      };
      handleSave(payload); // Pass the full payload to handleSave
      // Reset form after submission
      setName(userData?.user?.fullName || "");
      setEmail(userData?.user?.email || "");
      setPhone(userData?.user?.phone || "");
      setTopic("");
      setMessage("");
      setErrors({ name: "", email: "", phone: "", topic: "", message: "" });
    }
  };

  return (
    <Box p={5} bgcolor="#f5f5f5">
      <Box display="flex" justifyContent="center" flexWrap="wrap" gap={4}>
        {[
          {
            icon: <PersonAdd sx={{ fontSize: 135, color: "grey" }} />,
            title: "General",
            desc: "General Support/Information",
            email: "support@vacanzi.com",
            supportType: "general",
          },
          {
            icon: <Settings sx={{ fontSize: 135, color: "grey" }} />,
            title: "Technical",
            desc: "Find Consultant/Book Appointment",
            email: "techsupport@vacanzi.com",
            supportType: "technical",
          },
          {
            icon: <CreditCard sx={{ fontSize: 135, color: "grey" }} />,
            title: "Payment",
            desc: "Payments/Data Related",
            email: "paymentsupport@vacanzi.com",
            supportType: "payment",
          },
        ].map((item, index) => (
          <Card
            key={index}
            sx={{
              width: 320,
              borderRadius: 3,
              boxShadow: 3,
              textAlign: "center",
              bgcolor: "white",
            }}
          >
            <CardContent>
              {item.icon}
              <Typography fontWeight={600} fontSize={25} mt={2}>
                {item.title}
              </Typography>
              <Typography color="textSecondary" pb={2}>
                {item.desc}
              </Typography>
              <Typography fontWeight={600}>
                For any query reach us on:
              </Typography>
              <Typography fontWeight={600} color="primary">
                {item.email}
              </Typography>
              <Typography fontWeight={600} color="primary">
                +91-8904241444
              </Typography>
              <Box pt={3}>
                <Button
                  variant="contained"
                  sx={{
                    bgcolor: "#423bfa",
                    color: "white",
                    textTransform: "none",
                    borderRadius: 2,
                    px: 3,
                  }}
                  onClick={() => handleModalOpen(item.supportType)}
                >
                  Click Here
                </Button>
              </Box>
            </CardContent>
          </Card>
        ))}
      </Box>

      <Modal open={open} onClose={handleClose}>
        <Box
          position="absolute"
          top="30%"
          left="40%"
          transform="translate(-50%, -50%)"
          bgcolor="white"
          p={4}
          borderRadius={2}
          boxShadow={3}
          width={400}
        >
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
            }}
          >
            <CloseIcon />
          </IconButton>

          <form onSubmit={handleSaveClick}>
            <Grid container spacing={1.5} sx={{ mt: 2 }}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2" sx={{ mb: 0.5 }}>
                  Your Name
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  name="name"
                  placeholder="Enter Your Name"
                  value={name}
                  onChange={handleNameChange}
                  error={!!errors.name}
                  helperText={errors.name}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2" sx={{ mb: 0.5 }}>
                  Your Email
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  type="email"
                  name="email"
                  placeholder="Enter Your Email"
                  value={email}
                  onChange={handleEmailChange}
                  error={!!errors.email}
                  helperText={errors.email}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ mb: 0.5 }}>
                  Your Phone
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  type="tel"
                  name="phone"
                  placeholder="Enter Your Phone (10 digits)"
                  value={phone}
                  onChange={handlePhoneChange}
                  error={!!errors.phone}
                  helperText={errors.phone}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ mb: 0.5 }}>
                  Subject
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  name="subject"
                  placeholder="Enter subject"
                  value={topic}
                  onChange={handleTopicChange}
                  error={!!errors.topic}
                  helperText={errors.topic}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ mb: 0.5 }}>
                  Message
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  name="message"
                  placeholder="Enter your message"
                  multiline
                  rows={4}
                  value={message}
                  onChange={handleMessageChange}
                  error={!!errors.message}
                  helperText={errors.message}
                />
              </Grid>
              <Grid item xs={12} sx={{ textAlign: "center", mt: 2 }}>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    px: 4,
                    py: 1,
                    backgroundColor: "black",
                    textTransform: "none",
                    fontWeight: "bold",
                  }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </Box>
  );
};

export default JobSeekerContactUs;