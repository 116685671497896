import React, { useState, useEffect, Suspense, lazy,useCallback } from "react";
import {
  Typography,
  IconButton,
  Tooltip,
  Button,
  Avatar,
  Container,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import ConstructionOutlinedIcon from "@mui/icons-material/ConstructionOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Footer from "../../../components/JobSeekerComponents/Footer/Footer";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { jwtDecode } from "jwt-decode";
import baseURL from "../../../api/baseUrl";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import NewNavBar from "../../../components/JobSeekerComponents/NavBar/NewNavbar";
import EditIcon from "@mui/icons-material/Edit";
import ReviewModal from "../../../components/JobSeekerComponents/Reviews/ReviewModal";
import PopupSuccess from "../../../utils/popup/PopupSuccess";
import ReleaseJobPostModel from "../../../pages/EmployerPages/SubscriptionStatus/ReleaseJobPostModel";

const JobCardSkeleton = lazy(() =>
  import("../../../utils/lazy/JobCardSkeleton")
);

const JobSeekerSavedJobs = () => {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [playingVideoId, setPlayingVideoId] = useState(null);
  const navigate = useNavigate();
  const [openModalJobId, setOpenModalJobId] = useState(null); // Track which job's modal is open
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSuccess = useCallback((message) => {
    setSuccessMessage(message);
    setSuccessOpen(true);
    setTimeout(() => {
      setSuccessOpen(false);
      setSuccessMessage("");
    }, 2000);
  }, []);

  const handleError = useCallback((message) => {
    setErrorMessage(message);
    setErrorOpen(true);
    setTimeout(() => {
      setErrorOpen(false);
      setErrorMessage("");
    }, 2000);
  }, []);

  const fetchSavedJobs = async () => {
    try {
      const authToken = localStorage.getItem("authToken");
      if (!authToken) throw new Error("User not authenticated");

      const decodedToken = jwtDecode(authToken);
      const userId = decodedToken.id;

      const response = await fetch(`${baseURL}/jobs/getsavedJobs/${userId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setJobs(data?.savedJobs || []);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSavedJobs();
  }, []);

  const handlePlay = (jobId) => {
    setPlayingVideoId(playingVideoId === jobId ? null : jobId);
  };

  const postedDateConvert = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  const handleDelete = async (jobId) => {
    try {
      const authToken = localStorage.getItem("authToken");
      if (!authToken) throw new Error("User not authenticated");

      const decodedToken = jwtDecode(authToken);
      const userId = decodedToken.id;

      const response = await fetch(
        `${baseURL}/jobs/deleteSavedJobs/${userId}/${jobId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) throw new Error("Failed to delete saved job");

      setJobs((prevJobs) =>
        prevJobs?.filter((job) => job?.jobId?._id !== jobId)
      );
      handleSuccess("Job removed from saved list");
    } catch (error) {
      handleError(error.message);
    }
  };

  const truncateText = (text, maxWords) => {
    const words = text.split(" ");
    if (words.length > maxWords) {
      return words.slice(0, maxWords).join(" ") + "...";
    }
    return text;
  };

  if (loading) {
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <JobCardSkeleton />
      </Suspense>
    );
  }

  if (error) {
    return (
      <Typography variant="body1" color="error" sx={{ mt: 4 }}>
        {error}
      </Typography>
    );
  }

  const profileImage = localStorage.getItem("profileImage");
  const userName = localStorage.getItem("userName");

  return (
    <>
      <NewNavBar />
      <Box
        sx={{
          background: "linear-gradient(135deg, #F8F9FA, #CED4DA)",
          display: "flex",
          margin: 0,
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          minHeight: "100vh",
          padding: "30px ",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            textAlign: "center",
            mb: 4,
            fontFamily: "IBM Plex Sans",
            fontWeight: 600,
          }}
        >
          <span style={{ color: "#423bfa" }}>{userName}</span>, Saved Jobs
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Avatar
            sx={{ width: "100px", height: "100px", mb: 1 }}
            src={profileImage}
          />
        </Box>
        {jobs.length > 0 ? (
          <Container maxWidth="xl">
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                background: "linear-gradient(135deg, #F8F9FA, #CED4DA)",
                padding: 2,
                justifyContent: "center",
                gap: 2,
              }}
            >
              {jobs.map((job) => {
                const averageRating =
                  job?.jobId?.jsReviews && job.jobId.jsReviews.length > 0
                    ? (
                        job.jobId.jsReviews.reduce(
                          (sum, review) => sum + review.rating,
                          0
                        ) / job.jobId.jsReviews.length
                      ).toFixed(1)
                    : null;

                return (
                  <Box
                    key={job.jobId?._id}
                    sx={{
                      flex: "0 1 calc(50% - 10px)",
                      mt: 1,
                      p: 3,
                      background: "#ffffff",
                      borderRadius: "20px",
                      boxShadow: "0 6px 12px rgba(30, 10, 58, .04)",
                      marginBottom: 3,
                      padding: "24px 24px 20px",
                      transition: "box-shadow .2s linear",
                      fontFamily: "IBM Plex Sans, sans-serif",
                      position: "relative",
                      display: "flex",
                      gap: 3,
                      maxWidth: "800px",
                      height: "auto",
                      "&:hover": {
                        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                      },
                    }}
                  >
                    {/* Left Side - Video Player */}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <Box
                        sx={{
                          width: "160px",
                          height: "190px",
                          borderRadius: 4,
                          overflow: "hidden",
                          bgcolor: "black",
                          flexShrink: 0,
                          position: "relative",
                        }}
                      >
                        <Box
                          component="img"
                          src={job.jobId?.organizationId?.companyLogo?.url}
                          alt="Video thumbnail"
                          sx={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            cursor: "pointer",
                          }}
                          onClick={() => handlePlay(job.jobId?._id)}
                        />
                      </Box>
                      <Button
                        variant="contained"
                        onClick={() => setOpenModalJobId(job.jobId?._id)}
                        size="small"
                        endIcon={<EditIcon sx={{ fontSize: "16px" }} />}
                        sx={{
                          mt: 2,

                          backgroundColor: "#423bfa",
                          color: "white",
                          fontWeight: "bold",
                          borderRadius: "0.7rem",
                          padding: "4px 12px", // Adjusted padding for better spacing
                          textTransform: "none",
                          fontSize: "0.8rem",
                          "&:hover": {
                            backgroundColor: "#B0F0F0",
                            color: "#000",
                          },
                          display: "flex", // Ensure flex for internal alignment
                          alignItems: "center", // Vertically center text and icon
                          justifyContent: "center", // Horizontally center content
                          minWidth: "auto", // Prevent excessive width
                        }}
                      >
                        {job?.jobId?.jsReviews && job.jobId.jsReviews.length > 0
                          ? "Reviewed"
                          : "Write a Review"}
                      </Button>
                    </Box>
                    <ReviewModal
                      open={openModalJobId === job.jobId?._id}
                      onClose={() => setOpenModalJobId(null)}
                      companyName={job?.jobId?.orgName}
                      jobPostId={job?.jobId?._id}
                      averageRating={averageRating}
                      onRatingSubmitted={fetchSavedJobs} // Add this prop
                    />
                    {/* Right Side - Job Content */}
                    <Box sx={{ flex: 1 }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mb: 1,
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          navigate(`/JobSeekerFilter/job/${job?.jobId?._id}`)
                        }
                      >
                        <Box>
                          <Typography
                            variant="h6"
                            sx={{
                              fontFamily: "IBM Plex Sans, sans-serif",
                              fontWeight: 600,
                              color: "#1A1A1A",
                              fontSize: "1.1rem",
                              mb: 0.5,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {job?.jobId?.jobTitle}
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                              mb: 0.5,
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: "IBM Plex Sans, sans-serif",
                                color: "#484848",
                                fontSize: "0.95rem",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {job?.jobId?.organizationId?.organizationName}
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 0.5,
                              }}
                            >
                              {job?.jobId?.jsReviews &&
                              job.jobId.jsReviews.length > 0 ? (
                                <Typography
                                  sx={{
                                    fontFamily: "Satoshi",
                                    color: "#423bfa",
                                    fontWeight: "bold",
                                    px: 0.8,
                                    py: 0.2,
                                    borderRadius: 1,
                                    fontSize: "0.8rem",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <StarIcon
                                    sx={{ fontSize: "0.9rem", mr: 0.3 }}
                                  />
                                  {averageRating}
                                </Typography>
                              ) : null}
                            </Box>
                          </Box>
                        </Box>
                        <Box
                          component="img"
                          src={job?.jobId?.organizationId?.companyLogo?.url}
                          sx={{
                            width: 45,
                            height: 45,
                            borderRadius: 1,
                            flexShrink: 0,
                            objectFit: "cover",
                          }}
                        />
                      </Box>

                      {/* Rest of the Details Section */}
                      <Box sx={{ display: "flex", gap: 3, mb: 1 }}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 0.5,
                          }}
                        >
                          <WorkOutlineOutlinedIcon
                            sx={{ color: "#666", fontSize: "1.1rem" }}
                          />
                          <Typography
                            sx={{
                              color: "#484848",
                              fontSize: "0.9rem",
                              fontFamily: "IBM Plex Sans, sans-serif",
                            }}
                          >
                            {job?.jobId?.requiredExperienceMin ||
                              "Not Mentioned"}{" "}
                            -{" "}
                            {job?.jobId?.requiredExperienceMax ||
                              "Not Mentioned"}
                          </Typography>
                        </Box>
                        <Typography
                          sx={{
                            color: "#484848",
                            fontSize: "0.9rem",
                            fontFamily: "IBM Plex Sans, sans-serif",
                          }}
                        >
                          ₹ {job?.jobId?.ctcMin || "Not Disclosed"} -{" "}
                          {job?.jobId?.ctcMax || "Not Disclosed"}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mb: 1,
                        }}
                      >
                        <LocationOnOutlinedIcon
                          sx={{ color: "#666", fontSize: "1.1rem" }}
                        />
                        <Typography
                          sx={{
                            color: "#484848",
                            fontSize: "0.9rem",
                            fontFamily: "IBM Plex Sans, sans-serif",
                          }}
                        >
                          {Array.isArray(job?.jobId?.jobLocations) &&
                          job?.jobId?.jobLocations.length > 0
                            ? truncateText(
                                job?.jobId?.jobLocations
                                  .map((loc) => loc?.location)
                                  .join(", "),
                                4
                              )
                            : "Location Not Available"}
                        </Typography>
                      </Box>

                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.8 }}>
                        <DescriptionOutlinedIcon
                          sx={{ color: "#666", fontSize: "1.1rem" }}
                        />
                        <Typography
                          sx={{
                            color: "#484848",
                            fontSize: "0.9rem",
                            fontFamily: "IBM Plex Sans, sans-serif",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "normal",
                            display: "-webkit-box",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                            maxWidth: "100%",
                          }}
                        >
                          Role: {truncateText(job?.jobId?.jobRole || "", 6)}
                        </Typography>
                      </Box>

                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                        <ConstructionOutlinedIcon
                          sx={{ color: "#666", fontSize: "1.1rem" }}
                        />
                        <Typography
                          sx={{
                            fontSize: "0.9rem",
                            color: "#000",
                            fontFamily: "Satoshi",
                          }}
                        >
                          Skills:{" "}
                          {(Array.isArray(job?.jobId?.skillPreferences)
                            ? job?.jobId?.skillPreferences
                            : job?.jobId?.skillPreferences?.split(",")
                          )
                            ?.map((tag) => tag.trim())
                            .map((tag, index, array) => (
                              <React.Fragment key={index}>
                                {truncateText(tag, 1)}
                                {index !== array.length - 1 && ", "}
                              </React.Fragment>
                            ))}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          mt: 2,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            gap: 0.5,
                          }}
                        >
                          <AccessTimeIcon
                            sx={{ color: "#666", fontSize: "0.9rem" }}
                          />
                          <Typography
                            sx={{
                              color: "#666",
                              fontSize: "0.85rem",
                              fontFamily: "IBM Plex Sans, sans-serif",
                            }}
                          >
                            Posted on {postedDateConvert(job?.jobId?.postDate)}
                          </Typography>
                          <Typography
                            sx={{
                              color: "#666",
                              fontSize: "0.85rem",
                              fontFamily: "IBM Plex Sans, sans-serif",
                              marginLeft: "20px",
                            }}
                          >
                            Saved on {postedDateConvert(job?.savedAt)}
                          </Typography>
                        </Box>
                        <Tooltip title="Remove from the list">
                          <IconButton
                            onClick={() => handleDelete(job?.jobId?._id)}
                            sx={{ color: "#035414FF" }}
                          >
                            <BookmarkIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Container>
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
          >
            <Typography variant="h5" component="h2" mt={4} mb={2}>
              No saved jobs found
            </Typography>
            <Typography variant="body1" color="text.secondary" mb={4}>
              You haven't saved any jobs yet. Start exploring and save jobs that
              interest you!
            </Typography>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#000000",
                borderRadius: "0.7rem",
                textTransform: "none",
                ":hover": { backgroundColor: "#000000" },
              }}
              onClick={() => navigate("/findJobs")}
            >
              Find Job
            </Button>
          </Box>
        )}
      </Box>
      <PopupSuccess
        modelOpen={successOpen}
        onClose={() => setSuccessOpen(false)}
        message={successMessage}
      />
      <ReleaseJobPostModel
        modelOpen={errorOpen}
        onClose={() => setErrorOpen(false)}
        message={errorMessage}
      />

      <Footer />
    </>
  );
};

export default JobSeekerSavedJobs;
