import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Container,
  Grid,
  Typography,
  Paper,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
import { getUserApi } from "../../../api/jobseeker";

const JobSeekerSupportForm = ({ handleSave }) => {
  const token = localStorage.getItem("authToken");
  const userId = localStorage.getItem("userId");
  const [userData, setUserData] = useState();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState(""); // Added phone state

  useEffect(() => {
    if (token) {
      const getUserDetails = async () => {
        try {
          const response = await getUserApi(userId, token);
          const user = response?.user;
          setUserData(user);

          if (user) {
            setName(user.fullName || "");
            setEmail(user.email || "");
            setPhone(user.phone || ""); // Assuming phone might be in user data
          }
        } catch (error) {
          console.log(error.message);
        }
      };
      getUserDetails();
    }
  }, [token, userId]);

  const [topic, setTopic] = useState("");
  const [message, setMessage] = useState("");
  const [supportType, setSupportType] = useState("");
  const [errors, setErrors] = useState({
    name: "", // Added validation for name
    email: "", // Added validation for email
    phone: "", // Added validation for phone
    topic: "",
    message: "",
    supportType: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const supportOptions = [
    { value: "general", label: "General" },
    { value: "technical", label: "Technical" },
    { value: "payment", label: "Payment" },
    { value: "fraudAlert", label: "Fraud Alert" },
  ];

  const handleTopicChange = (event) => {
    setTopic(event.target.value);
    setErrors((prev) => ({ ...prev, topic: "" }));
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
    setErrors((prev) => ({ ...prev, message: "" }));
  };

  const handleSupportTypeChange = (event) => {
    setSupportType(event.target.value);
    setErrors((prev) => ({ ...prev, supportType: "" }));
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
    setErrors((prev) => ({ ...prev, name: "" }));
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setErrors((prev) => ({ ...prev, email: "" }));
  };

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
    setErrors((prev) => ({ ...prev, phone: "" }));
  };

  const clearForm = () => {
    setName(token ? userData?.fullName || "" : ""); // Reset to userData if available
    setEmail(token ? userData?.email || "" : "");
    setPhone(token ? userData?.phone || "" : "");
    setTopic("");
    setMessage("");
    setSupportType("");
    setErrors({ name: "", email: "", phone: "", topic: "", message: "", supportType: "" });
  };

  const handleSaveClick = async (e) => {
    e.preventDefault();
    let validationErrors = {};

    if (!name.trim()) validationErrors.name = "Name cannot be empty";
    if (!email.trim()) {
      validationErrors.email = "Email cannot be empty";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      validationErrors.email = "Email is invalid";
    }
    if (!phone.trim()) {
      validationErrors.phone = "Phone cannot be empty";
    } else if (!/^\d{10}$/.test(phone)) { // Basic phone validation (10 digits)
      validationErrors.phone = "Phone must be a 10-digit number";
    }
    if (!topic.trim()) validationErrors.topic = "Topic cannot be empty";
    if (!message.trim()) validationErrors.message = "Message cannot be empty";
    if (!supportType) validationErrors.supportType = "Please select a support type";

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setIsSubmitting(true);
    setSuccessMessage("");

    try {
      if (typeof handleSave !== "function") {
        throw new Error("handleSave is not a function");
      }

      const payload = {
        name,
        email,
        phone,
        topic,
        messageContent: message,
        supportType,
      };

      await handleSave(payload); // Pass the full payload to handleSave

      clearForm();
      setSuccessMessage("Support request submitted successfully!");
      setTimeout(() => setSuccessMessage(""), 3000);
    } catch (error) {
      console.error("Error submitting support request:", error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      <Paper
        elevation={3}
        sx={{
          p: 3,
          borderRadius: "1rem",
          maxWidth: 500,
          mx: "auto",
          backgroundColor: "#fff",
        }}
      >
        <form onSubmit={handleSaveClick}>
          <Typography
            variant="h6"
            sx={{ mb: 2, fontWeight: 600, textAlign: "center" }}
          >
            Contact Us
          </Typography>
          {successMessage && (
            <Typography
              variant="body2"
              sx={{ mb: 2, color: "green", textAlign: "center" }}
            >
              {successMessage}
            </Typography>
          )}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" sx={{ mb: 0.5, fontWeight: 500 }}>
                Your Name
              </Typography>
              <TextField
                fullWidth
                size="small"
                name="name"
                placeholder="Enter Your Name"
                value={name}
                onChange={handleNameChange}
                error={!!errors.name}
                helperText={errors.name}
                disabled={isSubmitting}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" sx={{ mb: 0.5, fontWeight: 500 }}>
                Your Email
              </Typography>
              <TextField
                fullWidth
                size="small"
                type="email"
                name="email"
                placeholder="Enter Your Email"
                value={email}
                onChange={handleEmailChange}
                error={!!errors.email}
                helperText={errors.email}
                disabled={isSubmitting}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" sx={{ mb: 0.5, fontWeight: 500 }}>
                Your Phone
              </Typography>
              <TextField
                fullWidth
                size="small"
                type="tel"
                name="phone"
                placeholder="Enter Your Phone (10 digits)"
                value={phone}
                onChange={handlePhoneChange}
                error={!!errors.phone}
                helperText={errors.phone}
                disabled={isSubmitting}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" sx={{ mb: 0.5, fontWeight: 500 }}>
                Subject
              </Typography>
              <TextField
                fullWidth
                size="small"
                name="subject"
                placeholder="Enter subject"
                value={topic}
                onChange={handleTopicChange}
                error={!!errors.topic}
                helperText={errors.topic}
                disabled={isSubmitting}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth size="small" error={!!errors.supportType}>
                <Typography variant="body2" sx={{ mb: 0.5, fontWeight: 500 }}>
                  Support Type
                </Typography>
                <Select
                  value={supportType}
                  onChange={handleSupportTypeChange}
                  displayEmpty
                  renderValue={(selected) => {
                    if (!selected) {
                      return <em>Select support type</em>;
                    }
                    return supportOptions.find(
                      (option) => option.value === selected
                    )?.label;
                  }}
                  disabled={isSubmitting}
                >
                  <MenuItem value="" disabled>
                    <em>Select support type</em>
                  </MenuItem>
                  {supportOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                {errors.supportType && (
                  <Typography variant="caption" color="error">
                    {errors.supportType}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" sx={{ mb: 0.5, fontWeight: 500 }}>
                Message
              </Typography>
              <TextField
                fullWidth
                size="small"
                name="message"
                placeholder="Enter your message"
                multiline
                rows={4}
                value={message}
                onChange={handleMessageChange}
                error={!!errors.message}
                helperText={errors.message}
                disabled={isSubmitting}
              />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center", mt: 2 }}>
              <Button
                type="submit"
                variant="contained"
                disabled={isSubmitting}
                sx={{
                  backgroundColor: "#000",
                  color: "#fff",
                  textTransform: "none",
                  fontWeight: 600,
                  padding: "0.7rem 2rem",
                  borderRadius: "0.8rem",
                  fontSize: "1rem",
                  fontFamily: "IBM Plex Sans, sans-serif",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                  "&:hover": {
                    backgroundColor: "#222",
                    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.3)",
                  },
                  "&:disabled": {
                    backgroundColor: "#ccc",
                    color: "#666",
                  },
                }}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default JobSeekerSupportForm;