import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Button,
  Container,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIos";
import { getDepartmentsApi } from "../../../api/common";

const RoleCard = ({ title, image }) => {
  const navigate = useNavigate();
  const handleNavigation = (value) => {
    const departmentArray = Array.isArray(value) ? value : [value];
    navigate("/findjobs", {
      state: {
        departments: departmentArray,
      },
    });
  };
  return (
    <Paper
      elevation={0}
      sx={{
        border: "1px solid #DFDFE7FF",
        padding: "18px",
        fontFamily: "IBM Plex Sans",
        borderRadius: 2,
        cursor: "pointer",
        transition: "all 0.3s ease",
        "&:hover": {
          transform: "translateY(-5px)",
          boxShadow: "0 10px 20px rgba(0,0,0,0.1)",
        },
        flex: "0 0 auto",
        width: "auto",
        height: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
      onClick={() => handleNavigation(title)}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontWeight: "bold",
            fontSize: 16,
            mx: 2,
            whiteSpace: "nowrap",
          }}
        >
          {title}
        </Typography>
        <Box component="img" src={image} width={40} height={40} />
      </Box>
    </Paper>
  );
};

const JobSeekerTrendingJobs = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [departments, setDepartments] = useState([]);
  const visibleRoles = 5;
  const navigate = useNavigate();

  useEffect(() => {
    const getAllDepartment = async () => {
      try {
        const response = await getDepartmentsApi();
        if (response && response.length > 0 && response[0].items) {
          setDepartments(response[0].items);
        } else {
          console.error("Invalid API response format:", response);
        }
      } catch (error) {
        console.error("Error fetching departments:", error.message);
      }
    };
    getAllDepartment();
  }, []);

  useEffect(() => {
    if (departments.length > visibleRoles) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % departments.length);
      }, 2500);
      return () => clearInterval(interval);
    }
  }, [departments]);

  return (
    <Box sx={{ padding: 3, position: "relative", minHeight: "40vh",mt:5, display: "flex", flexDirection: "column" }}>
      <Typography variant="h4" fontWeight="bold" textAlign="center" color="#000000" mb={4} fontFamily="IBM plex sans">
        Trending Job Roles By Departments
      </Typography>
      <Container maxWidth="lg">
        <Box sx={{ position: "relative", overflow: "hidden", width: "100%" }}>
          <IconButton
            onClick={() => setCurrentIndex((prev) => (prev - 1 + departments.length) % departments.length)}
            sx={{
              position: "absolute",
              left: 0,
              top: "50%",
              transform: "translateY(-50%)",
              zIndex: 2,
              backgroundColor: "rgba(255, 255, 255, 0.7)",
            }}
          >
            <ArrowBackIosNewIcon />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              flexWrap: "nowrap",
              gap: 4,
              transition: "transform 0.5s ease-in-out",
              transform: `translateX(-${currentIndex * (100 / visibleRoles)}%)`,
            }}
          >
            {departments?.map((dep, index) => (
              <RoleCard key={dep?.id || index} title={dep?.name} image={dep?.image} />
            ))}
          </Box>
          <IconButton
            onClick={() => setCurrentIndex((prev) => (prev + 1) % departments.length)}
            sx={{
              position: "absolute",
              right: 0,
              top: "50%",
              transform: "translateY(-50%)",
              zIndex: 2,
              backgroundColor: "rgba(255, 255, 255, 0.7)",
            }}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </Box>
      </Container>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <Button
          endIcon={<ArrowForwardIosIcon sx={{ fontSize: "12px" }} />}
          variant="outlined"
          sx={{
            color: "#ffffff",
            padding: "0.375rem 0.75rem",
            backgroundColor: "#18181b",
            fontWeight: "bold",
            borderRadius: "0.7rem",
            fontFamily: "IBM plex sans",
            textTransform: "none",
            borderColor: "#18181b",
            "&:hover": {
              backgroundColor: "#21867a",
              color: "white",
            },
          }}
          onClick={() => navigate("/findJobs")}
        >
          View All
        </Button>
      </Box>
    </Box>
  );
};

export default JobSeekerTrendingJobs;