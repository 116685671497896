import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Autocomplete,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { debounce } from "lodash";
import { getTopCitiesApi } from "../../api/common";

const CompanySidebar = ({ handleLocationFilter }) => {
  const [inputValue, setInputValue] = useState("");
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const fetchCities = useMemo(
    () =>
      debounce(async (query) => {
        try {
          if (query.trim().length >= 2) {
            const response = await getTopCitiesApi(query);
            if (response && response.cities) {
              setLocations(response.cities);
            }
          } else {
            setLocations([]);
          }
        } catch (error) {
          console.log(error.message);
        }
      }, 300),
    []
  );

  useEffect(() => {
    fetchCities(inputValue);
  }, [inputValue, fetchCities]);

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const handleCityChange = (event, newValue) => {
    setSelectedLocation(newValue);
    handleLocationFilter(newValue); // Pass null if cleared
  };

  const handleReset = () => {
    setSelectedLocation(null);
    setInputValue("");
    handleLocationFilter(null); // Reset the filter in the parent
  };

  return (
    <Box sx={{ backgroundColor: "#fff", p: 2, borderRadius: 2, boxShadow: 1 }}>
      <Accordion
        defaultExpanded
        sx={{
          boxShadow: "none",
          backgroundColor: "transparent",
          "&:before": { display: "none" },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: "#666", opacity: 0.7 }} />}
          sx={{
            padding: 0,
            minHeight: "40px",
            "& .MuiAccordionSummary-content": { margin: "0" },
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "bold",
              fontFamily: "IBM Plex Sans, sans-serif",
              color: "#333",
            }}
          >
            Location
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: "0 0 0" }}>
          <Autocomplete
            freeSolo
            options={locations?.map((city) => city.name)}
            value={selectedLocation}
            onChange={handleCityChange}
            onInputChange={handleInputChange}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Search for a city"
                sx={{
                  backgroundColor: "#f9f9f9",
                  borderRadius: "10px",
                  fontFamily: "IBM Plex Sans",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "10px",
                    "& fieldset": { borderColor: "#e0e0e0" },
                    "&:hover fieldset": { borderColor: "#423bfa" },
                    "&.Mui-focused fieldset": { borderColor: "#423bfa" },
                  },
                }}
              />
            )}
          />
          <Button
            onClick={handleReset}
            sx={{
              mt: 1,
              textTransform: "none",
              fontFamily: "IBM Plex Sans",
              color: "#423bfa",
            }}
          >
            Reset Location
          </Button>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default CompanySidebar;