import React, { useState,useCallback } from "react";
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  // Checkbox,
  // FormControlLabel,
  // Link,
  styled,
  // Select,
  // MenuItem,
  Grid,
  TextareaAutosize,
  Chip,
  Autocomplete,
} from "@mui/material";
// import PublicIcon from "@mui/icons-material/Public";
import { createCampaignApi } from "../../../api/common";
import { ThreeDot } from "react-loading-indicators";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import PopupSuccess from "../../../utils/popup/PopupSuccess";
import ReleaseJobPostModel from "../../../pages/EmployerPages/SubscriptionStatus/ReleaseJobPostModel";
// import JobSeekerLetBuild from "../../../components/JobSeekerComponents/JobSeekerHomePageBanners/JobSeekerLetBuild";
import JobSeekerLetBuildForm from "../../../components/JobSeekerComponents/JobSeekerHomePageBanners/JobSeekerLetBuildForm";

const tags = [
  "motivational",
  "funny",
  "inspirational",
  "relatableWorkMoments",
  "teamBonding",
  "mostLiked",
  "mostShared",
  "lastestUploads",
  "workLifebalance",
  "officePranks",
  "remoteWork",
  "mondayBlues",
  "teamWork",
  "leaderShipMoments",
  "funnyInterviews",
].map((tag) => ({
  label: tag.charAt(0).toUpperCase() + tag.slice(1), // Capitalize first letter for label
  value: tag, // Keep original as value
}));
// Styled components
const StyledContainer = styled(Container)({
  maxWidth: "xl",
  padding: "24px",
});

// const Footer = styled(Box)(({ theme }) => ({
//   borderTop: `1px solid ${theme.palette.divider}`,
//   marginTop: "40px",
//   paddingTop: "24px",
//   display: "flex",
//   justifyContent: "space-between",
//   alignItems: "center",
//   flexWrap: "wrap",
//   gap: "16px",
// }));
// const VideoPreviewBox = styled(Box)(({ theme }) => ({
//   width: "60%",
//   height: "350px",
//   position: "relative",
//   backgroundImage: "url(/iphone.png)",
//   backgroundSize: "cover",
//   backgroundRepeat: "no-repeat",
//   backgroundPosition: "center",
//   display: "flex",
//   justifyContent: "center",
//   alignItems: "center",

//   // Breakpoints for the Box itself

//   [theme.breakpoints.down("xl")]: {
//     width: "60%", // Applies when screen width < 1200px
//     height: "350px",
//   },

//   [theme.breakpoints.down("lg")]: {
//     width: "70%", // Applies when screen width < 1200px
//     height: "350px",

//     backgroundSize: "cover",
//   },
//   [theme.breakpoints.down("md")]: {
//     width: "50%", // Applies when screen width < 900px
//     height: "300px",
//   },

//   // Styles for video and placeholder
//   "& video, & .placeholder": {
//     width: "39%",
//     height: "82%",
//     position: "absolute",
//     top: "14%",
//     left: "50%",
//     transform: "translateX(-49.5%)",
//     objectFit: "cover",
//     borderRadius: "0 0 20px 20px",

//     // Nested breakpoints for video/placeholder

//     [theme.breakpoints.down("xl")]: {
//       width: "40%", // Applies when screen width < 1200px
//       height: "78%",
//     },

//     [theme.breakpoints.down("lg")]: {
//       width: "47%", // Applies when screen width < 1200px
//       height: "77%",
//       borderRadius: "0 0 10px 10px",
//     },
//     [theme.breakpoints.down("md")]: {
//       width: "auto", // Applies when screen width < 900px
//       height: "84%",
//       borderRadius: "0 0 24px 24px",
//     },
//   },

//   "& .placeholder": {
//     backgroundColor: "#e0e0e0",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//   },
// }));

// const LogoPreviewBox = styled(Box)(({ theme }) => ({
//   width: "250px",
//   height: "250px",
//   borderRadius: "8px",
//   border: "2px solid #ccc",
//   display: "flex",
//   justifyContent: "center",
//   alignItems: "center",
//   overflow: "hidden",
//   "& img": {
//     width: "100%",
//     height: "100%",
//     objectFit: "cover",
//   },
//   "& .placeholder": {
//     backgroundColor: "#e0e0e0",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     width: "100%",
//     height: "100%",
//   },
// }));

const FormSection = styled(Box)({
  backgroundColor: "#ffffff",
  padding: "24px",
  borderRadius: "8px",
  marginBottom: "24px",
});

const MarketPage = () => {
  const navigate = useNavigate();
  // const [videoFile, setVideoFile] = useState(null);
  // const [logoFile, setLogoFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [agreementChecked, setAgreementChecked] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    targetUrl: "",
    startDate: "",
    endDate: "",
    budget: "",
    content: "",
    tags: [],
    media: null,
    campaignLogo: null,
  });
  const token = localStorage.getItem("authToken");
  const userName = localStorage.getItem("userName");

  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSuccess = useCallback((message) => {
    setSuccessMessage(message);
    setSuccessOpen(true);
    setTimeout(() => {
      setSuccessOpen(false);
      setSuccessMessage("");
    }, 2000);
  }, []);

  const handleError = useCallback((message) => {
    setErrorMessage(message);
    setErrorOpen(true);
    setTimeout(() => {
      setErrorOpen(false);
      setErrorMessage("");
    }, 2000);
  }, []);

  // const handleVideoUpload = (event) => {
  //   const file = event.target.files[0];
  //   if (file && file.type.startsWith("video/")) {
  //     setVideoFile(URL.createObjectURL(file));
  //     setFormData((prev) => ({ ...prev, media: file }));
  //   } else {
  //     handleError('Please upload a valid video file.');
      
  //   }
  // };

  // const handleLogoUpload = (event) => {
  //   const file = event.target.files[0];
  //   if (file && file.type.startsWith("image/")) {
  //     setLogoFile(URL.createObjectURL(file));
  //     setFormData((prev) => ({ ...prev, campaignLogo: file }));
  //   } else {
  //     handleError('Please upload a valid image file.')
     
  //   }
  // };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleRemoveSkill = (skillToRemove) => {
    setFormData((prevData) => ({
      ...prevData,
      tags: prevData.tags.filter((skill) => skill !== skillToRemove),
    }));
  };

  const handleSubmitCampaign = async (e) => {
    e.preventDefault();

    if (!agreementChecked) {
      handleError('Please Agree to The Terms & Conditions')
    
      return;
    }

    setLoading(true);
    try {
      const campaignData = new FormData();
      Object.keys(formData).forEach((key) => {
        if (key === "tags" && Array.isArray(formData[key])) {
          campaignData.append(key, formData[key].join(",")); // Join tags into a single string
        } else if (key === "media" && formData[key]) {
          campaignData.append(key, formData[key]); // Append file directly
        } else {
          campaignData.append(key, formData[key]); // Append other fields
        }
      });

      const response = await createCampaignApi(token, campaignData);
      if (response) {
        handleSuccess(response.message || "Campaign created successfully.")
       
        // Reset form after successful submission
        setFormData({
          title: "",
          description: "",
          targetUrl: "",
          startDate: "",
          endDate: "",
          budget: "",
          content: "",
          tags: [],
          media: null,
          campaignLogo: null,
        });
        // setVideoFile(null);
        setAgreementChecked(false);
      }
    } catch (error) {
      console.error(error.message);
      handleError(error.message || "Failed to create campaign.")
     
    } finally {
      setLoading(false);
    }
  };

  console.log("formdata", formData);

  return (
    <Box sx={{ bgcolor: "#fff", width: "100%" }}>
      <StyledContainer maxWidth="xl">
        <Box sx={{ position: "relative", width: "100%" }}>
          {/* ArrowBack in top-left corner */}
          <Box sx={{ position: "absolute", top: 16, left: 16 }}>
            <ArrowBack
              sx={{ cursor: "pointer", fontSize: "30px" }}
              onClick={() => navigate(-1)}
            />
          </Box>

          {/* Centered text */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center", // Vertical centering (optional, adjust as needed)
              alignItems: "center", // Horizontal centering
              mb: 4,
              width: "100%",
              textAlign: "center", // Ensure text aligns center within the box
              pt: 6, // Add padding-top to avoid overlap with ArrowBack
            }}
          >
            <Typography
              variant="h1"
              sx={{
                fontFamily: "satoshi",
                fontSize: { xs: "32px", md: "40px" },
                fontWeight: 600,
                lineHeight: 1.2,
                mb: 2,
              }}
            >
              Hey {userName?userName:""}, let's set up your Vacanzi Ads account
            </Typography>
            <Typography
              sx={{
                color: "text.secondary",
                mb: 2,
                fontFamily: "satoshi",
                textAlign: "center",
              }}
            >
              Answer a few simple questions to quickly set up your new account
              and create your first campaign.
            </Typography>
          </Box>
        </Box>

        <form onSubmit={handleSubmitCampaign}>
          <Grid container spacing={3} alignItems="flex-start">
            <Grid item xs={12} md={6}>
              <FormSection>
                <Typography
                  variant="h6"
                  sx={{ fontFamily: "satoshi", fontWeight: 600, mb: 2 }}
                >
                  What's your Campaign Title?
                </Typography>
                <TextField
                  fullWidth
                  name="title"
                  value={formData.title}
                  onChange={handleInputChange}
                  sx={{
                    mb: 4,
                    "& .MuiInputBase-root": { fontFamily: "satoshi" },
                  }}
                  required
                />

                <Typography
                  variant="h6"
                  sx={{ fontFamily: "satoshi", fontWeight: 600, mb: 2 }}
                >
                  Describe About The Campaign
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ mb: 1, fontFamily: "poppins" }}
                >
                  Provide a brief overview of your campaign's goals and
                  objectives.
                </Typography>
                <TextareaAutosize
                  minRows={1.8}
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  style={{
                    width: "100%",
                    padding: "8px",
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "14px",
                    marginBottom: "32px",
                    borderRadius: "4px",
                    resize: "vertical",
                    backgroundColor: "#fff",
                  }}
                  required
                />

                <Typography
                  variant="h6"
                  sx={{ fontFamily: "satoshi", fontWeight: 600, mb: 2 }}
                >
                  What's your Website URL?
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ mb: 1, fontFamily: "poppins" }}
                >
                  Enter your website URL to direct visitors to your site.
                </Typography>
                <TextField
                  fullWidth
                  name="targetUrl"
                  value={formData.targetUrl}
                  onChange={handleInputChange}
                  sx={{
                    mb: 4,
                    "& .MuiInputBase-root": { fontFamily: "satoshi" },
                  }}
                  required
                />

                <Typography
                  variant="h6"
                  sx={{ fontFamily: "satoshi", fontWeight: 600, mb: 2 }}
                >
                  Schedule The Campaign
                </Typography>
                <Box
                  sx={{ display: "flex", gap: 2, alignItems: "center", mb: 5 }}
                >
                  <Box sx={{ flex: 1 }}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "satoshi",
                        fontWeight: 600,
                        mb: 1,
                        fontSize: "16px",
                      }}
                    >
                      Start Date
                    </Typography>
                    <TextField
                      type="date"
                      name="startDate"
                      value={formData.startDate}
                      onChange={handleInputChange}
                      variant="outlined"
                      fullWidth
                      sx={{ "& .MuiInputBase-root": { fontFamily: "satoshi" } }}
                      required
                    />
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography
                      sx={{
                        fontFamily: "satoshi",
                        fontWeight: 600,
                        mb: 1,
                        fontSize: "16px",
                      }}
                    >
                      End Date
                    </Typography>
                    <TextField
                      type="date"
                      name="endDate"
                      value={formData.endDate}
                      onChange={handleInputChange}
                      variant="outlined"
                      fullWidth
                      sx={{ "& .MuiInputBase-root": { fontFamily: "satoshi" } }}
                      required
                    />
                  </Box>
                </Box>
         
              </FormSection>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormSection>
              <Typography
  variant="h6"
  sx={{ fontFamily: "satoshi", fontWeight: 600, mb: 2 }}
>
  Budget
</Typography>
<TextField
  fullWidth
  name="budget"
  value={formData.budget}
  onChange={handleInputChange}
  sx={{ mb: 4, fontFamily: "satoshi" }}
  required
  type="number" // Ensures only numerical input
  placeholder="Enter Budget"
/>


                <Typography
                  variant="h6"
                  sx={{ mb: 2, fontFamily: "poppins", fontWeight: 600 }}
                >
                  Campaign Content
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ mb: 1, fontFamily: "poppins" }}
                >
                  Create engaging content to attract your target audience.
                </Typography>
                <TextareaAutosize
                  minRows={1.8}
                  name="content"
                  value={formData.content}
                  onChange={handleInputChange}
                  style={{
                    width: "100%",
                    padding: "8px",
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "14px",
                    marginBottom: "32px",
                    borderRadius: "4px",
                    resize: "vertical",
                    backgroundColor: "#fff",
                  }}
                  required
                />

                <Typography
                  variant="h6"
                  sx={{ mb: 2, fontFamily: "poppins", fontWeight: 600 }}
                >
                  Tags
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ mb: 1, fontFamily: "poppins" }}
                >
                  Add relevant tags to improve visibility and reach the right
                  audience.
                </Typography>
                <Box sx={{ mb: 1 }}>
                  <Autocomplete
                    multiple
                    options={tags} // Use the transformed tags array
                    getOptionLabel={(option) => option.label} // Display the label in the dropdown
                    value={formData.tags.map((tag) => ({
                      label: tag.charAt(0).toUpperCase() + tag.slice(1),
                      value: tag,
                    }))} // Map formData.tags to match options format
                    onChange={(event, newValue) => {
                      setFormData((prevData) => ({
                        ...prevData,
                        tags: newValue.map((item) =>
                          typeof item === "string" ? item : item.value
                        ), // Handle both freeSolo strings and selected options
                      }));
                    }}
                    ListboxProps={{
                      style: {
                        maxHeight: "200px",
                        fontFamily: "Satoshi",
                        overflowY: "auto",
                        scrollbarWidth: "none",
                        msOverflowStyle: "none",
                        "&::-webkit-scrollbar": {
                          display: "none",
                        },
                      },
                    }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          key={option.value || option} // Use value if object, otherwise string
                          label={option.label || option} // Use label if object, otherwise string
                          {...getTagProps({ index })}
                          onDelete={() =>
                            handleRemoveSkill(option.value || option)
                          }
                          sx={{
                            fontFamily: "satoshi, sans-serif",
                            margin: 0.5,
                            borderRadius: "0.7rem",
                            backgroundColor: "#000",
                            color: "#fff",
                            "& .MuiChip-deleteIcon": {
                              color: "#fff",
                              "&:hover": { color: "#fff" },
                            },
                          }}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        fullWidth
                        size="small"
                        placeholder="Add tags"
                        sx={{ fontFamily: "satoshi" }}
                        inputProps={{
                          ...params.inputProps,
                          style: {
                            fontSize: 16,
                            fontFamily: "satoshi",
                            minHeight: "40px",
                          },
                        }}
                      />
                    )}
                  />
                </Box>
       

                {/* <Box
                  sx={{
                    display: "flex",
                    gap: 2,
                    alignItems: "center",
                    flexDirection: "column",
                    p: 2,
                    borderRadius: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: 2,
                      width: "100%",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <VideoPreviewBox>
                        {videoFile ? (
                          <video src={videoFile} autoPlay />
                        ) : (
                          <Box className="placeholder">
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              sx={{ fontFamily: "satoshi" }}
                            >
                              No video uploaded yet
                            </Typography>
                          </Box>
                        )}
                      </VideoPreviewBox>
                      <LogoPreviewBox>
                        {logoFile ? (
                          <img src={logoFile} alt="Campaign Logo" />
                        ) : (
                          <Box className="placeholder">
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              sx={{ fontFamily: "satoshi" }}
                            >
                              No logo
                            </Typography>
                          </Box>
                        )}
                      </LogoPreviewBox>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                        px: 10,
                      }}
                    >
                      <Button
                        variant="contained"
                        component="label"
                        sx={{
                          fontFamily: "satoshi",
                          backgroundColor: "#000",
                          textTransform: "none",
                          py: 1,
                          ml: { xl: 7, lg: 5 },
                          ":hover": {
                            backgroundColor: "#202020FF",
                          },
                        }}
                      >
                        {videoFile ? "Replace Video" : "Upload Video"}
                        <input
                          type="file"
                          accept="video/*"
                          hidden
                          onChange={handleVideoUpload}
                        />
                      </Button>
                      <Button
                        variant="contained"
                        component="label"
                        sx={{
                          fontFamily: "satoshi",
                          backgroundColor: "#000",
                          textTransform: "none",
                          py: 1,
                          ":hover": {
                            backgroundColor: "#202020FF",
                          },
                        }}
                      >
                        {logoFile ? "Replace Logo" : "Upload Logo"}
                        <input
                          type="file"
                          accept="image/*"
                          hidden
                          onChange={handleLogoUpload}
                        />
                      </Button>
                    </Box>
                  </Box>
                </Box> */}
                <JobSeekerLetBuildForm/>
                
              </FormSection>
            </Grid>
          </Grid>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 2,
              mt:-10,
              mb: 8,
            }}
          >

            <Button
              type="submit"
              variant="contained"
           
              sx={{
                fontFamily: "satoshi",
                fontSize: "16px",
                backgroundColor: "#000",
                width: { xs: "100%", sm: "80%", md: "50%" },
                borderRadius: "0.7rem",
                textTransform: "none",
                py: 1.5,
                ":hover": { backgroundColor: "#3B3B3BFF" },
              }}
            >
              {loading ? (
                <ThreeDot color="#fff" size="small" text="" textColor="" />
              ) : (
                "Request Compaign"
              )}
            </Button>
          </Box>
        </form>

      
      </StyledContainer>
      <PopupSuccess
        modelOpen={successOpen}
        onClose={() => setSuccessOpen(false)}
        message={successMessage}
      />
      <ReleaseJobPostModel
        modelOpen={errorOpen}
        onClose={() => setErrorOpen(false)}
        message={errorMessage}
      />
    </Box>
  );
};

export default MarketPage;
