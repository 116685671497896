import React from "react";
import {
  Modal,
  Box,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";



// Data for the modal from an employer perspective
const employerVideoBenefits = [
  {
    section: "Why Upload an Office Culture Video?",
    items: [
      {
        label: "Stronger Employer Branding",
        content: "Showcase your workplace, team, and culture to attract top talent.",
      },
      {
        label: "Inspire the Right Candidates",
        content: "Help job seekers visualize themselves in your company, drawing those who align with your values.",
      },
      {
        label: "Build Trust & Connection",
        content: "Make your company feel more relatable, increasing candidate confidence before applying.",
      },
    ],
  },
  {
    section: "Why Upload a Job Posting Video?",
    items: [
      {
        label: "Set Clear Expectations",
        content: "Explain the role, responsibilities, and growth potential beyond a text job post.",
      },
      {
        label: "Attract the Right Talent",
        content: "Pre-qualify candidates by giving real insights, reducing irrelevant applications.",
      },
      {
        label: "Stand Out & Engage",
        content: "A video job post is more memorable, increasing quality applications.",
      },
    ],
  },
];

// Reusable Modal Component
const EmployerVacanziPopup = ({ open, onClose }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="employer-video-benefits-modal"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backdropFilter: "blur(5px)", // Adds a subtle blur to the background
      }}
    >
      <Box
        sx={{
          bgcolor: "white",
          borderRadius: "20px",
          p: 4,
          width: "90%",
          maxWidth: "600px",
          maxHeight: "80vh",
          position: "relative",
          boxShadow: "0 8px 32px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* Close Button */}
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: 12,
            right: 12,
            bgcolor: "rgba(0, 0, 0, 0.05)",
            "&:hover": { bgcolor: "rgba(0, 0, 0, 0.1)" },
          }}
        >
          <CloseIcon sx={{ fontSize: "28px", color: "#333" }} />
        </IconButton>

        {/* Logo */}
        <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
          <img src= "60seconds.png" height="12%" width="20%" alt="logo" />
        </Box>

        {/* Modal Header */}
        <Typography
          id="employer-video-benefits-modal"
          sx={{
            fontSize: "28px",
            fontFamily: "IBM Plex Sans",
            fontWeight: "bold",
            textAlign: "center",
            mb: 3,
            color: "#1a1a1a",
          }}
        >
          Why Use Video on Vacanzi as an Employer? 🎥
        </Typography>

        {/* Scrollable Benefits List */}
        <Box
          sx={{
            maxHeight: "50vh", // Limits the height of the scrollable area
            overflowY: "auto", // Enables scrolling
            scrollbarWidth: "thin",
            scrollbarColor: "rgba(0, 0, 0, 0.3) transparent",
            "&::-webkit-scrollbar": {
              width: "6px",
            },
            "&::-webkit-scrollbar-track": {
              background: "transparent",
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "rgba(0, 0, 0, 0.3)",
              borderRadius: "10px",
              "&:hover": {
                background: "rgba(0, 0, 0, 0.5)",
              },
            },
          }}
        >
          {employerVideoBenefits.map((section, sectionIndex) => (
            <Box key={sectionIndex} sx={{ mb: 3 }}>
              {/* Section Header */}
              <Typography
                sx={{
                  fontSize: "22px",
                  fontFamily: "IBM Plex Sans",
                  fontWeight: "bold",
                  color: "#1976d2", // Blue to distinguish sections
                  mb: 2,
                }}
              >
                {section.section}
              </Typography>
              {/* Section Items */}
              <List sx={{ pt: 0 }}>
                {section.items.map((benefit, itemIndex) => (
                  <ListItem
                    key={itemIndex}
                    sx={{
                      alignItems: "flex-start",
                      py: 1,
                      "&:hover": {
                        bgcolor: "rgba(25, 118, 210, 0.05)",
                        borderRadius: "8px",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "24px",
                        fontFamily: "IBM Plex Sans",
                        fontWeight: "bold",
                        color: "#000",
                        mr: 2,
                        minWidth: "24px",
                      }}
                    >
                      {itemIndex + 1}
                    </Typography>
                    <ListItemText
                      primary={
                        <Typography
                          sx={{
                            fontSize: "20px",
                            fontFamily: "IBM Plex Sans",
                            fontWeight: "bold",
                            color: "#333",
                          }}
                        >
                          {benefit.label}
                        </Typography>
                      }
                      secondary={
                        <Typography
                          sx={{
                            fontSize: "18px",
                            fontFamily: "IBM Plex Sans",
                            color: "text.secondary",
                            mt: 0.3,
                          }}
                        >
                          {benefit.content}
                        </Typography>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          ))}
        </Box>
      </Box>
    </Modal>
  );
};

export default EmployerVacanziPopup;