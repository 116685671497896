import React, { useEffect, useState } from "react";
// import NavBar from "../../../components/JobSeekerComponents/NavBar/NavBar";
import { Button, Typography } from "@mui/material";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { Box, styled } from "@mui/system";
import { useParams } from "react-router-dom";
// import { CommitSharp, Token } from "@mui/icons-material";
import { getInvoiceByIdApi } from "../../../api/subscriptions";

const StyledTypo = styled(Typography)(({ theme }) => ({
  fontFamily: "poppins",
}));

const EmployerInvoice = () => {
  const [invoiceData, setInvoiceData] = useState();
  const token = localStorage.getItem("token");
  const { invoiceId } = useParams();
  useEffect(() => {
    const getInvoiceData = async () => {
      try {
        const response = await getInvoiceByIdApi(invoiceId, token);
        setInvoiceData(response);
      } catch (error) {
        console.log(error.message);
      }
    };
    getInvoiceData();
  }, [token, invoiceId]);

  function numberToWords(num) {
    const units = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
    ];
    const teens = [
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];
    const tens = [
      "",
      "Ten",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];

    if (num === 0) return "Zero"; // Handle zero case

    // Extract the integer part of the number
    num = Math.floor(num);

    function convertChunk(chunk) {
      let words = "";
      if (chunk >= 100) {
        words += units[Math.floor(chunk / 100)] + " Hundred ";
        chunk %= 100;
      }
      if (chunk >= 20) {
        words += tens[Math.floor(chunk / 10)] + " ";
        chunk %= 10;
      } else if (chunk >= 10) {
        words += teens[chunk - 10] + " ";
        chunk = 0;
      }
      if (chunk > 0 && chunk < 10) {
        words += units[chunk] + " ";
      }
      return words.trim();
    }

    let result = "";
    if (num >= 1e6) {
      result += convertChunk(Math.floor(num / 1e6)) + " Million ";
      num %= 1e6;
    }
    if (num >= 1e3) {
      result += convertChunk(Math.floor(num / 1e3)) + " Thousand ";
      num %= 1e3;
    }
    if (num > 0) {
      result += convertChunk(num);
    }

    return result.trim();
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: "numeric", // Full year (e.g., "2024")
      month: "numeric", // Full month name (e.g., "December")
      day: "numeric", // Numeric day (e.g., "20")
    };
    return date.toLocaleDateString("en-US", options);
  };

  const downloadInvoice = async () => {
    const invoiceElement = document.getElementById("invoice");

    // Render HTML content to canvas
    const canvas = await html2canvas(invoiceElement, { scale: 2 });
    const imgData = canvas.toDataURL("image/png");

    // Create jsPDF instance
    const pdf = new jsPDF("p", "mm", "a4");
    const pdfWidth = 210; // A4 width in mm
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width; // Maintain aspect ratio

    // Calculate how many pages we need
    let yPosition = 0;
    const pageHeight = 297; // A4 page height in mm

    // If the content exceeds the page height, split it into pages
    while (yPosition < pdfHeight) {
      // Add the current portion of the image to the PDF
      pdf.addImage(imgData, "PNG", 0, -yPosition, pdfWidth, pdfHeight);

      // Move the yPosition for the next page
      yPosition += pageHeight;

      // Add a new page if there's more content
      if (yPosition < pdfHeight) {
        pdf.addPage();
      }
    }

    // Save the PDF
    pdf.save("Invoice.pdf");
  };
  console.log("invoicedata", invoiceData);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {/* <NavBar /> */}

      <Button
        onClick={downloadInvoice}
        style={{
          backgroundColor: "#000",
          borderRadius: "0.7rem",
          fontFamily: "poppins",
          textTransform: "none",
          marginTop: "20px",
        }}
        variant="contained"
      >
        Download Invoice
      </Button>

      {/* Invoice content */}
      <div
        id="invoice"
        style={{ width: "800px", padding: "20px", backgroundColor: "white" }}
      >
        <div>
          <table
            cellSpacing="0"
            cellPadding="0"
            style={{ marginLeft: "5.85pt", borderCollapse: "col	lapse" }}
          >
            <tr>
              <td
                colSpan="7"
                style={{
                  width: "800.65pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "0.75pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "0.75pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "0.75pt",
                  verticalAlign: "top",
                }}
              ></td>
            </tr>
            <tr style={{ height: "50.3pt" }}>
              <td
                colSpan="7"
                style={{
                  width: "554.65pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "0.75pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "0.75pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center	",
                    flexDirection: "column",
                    mt: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      style={{ height: 50, width: 50 }}
                      src="/vacanziLogo.png"
                      alt="Vacanzi Logo"
                    />
                    <Typography
                      variant="h4"
                      noWrap
                      component="a"
                      href="/search-candidates"
                      sx={{
                        display: { xs: "none", md: "flex" },
                        fontFamily: "GlanceSansBold",
                        color: "#423BFA",
                        textDecoration: "none",
                        ml: 2,
                      }}
                    >
                      Vacanzi
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <StyledTypo
                      sx={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        fontFamily: "poppins",
                      }}
                    >
                      Tax Invoice
                    </StyledTypo>
                    <StyledTypo
                      sx={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        fontFamily: "poppins",
                      }}
                    >
                      ORIGINAL FOR RECIPIENT
                    </StyledTypo>
                  </Box>
                </Box>
              </td>
            </tr>

            <tr style={{ height: "12.2pt" }}>
              <td
                colSpan="2"
                style={{
                  color: "#fff",
                  width: "186.38pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "0.75pt",
                  verticalAlign: "top",
                  backgroundColor: "#423bfa",
                }}
              >
                <StyledTypo
                  sx={{
                    fontSize: "13px",
                    fontWeight: "bold",
                    textAlign: "center",
                    fontFamily: "poppins",
                  }}
                >
                  Customer Details
                </StyledTypo>
              </td>
              <td
                colSpan="2"
                style={{
                  color: "#fff",
                  width: "147.75pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  verticalAlign: "top",
                  backgroundColor: "#423bfa",
                }}
              >
                <StyledTypo
                  sx={{
                    fontSize: "13px",
                    fontWeight: "bold",
                    textAlign: "center",
                    fontFamily: "poppins",
                  }}
                >
                  Invoice
                </StyledTypo>
              </td>
              <td
                colSpan="3"
                style={{
                  color: "#fff",
                  width: "2pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "0.75pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  verticalAlign: "top",
                  backgroundColor: "#423bfa",
                }}
              >
                <StyledTypo
                  sx={{
                    fontSize: "13px",
                    fontWeight: "bold",
                    textAlign: "center",
                    fontFamily: "poppins",
                  }}
                >
                  Billing /Supplier Information
                </StyledTypo>
              </td>
            </tr>
            <tr style={{ height: "133.35pt" }}>
              <td
                colSpan="2"
                style={{
                  width: "186.38pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "0.75pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "0.75pt",
                  verticalAlign: "top",
                }}
              >
                <Box sx={{ marginLeft: 2 }}>
                  <StyledTypo
                    sx={{
                      fontSize: "12px",
                      color: "#20CA09FF",
                      fontFamily: "poppins",
                    }}
                  >
                    Bill-to/Sell-to Customer
                  </StyledTypo>

                  <StyledTypo sx={{ fontSize: "11px", fontFamily: "poppins" }}>
                    Legal Name:
                    {invoiceData?.invoice?.organizationId?.organizationName}
                  </StyledTypo>
                  <StyledTypo sx={{ fontSize: "11px", fontFamily: "poppins" }}>
                    Trade Name:
                    {invoiceData?.invoice?.organizationId?.organizationName}
                  </StyledTypo>

                  <StyledTypo sx={{ fontSize: "11px", fontFamily: "poppins" }}>
                    Address: 10/1,VK Plaza,BANGALORE-560043,India
                  </StyledTypo>

                  <StyledTypo sx={{ fontSize: "11px", fontFamily: "poppins" }}>
                    Receipient place : BANGALORE
                  </StyledTypo>

                  <StyledTypo sx={{ fontSize: "11px", fontFamily: "poppins" }}>
                    Place of Supply:{" "}
                    {invoiceData?.invoice?.organizationId?.gstNumber
                      ? invoiceData?.invoice?.organizationId?.supplyCode
                      : "NA"}
                  </StyledTypo>
                  <StyledTypo sx={{ fontSize: "11px", fontFamily: "poppins" }}>
                    Receipient State code -
                    {invoiceData?.invoice?.organizationId?.gstNumber
                      ? invoiceData?.invoice?.organizationId?.stateCode
                      : "NA"}
                  </StyledTypo>
                  <StyledTypo sx={{ fontSize: "11px", fontFamily: "poppins" }}>
                    PAN No:
                    {invoiceData?.invoice?.organizationId?.gstNumber
                      ? invoiceData?.invoice?.organizationId?.panNumber
                      : "NA"}
                  </StyledTypo>
                  <StyledTypo sx={{ fontSize: "11px", fontFamily: "poppins" }}>
                    GSTIN:{" "}
                    {invoiceData?.invoice?.organizationId?.gstNumber
                      ? invoiceData?.invoice?.organizationId?.gstNumber
                      : "NA"}
                  </StyledTypo>
                </Box>
              </td>
              <td
                style={{
                  width: "147.75pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "0.75pt",
                  verticalAlign: "top",
                }}
              >
                <Box sx={{ marginLeft: 2 }}>
                  <StyledTypo sx={{ fontSize: "11px" }}>
                    Document No : {invoiceData?.invoice?.invoiceId}
                  </StyledTypo>
                  <StyledTypo sx={{ fontSize: "11px" }}>
                    Supply Type : B2B
                  </StyledTypo>
                  <StyledTypo sx={{ fontSize: "11px" }}>
                    Document Type : INV
                  </StyledTypo>
                </Box>
              </td>
              <td
                colSpan={4}
                style={{
                  width: "218.52pt",
                  borderRight: "0.75pt solid",
                  borderLeft: "1pt solid",
                  borderBottom: "0.75pt solid",
                  verticalAlign: "top",
                }}
              >
                <Box sx={{ marginLeft: 2 }}>
                  <StyledTypo sx={{ fontSize: "11px" }}>
                    VK Plaza, Ramamurthynagar Main Road, Dodda Bansawadi,
                    Bengaluru - 560043
                  </StyledTypo>
                  <StyledTypo sx={{ fontSize: "11px" }}>India</StyledTypo>
                  <StyledTypo sx={{ fontSize: "11px" }}>
                    Supplier Place : Bengaluru
                  </StyledTypo>
                  <StyledTypo sx={{ fontSize: "11px" }}>
                    Supplier State Code : 29
                  </StyledTypo>
                  <StyledTypo sx={{ fontSize: "11px" }}>
                    PAN No: ABJCS3745D
                  </StyledTypo>
                  <StyledTypo sx={{ fontSize: "11px" }}>
                    GSTN No : 29ABJCS3745D1Z5
                  </StyledTypo>
                  <StyledTypo sx={{ fontSize: "11px" }}>
                    CIN No : U72900KA2022PTC166004
                  </StyledTypo>
                </Box>
              </td>
            </tr>

            <tr />

            <tr style={{ height: "19pt" }}>
              <td
                style={{
           
                  width: "21.12pt",
                  borderStyle: "solid",
                  borderWidth: "0.75pt 1pt 1pt 0.75pt",
                  verticalAlign: "top",
                  backgroundColor: "#423bfa",
                }}
              >
                <StyledTypo sx={{ fontSize: "13px", fontWeight: "bold" ,color:"#fff"}}>
                  Service Description
                </StyledTypo>
              </td>
              <td
                colSpan="5"
                style={{
                  width: "466.4pt",
                  borderStyle: "solid",
                  borderWidth: "0.75pt 1pt 1pt",
                  verticalAlign: "top",
                  backgroundColor: "#423bfa",
                }}
              >
                <StyledTypo sx={{ fontSize: "13px", fontWeight: "bold" ,color:"#fff"}}>
                  Service Description
                </StyledTypo>
              </td>
              <td
                style={{
                  color: "#fff",
                  width: "65.12pt",
                  borderStyle: "solid",
                  borderWidth: "0.75pt 0.75pt 1pt 1pt",
                  verticalAlign: "top",
                  backgroundColor: "#423bfa",
                }}
              >
                <StyledTypo sx={{ fontSize: "13px", fontWeight: "bold" }}>
                  Rate
                </StyledTypo>
              </td>
            </tr>
            <tr style={{ height: "11.55pt" }}>
              <td
                style={{
                  width: "21.12pt",
                  borderStyle: "solid",
                  borderWidth: "1pt 1pt 1pt 0.75pt",
                  verticalAlign: "top",
                }}
              >
                <StyledTypo sx={{ fontSize: "11px" }}>1</StyledTypo>
              </td>
              <td
                colSpan="5"
                style={{
                  width: "466.4pt",
                  borderStyle: "solid",
                  borderWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <StyledTypo sx={{ fontSize: "11px" }}>
                 {invoiceData?.invoice?.subType === "jobposting" ? "Job Posting " :" DataBase"} Subscription upgraded
                </StyledTypo>
              </td>
              <td
                style={{
                  width: "65.12pt",
                  borderStyle: "solid",
                  borderWidth: "1pt 0.75pt 1pt 1pt",
                  verticalAlign: "top",
                }}
              > 
               <StyledTypo sx={{ fontSize: "11px", textAlign: "center" }}>
                  {invoiceData?.invoice?.costDetails?.amountWithoutGst.toFixed(
                    2
                  )}
                </StyledTypo>
              </td>
            </tr>

            <tr style={{ height: "10.5pt" }}>
              <td
                colSpan="4"
                rowSpan="8"
                style={{
                  width: "397.65pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  verticalAlign: "top",
                  marginTop: "50px",
                }}
              ></td>
              <td
                colSpan="2"
                style={{
                  width: "90.25pt",
                  borderStyle: "solid",
                  borderWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <StyledTypo sx={{ fontSize: "11px", textAlign: "center" }}>
                  Total Taxable Value
                </StyledTypo>
              </td>
              <td
                style={{
                  width: "65.12pt",
                  borderStyle: "solid",
                  borderWidth: "1pt 0.75pt 1pt 1pt",
                  verticalAlign: "top",
                }}
              >
                <StyledTypo sx={{ fontSize: "11px", textAlign: "center" }}>
                  {invoiceData?.invoice?.costDetails?.amountWithoutGst.toFixed(
                    2
                  )}
                </StyledTypo>
              </td>
            </tr>

            <tr style={{ height: "9pt" }}>
              <td
                colSpan="2"
                style={{
                  width: "90.25pt",
                  borderStyle: "solid",
                  borderWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <StyledTypo sx={{ fontSize: "11px", textAlign: "center" }}>
                  IGST
                </StyledTypo>
              </td>
              <td
                style={{
                  width: "65.12pt",
                  borderStyle: "solid",
                  borderWidth: "1pt 0.75pt 1pt 1pt",
                  verticalAlign: "top",
                }}
              >
                <StyledTypo sx={{ fontSize: "11px", textAlign: "center" }}>
                  {invoiceData?.invoice?.costDetails?.igstAmount || 0}
                </StyledTypo>
              </td>
            </tr>

            <tr style={{ height: "10pt" }}>
              <td
                colSpan="2"
                style={{
                  width: "90.25pt",
                  borderStyle: "solid",
                  borderWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <StyledTypo sx={{ fontSize: "11px", textAlign: "center" }}>
                  CGST
                </StyledTypo>
              </td>
              <td
                style={{
                  width: "65.12pt",
                  borderStyle: "solid",
                  borderWidth: "1pt 0.75pt 1pt 1pt",
                  verticalAlign: "top",
                }}
              >
                <StyledTypo sx={{ fontSize: "11px", textAlign: "center" }}>
                  {invoiceData?.invoice?.costDetails?.cgstAmount.toFixed(2)}
                </StyledTypo>
              </td>
            </tr>

            <tr style={{ height: "10pt" }}>
              <td
                colSpan="2"
                style={{
                  width: "90.25pt",
                  borderStyle: "solid",
                  borderWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <StyledTypo sx={{ fontSize: "11px", textAlign: "center" }}>
                  SGST
                </StyledTypo>
              </td>
              <td
                style={{
                  width: "65.12pt",
                  borderStyle: "solid",
                  borderWidth: "1pt 0.75pt 1pt 1pt",
                  verticalAlign: "top",
                }}
              >
                <StyledTypo sx={{ fontSize: "11px", textAlign: "center" }}>
                  {invoiceData?.invoice?.costDetails?.sgstAmount.toFixed(2)}
                </StyledTypo>
              </td>
            </tr>
            <tr style={{ height: "17.4pt" }}>
              <td
                colSpan="2"
                style={{
                  width: "90.25pt",
                  borderStyle: "solid",
                  borderWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <StyledTypo sx={{ fontSize: "11px", textAlign: "center" }}>
                  Net Amount/Total Invoice Value
                </StyledTypo>
              </td>
              <td
                style={{
                  width: "65.12pt",
                  borderStyle: "solid",
                  borderWidth: "1pt 0.75pt 1pt 1pt",
                  verticalAlign: "top",
                }}
              >
                <StyledTypo sx={{ fontSize: "11px", textAlign: "center" }}>
                  {invoiceData?.invoice?.costDetails?.originalAmount}
                </StyledTypo>
              </td>
            </tr>

            {
              invoiceData?.invoice?.costDetails?.discountApplied > 0 && (
                <tr style={{ height: "17.4pt" }}>
                  <td
                    colSpan="2"
                    style={{
                      width: "90.25pt",
                      borderStyle: "solid",
                      borderWidth: "1pt",
                      verticalAlign: "top",
                    }}
                  >
                    <StyledTypo sx={{ fontSize: "11px", textAlign: "center" }}>
                      Discount Applied <br />(
                      <span style={{ fontWeight: "bold" }}>
                        {invoiceData?.invoice?.promoCode}
                      </span>
                      )
                    </StyledTypo>
                  </td>
                  <td
                    style={{
                      width: "65.12pt",
                      borderStyle: "solid",
                      borderWidth: "1pt 0.75pt 1pt 1pt",
                      verticalAlign: "top",
                    }}
                  >
                    <StyledTypo sx={{ fontSize: "11px", textAlign: "center" }}>
                      {invoiceData?.invoice?.costDetails?.discountApplied}
                    </StyledTypo>
                  </td>
                </tr>
              )}

            <tr style={{ height: "10.25pt" }}>
              <td
                colSpan="2"
                style={{
                  width: "90.25pt",
                  borderStyle: "solid",
                  borderWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <StyledTypo sx={{ fontSize: "11px", textAlign: "center" }}>
                  Payable Amount (INR)
                </StyledTypo>
              </td>
              <td
                style={{
                  width: "65.12pt",
                  borderStyle: "solid",
                  borderWidth: "1pt 0.75pt 1pt 1pt",
                  verticalAlign: "top",
                }}
              >
                <StyledTypo sx={{ fontSize: "11px", textAlign: "center" }}>
                  {invoiceData?.invoice?.costDetails?.amount}
                </StyledTypo>
              </td>
            </tr>
          </table>
          <Box>
            <StyledTypo
              sx={{
                fontSize: "11px",
                textAlign: "start",
                marginLeft: 1,
                marginTop: "-100px",
              }}
            >
              * Total In Words ****{" "}
              {numberToWords(invoiceData?.invoice?.amount)} Rupees Only Whether
              Tax is payable <br />
              on reverse charge : No.
            </StyledTypo>
          </Box>
          <Box marginTop={2} marginBottom={2}>
            <StyledTypo
              sx={{ fontSize: "11px", textAlign: "start", marginLeft: 1 }}
            >
              * This invoice is recognised subject to realization of Payment.
            </StyledTypo>
            <StyledTypo
              sx={{ fontSize: "11px", textAlign: "start", marginLeft: 1 }}
            >
              * Refer Terms and Conditions at{" "}
              <a href="www.vacanzi.com" style={{ color: "blue" }}>
                vacanzi.com
              </a>
            </StyledTypo>
            <StyledTypo
              sx={{ fontSize: "11px", textAlign: "start", marginLeft: 1 }}
            >
              * All disputes subject to Bangalore Jurisdiction only.
            </StyledTypo>
          </Box>
        </div>

        {/* payment details */}

        <div style={{ marginBottom: "40px" }}>
          <table
            cellSpacing="0"
            cellPadding="0"
            style={{ marginLeft: "5.85pt", borderCollapse: "col	lapse" }}
          >
            <tr>
              <td
                colSpan="6"
                style={{
                  width: "800.65pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "0.75pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "0.75pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "0.75pt",
                  verticalAlign: "top",
                }}
              ></td>
            </tr>
            <tr style={{ height: "20.3pt" }}>
              <td
                colSpan="6"
                style={{
                  width: "554.65pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "0.75pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "0.75pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <StyledTypo
                    sx={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      textAlign: "bottom",
                    }}
                  >
                    Payment Details
                  </StyledTypo>
                </Box>
              </td>
            </tr>

            <tr style={{ height: "19pt" }}>
              <td
                style={{
                  borderStyle: "solid",
                  borderWidth: "0.75pt 1pt 1pt 0.75pt",
                  verticalAlign: "top",
                  backgroundColor: "#423bfa",
                }}
              >
                <StyledTypo sx={{ fontSize: "13px", fontWeight: "bold",color:"#fff" }}>
                  S.No
                </StyledTypo>
              </td>
              <td
                style={{
                  borderStyle: "solid",
                  borderWidth: "0.75pt 1pt 1pt",
                  verticalAlign: "top",
                  backgroundColor: "#423bfa",
                }}
              >
                <StyledTypo
                  sx={{
                    fontSize: "13px",
                    fontWeight: "bold",
                    textAlign: "center",
                    color: "#fff",
                  }}
                >
                  Transaction Id
                </StyledTypo>
              </td>
              <td
                style={{
                  borderStyle: "solid",
                  borderWidth: "0.75pt 0.75pt 1pt 1pt",
                  verticalAlign: "top",
                  backgroundColor: "#423bfa",
                }}
              >
                <StyledTypo
                  sx={{
                    fontSize: "13px",
                    fontWeight: "bold",
                    textAlign: "center",
                    color: "#fff",
                  }}
                >
                  Payment Mode
                </StyledTypo>
              </td>
              <td
                style={{
                  borderStyle: "solid",
                  borderWidth: "0.75pt 0.75pt 1pt 1pt",
                  verticalAlign: "top",
                  backgroundColor: "#423bfa",
                }}
              >
                <StyledTypo
                  sx={{
                    fontSize: "13px",
                    fontWeight: "bold",
                    textAlign: "center",
                    color: "#fff",
                  }}
                >
                  Payment Date
                </StyledTypo>
              </td>
              <td
                style={{
                  borderStyle: "solid",
                  borderWidth: "0.75pt 0.75pt 1pt 1pt",
                  verticalAlign: "top",
                  backgroundColor: "#423bfa",
                }}
              >
                <StyledTypo
                  sx={{
                    fontSize: "13px",
                    fontWeight: "bold",
                    textAlign: "center",
                    color: "#fff",
                  }}
                >
                  Amount
                </StyledTypo>
              </td>
            </tr>

            <tr style={{ height: "11.55pt" }}>
              <td
                style={{
                  borderStyle: "solid",
                  borderWidth: "1pt 1pt 1pt 0.75pt",
                  verticalAlign: "top",
                }}
              >
                <StyledTypo sx={{ fontSize: "11px", textAlign: "center" }}>
                  1
                </StyledTypo>
              </td>
              <td
                style={{
                  borderStyle: "solid",
                  borderWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <StyledTypo sx={{ fontSize: "11px", textAlign: "center" }}>
                  {invoiceData?.invoice?.transactionDetails?.transactionId}
                </StyledTypo>
              </td>
              <td
                style={{
                  borderStyle: "solid",
                  borderWidth: "1pt 0.75pt 1pt 1pt",
                  verticalAlign: "top",
                }}
              >
                <StyledTypo sx={{ fontSize: "11px", textAlign: "center" }}>
                  Wallet
                </StyledTypo>
              </td>
              <td
                style={{
                  borderStyle: "solid",
                  borderWidth: "1pt 0.75pt 1pt 1pt",
                  verticalAlign: "top",
                }}
              >
                <StyledTypo sx={{ fontSize: "11px", textAlign: "center" }}>
                  {formatDate(invoiceData?.invoice?.transactionDetails?.date)}
                </StyledTypo>
              </td>
              <td
                style={{
                  borderStyle: "solid",
                  borderWidth: "1pt 0.75pt 1pt 1pt",
                  verticalAlign: "top",
                }}
              >
                <StyledTypo sx={{ fontSize: "11px", textAlign: "center" }}>
                  {invoiceData?.invoice?.amount}
                </StyledTypo>
              </td>
            </tr>
          </table>
        </div>

        {/* anexure part */}

        <div>
          <table
            cellSpacing="0"
            cellPadding="0"
            style={{ marginLeft: "5.85pt", borderCollapse: "col	lapse" }}
          >
            <tr>
              <td
                colSpan="7"
                style={{
                  width: "800.65pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "0.75pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "0.75pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "0.75pt",
                  verticalAlign: "top",
                }}
              ></td>
            </tr>
            <tr style={{ height: "23.3pt" }}>
              <td
                colSpan="7"
                style={{
                  width: "554.65pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "0.75pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "0.75pt",
                  borderBottomStyle: "solid",
               
                  verticalAlign: "top",
                }}
              >
           
            
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >

                    <StyledTypo sx={{ fontSize: "16px", fontWeight: "bold" }}>
                      Annexure
                    </StyledTypo>
                  </Box>
              </td>
            </tr>

     

            <tr />
            <tr style={{ height: "12.85pt" }}>
              <td
                style={{
                  color: "#fff",
                  width: "28.12pt",
                  borderStyle: "solid",
                  borderWidth: "0.75pt 1pt 1pt 0.75pt",
                  verticalAlign: "top",
                  backgroundColor: "#423bfa",
                }}
              >
                <StyledTypo sx={{ fontSize: "13px", fontWeight: "bold" }}>
                  Sl.No
                </StyledTypo>
              </td>
              <td
                colSpan="3"
                style={{
                  color: "#fff",
                  width: "371.65pt",
                  borderStyle: "solid",
                  borderWidth: "0.75pt 1pt 1pt",
                  verticalAlign: "top",
                  backgroundColor: "#423bfa",
                }}
              >
                <StyledTypo
                  sx={{
                    fontSize: "13px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Description of Services
                </StyledTypo>
              </td>
              <td
                colSpan="2"
                style={{
                  width: "77.3pt",
                  color: "#fff",
                  borderStyle: "solid",
                  borderWidth: "0.75pt 1pt 1pt",
                  verticalAlign: "top",
                  backgroundColor: "#423bfa",
                }}
              >
                <StyledTypo
                  sx={{
                    fontSize: "13px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Start Date
                </StyledTypo>
              </td>
              <td
                style={{
                  width: "74.53pt",
                  color: "#fff",
                  borderStyle: "solid",
                  borderWidth: "0.75pt 0.75pt 1pt 1pt",
                  verticalAlign: "top",
                  backgroundColor: "#423bfa",
                }}
              >
                <StyledTypo
                  sx={{
                    fontSize: "13px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  End Date
                </StyledTypo>
              </td>
            </tr>
            <tr style={{ height: "13.35pt" }}>
              <td
                style={{
                  width: "28.12pt",
                  borderStyle: "solid",
                  borderWidth: "1pt 1pt 1pt 0.75pt",
                  verticalAlign: "top",
                }}
              >
                <StyledTypo
                  sx={{
                    fontSize: "11px",
                    textAlign: "center",
                  }}
                >
                  1
                </StyledTypo>
              </td>
              <td
                colSpan="3"
                style={{
                  width: "371.65pt",
                  borderStyle: "solid",
                  borderWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <StyledTypo
                  sx={{
                    fontSize: "11px",
                    textAlign: "center",
                  }}
                >
                  {invoiceData?.invoice?.subscriptionId?.cvDownloads} CV
                  Downloads
                </StyledTypo>
              </td>
              <td
                colSpan="2"
                style={{
                  width: "77.3pt",
                  borderStyle: "solid",
                  borderWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <StyledTypo
                  sx={{
                    fontSize: "11px",
                    textAlign: "center",
                  }}
                >
                  {formatDate(invoiceData?.invoice?.transactionDetails?.date)}
                </StyledTypo>
              </td>
              <td
                style={{
                  width: "74.53pt",
                  borderStyle: "solid",
                  borderWidth: "1pt 0.75pt 1pt 1pt",
                  verticalAlign: "top",
                }}
              >
                <StyledTypo
                  sx={{
                    fontSize: "11px",
                    textAlign: "center",
                  }}
                >
                  {formatDate(invoiceData?.invoice?.validUntil)}
                </StyledTypo>
              </td>
            </tr>

            <tr style={{ height: "13.35pt" }}>
              <td
                style={{
                  width: "28.12pt",
                  borderStyle: "solid",
                  borderWidth: "1pt 1pt 1pt 0.75pt",
                  verticalAlign: "top",
                }}
              >
                <StyledTypo
                  sx={{
                    fontSize: "11px",
                    textAlign: "center",
                  }}
                >
                  2
                </StyledTypo>
              </td>
              <td
                colSpan="3"
                style={{
                  width: "371.65pt",
                  borderStyle: "solid",
                  borderWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <StyledTypo
                  sx={{
                    fontSize: "11px",
                    textAlign: "center",
                  }}
                >
                  {invoiceData?.invoice?.subscriptionId?.invites} Invites
                </StyledTypo>
              </td>
              <td
                colSpan="2"
                style={{
                  width: "77.3pt",
                  borderStyle: "solid",
                  borderWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <StyledTypo
                  sx={{
                    fontSize: "11px",
                    textAlign: "center",
                  }}
                >
                  {formatDate(invoiceData?.invoice?.transactionDetails?.date)}
                </StyledTypo>
              </td>
              <td
                style={{
                  width: "74.53pt",
                  borderStyle: "solid",
                  borderWidth: "1pt 0.75pt 1pt 1pt",
                  verticalAlign: "top",
                }}
              >
                <StyledTypo
                  sx={{
                    fontSize: "11px",
                    textAlign: "center",
                  }}
                >
                  {formatDate(invoiceData?.invoice?.validUntil)}
                </StyledTypo>
              </td>
            </tr>
            <tr style={{ height: "13.35pt" }}>
              <td
                style={{
                  width: "28.12pt",
                  borderStyle: "solid",
                  borderWidth: "1pt 1pt 1pt 0.75pt",
                  verticalAlign: "top",
                }}
              >
                <StyledTypo
                  sx={{
                    fontSize: "11px",
                    textAlign: "center",
                  }}
                >
                  3
                </StyledTypo>
              </td>
              <td
                colSpan="3"
                style={{
                  width: "371.65pt",
                  borderStyle: "solid",
                  borderWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <StyledTypo
                  sx={{
                    fontSize: "11px",
                    textAlign: "center",
                  }}
                >
                  {invoiceData?.invoice?.subscriptionId?.jobPosts} Job Posts
                </StyledTypo>
              </td>
              <td
                colSpan="2"
                style={{
                  width: "77.3pt",
                  borderStyle: "solid",
                  borderWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <StyledTypo
                  sx={{
                    fontSize: "11px",
                    textAlign: "center",
                  }}
                >
                  {formatDate(invoiceData?.invoice?.transactionDetails?.date)}
                </StyledTypo>
              </td>
              <td
                style={{
                  width: "74.53pt",
                  borderStyle: "solid",
                  borderWidth: "1pt 0.75pt 1pt 1pt",
                  verticalAlign: "top",
                }}
              >
                <StyledTypo
                  sx={{
                    fontSize: "11px",
                    textAlign: "center",
                  }}
                >
                  {formatDate(invoiceData?.invoice?.validUntil)}
                </StyledTypo>
              </td>
            </tr>
            <tr style={{ height: "13.35pt" }}>
              <td
                style={{
                  width: "28.12pt",
                  borderStyle: "solid",
                  borderWidth: "1pt 1pt 1pt 0.75pt",
                  verticalAlign: "top",
                }}
              >
                <StyledTypo
                  sx={{
                    fontSize: "11px",
                    textAlign: "center",
                  }}
                >
                  1
                </StyledTypo>
              </td>
              <td
                colSpan="3"
                style={{
                  width: "371.65pt",
                  borderStyle: "solid",
                  borderWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <StyledTypo
                  sx={{
                    fontSize: "11px",
                    textAlign: "center",
                  }}
                >
                  {invoiceData?.invoice?.subscriptionId?.userAccess} User Access
                </StyledTypo>
              </td>
              <td
                colSpan="2"
                style={{
                  width: "77.3pt",
                  borderStyle: "solid",
                  borderWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <StyledTypo
                  sx={{
                    fontSize: "11px",
                    textAlign: "center",
                  }}
                >
                  {formatDate(invoiceData?.invoice?.transactionDetails?.date)}
                </StyledTypo>
              </td>
              <td
                style={{
                  width: "74.53pt",
                  borderStyle: "solid",
                  borderWidth: "1pt 0.75pt 1pt 1pt",
                  verticalAlign: "top",
                }}
              >
                <StyledTypo
                  sx={{
                    fontSize: "11px",
                    textAlign: "center",
                  }}
                >
                  {formatDate(invoiceData?.invoice?.validUntil)}
                </StyledTypo>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  );
};

export default EmployerInvoice;
