import { useEffect, useCallback } from "react";
import "./index.css"; // Import the CSS file
import Routes from "./routes/routes";
import { messaging } from "./utils/firebaseConfig";
import { getToken } from "firebase/messaging";
import baseURL from "./api/baseUrl";
import { ToastContainer } from "react-toastify";

function App() {
  // Memoize the token retrieval function
  const fetchFcmToken = useCallback(async () => {
    try {
      // Check if permission is already granted
      if (Notification.permission === "granted") {
        // Get FCM token silently
        const token = await getToken(messaging, {
          vapidKey: process.env.REACT_APP_FCM_SECRET,
        });

        if (token) {

          const userId = localStorage.getItem("userId");
          if (userId) {
            await sendTokenToBackend(userId, token);
          } else {
            console.log("No userId found in localStorage.");
          }
        } else {
          console.log("No registration token available.");
        }
      } else if (Notification.permission === "denied") {
        console.log("Notification permission denied by user.");
      } else {
        // Default case: Permission is "default" (not yet granted or denied)
        // Optionally, you can silently attempt to get the token later or do nothing
        console.log("Notification permission not yet granted.");
      }
    } catch (error) {
      console.error("Failed to fetch FCM token:", error);
    }
  }, []);

  // Function to send the token to your backend
  async function sendTokenToBackend(userId, token) {
    try {
      const response = await fetch(`${baseURL}/update-token`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId, fcmToken: token }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(
          `Failed to send token to backend: ${response.status} ${response.statusText} - ${errorText}`
        );
      }

    } catch (error) {
      console.error("Error sending token to backend:", error);
    }
  }

  // Use `useEffect` to trigger token fetch on mount
  useEffect(() => {
    fetchFcmToken();
  }, [fetchFcmToken]);

  



  return (
    <div style={{ width: "100vw", margin: 0, padding: 0 }}>
        <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        closeOnClick
        closeButton={true}
        pauseOnHover
        draggable
        theme="colored"
      />
      <Routes />
    
    </div>
  );
}

export default App;