import React from 'react';
import { Box, Typography, Divider, Container } from '@mui/material';

const MemberStats = ({orgData}) => {
  console.log('users',orgData )
  return (
    <Container
      elevation={0}
      sx={{
        border: '1px solid #e0e0e0',
        borderRadius: '8px',
        padding: '20px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: '#f9f9f9',
        
      }}

    >
      {/* Total Members */}
      <Box sx={{display:"flex",flexDirection:"column",alignItems:'center',justifyContent:"center", padding: '0 16px' }}>
        <Typography variant="subtitle1" color="textSecondary">
          Total members
        </Typography>
        <Typography variant="h5" fontWeight="bold">
          {orgData?.length}
        </Typography>
      </Box>

      {/* Vertical Divider */}
      <Divider orientation="vertical" flexItem sx={{ margin: '0 16px', borderColor: '#e0e0e0' }} />

      {/* Active Members */}
      <Box sx={{display:"flex",flexDirection:"column",alignItems:'center',justifyContent:"center", padding: '0 16px' }}>
        <Typography variant="subtitle1" color="textSecondary">
          Active members
        </Typography>
        <Typography variant="h5" fontWeight="bold">
        {orgData?.length}
        </Typography>
      </Box>

      {/* Vertical Divider */}
      <Divider orientation="vertical" flexItem sx={{ margin: '0 16px', borderColor: '#e0e0e0' }} />

      {/* Invited Members */}
      <Box sx={{display:"flex",flexDirection:"column",alignItems:'center',justifyContent:"center", padding: '0 16px' }}>
        <Typography variant="subtitle1" color="textSecondary">
          Invited
        </Typography>
        <Typography variant="h5" fontWeight="bold">
          00
        </Typography>
      </Box>
    </Container>
  );
};

export default MemberStats;
