"use client";

import React from "react";
import { Dialog, DialogContent, Typography, Box, styled } from "@mui/material";

import SubScriptionPage from "../EmployerJobPost/SubScriptionPage";
import CloseIcon from "@mui/icons-material/Close";
import searchCandidates from "../../../styles/searchCandidatesStyles";

const FullWidthDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    width: "90%",
    maxWidth: "1200px",
    margin: "32px auto",
    borderRadius: "16px",
  },
}));

//   height: "100%",
//   display: "flex",
//   flexDirection: "column",
//   justifyContent: "space-between",
//   transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
//   "&:hover": {
//     transform: "translateY(-5px)",
//     boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
//   },
// });

// const databasePlans = [
//   {
//     title: "Basic Search",
//     price: "$49/month",
//     features: ["100 searches/month", "Basic filters", "Email support"],
//   },
//   {
//     title: "Pro Search",
//     price: "$99/month",
//     features: ["Unlimited searches", "Advanced filters", "Priority support"],
//   },
//   {
//     title: "Enterprise Search",
//     price: "Custom",
//     features: [
//       "Custom search volume",
//       "API access",
//       "Dedicated account manager",
//     ],
//   },
// ];

// const jobPostingPlans = [
//   {
//     title: "Starter Pack",
//     price: "$199/month",
//     features: ["5 job postings", "30-day visibility", "Basic analytics"],
//   },
//   {
//     title: "Growth Pack",
//     price: "$399/month",
//     features: ["15 job postings", "60-day visibility", "Advanced analytics"],
//   },
//   {
//     title: "Premium Pack",
//     price: "$699/month",
//     features: [
//       "Unlimited job postings",
//       "90-day visibility",
//       "Premium placement",
//     ],
//   },
// ];

export default function SubscriptionModal({ modelOpen, onClose }) {
  return (
    <FullWidthDialog
      open={modelOpen}
      onClose={onClose}
      aria-labelledby="subscription-dialog-title"
    >
      <Box sx={{...searchCandidates.flexboxend, padding: "16px"}}>
        <CloseIcon onClick={onClose} sx={{fontSize:"40px",cursor:"pointer"}}/>
      </Box>

      <DialogContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "300px",
            height: "300px",
            backgroundColor: "#E8E8E8FF", // Light gray background
            borderRadius: "8px", // Rounded corners
            padding: "16px", // Padding inside the box
            marginBottom: "24px", // Space below the box
            maxWidth: "400px", // Smaller width
            margin: "0 auto", // Center the box
          }}
        >
          <Typography
            variant="h5" // Smaller font size
            component="h2"
            align="center"
            fontWeight="bold"
            color="primary"
          >
            You don't have any active plans
          </Typography>
        </Box>

        <SubScriptionPage />
      </DialogContent>
    </FullWidthDialog>
  );
}
