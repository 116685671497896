import React, { useState, useEffect, Suspense, lazy, useCallback } from "react";
import {
  Grid,
  Typography,
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Pagination,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Footer from "../../../components/JobSeekerComponents/Footer/Footer";
import baseURL from "../../../api/baseUrl";
import LogoCarouselSkeleton from "../../../utils/lazy/CarouselSkeleton";
import JobCardSkeleton from "../../../utils/lazy/JobCardSkeleton";
import FilterSkeleton from "../../../utils/lazy/FilterSkeleton";
import { useLocation, useNavigate } from "react-router-dom";
import NewNavBar from "../../../components/JobSeekerComponents/NavBar/NewNavbar";

// Lazy-loaded components
const Filters = lazy(() =>
  import(
    "../../../components/JobSeekerComponents/JobSeekerFilters/Filters/FiltersJobSeeker/Filters"
  )
);
const JobCard = lazy(() =>
  import("../../../components/JobSeekerComponents/FindJob/JobCard")
);
const LogoCarousel = lazy(() =>
  import("../../../components/JobSeekerComponents/FindJob/LogoCarousel")
);

const JobSeekerFilter = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [jobs, setJobs] = useState([]);
  const [allJobData, setAllJobData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortBy, setSortBy] = useState("newToOld");
  const [departments, setDepartments] = useState([]);
  const [salary, setSalary] = useState("");
  const [industry, setIndustry] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState(
    location.state?.jobCity
      ? Array.isArray(location.state.jobCity)
        ? location.state.jobCity
        : location.state.jobCity.split(",")
      : []
  );
  const [jobType, setJobType] = useState("");
  const [minCTC, setMinCTC] = useState(0);
  const [maxCTC, setMaxCTC] = useState(null);
  const [minExperience, setMinExperience] = useState(0);
  const [maxExperience, setMaxExperience] = useState(null);
  const [keyword, setKeyword] = useState(location.state?.keyword || "");
  const [searchKeywords, setSearchKeywords] = useState("");

  useEffect(() => {
    if (location.state?.departments) {
      setDepartments(location.state.departments);
    }
  }, [location.state]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const clearKeywords = useCallback(() => {
    navigate(location.pathname, { replace: true, state: {} });
  }, [navigate, location.pathname]);

  useEffect(() => {
    const fetchJobs = async (limit = 10) => {
      const authToken = localStorage.getItem("authToken");
      const queryParams = new URLSearchParams({
        ...(keyword && { keyword: encodeURIComponent(keyword) }),
        page: currentPage,
        postDateFilter: sortBy,
        ...(departments.length > 0 && {
          department: encodeURIComponent(departments.join(",")),
        }),
        ...(minExperience !== null && maxExperience !== null && { 
          requiredExperienceMin: minExperience,  
          requiredExperienceMax: maxExperience,  
        }),
        ...(salary && { salarySort: salary }),
        ...(industry.length > 0 && {
          industry: encodeURIComponent(industry.join(",")),
        }),
        ...(selectedLocations.length > 0 && {
          jobLocation: selectedLocations.map(encodeURIComponent).join(","),
        }),
        ...(jobType && { jobType }),
        ...(minCTC && { minCTC }),
        ...(maxCTC && { maxCTC }),
        limit,
      }).toString();
  
      const url = `${baseURL}/jobs/all${queryParams ? `?${queryParams}` : ""}`;
  
      try {
        setLoading(true);
        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
  
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
  
        const data = await response.json();
        setAllJobData(data);
        setJobs(data.data);
        setTotalPages(data.totalPages);
        clearKeywords();
      } catch (error) {
        console.log(error.message);
      } finally {
        setLoading(false);
      }
    };
  
    fetchJobs();
  }, [
    sortBy,
    currentPage,
    departments,
    minExperience,
    maxExperience, // Updated dependency list
    salary,
    industry,
    selectedLocations,
    jobType,
    maxCTC,
    minCTC,
    keyword,
    clearKeywords,
  ]);

  const handleSearchChange = (event) => {
    setSearchKeywords(event.target.value);
  };

  const handleSearchSubmit = () => {
    setCurrentPage(1);
    setKeyword(searchKeywords);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearchSubmit();
    }
  };

  const handleSortChange = (newSort) => {
    setSortBy(newSort);
    setCurrentPage(1);
  };

  const handleDepartmentChange = (newDepartment) => {
    setDepartments(newDepartment);
    setCurrentPage(1);
  };

  const handleExperienceChange = (newValue) => {
    console.log("newValue", newValue);
    setMinExperience(newValue[0]); // Update minimum experience
    setMaxExperience(newValue[1]); // Update maximum experience
    setCurrentPage(1);
  };

  const handleIndustryChange = (newIndustry) => {
    setIndustry(newIndustry);
    setCurrentPage(1);
  };

  const handleSalaryChange = (newSalary) => {
    setSalary(newSalary);
  };

  const handleLocationChange = (newLocation) => {
    setSelectedLocations(Array.isArray(newLocation) ? newLocation : [newLocation]);
    setCurrentPage(1);
  };

  const handleJobTypeChange = (newJobType) => {
    setJobType(newJobType);
    setCurrentPage(1);
  };

  const handleCTCRangeChange = (newRange) => {
    setMinCTC(newRange[0]);
    setMaxCTC(newRange[1]);
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleSelectJob = (id) => {
    // Placeholder for future functionality if needed
    console.log("Selected job ID:", id);
  };

  const startJob = (currentPage - 1) * 10 + 1;
  const endJob = Math.min(currentPage * 10, allJobData?.totalJobPosts || 0);

  return (
    <Box
      sx={{
        background: "linear-gradient(135deg, #F8F9FA, #CED4DA)",
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        margin: 0,
      }}
    >
      <NewNavBar />
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          maxWidth: "1200px",
          margin: "0 auto",
          padding: (theme) => theme.spacing(4),
        }}
      >
        <Suspense fallback={<LogoCarouselSkeleton />}>
          <LogoCarousel />
        </Suspense>

        {keyword.length > 0 && (
          <Typography sx={{ fontFamily: "satoshi", fontSize: "18px" }}>
            Showing{" "}
            <span style={{ fontWeight: "bold" }}>
              {keyword.split(",").map((k) => `"${k.trim()}"`).join(", ")}
            </span>{" "}
            in Search Results
          </Typography>
        )}

        <Box sx={{ width: "100%", maxWidth: "800px", my: "24px" }}>
          <TextField
            fullWidth
            placeholder="Search jobs by title, keyword, or company..."
            value={searchKeywords}
            onChange={handleSearchChange}
            onKeyDown={handleKeyDown}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleSearchSubmit}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
              sx: {
                borderRadius: "25px",
                backgroundColor: "#ffffff",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                color: "#3F3F3F",
                fontSize: "16px",
                px: "10px",
              },
            }}
          />
        </Box>

        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={3}>
            <Suspense fallback={<FilterSkeleton />}>
              <Filters
                handleSortChange={handleSortChange}
                sortBy={sortBy}
                departments={departments}
                handleDepartmentChange={handleDepartmentChange}
                handleExperienceChange={handleExperienceChange}
                salary={salary}
                handleSalaryChange={handleSalaryChange}
                industry={industry}
                handleIndustryChange={handleIndustryChange}
                selectedLocations={selectedLocations}
                handleLocationChange={handleLocationChange}
                jobType={jobType}
                handleJobTypeChange={handleJobTypeChange}
                handleCTCRangeChange={handleCTCRangeChange}
                minCTC={minCTC}
                maxCTC={maxCTC}
                keyword={keyword}
                minExperience={minExperience}
                maxExperience={maxExperience}
              />
            </Suspense>
          </Grid>
          <Grid item xs={12} md={7}>
            {loading ? (
              <JobCardSkeleton />
            ) : (
              <Suspense fallback={<JobCardSkeleton />}>
                <JobCard jobs={jobs} handleSelectJob={handleSelectJob} />
              </Suspense>
            )}
          </Grid>
        </Grid>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            background: "linear-gradient(250deg, #CED4DA)",
            borderBottom: "1px solid #e0e0e0",
            fontFamily: "IBM Plex Sans, sans-serif",
            mb: 2,
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              color: "#666",
              fontFamily: "IBM Plex Sans, sans-serif",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mt: "30px",
            }}
          >
            {startJob} - {endJob} of {allJobData?.totalJobPosts || 0} Jobs
          </Typography>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            sx={{ mt: "2rem", display: "flex", justifyContent: "center", mb: 1 }}
          />
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default JobSeekerFilter;