import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Grid,
} from "@mui/material";
import { getAllArticles } from "../../../../api/blogs";
import NewNavBar from "../../NavBar/NewNavbar";
import { useNavigate } from "react-router-dom"; // Import useNavigate

// Styles (unchanged except for card to make it clickable)
const styles = {
  root: {
    backgroundColor: "#F5F5FB",
    minHeight: "100vh",
    padding: "60px 0",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  heading: {
    fontWeight: 700,
    textAlign: "center",
    marginBottom: "8px",
    color: "#1A1A1A",
    fontFamily: '"Montserrat", sans-serif',
  },
  underline: {
    width: "64px",
    height: "4px",
    backgroundColor: "#FF9800",
    marginBottom: "40px",
  },
  card: {
    borderRadius: "12px",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.08)",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    transition: "0.3s",
    cursor: "pointer", // Add cursor pointer for clickability
    "&:hover": {
      transform: "translateY(-4px)",
      boxShadow: "0px 8px 30px rgba(0, 0, 0, 0.12)",
    },
  },
  cardMedia: {
    height: 250,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  cardContent: {
    padding: "12px",
  },
  date: {
    fontSize: "14px",
    color: "#666",
    marginBottom: "12px",
  },
  cardTitle: {
    fontSize: "20px",
    fontWeight: 700,
    marginBottom: "12px",
    lineHeight: 1.3,
    color: "#1A1A1A",
  },
  cardDescription: {
    fontSize: "14px",
    color: "#666",
    lineHeight: 1.5,
  },
};

const JobSeekerArticles = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); // Hook for navigation

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const data = await getAllArticles();
        setBlogs(data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch blogs:", error);
        setLoading(false);
      }
    };
    fetchBlogs();
  }, []);

  const handleCardClick = (articleId) => {
    
    navigate(`/article/${articleId}`); // Navigate to blog details page
  };
  
  return (
    <>
      <NewNavBar />
      <Box sx={styles.root}>
        <Container maxWidth="lg" sx={styles.container}>
          <Typography variant="h4" sx={styles.heading}>
            Browse our thoughts and news
          </Typography>
          <Box sx={styles.underline} />
          {loading ? (
            <Typography>Loading blogs...</Typography>
          ) : (
            <Grid container spacing={4}>
              {blogs?.map((post) => (
                <Grid item xs={12} md={6} lg={6} key={post._id}>
                  <Card
                    sx={styles.card}
                    onClick={() => handleCardClick(post._id)} // Click handler
                  >
                    <CardMedia
                      sx={styles.cardMedia}
                      image={post.imageUrl || "default-image.jpg"}
                      title={post.title}
                    />
                    <CardContent sx={styles.cardContent}>
                      <Typography sx={styles.date}>
                        {new Date(post.metadata.publishDate).toLocaleDateString()}
                      </Typography>
                      <Typography sx={styles.cardTitle}>{post.title}</Typography>
                      <Typography sx={styles.cardDescription}>
                        {post.introduction.length > 150
                          ? `${post.introduction.substring(0, 150)}...`
                          : post.introduction}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
        </Container>
      </Box>
    </>
  );
};

export default JobSeekerArticles;