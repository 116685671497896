import React, { useEffect, useRef, useState,useCallback } from "react";
import NewNavBar from "../../NavBar/NewNavbar";
import Footer from "../../Footer/Footer";
import { Container, Typography, Box } from "@mui/material";
import CareerCards from "./CareerCards";
import CareerIcons from "./CareerIcons";
import CareerImages from "./CareerImages";
import JobSeekerSupportForm from "../../../../pages/JobSeekerPages/JobSeekerSupport/JobSeekerSupportForm";
import { getUserApi } from "../../../../api/jobseeker";
import {
  jobSeekerCreateTicket,
  fetchJobSeekerSupport,
} from "../../../../api/suport";
import PopupSuccess from "../../../../utils/popup/PopupSuccess";
import ReleaseJobPostModel from "../../../../pages/EmployerPages/SubscriptionStatus/ReleaseJobPostModel";

const jobCategories = [
  {
    title: "Technology",
    openings: 13,
  },
  {
    title: "Product",
    openings: 2,
  },
  {
    title: "Data",
    openings: 3,
  },
  {
    title: "Design",
    openings: 1,
  },
  {
    title: "Marketing",
    openings: 18,
  },
  {
    title: "Business",
    openings: 20,
  },
];

const JobSeekerCareers = () => {
  const supportRef = useRef(null);

  const token = localStorage.getItem("authToken");
  const userId = localStorage.getItem("userId");
  const [userData, setUserData] = useState();
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSuccess = useCallback((message) => {
    setSuccessMessage(message);
    setSuccessOpen(true);
    setTimeout(() => {
      setSuccessOpen(false);
      setSuccessMessage("");
    }, 2000);
  }, []);

  const handleError = useCallback((message) => {
    setErrorMessage(message);
    setErrorOpen(true);
    setTimeout(() => {
      setErrorOpen(false);
      setErrorMessage("");
    }, 2000);
  }, []);


  const handleModalClose = () => {
    console.log("Closing modal");
  };
  useEffect(() => {
    if (token) {
      const getUserDetails = async () => {
        try {
          const response = await getUserApi(userId, token);
          setUserData(response?.user);
        } catch (error) {
          console.log(error.message);
        }
      };
      getUserDetails();
    }
  }, [token, userId]);

  useEffect(() => {
    // Scroll to the support form section on page load
    if (supportRef.current) {
      supportRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const handleSave = async (topic, messageContent, supportType) => {
    try {
      const payload = { topic, messageContent, supportType };
      await jobSeekerCreateTicket(payload);
      const updatedSupportData = await fetchJobSeekerSupport();
      console.log("updatedSupportData", updatedSupportData);
handleSuccess('Support ticket created successfully');
     
    } catch (error) {
      handleError('Failed to create the support ticket.')
   
    } finally {
      handleModalClose();
    }
  };

  return (
    <>
      <NewNavBar />
      <Box sx={{ backgroundColor: "#f5faf5", p: 8 }}>
        <Container>
          <Container>
            <Typography
              variant="h2"
              align="center"
              sx={{
                fontWeight: "bold",
                lineHeight: "48px",
                mx: "auto",
              }}
            >
              Join the Vacanzi Revolution!{" "}
            </Typography>
            <Typography
              variant="h4"
              align="center"
              sx={{
                fontWeight: "bold",
                fontSize: { xs: 24, md: "40px" },
                lineHeight: "48px",
                maxWidth: "600px",
                mx: "auto",
              }}
            >
              Be Part of Something Bigger
            </Typography>
          </Container>
        </Container>
      </Box>
      <Box sx={{ background: "#f5f5f5" }}>
        <CareerCards jobCategories={jobCategories} />
      </Box>
      <Box>
        <CareerIcons />
      </Box>
      <Box>
        <CareerImages />
      </Box>
      <Box ref={supportRef} sx={{ mb: 10 }}>
        <Typography
          sx={{ textAlign: "center", fontWeight: "bold", fontSize: "34px" }}
          gutterBottom
        >
          Careers
        </Typography>
        <JobSeekerSupportForm userData={userData} handleSave={handleSave} />
      </Box>
      <PopupSuccess
        modelOpen={successOpen}
        onClose={() => setSuccessOpen(false)}
        message={successMessage}
      />
      <ReleaseJobPostModel
        modelOpen={errorOpen}
        onClose={() => setErrorOpen(false)}
        message={errorMessage}
      />
      <Footer />
    </>
  );
};

export default JobSeekerCareers;
