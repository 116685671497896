import React, { useState, useEffect, useCallback } from "react";
import { Box, Container, Typography, Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import baseURL from "../../api/baseUrl";

const CustomAccordion = styled(Accordion)(({ theme }) => ({
  border: "none",
  backgroundColor: "transparent",
  boxShadow: "none",
  "&:before": { display: "none" },
  "& .MuiAccordionSummary-root": { borderBottom: "1px solid #E2E8F0", padding: theme.spacing(1, 0) },
  "& .MuiAccordionSummary-content": { margin: "8px 0" },
  "& .MuiAccordionDetails-root": { padding: theme.spacing(1, 0) },
}));

const CustomExpandIcon = styled(ExpandMoreIcon)({
  color: "#14234A",
  transition: "transform 0.3s ease",
});

const Faq = ({ category }) => {
  const [faqs, setFaqs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [expanded, setExpanded] = useState(false);

  // Memoized fetch function
  const fetchFaqs = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await fetch(`${baseURL}/faqs/${category}`);
      if (!response.ok) throw new Error(`Failed to fetch FAQs: ${response.statusText}`);

      const data = await response.json();
      setFaqs((prevFaqs) => (JSON.stringify(prevFaqs) === JSON.stringify(data) ? prevFaqs : data)); // Prevent unnecessary state updates
    } catch (err) {
      console.error("Error fetching FAQs:", err);
      setError(err.message || "An unexpected error occurred.");
    } finally {
      setLoading(false);
    }
  }, [category]);

  // Fetch data only when category changes
  useEffect(() => {
    fetchFaqs();
  }, [fetchFaqs]);

  // Memoized event handler
  const handleChange = useCallback((panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  }, []);

  if (loading) return <Typography variant="h6">Loading FAQs...</Typography>;
  if (error) return <Typography variant="h6" color="error">{error}</Typography>;

  return (
    <Box sx={{ py: 4, bgcolor: "#EAFBF4FF", fontFamily: "IBM Plex Sans, sans-serif" }}>
      <Container maxWidth="lg">
        <Typography
          variant="h2"
          component="h2"
          textAlign="center"
          sx={{ mb: 4, color: "#14234A", fontSize: { xs: "1.75rem", md: "2rem" }, fontWeight: 600 }}
        >
          Frequently Asked Questions
        </Typography>

        <Box sx={{ maxWidth: "100%" }}>
          {faqs.map((faq, index) => (
            <CustomAccordion
              key={faq._id}
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
              disableGutters
            >
              <AccordionSummary
                expandIcon={<CustomExpandIcon />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
                sx={{
                  "& .MuiAccordionSummary-expandIconWrapper": {
                    transform: expanded === `panel${index}` ? "rotate(180deg)" : "none",
                    transition: "transform 0.3s ease",
                  },
                }}
              >
                <Typography sx={{ fontSize: { xs: "0.875rem", md: "1rem" }, fontWeight: 500, color: "#14234A" }}>
                  {faq.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={{ color: "#475569", lineHeight: 1.6, fontSize: { xs: "0.8125rem", md: "0.875rem" } }}>
                  {faq.answer}
                </Typography>
              </AccordionDetails>
            </CustomAccordion>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default Faq;
