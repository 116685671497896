import { useEffect, useState } from "react";
import { getDepartmentsApi } from "../../../api/common";

// Utility function to fetch departments dynamically
export const fetchDepartments = async () => {
  try {
    const response = await getDepartmentsApi();
    if (response && response.length > 0) {
      // Extract only the 'name' property from each department object
      return response.map((dept) => dept.name);
    } else {
      console.error("Invalid API response format:", response);
      return []; // Return empty array if no valid data
    }
  } catch (error) {
    console.error("Error fetching departments:", error.message);
    return []; // Return empty array on error
  }
};

// Custom hook to manage department names in components
export const useDepartments = () => {
  const [departments, setDepartments] = useState([]);

  useEffect(() => {
    const getAllDepartments = async () => {
      const fetchedDepartments = await fetchDepartments();
      setDepartments(fetchedDepartments);
    };
    getAllDepartments();
  }, []);

  return departments;
};

// Export getDepartmentOptions as a function that returns a promise of department names
export const getDepartmentOptions = () => fetchDepartments();

// Rest of your exports remain unchanged
export const pgEducations = [
  "LLM",
  "M.A",
  "M.Arch",
  "M.Ch",
  "M.Com",
  "M.Des.",
  "M.Ed",
  "M.Pharma",
  "M.Tech",
  "MBA/PGDM",
  "MCA",
  "MCM",
  "MDS",
  "MFA",
  "MS/M.Sc(Science)",
  "MVSC",
  "Medical-MS/MD",
  "PG Diploma",
  "Other Post Graduate"
];

export const ugEducations = [
  "Any Computers(Degree/Diploma)",
  "Any Engineering",
  "Any Hotel-Management",
  "Any Management",
  "Any Medical",
  "B.Tech/B.E (From Top Ranked Colleges)",
  "ITI",
  "Journalism/Mass Communication",
  "UG Popular Course",
  "Vocational-Training",
  "B.A",
  "B.Arch",
  "B.B.A/ B.M.S",
  "B.Com",
  "B.Des.",
  "B.Ed",
  "B.El.Ed",
  "B.P.Ed",
  "B.Pharma",
  "B.Sc",
  "B.Tech/B.E.",
  "B.U.M.S",
  "BAMS",
  "BCA",
  "BDS",
  "BFA",
  "BHM",
  "BHMCT",
  "BHMS",
  "BVSC",
  "Diploma",
  "ITI Certification",
  "LLB",
  "MBBS",
  "Other Graduate"
];

export const jobTypeOptions = [
  "partTime",
  "fullTime",
  "freelance",
  "contract",
  "internShip",
  "remote",
].map((jobType) => ({
  value: jobType,
  label: jobType
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, (str) => str.toUpperCase()),
}));

export const experienceOptions = [
  "Fresher",
  ...Array.from({ length: 30 }, (_, i) => `${i + 1} Year${i > 0 ? "s" : ""}`),
];

export const minExpOptions = [
  { label: "Fresher", value: 0 },
  ...Array.from({ length: 30 }, (_, i) => ({
    label: `${i + 1} Year${i > 0 ? "s" : ""}`,
    value: i + 1,
  })),
];

export const getMaxExpOptions = (minExp) => {
  if (!minExp) {
    return minExpOptions.slice(1);
  }
  const minValue = minExp.value !== undefined ? minExp.value : 0;
  return minExpOptions.filter((option) => option.value >= minValue);
};

export const keySkills = [
  "Java",
  "Python",
  "C++",
  "JavaScript",
  "ReactJS",
  "AngularJS",
  "NodeJS",
  "ExpressJS",
  "MongoDB",
  "SQL",
  "NoSQL",
  "HTML",
  "CSS",
  "Bootstrap",
  "Material UI",
  "Redux",
  "TypeScript",
  "Django",
  "Flask",
  "RESTful API",
  "GraphQL",
  "AWS",
  "Azure",
  "GCP",
  "Docker",
  "Kubernetes",
  "Jenkins",
  "Git",
  "JIRA",
  "Confluence",
  "Agile",
  "Scrum",
  "Kanban",
  "TDD",
  "BDD",
  "CI/CD",
  "Microservices",
  "Serverless",
  "Kafka",
  "RabbitMQ",
  "Elasticsearch",
  "Logstash",
  "Kibana",
  "Prometheus",
  "Grafana",
  "Splunk",
  "New Relic",
  "Datadog",
  "Dynatrace",
  "AppDynamics",
  "Nagios",
  "Zabbix",
  "Icinga",
  "Sensu",
  "PagerDuty",
  "VictorOps",
  "OpsGenie",
  "BigPanda",
  "Moogsoft",
  "xMatters",
  "Sumo Logic",
  "Loggly",
  "Logentries",
  "Papertrail",
  "Logz.io",
  "SolarWinds",
  "LogRhythm",
  "Graylog",
  "Splunk",
  "Datadog",
  "Dynatrace",
  "AppDynamics",
];

export const minSalaryOptions = Array.from({ length: 101 }, (_, i) => ({
  label: `${i} LPA`,
  value: i.toString(),
}));

export const getMaxSalaryOptions = (minSalary) => {
  if (!minSalary) return minSalaryOptions.slice(1);
  const minIndex = minSalaryOptions.findIndex((option) => option.value === minSalary);
  return minSalaryOptions.slice(minIndex + 1);
};

export const industryOptions = [
  "BFSI",
  "Technology",
  "BPO",
  "Consumer, Retail & Hospitality",
  "Healthcare & Life Sciences",
  "Professional Services",
  "Education",
  "Infrastructure, Transport & Real Estate",
  "Manufacturing & Production",
  "IT Services",
  "Others",
];


export let departmentOptions = [
 
  'Customer Success, Service & Operations',
  'Data Science & Analytics',
  'Engineering - Hardware & Networks',
  'Engineering - Software & QA',
  'Finance & Accounting',
  'Human Resources',
  'IT & Information Security',
  'Marketing & Communication',
  'Product Management',
  'Production, Manufacturing & Engineering',
  'Project & Program Management',
  'Quality Assurance',
  'Sales & Business Development',
  'UX, Design & Architecture',
  'Administration & Facilities',
  'Aviation & Aerospace',
  'Construction & Site Engineering',
  'Consulting',
  'Content, Editorial & Journalism',
  'CSR & Social Service',
  'Energy & Mining',
  'Environment Health & Safety',
  'Food, Beverage & Hospitality',
  'Healthcare & Life Sciences',
  'Legal & Regulatory',
  'Media Production & Entertainment',
  'Merchandising, Retail & eCommerce',
  'Procurement & Supply Chain',
  'Research & Development',
  'Risk Management & Compliance',
  'Security Services',
  'Shipping & Maritime',
  'Sports, Fitness & Personal Care',
  'Strategic & Top Management',
  'Teaching & Training',
  'Other'
];