// src/layouts/EmployerLayout.jsx
import React from "react";
import { Outlet } from "react-router-dom";
import EmployerDrawer from "../components/EmployerComponents/EmployerJobListingComponents/EmployerDrawer/EmployerDrawer";

const EmployerLayout = () => {
  return (
    <>
     <EmployerDrawer>
        <Outlet />
      </EmployerDrawer>
      
    </>
     
    
  );
};

export default EmployerLayout;
