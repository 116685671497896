import React, { useEffect, useState, useCallback } from "react";
import {
  Card,
  Dialog,
  DialogContent,
  Radio,
  Box,
  CardContent,
  Button,
  Typography,
  TextField,
  InputAdornment,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { jwtDecode } from "jwt-decode";
import baseURL from "../../api/baseUrl";
import { ThreeDot } from "react-loading-indicators";
import { shortListApi } from "../../api/jobPostAPI";
import { sendInviteApi } from "../../api/sendInvite";
import { truncateText } from "../../utils/helperFunctions/Format";

const SelectJobDialog = ({
  openSelectJob,
  handleSelectJob: parentHandleSelectJob,
  inviteData,
  handleCloseDialog,
  inviteLoading,
  setInviteLoading,
  type = "invite",
  data,
  onSuccess,
  onError, // New prop for error callback
}) => {
  const [jobs, setJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedJobId, setSelectedJobId] = useState(inviteData?.jobId || "");
  const token = localStorage.getItem("token");

  const fetchJobPostings = useCallback(async (userData) => {
    try {
      const { role, id, token, organizationId, EmployerOranizationId } = userData;
      let url;
      if (role === "recruiter_admin") {
        url = `${baseURL}/jobpost/${organizationId || EmployerOranizationId}`;
      } else if (role === "recruiter") {
        url = `${baseURL}/jobpost/by/${id}`;
      } else {
        console.error("Invalid role:", role);
        return;
      }

      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();
      const activeJobs = data.filter((item) => item.status === "active");
      if (response.ok) {
        setJobs(activeJobs);
        setFilteredJobs(activeJobs);
      } else {
        console.error("Error fetching job postings:", data.message);
      }
    } catch (error) {
      console.error("Error fetching job postings:", error);
    }
  }, []);

  const handleSearchChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = jobs.filter(
      (job) =>
        job.jobTitle?.toLowerCase().includes(query) ||
        job.jobRole?.toLowerCase().includes(query) ||
        job.ctc?.toLowerCase().includes(query) ||
        job.requiredExperience?.toLowerCase().includes(query)
    );
    setFilteredJobs(filtered);
  };

  useEffect(() => {
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const userData = {
          organizationId:
            decodedToken.organizationId || localStorage.getItem("organizationId"),
          role: decodedToken.role || localStorage.getItem("role"),
          token,
          id: decodedToken.id,
          fullName: decodedToken.name,
        };
        fetchJobPostings(userData);
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    }
  }, [token, fetchJobPostings]);

  useEffect(() => {
    if (openSelectJob) {
      setSelectedJobId(inviteData?.jobId || "");
    }
  }, [openSelectJob, inviteData?.jobId]);

  const handleLocalSelectJob = (event) => {
    setSelectedJobId(event.target.value);
  };

  const handleAction = async () => {
    setInviteLoading(true);
    try {
      const actionData = { userIds: inviteData.userId, jobId: selectedJobId };

      if (!selectedJobId) {
        onError("No job selected. Please select a job to proceed.");
        return;
      }

      if (type === "invite") {
       const response =  await sendInviteApi(token, actionData);
        onSuccess(response.message||"Invitation sent successfully!");
      } else if (type === "shortlist") {
       const response =  await shortListApi(selectedJobId, token, { userIds: [data?._id] });
        onSuccess(response.message || "Candidate shortlisted successfully!");
      }

      parentHandleSelectJob({ target: { value: selectedJobId } });
      handleCloseDialog();
    } catch (error) {
      console.error(`Error during ${type}:`, error);
      onError(
        error.message ||
          `Failed to ${type === "invite" ? "send invite" : "shortlist candidate"}`
      );
    } finally {
      setInviteLoading(false);
    }
  };

  return (
    <Dialog
      open={openSelectJob}
      onClose={handleCloseDialog}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        style: {
          borderRadius: "16px",
          boxShadow: "0 8px 32px rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 3,
          }}
        >
          <Typography
            sx={{
              fontSize: 24,
              fontFamily: "poppins, sans-serif",
              fontWeight: 600,
              color: "#000",
            }}
          >
            Select a Job to {type === "invite" ? "Invite" : "Shortlist"}
          </Typography>
          <CloseIcon
            sx={{ fontSize: 30, cursor: "pointer", color: "#666" }}
            onClick={handleCloseDialog}
          />
        </Box>

        <TextField
          fullWidth
          placeholder="Search jobs..."
          variant="outlined"
          value={searchQuery}
          onChange={handleSearchChange}
          sx={{
            mb: 3,
            "& .MuiOutlinedInput-root": {
              borderRadius: "12px",
              "& fieldset": { borderColor: "#ddd" },
              "&:hover fieldset": { borderColor: "#423BFA" },
              "&.Mui-focused fieldset": { borderColor: "#423BFA" },
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ color: "#666" }} />
              </InputAdornment>
            ),
          }}
        />

        {filteredJobs.map((job) => (
          <Card
            key={job._id}
            sx={{
              mb: 2,
              borderRadius: "12px",
              border: "1px solid",
              borderColor: "grey.300",
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.05)",
              transition: "box-shadow 0.3s, transform 0.3s",
              "&:hover": {
                boxShadow: "0 8px 24px rgba(0, 0, 0, 0.1)",
                transform: "translateY(-2px)",
              },
            }}
          >
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <Typography
                    gutterBottom
                    sx={{
                      color: "#000",
                      fontSize: 18,
                      fontWeight: 600,
                      mb: 1,
                      fontFamily: "poppins",
                    }}
                  >
                    {job.jobTitle}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#666",
                      fontSize: 14,
                      mb: 0.5,
                      fontFamily: "poppins",
                    }}
                  >
                    CTC:{" "}
                    {job?.ctcMin
                      ? `${job.ctcMin.toString().replace("LPA", "")}`
                      : "Not Disclosed"}
                    -{job?.ctcMax ? job.ctcMax : ""}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#666",
                      fontSize: 14,
                      mb: 0.5,
                      fontFamily: "poppins",
                    }}
                  >
                    Experience:{" "}
                    {job?.requiredExperienceMin || job?.requiredExperienceMax
                      ? `${
                          job?.requiredExperienceMin !== undefined
                            ? job?.requiredExperienceMin === "Fresher"
                              ? "0"
                              : job?.requiredExperienceMin === "1 Year"
                              ? "1"
                              : `${(job?.requiredExperienceMin || "").toString().replace("Years", "")}`
                            : ""
                        }${
                          job?.requiredExperienceMin && job?.requiredExperienceMax ? "-" : ""
                        }${
                          job?.requiredExperienceMax !== undefined
                            ? job?.requiredExperienceMax === 0
                              ? "0"
                              : job?.requiredExperienceMax === 1
                              ? "1 Year"
                              : `${job?.requiredExperienceMax}`
                            : ""
                        }`
                      : "Not Mentioned"}{" "}
                  </Typography>
                  <Typography
                    sx={{ color: "#666", fontSize: 14, fontFamily: "poppins" }}
                  >
                    Job Role: {truncateText(job?.jobRole, 100)}
                  </Typography>
                </Box>
                <Radio
                  checked={selectedJobId === job._id}
                  onChange={handleLocalSelectJob}
                  value={job._id}
                  sx={{ color: "#000", "&.Mui-checked": { color: "#423BFA" } }}
                />
              </Box>
            </CardContent>
          </Card>
        ))}

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 2,
            bgcolor: "white",
            position: "sticky",
            bottom: 0,
            pt: 2,
            pb: 1,
            zIndex: 10,
          }}
        >
          <Button
            variant="contained"
            onClick={handleAction}
            disabled={!selectedJobId}
            sx={{
              textTransform: "none",
              borderRadius: "0.7rem",
              backgroundColor: "#000",
              fontFamily: "poppins, sans-serif",
              fontSize: "16px",
              fontWeight: 500,
              padding: "8px 24px",
              "&:hover": { backgroundColor: "#666", color: "#fff" },
              minWidth: "120px",
              height: "40px",
            }}
          >
            {inviteLoading ? (
              <ThreeDot color="#000" size="small" text="" textColor="" />
            ) : type === "invite" ? (
              "Send Invite"
            ) : (
              "Shortlist"
            )}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SelectJobDialog;