import React, { createContext, useContext, useEffect, useState, useCallback } from "react";
import { getUserApi } from "../api/jobseeker";


const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const token = localStorage.getItem("authToken");
  const userId = localStorage.getItem("userId");; // Ensure this exists

  const fetchUserDetails = useCallback(async () => {
    if (!userId || !token) return;

    try {
      const userDetails = await getUserApi(userId, token);
      setUserData(userDetails?.user);
    } catch (error) {
      console.error("Failed to fetch organization details:", error);
    }
  }, [userId, token]);

  useEffect(() => {
    if (!userData) {
        fetchUserDetails();
    }
  }, [fetchUserDetails, userData]);

  return <UserContext.Provider value={{ userData ,setUserData}}>{children}</UserContext.Provider>;
};

export const useJobseeker = () => useContext(UserContext);