import React, { useState } from "react";
import {
  Box,
  Paper,
  Button,
  CardContent,
  Collapse,
  Typography,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CompanyDetailsModel from "../../../../components/EmployerComponents/CompanyProfileComponents/CompanyDetailsModel";

const CompanyDetails = ({
  openCompanyDialog,
  handleCompanyDialogClose,
  handleSaveCompanyDetails,
  handleCompanyEdit,
  orgData,
  fetchOrgDetails
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };
console.log('orgdata',orgData)
  return (
    <Paper
      elevation={3}
      sx={{
        backgroundColor: "#FFF",
        borderRadius: "12px",
        overflow: "hidden",
        marginTop: 2,
      }}
    >
      <CardContent sx={{ cursor: "pointer" }} onClick={handleToggleExpand}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" sx={{  fontWeight: "bold",fontFamily:"poppins" }}>
            Company Details
          </Typography>
          {expanded ? (
            <KeyboardArrowDownIcon
              sx={{
                fontSize: "40px",
                cursor: "pointer",
                color: "gray",
                transition: "transform 0.3s ease",
              }}
            />
          ) : (
            <ArrowForwardIosIcon
              sx={{
                cursor: "pointer",
                color: "gray",
                transition: "transform 0.3s ease",
              }}
            />
          )}
        </Box>
      </CardContent>

      <Collapse in={expanded}>
        <CardContent>
          <Box sx={{ mb: 2 }}>
            <Typography
              gutterBottom
              variant="body2"
              sx={{ fontSize: "1rem", color: "#000", fontFamily: "poppins" }}
            >
              <strong>Industry Type:</strong> {orgData?.industry}
            </Typography>

            <Typography
              gutterBottom
              variant="body2"
              sx={{ fontSize: "1rem", color: "#000", fontFamily: "poppins" }}
            >
              <strong>Director's Name:</strong> {orgData?.directorName}
            </Typography>
            <Typography
              gutterBottom
              variant="body2"
              sx={{ fontSize: "1rem", color: "#000", fontFamily: "poppins" }}
            >
              <strong>Website URL:</strong> {orgData?.website}
            </Typography>
            <Typography
              gutterBottom
              variant="body2"
              sx={{ fontSize: "1rem", color: "#000", fontFamily: "poppins" }}
            >
              <strong>TA Manager:</strong> {orgData?.hrName}
            </Typography>
            <Typography
              gutterBottom
              variant="body2"
              sx={{ fontSize: "1rem", color: "#000", fontFamily: "poppins" }}
            >
              <strong>TA Manager Contact:</strong> {orgData?.hrPhone}
            </Typography>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "end", mt: 2 }}>
            <Button
              sx={{ borderRadius: "0.7rem", backgroundColor: "#423bfa",textTransform:"none",fontWeight:"bold",fontFamily:"poppins" }}
              variant="contained"
              onClick={handleCompanyEdit}
            >
              Edit
            </Button>
          </Box>
        </CardContent>
      </Collapse>

      <CompanyDetailsModel
        openCompanyDialog={openCompanyDialog}
        handleCompanyDialogClose={handleCompanyDialogClose}
        handleSaveCompanyDetails={handleSaveCompanyDetails}
        orgData={orgData}
        fetchOrgDetails={fetchOrgDetails}
      />
    </Paper>
  );
};

export default CompanyDetails;
