import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import {
  Box,
  Button,
  Grid,
  Avatar,
  Typography,
  Paper,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  // keyframes,
  // InputLabel,
  // Select,
  // MenuItem,
  // FormControl,
  CircularProgress,
  debounce,
  Autocomplete,
  MenuItem,
  Select,
  Chip,
  InputAdornment,
} from "@mui/material";
// import { Edit as EditIcon } from "@mui/icons-material";
import baseURL from "../../../api/baseUrl";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { getProfilePercentageApi, getUserApi } from "../../../api/jobseeker";
import { getTopCitiesApi } from "../../../api/common";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import LoadingImageGif from "../../../assets/images/Landingloading.gif";
import EmailEdit from "./EmailEdit";
import BioModal from "./BioModal";
import PhoneEdit from "./PhoneEdit";
import PopupSuccess from "../../../utils/popup/PopupSuccess";
import ReleaseJobPostModel from "../../../pages/EmployerPages/SubscriptionStatus/ReleaseJobPostModel";

const JobSeekerPersonalDetails = () => {
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [otp, setOtp] = useState(Array(4).fill("")); // OTP state
  const inputRefs = useRef([]);
  const [isLoading, setIsLoading] = useState(true);

  const [userData, setUserData] = useState();

  const [profileImage, setProfileImage] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [profilePercentage, setProfilePercentage] = useState();
  const [locations, setLocations] = useState([]);
  const [inputValue, setInputValue] = useState();
  const [skillInput, setSkillInput] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    dob: "",
    gender: "",
    totalExpInMonths: "",
    totalExpInYears: "",
    noticePeriod: "",
    currentLocation: "",
    profileImage: "",
    skills: [],
    bio: "",
    expectedCtc: "",
  });
  const [successOpen, setSuccessOpen] = useState(false);

  const [successMessage, setSuccessMessage] = useState("");

  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSuccess = useCallback((message) => {
    setSuccessMessage(message);
    setSuccessOpen(true);
    setTimeout(() => {
      setSuccessOpen(false);
      setSuccessMessage("");
    }, 2000);
  }, []);

  const handleError = useCallback((message) => {
    setErrorMessage(message);
    setErrorOpen(true);
    setTimeout(() => {
      setErrorOpen(false);
      setErrorMessage("");
    }, 2000);
  }, []);

  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem("authToken");

  useEffect(() => {
    setOtpVerified(userData?.emailVerified);

    const skillsArray = userData?.skills
      ? Array.isArray(userData?.skills)
        ? userData?.skills
        : userData?.skills.split(",").map((skill) => skill.trim())
      : [];
    // Set formData for both displaying and editing
    setFormData({
      fullName: userData?.fullName || "",
      email: userData?.email || "",
      phone: userData?.phone || "",
      profileImage: userData?.profileImage || "",
      totalExpInMonths: userData?.totalExpInMonths || "",
      totalExpInYears: userData?.totalExpInYears || "",
      noticePeriod: userData?.noticePeriod || "",
      skills: skillsArray || "",
      dob: userData?.dob || "",
      gender: userData?.gender || "",
      currentLocation: userData?.currentLocation || "",
      expectedCtc: userData?.expectedCtc || "",
    });

    // If a profile image exists, set it in the state
    if (userData?.profileImageUrl) {
      setProfileImage(userData?.profileImageUrl);
    }
  }, [userData]);

  const formatDate = (date) => {
    if (!date) return "";
    const formattedDate = new Date(date);
    return formattedDate.toLocaleDateString("en-US");
  };

  const updateBio = (newBio) => {
    setUserData((prev) => ({ ...prev, bio: newBio })); // Update state
  };

  // const updateSummary = (newSummary) => {
  //   setUserData((prev) => ({ ...prev, summary: newSummary })); // Update state
  // };

  useEffect(() => {
    if (token) {
      const getUserDetails = async () => {
        setIsLoading(true); // Start loading
        try {
          const response = await getUserApi(userId, token);
          setUserData(response?.user);
        } catch (error) {
          console.log(error.message);
        } finally {
          setIsLoading(false); // Stop loading
        }
      };
      getUserDetails();
    }
  }, [token, userId]);

  const handleAddSkill = () => {
    if (skillInput.trim() !== "") {
      const updatedSkills = Array.from(
        new Set([...(formData.skills || []), skillInput.trim()])
      ); // Prevent duplicates
      setFormData((prev) => ({ ...prev, skills: updatedSkills }));
      setSkillInput(""); // Clear input field
    }
    setOpenDialog(true); // Open dialog instead of directly adding skill
  };
  // Remove skill handler
  // const handleRemoveSkill = (index) => {
  //   const updatedSkills = skills.filter((_, i) => i !== index);
  //   setSkills(updatedSkills);
  //   setFormData((prev) => ({
  //     ...prev,
  //     skills: updatedSkills.join(","), // Convert array to string for formData
  //   }));
  // };

  // This method will be called when you upload a new image
  // const handleImageUpload = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     // Set the image preview immediately
  //     const imageURL = URL.createObjectURL(file);
  //     setProfileImage(imageURL); // Instantly update the preview

  //     // Set the form data for the file
  //     setFormData((prevData) => ({
  //       ...prevData,
  //       profileImage: file, // Save the file object to form data
  //     }));
  //   }
  // };

  const handleEdit = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target; // ✅ Correct destructuring
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // const [newLanguage, setNewLanguage] = useState("");
  const handleTotalExpChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      totalExpInYears: e.target.value,
    }));
  };

  const handleTotalExpInMonthsChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      totalExpInMonths: e.target.value,
    }));
  };

  const handleSubmit = async (updatedFields) => {
    const authToken = localStorage.getItem("authToken");
    const formDataToSubmit = new FormData();

    for (const key in updatedFields) {
      if (updatedFields[key]) {
        formDataToSubmit.append(key, updatedFields[key]);
      }
    }

    // if (email) {
    //   formDataToSubmit.append("email", email);
    // }

    // Append all form data except skills
    for (const key in formData) {
      if (key !== "skills" && key !== "email") {
        formDataToSubmit.append(key, formData[key]);
      }
    }

    // Append profile image if it exists
    if (profileImage instanceof File) {
      formDataToSubmit.append("profileImage", profileImage);
    }

    // Append skills[] values if it's an array
    if (Array.isArray(formData.skills)) {
      formData.skills.forEach((skill) => {
        formDataToSubmit.append("skills[]", skill); // Ensure skills are sent as "skills[]:React.Js"
      });
    }

    try {
      const response = await fetch(`${baseURL}/updateUser`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        body: formDataToSubmit,
      });

      if (response.ok) {
        const updatedData = await response.json();
        setUserData(updatedData.updatedUser);
        setOpenModal(false);
        setOpenDialog(false);
        handleSuccess("Profile updated successfully");
      } else {
        console.error("Failed to update user data");
        handleError("Failed to update profile");
      }
    } catch (error) {
      console.error(error);
      handleError("Error updating profile");
    }
  };

  const handleOtpKeyDown = (e, idx) => {
    // Move to the previous field on backspace if the current field is empty
    if (e.key === "Backspace" && !otp[idx] && idx > 0) {
      inputRefs.current[idx - 1]?.focus();
    }
  };

  const handleOtpPaste = (e, idx) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text").trim();
    // Allow only numbers and ensure the length matches the OTP length (4 digits)
    if (!/^[0-9]+$/.test(pastedData) || pastedData.length !== otp.length)
      return;

    const newOtp = pastedData.split("").slice(0, otp.length);
    setOtp(newOtp);

    // Focus the last field after pasting
    inputRefs.current[otp.length - 1]?.focus();
  };

  const handleOtpChange = (e, index) => {
    const value = e.target.value;

    // Allow only numeric input
    if (/^\d*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move to the next input field if a digit is entered
      if (value && index < 3) {
        inputRefs.current[index + 1]?.focus();
      }
    }

    // Handle backspace
    if (e.key === "Backspace" && !value && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleVerifyOtp = async (otp) => {
    const token = localStorage.getItem("authToken")?.replace(/"/g, "");

    try {
      const response = await fetch(`${baseURL}/verifyEmailPhoneOtp`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: formData.email, otp }),
      });

      const data = await response.json();
      if (response.ok) {
        setOtpVerified(true);
        setUserData(data?.user);
        handleSuccess("OTP verified successfully!");
      } else {
        handleError(data.message);
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      handleError("Error verifying OTP");
    }
  };

  const handleSendVerifyOtp = async (otpArray) => {
    const token = localStorage.getItem("authToken")?.replace(/"/g, "");

    // Convert OTP array to string
    const otpString = otpArray.join("");

    try {
      const response = await fetch(`${baseURL}/verifyEmailPhoneOtp`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: formData.email, otp: otpString }), // Send OTP as a string
      });

      const data = await response.json();
      if (response.ok) {
        setOtpSent(true);
        setOtpVerified(true);
        setUserData(data?.user);
        handleSuccess("OTP verified successfully!");
      } else {
        handleError(data.message || "Failed to verify OTP");
      }
    } catch (error) {
      handleError("Error verifying OTP");
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file); // Generate blob URL for preview
      setFormData({ ...formData, profileImage: file, previewImage: imageUrl });
    }
  };

  const handleDeleteSkill = (skillToDelete) => {
    const updatedSkills = formData.skills.filter(
      (skill) => skill !== skillToDelete
    );
    setFormData((prev) => ({ ...prev, skills: updatedSkills }));
  };

  const handleSendOtp = async (email) => {
    const token = localStorage.getItem("authToken")?.replace(/"/g, "");

    try {
      const response = await fetch(`${baseURL}/emailPhoneOtp`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }), // Use the updated email
      });

      const data = await response.json();
      if (response.ok) {
        setOtpSent(true);
        handleSuccess("OTP sent successfully!");
      } else {
        handleError(data.message || "Failed to send OTP");
      }
    } catch (error) {
      handleError("Error sending OTP");
    }
  };

  const handleSendPhoneOtp = async (phone) => {
    const token = localStorage.getItem("authToken")?.replace(/"/g, "");

    try {
      const response = await fetch(`${baseURL}/emailPhoneOtp`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ phone }), // Use the updated email
      });

      const data = await response.json();
      if (response.ok) {
        setOtpSent(true);
        handleSuccess("OTP sent successfully!");
      } else {
        handleError(data.message || "Failed to send OTP");
      }
    } catch (error) {
      handleError("Error sending OTP");
    }
  };

  useEffect(() => {
    const getProfilePercentage = async () => {
      try {
        const response = await getProfilePercentageApi(userId, token);
        setProfilePercentage(response);
      } catch (error) {
        console.log(error.message);
      }
    };
    getProfilePercentage();
  }, [token, userId]);

  const getProgressColor = (percentage) => {
    if (percentage <= 30) {
      return "#ff4444"; // Red for 0-30%
    } else if (percentage <= 70) {
      return "#ffbb33"; // Orange for 31-70%
    } else {
      return "#00C851"; // Green for 71-100%
    }
  };

  const fetchCities = useMemo(
    () =>
      debounce(async (query) => {
        try {
          if (query.trim().length >= 2) {
            const response = await getTopCitiesApi(query);
            if (response && response.cities) {
              setLocations(response.cities);
            }
          } else {
            setLocations([]);
          }
        } catch (error) {
          console.log(error.message);
        }
      }, 300),
    []
  );

  useEffect(() => {
    fetchCities(inputValue);

    return () => fetchCities.clear();
  }, [inputValue, fetchCities]);

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSkillInput(""); // Reset input when closing
  };

  const designation =
    userData?.preferredJobTitle?.trim() ||
    (userData?.experiences?.length > 0 &&
      userData.experiences[0]?.designation?.trim()) ||
    (userData?.experiences?.length > 1 &&
      userData.experiences[1]?.designation?.trim()) ||
    "No Designation";

  const company =
    userData?.experiences?.length > 0 &&
    userData.experiences[0]?.company?.trim()
      ? `at ${userData.experiences[0].company}`
      : userData?.experiences?.length > 1 &&
        userData.experiences[1]?.company?.trim()
      ? `at ${userData.experiences[1].company}`
      : "";

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <img src={LoadingImageGif} alt="Loading..." width={150} />
      </Box>
    );
  }

  return (
    <Box>
      {/* profile details section */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: 2,
          margin: "20px auto",
          fontFamily: "IBM Plex Sans", // Apply IBM Plex Sans globally
        }}
      >
        <Paper
          sx={{
            padding: { xs: "15px", sm: "20px", md: "30px" },
            minWidth: { xs: "100%", sm: "80%", md: "765px" },
            borderRadius: "15px",
            maxWidth: "100%",
            margin: "20px auto",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)", // Add a subtle shadow
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          variant="outlined"
          elevation={0}
        >
          {profilePercentage?.missingFields.length > 0 && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexWrap="wrap"
              sx={{
                backgroundColor: "#fff4e5", // Light orange background
                borderRadius: "10px", // Rounded corners
                padding: "8px 12px", // Add padding
                border: "1px solid #ffcc80", // Soft border
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Light shadow
                width: "70%",
                marginBottom: "25px",
              }}
            >
              <span
                style={{
                  fontSize: "13px",
                  marginLeft: "30px",
                  marginTop: "8px",
                  marginRight: "10px",
                  fontWeight: "500",
                }}
              >
                Please complete your profile by providing the following details
                :{" "}
              </span>{" "}
              <Box display="flex" flexWrap="wrap" gap={1} mt={1}>
                {profilePercentage.missingFields.map((item, index) => (
                  <Box
                    key={index}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <FiberManualRecordIcon
                      sx={{
                        width: "8px",
                        height: "8px",
                        marginRight: "4px",
                        color: "#423bfa",
                      }}
                    />
                    <Typography
                      variant="caption"
                      sx={{ fontSize: "0.75rem", color: "#423bfa" }}
                    >
                      {item.replace(/\b\w/g, (char) => char.toUpperCase())}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
          <Grid container spacing={3} alignItems="center">
            {/* Avatar Section */}
            <Grid item xs={12} sm={3} md={2} textAlign="center">
              <Box
                sx={{
                  position: "relative",
                  display: "inline-block",
                  width: 80,
                  height: 80,
                }}
              >
                {/* Circular Progress Bar */}
                <CircularProgress
                  variant="determinate"
                  value={profilePercentage?.profilePercentage}
                  size={92}
                  thickness={3}
                  sx={{
                    position: "absolute",
                    top: -6,
                    right: 0,
                    left: -6.6,
                    transform: "translate(-50%, -50%)",
                    color: getProgressColor(
                      profilePercentage?.profilePercentage
                    ),
                  }}
                />

                {/* Avatar */}
                <Avatar
                  sx={{
                    width: 80,
                    height: 80,
                    margin: "0 auto",
                    boxShadow: "0 2px 6px rgba(0,0,0,0.2)",
                    position: "relative",
                  }}
                  src={profileImage || userData?.profileImage}
                >
                  {!profileImage && formData.fullName.charAt(0)}
                </Avatar>
                <Typography
                  sx={{
                    color: `${getProgressColor(
                      profilePercentage?.profilePercentage
                    )}`,
                    fontFamily: "IBM Plex Sans",
                    fontWeight: "bold",
                    mt: 1,
                    fontSize: "20px",
                    ml: 2,
                  }}
                >
                  {profilePercentage?.profilePercentage}%
                </Typography>
              </Box>
            </Grid>

            {/* User Information */}
            <Grid item xs={12} sm={9} md={10}>
              <Box display="flex" flexDirection="column">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  mb={1}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      marginRight: "8px",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    {formData.fullName}
                  </Typography>
                  <Tooltip title="Edit Profile">
                    <Button
                      onClick={handleEdit}
                      sx={{
                        backgroundColor: "black",
                        color: "white",
                        borderRadius: "0.7rem",
                        textTransform: "none",
                        fontFamily: "IBM Plex Sans",
                        fontWeight: "bold",
                        padding: "8px 16px",
                        "&:hover": {
                          backgroundColor: "#333",
                        },
                      }}
                    >
                      Edit
                    </Button>
                  </Tooltip>
                </Box>

                <Typography
                  variant="body2"
                  color="textSecondary"
                  display="flex"
                  alignItems="center"
                  sx={{ fontFamily: "IBM Plex Sans" }}
                >
                  <BusinessCenterOutlinedIcon
                    sx={{ mr: 1, color: "#7B7B7BFF" }}
                  />
                  {designation} {company}
                </Typography>

                <Typography
                  variant="body2"
                  color="textSecondary"
                  display="flex"
                  alignItems="center"
                  mt={1}
                  sx={{ fontFamily: "IBM Plex Sans" }}
                >
                  <PlaceOutlinedIcon sx={{ mr: 1, color: "#7B7B7BFF" }} />
                  {formData.currentLocation || "Location not available"}
                </Typography>
              </Box>
            </Grid>
          </Grid>

          {/* Additional Fields Section */}
          <Box mt={4}>
            <Grid container spacing={2}>
              {[
                "phone",
                "email",
                "dob",
                "gender",
                "currentLocation",
                "expectedCtc",
              ].map((field, index) => (
                <Grid item xs={12} sm={4} md={6} key={index}>
                  <Typography
                    variant="subtitle2"
                    color="textSecondary"
                    gutterBottom
                    sx={{ fontFamily: "IBM Plex Sans" }}
                  >
                    {
                      // Custom field labels
                      field === "email"
                        ? "Email ID"
                        : field === "dob"
                        ? "Date of Birth"
                        : field === "currentLocation"
                        ? "Current Location"
                        : field === "expectedCtc"
                        ? "Expected CTC"
                        : field.charAt(0).toUpperCase() + field.slice(1)
                    }
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: "IBM Plex Sans",
                      display: "flex",
                      alignItems: "center",
                      gap: 1, // Adjust spacing between elements
                    }}
                  >
                    {field === "email" && formData[field] ? (
                      <>
                        {formData?.email}
                        <EmailEdit
                          initialEmail={formData?.email}
                          handleVerifyOtp={handleVerifyOtp}
                          handleSendOtp={handleSendOtp}
                        />
                        {otpVerified && (
                          <CheckCircleIcon sx={{ color: "green" }} />
                        )}
                      </>
                    ) : field === "phone" ? (
                      <>
                        {formData[field] || "Add Phone"}
                        <PhoneEdit
                          initialPhone={formData?.phone}
                          handleVerifyOtp={handleVerifyOtp}
                          handleSendPhoneOtp={handleSendPhoneOtp}
                        />
                      </>
                    ) : field === "dob" ? (
                      formData[field] ? (
                        formatDate(formData[field])
                      ) : (
                        "Add Date of Birth"
                      )
                    ) : field === "gender" ? (
                      formData[field] ? (
                        formData[field].charAt(0).toUpperCase() +
                        formData[field].slice(1)
                      ) : (
                        "Add Gender"
                      )
                    ) : (
                      formData[field] || `-`
                    )}
                  </Typography>

                  {field === "email" && !otpVerified && !otpSent && (
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => handleSendOtp(formData?.email)}
                      sx={{
                        mt: 1,
                        width: "100%",
                        fontFamily: "IBM Plex Sans",
                        borderRadius: "0.7rem",
                        textTransform: "none",
                        fontWeight: "bold",
                        "&:hover": {
                          backgroundColor: "#f0f0f0",
                        },
                      }}
                    >
                      Verify Email
                    </Button>
                  )}
                  {field === "email" && otpSent && !otpVerified && (
                    <Box mt={2}>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          mb: 1,
                          color: "text.secondary",
                          fontFamily: "IBM Plex Sans",
                        }}
                      >
                        Enter OTP sent to your email:
                      </Typography>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        gap={1.5}
                        mt={1}
                      >
                        {otp.map((digit, idx) => (
                          <TextField
                            key={idx}
                            value={digit}
                            onChange={(e) => handleOtpChange(e, idx)}
                            onKeyDown={(e) => handleOtpKeyDown(e, idx)}
                            onPaste={(e) => handleOtpPaste(e, idx)}
                            inputProps={{
                              maxLength: 1,
                              style: {
                                textAlign: "center",
                                fontSize: "20px",
                                fontWeight: "bold",
                              },
                              inputMode: "numeric", // Ensures numeric keyboard on mobile
                              pattern: "[0-9]*", // Restricts to numbers
                            }}
                            variant="outlined"
                            inputRef={(el) => (inputRefs.current[idx] = el)} // Assign ref for focusing
                            sx={{
                              width: 50,
                              height: 50,
                              fontFamily: "IBM Plex Sans",
                              "& .MuiOutlinedInput-root": {
                                borderRadius: "10px",
                                "& input": {
                                  textAlign: "center",
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                },
                                "& fieldset": {
                                  borderColor: "#ccc",
                                },
                                "&:hover fieldset": {
                                  borderColor: "#423bfa",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "#423bfa",
                                },
                              },
                            }}
                          />
                        ))}
                      </Box>
                      <Box mt={2} textAlign="center">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleSendVerifyOtp(otp)}
                          sx={{
                            borderRadius: "20px",
                            textTransform: "none",
                            fontWeight: "bold",
                            padding: "8px 24px",
                            backgroundColor: "#423bfa",
                            fontFamily: "IBM Plex Sans",
                            "&:hover": {
                              backgroundColor: "#362fd9",
                            },
                          }}
                          startIcon={<CheckCircleIcon />}
                        >
                          Verify OTP
                        </Button>
                      </Box>
                      <Box mt={1} textAlign="center">
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          sx={{ fontFamily: "IBM Plex Sans" }}
                        >
                          Didn’t receive the OTP?{" "}
                          <Button
                            variant="text"
                            onClick={handleSendOtp}
                            sx={{
                              textTransform: "none",
                              color: "#423bfa",
                              fontWeight: "bold",
                              fontFamily: "IBM Plex Sans",
                              "&:hover": {
                                backgroundColor: "transparent",
                              },
                            }}
                          >
                            Resend OTP
                          </Button>
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Grid>
              ))}
            </Grid>
          </Box>
        </Paper>
      </Box>

      {/* total exp */}

      <BioModal
        bio={userData?.bio}
        onUpdateBio={updateBio}
        handleSubmit={handleSubmit}
      />

      <Paper
        sx={{
          padding: "20px",
          margin: "0 auto",
          display: "flex",
          justifyContent: "space-between",
          borderRadius: "15px",
          marginTop: 1,
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
          cursor: "pointer", // Indicate clickable area
          transition: "background-color 0.3s ease", // Smooth hover effect
          "&:hover": {
            backgroundColor: "#f5f5f5", // Light hover effect
          },
          fontFamily: "IBM Plex Sans", // Apply IBM Plex Sans globally
        }}
        onClick={handleEdit}
        variant="outlined"
        elevation={0} // Remove default elevation for a cleaner look
      >
        <Typography
          sx={{
            fontWeight: "bold",
            color: "gray",
            fontFamily: "IBM Plex Sans", // Ensure consistent font
          }}
        >
          Total Year of Experience:{" "}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "18px",
              ml: 4,
              fontFamily: "IBM Plex Sans", // Ensure consistent font
            }}
          >
            {formData.totalExpInYears && `${formData.totalExpInYears} years `}
            {formData.totalExpInMonths && `${formData.totalExpInMonths} months`}
          </Typography>

          <ArrowForwardIosIcon
            sx={{
              cursor: "pointer",
              color: "gray", // Match the color scheme
              transition: "transform 0.3s ease", // Add hover animation
              "&:hover": {
                transform: "translateX(4px)", // Move icon slightly on hover
              },
            }}
          />
        </Box>
      </Paper>

      {/* Notice period */}
      <Paper
        sx={{
          padding: "20px",
          margin: "0 auto",
          display: "flex",
          justifyContent: "space-between",
          borderRadius: "15px",
          marginTop: 1,
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
          cursor: "pointer", // Indicate clickable area
          transition: "background-color 0.3s ease", // Smooth hover effect
          "&:hover": {
            backgroundColor: "#f5f5f5", // Light hover effect
          },
          fontFamily: "IBM Plex Sans", // Apply IBM Plex Sans globally
        }}
        onClick={handleEdit}
        variant="outlined"
        elevation={0} // Remove default elevation for a cleaner look
      >
        <Typography
          sx={{
            fontWeight: "bold",
            color: "gray",
            fontFamily: "IBM Plex Sans", // Ensure consistent font
          }}
        >
          Notice Period:
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              ml: 2,
              fontFamily: "IBM Plex Sans", // Ensure consistent font
            }}
          >
            {formData.noticePeriod}
          </Typography>
          <ArrowForwardIosIcon
            sx={{
              cursor: "pointer",
              color: "gray", // Match the color scheme
              transition: "transform 0.3s ease", // Add hover animation
              "&:hover": {
                transform: "translateX(4px)", // Move icon slightly on hover
              },
            }}
          />
        </Box>
      </Paper>

      <Box sx={{ backgroundColor: "#f9f9f9", marginTop: 1 }}>
        <Paper
          sx={{
            padding: "20px",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column", // Ensure the content is arranged in columns
            borderRadius: "15px",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
            cursor: "pointer", // Indicate clickable area
            transition: "background-color 0.3s ease", // Smooth hover effect
            "&:hover": {
              backgroundColor: "#f5f5f5", // Light hover effect
            },
            fontFamily: "IBM Plex Sans", // Apply IBM Plex Sans globally
          }}
          variant="outlined"
          elevation={0} // Remove default elevation for a cleaner look
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center", // Align items vertically
              marginBottom: 2,
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                color: "gray",
                fontSize: "16px",
                fontFamily: "IBM Plex Sans", // Ensure consistent font
              }}
            >
              Skills:
            </Typography>
            <Typography
              variant="body1"
              color="primary"
              onClick={handleAddSkill}
              sx={{
                cursor: "pointer",
                fontWeight: "bold",
                marginRight: 2,
                fontFamily: "IBM Plex Sans", // Ensure consistent font

                color: "blue", // White text
                padding: "8px 16px", // Add padding
                borderRadius: "0.7rem", // Rounded corners
                transition: "background-color 0.3s ease", // Smooth hover transition
              }}
            >
              + Add
            </Typography>
          </Box>

          {/* Container for skills */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center", // Align items on the same line
              gap: 2,
              flexWrap: "wrap", // Wrap skills to the next line if necessary
            }}
          >
            {userData?.skills?.length > 0 ? (
              userData?.skills?.map((skill, index) => (
                <Chip
                  key={index}
                  label={skill}
                  sx={{
                    borderRadius: "15px",
                    backgroundColor: "#e0e0e0",
                    color: "black",
                    fontSize: "0.875rem",
                    padding: "4px 12px",
                    fontFamily: "IBM Plex Sans", // Ensure consistent font
                    "&:hover": {
                      backgroundColor: "#d0d0d0", // Add hover effect for chips
                    },
                  }}
                />
              ))
            ) : formData?.skills?.length > 0 ? (
              formData?.skills?.map((skill, index) => (
                <Chip
                  key={index}
                  label={skill}
                  sx={{
                    borderRadius: "15px",
                    backgroundColor: "#e0e0e0",
                    color: "black",
                    fontSize: "0.875rem",
                    padding: "4px 12px",
                    fontFamily: "IBM Plex Sans", // Ensure consistent font
                    "&:hover": {
                      backgroundColor: "#d0d0d0", // Add hover effect for chips
                    },
                  }}
                />
              ))
            ) : (
              <Typography
                sx={{
                  color: "gray",
                  fontStyle: "italic",
                  fontFamily: "IBM Plex Sans", // Ensure consistent font
                }}
              >
                No skills added yet.
              </Typography>
            )}
          </Box>
        </Paper>
      </Box>
      {/* Skills Dialog */}
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="add-skill-dialog"
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "15px",
            fontFamily: "IBM Plex Sans",
          },
          "& .MuiDialogContent-root": {
            "&::-webkit-scrollbar": {
              display: "none",
            },
            scrollbarWidth: "none",
          },
        }}
      >
        <DialogTitle
          id="add-skill-dialog"
          sx={{ fontFamily: "IBM Plex Sans", fontWeight: "bold" }}
        >
          {formData?.skills?.length > 0 ? "Update Skill" : "Add New Skill"}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mb: 2 }}>
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", mb: 1, fontFamily: "IBM Plex Sans" }}
            >
              Skills
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column", // Changed to column for better space management
                gap: 1,
                backgroundColor: "#f9f9f9",
                borderRadius: "10px",
                padding: "8px",
                maxHeight: "200px", // Limit height to prevent overflow
                overflowY: "auto", // Add scroll if too many skills
              }}
            >
              {/* Display existing skills */}
              {formData.skills?.length > 0 && (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 1,
                    mb: 1,
                  }}
                >
                  {formData.skills.map((skill, index) => (
                    <Chip
                      key={index}
                      label={skill}
                      onDelete={() => handleDeleteSkill(skill)}
                      size="small"
                      sx={{
                        fontSize: "0.75rem",
                        height: "24px",
                        maxWidth: "150px", // Limit chip width
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    />
                  ))}
                </Box>
              )}
              {/* Skill input */}
              <TextField
                fullWidth
                variant="outlined"
                placeholder={
                  formData.skills?.length >= 10
                    ? "Max 10 skills reached"
                    : "Enter a skill (max 20 chars)"
                }
                value={skillInput}
                onChange={(e) => {
                  const value = e.target.value;
                  // Allow letters, numbers, spaces, and hyphens; max 20 chars
                  if (
                    value.length <= 20 &&
                    (value === "" || /^[A-Za-z0-9\s-]+$/.test(value))
                  ) {
                    setSkillInput(value);
                  }
                }}
                onKeyDown={(e) => {
                  if (
                    e.key === "Enter" &&
                    skillInput.trim() !== "" &&
                    formData.skills?.length < 10 // Max 10 skills
                  ) {
                    e.preventDefault();
                    handleAddSkill();
                  }
                }}
                disabled={formData.skills?.length >= 10} // Disable if max reached
                error={skillInput && !/^[A-Za-z0-9\s-]+$/.test(skillInput)}
                helperText={
                  skillInput && !/^[A-Za-z0-9\s-]+$/.test(skillInput)
                    ? "Use letters, numbers, spaces, or hyphens only"
                    : formData.skills?.length >= 10
                    ? "Maximum 10 skills allowed"
                    : `${skillInput.length}/20 characters`
                }
                InputProps={{
                  style: { fontFamily: "IBM Plex Sans", fontSize: "0.875rem" },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px",
                  },
                }}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            padding: "16px",
            justifyContent: "flex-end",
          }}
        >
          <Button
            onClick={handleDialogClose}
            sx={{
              borderRadius: "0.7rem",
              textTransform: "none",
              fontWeight: "bold",
              fontFamily: "IBM Plex Sans",
              backgroundColor: "black",
              color: "white",
              padding: "8px 16px",
              "&:hover": {
                backgroundColor: "#333",
              },
            }}
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{
              borderRadius: "10px",
              textTransform: "none",
              fontFamily: "IBM Plex Sans",
              fontWeight: "bold",
              backgroundColor: "#423bfa",
              "&:hover": {
                backgroundColor: "#362fd9",
              },
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {/* 
      <SummaryModal
        summary={userData?.description}
        onUpdatesummary={updateSummary}
        handleSubmit={handleSubmit}
      /> */}

      <Dialog
        open={openModal}
        onClose={handleModalClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="edit-personal-details-dialog"
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "15px", // Rounded corners for the dialog
            fontFamily: "IBM Plex Sans", // Apply IBM Plex Sans globally
          },
        }}
      >
        <DialogTitle
          id="edit-personal-details-dialog"
          sx={{ fontFamily: "IBM Plex Sans", fontWeight: "bold" }}
        >
          <b>Edit Personal Details</b>
        </DialogTitle>
        <DialogContent dividers>
          {/* First Name Field */}
          <Box sx={{ mb: 2 }}>
            <Box
              sx={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100px", // Adjust width as needed
                height: "100px", // Adjust height as needed
                margin: "0 auto",
              }}
            >
              <Avatar
                sx={{ width: 80, height: 80 }}
                src={formData.previewImage || formData.profileImage || ""}
              />

              <IconButton
                onClick={() =>
                  document.getElementById("profileImageInput").click()
                }
                sx={{
                  position: "absolute",
                  bottom: "30%",
                  left: "100%",
                  transform: "translate(-50%, 50%)", // Centers the button inside the div
                  bgcolor: "white",
                  padding: "4px",
                  boxShadow: 2, // Adds a slight shadow effect
                }}
              >
                <EditIcon />
              </IconButton>

              <input
                id="profileImageInput"
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
            </Box>

            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", mb: 1, fontFamily: "IBM Plex Sans" }}
            >
              First Name
            </Typography>
            <TextField
              fullWidth
              name="fullName"
              value={formData.fullName || ""} // Fallback to empty string if undefined
              onChange={(e) => {
                const value = e.target.value;
                // Allow letters, spaces, hyphens, and apostrophes; max 50 chars
                if (
                  value === "" ||
                  (value.length <= 50 && /^[A-Za-z\s'-]+$/.test(value))
                ) {
                  handleInputChange(e);
                }
              }}
              variant="outlined"
              placeholder="e.g., John Doe" // Added for guidance
              error={
                formData.fullName && !/^[A-Za-z\s'-]+$/.test(formData.fullName)
              }
              inputProps={{
                maxLength: 50, // Reasonable limit for full names
              }}
              sx={{
                backgroundColor: "#f9f9f9",
                borderRadius: "10px",
                fontFamily: "IBM Plex Sans",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "10px",
                  "& fieldset": {
                    borderColor: "#e0e0e0",
                  },
                  "&:hover fieldset": {
                    borderColor: "#423bfa",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#423bfa",
                  },
                },
              }}
            />
          </Box>

          {/* Date of Birth Field */}
          <Box sx={{ mb: 2 }}>
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", mb: 1, fontFamily: "IBM Plex Sans" }}
            >
              Date of Birth
            </Typography>
            <TextField
              fullWidth
              name="dob"
              value={formData.dob ? formData.dob.split("T")[0] : ""} // Convert ISO date to YYYY-MM-DD
              onChange={(e) =>
                setFormData({ ...formData, dob: e.target.value })
              } // Update state correctly
              type="date"
              InputLabelProps={{ shrink: true }}
              // Prevent typing
              inputProps={{
                style: { cursor: "pointer" }, // Indicates it's clickable
              }}
              // Prevent keyboard popup on mobile
              onKeyDown={(e) => e.preventDefault()}
              // Optional: Prevent paste
              onPaste={(e) => e.preventDefault()}
              sx={{
                borderRadius: "8px",
                "& .MuiOutlinedInput-root": { borderRadius: "8px" },
                // Ensure the field still looks interactive
                "& .MuiOutlinedInput-input": { cursor: "pointer" },
                "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "inherit",
                  },
              }}
            />
          </Box>

          {/* Gender Selection Field */}
          <Box sx={{ mb: 2 }}>
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", mb: 1, fontFamily: "IBM Plex Sans" }}
            >
              Gender
            </Typography>
            <Select
              fullWidth
              name="gender"
              value={formData.gender || ""}
              onChange={handleInputChange}
              variant="outlined"
              sx={{
                backgroundColor: "#f9f9f9",
                borderRadius: "10px",
                fontFamily: "IBM Plex Sans",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "10px",
                  "& fieldset": {
                    borderColor: "#e0e0e0",
                  },
                  "&:hover fieldset": {
                    borderColor: "#423bfa",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#423bfa",
                  },
                },
              }}
            >
              <MenuItem value="male">Male</MenuItem>
              <MenuItem value="female">Female</MenuItem>
            </Select>
          </Box>

          {/* Notice Period Selection Field */}
          <Box sx={{ mb: 2 }}>
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", mb: 1, fontFamily: "IBM Plex Sans" }}
            >
              Notice Period
            </Typography>
            <Select
              fullWidth
              name="noticePeriod"
              value={String(formData.noticePeriod) || ""} // Convert number to string
              onChange={handleInputChange}
              variant="outlined"
              sx={{
                backgroundColor: "#f9f9f9",
                borderRadius: "10px",
                fontFamily: "IBM Plex Sans",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "10px",
                  "& fieldset": {
                    borderColor: "#e0e0e0",
                  },
                  "&:hover fieldset": {
                    borderColor: "#423bfa",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#423bfa",
                  },
                },
              }}
            >
              <MenuItem value="Any">Any</MenuItem>
              <MenuItem value="0-15 days">0-15 days</MenuItem>
              <MenuItem value="1 month">1 month</MenuItem>
              <MenuItem value="2 months">2 Months</MenuItem>
              <MenuItem value="3 months">3 Months</MenuItem>
              <MenuItem value="More than 3 months">More Than 3 Months</MenuItem>
              <MenuItem value="Currently in Notice period">
                Currently in Notice period
              </MenuItem>
            </Select>
          </Box>

          {/* Current Location Field with Autocomplete */}
          <Box sx={{ mb: 2 }}>
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", mb: 1, fontFamily: "IBM Plex Sans" }}
            >
              Current Location
            </Typography>
            <Autocomplete
              freeSolo
              options={locations?.map((city) => city.name)}
              value={formData?.currentLocation}
              onChange={(event, newValue) => {
                setFormData((prev) => ({
                  ...prev,
                  currentLocation: newValue || "",
                }));
              }}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Search for a city"
                  sx={{
                    backgroundColor: "#f9f9f9",
                    borderRadius: "10px",
                    fontFamily: "IBM Plex Sans",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",
                      "& fieldset": {
                        borderColor: "#e0e0e0",
                      },
                      "&:hover fieldset": {
                        borderColor: "#423bfa",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#423bfa",
                      },
                    },
                  }}
                />
              )}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", mb: 1, fontFamily: "IBM Plex Sans" }}
            >
              Total Years of Experience
            </Typography>
            <Select
              fullWidth
              value={formData.totalExpInYears}
              onChange={handleTotalExpChange}
              size="small"
              displayEmpty
              sx={{
                backgroundColor: "#f9f9f9",
                borderRadius: "10px",
                fontFamily: "IBM Plex Sans",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "10px",
                  "& fieldset": {
                    borderColor: "#e0e0e0",
                  },
                  "&:hover fieldset": {
                    borderColor: "#423bfa",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#423bfa",
                  },
                },
              }}
            >
              <MenuItem value="" disabled>
                Select Years
              </MenuItem>
              {[...Array(61).keys()].map((year) => (
                <MenuItem key={year} value={year}>
                  {year} {year === 1 ? "Year" : "Years"}
                </MenuItem>
              ))}
              <MenuItem value="60+">60+ Years</MenuItem>
            </Select>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", mb: 1, fontFamily: "IBM Plex Sans" }}
            >
              Total Months of Experience
            </Typography>
            <Select
              fullWidth
              value={formData.totalExpInMonths}
              onChange={handleTotalExpInMonthsChange}
              size="small"
              displayEmpty
              sx={{
                backgroundColor: "#f9f9f9",
                borderRadius: "10px",
                fontFamily: "IBM Plex Sans",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "10px",
                  "& fieldset": {
                    borderColor: "#e0e0e0",
                  },
                  "&:hover fieldset": {
                    borderColor: "#423bfa",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#423bfa",
                  },
                },
              }}
            >
              <MenuItem value="" disabled>
                Select Months
              </MenuItem>
              {[...Array(12).keys()].map((month) => (
                <MenuItem key={month} value={month}>
                  {month} {month === 1 ? "Month" : "Months"}
                </MenuItem>
              ))}
            </Select>
          </Box>

          <Box sx={{ mb: 2 }}>
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", mb: 1, fontFamily: "IBM Plex Sans" }}
            >
              Expected CTC
            </Typography>
            <TextField
              fullWidth
              name="expectedCtc"
              value={formData.expectedCtc || ""} // Fallback to empty string if undefined
              onChange={(e) => {
                const value = e.target.value;
                // Allow letters, spaces, hyphens, and apostrophes; max 50 chars
                if (
                  value === "" ||
                  /^\d{1,2}(\.)?$/.test(value) ||
                  /^\d{1,2}\.[0-9]$/.test(value)
                ) {
                  handleInputChange(e);
                }
              }}
              variant="outlined"
              placeholder="In LPA" // Added for guidance
             
              inputProps={{
                maxLength: 50, // Reasonable limit for full names
              }}
              sx={{
                backgroundColor: "#f9f9f9",
                borderRadius: "10px",
                fontFamily: "IBM Plex Sans",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "10px",
                  "& fieldset": {
                    borderColor: "#e0e0e0",
                  },
                  "&:hover fieldset": {
                    borderColor: "#423bfa",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#423bfa",
                  },
                },
              }}
            />
          </Box>

          {/* Skills Input (Free-form text) */}
          <Box sx={{ mb: 2 }}>
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", mb: 1, fontFamily: "IBM Plex Sans" }}
            >
              Skills
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                flexWrap: "wrap",
                backgroundColor: "#f9f9f9",
                borderRadius: "10px",
                padding: "8px",
              }}
            >
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Enter a skill"
                value={skillInput}
                onChange={(e) => setSkillInput(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && skillInput.trim() !== "") {
                    e.preventDefault(); // Prevent form submission
                    handleAddSkill();
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {formData.skills?.map((skill, index) => (
                        <Chip
                          key={index}
                          label={skill}
                          onDelete={() => handleDeleteSkill(skill)}
                          size="small"
                          sx={{ fontSize: "0.75rem", height: "24px" }}
                        />
                      ))}
                    </InputAdornment>
                  ),
                  style: { fontFamily: "IBM Plex Sans", fontSize: "0.875rem" },
                }}
              />

              {/* Add button */}
              <Button
                variant="contained"
                onClick={handleAddSkill} // Add skill on button click
                sx={{
                  borderRadius: "10px",
                  textTransform: "none",
                  fontFamily: "IBM Plex Sans",
                  fontWeight: "bold",
                  backgroundColor: "#423bfa",
                  "&:hover": {
                    backgroundColor: "#362fd9",
                  },
                }}
              >
                {formData?.skills?.length > 0 ? "Edit" : "Add"}
              </Button>
            </Box>
          </Box>

          {/* Profile Image Upload */}
          {/* <Box sx={{ mt: 3 }}>
            <Button
              variant="contained"
              color="primary"
              component="label"
              sx={{
                mb: 2,
                borderRadius: "0.7rem",
                textTransform: "none",
                fontFamily: "IBM Plex Sans",
                fontWeight: "bold",
                backgroundColor: "#423bfa",
                "&:hover": {
                  backgroundColor: "#362fd9",
                },
              }}
            >
              Upload Profile Image
              <input type="file" hidden onChange={handleImageUpload} />
            </Button>
            {profileImage ? (
              <Box sx={{ textAlign: "center", mt: 2 }}>
                <Typography
                  variant="body2"
                  sx={{ mb: 1, fontFamily: "IBM Plex Sans" }}
                >
                  Preview:
                </Typography>
                <Avatar
                  sx={{ width: 100, height: 100, margin: "0 auto" }}
                  src={profileImage}
                />
              </Box>
            ) : null}
          </Box> */}
        </DialogContent>

        <DialogActions sx={{ padding: 2 }}>
          <Button
            sx={{
              borderRadius: "0.7rem",
              textTransform: "none",
              fontWeight: "bold",
              fontFamily: "IBM Plex Sans",
              backgroundColor: "black",
              color: "white",
              "&:hover": {
                backgroundColor: "#333", // Darker hover effect
              },
            }}
            onClick={handleModalClose}
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            sx={{
              borderRadius: "0.7rem",
              textTransform: "none",
              fontWeight: "bold",
              fontFamily: "IBM Plex Sans",
              backgroundColor: "#423bfa",
              "&:hover": {
                backgroundColor: "#362fd9", // Darker hover effect
              },
            }}
            onClick={handleSubmit}
            variant="contained"
          >
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
      <PopupSuccess
        modelOpen={successOpen}
        onClose={() => setSuccessOpen(false)}
        message={successMessage}
      />
      <ReleaseJobPostModel
        modelOpen={errorOpen}
        onClose={() => setErrorOpen(false)}
        message={errorMessage}
      />
    </Box>
  );
};

export default JobSeekerPersonalDetails;
