import React, { memo, useState, useCallback } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  Box,
  TextField,
  IconButton,
  Tabs,
  Tab,
  Skeleton,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import CounsellorsChatList from "./CounsellorChatList";
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';

const JobSeekerToJobSeekerChatList = memo(
  ({ connectionsChatList, counsellors, counsellorsChatList, onChatSelect ,isLoading}) => {
    const userId = localStorage.getItem("userId");
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedTab, setSelectedTab] = useState(0); // 0 for Connections, 1 for Counsellors

    const handleTabChange = (event, newValue) => {
      setSelectedTab(newValue);
      setSearchQuery(""); // Reset search query when switching tabs
    };

    const handleChatSelect = useCallback(
      (chat) => {
        onChatSelect(chat); // Passing the full chat object
      },
      [onChatSelect]
    );

    const handleCounsellorSelect = useCallback(
      (counsellor) => {
        onChatSelect(counsellor); // Pass counsellor object to parent
      },
      [onChatSelect]
    );

    const filteredChatList = connectionsChatList?.filter(
      (chat) =>
        chat.lastMessage?.message
          ?.toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        chat.participants.some((p) =>
          (p.userInfo?.fullName || p.userInfo?.rcName || "User")
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        )
    );

    const renderLoadingSkeletons = () => {
      return Array(5).fill(0).map((_, index) => (
        <ListItem key={index} sx={{ padding: "12px 16px" }}>
          <ListItemAvatar>
            <Skeleton variant="circular" width={40} height={40} />
          </ListItemAvatar>
          <ListItemText
            primary={<Skeleton variant="text" width="60%" />}
            secondary={<Skeleton variant="text" width="40%" />}
          />
        </ListItem>
      ));
    };



    return (
      <Box
        sx={{
          width: "100%",
          zIndex: 1000,
          borderRadius: "12px",
          overflow: "hidden",
          background: "#fff",
          boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.1)",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          overflowY: "auto",
          "&::-webkit-scrollbar": { width: "0px" },
          "&::-webkit-scrollbar-thumb": { backgroundColor: "transparent" },
          scrollbarWidth: "none",
          "-ms-overflow-style": "none",
        }}
      >
        {/* Tabs for Connections and Counsellors */}
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          sx={{
            backgroundColor: "#f5f5f5",
            position: "sticky",
            top: 0,
            zIndex: 1002, // Higher than search bar
            "& .MuiTabs-indicator": {
              backgroundColor: "#423bfa",
            },
          }}
        >
          <Tab
            label="Connections"
            sx={{
              textTransform: "none",
              fontFamily: "IBM Plex Sans",
              fontWeight: "bold",
              color: selectedTab === 0 ? "#423bfa" : "#666",
              "&.Mui-selected": { color: "#423bfa" },
            }}
          />
          <Tab
            label="Counsellors"
            sx={{
              textTransform: "none",
              fontFamily: "IBM Plex Sans",
              fontWeight: "bold",
              color: selectedTab === 1 ? "#423bfa" : "#666",
              "&.Mui-selected": { color: "#423bfa" },
            }}
          />
        </Tabs>

        {/* Conditional Rendering Based on Tab */}
        {selectedTab === 0 ? (
          <>
            {/* Sticky Search Bar for Connections */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                position: "sticky",
                top: "36px", // Adjust for smaller tabs
                backgroundColor: "#f5f5f5",
                padding: "4px 6px",
                borderRadius: "10px",
                mb: 1,
                zIndex: 1001,
                border: "1px solid #e0e0e0",
                transition: "all 0.3s ease-in-out",
                minHeight: "38px",
                "&:hover": {
                  backgroundColor: "#e0e0e0",
                  borderRadius: "10px",
                  boxShadow: "0 0 4px rgba(0,0,0,0.2)",
                },
              }}
            >
              <Search sx={{ color: "black", fontWeight: "bold" }} />
              <TextField
                placeholder="Search messages"
                variant="standard"
                size="small"
                fullWidth
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    paddingX: "8px",
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "#374151",
                  },
                }}
                sx={{
                  flexGrow: 1,
                  "& .MuiInputBase-root": { backgroundColor: "transparent" },
                }}
              />
              <IconButton size="small">
                <PeopleOutlineIcon sx={{ color: "#000", fontSize: "24px" }} />
              </IconButton>
            </Box>

            {/* Connections Chat List */}
            <List sx={{ padding: 0, flexGrow: 1, backgroundColor: "#fff" }}>
  {isLoading ? (
    renderLoadingSkeletons()
  ) : filteredChatList?.length > 0 ? (
    filteredChatList.map((chat) => {
      // Find the participant that is NOT the current user
      const otherParticipant = chat.participants.find(
        (p) => p.userId !== userId
      );

      // If no other participant found (shouldn't happen in normal cases), use the first one
      const participant = otherParticipant || chat.participants[0];
      
      const receiverName =
        participant?.userInfo?.fullName ||
        participant?.userInfo?.rcName ||
        "User";
      const profileImage =
        participant?.userInfo?.profileImage ||
        participant?.userInfo?.organizationDetails?.companyLogo?.url ||
        null;

      return (
        <ListItem
          key={chat._id}
          onClick={() => handleChatSelect(chat)}
          sx={{
            padding: "2px 0px",
            transition: "background 0.2s",
            "&:hover": {
              backgroundColor: "#e0e0e0",
              cursor: "pointer",
            },
            borderBottom: "1px solid #e5e5e5",
            display: "flex",
            alignItems: "center",
          }}
        >
          <ListItemAvatar>
            <Avatar
              alt={receiverName}
              src={profileImage}
              sx={{ width: 42, height: 42 }}
            />
          </ListItemAvatar>
          <ListItemText
            primary={
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: "#000",
                    fontSize: "0.90rem",
                    fontWeight: 400,
                    fontFamily: "IBM Plex Sans",
                  }}
                >
                  {receiverName}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      color: "#6d6d6d",
                      fontSize: "0.65rem",
                    }}
                  >
                    {chat.lastMessageTimestamp
                      ? new Date(
                          chat.lastMessageTimestamp
                        ).toLocaleDateString("en-US", {
                          month: "short",
                          day: "numeric",
                        })
                      : ""}
                  </Typography>
                </Box>
              </Box>
            }
            secondary={
              <Typography
                variant="body2"
                sx={{
                  fontSize: "0.85rem",
                  color: "#666",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontFamily: "IBM Plex Sans",
                }}
              >
                {chat.lastMessage?.message || "No messages yet"}
              </Typography>
            }
          />
        </ListItem>
      );
    })
  ) : (
    <Typography
      variant="body2"
      sx={{
        textAlign: "center",
        color: "#666",
        mt: 2,
        fontFamily: "IBM Plex Sans",
      }}
    >
      No chats found
    </Typography>
  )}
</List>
          </>
        ) : (
          <CounsellorsChatList
            counsellors={counsellors}
            onCounsellorSelect={handleCounsellorSelect}
          />
        )}
      </Box>
    );
  }
);

export default JobSeekerToJobSeekerChatList;