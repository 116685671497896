import baseURL from "./baseUrl";
import { getUserIdFromToken } from "../utils/userAuth";

export const getJobSeekerDetails = async () => {
  const userId = await getUserIdFromToken();
  try {
    const response = await fetch(`${baseURL}/jobseeker/${userId}`);
    
    if (!response.ok) {
     
      throw new Error('Failed to fetch job seeker details');
    }

    const data = await response.json();
    
    return data.user;  
  } catch (error) {
    console.error("Error fetching job seeker details:", error);
    return null;  
  }
};


// export const getViewedJoseeker = async (orgId, token) => {
//   try {
//     const response = await fetch(
//       `${baseURL}/organization/${orgId}/getallrecruiters`,
//       {
//         method: "GET",
//         headers: {
//           Authorization: `Bearer ${token}`,
//           "Content-Type": "application/json",
//         },
//       }
//     );
//     if (!response.ok) {
//       throw new Error("network response was not ok");
//     }
//     const data = await response.json();
//     return data;
//   } catch (error) {
//     console.log(error.message);
//     throw error;
//   }
// };



export const getUnlockedCandidatesApi = async (orgId, token) => {
  try {
    const response = await fetch(
      `${baseURL}/organization/unlockedCandidates/${orgId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.ok) {
      throw new Error("network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
    throw error;
  }
};

export const saveProfilesApi = async (token, formValues) => {
  try {
    const response = await fetch(`${baseURL}/saveCandidate`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formValues),
    });
    const data = await response.json()
  
    if (!response) {
      throw new Error( data.message || "network response was not ok");
    }
    return data
  } catch (error) {
    console.log(error.message);
     throw error
  }
};
export const unSaveProfilesApi = async (token, formValues) => {
  try {
    const response = await fetch(`${baseURL}/unSaveCandidate`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formValues),
    });
    const data = await response.json()
  
    if (!response) {
      throw new Error( data.message || "network response was not ok");
    }
    return data
  } catch (error) {
    console.log(error.message);
     throw error
  }
};

export const getSimilarJobseekerApi = async (userId, token) => {
  try {
    const response = await fetch(
      `${baseURL}/rc/similarJobseekers/${userId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    if (!response.ok) {
      throw new Error("network response was not ok");
    }
 
    return data;
  } catch (error) {
    console.log(error.message);
    throw error;
  }
};