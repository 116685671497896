import React from "react";
import { Container, Box } from "@mui/material";
import NewNavBar from "../../../components/JobSeekerComponents/NavBar/NewNavbar";
import Footer from "../../../components/JobSeekerComponents/Footer/Footer";
import Hero from "./Hero";
// import HowToHire from "./HowToHire";
import Clients from "./Clients";
import EnterPrise from "./EnterPrise";
import WhyVacanzi from "./WhyVacanzi";
import FindWork from "./FindWork";

const AboutUs = () => {
  return (
    <>
      <NewNavBar />
      <Container width="lg" sx={{ mt:10}} >
        <Box mb={2}>
          <Hero />
        </Box>
        {/* <Box mb={2}>
          <HowToHire />
        </Box> */}
        <Box mb={2}>
          <EnterPrise />
        </Box>
        <Box mb={10}>
          <Clients />
        </Box>
        <Box mb={2}>
          <WhyVacanzi />
        </Box>
        <Box mb={10}>
          <FindWork />
        </Box>
        </Container>
      <Footer />
    </>
  );
};

export default AboutUs;
