import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextareaAutosize,
} from "@mui/material";

const BioModal = ({ bio, onUpdateBio, handleSubmit }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [bioInput, setBioInput] = useState(bio || ""); // Initialize with bio prop

  const handleDialogOpen = () => {
    setBioInput(bio || ""); // Set bio input to current bio when opening
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleSave = async () => {
    try {
      const res = await handleSubmit({ bio: bioInput });
      console.log('res', res);

      onUpdateBio(bioInput); // Update parent component
      setOpenDialog(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Box sx={{ backgroundColor: "#f9f9f9", marginTop: 1 }}>
        <Paper
          sx={{
            padding: "20px",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            borderRadius: "15px",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
            cursor: "pointer",
            transition: "background-color 0.3s ease",
            "&:hover": { backgroundColor: "#f5f5f5" },
            fontFamily: "IBM Plex Sans",
          }}
          variant="outlined"
          elevation={0}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 2,
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                color: "gray",
                fontSize: "16px",
                fontFamily: "IBM Plex Sans",
              }}
            >
              Bio:
            </Typography>
            <Typography
              variant="body1"
              color="primary"
              onClick={handleDialogOpen}
              sx={{
                cursor: "pointer",
                fontWeight: "bold",
                marginRight: 2,
                fontFamily: "IBM Plex Sans",
                color: "blue",
                padding: "8px 16px",
                borderRadius: "0.7rem",
                transition: "background-color 0.3s ease",
              }}
            >
              {bio ? <>
                <Button

                  sx={{
                    backgroundColor: "black",
                    color: "white",
                    borderRadius: "0.7rem",
                    textTransform: "none",
                    fontFamily: "IBM Plex Sans",
                    fontWeight: "bold",
                    padding: "8px 16px",
                    "&:hover": {
                      backgroundColor: "#333",
                    },
                  }}
                >
                  Edit
                </Button>
              </> :
                <>
                  <Button

                    sx={{
                      backgroundColor: "black",
                      color: "white",
                      borderRadius: "0.7rem",
                      textTransform: "none",
                      fontFamily: "IBM Plex Sans",
                      fontWeight: "bold",
                      padding: "8px 16px",
                      "&:hover": {
                        backgroundColor: "#333",
                      },
                    }}
                  >
                    + Add
                  </Button>
                </>
              }
            </Typography>
          </Box>

          {/* Bio Display */}
          <Box sx={{ marginBottom: 2 }}>
            {bio ? (
              <Typography
                sx={{
                  color: "black",
                  fontSize: "0.9rem",
                  fontFamily: "IBM Plex Sans",
                }}
              >
                {bio}
              </Typography>
            ) : (
              <Typography
                sx={{
                  color: "gray",
                  fontStyle: "italic",
                  fontFamily: "IBM Plex Sans",
                }}
              >
                No bio added yet.
              </Typography>
            )}
          </Box>
        </Paper>
      </Box>

      {/* Bio Dialog */}
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="bio-dialog"
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "15px",
            fontFamily: "IBM Plex Sans",
          },
          "& .MuiDialogContent-root": {
            "&::-webkit-scrollbar": { display: "none" },
            scrollbarWidth: "none",
          },
        }}
      >
        <DialogTitle
          id="bio-dialog"
          sx={{ fontFamily: "IBM Plex Sans", fontWeight: "bold" }}
        >
          {bio ? "Edit Bio" : "Add Bio"}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mb: 2 }}>

            <TextareaAutosize
              minRows={4}
              maxRows={8}
              placeholder="Write something about yourself..."
              value={bioInput}
              onChange={(e) => setBioInput(e.target.value)}
              style={{
                width: "100%",
                fontFamily: "IBM Plex Sans",
                fontSize: "0.9rem",
                padding: "10px",
                borderRadius: "8px",
                border: "1px solid #ccc",
                resize: "none",
              }}
              maxLength={250}
            />
            <p style={{ textAlign: "right", fontSize: "12px", color: "#888" }}>
              {(bioInput || "").length}/250 characters
            </p>
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: "16px", justifyContent: "flex-end" }}>
          <Button
            onClick={handleDialogClose}
            sx={{
              borderRadius: "0.7rem",
              textTransform: "none",
              fontWeight: "bold",
              fontFamily: "IBM Plex Sans",
              backgroundColor: "black",
              color: "white",
              padding: "8px 16px",
              "&:hover": { backgroundColor: "#333" },
            }}
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            variant="contained"
            sx={{
              borderRadius: "10px",
              textTransform: "none",
              fontFamily: "IBM Plex Sans",
              fontWeight: "bold",
              backgroundColor: "#423bfa",
              "&:hover": { backgroundColor: "#362fd9" },
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BioModal;
