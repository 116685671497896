import { CardContent, Grid, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getOrgDetails } from "../../../api/companyProfile";
import { useOrg } from "../../../context/OrgContext";


const SubscriptionSuccessPage = () => {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(3);
  const {setOrgData} = useOrg()
  const orgId = localStorage.getItem("organizationId");
  const token = localStorage.getItem("token");

    const fetchOrgDetails = useCallback(async () => {
      try {
        if (orgId) {
          const companyDetails = await getOrgDetails(orgId, token);
          setOrgData(companyDetails);
          console.log("orgdata fetchted");
        } else {
          throw new Error("No organization id");
        }
      } catch (error) {
        console.log(error.message);
      }
    }, [orgId, token, setOrgData]); // Dependencies of useCallback
  
    useEffect(() => {
      fetchOrgDetails();
    }, [fetchOrgDetails]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prev) => prev - 1);
    }, 1000);

    // Redirect after 3 seconds
    const redirectTimer = setTimeout(() => {
      navigate("/employerJobPosting"); // Change "/jobpost" to your actual route
    }, 3000);

    return () => {
      clearInterval(timer);
      clearTimeout(redirectTimer);
    };
  }, [navigate]);

  return (

      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          backgroundColor: "#ffffff",
          padding: 2,
          borderRadius: 4,
          height: "80vh",
        }}
      >
        <Grid item>
          <CardContent align="center">
            <Typography
              variant="h5"
              gutterBottom
              sx={{ fontFamily: "poppins" }}
            >
              ✨ Congratulations! ✨
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{ fontFamily: "poppins" }}
            >
              Your Free Plan Is Subscribed
            </Typography>
            <Typography
              variant="body2"
              sx={{ fontFamily: "poppins", marginTop: 2, color: "gray" }}
            >
              Redirecting to Job Post page in {countdown}...
            </Typography>
          </CardContent>
        </Grid>
      </Grid>
 
  );
};

export default SubscriptionSuccessPage;
