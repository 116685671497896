import React, { useState } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import JobSeekerConnect from "../../../components/JobSeekerComponents/JobSeekerNetwork/JobSeekerConnect";
import JobSeekerFollow from "../../../components/JobSeekerComponents/JobSeekerNetwork/JobSeekerFollow";
import NewNavBar from "../../../components/JobSeekerComponents/NavBar/NewNavbar";

const JobSeekerNetwork = () => {
  const [activeTab, setActiveTab] = useState(0); // State to manage active tab

  const users = [
    {
      name: "BINDU RANI",
      status: "Student at Selfeey Infotech",
      avatar: "https://via.placeholder.com/50", // Replace with actual images
    },
    {
      name: "Srayas ...",
      status: "Student at Selfeey Infotech",
      avatar: "",
    },
    {
      name: "Bhumika Singh",
      status: "Student at Selfeey Infotech",
      avatar: "",
    },
    {
      name: "Syed zabiulla",
      status: "Attended Selfeey Infotech",
      avatar: "https://via.placeholder.com/50",
    },
  ];

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue); // Update the active tab
  };

  return (
    <div>
      <NewNavBar />
      <Box sx={{ width: "100%", mt: 2 }}>
        {/* Tabs */}
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          centered
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: "#423bfa", // Custom indicator color
            },
          }}
        >
          <Tab
            label="Followers"
            sx={{
              textTransform: "none",
              fontWeight: "bold",
              color: activeTab === 0 ? "#423bfa" : "#000000", // Active tab text color
            }}
          />
          <Tab
            label="Following"
            sx={{
              textTransform: "none",
              fontWeight: "bold",
              color: activeTab === 1 ? "#423bfa" : "#000000", // Active tab text color
            }}
          />
        </Tabs>

        {/* Tab Content */}
        <Box sx={{ p: 3 }}>
          {activeTab === 0 && <JobSeekerConnect users={users} />}
          {activeTab === 1 && <JobSeekerFollow users={users} />}
        </Box>
      </Box>
    </div>
  );
};

export default JobSeekerNetwork;