import React, { useEffect, useState, useCallback } from "react";
import {
  Typography,
  Grid,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  getReleventJobSeekersApi,
  followCounsellorApi,
  unFollowCounsellorApi,
} from "../../../api/common";
import { FourSquare } from "react-loading-indicators";
import PopupSuccess from "../../../utils/popup/PopupSuccess";
import ReleaseJobPostModel from "../../../pages/EmployerPages/SubscriptionStatus/ReleaseJobPostModel";

const styles = {
  pageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
  },
  followButton: {
    textTransform: "none",
    py: 1,
    px: 2,
    borderRadius: 4,
    fontFamily: "satoshi",
    fontWeight: 600,
  },
};

const Simstyles = {
  container: {
    bgcolor: "rgb(247, 245, 238)",
    borderRadius: 10,
    p: 3,
    mb: 4,
  },
  title: {
    fontSize: "1.5rem",
    fontFamily: "Satoshi",
    fontWeight: 600,
    color: "#000",
  },
  gridContainer: {
    mt: 2,
  },
  profileCard: {
    width: "100%",
    height: 240, // Fixed height for consistency
    cursor: "pointer",
    bgcolor: "#fff",
    border: "1px solid",
    borderColor: "grey.300",
    borderRadius: 3,
    boxShadow: "none",
    "&:hover": {
      boxShadow: "0 2px 8px rgba(0,0,0,0.08)",
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  cardContent: {
    fontFamily: "Satoshi",
    padding: 2,
    "&:last-child": {
      paddingBottom: 2,
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 1,
    flexGrow: 1,
  },
  avatar: {
    width: 60,
    height: 60,
  },
  name: {
    fontSize: "0.9rem",
    fontFamily: "Satoshi",
    fontWeight: 600,
    color: "#000",
  },
  description: {
    fontSize: "0.75rem",
    fontFamily: "Satoshi",
    color: "rgba(0, 0, 0, 0.6)",
    lineHeight: 1.3,
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
};

const Follow = () => {
  const [relevantJobSeekers, setRelevantJobSeekers] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("userId");
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSuccess = useCallback((message) => {
    setSuccessMessage(message);
    setSuccessOpen(true);
    setTimeout(() => {
      setSuccessOpen(false);
      setSuccessMessage("");
    }, 2000);
  }, []);

  const handleError = useCallback((message) => {
    setErrorMessage(message);
    setErrorOpen(true);
    setTimeout(() => {
      setErrorOpen(false);
      setErrorMessage("");
    }, 2000);
  }, []);

  useEffect(() => {
    setLoading(true);
    const fetchCounsellors = async () => {
      try {
        const response = await getReleventJobSeekersApi(userId);
        setRelevantJobSeekers(response?.data || []);
      } catch (error) {
        console.log(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchCounsellors();
  }, [userId]);

  const handleNavigate = (id) => {
    navigate(`/counsellorProfile/${id}`);
  };

  const handleFollowToggle = async (person) => {
    const followData = {
      followingUserId: person._id,
      followerUserId: userId,
    };

    try {
      let response;
      if (person.isFollowing) {
        response = await unFollowCounsellorApi(token, followData);
      } else {
        response = await followCounsellorApi(token, followData);
      }

      setRelevantJobSeekers((prevJobSeekers) =>
        prevJobSeekers.map((jobSeeker) =>
          jobSeeker._id === person._id
            ? { ...jobSeeker, isFollowing: !jobSeeker.isFollowing }
            : jobSeeker
        )
      );
      handleSuccess(
        response.message ||
          (person.isFollowing
            ? "Successfully unfollowed"
            : "Successfully followed")
      );
    } catch (error) {
      handleError(error.message || "An unexpected error occurred.");
    }
  };

  return (
    <>
      {loading ? (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            zIndex: 9999,
            backgroundColor: "#FFFFFF",
          }}
        >
          <FourSquare color="#423bfa" size="small" text="" textColor="" />
          <Typography sx={{ fontFamily: "poppins", mt: 1 }}>
            Fetching Relevant JobSeekers for you ....
          </Typography>
        </Box>
      ) : (
        <Box sx={styles.pageContainer}>
          <Container maxWidth="xl">
            <Box sx={Simstyles.container}>
              <Typography variant="h4" sx={Simstyles.title}>
                Follow
              </Typography>
              <Grid container spacing={2} sx={Simstyles.gridContainer}>
                {relevantJobSeekers.map((person) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={person._id}>
                    <Card
                      sx={Simstyles.profileCard}
                      onClick={() => handleNavigate(person._id)}
                    >
                      <CardContent sx={Simstyles.cardContent}>
                        <Avatar
                          src={person.profileImage}
                          alt={person.fullName}
                          sx={Simstyles.avatar}
                        />
                        <Box sx={{ flexGrow: 1, overflow: "hidden" }}>
                          <Typography sx={Simstyles.name}>
                            {person.fullName}
                          </Typography>
                          <Typography sx={Simstyles.description}>
                            {person.bio}
                          </Typography>
                        </Box>
                      </CardContent>
                      <Box sx={{ p: 2, pt: 0 }}>
                        <Button
                          variant={
                            person.isFollowing ? "contained" : "outlined"
                          }
                          color="primary"
                          fullWidth
                          sx={styles.followButton}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleFollowToggle(person);
                          }}
                        >
                          {person.isFollowing ? "Following" : "Follow"}
                        </Button>
                      </Box>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Container>
          <PopupSuccess
            modelOpen={successOpen}
            onClose={() => setSuccessOpen(false)}
            message={successMessage}
          />
          <ReleaseJobPostModel
            modelOpen={errorOpen}
            onClose={() => setErrorOpen(false)}
            message={errorMessage}
          />
        </Box>
      )}
    </>
  );
};

export default Follow;
