// socket.js
import io from "socket.io-client";

let socket = null;

const initializeSocket = (userId, userType, token) => {
  // Disconnect existing socket if it exists
  if (socket && socket.connected) {
    socket.disconnect();
  }


  
  socket = io("https://vacanzi.com/chat", {
    query: {
      EIO: 4,
      transport: "websocket",
      userId,
      userType,
      token,
    },
    transports: ["websocket"],
    reconnection: true,              // Enable reconnection
    reconnectionAttempts: Infinity,  // Unlimited reconnection attempts
    reconnectionDelay: 1000,         // Delay between reconnection attempts (1 second)
    reconnectionDelayMax: 5000,      // Maximum delay between attempts (5 seconds)
    randomizationFactor: 0.5,        // Randomize delay to avoid server overload
  });

  // Handle successful connection
  socket.on("connect", () => {
    console.log(`Connected as ${userType} with userId: ${userId}`);
  });

  // Handle disconnection
  socket.on("disconnect", (reason) => {
    console.log(`${userType} socket disconnected. Reason: ${reason}`);
    if (reason === "io server disconnect") {
      // Server disconnected the socket, try manual reconnect
      socket.connect();
    }
    // Other reasons (e.g., "transport close", "ping timeout") are handled by auto-reconnect
  });

  // Handle reconnection attempt
  socket.on("reconnect_attempt", (attempt) => {
    console.log(`${userType} attempting to reconnect (attempt ${attempt})`);
  });

  // Handle successful reconnection
  socket.on("reconnect", (attempt) => {
    console.log(`${userType} reconnected successfully after ${attempt} attempts`);
  });

  // Handle reconnection failure after all attempts (won't happen with Infinity)
  socket.on("reconnect_failed", () => {
    console.error(`${userType} failed to reconnect after all attempts`);
    // Optionally reinitialize with a fresh token here if needed
  });

  // Handle errors
  socket.on("error", (error) => {
    console.error(`${userType} socket error:`, error);
    if (error === "Authentication error") {
      // Handle token expiry or invalid token if server returns this
      console.log("Token may be invalid, consider refreshing it");
    }
  });

  return socket;
};

export const getSocket = () => socket;

export default initializeSocket;