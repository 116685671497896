import React, { useState } from "react";
import {
  Avatar,
  Typography,
  Chip,
  Grid,
  IconButton,
  Paper,
  Button,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Box, styled } from "@mui/system";
import EditIcon from "@mui/icons-material/Edit";
import CompanyRatingModal from "../JobSeekerComponents/Reviews/CompanyRatingModal";
import StarIcon from "@mui/icons-material/Star";

const styles = {
  card: {
    maxWidth: "500px",
    border: "1px solid",
    borderColor: "grey.300",
    borderRadius: "15px",
    overflow: "hidden",
    position: "relative",
    margin: "0 auto",
  },
  header: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    padding: "16px 16px 0 16px",
    gap: "10px",
  },
  headerText: {
    fontSize: "25px",
    fontFamily: "satoshi",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "250px",
  },
  logo: {
    width: 60,
    height: 60,
    backgroundColor: "#0066b2",
    borderRadius: "4px",
    flexShrink: 0,
  },
  title: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    marginTop: "16px",
    paddingLeft: "16px",
    paddingRight: "16px",
    fontFamily: "satoshi",
  },
  titleText: {
    fontWeight: "bold",
    fontSize: "1.25rem",
    color: "#333",
    fontFamily: "satoshi",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "300px",
  },
  location: {
    display: "flex",
    paddingLeft: "16px",
    paddingRight: "16px",
    color: "#666",
    fontFamily: "satoshi",
    fontSize: "0.875rem",
  },
  locationText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "300px",
  },
  tagContainer: {
    display: "flex",
    gap: "8px",
    padding: "16px 16px 8px 16px",
    fontFamily: "satoshi",
    overflow: "hidden",
  },
  tag: {
    borderRadius: "16px",
    backgroundColor: "#f5f5f5",
    color: "#333",
    fontFamily: "satoshi",
    fontSize: "0.75rem",
    maxWidth: "120px",
    "& .MuiChip-label": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },
};

const StyledChip = styled(Chip)(styles.tag);

const CompanyCards = ({ companies, goToCompanyPage, fetchCompanies }) => {
  const [openModalCompanyId, setOpenModalCompanyId] = useState(null);

  const hasNoCompanies =
    !companies ||
    companies.every(
      (industry) =>
        !industry.organizations || industry.organizations.length === 0
    );

  if (hasNoCompanies) {
    return (
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <img
          src="/comingsoon.png"
          alt=""
          style={{ width: "300px", height: "300px" }}
        />
      </Box>
    );
  }

  const token = localStorage.getItem("authToken");
  return (
    <Grid
      container
      spacing={2}
      style={{
        overflowY: "auto",
        maxHeight: "500px",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      {companies?.map((industry) =>
        industry?.organizations?.map((company, index) => {
          // Calculate average rating (assuming company.reviews exists)

          const hasReviews = company?.overallRating !== 0;

          return (
            <Grid item xs={12} sm={6} md={6} key={index}>
              <Paper
                elevation={0}
                sx={styles.card}
                onClick={() => goToCompanyPage(company?.id)}
              >
                <Box sx={styles.header}>
                  <Avatar
                    alt={company?.organizationName?.toUpperCase()}
                    src={company?.logo?.url || "z"}
                    sx={styles.logo}
                    variant="square"
                  />
                  <Box>
                    <Typography sx={styles.headerText}>
                      {company?.organizationName || "Company Name"}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Satoshi",
                        color: "#423bfa",
                        fontWeight: "bold",
                        px: 0.8,
                        py: 0.2,
                        borderRadius: 1,
                        fontSize: "0.8rem",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <StarIcon sx={{ fontSize: "0.9rem", mr: 0.3 }} />
                      {company?.overallRating > 0
                        ? company?.overallRating
                        : 5}{" "}
                    </Typography>
                  </Box>
                </Box>

                <Box sx={styles.title}>
                  <Typography sx={styles.titleText}>
                    {company?.industry || "Industry"}
                  </Typography>
                </Box>
                <Box sx={styles.location}>
                  <Typography variant="body2" sx={styles.locationText}>
                    {company?.subIndustry || "Sub-Industry"}
                  </Typography>
                </Box>
                <Box sx={styles.tagContainer}>
                  <StyledChip
                    label={company?.industry || "Industry"}
                    size="small"
                  />
                  <StyledChip
                    label={company?.subIndustry || "Sub-Industry"}
                    size="small"
                  />
                </Box>
                {token ? (
                  <Button
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent triggering goToCompanyPage
                      setOpenModalCompanyId(company?.id);
                    }}
                    size="small"
                    endIcon={<EditIcon sx={{ fontSize: "14px", ml: 0.5 }} />}
                    sx={{
                      mt: 2,
                      ml: 2,
                      backgroundColor: "#423bfa",
                      color: "white",
                      fontWeight: "bold",
                      borderRadius: "0.7rem",
                      padding: "4px 12px", // Adjusted padding for better spacing
                      textTransform: "none",
                      fontSize: "0.8rem",
                      "&:hover": {
                        backgroundColor: "#B0F0F0",
                        color: "#000",
                      },
                      display: "flex", // Ensure flex for internal alignment
                      alignItems: "center", // Vertically center text and icon
                      justifyContent: "center", // Horizontally center content
                      minWidth: "auto", // Prevent excessive width
                    }}
                    variant="contained"
                  >
                    {hasReviews ? "Reviewed" : "Write a Review"}
                  </Button>
                ) : null}

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    padding: "8px 16px",
                  }}
                >
                  <IconButton>
                    <NavigateNextIcon />
                  </IconButton>
                </Box>
              </Paper>
              <CompanyRatingModal
                open={openModalCompanyId === company?.id}
                onClose={() => setOpenModalCompanyId(null)}
                companyName={company?.organizationName || "Company Name"}
                orgId={company?.id}
                averageRating={company?.overallRating}
                onRatingSubmitted={fetchCompanies}
              />
            </Grid>
          );
        })
      )}
    </Grid>
  );
};

export default CompanyCards;
