import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, CardContent, Typography, Box, Chip, Grid, Divider, IconButton, useMediaQuery, Container } from '@mui/material';
import { ChevronLeft, Star } from '@mui/icons-material';
// import NavBar from '../../../components/JobSeekerComponents/NavBar/NavBar';
import Footer from '../../../components/JobSeekerComponents/Footer/Footer';
import CustomSnackbar from '../../../components/AdvancedComponents/CustomSnackbar';
import vacanziLogo from '../../../assets/icons/logos/vacanzi.png'; // Adjust the path as needed
import baseURL from '../../../api/baseUrl';
import { jwtDecode } from 'jwt-decode';
import NewNavBar from '../../../components/JobSeekerComponents/NavBar/NewNavbar';

const JobSavedDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)');
  const [job, setJob] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState('success');

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const authToken = localStorage.getItem('authToken');
        if (!authToken) throw new Error('User not authenticated');

        const decodedToken = jwtDecode(authToken);
        const userId = decodedToken.id;

        const response = await fetch(`${baseURL}/jobs/savedJobs/${userId}/${id}`, {
          headers: {
            'Authorization': `Bearer ${authToken}`
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch job details');
        }

        const data = await response.json();
        setJob(data.savedJob?.jobId);
      } catch (error) {
        console.error('Error fetching job details:', error);
        setSnackbarMessage('Error fetching job details');
        setSnackbarType('error');
        setSnackbarOpen(true);
      }
    };

    fetchJobDetails();
  }, [id]);

  if (!job) return <Typography variant="h6">Loading...</Typography>;

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <NewNavBar />
      <Box sx={{ p: 2 }}>
        <IconButton color="primary" onClick={() => navigate('/jobSeekerSavedJobs')} sx={{ mb: 2 }}>
          <ChevronLeft />
        </IconButton>
        <Container>
          <Card variant="outlined" sx={{ mb: 2, p: isMobile ? 1 : 2 }}>
            <CardContent>
              <Grid container spacing={isMobile ? 1 : 2}>
                <Grid item xs={12}>
                  {/* Header Section */}
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box>
                      <Typography variant="h5" sx={{ fontWeight: 'bold', mb: isMobile ? 1 : 0 }}>
                        {job.jobTitle}
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary">
                        {job.hiringFor}
                      </Typography>
                      {job.rating && (
                        <Box display="flex" alignItems="center">
                          <Star sx={{ ml: 0, mr: 0.5, fontSize: '15px' }} color="warning" />
                          <Typography variant="body2" color="textSecondary">
                            {job.rating} ({job.reviews} Reviews)
                          </Typography>
                        </Box>
                      )}
                    </Box>
                    {/* Logo and Organization */}
                    <Box display="flex" alignItems="center">
                      <img
                        height={isMobile ? 40 : 50}
                        width={isMobile ? 40 : 50}
                        src={job.organizationId?.companyLogo?.url || vacanziLogo}
                        alt="company logo"
                        style={{ borderRadius: "50%", }}
                      />
                      <Box sx={{ ml:2}}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', }}>
                          {job.organizationId.organizationName}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {job.organizationId.industryType}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Divider sx={{ my: isMobile ? 1 : 2 }} />
                </Grid>

                {/* Job Details Section */}
                <Grid container spacing={isMobile ? 1 : 2}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                      Experience Type
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {job.experienceType} ({job.requiredExperience})
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                      Salary
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      ₹ {job.ctc} - per annum
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                      Location
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {job.jobLocation}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                      Education Requirement
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {job.requiredEducation}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                      Skills
                    </Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {Array.isArray(job.skillPreferences) &&
                        job.skillPreferences.map((skill, index) => (
                          <Chip key={index} label={skill} />
                        ))}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                      Department
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {job.department}
                    </Typography>
                  </Grid>
                </Grid>

                {/* Job Description Section */}
                <Box sx={{ mt: 2 }}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1 }}>
                    Job Description
                  </Typography>
                  <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
                    {job.jobDescription}
                  </Typography>
                </Box>

                {/* Footer Section */}
                <Box display="flex" justifyContent="space-between" mt={2}>
                  <Typography variant="body2" color="textSecondary">
                    Posted on: {new Date(job.postDate).toDateString()}
                  </Typography>
                  {job.viewCount && (
                    <Typography variant="body2" color="textSecondary">
                      Views: {job.viewCount}
                    </Typography>
                  )}
                </Box>
              </Grid>
            </CardContent>
          </Card>
        </Container>

      </Box>
      <Footer />
      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        type={snackbarType}
        handleClose={handleCloseSnackbar}
      />
    </>
  );
};

export default JobSavedDetails;
