import * as React from "react";
import Button from "@mui/material/Button";
import { useState, useEffect } from "react";
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { editAccountDetails, getOrgDetails } from "../../../api/companyProfile";

function AccountDetailsModel({ openDialog, handleCloseDialog, orgData }) {
  const [formDetails, setFormDetails] = useState({
    role: "",
    rcName: "",
    rcPhone: "",
  });

  useEffect(() => {
    if (
      orgData &&
      orgData.recruiterAdmins &&
      orgData.recruiterAdmins.length > 0
    ) {
      setFormDetails({
        role: orgData.recruiterAdmins[0]?.role || "",
        rcName: orgData.recruiterAdmins[0]?.rcName || "",
        rcPhone: orgData.recruiterAdmins[0]?.rcPhone || "",
      });
    }
  }, [orgData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormDetails((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const orgId = localStorage.getItem("organizationId");
  const token = localStorage.getItem("token");

  const editAccountData = async () => {
    try {
      const dataToSend = {
        role: formDetails.role,
        rcName: formDetails.rcName,
        contactNumber: formDetails.rcPhone,
      };
      await editAccountDetails(orgId, token, dataToSend);
      await getOrgDetails(orgId, token);
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <Dialog open={openDialog} onClose={handleCloseDialog}>
      <DialogTitle>Edit Account Details</DialogTitle>
      <DialogContent>
        {orgData?.recruiterAdmins?.map((index) => (
          <Grid container spacing={2} key={index}>
            <Grid item xs={12} sm={6}>
              <Typography component="legend" sx={{ fontSize: 12 }}>
                Role
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                name="role"
                value={formDetails.role}
                onChange={handleInputChange}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography component="legend" sx={{ fontSize: 12 }}>
                Reporting Manager
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                name="rcName"
                value={formDetails.rcName}
                onChange={handleInputChange}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography component="legend" sx={{ fontSize: 12 }}>
                Mobile Number
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                name="rcPhone"
                value={formDetails.rcPhone}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} marginTop={2}>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  sx={{
                    bgcolor: "#FA3B3BFF",
                    borderRadius: 2,
                    ":hover": {
                      backgroundColor: "#FA3B3BFF",
                    },
                  }}
                  onClick={handleCloseDialog}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  sx={{
                    bgcolor: "#423bfa",
                    color: "white",
                    borderRadius: 2,
                    "&:hover": { backgroundColor: "#B0F0F0",color:'#000' },

                  }}
                  onClick={editAccountData}
                >
                  Save
                </Button>
              </DialogActions>
            </Grid>
          </Grid>
        ))}
      </DialogContent>
    </Dialog>
  );
}

export default AccountDetailsModel;
