"use client";

import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  IconButton,
  Stack,
  styled,
  Tooltip,
  Avatar,
  Tabs,
  Tab,
  Button,
  Modal,
  TextField,
  CircularProgress,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import LocationOnOutlined from "@mui/icons-material/LocationOnOutlined";
import CurrencyRupeeOutlinedIcon from "@mui/icons-material/CurrencyRupeeOutlined";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import ChatIcon from "@mui/icons-material/Chat";
import NewNavBar from "../../../components/JobSeekerComponents/NavBar/NewNavbar";
import {
  getAllInvitesApi,
  updateInviteStatusAoi,
} from "../../../api/jobseeker";
import { useNavigate } from "react-router-dom";
import { ThreeDot } from "react-loading-indicators";
import JobSeekerChatModel from "./JobseekerChatModel";
import { truncateText } from "../../../utils/helperFunctions/Format";
import { useJobseeker } from "../../../context/UserContext";
import Follow from "./Follow"; // Import the Follow component

// Styled Components (unchanged)
const StyledCard = styled(Card)(({ theme, expanded }) => ({
  borderRadius: "16px",
  border: "1px solid",
  borderColor: "#9C9C9CFF",
  transition: "transform 0.2s ease-in-out",
  background: "#ffffff",
  maxWidth: "380px",
  cursor: "pointer",
  height: expanded ? "auto" : "450px",
  position: "relative",
  [theme.breakpoints.up("xl")]: {
    height: expanded ? "auto" : "400px",
  },
}));

const CompanyLogo = styled(Box)(({ theme }) => ({
  width: "60px",
  height: "60px",
  borderRadius: "12px",
  backgroundColor: theme.palette.grey[100],
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: theme.spacing(2),
}));

const ActionButton = styled(IconButton)(({ color }) => ({
  borderRadius: "12px",
  padding: "8px",
  backgroundColor:
    color === "success" ? "rgba(46, 204, 113, 0.1)" : "rgba(231, 76, 60, 0.1)",
  color: color === "success" ? "#2ecc71" : "#e74c3c",
  "&:hover": {
    backgroundColor:
      color === "success"
        ? "rgba(46, 204, 113, 0.2)"
        : "rgba(231, 76, 60, 0.2)",
  },
}));

const CardContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  border: "1px solid #CACACAFF",
  borderRadius: "0.7rem",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(3),
  padding: theme.spacing(4),
  "& > *": {
    flexBasis: "calc(33.333% - 16px)",
    maxWidth: "calc(33.333% - 16px)",
  },
  [theme.breakpoints.down("md")]: {
    "& > *": {
      flexBasis: "calc(50% - 16px)",
      maxWidth: "calc(50% - 16px)",
    },
  },
  [theme.breakpoints.down("sm")]: {
    "& > *": {
      flexBasis: "100%",
      maxWidth: "100%",
    },
  },
}));

const ChatButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  bottom: "16px",
  padding: "10px",
  left: "16px",
  backgroundColor: theme.palette.primary.main,
  color: "#fff",
  borderRadius: "50%",
  boxShadow: theme.shadows[4],
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

function Invites() {
  const [invites, setInvites] = useState([]);
  const [loading, setLoading] = useState(true);
  const [updateLoading, setUpdateLoading] = useState(null);
  const [reasonReject, setReasonReject] = useState("");
  const [openRejectId, setOpenRejectId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [recruiterData,setRecruiterData] = useState({
    orgName:"",
    companyLogo:'',
    recName:''
  })

  const [error, setError] = useState(null);
  const [tabValue, setTabValue] = useState(0); // State for tab selection
  const { userData } = useJobseeker();

  const userId = localStorage.getItem("userId");
  const navigate = useNavigate();
  const profileImage = localStorage.getItem("profileImage");

  // Fetch invites
  const fetchInvites = useCallback(async () => {
    if (!userId) return;
    try {
      setLoading(true);
      const response = await getAllInvitesApi(userId);
      setInvites(response?.invites || []);
      setError(null);
    } catch (error) {
      console.error("Error fetching invites:", error.message);
      setError("Failed to load invites");
    } finally {
      setLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    fetchInvites();
  }, [fetchInvites]);

  // Update invite status
  const handleUpdateInvite = async (inviteId, status, rejectionReason = "") => {
    setUpdateLoading(inviteId);
    const formData = {
      status,
      rejectionReason,
    };
    try {
      const response = await updateInviteStatusAoi(inviteId, formData);
      setInvites((prev) =>
        prev.map((invite) =>
          invite._id === inviteId
            ? { ...invite, status: response.invite.status }
            : invite
        )
      );
      setOpenRejectId(null);
      setReasonReject("");
    } catch (error) {
      console.error("Error updating invite:", error.message);
    } finally {
      setUpdateLoading(null);
    }
  };

  const handleAccept = (id) => handleUpdateInvite(id, "accepted");
  const handleSubmitReject = (id) =>
    handleUpdateInvite(id, "rejected", reasonReject);
  const handleToggleReject = (inviteId) => {
    setOpenRejectId((prevId) => (prevId === inviteId ? null : inviteId));
    setReasonReject("");
  };
  const handleReasonForRejection = (e) => setReasonReject(e.target.value);

  const handleOpenChat = (recId, organizationName, logo, recruiterName) => {
    setCurrentId(recId);
    setRecruiterData({
      orgName: organizationName || "",
      companyLogo: logo || "",
      recName: recruiterName || ""
    });
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCurrentId(null);
    setRecruiterData({
      orgName: "",
      companyLogo:  "",
      recName:  ""
    });
  };

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  console.log("invites",invites)

  // Render the Invites content
  const renderInvitesContent = () => {
    if (loading) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      );
    }

    if (error) {
      return (
        <Box sx={{ textAlign: "center", py: 4 }}>
          <Typography color="error">{error}</Typography>
          <Button onClick={fetchInvites}>Retry</Button>
        </Box>
      );
    }

    return (
      <>
        {invites.length === 0 ? (
          <Typography
            variant="h4"
            sx={{
              textAlign: "center",
              mt: 4,
              fontFamily: "satoshi",
              fontWeight: 600,
            }}
          >
            No invitations found
          </Typography>
        ) : (
          <CardContainer>
            {invites.map((invite) => (
              <StyledCard
                key={invite._id}
                expanded={openRejectId === invite._id}
                elevation={0}
              >
                <CardContent>
                  <CompanyLogo>
                    <Avatar
                      src={invite?.job?.organizationId?.companyLogo?.url}
                      size={30}
                    />
                  </CompanyLogo>

                  <Typography
                    variant="h6"
                    sx={{ fontFamily: "satoshi", fontWeight: 600, mb: 1 }}
                  >
                    {invite?.job?.orgName || "Unknown Organization"}
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontFamily: "satoshi",
                      color: "primary",
                      fontWeight: 600,
                      mb: 2,
                    }}
                  >
                    {invite?.job?.jobTitle || "Unknown Title"}
                  </Typography>

                  <Stack spacing={1} sx={{ mb: 3 }}>
                    <Typography
                      variant="body2"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        fontFamily: "satoshi",
                        color: "#000",
                      }}
                    >
                      <LocationOnOutlined sx={{ opacity: 0.6 }} />{" "}
                      {truncateText(invite?.job?.jobLocation?.join(","), 30)}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: "satoshi",
                        display: "flex",
                        alignItems: "center",
                        color: "#000",
                      }}
                    >
                      <CurrencyRupeeOutlinedIcon sx={{ opacity: 0.6 }} />
                      {invite?.job?.showSalary
                        ? invite?.job?.ctc
                        : "Not Mentioned"}
                    </Typography>
                  </Stack>

                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: "satoshi",
                      fontWeight: "bold",
                      color: "#266800FF",
                    }}
                  >
                    {invite?.job?.orgName || "The company"} has invited you to
                    apply for the {invite?.job?.jobTitle || "this"} role.
                  </Typography>

                  {invite.hasApplied ? (
                    <Box
                      sx={{
                        borderTop: "1px solid",
                        borderColor: "divider",
                        pt: 2,
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        gap: 2,
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "satoshi",
                          fontWeight: "bold",
                          mt: "10px",
                          color: "#266800FF",
                        }}
                      >
                        Applied
                      </Typography>
                    </Box>
                  ) : invite.status === "accepted" ||
                    invite.status === "rejected" ? (
                    <Box
                      sx={{
                        borderTop: "1px solid",
                        borderColor: "divider",
                        pt: 2,
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        gap: 2,
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "satoshi",
                          fontWeight: "bold",
                          mt: "10px",
                          color:
                            invite.status === "accepted"
                              ? "#266800FF"
                              : "#e74c3c",
                        }}
                      >
                        {invite.status === "accepted" ? "Accepted" : "Rejected"}
                      </Typography>
                      {invite.status === "accepted" && (
                        <Button
                          variant="contained"
                          sx={{
                            color: "#ffffff",
                            backgroundColor: "#000000",
                            fontFamily: "satoshi",
                            textTransform: "none",
                            borderRadius: "0.7rem",
                            ":hover": { backgroundColor: "#000000" },
                          }}
                          onClick={() =>
                            navigate(`/JobSeekerFilter/job/${invite?.job?._id}`)
                          }
                        >
                          View Job
                        </Button>
                      )}
                    </Box>
                  ) : (
                    <>
                      {openRejectId === invite._id ? (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            mt: 1,
                          }}
                        >
                          <IconButton
                            sx={{ alignSelf: "flex-end", marginBottom: 1 }}
                            onClick={() => setOpenRejectId(null)}
                          >
                            <CloseIcon />
                          </IconButton>

                          <TextField
                            fullWidth
                            placeholder="Reason for rejection (Optional)"
                            value={reasonReject}
                            onChange={handleReasonForRejection}
                            size="medium"
                            multiline
                            rows={3}
                            sx={{
                              mb: 2,
                              width: "300px",
                              fontFamily: "satoshi",
                            }}
                          />
                          <Button
                            variant="contained"
                            sx={{
                              backgroundColor: "#000000",
                              textTransform: "none",
                              fontFamily: "satoshi",
                              color: "white",
                              borderRadius: "0.7rem",
                              ":hover": {
                                backgroundColor: "#000000",
                              },
                            }}
                            onClick={() => handleSubmitReject(invite._id)}
                            disabled={updateLoading === invite._id}
                          >
                            {updateLoading === invite._id ? (
                              <ThreeDot
                                color="#FFFFFFFF"
                                size="small"
                                text=""
                                textColor=""
                              />
                            ) : (
                              "Submit"
                            )}
                          </Button>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            borderTop: "1px solid",
                            borderColor: "divider",
                            pt: 2,
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            gap: 2,
                          }}
                        >
                          <ActionButton
                            color="error"
                            onClick={() => handleToggleReject(invite._id)}
                            aria-label="Reject invitation"
                            disabled={updateLoading === invite._id}
                          >
                            <CancelIcon size={20} />
                          </ActionButton>
                          <ActionButton
                            color="success"
                            onClick={() => handleAccept(invite._id)}
                            aria-label="Accept invitation"
                            disabled={updateLoading === invite._id}
                          >
                            <DoneIcon size={20} />
                          </ActionButton>
                        </Box>
                      )}
                    </>
                  )}
                </CardContent>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    position: "relative",
                  }}
                >
                  <Tooltip title="Chat with Employer" placement="top" arrow>
                    <ChatButton
                      onClick={() =>
                        handleOpenChat(
                          invite?.recruiter?._id,
                          invite?.job?.organizationId?.organizationName,
                          invite?.job?.organizationId?.companyLogo?.url,
                          invite?.recruiter?.rcName
                        )
                      }
                    >
                      <ChatIcon />
                    </ChatButton>
                  </Tooltip>
                </Box>
              </StyledCard>
            ))}
          </CardContainer>
        )}
      </>
    );
  };

  return (
    <>
      <NewNavBar />
      <Box
        sx={{
          bgcolor: "#f5f7fa",
          minHeight: "100vh",
          py: 4,
          px: { xs: 2, md: 15 },
        }}
      >
        <Typography
          variant="h4"
          sx={{
            textAlign: "center",
            mb: 4,
            fontFamily: "IBM Plex Sans",
            fontWeight: 600,
          }}
        >
          <span style={{ color: "#423bfa", fontFamily: "satoshi" }}>
            {userData?.fullName}
          </span>
          , Job Invitations
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Avatar
            sx={{ width: "100px", height: "100px", mb: 1 }}
            src={profileImage}
          />
        </Box>

        {/* Tabs Section */}
        <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="invites and follow tabs"
            sx={{
              "& .MuiTabs-indicator": {
                backgroundColor: "#423bfa",
                height: 3, // Makes the indicator line thicker
              },
            }}
          >
            <Tab
              label="Invites"
              sx={{
                fontFamily: "satoshi",
                fontWeight: 700,
                textTransform: "none",
                fontSize: "1.1rem", // Increased font size
                padding: "12px 24px", // Increased padding
                minWidth: 120, // Minimum width for larger clickable area
                color: tabValue === 0 ? "#423bfa" : "text.secondary",
                "&.Mui-selected": {
                  color: "#423bfa",
                },
              }}
            />
            <Tab
              label="Follow"
              sx={{
                fontFamily: "satoshi",
                fontWeight: 700,
                textTransform: "none",
                fontSize: "1.1rem", // Increased font size
                padding: "12px 24px", // Increased padding
                minWidth: 120, // Minimum width for larger clickable area
                color: tabValue === 1 ? "#423bfa" : "text.secondary",
                "&.Mui-selected": {
                  color: "#423bfa",
                },
              }}
            />
          </Tabs>
        </Box>

        {/* Tab Content */}
        {tabValue === 0 && renderInvitesContent()}
        {tabValue === 1 && <Follow />}

        {isModalOpen && (
          <Modal open={isModalOpen} onClose={handleCloseModal}    sx={{
            position: "fixed",
            bottom: 20,
            right: 20,
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}>
            {userId && (
              <Box sx={{ width: 400, height: 500 }}>
                <JobSeekerChatModel
                  currentId={currentId}
                  onClose={handleCloseModal}
                  recruiterData={recruiterData}
                />
              </Box>
            )}
          </Modal>
        )}
      </Box>
    </>
  );
}

export default Invites;
