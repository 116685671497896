import React, { lazy, Suspense, useEffect, useState } from "react";
import { Box } from "@mui/material";
// import ResponsiveAppBar from "../../../components/JobSeekerComponents/NavBar/NavBar";

import OrgCarousel from "../../../components/JobSeekerComponents/OrganizationOvervIew/OrganizationCarousel";
import { useSearchParams } from "react-router-dom";
import { getOrganizaionDetailsApi } from "../../../api/jobseeker";
import TitleCardSkeleton from "../../../utils/lazy/TitleCardSkeleton";
import DescriptionBox from "../../../utils/lazy/DescriptionBox";
import NewNavBar from "../../../components/JobSeekerComponents/NavBar/NewNavbar";

const OrgTitleCard = lazy(() =>
  import(
    "../../../components/JobSeekerComponents/OrganizationOvervIew/OrganizationTitleCard"
  )
);
const OrgVideoCard = lazy(() =>
  import(
    "../../../components/JobSeekerComponents/OrganizationOvervIew/OrganizationVideoCard"
  )
);
const OrgDescription = lazy(() =>
  import(
    "../../../components/JobSeekerComponents/OrganizationOvervIew/OrgDescription"
  )
);

const OrgOverView = () => {
  const [selectedJob, setSelectedJob] = useState(null);
  const [orgData, setOrgData] = useState();
  const [searchParams] = useSearchParams();
  const companyId = searchParams.get("companyId"); // Access the companyId from the query string

  // Placeholder function for handling video selection
  const handleVideoSelect = (video) => {
    setSelectedJob(video);
  };

  // Placeholder function for handling job application
  const handleApplyJob = () => {
    console.log("Apply job clicked");
  };

  useEffect(() => {
    const fecthOrgDetails = async () => {
      const response = await getOrganizaionDetailsApi(companyId);
      setOrgData(response);
    };
    fecthOrgDetails();
  }, [companyId]);
  
  return (
    <>
      <NewNavBar />

      <Box
        sx={{
          p: 3,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 3,
          backgroundColor: "#EAFBF4FF",
          fontFamily: "IBM plex sans",
          padding: 10,
        }}
      >
        {/* Left Side: Title, Video, Video Carousel */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
          }}
        >
          <Suspense fallback={<TitleCardSkeleton />}>
            <OrgTitleCard orgData={orgData} />
          </Suspense>

          <Suspense fallback={<TitleCardSkeleton />}>
            <OrgVideoCard
            selectedJob={selectedJob}
              video={
                orgData?.jobPosts?.[0]
                  ? {
                      id: orgData.jobPosts[0]._id,
                      orgVideoUrl: orgData.jobPosts[0].jobVideoUrl?.url, // Mapping jobVideoUrl.url to orgVideoUrl
                      jobTitle: orgData.jobPosts[0].jobTitle,
                    }
                  : null // Fallback if no job post exists
              }
            />
          </Suspense>

          <OrgCarousel
            videos={orgData?.jobPosts?.map((job) => ({
              id: job?._id,
              jobVideoUrl: job?.jobVideoUrl?.url,
              jobTitle: job?.jobTitle,
            }))}
            onVideoSelect={handleVideoSelect}
          />
        </Box>

        {/* Right Side: Description */}
        <Box>
          <Suspense fallback={<DescriptionBox />}>
            <OrgDescription orgData={orgData} handleApplyJob={handleApplyJob} />
          </Suspense>
        </Box>
      </Box>
    </>
  );
};

export default OrgOverView;
