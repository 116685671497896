import React, { useState } from "react";
import { Box, Typography, styled, Button, } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import CourseContactForm from "./CourseContactForm";

const VideoBackground = styled(Box)({
  position: "relative",
  height: "100vh",
  width: "100vw",
  overflow: "hidden",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "0 5%",
});

const StyledVideo = styled("video")({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  zIndex: -1,
});

const Overlay = styled(Box)({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  zIndex: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "0 5%",
});

const LeftContent = styled(Box)({
  maxWidth: "400px",
  textAlign: "left",
});

const RightContent = styled(Box)({
  maxWidth: "600px",
  textAlign: "center",
  display: "flex",
  marginTop: "60px",
  flexDirection: "column",
  alignItems: "center",
  gap: "1.5rem",
});

const TestimonialBox = styled(Box)({
  backgroundColor: "rgba(255, 255, 255, 0.1)",
  borderRadius: "15px",
  padding: "2rem",
  maxWidth: "500px",

  backdropFilter: "blur(5px)",
  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
  overflow: "hidden", // Clip overflowing content
});

const TestimonialItem = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",

  height: "100%", // Fill the slide height
  width: "100%",
});

const CourseDetailsBox = styled(Box)({
  borderRadius: "10px",
  padding: "1rem",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "0.5rem",
  width: "100%",
});

const StyledButton = styled(Button)({
  marginTop: "1.5rem",
  padding: "10px 20px",
  borderRadius: "25px",
  border: "2px solid white",
  backgroundColor: "transparent",
  marginBottom: "10px",
  color: "white",
  fontWeight: "bold",
  textTransform: "none",
  fontSize: "1rem",
  transition: "all 0.3s ease",
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    borderColor: "#fff",
    transform: "scale(1.05)",
  },
});

const testimonials = [
  {
    image: "/simpro.webp",
    quote: "This course transformed my career in just weeks!",
    name: "Jane Doe",
    designation: "Software Engineer, TechCorp",
    courseImage: "/skillTest1.png",
    courseName: "Advanced React",
    courseDetails: "6 Weeks • Online",
  },
  {
    image: "/simpro1.jpg",
    quote: "The best learning experience I’ve ever had!",
    name: "John Smith",
    designation: "Data Analyst, DataWorks",
    courseImage: "/skillTest1.png",
    courseName: "Data Science Basics",
    courseDetails: "8 Weeks • Online",
  },
  {
    image: "/simpro2.jpg",
    quote: "Skills I gained here landed me my dream job!",
    name: "Emily Johnson",
    designation: "UX Designer, CreativeLabs",
    courseImage: "/skillTest1.png",
    courseName: "UI/UX Design",
    courseDetails: "5 Weeks • Online",
  },
  {
    image: "/simpro3.jpg",
    quote: "A game-changer for my professional growth!",
    name: "Michael Brown",
    designation: "Product Manager, InnovateCo",
    courseImage: "/skillTest1.png",
    courseName: "Product Management",
    courseDetails: "4 Weeks • Online",
  },
];

const CourseHero = ({
  scrollToCourseDetails,
  setType,
  formData,
  setFormData,
  handleSubmitContactForm,
}) => {
  const [openContactForm, setOpenContactForm] = useState(false);

  const handleOpenForm = () => {
    setType("apply"); // Update type in CourseHome
    setFormData((prev) => ({ ...prev, type: "apply" })); // Sync formData.type
    setOpenContactForm(true);
  };
  const handleCloseForm = () => {
    setOpenContactForm(false);
  };
  return (
    <>
      <VideoBackground>
        <StyledVideo autoPlay loop muted playsInline>
          <source src="/bgVideo.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </StyledVideo>
        <Overlay>
          <LeftContent>
            <Typography
              variant="h4"
              sx={{
                color: "white",
                fontWeight: "bold",
                fontSize: { xs: "1.5rem", md: "2.5rem" },
                fontFamily: "Gilroy, sans-serif",
                lineHeight: 1.2,
                mb: 2,
              }}
            >
              Upskill Today, Lead Tomorrow
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "white",
                fontSize: { xs: "1rem", md: "1.25rem" },
                fontFamily: "Gilroy, sans-serif",
                mb: 3,
              }}
            >
              Master in-demand skills with our expert-led courses.
            </Typography>
            <StyledButton
              onClick={handleOpenForm}
            >
              Reach Us
            </StyledButton>
          </LeftContent>
          <RightContent>
            <TestimonialBox
              sx={{
                width: { xl: "100%", lg: "70%" },
                height: { xl: "500px", lg: "450px" },
              }}
            >
              <Swiper
                direction="vertical"
                modules={[Autoplay]}
                autoplay={{
                  delay: 4000,
                  disableOnInteraction: false,
                  reverseDirection: false, // Bottom to top
                }}
                loop={true}
                slidesPerView={1} // One slide visible
                spaceBetween={0} // No gap
                style={{ height: "100%", width: "100%" }}
                className="swiper-container" // For additional styling if needed
              >
                {testimonials.map((testimonial, index) => (
                  <SwiperSlide
                    key={index}
                    style={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                    }} // Ensure slide fits box
                  >
                    <TestimonialItem>
                      {/* <Avatar
                        src={testimonial.image}
                        alt={testimonial.name}
                        sx={{
                          width: "80px",
                          height: "80px",
                          borderRadius: "",
                          objectFit: "cover",
                          border: "2px solid rgba(255, 255, 255, 0.3)",
                        }}
                      /> */}
                      <Typography
                        variant="body2"
                        sx={{
                          color: "white",
                          fontFamily: "Gilroy, sans-serif",
                          fontStyle: "italic",
                          fontSize: "1rem",
                          maxWidth: "400px",
                        }}
                      >
                        "{testimonial.quote}"
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: "white",
                          fontFamily: "Gilroy, sans-serif",
                          fontWeight: "bold",
                        }}
                      >
                        {testimonial.name}
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{
                          color: "rgba(255, 255, 255, 0.8)",
                          fontFamily: "Gilroy, sans-serif",
                        }}
                      >
                        {testimonial.designation}
                      </Typography>
                      <CourseDetailsBox>
                        <Box
                          component="img"
                          src={testimonial.courseImage}
                          alt={testimonial.courseName}
                          sx={{
                            width: "100%", // Adjusted for better fit
                            maxHeight: "75%", // Limit height to avoid overflow
                            borderRadius: "20px",
                            objectFit: "cover",
                          }}
                        />
                        <Box>
                          <Typography
                            variant="body2"
                            sx={{
                              color: "white",
                              fontFamily: "Gilroy, sans-serif",
                              fontWeight: "bold",
                            }}
                          >
                            {testimonial.courseName}
                          </Typography>
                          <Typography
                            variant="caption"
                            sx={{
                              color: "rgba(255, 255, 255, 0.8)",
                              fontFamily: "Gilroy, sans-serif",
                            }}
                          >
                            {testimonial.courseDetails}
                          </Typography>
                        </Box>
                      </CourseDetailsBox>
                    </TestimonialItem>
                  </SwiperSlide>
                ))}
              </Swiper>
            </TestimonialBox>
            <StyledButton onClick={scrollToCourseDetails}>
              Explore Courses
            </StyledButton>
          </RightContent>
        </Overlay>
      </VideoBackground>
      <CourseContactForm
        handleSubmitContactForm={handleSubmitContactForm}
        setType={setType}
        formData={formData}
        setFormData={setFormData}
        open={openContactForm}
        onClose={handleCloseForm}
        isHero={true}
      />
    </>
  );
};

export default CourseHero;
