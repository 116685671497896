import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Grid, Autocomplete, TextField } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Define minExpOptions
export const minExpOptions = [
  { label: "Fresher", value: 0 }, // Fresher as 0 years
  ...Array.from({ length: 30 }, (_, i) => ({
    label: `${i + 1} Year${i > 0 ? "s" : ""}`,
    value: i + 1,
  })),
];

// Define getMaxExpOptions to filter based on minExp
export const getMaxExpOptions = (minExp) => {
  // If no minExp is provided (undefined or null), return all options except "Fresher"
  if (!minExp && minExp !== 0) {
    return minExpOptions.slice(1);
  }

  // Use the numeric value of minExp (handle both number and undefined cases)
  const minValue = typeof minExp === "number" ? minExp : 0;

  // Filter options where value is >= minValue
  return minExpOptions.filter((option) => option.value >= minValue);
};

const CandidateExperience = ({ formData, setFormData }) => {
  // Handle change for Autocomplete
  const handleAutocompleteChange = (fieldName, newValue) => {
    // Extract the numeric value from the selected option
    const numericValue = newValue ? newValue.value : ''; // Use empty string if no value is selected

    // Update only the specific field in formData
    setFormData((prev) => ({
      ...prev,
      [fieldName]: numericValue, // Dynamically set the field (minExperience or maxExperience)
    }));
  };

  return (
    <Accordion
      defaultExpanded
      sx={{
        boxShadow: "none",
        backgroundColor: "transparent",
        "&:before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: "#666", opacity: 0.7 }} />}
        sx={{
          padding: "8px 0",
          minHeight: "40px",
          "& .MuiAccordionSummary-content": {
            margin: "0",
          },
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "bold",
            fontFamily: "IBM Plex Sans, sans-serif",
            color: "#333",
          }}
        >
          Experience
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{ padding: "0 0 16px 0", backgroundColor: "transparent" }}
      >
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Autocomplete
              options={minExpOptions}
              getOptionLabel={(option) => {
                if (typeof option === "number" || option === '') {
                  const foundOption = minExpOptions.find((opt) => opt.value === option);
                  return foundOption ? foundOption.label : '';
                }
                return option?.label || '';
              }} // Map numeric value to label for display
              value={formData?.minExperience ?? ''} // Use numeric value directly
              onChange={(event, newValue) => handleAutocompleteChange("minExperience", newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Min experience"
                  variant="outlined"
                  size="small"
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              options={getMaxExpOptions(formData?.minExperience)}
              getOptionLabel={(option) => {
                if (typeof option === "number" || option === '') {
                  const foundOption = minExpOptions.find((opt) => opt.value === option);
                  return foundOption ? foundOption.label : '';
                }
                return option?.label || '';
              }} // Map numeric value to label for display
              value={formData?.maxExperience ?? ''} // Use numeric value directly
              onChange={(event, newValue) => handleAutocompleteChange("maxExperience", newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Max experience"
                  variant="outlined"
                  size="small"
                />
              )}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default CandidateExperience;