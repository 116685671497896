import { Box, Typography, styled } from "@mui/material";
// import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
// import { useNavigate } from "react-router-dom";
// import StarIcon from "@mui/icons-material/Star";
// import { truncateText } from "../../../utils/helperFunctions/Format";
// Styled Components
const CardWrapper = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  cursor: "pointer",
  alignItems: "center",
  padding: "20px",
  backgroundColor: "#ffffff",
  borderRadius: "12px",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.05)",
  border: "1px solid #eaeaea",
  "&:hover": {
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  height: "160px", // Fixed height for each card
  width: "100%", // Ensure cards take full width
  flexShrink: 0, // Prevent cards from shrinking
});

const ContentSection = styled(Box)({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

const JobTitle = styled(Typography)({
  fontFamily: "satoshi",
  fontSize: "16px",
  fontWeight: 600,
  color: "#111827",
  lineHeight: 1.5,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "normal",
  display: "-webkit-box",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
  minHeight: "48px", // Ensures equal height for job titles
});

const CompanyName = styled(Typography)({
  fontFamily: "satoshi",
  fontSize: "14px",
  color: "#4B5563",
  marginBottom: "8px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "normal",
  display: "-webkit-box",
  WebkitLineClamp: 1,
  WebkitBoxOrient: "vertical",
});

// const RatingBox = styled(Box)({
//   display: "flex",
//   alignItems: "center",
//   gap: "4px",
//   marginBottom: "8px",
// });

// const RatingText = styled(Typography)({
//   fontFamily: "satoshi",
//   fontSize: "14px",
//   color: "#111827",
//   display: "flex",
//   alignItems: "center",
//   gap: "4px",
// });

// const ReviewCount = styled(Typography)({
//   fontFamily: "satoshi",
//   fontSize: "14px",
//   color: "#6B7280",
// });

// const LocationBox = styled(Box)({
//   display: "flex",
//   alignItems: "center",
//   gap: 2,
//   justifyContent: "space-between",
//   width: "100%",
// });

// const LocationText = styled(Typography)({
//   fontFamily: "satoshi",
//   fontSize: "14px",
//   color: "#4B5563",
// });

// const PostedTime = styled(Typography)({
//   fontFamily: "satoshi",
//   fontSize: "14px",
//   color: "#6B7280",
//   marginLeft: "auto",
// });

const CompanyLogo = styled("img")({
  width: "60px",
  height: "60px",
  objectFit: "contain",

  flexShrink: 0, // Ensures logos do not affect alignment
});

function SimilarJobs({ similarJobs }) {
  const handleNavigation = (jobId) => {
    const profileUrl = `/JobSeekerFilter/job/${jobId}`; // Construct the route
    window.open(profileUrl, "_blank", "noopener,noreferrer"); // Open in a new tab
  };
  console.log("similar", similarJobs);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2,
        borderRadius: "12px",
        height: "auto", // Fixed height for the container
        overflowY: "auto", // Enable vertical scrolling
        width: "100%", // Ensure the container takes full width
      }}
    >
      <Typography
        sx={{
          backgroundColor: "#ffffff",
          px: { xl: 5.5, lg: 3, md: 3 },
          py: 2.5,
          borderRadius: "10px",
          fontWeight: "bold",
          fontSize: "17px",
          position: "sticky",
          top: 0,
          zIndex: 1,
          border: "1px solid",
          borderColor: "grey.300",
          width: "100%", // Ensure the title takes full width
        }}
      >
        Jobs you might be interested in
      </Typography>

      {Array.isArray(similarJobs) && similarJobs.length > 0 ? (
  similarJobs.slice(0, 3).map((job) => ( // Show only 5 jobs
    <CardWrapper key={job._id} onClick={() => handleNavigation(job._id)}>
      <ContentSection>
        <JobTitle>{job.jobTitle || "Job Title Not Available"}</JobTitle>
        <CompanyName>
          {job?.forConsultantCompany
            ? job?.consultantOrgName
            : job?.organizationDetails?.organizationName || "Company Not Specified"}
        </CompanyName>
        {/* <RatingBox>
          <RatingText sx={{ color: "#423bfa" }}>
            <StarIcon sx={{ fontSize: "0.9rem", mr: 0.3 }} />{" "}
            {job?.organizationDetails?.overallRating === 0
              ? 5
              : job?.organizationDetails?.overallRating}
          </RatingText>
        </RatingBox> */}
      </ContentSection>
      {job.organizationDetails?.companyLogo?.url ? (
        <CompanyLogo
          src={
            job?.forConsultantCompany
              ? job?.consultantCompanyLogo?.url || job?.organizationDetails?.companyLogo?.url
              : job?.organizationDetails?.companyLogo?.url
          }
          alt={job.organizationDetails.organizationName}
        />
      ) : (
        <CompanyLogo as="div">No Logo</CompanyLogo>
      )}
    </CardWrapper>
  ))
) : (
  <Typography variant="body2" color="textSecondary">
    No similar jobs found.
  </Typography>
)}

    </Box>
  );
}

export default SimilarJobs;
