import React, { useEffect, useState, useMemo } from "react";
import {
  Autocomplete,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getTopCitiesApi } from "../../../../api/common";
import { debounce } from "@mui/material/utils";

const CandidateCity = ({ formData, setFormData }) => {
  const [locations, setLocations] = useState([]); // Initialize as an empty array
  const [inputValue, setInputValue] = useState(""); // Track input value for dynamic search
  const [isTopCity, setIsTopCity] = useState(true); // Track if the top cities are shown

  // Debounced fetchCities function
    const fetchCities = useMemo(
      () =>
        debounce(async (query) => {
         
          try {
            const response = await getTopCitiesApi(query, isTopCity);
            if (response && response.cities) {
              setLocations(response.cities);
            }
          } catch (error) {
            console.log(error.message);
          } 
        }, 300),
      [isTopCity] // Dependency ensures it updates when `isTopCity` changes
    );
  
    useEffect(() => {
      fetchCities(inputValue);
      return () => fetchCities.clear();
    }, [inputValue, fetchCities, isTopCity]);


  const handleFocus = () => {
    // Show top cities when the field is focused
    setIsTopCity(true);
    fetchCities("");
  };
  const handleCityChange = (event, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      currentLocation: value, 
    }));
  };

  // Handle input change for dynamic search
  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue); 
  };

  return (
    <Accordion
      defaultExpanded
      sx={{
        boxShadow: "none",
        backgroundColor: "transparent",
        "&:before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: "#666", opacity: 0.7 }} />}
        sx={{
          padding: "8px 0",
          minHeight: "40px",
          "& .MuiAccordionSummary-content": {
            margin: "0",
          },
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "bold",
            fontFamily: "IBM Plex Sans, sans-serif",
            color: "#333",
          }}
        >
          Current City/Area
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{ padding: "0 0 16px 0", backgroundColor: "transparent" }}
      >
        <Autocomplete
          options={locations.map((city) => city?.name || "")} // Ensure it's always a string
          value={formData.currentLocation || ""}
          getOptionLabel={(option) => (typeof option === "string" ? option : "")} 
          isOptionEqualToValue={(option, value) => option?.name === value?.name}
          onChange={handleCityChange} 
          onFocus={() => handleFocus()}
          onInputChange={handleInputChange}
          ListboxProps={{
            style: {
              maxHeight: "200px",
              fontFamily: "Satoshi",
              fontWeight: "bold",
              overflowY: "auto",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            },
          }} // Handle input change for dynamic search
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Search City"
              variant="outlined"
              size="small"
            />
          )}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default CandidateCity;