import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Menu,
  Container,
  MenuItem,
  IconButton,
  TextField,
  Select,
  FormControl,
  InputLabel,
  InputAdornment,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteRecruiter } from "../../../api/manageUsers";
import EditUser from "./EditUser";

import { ThreeDot } from "react-loading-indicators";

const MemberTable = ({
  handleSuccess,
  handleError,
  fetchUsers,
  manageUsers,
  loading,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [openEditDialogBox, setOpenEditDialogBox] = useState(false);
  const [userId, setUserId] = useState("");
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const token = localStorage.getItem("token");

  // Dialog functions for Edit
  const handleOpenDialogBox = (userId) => {
    setOpenEditDialogBox(true);
    setUserId(userId);
  };

  const handleCloseDialogBox = () => {
    setOpenEditDialogBox(false);
  };

  // Menu functions
  const handleMenuOpen = (event, userId) => {
    setAnchorEl(event.currentTarget);
    setSelectedUserId(userId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    // Do NOT reset selectedUserId here; reset it only after dialog actions
  };

  // Delete Dialog functions
  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
    handleMenuClose(); // Close the menu, but selectedUserId persists
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setDeleteLoading(false);
    setSelectedUserId(null); // Reset selectedUserId only when dialog closes
  };

  const handleDeleteRecruiter = async () => {
    if (!selectedUserId) return;

    setDeleteLoading(true);
    try {
      const response = await deleteRecruiter(selectedUserId, token);

      handleSuccess(response.message || "Recruiter deleted successfully");

      handleCloseDeleteDialog(); // Close the dialog on success
    } catch (error) {
      console.error("Failed to delete recruiter:", error);

      handleError(error.message || "Failed to delete recruiter.");
    } finally {
      setDeleteLoading(false);
      fetchUsers(); // Refresh the user list
    }
  };

  // Filter logic
  useEffect(() => {
    const filtered = manageUsers.filter((user) => {
      const matchesSearch =
        user.rcName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.rcEmail.toLowerCase().includes(searchQuery.toLowerCase());
      const matchesStatus =
        statusFilter === "" ||
        (statusFilter === "Active" && !user.deleted) ||
        (statusFilter === "Inactive" && user.deleted);
      return matchesSearch && matchesStatus;
    });
    setFilteredUsers(filtered);
  }, [searchQuery, statusFilter, manageUsers]);

  console.log("manageUsers", manageUsers);
  return (
    <Container
      sx={{
        padding: 2,
        border: "1px solid #e0e0e0",
        borderRadius: "8px",
        background: "#fff",
      }}
    >
      {/* Search and Filter */}
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        mb={2}
        gap={2}
      >
        <TextField
          size="small"
          variant="outlined"
          placeholder="Search by name or email"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{ width: "30%" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <FormControl size="small" variant="outlined" sx={{ minWidth: 200 }}>
          <InputLabel>Status</InputLabel>
          <Select
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
            label="Status"
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="Active">Active</MenuItem>
            <MenuItem value="Inactive">Inactive</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {/* Table */}
      <TableContainer>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress size={24} color="inherit" />
          </Box>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle2">Name & Email</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2">Member Role</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2">Permissions</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2">Status</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2">Contact</Typography>
                </TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUsers?.map((member, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <Box
                        sx={{
                          width: 36,
                          height: 36,
                          borderRadius: "50%",
                          backgroundColor: "#f5f5f5",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginRight: 2,
                        }}
                      >
                        <Typography variant="body2" fontWeight="bold">
                          {member?.rcName[0]?.toUpperCase()}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="body1">{member.rcName}</Typography>
                        <Typography variant="body2" color="textSecondary">
                          {member.rcEmail}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {member?.role === "recruiter_admin"
                      ? "Recruiter Admin"
                      : member?.role === "recruiter"
                      ? "Recruiter"
                      : "Manager"}
                  </TableCell>
                  <TableCell>
                    {Object.keys(member.permissions)
                      .filter((key) => member.permissions[key])
                      .join(", ")}
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        backgroundColor: !member.deleted
                          ? "#e8f5e9"
                          : "#fbe9e7",
                        color: !member.deleted ? "#4caf50" : "#d32f2f",
                        borderRadius: "16px",
                        padding: "4px 12px",
                        display: "inline-block",
                      }}
                    >
                      {!member.deleted ? "Active" : "Inactive"}
                    </Box>
                  </TableCell>
                  <TableCell>{member.rcPhone}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      onClick={(event) => handleMenuOpen(event, member._id)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>

      {/* Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem
          onClick={() => {
            handleMenuClose();
            handleOpenDialogBox(selectedUserId);
          }}
        >
          <EditIcon fontSize="small" sx={{ marginRight: 1 }} />
          Edit Member
        </MenuItem>
        <MenuItem
          onClick={handleOpenDeleteDialog}
          disabled={
            filteredUsers?.find((member) => member._id === selectedUserId)
              ?.role === "recruiter_admin"
          }
        >
          <DeleteIcon fontSize="small" sx={{ marginRight: 1 }} />
          Delete Member
        </MenuItem>
      </Menu>

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle sx={{ fontFamily: "poppins" }}>
          Delete Recruiter
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ fontFamily: "poppins" }}>
            Are you sure you want to delete this recruiter?{" "}
            <span style={{ fontWeight: "bold", fontSize: "18px" }}>
              This can't be undone!
            </span>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDeleteDialog}
            sx={{
              bgcolor: "#000000",
              color: "white",
              fontFamily: "Poppins",
              borderRadius: "0.7rem",
              textTransform: "none",
              ":hover": { bgcolor: "#000000", color: "white" },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteRecruiter}
            disabled={deleteLoading}
            sx={{
              bgcolor: "#000000",
              color: "white",
              borderRadius: "0.7rem",
              fontFamily: "Poppins",
              textTransform: "none",
              ":hover": { bgcolor: "#000000", color: "white" },
            }}
          >
            {deleteLoading ? (
              <ThreeDot color="#FFFFFF" size="small" text="" textColor="" />
            ) : (
              "Delete Recruiter"
            )}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Dialog */}
      <EditUser
        openEditDialogBox={openEditDialogBox}
        handleCloseDialogBox={handleCloseDialogBox}
        userId={userId}
        filteredUsers={filteredUsers}
        fetchUsers={fetchUsers}
        handleSuccess={handleSuccess}
        handleError={handleError}
      />
    </Container>
  );
};

export default MemberTable;
