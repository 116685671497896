import React from 'react'
import { Box, Typography, Container, Paper, Stack } from "@mui/material"
import VerifiedIcon from "@mui/icons-material/Verified"
import LightbulbIcon from "@mui/icons-material/Lightbulb"
import GavelIcon from "@mui/icons-material/Gavel"
import HandshakeIcon from "@mui/icons-material/Handshake"
import SpeedIcon from "@mui/icons-material/Speed"
import SupportAgentIcon from "@mui/icons-material/SupportAgent"

const WhyVacanzi = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Stack direction={{ xs: 'column', md: 'row' }} position={'relative'} spacing={4}>
        {/* Left Section */}
        <Box sx={{ flex: 1, pr: { md: 4 } }}>
          <Stack spacing={4}>
            {/* Innovation */}
            <Stack direction="row" spacing={2}>
              <LightbulbIcon sx={{ color: "text.secondary" }} />
              <Box>
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  Innovation
                </Typography>
                <Typography color="text.secondary">
                  Leveraging AI and cutting-edge technology to transform recruitment.
                </Typography>
              </Box>
            </Stack>

            {/* Integrity */}
            <Stack direction="row" spacing={2}>
              <GavelIcon sx={{ color: "text.secondary" }} />
              <Box>
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  Integrity 
                </Typography>
                <Typography color="text.secondary">
                  Building trust through honesty, transparency, and fairness.
                </Typography>
              </Box>
            </Stack>

            {/* Empowerment */}
            <Stack direction="row" spacing={2}>
              <HandshakeIcon sx={{ color: "text.secondary" }} />
              <Box>
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  Empowerment
                </Typography>
                <Typography color="text.secondary">
                  Providing tools and opportunities for job seekers and employers to succeed.
                </Typography>
              </Box>
            </Stack>

            {/* Guidance */}
            <Stack direction="row" spacing={2}>
              <VerifiedIcon sx={{ color: "text.secondary" }} />
              <Box>
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  Guidance
                </Typography>
                <Typography color="text.secondary">
                  Assisting job seekers and employers in making meaningful connections.
                </Typography>
              </Box>
            </Stack>

            {/* Efficiency */}
            <Stack direction="row" spacing={2}>
              <SpeedIcon sx={{ color: "text.secondary" }} />
              <Box>
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  Efficiency 
                </Typography>
                <Typography color="text.secondary">
                  Simplifying and streamlining the hiring process for both job seekers and employers.
                </Typography>
              </Box>
            </Stack>

            {/* Support */}
            <Stack direction="row" spacing={2}>
              <SupportAgentIcon sx={{ color: "text.secondary" }} />
              <Box>
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  Support
                </Typography>
                <Typography color="text.secondary">
                  Connecting job seekers with the right employers and helping them land their dream jobs as soon as possible.
                </Typography>
              </Box>
            </Stack>
          </Stack>
        </Box>
        
        {/* Right Section - "Our Values" with better alignment */}
        <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Paper
            elevation={0}
            sx={{
              bgcolor: "#000",
              color: "white",
              p: 4,
              borderRadius: 2,
              textAlign: 'center',
              minHeight: '250px', // Adjusting height for better alignment
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Typography variant="h1" fontWeight="bold" gutterBottom>
              Our
            </Typography>
            <Typography variant="h1" fontWeight="bold" gutterBottom>
              Values
            </Typography>
          </Paper>
        </Box>
      </Stack>
    </Container>
  )
}

export default WhyVacanzi
