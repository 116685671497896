import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  styled,
  debounce,
  Autocomplete,
  CircularProgress,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
import NonRegisteredAppBar from "../../../components/NavBarNonRegistered/NonRegisteredNavBar";
import baseURL from "../../../api/baseUrl";
import { getTopCitiesApi } from "../../../api/common";
import EmployerVacanziPopup from "./EmployerVacanziPopup";
import SuccessModal from "../../../components/AdvancedComponents/SuccessModal";
import ReleaseJobPostModel from "../SubscriptionStatus/ReleaseJobPostModel";

const StyledContainer = styled(Box)({
  maxWidth: "600px",
  margin: "40px auto",
  padding: "20px",
  borderRadius: "10px",
  backgroundColor: "#ffffff",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.4)",
});

const FileInput = styled("input")({
  display: "none",
});

const StyledTextField = styled(TextField)({
  marginBottom: "20px",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#e0e0e0",
    },
    "&:hover fieldset": {
      borderColor: "#b0b0b0",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#1976d2",
    },
  },
  "& input[type=number]": {
    "-moz-appearance": "textfield",
    "-webkit-appearance": "none",
    appearance: "none",
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      display: "none",
      margin: 0,
    },
  },
});

const EmployeeCountButton = styled(Button)(({ selected }) => ({
  margin: "0 8px 8px 0",
  borderRadius: "20px",
  textTransform: "none",
  padding: "6px 16px",
  backgroundColor: selected ? "#000000" : "#fff",
  color: selected ? "#fff" : "#000",
  border: "1px solid #e0e0e0",
  "&:hover": {
    backgroundColor: selected ? "#000000" : "#f5f5f5",
  },
}));

const SubmitButton = styled(Button)({
  backgroundColor: "#423bfa",
  color: "white",
  padding: "12px",
  fontWeight: "bold",
  fontFamily: "Satoshi",
  fontSize: "16px",
  marginTop: "20px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#423bfa",
  },
});

const employeeRanges = [
  "0-50",
  "51-100",
  "101-300",
  "301-500",
  "501-1000",
  "1000 above",
];

const orgTypeOptions = [
  { name: "Company", value: "company" },
  { name: "MNC", value: "mnc" },
  { name: "Sole Proprietorship", value: "soleProprietorship" },
  { name: "Partnership", value: "partnerShip" },
  {
    name: "Limited Liability Partnership",
    value: "limitedLiabilityPartnership",
  },
  { name: "Private Limited Company", value: "privateLimitedCompany" },
  { name: "Public Limited Company", value: "publicLimitedCompany" },
  { name: "Cooperative Society", value: "cooperativeSociety" },
  { name: "NGO", value: "ngo" },
];

const EmployerRegistration = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedRange, setSelectedRange] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [logoPreview, setLogoPreview] = useState(null);
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [locations, setLocations] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [isTopCity, setIsTopCity] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  const [formData, setFormData] = useState({
    name: location.state?.signupName,
    email: location.state?.signupEmail,
    contactNumber: location.state?.signupPhone,
    role: location.state?.role,
    organizationName: "",
    acceptedTCs: false,
    industryType: "",
    orgType: "",
    isConsultantCompany: false,
    contactPersonName: "",
    website: "",
    numberOfEmployees: "",
    contactPersonNumber: "",
    orgPinCode: "",
    organizationAddress: "",
    orgCity: "",
    orgState: "",
    orgArea: "",
    hrName: "",
    hrphone: "",
    directorName: "",
    companyLogo: null,
    orgCountry: "",
    receiveUpdates: false,
  });

  const handleNext = () => {
    if (!validateStep1()) return;
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleOpenPopup = () => {
    setOpenDialog(true);
  };
  const handleClosePopup = () => {
    setOpenDialog(false);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({ ...formData, companyLogo: file });
      setLogoPreview(URL.createObjectURL(file));
    }
  };

  const validateStep1 = () => {
    const newErrors = {};
    if (!formData.organizationName?.trim()) {
      newErrors.organizationName = "Company name is required";
    }
    if (!formData.orgType) {
      newErrors.orgType = "Organization type is required";
    }
    if (!formData.numberOfEmployees) {
      newErrors.numberOfEmployees = "Please select the number of employees";
    }
    if (!formData.companyLogo) {
      newErrors.companyLogo = "Company logo is required";
    }
    if (!formData.acceptedTCs) {
      newErrors.acceptedTCs = "You must agree to the terms";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.organizationAddress?.trim()) {
      newErrors.organizationAddress = "Organization Address is Required";
    }
    if (!formData.orgArea?.trim()) {
      newErrors.orgArea = "Area is Required";
    }
    if (!formData.orgCity?.trim()) {
      newErrors.orgCity = "City is required";
    }
    if (!formData.hrName?.trim()) {
      newErrors.hrName = "HR Name is Required";
    }
    if (!formData.directorName?.trim()) {
      newErrors.directorName = "Director's Name is required";
    }
    if (!formData.orgPinCode?.trim()) {
      newErrors.orgPinCode = "Pincode is Required";
    }
    if (!formData.website) {
      newErrors.website = "Website is required.";
    } else {
      const websiteRegex =
        /^(www\.)?[a-zA-Z0-9-]+\.(com|in|net|org|co|edu|gov|biz)(\/[a-zA-Z0-9-]*)*$/;
      if (!websiteRegex.test(formData.website)) {
        newErrors.website =
          "Please enter a valid website URL (e.g., www.vacanzi.com, vacanzi.in)";
      }
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleRegisterAccount = async () => {
    if (!validateForm()) return;
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      if (!token) throw new Error("No token found");

      const formDataToSend = new FormData();
      formDataToSend.append("organizationName", formData.organizationName);
      formDataToSend.append("orgType", formData.orgType); // Added orgType
      formDataToSend.append(
        "organizationAddress",
        formData.organizationAddress
      );
      formDataToSend.append("contactNumber", formData.contactNumber);
      formDataToSend.append("email", formData.email);
      formDataToSend.append("numberOfEmployees", formData.numberOfEmployees);
      formDataToSend.append("orgArea", formData.orgArea);
      formDataToSend.append("orgCity", formData.orgCity);
      formDataToSend.append("orgPinCode", formData.orgPinCode);
      formDataToSend.append("hrName", formData.hrName);
      formDataToSend.append("directorName", formData.directorName);
      formDataToSend.append("website", formData.website);
      if (formData.companyLogo) {
        formDataToSend.append("companyLogo", formData.companyLogo);
      }

      const response = await fetch(`${baseURL}/organization/create`, {
        method: "POST",
        headers: { Authorization: `Bearer ${token}` },
        body: formDataToSend,
      });
      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.message || "Registration failed");
      }

      localStorage.setItem("organizationId", responseData?.organization?._id);
      localStorage.setItem(
        "recruiterId",
        responseData?.organization?.createdBy
      );

      setOpenSuccessModal(true);

      setTimeout(() => {
        setOpenSuccessModal(false);
        navigate("/employer/subscriptionStatus");
      }, 2000);
    } catch (error) {
      console.error("Error during registration:", error.message);
      handleError(error.message || "Registration failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    handleRegisterAccount();
  };

  const handleCityChange = (event, newValue) => {
    setFormData((prev) => ({
      ...prev,
      orgCity: newValue || "",
    }));
  };

  const fetchCities = useMemo(
    () =>
      debounce(async (query) => {
        try {
          const response = await getTopCitiesApi(query, isTopCity);
          if (response && response.cities) {
            setLocations(response.cities);
          } else {
            setLocations([]);
          }
        } catch (error) {
          console.log("Error fetching cities:", error.message);
          setLocations([]);
        }
      }, 300),
    [isTopCity]
  );

  useEffect(() => {
    fetchCities(inputValue);
  }, [inputValue, fetchCities]);

  const handleFocus = () => {
    setIsTopCity(true);
    setInputValue("");
    fetchCities("");
  };

  const handleCheckboxChange = (event) => {
    setFormData((prev) => ({
      ...prev,
      isConsultantCompany: event.target.checked,
    }));
  };
const handleAcceptTCs = (event)=>{
  setFormData((prev)=>({
    ...prev,
     acceptedTCs:event.target.checked
  }))
}
  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    if (newInputValue.length === 0) {
      setIsTopCity(true);
    } else {
      setIsTopCity(false);
    }
  };

  const handleCloseSuccessModal = () => {
    setOpenSuccessModal(false);
  };

  const handleError = useCallback((message) => {
    setErrorMessage(message);
    setErrorOpen(true);
    setTimeout(() => {
      setErrorOpen(false);
      setErrorMessage("");
    }, 2000);
  }, []);

  return (
    <>
      <NonRegisteredAppBar phone={location.state?.signupPhone} />

      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress size={24} color="inherit" />
        </Box>
      ) : (
        <StyledContainer>
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            sx={{
              fontWeight: 500,
              color: "#333",
              mb: 4,
            }}
          >
            {activeStep === 0 ? "Let's get you started!" : "Company Details"}
          </Typography>

          <form onSubmit={handleFormSubmit}>
            {activeStep === 0 && (
              <>
                <Typography
                  variant="subtitle1"
                  sx={{
                    mb: 1,
                    color: "#333",
                    fontWeight: "bold",
                    fontFamily: "poppins",
                  }}
                >
                  Company Name
                </Typography>
                <StyledTextField
                  fullWidth
                  placeholder="Enter Your Company Name"
                  variant="outlined"
                  name="organizationName"
                  value={formData.organizationName}
                  onChange={handleChange}
                  error={Boolean(errors.organizationName)}
                  helperText={errors.organizationName}
                />

                <Typography
                  variant="subtitle1"
                  sx={{
                    mb: 1,
                    color: "#333",
                    fontWeight: "bold",
                    fontFamily: "poppins",
                  }}
                >
                  Organization Type
                </Typography>
                <FormControl fullWidth sx={{ mb: 2 }}>
               
                  <Select
                    labelId="org-type-label"
                    id="org-type"
                    name="orgType"
                    value={formData.orgType}
              
                    onChange={handleChange}
                    error={Boolean(errors.orgType)}
                  >
                    {orgTypeOptions.map((type) => (
                      <MenuItem key={type.value} value={type.value}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.orgType && (
                    <Typography
                      sx={{ color: "red", fontSize: "0.8rem", mt: 1 }}
                    >
                      {errors.orgType}
                    </Typography>
                  )}
                </FormControl>

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.isConsultantCompany}
                      onChange={handleCheckboxChange}
                      name="isConsultantCompany"
                    />
                  }
                  label={
                    <Typography variant="body2" sx={{ fontFamily: "poppins" }}>
                      This is a consultancy (Hiring or staffing agency)
                    </Typography>
                  }
                  sx={{ mb: 2 }}
                />

                <Typography
                  variant="subtitle1"
                  sx={{ mt: 2, mb: 1, fontWeight: "bold" }}
                >
                  Company Logo
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <label htmlFor="upload-logo">
                    <FileInput
                      accept="image/*"
                      id="upload-logo"
                      type="file"
                      onChange={handleFileChange}
                    />
                    <Button
                      variant="contained"
                      component="span"
                      sx={{
                        backgroundColor: "#000000",
                        borderRadius: "0.7rem",
                        ":hover": { backgroundColor: "#000000" },
                      }}
                    >
                      Upload Logo
                    </Button>
                  </label>
                  {logoPreview && (
                    <img
                      src={logoPreview}
                      alt="Company Logo"
                      width={50}
                      height={50}
                      style={{ borderRadius: "5px" }}
                    />
                  )}
                </Box>
                {!formData.companyLogo && errors.companyLogo && (
                  <Typography variant="body2" sx={{ color: "red", mt: 1 }}>
                    {errors.companyLogo}
                  </Typography>
                )}

                <Typography
                  variant="subtitle1"
                  sx={{
                    mb: 1,
                    color: "#333",
                    fontWeight: "bold",
                    fontFamily: "poppins",
                  }}
                >
                  Number of employees in your company
                </Typography>
                <Box>
                  {employeeRanges.map((range) => (
                    <EmployeeCountButton
                      key={range}
                      selected={selectedRange === range}
                      onClick={() => {
                        setSelectedRange(range);
                        setFormData({
                          ...formData,
                          numberOfEmployees: range,
                        });
                      }}
                      variant="outlined"
                    >
                      {range}
                    </EmployeeCountButton>
                  ))}
                </Box>
                {errors.numberOfEmployees && (
                  <Typography sx={{ color: "red", fontSize: "0.8rem", mb: 3 }}>
                    {errors.numberOfEmployees}
                  </Typography>
                )}

                <FormControlLabel
                  control={
                    <Checkbox
                    checked={formData.acceptedTCs}
                      onChange={handleAcceptTCs}
                    />
                  }
                  label={
                    <Typography variant="body2" sx={{ fontFamily: "poppins" }}>
                      I agree to Vacanzi's{" "}
                      <span
                        style={{
                          color: "#423bfa",
                          textDecoration: "underline",
                        }}
                        onClick={() => navigate("/terms-&-cons")}
                      >
                        {" "}
                        Terms of Services and Privacy policy
                      </span>
                    </Typography>
                  }
                  sx={{ mb: 2 }}
                />
                {errors.acceptedTCs && (
                  <Typography sx={{ color: "red", fontSize: "0.8rem" }}>
                    {errors.acceptedTCs}
                  </Typography>
                )}

                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "poppins",
                    color: "#423bfa",
                    cursor: "pointer",
                  }}
                  onClick={handleOpenPopup}
                  textAlign="center"
                >
                  Why Vacanzi & Cultural Video
                </Typography>

                <SubmitButton
                  type="button"
                  variant="contained"
                  fullWidth
                  disabled={!formData.acceptedTCs}
                  onClick={handleNext}
                >
                  Next
                </SubmitButton>
              </>
            )}

            {activeStep === 1 && (
              <>
                <Typography
                  variant="body2"
                  sx={{
                    mt: 1,
                    mb: 0.5,
                    fontWeight: "bold",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                >
                  Corporate Address
                </Typography>
                <StyledTextField
                  fullWidth
                  placeholder="Enter Your Address"
                  variant="outlined"
                  name="organizationAddress"
                  value={formData.organizationAddress}
                  onChange={handleChange}
                  error={!!errors.organizationAddress}
                  helperText={errors.organizationAddress}
                />

                <Typography
                  variant="body2"
                  sx={{
                    mt: 1,
                    mb: 0.5,
                    fontWeight: "bold",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                >
                  Area
                </Typography>
                <StyledTextField
                  fullWidth
                  placeholder="Enter Your Area"
                  variant="outlined"
                  name="orgArea"
                  value={formData.orgArea}
                  onChange={handleChange}
                  error={!!errors.orgArea}
                  helperText={errors.orgArea}
                />

                <Typography
                  variant="body2"
                  sx={{
                    mt: 1,
                    mb: 0.5,
                    fontWeight: "bold",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                >
                  City
                </Typography>
                <Autocomplete
                  fullWidth
                  freeSolo
                  options={locations.map((city) => city.name)}
                  getOptionLabel={(option) => option}
                  value={formData.orgCity || ""}
                  onInputChange={handleInputChange}
                  onChange={(event, newValue) => {
                    handleCityChange(event, newValue);
                  }}
                  onFocus={handleFocus}
                  ListboxProps={{
                    style: {
                      maxHeight: "200px",
                      fontFamily: "Satoshi",
                      fontWeight: "bold",
                      overflowY: "auto",
                      scrollbarWidth: "none",
                      msOverflowStyle: "none",
                      "&::-webkit-scrollbar": {
                        display: "none",
                      },
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Search Your City"
                      sx={{ fontFamily: "Satoshi" }}
                      error={!!errors.orgCity}
                      helperText={errors.orgCity}
                    />
                  )}
                />

                <Typography
                  variant="body2"
                  sx={{
                    mt: 1,
                    mb: 0.5,
                    fontWeight: "bold",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                >
                  Pincode
                </Typography>
                <StyledTextField
                  fullWidth
                  placeholder="Enter Your Pincode"
                  variant="outlined"
                  name="orgPinCode"
                  type="number"
                  value={formData.orgPinCode}
                  onChange={handleChange}
                  error={!!errors.orgPinCode}
                  helperText={errors.orgPinCode}
                />

                <Typography
                  variant="body2"
                  sx={{
                    mt: 1,
                    mb: 0.5,
                    fontWeight: "bold",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                >
                  Director's Name
                </Typography>
                <StyledTextField
                  fullWidth
                  placeholder="Enter Director's Name"
                  variant="outlined"
                  name="directorName"
                  value={formData.directorName}
                  onChange={handleChange}
                  error={!!errors.directorName}
                  helperText={errors.directorName}
                />

                <Typography
                  variant="body2"
                  sx={{
                    mt: 1,
                    mb: 0.5,
                    fontWeight: "bold",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                >
                  HR Name
                </Typography>
                <StyledTextField
                  fullWidth
                  placeholder="Enter HR Name"
                  variant="outlined"
                  name="hrName"
                  value={formData.hrName}
                  onChange={handleChange}
                  error={!!errors.hrName}
                  helperText={errors.hrName}
                />

                <Typography
                  variant="body2"
                  sx={{
                    mt: 1,
                    mb: 0.5,
                    fontWeight: "bold",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                >
                  Website URL
                </Typography>
                <StyledTextField
                  fullWidth
                  placeholder="Website URL"
                  variant="outlined"
                  name="website"
                  value={formData.website}
                  onChange={handleChange}
                  error={!!errors.website}
                  helperText={errors.website}
                />

                <SubmitButton type="submit" variant="contained" fullWidth>
                  Submit
                </SubmitButton>
              </>
            )}
          </form>
        </StyledContainer>
      )}
      <EmployerVacanziPopup open={openDialog} onClose={handleClosePopup} />
      <SuccessModal open={openSuccessModal} onClose={handleCloseSuccessModal} />
      <ReleaseJobPostModel
        modelOpen={errorOpen}
        onClose={() => setErrorOpen(false)}
        message={errorMessage}
      />
    </>
  );
};

export default EmployerRegistration;
