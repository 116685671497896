import React, { useState } from "react";
import { Box, Paper } from "@mui/material";
import { Button, CardContent, Collapse, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AccountDetailsModel from "../../../../components/EmployerComponents/CompanyProfileComponents/AccountDetailsModel";

const AccountDetails = ({
  openDialog,
  handleCloseDialog,
  handleSaveDialog,
  handleEdit,
  orgData,
}) => {
  const [expanded, setExpanded] = useState(true);

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <Paper
      elevation={3}
      sx={{
        backgroundColor: "#FFF",
        borderRadius: "12px",
        overflow: "hidden",
        width: "100%",
      }}
    >
      <CardContent sx={{ cursor: "pointer" }} onClick={handleToggleExpand}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Typography variant="h6" sx={{  fontWeight: "bold",fontFamily:"poppins" }}>Account Details</Typography>
          {expanded ? (
            <KeyboardArrowDownIcon
              sx={{
                fontSize:'40px',
                cursor: "pointer",
                color: "gray",
                transition: "transform 0.3s ease",
              }}
            />
          ) : (
            <ArrowForwardIosIcon
              sx={{
                cursor: "pointer",
                color: "gray",
                transition: "transform 0.3s ease",
              }}
            />
          )}
        </Box>
      </CardContent>

      <Collapse in={expanded}>
        <CardContent>
          {orgData?.recruiterAdmins.map((account, index) => (
            <Box key={index} sx={{ mb: 2 }}>
              <Typography
                gutterBottom
                variant="body2"
                sx={{ fontSize: "1rem", color: "#000", fontFamily:"poppins" }}
              >
                <strong> Role:</strong> {account.role}
              </Typography>
              <Typography
                gutterBottom
                variant="body2"
                sx={{ fontSize: "1rem", color: "#000", fontFamily: "poppins" }}
              >
                <strong> Reporting Manager: </strong> {account.rcName}
              </Typography>
              <Typography
                gutterBottom
                variant="body2"
                sx={{ fontSize: "1rem", color: "#000", fontFamily: "poppins" }}
              >
                <strong> Mobile Number: </strong> {account.rcPhone}
              </Typography>
            </Box>
          ))}

          <AccountDetailsModel
            openDialog={openDialog}
            handleCloseDialog={handleCloseDialog}
            handleSaveDialog={handleSaveDialog}
            orgData={orgData}
          />

          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Button
              sx={{ borderRadius: "0.7rem", backgroundColor: "#423BFA",fontFamily:"poppins",textTransform:"none" }}
              variant="contained"
              onClick={handleEdit}
              disabled
            >
              Edit
            </Button>
          </Box>
        </CardContent>
      </Collapse>
    </Paper>
  );
};

export default AccountDetails;
