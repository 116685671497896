import React, { useRef, useState, useCallback, memo } from "react";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Avatar,
  styled,
  TextField,
  Slider,
  InputAdornment,
  IconButton,
} from "@mui/material";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import VideoCameraFrontOutlinedIcon from "@mui/icons-material/VideoCameraFrontOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import BrokenImageOutlinedIcon from "@mui/icons-material/BrokenImageOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Cropper from "react-easy-crop";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import baseURL from "../../../../api/baseUrl";
import "react-image-crop/dist/ReactCrop.css";
import VacanziBenefitsModal from "../../../VacanziVideoModal";

// Styled Components
const DarkDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    backgroundColor: "#FFFFFFFF",
    color: "#000000FF",
    borderRadius: "12px",
    maxWidth: "850px",
  },
}));

const DialogHeader = styled(DialogTitle)(() => ({
  fontSize: "24px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  fontFamily: "poppins",
  fontWeight: 600,
  padding: "24px",
}));

const UploadSection = styled(Box)(({ theme }) => ({
  borderRadius: "8px",
  width: "250px",
  display: "flex",
  color: "#000000",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  transition: "border-color 0.2s",
  height: "200px",
  "&:hover": { borderColor: "#00897b" },
}));

const PreviewAvatar = styled(Box)(({ theme }) => ({
  width: "140px",
  height: "140px",
  borderRadius: "50%",
  backgroundColor: "#FFFFFFFF",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#000000",
  fontSize: "32px",
  marginBottom: "16px",
}));

const VideoPreview = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "140px",
  backgroundColor: "#000000",
  borderRadius: "8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: "16px",
}));

const ResumePreview = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "140px",
  backgroundColor: "#eeeeee",
  borderRadius: "8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: "16px",
  color: "#000000FF",
  fontWeight: "bold",
}));

const UploadButton = styled(Button)(({ theme }) => ({
  color: "#000000FF",
  fontFamily: "poppins",
  textTransform: "none",
  backgroundColor: "rgba(0, 137, 123, 0.1)",
  borderColor: "rgba(255, 255, 255, 0.2)",
  "&:hover": {
    borderColor: "#00897b",
    backgroundColor: "rgba(0, 137, 123, 0.1)",
  },
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: "0 24px 24px 24px",
}));

const Container = styled(Box)(() => ({
  backgroundColor: "#ffffff",
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "48px 24px",
}));

const ProfileAvatar = styled(Avatar)(({ theme }) => ({
  width: 140,
  height: 140,
  objectFit: "cover",
  backgroundColor: "#423bfa",
  fontSize: "2.5rem",
  marginBottom: theme.spacing(2),
  border: "4px solid #ffffff",
  boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
}));

const ActionButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#353232FF",
  color: "#ffffff",
  borderRadius: "0.7rem",
  padding: "10px",
  textTransform: "none",
  fontSize: "1rem",
  fontWeight: 500,
  "&:hover": { backgroundColor: "#000000FF" },
  "& .icon": { marginRight: theme.spacing(1) },
  width: "220px",
  justifyContent: "center",
  margin: theme.spacing(2),
}));

const VideoButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#423bfa",
  color: "#ffffff",
  borderRadius: "0.7rem",
  padding: "10px",
  textTransform: "none",
  fontSize: "1rem",
  fontWeight: 500,
  "&:hover": { backgroundColor: "#423bfa" },
  "&.Mui-disabled": { backgroundColor: "grey", color: "#d3d3d3" },
  "& .icon": { marginRight: theme.spacing(1) },
  width: "220px",
  justifyContent: "center",
  margin: theme.spacing(2),
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: "2.5rem",
  fontWeight: 700,
  marginBottom: theme.spacing(6),
  textAlign: "center",
  color: "#000000",
}));

// const PhoneNumberButton = styled(Button)(({ theme }) => ({
//   backgroundColor: "#f5f5f5",
//   color: "#000",
//   border: "1px solid #ccc",
//   borderRadius: "4px",
//   textTransform: "none",
//   fontFamily: "IBM Plex Sans",
//   fontWeight: "bold",
//   padding: "8px 12px",
//   height: "40px",
//   width: "200px",
//   justifyContent: "space-between",
//   "&:hover": {
//     backgroundColor: "#e0e0e0",
//   },
// }));

// Updated PhoneInput Component with Validation
const PhoneInput = memo(function PhoneInput({
  textInput,
  setTextInput,
  showSuccess,
  onSendLink,
}) {
  const [error, setError] = useState(""); // State for error message

  const isValidPhoneNumber = textInput && /^[6-9][0-9]{9}$/.test(textInput); // Check if valid Indian phone number
  const [isEditable, setIsEditable] = useState(false); // Initially disabled

  const handleChange = useCallback(
    (e) => {
      const input = e.target.value;
      // Allow only digits and limit to 10 characters
      if (/^[0-9]{0,10}$/.test(input)) {
        setTextInput(input);
        // Validate while typing
        if (input && !/^[6-9][0-9]{0,9}$/.test(input)) {
          setError("Must start with 6, 7, 8, or 9 and be 10 digits");
        } else if (input.length === 10 && !/^[6-9][0-9]{9}$/.test(input)) {
          setError("Please enter a valid 10-digit Indian phone number");
        } else {
          setError("");
        }
      }
    },
    [setTextInput]
  );


  const handleEditClick = () => {
    setIsEditable(true); // Enable the input field
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        mt: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 2,
        }}
      >
        <Box
          sx={{ display: "flex", flexDirection: "column", alignItems: "start" }}
        >
          <Typography
            sx={{ fontWeight: "bold", fontFamily: "IBM Plex Sans", mb: 1 }}
          >
            Phone Number
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            value={textInput}
            onChange={handleChange}
            sx={{ width: "200px", mb: 2 }}
            placeholder="Enter 10-digit number"
            inputProps={{ maxLength: 10 }}
            autoFocus={isEditable}
            error={!!error}
            helperText={error}
            disabled={!isEditable} // Initially disabled
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={handleEditClick}>
                    <EditOutlinedIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Button
          variant="contained"
          onClick={() => onSendLink(textInput)}
          disabled={!isValidPhoneNumber} // Disable if invalid
          sx={{
            mt: 2,
            backgroundColor: "#423bfa",
            color: "white",
            fontWeight: "bold",
            borderRadius: "0.2rem",
            padding: "0.4rem",
            fontSize: "14px",
            "&:hover": {
              backgroundColor: "#B0F0F0",
              color: "#000",
            },
            textTransform: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            opacity: !isValidPhoneNumber ? 0.5 : 1, // Dim when disabled
          }}
        >
          Send App link to mobile
        </Button>
      </Box>
      {showSuccess ? (
        <Typography textAlign="center" variant="body2" color="green">
          App link Sent Successfully!
        </Typography>
      ) : null}
    </Box>
  );
});

// Main Component
const JobSeekerCaseFour = memo(
  ({
    handleChange,
    formData,
    isVideoButtonDisabled,
    setIsVideoButtonDisabled,
    handleSuccess,
    handleError,
  }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedImageUrl, setCroppedImageUrl] = useState(null);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [openCropDialog, setOpenCropDialog] = useState(false);
    const [imageSrc, setImageSrc] = useState(null);
    const [videoSrc, setVideoSrc] = useState(null);
    const [error, setError] = useState("");
    const [resumeName, setResumeName] = useState("");
    const [textInput, setTextInput] = useState(formData?.phone || "");
    const [cropSize, setCropSize] = useState({ width: 200, height: 200 });
    const [showSuccess, setShowSuccess] = useState();
    const fileInputRef = useRef(null);
    const videoInputRef = useRef(null);
    const pdfInputRef = useRef(null);

    const authToken = localStorage.getItem("authToken");
    const userId = localStorage.getItem("userId");

    const handleDialogOpen = useCallback(() => setOpenDialog(true), []);
    const handleDialogClose = useCallback(() => setOpenDialog(false), []);

    const [modalOpen, setModalOpen] = useState(false);

    const handleOpenModal = () => setModalOpen(true);
    const handleCloseModal = () => setModalOpen(false);

    const handleImageSelect = useCallback((event) => {
      const file = event.target.files[0];
      if (file) {
        const url = URL.createObjectURL(file);
        setImageSrc(url);
        setOpenCropDialog(true);
      }
    }, []);

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
      setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const handleCropComplete = useCallback(async () => {
      if (imageSrc && croppedAreaPixels) {
        const image = new Image();
        image.src = imageSrc;
        image.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          canvas.width = croppedAreaPixels.width;
          canvas.height = croppedAreaPixels.height;
          ctx.drawImage(
            image,
            croppedAreaPixels.x,
            croppedAreaPixels.y,
            croppedAreaPixels.width,
            croppedAreaPixels.height,
            0,
            0,
            croppedAreaPixels.width,
            croppedAreaPixels.height
          );
          canvas.toBlob(
            (blob) => {
              const file = new File([blob], "cropped-profile.jpg", {
                type: "image/jpeg",
              });
              setCroppedImageUrl(URL.createObjectURL(file));
              handleChange({ target: { name: "profileImage", value: file } });
              setOpenCropDialog(false);
            },
            "image/jpeg",
            0.9
          );
        };
      }
    }, [imageSrc, croppedAreaPixels, handleChange]);

    const handleCropSizeChange = useCallback((event, newValue, type) => {
      setCropSize((prevSize) => ({ ...prevSize, [type]: newValue }));
    }, []);

    const handleVideoSelect = useCallback(
      (event) => {
        const file = event.target.files[0];
        if (file) {
          const allowedTypes = [
            "video/mp4",
            "video/avi",
            "video/quicktime", // .mov files
            "video/webm",
          ];
    
          if (!allowedTypes.includes(file.type)) {
            setError("Invalid file type. Please upload a video in MP4, AVI, MOV, or WebM format.");
            return;
          }
    
         
          setError("");
          handleChange({ target: { name: "videoCV", value: file } });
          setVideoSrc(URL.createObjectURL(file));
        }
      },
      [handleChange]
    );

    const handleResumeSelect = useCallback(
      (event) => {
        const file = event.target.files[0];
        handleChange({ target: { name: "resume", value: file } });
        setResumeName(file?.name);
      },
      [handleChange]
    );

    const handleImageUpload = useCallback(
      () => fileInputRef.current.click(),
      []
    );
    const handleResumeUpload = useCallback(
      () => pdfInputRef.current.click(),
      []
    );

    const handleSendAppLink = useCallback(
      async (phoneNumber) => {
        try {
          if (!phoneNumber || phoneNumber.length !== 10) {
            handleError('Please enter a valid 10-digit phone number.');
            return;
          }
          if (!authToken || !userId) {
            const apiUrl = `${baseURL}/sendAppLinkToNewUser`;
            const response = await fetch(apiUrl, {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({ phoneNumber }),
            });
            if (!response.ok) throw new Error("Failed to send app link to new user.");
            
            const data = await response.json();
            handleSuccess(data?.message || "App link sent successfully");
            
            setIsVideoButtonDisabled(false);
            return;
          }
          const apiUrl = `${baseURL}/sendAppLink/${userId}`;
          const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          });
          if (!response.ok) {
            handleError('Failed to send notification; WhatsApp service not active');
          }
    
          const data = await response.json();
          handleSuccess(data?.message || "App link sent successfully");
          
          setShowSuccess(true);
          setIsVideoButtonDisabled(false);
        } catch (error) {
          handleError(error.message || "Error sending app link");
        }
      },
      [authToken, userId, handleError, handleSuccess, setIsVideoButtonDisabled]
    );
    

    return (
      <Container sx={{ mb: 6 }}>
        <Title variant="h1">Set up your profile</Title>
        <ProfileAvatar>
          {formData.profileImage ? (
            <img
              src={URL.createObjectURL(formData.profileImage)}
              alt="Profile"
              style={{ width: "100%", height: "100%", borderRadius: "50%" }}
            />
          ) : (
            "VD"
          )}
        </ProfileAvatar>
        <Box>
          <ActionButton
            onClick={handleDialogOpen}
            startIcon={<CloudUploadOutlinedIcon className="icon" />}
          >
            {formData.profileImage ? "Change Image" : "Upload Image"}
          </ActionButton>
          <ActionButton
            onClick={handleDialogOpen}
            startIcon={<InsertDriveFileOutlinedIcon className="icon" />}
          >
            {formData.resume ? "Change Resume" : "Upload Resume"}
          </ActionButton>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Title variant="h1">Download the App Now</Title>

          <Box
            component="img"
            src="/qrJobseeker.png"
            sx={{ width: "200px", height: "200px" }}
          />
          <Typography
            sx={{ fontSize: "12px", mt: 2, fontFamily: "IBM plex sans" }}
          >
            Scan this QR code to download Vacanzi App
          </Typography>
          <VideoButton
            onClick={handleDialogOpen}
            startIcon={<VideoCameraFrontOutlinedIcon className="icon" />}
            disabled={isVideoButtonDisabled}
            endIcon={<EditOutlinedIcon sx={{ ml: 2 }} />}
          >
            {formData.videoCV ? "Change Video" : "Upload Video"}
          </VideoButton>
          {!formData.videoCV && (
            <Typography
              sx={{
                fontSize: "12px",
                color: "#000",
                fontFamily: "IBM plex sans",
              }}
            >
              Upload Video
            </Typography>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              mt: 2,
            }}
          >
            <Typography
              sx={{
                fontSize: "18px",
                fontFamily: "IBM Plex Sans",
                fontWeight: "bold",
                cursor: "pointer",
                color: "#423bfa",
              }}
              onClick={handleOpenModal}
            >
              Upload video through app?
            </Typography>
            <PhoneInput
              showSuccess={showSuccess}
              textInput={textInput}
              setTextInput={setTextInput}
              onSendLink={handleSendAppLink}
              onClose={() => setTextInput("")} // Reset input on close
            />
          </Box>
          <Typography
            sx={{
              fontSize: "24px",
              mt: 2,
              fontFamily: "IBM plex sans",
              color: "#000",
              fontWeight: 600,
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={handleOpenModal} // Added to open the modal
          >
            Why Vacanzi Video?
          </Typography>
          <VacanziBenefitsModal open={modalOpen} onClose={handleCloseModal} />

          {/* <Typography sx={{ fontSize: "14px", fontFamily: "IBM plex sans" }}>
            "Showcase your personality, communication, and skills to impress
            recruiters instantly!"
          </Typography> */}
        </Box>

        <DarkDialog
          open={openDialog}
          onClose={handleDialogClose}
          maxWidth="md"
          fullWidth
        >
          <DialogHeader>
            <Typography
              variant="h5"
              sx={{ fontFamily: "poppins", fontWeight: 500 }}
              textAlign="center"
            >
              Upload Image, Video & Resume
            </Typography>

            <CloseOutlinedIcon
              sx={{ cursor: "pointer" }}
              onClick={handleDialogClose}
            />
          </DialogHeader>
          <StyledDialogContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <UploadSection>
                  <PreviewAvatar>
                    {imageSrc ? (
                      <img
                        src={
                          formData.profileImage
                            ? URL.createObjectURL(formData.profileImage)
                            : croppedImageUrl || ""
                        }
                        alt="Preview"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          borderRadius: "50%",
                        }}
                      />
                    ) : (
                      "VD"
                    )}
                  </PreviewAvatar>
                </UploadSection>

                <UploadButton
                  onClick={handleImageUpload}
                  variant="outlined"
                  startIcon={<BrokenImageOutlinedIcon size={20} />}
                  fullWidth
                >
                  {formData.profileImage ? "Change Image" : "Upload Image"}
                </UploadButton>
                <input
                  ref={fileInputRef}
                  type="file"
                  accept="image/*"
                  hidden
                  onChange={handleImageSelect}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <UploadSection>
                  <VideoPreview>
                    {videoSrc ? (
                      <video
                        src={videoSrc}
                        controls
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <VideoCameraFrontOutlinedIcon
                        size={40}
                        color="#ffffff"
                        opacity={0.5}
                      />
                    )}
                  </VideoPreview>
                </UploadSection>
                <UploadButton
                  onClick={() => videoInputRef.current.click()}
                  variant="outlined"
                  startIcon={<VideoCameraFrontOutlinedIcon size={20} />}
                  fullWidth
                >
                  {formData.videoCV ? "Change Video" : "Upload Video"}
                </UploadButton>
                <input
                  ref={videoInputRef}
                  type="file"
                  accept="video/*"
                  hidden
                  onChange={handleVideoSelect}
                />
                {error && (
                  <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                    {error}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} md={4}>
                <UploadSection>
                  <ResumePreview>
                    {resumeName ? (
                      <Typography variant="body1" noWrap>
                        {resumeName}
                      </Typography>
                    ) : (
                      <InsertDriveFileOutlinedIcon size={40} color="#00897b" />
                    )}
                  </ResumePreview>
                </UploadSection>
                <UploadButton
                  onClick={handleResumeUpload}
                  variant="outlined"
                  startIcon={<InsertDriveFileOutlinedIcon size={20} />}
                  fullWidth
                >
                  {formData.resume ? "Change Resume" : "Upload Resume"}
                </UploadButton>
                <input
                  ref={pdfInputRef}
                  type="file"
                  accept="application/pdf"
                  hidden
                  onChange={handleResumeSelect}
                />
              </Grid>
            </Grid>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                onClick={handleDialogClose}
                variant="contained"
                sx={{
                  backgroundColor: "#000000FF",
                  textTransform: "none",
                  borderRadius: "0.7rem",
                  mt: 5,
                  color: "white",
                  "&:hover": { backgroundColor: "#000000FF" },
                }}
              >
                Done
              </Button>
            </Box>
          </StyledDialogContent>
        </DarkDialog>

        <Dialog open={openCropDialog} onClose={() => setOpenCropDialog(false)}>
          <DialogHeader>
            <Typography
              sx={{ fontFamily: "poppins", fontSize: "22px", fontWeight: 500 }}
            >
              Crop Profile Image
            </Typography>
            <CloseOutlinedIcon
              sx={{ cursor: "pointer" }}
              onClick={() => setOpenCropDialog(false)}
            />
          </DialogHeader>
          <DialogContent>
            {imageSrc && (
              <div
                style={{
                  position: "relative",
                  width: "400px",
                  height: "300px",
                }}
              >
                <Cropper
                  image={imageSrc}
                  crop={crop}
                  zoom={zoom}
                  aspect={cropSize.width / cropSize.height}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                  cropSize={{ width: cropSize.width, height: cropSize.height }}
                />
              </div>
            )}
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle1">Adjust Crop Size</Typography>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <Box>
                  <Typography variant="body2">
                    Width: {cropSize.width}px
                  </Typography>
                  <Slider
                    value={cropSize.width}
                    onChange={(e, newValue) =>
                      handleCropSizeChange(e, newValue, "width")
                    }
                    min={50}
                    max={500}
                    step={10}
                    valueLabelDisplay="auto"
                  />
                </Box>
                <Box>
                  <Typography variant="body2">
                    Height: {cropSize.height}px
                  </Typography>
                  <Slider
                    value={cropSize.height}
                    onChange={(e, newValue) =>
                      handleCropSizeChange(e, newValue, "height")
                    }
                    min={50}
                    max={500}
                    step={10}
                    valueLabelDisplay="auto"
                  />
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
              <Button
                onClick={handleCropComplete}
                variant="contained"
                sx={{
                  backgroundColor: "#000000FF",
                  textTransform: "none",
                  borderRadius: "0.7rem",
                  color: "white",
                  "&:hover": { backgroundColor: "#000000FF" },
                }}
              >
                Done
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      </Container>
    );
  }
);

export default JobSeekerCaseFour;
