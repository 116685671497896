import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, isSupported } from "firebase/messaging";

// Firebase configuration
const firebaseConfig = {
  // apiKey: process.env.REACT_APP_API_KEY,
  // authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  // projectId: process.env.REACT_APP_PROJECT_ID,
  // storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  // messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  // appId: process.env.REACT_APP_APP_ID,
  // measurementId: process.env.REACT_APP_MEASUREMENT_ID

  apiKey: "AIzaSyAKHZWjC7jFDqcDfwohdUJli_Fs-rq-vEw",
 authDomain: "vacanzi.firebaseapp.com",
 projectId: "vacanzi",
 storageBucket: "vacanzi.appspot.com",
 messagingSenderId: 826573670917,
 appId: "1:826573670917:web:1d1c7aab9595f7293b6a6c",
 measurementId: "G-FLM5YZ20D7"

};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Analytics (optional)
const analytics = getAnalytics(app);

// Check if Firebase Messaging is supported before initializing
let messaging = null;
isSupported().then((supported) => {
  if (supported) {
    messaging = getMessaging(app);
    console.log("Firebase Messaging initialized:", messaging);
  } else {
    console.warn("Firebase Messaging is not supported in this browser.");
  }
});

export { app, analytics, messaging };
