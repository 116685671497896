import { useCallback, useState } from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  Typography,
  Box,
  TextField,
} from "@mui/material";
import { styled, keyframes } from "@mui/system";
import { ThreeDot } from "react-loading-indicators";
import { reportAnIssueApi } from "../../api/common";
import PopupSuccess from "../../utils/popup/PopupSuccess";
import ReleaseJobPostModel from "../EmployerPages/SubscriptionStatus/ReleaseJobPostModel";

// Animation styles
const fadeIn = keyframes`
    from { opacity: 0; transform: translateY(-10px); }
    to { opacity: 1; transform: translateY(0); }
  `;

// Styled components
const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    borderRadius: 16,
    boxShadow: "0 8px 32px rgba(0, 0, 0, 0.1)",
    overflow: "hidden",
    animation: `${fadeIn} 0.3s ease-out`,
    maxHeight: "90vh",
    "&::-webkit-scrollbar": { display: "none" },
    msOverflowStyle: "none",
    scrollbarWidth: "none",
  },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: "#423bfa",
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(3),
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(4),
}));

const StyledRadioGroup = styled(RadioGroup)(({ theme }) => ({
  "& .MuiFormControlLabel-root": {
    marginBottom: theme.spacing(2),
  },
}));

const StyledRadio = styled(Radio)(({ theme }) => ({
  "&.Mui-checked": {
    color: theme.palette.secondary.main,
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: "0.7rem",
  padding: theme.spacing(1, 4),
  textTransform: "none",
  color: "#fff",
  backgroundColor: "#000",
  fontWeight: 600,
  transition: "all 0.3s ease",
  "&:hover": {
    transform: "translateY(-2px)",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
    color: "#fff",
    backgroundColor: "#000",
  },
  "&.Mui-disabled": {
    color: "#aaa", // Gray text when disabled
    backgroundColor: "#ddd", // Light gray background when disabled
    boxShadow: "none",
    transform: "none",
    cursor: "not-allowed",
  },
}));

const ReportIssueDialogBox = ({
  openReportDialog,
  handleCloseReportDialog,
  userId,
  authToken,
  reportOptions,
}) => {
  const [guestDetails, setGuestDetails] = useState({
    name: "",
    email: "",
    phone: "",
    topic: "report issue",
    messageContent: "",
    supportType: "general",
  });

  const [errors, setErrors] = useState({
    email: false,
    phone: false,
  });

  const [selectedReason, setSelectedReason] = useState(""); // Tracks selected radio value
  const [customReason, setCustomReason] = useState(""); // Tracks custom input
  const [loading, setLoading] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSuccess = useCallback((message) => {
    setSuccessMessage(message);
    setSuccessOpen(true);
    setTimeout(() => {
      setSuccessOpen(false);
      setSuccessMessage("");
    }, 2000);
  }, []);

  const handleError = useCallback((message) => {
    setErrorMessage(message);
    setErrorOpen(true);
    setTimeout(() => {
      setErrorOpen(false);
      setErrorMessage("");
    }, 2000);
  }, []);

  // Validation functions
  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const validatePhone = (phone) => /^\d{10}$/.test(phone); // Ensures 10-digit number

  // Handle input changes for guest users
  const handleGuestInputChange = (e) => {
    const { name, value } = e.target;
    let isValid = true;

    if (name === "email") {
      isValid = validateEmail(value);
      setErrors((prev) => ({ ...prev, email: !isValid }));
    }
    if (name === "phone") {
      isValid = validatePhone(value);
      setErrors((prev) => ({ ...prev, phone: !isValid }));
    }

    setGuestDetails((prev) => ({ ...prev, [name]: value }));
  };

  // Handle radio button change
  const handleReasonChange = (event) => {
    const value = event.target.value;
    setSelectedReason(value);

    // If "Other" is selected, wait for input, else update messageContent directly
    if (value === "other") {
      setGuestDetails((prev) => ({ ...prev, messageContent: "" }));
    } else {
      setGuestDetails((prev) => ({ ...prev, messageContent: value }));
    }
  };

  // Handle custom input change for "Other"
  const handleCustomReasonChange = (e) => {
    const value = e.target.value;
    setCustomReason(value);
    setGuestDetails((prev) => ({ ...prev, messageContent: value }));
  };

  // Handle report submission
  const reportAnIssue = async () => {
    setLoading(true);
    try {
      await reportAnIssueApi(authToken, guestDetails);

      handleCloseReportDialog();
      handleSuccess(
        "Support ticket created successfully with an initial message"
      );
    } catch (error) {
      console.log(error.message);
      handleError(error.message || "Failed to report the issue");
    } finally {
      setLoading(false);
    }
  };

  const isSubmitDisabled =
    !selectedReason ||
    (!userId &&
      (!guestDetails.name.trim() ||
        !guestDetails.email.trim() ||
        !guestDetails.phone.trim() ||
        errors.email ||
        errors.phone));

  return (
    <StyledDialog
      open={openReportDialog}
      onClose={handleCloseReportDialog}
      fullWidth
      maxWidth="sm"
    >
      <StyledDialogTitle sx={{ fontFamily: "Satoshi" }}>
        Report Issue
      </StyledDialogTitle>

      <StyledDialogContent>
        {/* Show guest input fields if no userId or authToken */}
        {!userId || !authToken ? (
          <>
            <Typography
              variant="subtitle1"
              gutterBottom
              sx={{ fontFamily: "Satoshi", mt: 2 }}
            >
              Please provide your details:
            </Typography>
            <TextField
              fullWidth
              label="Name"
              name="name"
              value={guestDetails.name}
              onChange={handleGuestInputChange}
              required
              sx={{ mt: 2 }}
            />
            <TextField
              fullWidth
              label="Email"
              name="email"
              type="email"
              value={guestDetails.email}
              onChange={handleGuestInputChange}
              required
              error={errors.email}
              helperText={errors.email ? "Enter a valid email" : ""}
              sx={{ mt: 2 }}
            />
            <TextField
              fullWidth
              label="Phone Number"
              name="phone"
              type="tel"
              value={guestDetails.phone}
              onChange={handleGuestInputChange}
              required
              error={errors.phone}
              helperText={errors.phone ? "Enter a 10-digit phone number" : ""}
              sx={{ mt: 2 }}
            />
          </>
        ) : null}

        <Typography
          variant="subtitle1"
          gutterBottom
          sx={{ fontFamily: "Satoshi", mt: 3 }}
        >
          Please select a reason for your report:
        </Typography>

        <StyledRadioGroup value={selectedReason} onChange={handleReasonChange}>
          {reportOptions?.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={<StyledRadio />}
              label={
                <Box>
                  <Typography variant="body1" sx={{ fontFamily: "Satoshi" }}>
                    {option.label}
                  </Typography>
                </Box>
              }
            />
          ))}

          {/* Other option with text field */}
          <FormControlLabel
            value="other"
            control={<StyledRadio />}
            label={
              <Box>
                <Typography variant="body1" sx={{ fontFamily: "Satoshi" }}>
                  Other (please specify)
                </Typography>
              </Box>
            }
          />

          {selectedReason === "other" && (
            <TextField
              fullWidth
              placeholder="Enter your reason"
              value={customReason}
              onChange={handleCustomReasonChange}
              sx={{ mt: 2 }}
            />
          )}
        </StyledRadioGroup>
      </StyledDialogContent>

      <DialogActions sx={{ padding: 3, justifyContent: "space-between" }}>
        <StyledButton onClick={handleCloseReportDialog} variant="outlined">
          Cancel
        </StyledButton>

        <StyledButton onClick={reportAnIssue} disabled={isSubmitDisabled}>
          {loading ? <ThreeDot color="#FFFFFF" size="small" /> : "Report"}
        </StyledButton>
      </DialogActions>
      <PopupSuccess
        modelOpen={successOpen}
        onClose={() => setSuccessOpen(false)}
        message={successMessage}
      />
      <ReleaseJobPostModel
        modelOpen={errorOpen}
        onClose={() => setErrorOpen(false)}
        message={errorMessage}
      />
    </StyledDialog>
  );
};

export default ReportIssueDialogBox;
