import baseURL from "./baseUrl";

export const allUsers = async (orgId, token) => {
  try {
    const response = await fetch(
      `${baseURL}/organization/${orgId}/getallrecruiters`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    if (!response.ok) {
      throw new Error("network response was not ok");
    }
   
    return data;
  } catch (error) {
    console.log(error.message);
    throw error;
  }
};

export const addUser = async (orgId, token, formValues) => {
  try {
    const response = await fetch(`${baseURL}/recruiter/${orgId}/addrecruiter`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formValues),
    });
    
    const data = await response.json();
    
    // Handle errors: if response is not OK, throw a detailed error
    if (!response.ok) {
      throw new Error(data?.message || "Network response was not ok");
    }

    // Return the response data from the API
    return data;
  } catch (error) {
    console.error("Error adding user:", error.message);
    throw error; // Re-throw the error so it can be caught by the caller
  }
};

export const verifyRecruiterApi = async (orgId, token, formValues) => {
  try {
    const response = await fetch(`${baseURL}/recruiter/${orgId}/verifyRecruiter`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formValues),
    });
    const data = await response.json();
    if (!response.ok) {
      throw new Error( data.message||"network response was not ok");
    }
    return data;
  } catch (error) {
    console.log(error.message);
    throw error; // Re-throw the error so it can be caught by the caller
  }
};
export const getUsersOfOrg = async (orgId, token) => {
  try {
    const response = await fetch(`${baseURL}/recruiter/admins/${orgId}`, {
      method: 'GET',
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": 'Application/json'
      }
    });
    if (!response.ok) {
      throw new Error("network response was not ok");
    }
    const data = await response.json()
    return data
  } catch (error) {
    console.log(error.message);
  }
};

export const editUser = async (userId, token, formValues) => {
  try {
    const response = await fetch(`${baseURL}/recruiter/${userId}`, {
      method: "PUT",
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": 'Application/json'
      },
      body: JSON.stringify(formValues),
    })
    const data = await response.json()
    if (!response.ok) {
      throw new Error(data.message||"Failed Edit Member");
    }
    
    return data
  }
  catch (error) {
    console.error("Error adding user:", error.message);
    throw error; // Re-throw the error so it can be caught by the caller

  }
}

export const deleteRecruiter = async (userId, token) => {
  try {
    const response = await fetch(`${baseURL}/recruiter/${userId}`, {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": 'Application/json'
      },
    })
    const data = await response.json()
    if (!response.ok) {
      throw new Error( data.message||"network response was not ok");
    }
 
    return data
  }
  catch (error) {
    console.log(error.message)
    throw error
  }
}