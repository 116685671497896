// Format a date string into "Month Day, Year" format (e.g., "January 15, 2023")
export const formatDate = (dateString) => {
    if (!dateString) return ""; // Handle undefined/null inputs gracefully
  
    const date = new Date(dateString);
    if (isNaN(date)) return "Invalid Date"; // Handle invalid dates
  
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };
  
  // Format a date string to return only the year (e.g., "2023")
  export const formatYear = (dateString) => {
    const date = new Date(dateString);
    return isNaN(date) ? "Invalid Date" : date.getFullYear().toString();
  };
  
  // Calculate age based on date of birth (dob)
  export const calculateAge = (dob) => {
    const birthDate = new Date(dob);
    if (isNaN(birthDate)) return null; // Handle invalid dates
  
    const today = new Date();
    const age = today.getFullYear() - birthDate.getFullYear();
    return today <
      new Date(today.getFullYear(), birthDate.getMonth(), birthDate.getDate())
      ? age - 1
      : age;
  };

  export const truncateText = (text, maxChars) => {
    if (!text) return "";
    
    // If text is an array, join it first
    const stringToTruncate = Array.isArray(text) ? text.join(", ") : text;
    
    return stringToTruncate.length > maxChars 
      ? stringToTruncate.substring(0, maxChars) + "..." 
      : stringToTruncate;
  };

