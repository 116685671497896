// import React, { useEffect, useState } from "react";
// import {
//   Card,
//   CardContent,
//   Typography,
//   Button,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   Box,
// } from "@mui/material";

// import NewNavBar from "../../components/JobSeekerComponents/NavBar/NewNavbar";
// import { getWalletApi } from "../../api/jobseeker";
// import JobseekerPaymentModel from "../../components/AdvancedComponents/JobseekerPaymentModel";
// import { FourSquare } from "react-loading-indicators";
// import JobseekerWalletLandingPage from "./JobseekerWalletLandingPage";
// // import { border, borderColor, borderRadius } from "@mui/system";

// const styles = {
//   card: {
//     marginBottom: "20px",
//     border:"1px solid",
//     borderColor:"grey.300",
//     borderRadius:"0.8rem",
//   },
//   walletInfo: {
//     display: "flex",
//     justifyContent: "space-between",
//     alignItems: "center",
//   },
//   amount: {
//     fontSize: "2rem",
//     fontWeight: "bold",
//   },
//   addButton: {
//     marginLeft: "10px",
//   },
//   table: {
//     minWidth: 650,
//   },
//   tableHeader: {
//     backgroundColor: "#f5f5f5",
//   },
// };

// const JobseekerWallet = () => {
//   const [wallet, setWallet] = useState();
//   const userId = localStorage.getItem("userId");
//   const [openPaymentDialog, setPaymentDialog] = useState(false);
//   const [loading, setLoading] = useState(false);
//   useEffect(() => {
//     const fetchUserWallet = async () => {
//       setLoading(true);
//       try {
//         const response = await getWalletApi(userId);
//         setWallet(response.data);
//       } catch (error) {
//         console.log(error.message);
//       } finally {
//         setLoading(false);
//       }
//     };
//     fetchUserWallet();
//   }, [userId]);

//   const formatDate = (dateString) => {
//     const date = new Date(dateString);
//     const day = String(date.getDate()).padStart(2, "0"); // Adds leading zero if day is less than 10
//     const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed, so adding 1
//     const year = date.getFullYear();

//     return `${day}/${month}/${year}`;
//   };

//   const handleOpenPaymentMenu = () => {
//     setPaymentDialog(true);
//   };
//   const handleClosePaymentMenu = () => {
//     setPaymentDialog(false);
//   };

//   if (loading) {
//     return (
//       <Box
//         sx={{
//           position: "fixed",
//           top: 0,
//           left: 0,
//           width: "100%",
//           height: "100%",
//           display: "flex",
//           justifyContent: "center",
//           flexDirection: "column",
//           alignItems: "center",
//           zIndex: 9999, // Ensure the spinner is above other content
//           backgroundColor: "#FFFFFF", // Optional: Add a semi-transparent background
//         }}
//       >
//         <FourSquare color="#423bfa" size="small" text="" textColor="" />
//         <Typography sx={{ fontFamily: "poppins", mt: 1 }}>
//           Fetching Wallet Details...
//         </Typography>
//       </Box>
//     );
//   }

//   return (
//     <>
//       <NewNavBar />
//       <JobseekerWalletLandingPage/>
//       <Box sx={{ maxWidth: 800, margin: "0 auto", padding: "20px" }}>
//         <Card
//           sx={{
//             ...styles.card,
            
//             position: "sticky", // Make the card sticky
//             top: 64, // Stick to the top of the page
//             zIndex: 1, // Ensure it stays above other content
//             backgroundColor: "#fff", // Add background color to ensure it stays visible
//           }}
//           elevation={0}
//         >
//           <CardContent>
//             <Box sx={styles.walletInfo}>
//               <Typography
//                 variant="h5"
//                 component="div"
//                 sx={{ fontFamily: "IBM plex sans" }}
//               >
//                 Wallet Balance
//               </Typography>
//               <Box
//                 sx={{
//                   display: "flex",
//                   alignItems: "center",
//                   justifyContent: "center",
//                   gap: 2,
//                 }}
//               >
//                 <Typography variant="h4" component="span" sx={styles.amount}>
//                   ₹ {wallet?.balance}
//                 </Typography>
//                 <Button
//                   onClick={handleOpenPaymentMenu}
//                   variant="contained"
//                   sx={{
//                     textTransform: "none",
//                     backgroundColor: "#000000",
//                     color: "#ffffff",
//                     ":hover": {
//                       backgroundColor: "#000000",
//                       color: "#ffffff",
//                     },
//                     borderRadius: "0.7rem",
//                   }}
//                 >
//                   Add Money
//                 </Button>
//               </Box>
//             </Box>
//           </CardContent>
//         </Card>

//         <TableContainer
//           component={Paper}
//           sx={{
//             maxHeight: 400,
//             overflowY: "auto",
//             "&::-webkit-scrollbar": {
//               display: "none", // Hide the scrollbar
//             },
//           }}
//         >
//           <Table sx={styles.table} aria-label="transaction table">
//             <TableHead
//               sx={{
//                 position: "sticky",
//                 top: 0,
//                 backgroundColor: "#fff",
//                 zIndex: 1,
//               }}
//             >
//               <TableRow sx={styles.tableHeader}>
//                 <TableCell>Date</TableCell>
//                 <TableCell>Description</TableCell>
//                 <TableCell align="right">Amount</TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {wallet?.transactions?.map((transaction) => (
//                 <TableRow key={transaction.id}>
//                   <TableCell component="th" scope="row">
//                     {formatDate(transaction.date)}
//                   </TableCell>
//                   <TableCell>{transaction.description}</TableCell>
//                   <TableCell
//                     align="right"
//                     sx={{
//                       color: transaction.type === "credit" ? "green" : "red",
//                     }}
//                   >
//                     ₹{Math.abs(transaction.amount).toFixed(2)}
//                   </TableCell>
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </TableContainer>
//       </Box>
//       <JobseekerPaymentModel
//         openPayDialog={openPaymentDialog}
//         handleClosePaymentMenu={handleClosePaymentMenu}
//       />
//     </>
//   );
// };

// export default JobseekerWallet;




import React from 'react';
import JobseekerWalletLandingPage from "./JobseekerWalletLandingPage";

const JobseekerWallet = () => {
  return (
    <div>
        <JobseekerWalletLandingPage/>
      
    </div>
  )
}

export default JobseekerWallet

