import baseURL from "./baseUrl";

export const getChats = async () => {
    const recruiterId = localStorage.getItem("recruiterId");

    try {
        const response = await fetch(`${baseURL}/chat/by/${recruiterId}`, {


            method: "GET",
            headers: {
                "Content-Type": "Application/json",
            },
        });

        if (!response.ok) {
            throw new Error("network response was not ok");
        }
        return response.json();
    } catch (error) {
        console.log(error.message);
    }
};

export const getChatById = async (chatId) => {

    try {
        const response = await fetch(`${baseURL}/chat/${chatId}`, {
            method: "GET",
            headers: {
                "Content-Type": "Application/json",
            },
        });

        if (!response.ok) {
            throw new Error("network response was not ok");
        }
        return response.json();
    } catch (error) {
        console.log(error.message);
    }
};

export const employerJobSeekerChat = async (payload) => {
    const token = localStorage.getItem('token')?.replace(/"/g, '');

    if (!token) {
        throw new Error('No access token found');
    }

    try {
        const response = await fetch(`${baseURL}/chat/sendMessage`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json', // Set header for JSON
            },
            body: JSON.stringify(payload), // Convert payload to JSON string
        });

        // Check if the response status is not OK (e.g., 200–299)
        if (!response.ok) {
            const errorData = await response.json(); // Parse the error response body
            throw new Error(`Failed to send message: ${errorData.message || response.statusText}`);
        }

        const data = await response.json(); // Parse the JSON response

        if (data.chat) {
            return data
                ; // Return relevant data
        } else {
            throw new Error(`Failed to send message: ${data.message || 'Unknown error'}`);
        }
    } catch (error) {
        console.error('Error during sending message:', error);

        // Add response-specific error handling if needed
        throw error; // Propagate error to the calling function
    }
};


export const checkChatIdExistingApi = async(formData)=>{
    try{
      const response = await fetch(`${baseURL}/chat/get/web/chatId`,{
        method: "POST",
      headers: {
    
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
      })
      const data = await response.json();
      if (!response.ok) {
        throw new Error("network response was not ok");
      }
  
      return data;
    }
    catch(error){
        console.log(error.message)
        throw error
    }
}