import React, { useState, useCallback } from "react";
import { Box, Grid, Typography, TextField, Button } from "@mui/material";
import { jobSeekerCreateTicket } from "../../../api/suport";
import PopupSuccess from "../../../utils/popup/PopupSuccess";
import ReleaseJobPostModel from "../../../pages/EmployerPages/SubscriptionStatus/ReleaseJobPostModel";
import { useLocation } from "react-router-dom";

const JobSeekerLetBuildForm = () => {
  const [fullName, setFullName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [errors, setErrors] = useState({
    fullName: "",
    company: "",
    email: "",
    phone: "",
    
  });

  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const location = useLocation();

  const handleSuccess = useCallback((message) => {
    setSuccessMessage(message);
    setSuccessOpen(true);
    setTimeout(() => {
      setSuccessOpen(false);
      setSuccessMessage("");
    }, 2000);
  }, []);

  const handleError = useCallback((message) => {
    setErrorMessage(message);
    setErrorOpen(true);
    setTimeout(() => {
      setErrorOpen(false);
      setErrorMessage("");
    }, 2000);
  
  }, []);
  const ismarketing = location.pathname.includes("marketing");

  const topic = "Request";
  const supportType = ismarketing? "marketing":"general";

  const textFieldStyles = {
    "& .MuiInputBase-root": {
      color: "#000", // Input text color
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#000", // Default underline color
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottomColor: "#000", // Hover underline color
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#423bfa", // Focused underline color
    },
    "& .MuiFormHelperText-root": {
      color: "red", // Error text color
      mt: "4px", // Space below underline
    },
  };

  const handleSave = async (payload) => {
    try {
      await jobSeekerCreateTicket(payload);
      handleSuccess("Support ticket created successfully");
      setFullName("");
      setCompany("");
      setEmail("");
      setPhone("");
      setErrors({ fullName: "", company: "", email: "", phone: "" });
    } catch (error) {
      handleError("Failed to create the support ticket.");
    }
  };



  const validateForm = () => {
    let validationErrors = {};

    if (!fullName.trim()) {
      validationErrors.fullName = "Full name is required";
    }
    if (!company.trim()) {
      validationErrors.company = "Company name is required";
    }
    if (!email.trim()) {
      validationErrors.email = "Email is required";
    } else if (
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)
    ) {
      validationErrors.email = "Please enter a valid email address";
    }
    if (!phone.trim()) {
      validationErrors.phone = "Phone number is required";
    } else if (!/^\d{10}$/.test(phone)) {
      validationErrors.phone = "Please enter a valid 10-digit phone number";
    }

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleSaveClick = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const payload = {
        topic,
        supportType,
        company,
        name: fullName,
        email,
        phone,
      };
      handleSave(payload);
    }
  };

  const handleInputChange = (setter, field) => (e) => {
    setter(e.target.value);
    if (errors[field]) {
      setErrors((prev) => ({ ...prev, [field]: "" }));
    }
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,10}$/.test(value)) {
      setPhone(value);
      if (errors.phone) {
        setErrors((prev) => ({ ...prev, phone: "" }));
      }
    }
  };

  return (
    <Box
      sx={{
        alignItems: "center",
        background: "#f7f7f9",
        borderRadius: "1.5rem",
        padding: "6rem",
        maxWidth: 900,
        // mt: {ismarketing ? 0 :10},
        mb: 10,
        textAlign: "center",
      }}
    >
      {!ismarketing && (
        <Typography
          variant="h3"
          fontWeight={700}
          sx={{ mb: 3, textAlign: "start", maxWidth: 500, color: "#000" }}
        >
          Contact Us
        </Typography>
      )}

      <form onSubmit={handleSaveClick}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Full Name"
              variant="standard"
              value={fullName}
              onChange={handleInputChange(setFullName, "fullName")}
              error={!!errors.fullName}
              helperText={errors.fullName}
              InputLabelProps={{ sx: { color: "#000", fontWeight: "bold" } }}
              sx={textFieldStyles}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Company Name"
              variant="standard"
              value={company}
              onChange={handleInputChange(setCompany, "company")}
              error={!!errors.company}
              helperText={errors.company}
              InputLabelProps={{ sx: { color: "#000", fontWeight: "bold" } }}
              sx={textFieldStyles}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Work Email"
              variant="standard"
              value={email}
              onChange={handleInputChange(setEmail, "email")}
              error={!!errors.email}
              helperText={errors.email}
              InputLabelProps={{ sx: { color: "#000", fontWeight: "bold" } }}
              sx={textFieldStyles}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Phone Number"
              variant="standard"
              value={phone}
              onChange={handlePhoneChange}
              error={!!errors.phone}
              helperText={errors.phone}
              InputLabelProps={{ sx: { color: "#000", fontWeight: "bold" } }}
              sx={textFieldStyles}
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 6,
            gap: 5,
          }}
        >
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              borderRadius: "0.7rem",
              textTransform: "none",
              fontWeight: "bold",
              padding: "12px",
              fontFamily: "IBM Plex Sans",
              backgroundColor: "black",
              color: "white",
              "&:hover": { backgroundColor: "#333" },
            }}
          >
            Send request
          </Button>
          <Typography
            variant="body2"
            sx={{
              mt: 2,
              opacity: 0.8,
              fontSize: "0.8rem",
              textAlign: "start",
              color: "#000",
            }}
          >
            By sending this form, I confirm that I have read and accepted the{" "}
            <Typography
              component="span"
              sx={{
                textDecoration: "underline",
                cursor: "pointer",
                fontSize: "14px",
              }}
            >
              Privacy Policy.
            </Typography>
          </Typography>
        </Box>
      </form>
      <PopupSuccess
        modelOpen={successOpen}
        onClose={() => setSuccessOpen(false)}
        message={successMessage}
      />
      <ReleaseJobPostModel
        modelOpen={errorOpen}
        onClose={() => setErrorOpen(false)}
        message={errorMessage}
      />
    </Box>
  );
};

export default JobSeekerLetBuildForm;
