import React from "react";
import { Grid, Card, CardMedia } from "@mui/material";
import carreerVacanzi from "../../../../assets/images/vacanzi.gif";
import careerhuman1 from "../../../../assets/images/careerhuman1.webp";
import careerhuman2 from "../../../../assets/images/careerhuman2.webp";
import careerhuman3 from "../../../../assets/images/careerhuman3.webp";
import careerhuman4 from "../../../../assets/images/careerhuman4.webp";
import careerhuman5 from "../../../../assets/images/careerhuman5.webp";
import careerhuman6 from "../../../../assets/images/careerhuman6.webp";

const CareerImages = () => {
  const images = [careerhuman1, carreerVacanzi, careerhuman2, careerhuman3, careerhuman4, careerhuman5, careerhuman6];

  return (
    <Grid container spacing={3} sx={{ padding: 3, mb: 10 }}>
      {/* First Row - 3 images, center one (GIF) larger */}
      <Grid item xs={12} sm={3.5}>
        <Card sx={{ borderRadius: 2, boxShadow: 3 }}>
          <CardMedia component="img" image={images[0]} alt="Career 1" sx={{ height: 600 }} />
        </Card>
      </Grid>
      <Grid item xs={12} sm={5}>
        <Card sx={{ borderRadius: 2, boxShadow: 3 }}>
          <CardMedia component="img" image={images[1]} alt="Career 2" sx={{ height: 600 }} />
        </Card>
      </Grid>
      <Grid item xs={12} sm={3.5}>
        <Card sx={{ borderRadius: 2, boxShadow: 3 }}>
          <CardMedia component="img" image={images[2]} alt="Career 3" sx={{ height: 600 }} />
        </Card>
      </Grid>

      {/* Second Row - 4 different images */}
      {[careerhuman3, careerhuman4, careerhuman5, careerhuman6].map((img, index) => (
        <Grid item xs={12} sm={3} key={index}>
          <Card sx={{ borderRadius: 2, boxShadow: 3 }}>
            <CardMedia component="img" image={img} alt={`Career ${index + 4}`} sx={{ height: 450 }} />
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default CareerImages;