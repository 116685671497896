import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Typography, IconButton, Avatar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TextareaAutosize from "@mui/material/TextareaAutosize";

const EmployerJobSeekerChatWindow = ({
  chatData,
  onMessageSent,
  handleAccordionChange,
  profileImage,
  socket,
}) => {
  const [input, setInput] = useState("");
  const [isSocketConnected, setIsSocketConnected] = useState(socket?.connected || false);
  const currentUserId = localStorage.getItem("recruiterId");
  const messagesEndRef = useRef(null);
  const hasSentMessage = useRef(false);

  const sortedChatData = React.useMemo(() => {
    if (!chatData || !Array.isArray(chatData)) return [];
    return [...chatData].sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
  }, [chatData]);
  console.log("chatdata",sortedChatData)

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [sortedChatData]);

  useEffect(() => {
    if (!socket) return;

    setIsSocketConnected(socket.connected);

    const handleConnect = () => {
      console.log("Socket connected in EmployerJobSeekerChatWindow");
      setIsSocketConnected(true);
    };

    const handleDisconnect = () => {
      console.log("Socket disconnected in EmployerJobSeekerChatWindow");
      setIsSocketConnected(false);
    };

    const handleConnectError = (error) => {
      console.error("Socket.IO connection error:", error);
      setIsSocketConnected(false);
    };

    socket.on("connect", handleConnect);
    socket.on("disconnect", handleDisconnect);
    socket.on("connect_error", handleConnectError);

    return () => {
      socket.off("connect", handleConnect);
      socket.off("disconnect", handleDisconnect);
      socket.off("connect_error", handleConnectError);
    };
  }, [socket]);

  useEffect(() => {
    if (!isSocketConnected && socket) {
      const reconnectTimeout = setTimeout(() => {
        console.log("Socket is disconnected, attempting to reconnect...");
        socket.connect();
      }, 1000);
      return () => clearTimeout(reconnectTimeout);
    }
  }, [isSocketConnected, socket]);

  

  const receiver = sortedChatData[0]?.receiver?._id !== currentUserId
    ? sortedChatData[0]?.receiver
    : sortedChatData[0]?.sender;
 
  const handleSendMessage = () => {
    if (!input.trim()) {
      console.warn("Input is empty, message not sent");
      return;
    }

    if (hasSentMessage.current) {
      console.log("Message already sent, preventing duplicate");
      return;
    }
    hasSentMessage.current = true;

    const chatId = sortedChatData[0]?.chatId;

 
    const payload = {
      senderId: currentUserId,
      receivers: [
        {
          userId: receiver?._id,
          userModel: "User",
        },
      ],
      senderModel: "Recruiter",
      message: input,
      timestamp: new Date().toISOString(),
      chatId: chatId,
    };

    console.log("Sending message payload:", payload);

    if (isSocketConnected) {
      socket.emit("sendMessage", payload); // Emit once here
      onMessageSent(payload); // Notify parent without re-emitting
    } else {
      console.warn("Socket is disconnected, message not sent");
    }

    setInput("");
    setTimeout(() => {
      hasSentMessage.current = false;
    }, 100);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: { xl: "67vh", lg: "67vh", md: "67vh", sm: "67vh", xs: "67vh" },
        padding: 1,
        borderRadius: 2,
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        position: "relative",
      }}
    >
      <IconButton
        sx={{
          position: "absolute",
          top: 8,
          right: 8,
          color: "#4caf50",
          "&:hover": { color: "#388e3c" },
        }}
        onClick={handleAccordionChange}
      >
        <CloseIcon />
      </IconButton>

      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <Avatar src={receiver?.profileImage} sx={{ mr: 2 }} />
        <Box>
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              color: "#000",
              fontSize: "16px",
              fontFamily: "poppins",
            }}
          >
            {receiver?.rcName? receiver?.rcName:receiver?.fullName}
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: "#666", fontSize: "12px", fontFamily: "poppins" }}
          >
            Product Marketing Lead at TimesPro
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          padding: 2,
          marginTop: 1,
          borderTop: "0.1px solid #e0e0e0",
        }}
      >
        <Box
          sx={{ minHeight: "100%", display: "flex", flexDirection: "column" }}
        >
          {sortedChatData.length === 0 ? (
            <Typography
              variant="body2"
              sx={{ textAlign: "center", color: "gray", fontFamily: "poppins" }}
            >
              No messages yet. Start the conversation!
            </Typography>
          ) : (
            sortedChatData.map((msg, index) => {
              const previousMessage = sortedChatData[index - 1];
              const isMine = msg.sender
                ? msg?.sender?._id === currentUserId
                : msg.senderId === currentUserId;
              const showDate =
                !previousMessage ||
                new Date(previousMessage.timestamp).toDateString() !==
                  new Date(msg.timestamp).toDateString();

              return (
                <Box
                  key={msg._id || `${msg.timestamp}-${index}`}
                  sx={{ marginBottom: 2, width: "100%" }}
                >
                  {showDate && (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Typography
                        variant="caption"
                        sx={{
                          color: "#333",
                          fontWeight: "bold",
                          padding: "4px 8px",
                          borderRadius: "8px",
                          background: "#f0f0f0",
                        }}
                      >
                        {new Date(msg.timestamp).toDateString()}
                      </Typography>
                    </Box>
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      padding: "8px",
                    }}
                  >
                    <Avatar
                      src={isMine ? profileImage : receiver?.profileImage}
                      sx={{ width: 40, height: 40, marginRight: 2 }}
                    />
                    <Box sx={{ width: "100%" }}>
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            fontWeight: "bold",
                            color: "#000",
                            fontFamily: "poppins",
                          }}
                        >
                          {isMine ? "You" : receiver?.rcName|| receiver.fullName}
                        </Typography>
                        <Typography
                          variant="caption"
                          sx={{ color: "#666", fontFamily: "poppins" }}
                        >
                          {new Date(msg.timestamp).toLocaleTimeString()}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          padding: "1px",
                          borderRadius: "8px",
                          wordBreak: "break-word",
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{ color: "#333", fontFamily: "poppins" }}
                        >
                          {msg.message}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              );
            })
          )}
          <div ref={messagesEndRef} />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "10px 8px",
          backgroundColor: "#f9f8f6",
          boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
          gap: 1,
          width: "100%",
          position: "relative",
          borderTop: "2px solid green",
        }}
      >
        <TextareaAutosize
          minRows={3}
          maxRows={6}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Write a message..."
          style={{
            width: "100%",
            resize: "none",
            padding: "10px",
            fontSize: "16px",
            borderRadius: "8px",
            backgroundColor: "#f5f3ef",
            fontFamily: "poppins",
            border: "none",
            outline: "none",
          }}
        />
        <Button
          variant="contained"
          sx={{
            backgroundColor: input.length >= 2 ? "#000000" : "#000",
            color: "#fff",
            borderRadius: "20px",
            padding: "1px",
            textTransform: "none",
            position: "absolute",
            fontFamily: "poppins",
            bottom: "10px",
            right: "10px",
            "&:hover": {
              backgroundColor: input.length >= 2 ? "#000000" : "#000",
            },
          }}
          onClick={handleSendMessage}
          disabled={input.length < 2 || !isSocketConnected}
        >
          Send
        </Button>
      </Box>
    </Box>
  );
};

export default EmployerJobSeekerChatWindow;