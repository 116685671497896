import React,{ useState,useCallback} from "react";
import {
  Box,
  Button,
  Typography,
  IconButton,
  // Link,
  // Divider,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { MuiOtpInput } from "mui-one-time-password-input";
import { resendOtpApi } from "../../../api/jobseeker";
import PopupSuccess from "../../../utils/popup/PopupSuccess";


const JobSeekerOTPpage = ({
  handleBack,
  phoneNumber,
  handleFieldChange,
  // onResendOtp,
  timer,
  otp,
  errors,
  setTimer,
  handleOtpSubmit,
}) => {

    const [successOpen, setSuccessOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
  

      const handleSuccess = useCallback((message) => {
        setSuccessMessage(message);
        setSuccessOpen(true);
        setTimeout(() => {
          setSuccessOpen(false);
          setSuccessMessage("");
        }, 2000);
      }, []);
    

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleOtpSubmit();
    }
  };

  const handleResendOTP = async () => {
    const formData = {
      phone: phoneNumber,
    };

    try {
      const response = await resendOtpApi(formData);
      handleSuccess(response.message);
      
      setTimer(30);
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <Box
      sx={{
        width: "90%", // Set to 80% for responsiveness
        maxWidth: "800px", // Increase max width for larger screens
        padding: "2rem", // Adequate padding for spacing
        maxHeight: "600px", // Increased minimum height for more internal space
        marginRight:5 ,// Center the Box horizontally
        border: "1px solid #ddd", // Light border for structure
        borderRadius: "16px", // Rounded corners for a modern look
        backgroundColor: "#fff", // White background for clarity
        textAlign: "center", // Center align text
        boxShadow: "-1px 1px 15px 2px rgba(0, 0, 0, 0.1)", // Enhanced shadow for depth
        position: "relative", // For positioning child elements like the back button
      }}
    >
      <IconButton
        onClick={handleBack}
        sx={{
          position: "absolute",
          top: 16,
          left: 16,
        }}
      >
        <ArrowBackIcon />
      </IconButton>
      <Typography
        variant="h5"
        fontWeight="bold"
        gutterBottom
        sx={{ fontFamily: "IBM Plex Sans", mb: 3 }} // Added spacing below the header
      >
        Enter OTP
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mb: 3, // Increased margin below for better spacing
        }}
      >
        <Typography
          textAlign="center"
          fontWeight="bold"
          sx={{
            ml: 1,
            flex: 1,
            color: "black",
            opacity: 0.7,
            display: "flex",
          }}
        >
          Mobile Number
        </Typography>

        <Typography
          textAlign="center"
          fontWeight="bold"
          sx={{
            ml: 1,
            flex: 1,
            color: "black",
            opacity: 0.7,
            display: "flex",
          }}
        >
          +91 {phoneNumber}
        </Typography>
      </Box>

      <MuiOtpInput
        length={4}
        value={otp}
        onChange={(value) => handleFieldChange("otp", value)}
        onKeyDown={handleKeyDown}
        error={!!errors.otp}
        sx={{
          display: "flex",
          justifyContent: "center",
          "& .MuiInputBase-root": {
            backgroundColor: "white",
            border: "1px solid #ddd",
            borderRadius: "8px",
            width: "3.5rem", // Slightly increased width for better readability
            height: "3.5rem", // Increased height for larger input boxes
            margin: "0 0.5rem",
          },
        }}
        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
      />
      {errors.otp && (
        <Typography color="error" sx={{ mt: 2 }}>
          {errors.otp}
        </Typography>
      )}
      <Typography
        sx={{
          display: "block",
          mt: 2,
          textAlign: "center",
          fontSize: "0.9rem",
          fontFamily: "IBM Plex Sans",
        }}
      >
        Didn’t receive an OTP?{" "}
        {timer > 0 ? (
          <span style={{ fontFamily: "IBM Plex Sans", color: "#666" }}>
            Resend OTP in {timer}s
          </span>
        ) : (
          <strong
            style={{
              fontFamily: "IBM Plex Sans",
              color: "#000",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={handleResendOTP}
          >
            Resend OTP
          </strong>
        )}
      </Typography>
      <Typography
        variant="body2"
        color="textSecondary"
        sx={{
          mt: 2, // Increased margin top for spacing
          mb: 3, // Increased margin bottom for spacing
          fontFamily: "IBM Plex Sans",
          textAlign: "left",
        }}
      >
        An OTP has been sent to {phoneNumber}. You may not receive the OTP if
        the email/number is not registered.
      </Typography>
      <Button
        fullWidth
        variant="contained"
        sx={{
          mt: 3, // Increased margin top for better spacing
          backgroundColor: "#423bfa",
          color: "white",
          fontWeight: "bold",
          ":hover":{
            backgroundColor: "#B0F0F0", color: "#000" 
          },
          borderRadius: "10px",
          height: "50px", // Slightly increased button height
        }}
        onClick={handleOtpSubmit}
      >
        Login
      </Button>
      <PopupSuccess
          modelOpen={successOpen}
          onClose={() => setSuccessOpen(false)}
          message={successMessage}
        />
   
      {/* Google Sign-In Button */}
    </Box>
  );
};

export default JobSeekerOTPpage;
