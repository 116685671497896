import {
  Avatar,
  Button,
  Paper,
  Typography,
  Box,
  styled,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import {
  LocationOnOutlined,
  CurrencyRupeeOutlined,
  WorkHistoryOutlined,
} from "@mui/icons-material";
import React, { useEffect, useState, useCallback, useMemo, lazy } from "react";
import { getAppliedUsersApi, shortListApi } from "../../../api/jobPostAPI";
import { ThreeDot } from "react-loading-indicators";
import debounce from "lodash/debounce";
import { rejectCandidateApi } from "../../../api/sendInvite";
import { getJobseekerProfileApi } from "../../../api/jobseeker";
import CandidateChatModal from "../../../components/AdvancedComponents/CandidateSearchLists/CandidateChatModal";
import { formatYear } from "../../../utils/helperFunctions/Format";
import { useNavigate } from "react-router-dom";
import PopupSuccess from "../../../utils/popup/PopupSuccess";
import ReleaseJobPostModel from "../../../pages/EmployerPages/SubscriptionStatus/ReleaseJobPostModel";
import { Suspense } from "react";
import FilterSkeleton from "../../../utils/lazy/FilterSkeleton";
const ApplicantsFilters = lazy(() => import("./ApplicantsFilters"));

// Styled Components
const ProfileContainer = styled(Paper)(({ theme }) => ({
  display: "flex",
  padding: theme.spacing(3),
  marginBottom: "30px",
  flexDirection: "column",
  borderRadius: theme.spacing(1),
  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
  position: "relative",
  gap: theme.spacing(3),
  cursor: "pointer", // Indicate clickable area
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const ProfileSection = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  gap: theme.spacing(3),
  width: "100%",
}));

const ProfileHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
}));

const LabelText = styled(Typography)(({ theme }) => ({
  minWidth: "90px",
  fontWeight: "bold",
  fontSize: "12px",
  fontFamily: "poppins",
  flexShrink: 0,
}));

const DiscriptionText = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  fontFamily: "poppins",
  wordWrap: "break-word",
  overflowWrap: "break-word",
  flex: 1,
  minWidth: 0,
}));

// CandidateContact Component
const CandidateContact = ({ profile }) => {
  const [loading, setLoading] = useState(false);
  const [showContactDetails, setShowContactDetails] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const token = localStorage.getItem("token");

  const handleOpenChat = useCallback((profileId) => {
    setCurrentId(profileId);
    setIsModalOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
    setCurrentId(null);
  }, []);

  const getCandidateProfile = useCallback(
    async (currentId) => {
      setLoading(true);
      try {
        const response = await getJobseekerProfileApi(currentId, token);
        if (response) {
          setShowContactDetails(true);
        }
      } catch (error) {
        console.error(error.message);
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  const RightColumn = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(2),
    minWidth: "180px",
  }));

  const ActionButton = styled(Button)(({ theme }) => ({
    borderRadius: "10px",
    textTransform: "none",
    boxShadow: "none",
    fontFamily: "poppins",
    fontSize: "12px",
    padding: theme.spacing(1, 2),
    width: "100%",
    whiteSpace: "nowrap",
  }));

  const ProfileAvatar = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: theme.spacing(2),
  }));

  const stopPropagation = (e) => {
    e.stopPropagation(); // Prevent click from bubbling up to ProfileContainer
  };

  return (
    <RightColumn onClick={stopPropagation}>
      <ProfileAvatar>
        <Avatar
          src={profile.profileImage || "/placeholder.svg?height=80&width=80"}
          alt={profile.fullName}
          sx={{
            width: 80,
            height: 80,
            border: "2px solid #f0f0f0",
            alignSelf: "center",
          }}
        />
        {!showContactDetails ? (
          <ActionButton
            variant="outlined"
            color="primary"
            onClick={(e) => {
              stopPropagation(e);
              getCandidateProfile(profile._id);
            }}
            disabled={loading}
          >
            {loading ? (
              <ThreeDot color="#423bfa" size="medium" text="" textColor="" />
            ) : (
              "View Contact"
            )}
          </ActionButton>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              width: "100%",
              p: 1,
              bgcolor: "#f5f5f5",
              borderRadius: "8px",
            }}
          >
            <Typography sx={{ fontSize: "12px", fontFamily: "poppins" }}>
              Phone: {profile?.phone || "Not available"}
            </Typography>
            <Typography sx={{ fontSize: "12px", fontFamily: "poppins" }}>
              Email: {profile?.email || "Not available"}
            </Typography>
          </Box>
        )}
        <ActionButton
          variant="contained"
          color="primary"
          onClick={(e) => {
            stopPropagation(e);
            handleOpenChat(profile._id);
          }}
        >
          Chat
        </ActionButton>
      </ProfileAvatar>

      {isModalOpen && currentId && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            right: 0,
            zIndex: 1300,
            height: "100vh",
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          <CandidateChatModal
            name={profile?.fullName || "Unknown"}
            currentId={currentId}
            onClose={handleCloseModal}
          />
        </Box>
      )}
    </RightColumn>
  );
};

function AppliedCandidates({ jobData, setJobData, token, jobId }) {
  const [loadingStates, setLoadingStates] = useState({});
  const [loadingReject, setLoadingReject] = useState({});

  const [applicants, setApplicants] = useState(null);
  const [filterData, setFilterData] = useState({
    totalExpInYearsMax: "",
    totalExpInYearsMin: "",
    experienceType: "",
    skills: [],
    currentLocation: "",
    preferredLocation: [],
    gender: "",
    expectedCtcMin: "",
    expectedCtcMax: "",
  });
  const [appliedFilterData, setAppliedFilterData] = useState(filterData);
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  const handleShortList = useCallback(
    async (userId) => {
      try {
        setLoadingStates((prev) => ({ ...prev, [userId]: true }));
        const formValues = { userIds: [userId] };
        const response = await shortListApi(jobId, token, formValues);

        if (response) {
          const newShortlistEntry = {
            userId: userId,
            shortlistedAt: new Date().toISOString(),
            _id: response._id || `${userId}-${Date.now()}`,
          };

          setJobData((prevJobData) => {
            const existingShortlist = prevJobData.shortlistApplicants || [];
            const isAlreadyShortlisted = existingShortlist.some(
              (applicant) => applicant.userId === userId
            );

            if (isAlreadyShortlisted) {
              return prevJobData;
            }

            return {
              ...prevJobData,
              shortlistApplicants: [...existingShortlist, newShortlistEntry],
            };
          });

          // Trigger success popup
          setSuccessMessage("Candidate Rejected Successfully!");
          setSuccessOpen(true);
          setTimeout(() => {
            setSuccessOpen(false);
            setSuccessMessage("");
          }, 2000); // Auto-close after 2 seconds
        }
      } catch (error) {
        console.log("Error shortlisting applicant:", error.message);
        // Trigger error popup
        setErrorMessage(error.message || "Failed to shortlist candidate");
        setErrorOpen(true);
        setTimeout(() => {
          setErrorOpen(false);
          setErrorMessage("");
        }, 2000); // Auto-close after 2 seconds
      } finally {
        setLoadingStates((prev) => ({ ...prev, [userId]: false }));
      }
    },
    [jobId, token, setJobData]
  );

  const fetchApplicants = useCallback(async () => {
    try {
      const response = await getAppliedUsersApi(
        jobId,
        token,
        appliedFilterData.experienceType,
        appliedFilterData.skills,
        appliedFilterData.totalExpInYearsMin,
        appliedFilterData.totalExpInYearsMax,
        appliedFilterData.currentLocation,
        appliedFilterData.preferredLocation,
        appliedFilterData.gender,
        appliedFilterData.expectedCtcMin || "",
        appliedFilterData.expectedCtcMax || ""
      );
      setApplicants(response);
    } catch (error) {
      console.log(error.message);
    }
  }, [jobId, token, appliedFilterData]);

  const debouncedFetchApplicants = useMemo(
    () => debounce(() => fetchApplicants(true), 300),
    [fetchApplicants]
  );

  useEffect(() => {
    fetchApplicants(false);
  }, [fetchApplicants, jobId, token]);

  const handleApplyFilter = () => {
    setAppliedFilterData(filterData);
    debouncedFetchApplicants();
  };

  useEffect(() => {
    return () => {
      debouncedFetchApplicants.cancel();
    };
  }, [debouncedFetchApplicants]);

  const rejectCandidate = async (candidateId) => {
    setLoadingReject((prev) => ({ ...prev, [candidateId]: true }));
    try {
      await rejectCandidateApi(jobId, token, candidateId);

      setApplicants((prevData) => ({
        ...prevData,
        applications: prevData.applications.map((app) =>
          app.userData._id === candidateId
            ? { ...app, status: "rejected" }
            : app
        ),
      }));
      setSuccessMessage("Candidate shortlisted successfully!");
      setSuccessOpen(true);
      setTimeout(() => {
        setSuccessOpen(false);
        setSuccessMessage("");
      }, 2000);
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoadingReject((prev) => ({ ...prev, [candidateId]: false }));
    }
  };

  console.log("applicants", applicants);
  console.log("jobData", jobData);

  const handleProfileClick = (userId, resume, videoCV, intro) => {
    const profileData = {
      userId,
      resume,
      videoCV,
      intro,
      sourcePage: "appliedCandidates",
    };

    localStorage.setItem("jobSeekerProfile", JSON.stringify(profileData));

    navigate(`/viewJobSeekerProfile/${userId}`);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "start",
          justifyContent: "space-between",
          gap: { xs: 2, sm: 3, md: 4 },
          padding: { xs: 1, sm: 2 },
          width: "100%",
          maxWidth: "100%",
        }}
      >
        {/* Filters */}
        <Box
          sx={{
            display: "flex",
            alignItems: "start",
            justifyContent: "space-between",
            gap: 4,
          }}
        >
          <Suspense fallback={<FilterSkeleton />}>
            <ApplicantsFilters
              filterData={filterData}
              setFilterData={setFilterData}
              handleApplyFilter={handleApplyFilter}
            />
          </Suspense>
        </Box>

        {/* Applicants List */}
        <Box
          sx={{
            width: { xs: "100%", md: "calc(100% - 320px)" },
            display: "grid",
            gridTemplateColumns: {
              xs: "1fr",
              sm: "repeat(auto-fill, minmax(300px, 1fr))",
              md: "repeat(1, 1fr)",
              lg: "repeat(1, 1fr)",
              xl: "repeat(1, 1fr)",
            },
            gap: { xs: 2, sm: 3, md: 4 },
            padding: { xs: 0, sm: 1 },
          }}
        >
          {applicants?.applications?.length > 0 ? (
            applicants?.applications?.map((candidate, index) => {
              const isShortlisted = jobData?.shortlistApplicants?.some(
                (item) => item.userId === candidate?.userData?._id
              );
              const isRejected = candidate?.status === "rejected";
              const isLoading =
                loadingStates[candidate?.userData?._id] || false;
              const isRejecting =
                loadingReject[candidate?.userData?._id] || false;

              return (
                <ProfileContainer
                  key={index}
                  onClick={() =>
                    handleProfileClick(
                      candidate?.userData?._id,
                      candidate?.resume,
                      candidate?.videoCV,
                      candidate?.intro
                    )
                  }
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ flex: 1, minWidth: 0 }}>
                      {/* Profile Header */}
                      <ProfileHeader>
                        <Box sx={{ display: "flex", alignItems: "start" }}>
                          <FormControlLabel
                            control={<Checkbox color="primary" />}
                            sx={{
                              "& .MuiTypography-root": {
                                fontFamily: "Poppins",
                                fontWeight: "bold",
                                fontSize: "16px",
                              },
                            }}
                          />
                          <Box sx={{ flex: 1, mb: 1 }}>
                            <Typography
                              variant="h6"
                              fontWeight="bold"
                              sx={{
                                fontFamily: "poppins",
                                fontSize: "18px",
                                marginTop: "8px",
                              }}
                            >
                              {candidate?.userData?.fullName}
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                alignItems: "center",
                                color: "text.secondary",
                                mt: 1,
                                gap: 2,
                              }}
                            >
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <WorkHistoryOutlined
                                  fontSize="small"
                                  sx={{ mr: 0.5 }}
                                />
                                <Typography
                                  variant="body2"
                                  sx={{
                                    fontFamily: "poppins",
                                    px: 1,
                                    py: 0.5,
                                    borderRadius: 1,
                                  }}
                                >
                                  {candidate?.userData?.totalExpInYears > 0
                                    ? `${candidate?.userData?.totalExpInYears} Years`
                                    : "Fresher"}
                                </Typography>
                              </Box>
                              {candidate?.userData?.currentCtc && (
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <CurrencyRupeeOutlined fontSize="small" />
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      fontFamily: "poppins",
                                      px: 1,
                                      py: 0.5,
                                      borderRadius: 1,
                                    }}
                                  >
                                    {candidate?.userData?.currentCtc} LPA
                                  </Typography>
                                </Box>
                              )}
                              {candidate?.userData?.currentLocation && (
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <LocationOnOutlined
                                    fontSize="small"
                                    sx={{ mr: 0.5 }}
                                  />
                                  <Typography
                                    variant="body2"
                                    sx={{ fontFamily: "poppins" }}
                                  >
                                    {candidate?.userData?.currentLocation}
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      </ProfileHeader>

                      {/* Current/Prior */}
                      {candidate?.userData?.experiences[0] && (
                        <ProfileSection>
                          <LabelText>Current/Prior:</LabelText>
                          <DiscriptionText>
                            {candidate?.userData?.experiences[0]?.designation +
                              " at " +
                              candidate?.userData?.experiences[0]?.company ||
                              "Not Mentioned"}
                          </DiscriptionText>
                        </ProfileSection>
                      )}

                      {/* Education */}
                      {candidate?.userData?.educations[0] && (
                        <ProfileSection>
                          <LabelText>Education:</LabelText>
                          <DiscriptionText>
                            {candidate?.userData?.educations[0]?.degree
                              ? `${
                                  candidate?.userData?.educations[0].degree
                                } at ${
                                  candidate?.userData?.educations[0].institution
                                }${
                                  candidate?.userData?.educations[0]
                                    .currentlyPursuing
                                    ? " (Currently Pursuing)"
                                    : candidate?.userData?.educations[0].endDate
                                    ? ` (${formatYear(
                                        candidate?.userData?.educations[0]
                                          .endDate
                                      )})`
                                    : ""
                                }`
                              : "Not Mentioned"}
                          </DiscriptionText>
                        </ProfileSection>
                      )}

                      {/* Key Skills */}
                      {candidate?.userData?.skills?.length > 0 && (
                        <ProfileSection>
                          <LabelText>Key Skills:</LabelText>
                          <DiscriptionText>
                            {candidate?.userData?.skills?.join(" | ") ??
                              "Not Mentioned"}
                          </DiscriptionText>
                        </ProfileSection>
                      )}

                      {/* Current CTC */}
                      {candidate?.userData?.currentCtc && (
                        <ProfileSection>
                          <LabelText>Current CTC:</LabelText>
                          <DiscriptionText>
                            {candidate?.userData?.currentCtc || "Not Mentioned"}
                          </DiscriptionText>
                        </ProfileSection>
                      )}

                      {/* Notice Period */}
                      {candidate?.userData?.noticePeriod && (
                        <ProfileSection>
                          <LabelText>Notice Period:</LabelText>
                          <DiscriptionText>
                            {candidate?.userData?.noticePeriod ||
                              "Not Mentioned"}
                          </DiscriptionText>
                        </ProfileSection>
                      )}

                      {/* Applied Date */}
                      <ProfileSection>
                        <LabelText>Applied Date:</LabelText>
                        <DiscriptionText>
                          {candidate?.appliedAt
                            ? new Date(
                                candidate?.appliedAt
                              ).toLocaleDateString()
                            : "Not Available"}
                        </DiscriptionText>
                      </ProfileSection>

                      {/* Buttons */}
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 2,
                          mt: 2,
                        }}
                        onClick={(e) => e.stopPropagation()} // Prevent navigation on button clicks
                      >
                        <Button
                          sx={{
                            borderRadius: "0.7rem",
                            color: "white",
                            fontFamily: "poppins",
                            textTransform: "none",
                            fontSize: "12px",
                            padding: "6px 12px",
                            backgroundColor: isRejected
                              ? "#B9B9B9FF"
                              : "#000000",
                            "&:hover": {
                              backgroundColor: isRejected
                                ? "#B9B9B9FF"
                                : "#000000",
                            },
                          }}
                          onClick={() =>
                            rejectCandidate(candidate?.userData?._id)
                          }
                          disabled={isRejected}
                        >
                          {isRejecting ? (
                            <ThreeDot color="white" size="small" />
                          ) : isRejected ? (
                            "Rejected"
                          ) : (
                            "Reject"
                          )}
                        </Button>
                        <Button
                          sx={{
                            borderRadius: "0.7rem",
                            color: "white",
                            fontFamily: "poppins",
                            textTransform: "none",
                            fontSize: "12px",
                            padding: "6px 12px",
                            backgroundColor: isShortlisted
                              ? "#B9B9B9FF"
                              : "#000000",
                            "&:hover": {
                              backgroundColor: isShortlisted
                                ? "#B9B9B9FF"
                                : "#000000",
                            },
                          }}
                          onClick={() =>
                            handleShortList(candidate?.userData?._id)
                          }
                          disabled={isShortlisted}
                        >
                          {isLoading ? (
                            <ThreeDot color="white" size="small" />
                          ) : isShortlisted ? (
                            "Shortlisted"
                          ) : (
                            "Shortlist"
                          )}
                        </Button>
                      </Box>
                    </Box>

                    {/* Right Column with CandidateContact */}
                    <CandidateContact profile={candidate?.userData} />
                  </Box>
                </ProfileContainer>
              );
            })
          ) : (
            <Typography
              variant="h6"
              sx={{
                textAlign: "center",
                fontFamily: "poppins",
                color: "gray",
                fontSize: { xs: "16px", sm: "18px", md: "20px" },
                width: "100%",
                padding: 2,
              }}
            >
              No candidates available.
            </Typography>
          )}
        </Box>
      </Box>

      <PopupSuccess
        modelOpen={successOpen}
        onClose={() => setSuccessOpen(false)}
        message={successMessage}
      />
      <ReleaseJobPostModel
        modelOpen={errorOpen}
        onClose={() => setErrorOpen(false)}
        message={errorMessage}
      />
    </>
  );
}

export default AppliedCandidates;
