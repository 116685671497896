import React, { useState } from "react";
import { Card, Typography, Avatar, Button, Paper, Box} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import NetworkListModal from "./NetworkListModal";

const JobSeekerConnect = ({ users }) => {
  const [open, setOpen] = useState(false);

  return (
    <Paper
      elevation={0}
      sx={{
        width: "100%",
        p: 3,
        borderRadius: 3,
        border: "1px solid #E0E0E0",
        backgroundColor: "#fff",
        boxShadow: "0px 2px 10px rgba(0,0,0,0.05)",
      }}
    >
      <Typography variant="h6" sx={{ fontWeight: "bold", mb: 3, color: "#333" }}>
        People you may know from Selfeey Infotech
      </Typography>

      {/* Grid Layout */}
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
          gap: 2,
          justifyContent: "center",
        }}
      >
        {users.slice(0, 6).map((user, index) => (
          <Card
            key={index}
            elevation={0}
            sx={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              p: 2,
              borderRadius: 5,
              border: "1px solid #E0E0E0",
              backgroundColor: "#F9F9F9",
              boxShadow: "0px 2px 8px rgba(0,0,0,0.05)",
              transition: "transform 0.2s ease, box-shadow 0.2s ease",
              "&:hover": {
                transform: "scale(1.05)",
                boxShadow: "0px 4px 12px rgba(0,0,0,0.1)",
              },
            }}
          >
           
            <Avatar src={user.avatar} sx={{ width: 64, height: 64, mb: 1 }} />
            <Typography sx={{ fontWeight: "bold", color: "#333" }}>{user.name}</Typography>
            <Typography color="textSecondary" sx={{ textAlign: "center" }}>{user.status}</Typography>
            <Button
              variant="outlined"
              sx={{
                mt: 1,
                color: "#000",
                textTransform: "none",
                fontSize: "14px",
                fontWeight: "bold",
                borderColor: "#000",
                "&:hover": { backgroundColor: "#000", color: "#fff" },
              }}
              fullWidth
            >
              + Connect
            </Button>
          </Card>
        ))}
      </Box>

      {/* Show All Button */}
      <Button
        variant="text"
        onClick={() => setOpen(true)}
        sx={{
          mt: 2,
          display: "flex",
          alignItems: "center",
          textTransform: "none",
          color: "#008080",
          fontWeight: "bold",
          "&:hover": { textDecoration: "underline" },
        }}
      >
        Show all <ArrowForwardIosIcon sx={{ fontSize: 14, ml: 0.5 }} />
      </Button>

      {/* Modal */}
      <NetworkListModal open={open} handleClose={() => setOpen(false)} users={users} />
    </Paper>
  );
};

export default JobSeekerConnect;
