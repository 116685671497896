import React from 'react';
import { Box, Button, Typography, Modal, TextField } from '@mui/material';
import { ThreeDot } from 'react-loading-indicators';

const VerifyOtpModal = ({
  open,
  handleClose,
  handleFieldChange,
  otp,
  errors,
  handleOtpSubmit,
  email,
  loading
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="otp-modal-title"
      closeAfterTransition
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 420,
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
          p: 3,
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '16px' }}>
          Enter One Time Password to proceed
        </Typography>

        <Typography variant="body2" sx={{ color: '#535252', marginBottom: 2 }}>
          For enhanced security of your account, we have sent a One-Time Password (OTP) to your
          <b> email {email}</b>.
        </Typography>

        <TextField
          value={otp}
          size="small"
          fullWidth
          placeholder="Enter OTP received"
          onChange={(event) => handleFieldChange('otp', event.target.value)}
          error={!!errors.otp}
          helperText={errors.otp}
          inputProps={{
            inputMode: 'numeric',
            pattern: '[0-9]*',
            style: { fontSize: '16px' },
          }}
          sx={{
            '& input::placeholder': { fontSize: '13px', color: '#535252' },
          }}
        />

        <Button
          fullWidth
          variant="contained"
          sx={{
            mt: 2,
            backgroundColor: '#423bfa',
            color: 'white',
            fontWeight: 'bold',
            textTransform: 'none',
          }}
          onClick={handleOtpSubmit}
        >
             {loading ? (
              <ThreeDot
               
                color="#00C711FF"
                size="small"
                text=""
                textColor=""
              />
            ) : (
              "Verify"
            )}
        </Button>
      </Box>
    </Modal>
  );
};

export default VerifyOtpModal;
