import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Avatar,
  Box,
  Button,
  IconButton,
  Rating,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import styled from "@emotion/styled";
import baseURL from "../../../api/baseUrl";

const SuccessMessage = styled(Box)`
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1300;
  animation: slideUp 0.5s ease-in-out forwards;

  @keyframes slideUp {
    0% { bottom: -100px; opacity: 0; }
    100% { bottom: 50%; transform: translate(-50%, 50%); opacity: 1; }
  }
`;

const CompanyRatingModal = ({ open, onClose, companyName, orgId, averageRating, onRatingSubmitted }) => {
  const [rating, setRating] = useState(5); // Default to 5 stars
  const [showSuccess, setShowSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const username = localStorage.getItem("userName") || "Vacanzi dev";
  const profileImage = localStorage.getItem("profileImage") || "https://via.placeholder.com/40";
  const authToken = localStorage.getItem("authToken");

  const handleRatingChange = (event, newValue) => {
    setRating(newValue);
  };

  const handleSubmitRating = async () => {
    if (!orgId || isSubmitting) return;
    setIsSubmitting(true);

    try {
      const apiUrl = `${baseURL}/org/review/${orgId}`;
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({ rating }),
      });

      if (!response.ok) {
        throw new Error("Failed to submit rating");
      }

      setShowSuccess(true);
      setTimeout(async () => {
        setShowSuccess(false);
        setIsSubmitting(false);
        if (onRatingSubmitted) {
          await onRatingSubmitted(); // Fetch updated companies
        }
        onClose(); // Close the modal after success message
      }, 1000);
    } catch (error) {
      console.error("Error submitting rating:", error);
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (open) {
      setShowSuccess(false);
      // Set rating to averageRating only if it’s a valid number > 0, otherwise default to 5
      const parsedRating = averageRating ? parseFloat(averageRating) : 0;
      setRating(parsedRating > 0 ? parsedRating : 5);
    }
  }, [open, averageRating]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDialog-paper": { maxWidth: "500px", width: "100%", m: 2, backgroundColor: "white" },
        "& .MuiBackdrop-root": { backgroundColor: "rgba(0, 0, 0, 0.1)" },
      }}
    >
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="h6">{companyName}</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <Avatar src={profileImage} alt={username} sx={{ width: 40, height: 40, mr: 2 }} />
          <Box>
            <Typography variant="body1" fontWeight="bold">
              {username}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Posting publicly across Vacanzi
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 2, gap: 1 }}>
          <Rating
            value={rating}
            onChange={handleRatingChange}
            size="large"
            precision={0.1}
            sx={{ color: "#423bfa", "& .MuiRating-iconEmpty": { color: "#423bfa", opacity: 0.3 } }}
          />
          <Typography variant="body1" fontWeight="bold">
            {rating > 0 ? rating.toFixed(1) : "0.0"}
          </Typography>
        </Box>
        <Button
          variant="contained"
          fullWidth
          onClick={handleSubmitRating}
          disabled={isSubmitting} // Only disable when submitting, not when rating is 0
          sx={{
            textTransform: "none",
            backgroundColor: isSubmitting ? "#9a9a9a" : "#423bfa",
            "&:hover": { backgroundColor: isSubmitting ? "#9a9a9a" : "#3a32d1" },
          }}
        >
          {isSubmitting ? "Submitting..." : "Submit Rating"}
        </Button>
      </DialogContent>
      {showSuccess && (
        <SuccessMessage>
          <CheckCircleOutline sx={{ fontSize: 40, color: "darkgreen", mb: 1 }} />
          <Typography variant="h6" fontWeight="bold" textAlign="center">
            Thank you!
          </Typography>
          <Typography variant="body1" textAlign="center">
            Rating submitted successfully
          </Typography>
        </SuccessMessage>
      )}
    </Dialog>
  );
};

export default CompanyRatingModal;