import React, { memo } from 'react';
import {
  Accordion, AccordionSummary, AccordionDetails, Typography, Grid
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomAutocomplete from '../../CustomAutoComplete';

const ageOptions = ['18', '20', '25', '30', '35', '40', '45', '50', '55', '60'];

const CandidateAge = memo(({ handleAgeChange, formData }) => {
  return (
    <Accordion
    defaultExpanded
    sx={{
      boxShadow: "none",
      backgroundColor: "transparent",
      "&:before": {
        display: "none",
      },
    }}
  >
    <AccordionSummary
      expandIcon={<ExpandMoreIcon sx={{ color: "#666", opacity: 0.7 }} />}
      sx={{
        padding: "8px 0",
        minHeight: "40px",
        "& .MuiAccordionSummary-content": {
          margin: "0",
        },
      }}
    >
      <Typography
        sx={{
          fontSize: "16px",
          fontWeight: "bold",
          fontFamily: "IBM Plex Sans, sans-serif",
          color: "#333",
        }}
      >
        Age
      </Typography>
    </AccordionSummary>
    <AccordionDetails
      sx={{ padding: "0 0 16px 0", backgroundColor: "transparent" }}
    >
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <CustomAutocomplete
            placeholder="Min age"
            options={ageOptions}
            value={formData.minAge}
            onChange={(newValue) =>
              handleAgeChange({ target: { name: "minAge", value: newValue } })
            }
          />
        </Grid>
        <Grid item xs={6}>
          <CustomAutocomplete
            placeholder="Max age"
            options={ageOptions}
            value={formData.maxAge}
            onChange={(newValue) =>
              handleAgeChange({ target: { name: "maxAge", value: newValue } })
            }
          />
        </Grid>
      </Grid>
    </AccordionDetails>
  </Accordion>
  );
});

export default CandidateAge;
