import React, { createContext, useState, useContext } from "react";

// Create the context
const OpenStateContext = createContext();

// Create a custom hook to use the OpenStateContext
export const useOpenState = () => {
  return useContext(OpenStateContext);
};

// Create the Provider component
export const OpenStateProvider = ({ children }) => {
  const [open, setOpen] = useState(true);

  return (
    <OpenStateContext.Provider value={{ open, setOpen }}>
      {children}
    </OpenStateContext.Provider>
  );
};
