import React from "react";
import {  Skeleton, Paper, } from "@mui/material";

const PaperSkeleton = () => {
  return (
    <Paper
      sx={{
        width: "100%",
        height: "100vh", // Full height of the viewport
        padding: 3, // Padding around the content
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      {/* Title Skeleton */}
      <Skeleton
        variant="text"
        sx={{
          width: "50%", // You can adjust the width as needed
          height: 40,
          marginBottom: 2,
        }}
      />

      {/* First Field */}
      <Skeleton
        variant="text"
        sx={{
          width: "100%", // Full width for text fields
          height: 30,
          marginBottom: 2,
        }}
      />

      {/* Second Field */}
      <Skeleton
        variant="text"
        sx={{
          width: "100%",
          height: 30,
          marginBottom: 2,
        }}
      />

      {/* Third Field (you can add more fields as needed) */}
      <Skeleton
        variant="text"
        sx={{
          width: "80%", // Adjust width for a more realistic form appearance
          height: 30,
          marginBottom: 2,
        }}
      />

      {/* A long text or paragraph field */}
      <Skeleton
        variant="text"
        sx={{
          width: "100%",
          height: 60, // Taller height for paragraph-like field
          marginBottom: 2,
        }}
      />

      {/* A section for some form inputs (like buttons or checkboxes) */}
      <Skeleton
        variant="rectangular"
        sx={{
          width: "100%",
          height: 50, // Rectangular form input skeleton
          marginBottom: 2,
        }}
      />

      {/* Footer or other content */}
      <Skeleton
        variant="text"
        sx={{
          width: "30%",
          height: 20,
          marginTop: "auto", // Push the footer to the bottom
        }}
      />
    </Paper>
  );
};

export default PaperSkeleton;
