import { Box, Typography, Card, Container, CssBaseline } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import clientImage from "../../../assets/images/clients.png";
import { useNavigate } from "react-router-dom";

const Clients = () => {
  const navigate = useNavigate();
  return (
    <>
      <CssBaseline />
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "80vh",
          display: "flex",
          borderRadius: "12px",
          alignItems: "center",
          justifyContent: "center",
          backgroundImage: `url(${clientImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: "12px",
            zIndex: 1,
          }}
        />

        <Container
          maxWidth={false}
          sx={{
            zIndex: 2,
            padding: "20px 32px",
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              color: "#fff",
              marginBottom: "32px",
              fontSize: "1.125rem",
              fontFamily: '"Roobert", "Arial", sans-serif',
              top: 0,
              left: "32px",
            }}
          ></Typography>

          <Box sx={{ textAlign: "center" }}>
            <Box
              sx={{
                display: "flex",
                flexWrap: "nowrap",
                justifyContent: "space-between", // Ensures all cards are in a single line
                alignItems: "space-between",
                gap: "16px",
                position: "absolute",
                bottom: "-100px", // Moves the cards to the bottom
                left: "50%",
                transform: "translateX(-50%)", // Centers them horizontally
              }}
            >
              {[
                {
                  title: "Showcase Yourself",
                },
                {
                  title: "Connect Instantly",
                },
                {
                  title: "Get Hired Faster",
                },
              ].map((card, index) => (
                <Box
                  key={index}
                  onClick={() => navigate("/signinsignup")}
                  sx={{
                    flex: "1 1 auto",
                    minWidth: "350px",
                  }}
                >
                  <Card
                    sx={{
                      backgroundColor: "#000",
                      padding: "14px",
                      height: "100%",
                      borderRadius: "12px",
                      cursor: "pointer",
                      transition: "all 0.3s ease",
                      "&:hover": {
                        backgroundColor: "#000",
                        "& .card-text": {
                          color: "#fff",
                        },
                        "& .arrow-icon": {
                          color: "#fff",
                        },
                      },
                    }}
                  >
                    <Box>
                    <Typography
                      className="card-text"
                      sx={{
                        color: "#fff",
                        fontWeight: 600,
                        marginBottom: "24px",
                        whiteSpace: "pre-line",
                        fontFamily: '"Roobert", "Arial", sans-serif',
                        transition: "color 0.3s ease",
                        fontSize: "30px",
                      }}
                    >
                      {card.title}
                      
                    </Typography>
                    <ArrowForwardIcon
                        className="arrow-icon"
                        sx={{
                          color: "#fff",
                          ml:3,
                          transition: "color 0.3s ease",
                        }}
                      />
                    </Box>
                  </Card>
                </Box>
              ))}
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Clients;
