import React from "react";
import { Box, Button } from "@mui/material";
import JobSeekerChatList from "./JobSeekerChatList";
import JobSeekerChatDetails from "./JobSeekerChatDetails";
import { Container } from "@mui/system";

const JobSeekerChat = ({
  users,
  selectedChat,
  handleChatClick,
  chatMessages,
  handleSend,
  handleInputChange,
  messageData,
  supportUser,
  handleStatusChange,
  statusMessage,
  handleModalOpen,
}) => {
  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          margin: 2,
          mt: 10,
          position: "relative", // Allows absolute positioning inside this container
        }}
      >
        {/* Create Ticket Button */}
        <Box
          sx={{
            display:'none',
            position: "absolute",
            top: -50,
            right: 0,
          }}
        >
          <Button
            variant="contained"
            sx={{ backgroundColor: "#423bfa" }}
            onClick={handleModalOpen}
          >
            Create Ticket
          </Button>
        </Box>

        {/* Main Content */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: 2,
            height: "80vh",
          }}
        >
          {/* Chat List Column */}
          <Box sx={{ flex: 8 }}>
            <JobSeekerChatList users={users} onChatClick={handleChatClick} />
          </Box>

          {/* Chat Details Column */}
          <Box sx={{ flex: 20 }}>
            {selectedChat ? (
              <JobSeekerChatDetails
                chatMessages={chatMessages}
                supportUser={supportUser}
                handleInputChange={handleInputChange}
                handleSend={handleSend}
                messageData={messageData}
                handleStatusChange={handleStatusChange}
                statusMessage={statusMessage}
              />
            ) : (
              <div>Select a chat to view messages</div>
            )}
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default JobSeekerChat;
