import React, { useEffect, useMemo, useState } from "react";
import { Grid, Container } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Topcompanies from "../../../components/TopCompaniesDetails/Topcompanies";
import CompanyCards from "../../../components/TopCompaniesDetails/CompanyCards";
import CompanySidebar from "../../../components/TopCompaniesDetails/CompanySidebar"; // Fixed typo in import
import Footer from "../../../components/JobSeekerComponents/Footer/Footer";
import { getAllOrganizationsApi, getTopIndustryMasters } from "../../../api/common";
import baseURL from "../../../api/baseUrl";
import NewNavBar from "../../../components/JobSeekerComponents/NavBar/NewNavbar";

const TopHiringCompanies = () => {
  const [companies, setCompanies] = useState([]);
  const [companiesDetails, setCompanyDetails] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const [companyTypeFilter, setCompanyTypeFilter] = useState("");
  const [locationFilter, setLocationFilter] = useState("");

  const query = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const defaultIndustry = query.get("industry");

  useEffect(() => {
    if (defaultIndustry) {
      setSelectedIndustry(defaultIndustry);
    }
  }, [defaultIndustry]);

  // Fetch top industry masters
  useEffect(() => {
    const fetchTopIndustryMasters = async () => {
      try {
        const response = await getTopIndustryMasters();
        if (response) {
          const allCategories = response.reduce((acc, resItem) => {
            resItem.items.forEach((item) => {
              if (item.name) {
                acc.push({ name: item.name, count: item.organizationCount || 0 });
              }
            });
            return acc;
          }, []);
          setCompanyDetails(allCategories);
        }
      } catch (error) {
        console.error("Error fetching top industry masters:", error.message);
      }
    };
    fetchTopIndustryMasters();
  }, []);

  // Fetch companies based on filters or all organizations
  const fetchCompanies = async () => {
    try {
      if (!selectedIndustry && !companyTypeFilter && !locationFilter) {
        // If no filters or industry, fetch all organizations
        const response = await getAllOrganizationsApi();
        setCompanies(response?.data || []);
      } else if (selectedIndustry) {
        // Fetch companies by industry and filters
        const queryParams = new URLSearchParams({
          orgType: companyTypeFilter || "",
          city: locationFilter || "",
          industry: selectedIndustry,
        });
        const response = await fetch(
          `${baseURL}/organization/organizations-by-industry?${queryParams}`,
          {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          }
        );
        if (!response.ok) throw new Error("Network response was not ok");
        const data = await response.json();
        setCompanies(data?.data || []);
      } else {
        setCompanies([]); // Clear companies if no industry but filters exist
      }
    } catch (error) {
      console.error("Error fetching companies:", error.message);
      setCompanies([]);
    }
  };

  useEffect(() => {
    fetchCompanies();
  }, [companyTypeFilter, locationFilter, selectedIndustry]);


  const handleLocationFilter = (value) => {
    setLocationFilter(value);
  };

  const goToCompanyPage = (companyId) => {
    navigate(`/organizationOverview?companyId=${companyId}`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleNavbarIndustrySelect = (industry) => {
    setSelectedIndustry(industry);
  };

  return (
    <>
      <NewNavBar onIndustrySelect={handleNavbarIndustrySelect} />
      <Container maxWidth="xl" sx={{ mb: 10, mt: 2 }}>
        <Grid container spacing={4} sx={{ background: "#f7f7f9", padding: 2 }}>
          <Grid item xs={12}>
            <Topcompanies
              companiesDetails={companiesDetails}
              selectedIndustry={selectedIndustry}
              onIndustrySelect={setSelectedIndustry}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CompanySidebar
              handleLocationFilter={handleLocationFilter}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <CompanyCards
              companies={companies}
              goToCompanyPage={goToCompanyPage}
              fetchCompanies={fetchCompanies} // Pass fetch function for consistency
            />
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
};

export default TopHiringCompanies;