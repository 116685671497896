  import React, { useEffect, useState, lazy, Suspense, useCallback } from "react";
  import { Box, Typography } from "@mui/material";
  // import ResponsiveAppBar from "../../../components/JobSeekerComponents/NavBar/NavBar";
  // import CompanyTitleCard from "../../../components/JobSeekerComponents/JobPostOverView/CompanyTitleCard";
  // import CompanyVideoCard from "../../../components/JobSeekerComponents/JobPostOverView/CompanyVideoCard";
  import CompanyVideoCarousel from "../../../components/JobSeekerComponents/JobPostOverView/CompanyVideoCarousel";
  // import CompanyDescription from "../../../components/JobSeekerComponents/JobPostOverView/CompanyDescription";
  import { getUserIdFromToken } from "../../../utils/userAuth";
  // import { toast } from "react-toastify"; // Import toast from react-toastify
  import "react-toastify/dist/ReactToastify.css"; // Import toast styles
  import {
    applyJobApi,
    getJobsByOrgApi,
    getSimilarJobsApi,
  } from "../../../api/jobseeker";
  import TitleCardSkeleton from "../../../utils/lazy/TitleCardSkeleton";
  import VideoCardSkeleton from "../../../utils/lazy/VideoCardSkeleton";
  import DescriptionBox from "../../../utils/lazy/DescriptionBox";
  import NewNavBar from "../../../components/JobSeekerComponents/NavBar/NewNavbar";
  // import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
  import SimilarJobs from "../../../components/JobSeekerComponents/JobPostOverView/SimilarJobs";
  import { useJobseeker } from "../../../context/UserContext";
  // import { CheckCircleOutline } from "@mui/icons-material";
  import PopupSuccess from "../../../utils/popup/PopupSuccess";
  import ReleaseJobPostModel from "../../EmployerPages/SubscriptionStatus/ReleaseJobPostModel";
  import { truncateText } from "../../../utils/helperFunctions/Format";

  const CompanyTitleCard = lazy(() =>
    import(
      "../../../components/JobSeekerComponents/JobPostOverView/CompanyTitleCard"
    )
  );
  const CompanyVideoCard = lazy(() =>
    import(
      "../../../components/JobSeekerComponents/JobPostOverView/CompanyVideoCard"
    )
  );
  const CompanyDescription = lazy(() =>
    import(
      "../../../components/JobSeekerComponents/JobPostOverView/CompanyDescription"
    )
  );

  const CompanyOverView = ({ job, setJob, }) => {
    const [userId, setUserId] = useState(null);
    const [isApplied, setIsApplied] = useState(false);
    const [orgJobs, setOrgJobs] = useState();
    const [selectedJob, setSelectedJob] = useState();
    const [selectedVideoCv, setSelectedVideoCv] = useState(null);
    const [selectedResume, setSelectedResume] = useState(null);
    const [similarJobs, setSimilarJobs] = useState();
    const { userData } = useJobseeker();
    const [intro, setIntro] = useState("");
    const [successOpen, setSuccessOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorOpen, setErrorOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const token = localStorage.getItem("authToken");

   

    const handleSuccess = useCallback((message) => {
      setSuccessMessage(message);
      setSuccessOpen(true);
      setTimeout(() => {
        setSuccessOpen(false);
        setSuccessMessage("");
      }, 2000);
    }, []);

    const handleError = useCallback((message) => {
      setErrorMessage(message);
      setErrorOpen(true);
      setTimeout(() => {
        setErrorOpen(false);
        setErrorMessage("");
      }, 2000);
    }, []);

    useEffect(() => {
      window.scroll(0, 0);
    }, []);
    useEffect(() => {
      const getSimilarJob = async () => {
        try {
          const response = await getSimilarJobsApi(job._id, token);
          setSimilarJobs(response?.data);
        } catch (error) {
          console.log(error.message);
        }
      };
      getSimilarJob();
    }, [job._id, token]);

    const fetchUserId = () => {
      const storedUserId = getUserIdFromToken(); // Adjust this to how you fetch the userId
      setUserId(storedUserId);
    };

    useEffect(() => {
      const fetchJobsByOrganization = async () => {
        const orgId = job?.organizationId?._id; // Extract orgId from job

        if (!orgId) {
          return; // Early return if orgId is missing
        }

        try {
          const response = await getJobsByOrgApi(orgId); // Pass orgId to the API function
          setOrgJobs(response);
        } catch (error) {
          console.error("Failed to fetch jobs:", error.message);
        }
      };

      fetchJobsByOrganization();
    }, [job]);

    useEffect(() => {
      fetchUserId();

      if (job?.appliedCandidates && userId) {
        const appliedCandidates = job.appliedCandidates; // Array of applied candidate IDs
        const hasApplied = appliedCandidates.includes(userId); // Check if userId exists in the array
        setIsApplied(hasApplied); // Update the state to reflect if the user has applied
      }
    }, [userId, job]);

    if (!job) return <Typography variant="h6">Job not found</Typography>;

    // Function to handle video selection from the carousel
    const handleVideoSelect = (video) => {
      // Find the corresponding job from orgJobs
      setSelectedJob(orgJobs?.find((job) => job._id === video.id));
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    };

    const handleApplyJob = async (selectedVideoCv, selectedResume) => {
      try {
        const token = localStorage.getItem("authToken"); // Changed from "authToken" to match earlier usage
        const applicationData = {
          jobId: job._id,
          expectedCtc: userData.expectedCtc,
          videoCV: selectedVideoCv,
          resume: selectedResume,
          intro: intro,
        };
        console.log("applicationdata", applicationData);
        const response = await applyJobApi(token, applicationData);
        setJob(response?.application?.jobId);
        handleSuccess(response.message);
      } catch (error) {
        console.error("Error applying for the job:", error);
        handleError(error.message);
        return { success: false, error: error.message }; // Return failure to child
      }
    };
    console.log("orgjobs", orgJobs);
    return (
      <>
        <NewNavBar />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 3,
            background: "linear-gradient(135deg, #F8F9FA, #CED4DA)",
            fontFamily: "IBM plex sans",
            py: 10,
          }}
        >
          {/* Left Side: Title, Video, Video Carousel */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start", // Align items to the top
                justifyContent: {
                  xl: "space-between",
                  lg: "space-between",
                  md: "center",
                },
                px:{
                  xl:10,
                  md:10,
                  lg:10,
                },
                gap: 3,
              }}
            >
              {/* Video Card */}
              <Box>
                <Suspense fallback={<VideoCardSkeleton />}>
                  <CompanyVideoCard
                  isApplied={isApplied}
                    video={selectedJob ? selectedJob : job}
                    job={job}
                    setJob={setJob}
                    handleApplyJob={handleApplyJob}
                    selectedVideoCv={selectedVideoCv}
                    setSelectedVideoCv={setSelectedVideoCv}
                    selectedResume={selectedResume}
                    handleSuccess={handleSuccess}
                    handleError={handleError}
                    setSelectedResume={setSelectedResume}
                    setIntro={setIntro}
                  />
                </Suspense>
              </Box>

              {/* Description */}
              <Box>
                <Suspense fallback={<TitleCardSkeleton />}>
                  <CompanyTitleCard job={job} />
                </Suspense>

                <Suspense fallback={<DescriptionBox />}>
                  <CompanyDescription
                    job={selectedJob ? selectedJob : job}
                    isApplied={isApplied}
                    orgJobs={orgJobs}
                    selectedVideoCv={selectedVideoCv}
                    setSelectedVideoCv={setSelectedVideoCv}
                    selectedResume={selectedResume}
                    setSelectedResume={setSelectedResume}
                    handleApplyJob={handleApplyJob}
                    setIntro={setIntro}
                  />
                </Suspense>
              </Box>
              <SimilarJobs similarJobs={similarJobs} />
            </Box>

            {/* Video Carousel */}
            <CompanyVideoCarousel
              videos={orgJobs?.map((job) => ({
                id: job?._id,
                jobVideoUrl: job?.jobVideoUrl,
                jobTitle: job?.jobTitle,
                logo: job?.forConsultantCompany
                  ? job?.consultantCompanyLogo?.url
                    ? job?.consultantCompanyLogo?.url
                    : job?.organizationId?.companyLogo?.url
                  : job?.organizationId?.companyLogo?.url,
                companyName: job?.forConsultantCompany
                  ? job?.consultantOrgName
                  : job?.organizationId?.organizationName,
                location: truncateText(
                  Array.isArray(job?.jobLocations) && job?.jobLocations.length > 0
                    ? `${job.jobLocations[0].area}, ${job.jobLocations[0].location}, `
                    : "Location Not Available",
                  50
                ),
                ctc:job?.ctcMin + " -" + job?.ctcMin
              }))}
              onVideoSelect={handleVideoSelect}
              job={job}
              orgJobs={orgJobs}
            />
          </Box>
          <PopupSuccess
            modelOpen={successOpen}
            onClose={() => setSuccessOpen(false)}
            message={successMessage}
          />
          <ReleaseJobPostModel
            modelOpen={errorOpen}
            onClose={() => setErrorOpen(false)}
            message={errorMessage}
          />

        </Box>
      </>
    );
  };

  export default CompanyOverView;
