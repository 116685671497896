import { Box } from "@mui/system";
import React, { useCallback, useEffect, useState } from "react";
import deleteAccount from "../../../styles/deleteAccount";
import { 
  Button, 
  TextField, 
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions 
} from "@mui/material";
import { getOrgDetails } from "../../../api/companyProfile";
import { deleteOrganization } from "../../../api/deleteOrgAccount";
import PopupSuccess from "../../../utils/popup/PopupSuccess";
import ReleaseJobPostModel from "../SubscriptionStatus/ReleaseJobPostModel";
import { ThreeDot } from "react-loading-indicators";

const DeleteAccount = () => {
  const [orgData, setOrgData] = useState();
  const [reason, setReason] = useState("");
  const [reasonError, setReasonError] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleReasonChange = (e) => {
    setReason(e.target.value);
    if (e.target.value.trim() && reasonError) {
      setReasonError("");
    }
  };

  const validateForm = () => {
    if (!reason.trim()) {
      setReasonError("Reason is required");
      return false;
    }
    return true;
  };

  const handleOpenDialog = () => {
    if (validateForm()) {
      setOpenDialog(true);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const orgId = localStorage.getItem("organizationId");
  const token = localStorage.getItem("token");  

  useEffect(() => {
    const fetchOrgDetails = async () => {
      try {
        if (orgId) {
          const response = await getOrgDetails(orgId, token);
          setOrgData(response);
        }
      } catch (error) {
        console.log(error.message);
    }
    };
    fetchOrgDetails();
  }, [orgId, token]);

  const deleteOrgAccount = async () => {
    try {
      setLoading(true);
      if (orgId && reason.trim()) {
        await deleteOrganization(orgId, token, { reason });
        handleSuccess(
          "Delete Request has been received. Our team will get back to you within 6 hours"
        );
        handleCloseDialog();
        setReason("");
        setReasonError("");
      }
    } catch (error) {
      console.log(error.message);
      handleError(
        error.message || "Something went wrong, please try again later"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSuccess = useCallback((message) => {
    setSuccessMessage(message);
    setSuccessOpen(true);
    setTimeout(() => {
      setSuccessOpen(false);
      setSuccessMessage("");
    }, 2000);
  }, []);

  const handleError = useCallback((message) => {
    setErrorMessage(message);
    setErrorOpen(true);
    setTimeout(() => {
      setErrorOpen(false);
      setErrorMessage("");
    }, 2000);
  }, []);

  return (
    <Box sx={{ ...deleteAccount.parentBox }}>
      <Box sx={{ ...deleteAccount.formBox }}>
        <Typography sx={{ ...deleteAccount.typography }}>
          Delete your Account
        </Typography>
        <TextField
          id="outlined-basic"
          value={orgData?.creatorFullName}
          sx={{ ...deleteAccount.textFiled }}
          disabled
        />
        <TextField
          id="outlined-basic"
          value={orgData?.dmPhone}
          sx={{ ...deleteAccount.textFiled }}
          disabled
        />
        <TextField
          id="outlined-basic"
          value={orgData?.email}
          sx={{ ...deleteAccount.textFiled }}
          disabled
        />
        <TextField
          id="outlined-multiline-static"
          multiline
          placeholder="Reason for deleting Account"
          value={reason}
          rows={4}
          onChange={handleReasonChange}
          sx={{ ...deleteAccount.multiline }}
          error={!!reasonError}
          helperText={reasonError}
          required
        />
        <Button
          variant="contained"
          sx={{
            borderRadius: "0.7rem",
            fontFamily: "poppins",
            textTransform: "none",
            bgcolor: "#000",
            ":hover": {
              bgcolor: "#000",
            },
          }}
          onClick={handleOpenDialog}
          disabled={!reason.trim()}
        >
          Delete Account
        </Button>
      </Box>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle sx={{ fontFamily: "poppins" }}>
          Delete Account
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ fontFamily: "poppins" }}>
            Are you sure you want to delete your account?
      
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            sx={{
              bgcolor: "#000000",
              color: "white",
              fontFamily: "Poppins",
              borderRadius: "0.7rem",
              textTransform: "none",
              ":hover": { bgcolor: "#000000", color: "white" },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={deleteOrgAccount}
            sx={{
              bgcolor: "#000000",
              color: "white",
              borderRadius: "0.7rem",
              fontFamily: "Poppins",
              textTransform: "none",
              ":hover": { bgcolor: "#000000", color: "white" },
            }}
            disabled={loading}
          >
            {loading ? (
              <ThreeDot color="#FFFFFFFF" size="small" text="" textColor="" />
            ) : (
              "Delete Account"
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <PopupSuccess
        modelOpen={successOpen}
        onClose={() => setSuccessOpen(false)}
        message={successMessage}
      />
      <ReleaseJobPostModel
        modelOpen={errorOpen}
        onClose={() => setErrorOpen(false)}
        message={errorMessage}
      />
    </Box>
  );
};

export default DeleteAccount;