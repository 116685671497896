import { Box, Typography } from "@mui/material";
import GroupsIcon from "@mui/icons-material/Groups";
import { styled } from "@mui/material/styles";
import VideoCameraBackIcon from "@mui/icons-material/VideoCameraBack";
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import { useNavigate } from "react-router-dom";



const StyledCard = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "428px", // Default for larger screens
  maxWidth: "428px", // Prevents stretching
  height: "200px",
  padding: "32px",
  borderRadius: "24px",
  background: "rgba(255, 255, 255, 0.1)", // Light glass effect
  backdropFilter: "blur(15px)", // Ensure blur works properly
  WebkitBackdropFilter: "blur(15px)", // Safari support
  border: "2px solid #423bfa", // Semi-transparent border
  boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)", // Soft glow effect
  cursor: "pointer",
  transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",

  // ✅ Responsive width adjustment for small screens
  [theme.breakpoints.down("sm")]: {
    width: "90%", // Reduce width on small screens
  },

  "&:hover": {
    transform: "scale(1.02)",
    boxShadow: "0 6px 35px rgba(0, 0, 0, 0.15)", // Slightly stronger glow on hover
  },
}));


const IconWrapper = styled(Box)({
  marginTop: "2px",
  width: "48px",
  height: "48px",
});


const cardData = [
  {
    status: "In Last 30 Days",
    title: "14 Lakhs",
    description: "Jobseekers Registered",
    icon: <GroupsIcon sx={{ color: "#ffffff", fontSize: "35px",mt:0.5 }} />,
  },
  {
    status: "In Last 30 Days",
    title: "7.5 Lakhs",
    description: "Short Videos Profiles Uploaded",
    icon: <VideoCameraBackIcon sx={{ color: "#ffffff", fontSize: "35px",mt:0.5  }} />,
  },
  {
    status: "In Last 30 Days",
    title: "1.5 Lakhs ",
    description: "Conversations",
    icon: <ConnectWithoutContactIcon sx={{ color: "#ffffff", fontSize: "35px" ,mt:0.5 }} />,
  },
];

export default function LandingPageCards() {
  const navigate = useNavigate();

  return (
    <Box
      display="flex"
      gap={6}
      justifyContent="center"
      flexDirection={{ xs: "column", md: "row" }} // ✅ Responsive fix
      alignItems={{ xs: "center", md: "stretch" }} // ✅ Centers items on small screens
      onClick={() => navigate('/signinsignup')}
    >
      {cardData.map((card, index) => (
        <StyledCard key={index}>
          <Typography
            textAlign="right"
            sx={{
              color: "white",
              fontWeight: 600,
              fontSize: "14px",
              fontFamily: "satoshi",
            }}
          >
            {card.status}
          </Typography>
          <Typography
            sx={{
              color: "white",
              fontWeight: 600,
              marginBottom: 1,
              fontSize: "48px",
              fontFamily: "satoshi",
            }}
          >
            {card.title}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "start" }}>
            <IconWrapper>{card.icon}</IconWrapper>
            <Typography
              sx={{
                color: "rgba(255, 255, 255, 0.7)",
                fontSize: "16px",
                fontFamily: "satoshi",
              }}
            >
              {card.description}
            </Typography>
          </Box>
        </StyledCard>
      ))}
    </Box>
  );
}

