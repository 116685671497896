import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Grid,
  Container,
  CardMedia,
  Card,
  Button,
  Typography,
} from "@mui/material";
import JobSeekerPersonalDetails from "../../../components/JobSeekerComponents/JobSeekerProfileDetailComponent/JobSeekerPesonalDetail";
import WorkExperience from "../../../components/JobSeekerComponents/JobSeekerProfileDetailComponent/WorkExperience";
import JobSeekerEducationProfile from "../../../components/JobSeekerComponents/JobSeekerProfileDetailComponent/EducationProfile";
import NewNavBar from "../../../components/JobSeekerComponents/NavBar/NewNavbar";
import JobseekerResume from "../../../components/JobSeekerComponents/JobSeekerProfileDetailComponent/JobseekerResume";
import {
  addNewVideoCV,
  deleteCV,
  getUserApi,
  markAsDefaultCV,
} from "../../../api/jobseeker";
import JobSeekerCertifications from "../../../components/JobSeekerComponents/JobSeekerProfileDetailComponent/JobseekeerCertificate";
import JobSeekerVideoGallery from "./JobSeekerVideoGallery";
import JobSeekerVideoCarousel from "../../../components/JobSeekerComponents/OrganizationOvervIew/JobSeekerVideoCarousel";
import PopupSuccess from "../../../utils/popup/PopupSuccess";
import ReleaseJobPostModel from "../../../pages/EmployerPages/SubscriptionStatus/ReleaseJobPostModel";

// import JobSeekerDeleteAccount from "../../../components/JobSeekerComponents/JobSeekerUpdateProfile/JobSeekerDeleteAccount";

const token = localStorage.getItem("authToken");
const userId = localStorage.getItem("userId");

const JobSeekerProfileDetail = () => {
  const [videoCV, setVideoCV] = useState(null);
  const [userData, setUserData] = useState({ educations: [] });
  const [isUploading, setIsUploading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [defaultFileId, setDefaultFileId] = useState(null);
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSuccess = useCallback((message) => {
    setSuccessMessage(message);
    setSuccessOpen(true);
    setTimeout(() => {
      setSuccessOpen(false);
      setSuccessMessage("");
    }, 2000);
  }, []);

  const handleError = useCallback((message) => {
    setErrorMessage(message);
    setErrorOpen(true);
    setTimeout(() => {
      setErrorOpen(false);
      setErrorMessage("");
    }, 2000);
  }, []);

  useEffect(() => {
    setVideoCV(userData?.videoCV?.[0]?.url || null);
  }, [userData]);

  useEffect(() => {
    if (token) {
      const getUserDetails = async () => {
        try {
          const response = await getUserApi(userId, token);
          setUserData(response?.user);
        } catch (error) {
          console.log(error.message);
        }
      };
      getUserDetails();
    }
  }, []);

  const handleVideoUpload = async (file) => {
    if (!file) return;

    // Define allowed video MIME types
    const allowedTypes = [
      "video/mp4",
      "video/avi",
      "video/quicktime", // .mov files
      "video/webm",
    ];

    // Validate file type
    if (!allowedTypes.includes(file.type)) {
      handleError(
        "Invalid file type. Please upload a video in MP4, AVI, MOV, or WebM format."
      );

      return; // Exit early if file type is invalid
    }

    setIsUploading(true);
    const formData = new FormData();
    formData.append("videoCV", file); // Using the correct key for uploading

    try {
      const response = await addNewVideoCV(token, userId, formData); // API call to upload video

      setVideoCV(URL.createObjectURL(file)); // Show the uploaded video
      setUserData(response.user); // Ensure this updates the state correctly
      handleError(response.message);
    } catch (error) {
      console.error("Error uploading video:", error.message);
      handleError(error.message);
    } finally {
      setIsUploading(false);
    }
  };

  // Handle Delete
  const handleMarkDefault = async (video, fileType) => {
    const userId = localStorage.getItem("userId");
    if (!userId) {
      return;
    }

    const videoId = video?._id;
    if (!videoId) {
      return;
    }

    const payload = {
      fileId: videoId,
      fileType: fileType,
    };

    try {
      const data = await markAsDefaultCV(userId, payload);
      console.log("Marked as default:", data);

      // Update the user data state

      // Update the default file ID based on the response
      setDefaultFileId(videoId);
    } catch (error) {
      console.log("Marking as default failed:", error);
    }
  };

  // Handle Delete
  const handleDelete = async (video, fileType) => {
    const userId = localStorage.getItem("userId"); // Assuming the userId is stored in localStorage
    if (!userId) {
      console.log("User ID not found in localStorage");
      return;
    }

    const videoId = video?._id; // Video ID from the video object
    if (!videoId) {
      console.log("Video ID is missing");
      return;
    }

    // Prepare the payload
    const payload = {
      fileId: videoId,
      fileType: fileType, // Assuming file type is always videoCV
    };

    try {
      // Call deleteCV function with userId
      const data = await deleteCV(userId, payload);
      setUserData(data?.user);
      handleSuccess("Resume Deleted successfully!");

      // Optionally, you can update the videoList or perform other actions after successful delete
    } catch (error) {
      console.error("Failed to update resume:", error.message);
      handleError(error.message || "Failed to Delete");
    }
  };

  // const handleVideoDelete = async () => {
  //   const formData = new FormData();
  //   formData.append("filesToRemove", "videoCV"); // Correct key for removing video

  //   try {
  //     const response = await addNewVideoCV(formData); // Passing formData to delete video
  //     if (response.success) {
  //       setVideoCV(null); // Remove the video from the state
  //     }
  //   } catch (error) {
  //     console.error("Error deleting video:", error.message);
  //   }
  // };

  return (
    <>
      <NewNavBar />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center", // Center content horizontally
          alignItems: "flex-start", // Align items at the top
          minHeight: "100vh", // Ensure full viewport height
          padding: { xs: "10px", sm: "20px", md: "50px" }, // Responsive padding
          backgroundColor: "rgb(244, 245, 247)",
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            {/* Left Side: Video CV Card */}
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {/* Video Card */}
                <Card
                  sx={{
                    width: { xs: "100%", sm: "80%", md: "100%" },
                    borderRadius: 4,
                    mt: { xs: 2, sm: 4, md: 7 },
                    height: { xs: "30vh", sm: "40vh", md: "75vh" },
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {videoCV ? (
                    <Box sx={{ flexGrow: 1, width: "100%" }}>
                      <CardMedia
                        component="video"
                        src={videoCV || userData?.videoCV?.[0]?.url}
                        loop
                        alt="video"
                        sx={{
                          borderRadius: 4,
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                        }}
                        autoPlay
                        muted
                        controls
                      />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        textAlign: "center",
                      }}
                    >
                      <Typography sx={{ fontFamily: "IBM plex sans" }}>
                        No Video CV Found
                      </Typography>
                      <input
                        accept="video/mp4,video/avi,video/webm"
                        style={{ display: "none" }}
                        id="upload-video"
                        type="file"
                        onChange={(e) => {
                          const file = e.target.files[0];
                          if (file) {
                            handleVideoUpload(file);
                          }
                        }}
                        disabled={isUploading}
                      />
                      <label htmlFor="upload-video">
                        <Button
                          variant="contained"
                          component="span"
                          sx={{
                            backgroundColor: "#000000",
                            color: "#ffffff",
                            mt: 1,
                            borderRadius: "0.7rem",
                            "&:hover": {
                              backgroundColor: "#333333",
                            },
                          }}
                          disabled={isUploading}
                        >
                          {isUploading ? "Uploading..." : "Upload Video"}
                        </Button>
                        <Typography
                          sx={{ fontSize: "13px", color: "#3C3C3CFF", mt: 1 }}
                        >
                          Max size 10 MB
                        </Typography>
                      </label>
                    </Box>
                  )}
                </Card>
                <JobSeekerVideoCarousel
                  videos={userData?.videoCV?.map((video) => ({
                    id: video?._id,
                    jobVideoUrl: video?.url,
                    jobTitle: video?.file,
                  }))}
                />

                {/* View All Button - Now positioned outside the Card */}
                <Button
                  variant="contained"
                  onClick={() => setOpenModal(true)}
                  sx={{
                    backgroundColor: "#000000",
                    color: "#ffffff",
                    textTransform: "none",
                    borderRadius: "0.7rem",
                    mt: 2, // Space below the card
                    width: "50%",
                    "&:hover": {
                      backgroundColor: "#333333",
                    },
                  }}
                >
                  View All
                </Button>
              </Box>
            </Grid>

            {/* Right Side: Personal Details, Work Experience, and Education Profile */}
            <Grid item xs={12} md={8}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  width: "100%",
                  mt: 2,
                }}
              >
                {/* Personal Details */}
                <Box sx={{ width: "100%" }}>
                  <JobSeekerPersonalDetails />
                </Box>

                {/* Work Experience */}
                <Box sx={{ width: "100%" }}>
                  <WorkExperience />
                </Box>

                {/* Education Profile */}
                <Box sx={{ width: "100%" }}>
                  <JobSeekerEducationProfile
                    userData={userData}
                    setUserData={setUserData}
                  />
                </Box>
                <Box sx={{ width: "100%" }}>
                  <JobseekerResume
                    userData={userData}
                    onDelete={handleDelete}
                    onMarkAsDefault={handleMarkDefault}
                    defaultFileId={defaultFileId}
                  />
                </Box>
                <Box sx={{ width: "100%" }}>
                  <JobSeekerCertifications
                    userData={userData}
                    onDelete={handleDelete}
                    setUserData={setUserData}
                  />
                </Box>
                {/* <JobSeekerDeleteAccount/> */}
              </Box>
            </Grid>
          </Grid>
        </Container>

        <JobSeekerVideoGallery
          open={openModal}
          setOpen={setOpenModal}
          videoList={userData?.videoCV} // Ensure this is updated correctly
          onDelete={handleDelete}
          handleVideoUpload={handleVideoUpload}
          isUploading={isUploading}
        />
        <PopupSuccess
          modelOpen={successOpen}
          onClose={() => setSuccessOpen(false)}
          message={successMessage}
        />
        <ReleaseJobPostModel
          modelOpen={errorOpen}
          onClose={() => setErrorOpen(false)}
          message={errorMessage}
        />
      </Box>
    </>
  );
};

export default JobSeekerProfileDetail;
