import React, { memo, useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  Box,
  TextField,
  IconButton,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import { jwtDecode } from "jwt-decode";
import baseURL from "../../../api/baseUrl";

const CounsellorsChatList = memo(({ counsellors, onCounsellorSelect }) => {
  const [searchQuery, setSearchQuery] = useState("");

  const filteredCounsellors = counsellors?.filter((counsellor) =>
    counsellor.fullName?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleCounsellorClick = async (counsellor) => {
    try {
      // Get the auth token from local storage and decode it to get the userId
      const authToken = localStorage.getItem("authToken");
      if (!authToken) throw new Error("User not authenticated");

      const decodedToken = jwtDecode(authToken);
      const loggedInUserId = decodedToken.id; // Assuming 'id' is the field in the token

      // Construct the payload
      const payload = {
        users: [
          {
            userId: loggedInUserId,
            userModel: "User",
          },
          {
            userId: counsellor._id,
            userModel: "User",
          },
        ],
      };

      // Replace {{js_base_url}} with your actual base URL or import it
      const response = await fetch(`${baseURL}/chat/get/chatId`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch chat ID");
      }

      const data = await response.json();
      const chatId = data.chatId; // Assuming the response contains a 'chatId' field

      // Pass the counsellor object and chatId to the parent component
      onCounsellorSelect({ ...counsellor, chatId });
    } catch (error) {
      console.error("Error fetching chat ID:", error);
      // Optionally pass the counsellor without chatId if the API fails
      onCounsellorSelect(counsellor);
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        borderRadius: "12px",
        overflow: "hidden",
        background: "#fff",
        boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.1)",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflowY: "auto",
        "&::-webkit-scrollbar": { width: "0px" },
        "&::-webkit-scrollbar-thumb": { backgroundColor: "transparent" },
        scrollbarWidth: "none",
        "-ms-overflow-style": "none",
      }}
    >
      {/* Sticky Search Bar */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          position: "sticky",
          top: 0,
          backgroundColor: "#f5f5f5",
          padding: "4px 6px",
          borderRadius: "10px",
          mb: 1,
          zIndex: 1001,
          border: "1px solid #e0e0e0",
          transition: "all 0.3s ease-in-out",
          minHeight: "38px",
          "&:hover": {
            backgroundColor: "#e0e0e0",
            borderRadius: "10px",
            boxShadow: "0 0 4px rgba(0,0,0,0.2)",
          },
        }}
      >
        <Search sx={{ color: "black", fontWeight: "bold" }} />
        <TextField
          placeholder="Search counsellors"
          variant="standard"
          size="small"
          fullWidth
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            disableUnderline: true,
            sx: {
              paddingX: "8px",
              fontSize: "14px",
              fontWeight: "bold",
              color: "#374151",
            },
          }}
          sx={{
            flexGrow: 1,
            "& .MuiInputBase-root": { backgroundColor: "transparent" },
          }}
        />
        <IconButton size="small">
          <PeopleOutlineIcon sx={{ color: "#000", fontSize: "24px" }} />
        </IconButton>
      </Box>

      {/* Counsellors List */}
      <List sx={{ padding: 0, flexGrow: 1, backgroundColor: "#fff" }}>
        {filteredCounsellors?.length > 0 ? (
          filteredCounsellors.map((counsellor) => (
            <ListItem
              key={counsellor._id}
              onClick={() => handleCounsellorClick(counsellor)}
              sx={{
                padding: "2px 0px",
                transition: "background 0.2s",
                "&:hover": { backgroundColor: "#e0e0e0", cursor: "pointer" },
                borderBottom: "1px solid #e5e5e5",
                display: "flex",
                alignItems: "center",
              }}
            >
              <ListItemAvatar>
                <Avatar
                  alt={counsellor.fullName}
                  src={counsellor.profileImage}
                  sx={{ width: 42, height: 42 }}
                />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#000",
                      fontSize: "0.90rem",
                      fontWeight: 400,
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    {counsellor.fullName}
                  </Typography>
                }
              />
            </ListItem>
          ))
        ) : (
          <Typography
            variant="body2"
            sx={{
              textAlign: "center",
              color: "#666",
              mt: 2,
              fontFamily: "IBM Plex Sans",
            }}
          >
            No counsellors found
          </Typography>
        )}
      </List>
    </Box>
  );
});

export default CounsellorsChatList;