import baseUrl from "./baseUrl";
import { jwtDecode } from "jwt-decode";


export const fetchJobSeekerSupport = async () => {
    const authToken = localStorage.getItem('authToken');
    if (!authToken) throw new Error('User not authenticated');

    const decodedToken = jwtDecode(authToken);
    const userId = decodedToken.id;

    const token = localStorage.getItem('authToken')?.replace(/"/g, '');

    if (!token) {
        throw new Error('No access token found');
    }

    try {
        const response = await fetch(`${baseUrl}/getTicket/${userId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });

        // If the fetch returns null due to token invalidation, handle accordingly
        if (!response) {
            return; // Handle logout and redirect behavior
        }


        // Map the new response data to match the required fields

        return response.json();

    } catch (error) {
        console.error('Error fetching Support details:', error);
        throw error;
    }
};

// Assuming this is your API call function
export const fetchJobSeekerSupportById = async (supportId) => {
    const authToken = localStorage.getItem('authToken')?.replace(/"/g, '');

    try {
        const response = await fetch(`${baseUrl}/js_convo/${supportId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`,
            },
        });


        if (!response.ok) {
            console.warn("Token may be invalid or session expired.");
            return null;
        }

        const data = await response.json(); // assuming JSON response


        return data; // Extract messages from the response


    } catch (error) {
        console.error("Error fetching Support details:", error);
        throw error;
    }
};

export const employerCreateTicket = async (payload) => {
    const token = localStorage.getItem('token')?.replace(/"/g, '');

    if (!token) {
        throw new Error('No access token found');
    }

    try {
        const response = await fetch(`${baseUrl}/createOrgTicket`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload), // Send payload directly, not nested
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || `Failed to create ticket: ${response.status}`);
        }

        const data = await response.json();

        if (data.message === 'Support ticket created successfully with an initial message') {
            return {
                supportTicket: data.supportTicket,
                initialMessage: data.initialMessage,
            };
        } else {
            throw new Error(data.message || 'Unknown error occurred');
        }
    } catch (error) {
        console.error('Error during creating ticket:', error);
        throw error;
    }
};

export const jobSeekerCreateTicket = async (payload, supportId) => {
    const userId = localStorage.getItem('userId')?.replace(/"/g, '');

    try {
        const headers = {
            'Content-Type': 'application/json',
        };

        // Construct the URL based on whether userId exists
        const url = userId ? `${baseUrl}/createTicket/${userId}` : `${baseUrl}/createTicket/:userId`;

        const response = await fetch(url, {
            method: 'POST',
            headers,
            body: JSON.stringify(payload),
        });
        const data = await response.json();

        if (!response.ok) {
            throw new Error(data.message || `Failed to create ticket: ${response.status} ${response.statusText}`);
        }

        console.log('response in create', data);
        return data; // Return the successful response data

    } catch (error) {
        console.error('Error during creating ticket:', error);
        throw error; // Re-throw the error to be caught in handleSave
    }
};


export const jobSeekerSendMessage = async (payload, supportId) => {
    const authToken = localStorage.getItem('authToken')?.replace(/"/g, '');

    if (!authToken) {
        throw new Error('No access token found');
    }

    try {
        const response = await fetch(`${baseUrl}/replyToUserTicket/${supportId}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${authToken}`,
                'Content-Type': 'application/json', // Set header for JSON
            },
            body: JSON.stringify(payload), // Convert payload to JSON string
        });

        // Check if the response status is not OK (e.g., 200–299)
        if (!response.ok) {
            const errorData = await response.json(); // Parse the error response body
            throw new Error(`Failed to send message: ${errorData.message || response.statusText}`);
        }

        const data = await response.json(); // Parse the JSON response

        if (data.message === 'Reply sent successfully') {
            return data?.userMessage
                ; // Return relevant data
        } else {
            throw new Error(`Failed to send message: ${data.message || 'Unknown error'}`);
        }
    } catch (error) {
        console.error('Error during sending message:', error);

        // Add response-specific error handling if needed
        throw error; // Propagate error to the calling function
    }
};


export const jobSeekerUpdateChatStatus = async (payload, supportId) => {
    const authToken = localStorage.getItem('authToken')?.replace(/"/g, '');

    if (!authToken) {
        throw new Error('No access token found');
    }

    try {
        const response = await fetch(`${baseUrl}/jsSupportStatus/${supportId}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${authToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        });

        // Check if the response is okay (HTTP 2xx status)
        if (!response.ok) {
            const errorMessage = `Failed to update status. HTTP ${response.status} - ${response.statusText}`;
            console.error(errorMessage);
            const errorBody = await response.text();
            throw new Error(`${errorMessage}. Response body: ${errorBody}`);
        }

        const data = await response.json(); // Parse the JSON response

        // Validate the response content
        if (data?.message?.includes('Support ticket status updated')) {
            return data.supportTicket; // Return the relevant data
        } else {
            throw new Error(`Unexpected response: ${data.message || 'Unknown error'}`);
        }
    } catch (error) {
        console.error('Error during status update:', error.message);

        // Add specific error details if they exist
        if (error.response) {
            const errorDetails = {
                status: error.response.status,
                message: error.response.statusText,
                body: await error.response.text(),
            };
            console.error('Error response details:', errorDetails);
        }

        throw error; // Re-throw the error to propagate it
    }
};


export const reportJobseekerApi = async (formData, token) => {
    try {
      const response = await fetch(`${baseUrl}/reportJobseeker`, {
        headers: {
          "Content-Type": "Application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "POST",
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Network response was not ok");
      }
  
     
      return data;
    } catch (error) {
      console.log(error.message);
      throw error;
    }
  };