import React, { useState } from "react";
import { useLocation } from "react-router-dom"; // Import useLocation
import { Box, Typography, TextField, Button } from "@mui/material";
import googlePlay from "../../../assets/images/googlePlay.png";
import appStore from "../../../assets/images/appStore.png";
import { ThreeDot } from "react-loading-indicators";

const StandOut = ({ handleSendAppLink,loading }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState("");
  const location = useLocation(); // Get current route

  const isValidPhoneNumber = phoneNumber && /^[6-9][0-9]{9}$/.test(phoneNumber);

  const handleButtonClick = () => {
    if (isValidPhoneNumber) {
      handleSendAppLink(phoneNumber);
      setPhoneNumber("");
      setError("");
    }
  };

  // Determine which QR code to display based on the route
  const qrImage =
    location.pathname === "/employerJobPosting"
      ? "/qrEmp.png"
      : "/qrJobseeker.png";

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "400px",
        backgroundColor: "#fff",
        borderRadius: "16px",
        boxShadow: "0 8px 24px rgba(0, 0, 0, 0.1)",
        padding: "2rem",
        textAlign: "center",
        fontFamily: "IBM Plex Sans, sans-serif",
      }}
    >
      {/* QR Code */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: 2,
        }}
      >
        <img
          src={qrImage} // Use dynamic qrImage based on route
          alt="QR Code"
          style={{ width: "130px", height: "130px" }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: 2,
        }}
      >
        <img src={appStore} alt="App Store" style={{ width: "120px" }} />
        <img src={googlePlay} alt="Google Play" style={{ width: "120px" }} />
      </Box>
      {/* Title */}
      <Typography
        variant="h5"
        sx={{
          fontWeight: 600,
          fontSize: "1.5rem",
          color: "#000",
          fontFamily: "IBM Plex Sans, sans-serif",
        }}
      >
        Download The App Now
      </Typography>

      {/* Subtitle */}
      <Typography
        variant="body1"
        sx={{
          color: "#6c757d",
          marginTop: "0.5rem",
          fontFamily: "IBM Plex Sans, sans-serif",
        }}
      >
        Available for both Android and iOS apps
      </Typography>

      {/* Input and Button */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "1.5rem",
          gap: "0.5rem",
          border: "1px solid #e0e0e0",
          borderRadius: "12px",
          padding: "0.5rem",
          backgroundColor: "#f9f9f9",
        }}
      >
        <TextField
          placeholder="Enter mobile number..."
          variant="standard"
          value={phoneNumber}
          onChange={(e) => {
            const value = e.target.value;
            if (/^[0-9]{0,10}$/.test(value)) {
              setPhoneNumber(value);
              if (value && !/^[6-9][0-9]{0,9}$/.test(value)) {
                setError("Must start with 6, 7, 8, or 9 and be 10 digits");
              } else if (
                value.length === 10 &&
                !/^[6-9][0-9]{9}$/.test(value)
              ) {
                setError("Please enter a valid 10-digit Indian phone number");
              } else {
                setError("");
              }
            }
          }}
          inputProps={{
            maxLength: 10,
          }}
          InputProps={{
            disableUnderline: true,
            sx: {
              fontFamily: "IBM Plex Sans, sans-serif",
              fontSize: "0.9rem",
              flex: 1,
            },
          }}
          sx={{
            flex: 1,
            "& input": {
              padding: "0.5rem",
            },
          }}
          error={!!error}
          helperText={error}
        />
        <Button
          variant="contained"
          onClick={handleButtonClick}
          disabled={!isValidPhoneNumber}
          sx={{
            backgroundColor: "#000000",
            color: "#fff",
            textTransform: "none",
            fontWeight: 600,
            padding: "0.5rem 1.5rem",
            borderRadius: "0.7rem",
            fontFamily: "IBM Plex Sans, sans-serif",
            "&:hover": {
              backgroundColor: "#000000",
            },
            whiteSpace: "nowrap",
            opacity: !isValidPhoneNumber ? 0.9 : 1,
          }}
        >
          {loading? (
               <ThreeDot color="#fff" size="small" text="" textColor="" />
          ):"Get link"}
        </Button>
      </Box>
    </Box>
  );
};

export default StandOut;
