import React, { useState } from "react";
import { Box, IconButton, Avatar, Typography, Card } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";


const CompanyVideoCarousel = ({ videos, onVideoSelect,job }) => {
  const [startIndex, setStartIndex] = useState(0);


  const handlePrev = () => {
    setStartIndex((prev) => Math.max(prev - 1, 0));
  };

  const handleNext = () => {
    setStartIndex((prev) => Math.min(prev + 1, videos.length - 3));
  };

  const truncateText = (text, maxLength) => {
    if (!text) return "";
    return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
  };

  return (
    <Box sx={{ width: 1200, position: "relative", overflow: "hidden", p: 1 }}>
      <Typography sx={{ fontFamily: "IBM plex sans", fontWeight: "bold", mb: 1 }}>
        Recent Posts
      </Typography>
      
      <Box
        sx={{
          display: "flex",
          width: "100%",
          gap: 4,
          transition: "transform 0.3s ease-in-out",
          transform: `translateX(-${startIndex * (100 / 3)}%)`,
          borderRadius: 0,
        }}
      >
        {videos?.map((video) => (
          <Card
            key={video.id}
            sx={{
              width: 200,
              height: 280,
              borderRadius: "10px",
              cursor: "pointer",
              flexShrink: 0,
              backgroundColor: "#000",
              position: "relative",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => onVideoSelect(video)}
          >
            {/* Centered Avatar */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                flex: 1,
                mb: 6,
                width: "100%",
              }}
            >
              <Avatar
                src={video.logo || "/placeholder.svg"}
                alt={video.companyName || "Company"}
                sx={{ width: 80, height: 80 }}
              />
            </Box>

            {/* Bottom Details Box */}
            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                backgroundColor: "rgba(0, 0, 0, 0.7)",
                color: "white",
                p: 1,
                borderRadius: "0 0 10px 10px",
                zIndex: 1,
              }}
            >
              <Box
                sx={{
                  display: "inline-block",
                  maxWidth: "100%",
                  fontFamily: "poppins",
                  border: "1px solid #fff",
                  borderRadius: "0.6rem",
                  px: "10px",
                  mb: 1,
                  whiteSpace: "normal",
                  wordBreak: "break-word",
                  textAlign: "start",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "poppins",
                    display: "inline",
                  }}
                >
                  {truncateText(video.jobTitle,20) }
                  <br />
                  {truncateText(video.companyName,20) }
                </Typography>
              </Box>

              <Typography
                variant="caption"
                sx={{ fontFamily: "poppins", display: "block" }}
                textAlign="start"
              >
                {truncateText(video.location, 20)}
              </Typography>

              {video.ctc && (
                <Typography
                  variant="caption"
                  sx={{ display: "block", fontFamily: "poppins" }}
                  textAlign="start"
                >
                  {video.ctc} LPA
                </Typography>
              )}
            </Box>
          </Card>
        ))}
      </Box>

      {/* Navigation Buttons */}
      <IconButton
        onClick={handlePrev}
        disabled={startIndex === 0}
        sx={{
          position: "absolute",
          left: 8,
          top: "50%",
          transform: "translateY(-50%)",
          zIndex: 1,
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          "&:hover": { backgroundColor: "rgba(255, 255, 255, 1)" },
        }}
      >
        <ChevronLeftIcon />
      </IconButton>

      <IconButton
        onClick={handleNext}
        disabled={startIndex >= videos?.length - 3}
        sx={{
          position: "absolute",
          right: 8,
          top: "50%",
          transform: "translateY(-50%)",
          zIndex: 1,
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          "&:hover": { backgroundColor: "rgba(255, 255, 255, 1)" },
        }}
      >
        <ChevronRightIcon />
      </IconButton>
    </Box>
  );
};

export default CompanyVideoCarousel;