"use client";

import {  useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { companyDataApi } from "../../../api/subscriptions";

import { ThreeDot } from "react-loading-indicators";
import { useOrg } from "../../../context/OrgContext";
import { useModal } from "../../../context/ModelContext";


const StyledDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    width: "100%",
    maxWidth: 400,
    borderRadius: 12,
    padding: 16,
  },
});

const StyledTextField = styled(TextField)({
  marginBottom: 3,
  fontFamily: "Poppins",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    fontFamily: "Poppins",
    fontSize: "14px",

    minHeight: "36px", // Reduced height
    "& .MuiInputBase-input": {
      padding: "8px 12px", // Adjust padding to make it smaller
    },
    "& fieldset": {
      borderColor: "#e0e0e0",
    },
    "&:hover fieldset": {
      borderColor: "#1976d2",
    },
  },
});

const StyledSelect = styled(Select)({
  marginBottom: 8,
  borderRadius: 8,
  "& .MuiOutlinedInput-root": {
    minHeight: "36px", // Reduced height
    fontSize: "14px",
    padding: "8px 12px", // Adjust padding for a compact look
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#e0e0e0",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#1976d2",
  },
  // Target the select input directly
  "& .MuiSelect-select": {
    minHeight: "36px", // Ensure the select input height is reduced
    padding: "8px 12px", // Adjust padding for a compact look
    boxSizing: "border-box", // Ensure padding is included in the height
  },
});

export default function SubscribeForm({ open, onClose, subscriptionId,handleError }) {
  const [loading, setLoading] = useState(false)
  const { orgData } = useOrg();
  const [formData, setFormData] = useState({
    hrName: orgData?.hrName,
    hrPhone: orgData?.hrPhone,
    hrEmail: orgData?.hrEmail,
    hrDesignation: "",
    dmName: "",
    dmPhone: "",
    dmEmail: "",
    dmDesignation: "",
  });
  const { handleModelClose } = useModal();

  console.log("orgdata", orgData);

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const orgId = localStorage.getItem("organizationId");

;

  // Handle Input Change
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Remove error message when user starts typing
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  // Validation function
  const validateForm = () => {
    let newErrors = {};
    if (!formData.hrName?.trim()) newErrors.hrName = "HR Name is required";
    if (!formData.hrPhone?.trim()) {
      newErrors.hrPhone = "HR Phone is required";
    } else if (!/^[6-9]\d{9}$/.test(formData.hrPhone)) {
      newErrors.hrPhone =
        "Phone number must be 10 digits and start with 6 or above";
    }
    if (
      formData.hrPhone &&
      formData.dmPhone &&
      formData.hrPhone === formData.dmPhone
    ) {
      newErrors.dmPhone = "HR and DM phone numbers cannot be the same";
      newErrors.hrPhone = "HR and DM phone numbers cannot be the same";
    }

    if (!formData.hrEmail?.trim()) {
      newErrors.hrEmail = "HR Email is required";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.hrEmail)) {
      newErrors.hrEmail = "Invalid email format";
    }
    if (!formData.hrDesignation.trim())
      newErrors.hrDesignation = "HR Designation is required";

    if (!formData.dmName?.trim()) newErrors.dmName = "DM Name is required";
    if (!formData.dmPhone?.trim()) {
      newErrors.dmPhone = "HR Phone is required";
    } else if (!/^[6-9]\d{9}$/.test(formData.dmPhone)) {
      newErrors.dmPhone =
        "Phone number must be 10 digits and start with 6 or above";
    }
    if (!formData.dmEmail?.trim()) {
      newErrors.dmEmail = "DM Email is required";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.dmEmail)) {
      newErrors.dmEmail = "Invalid email format";
    }
    if (!formData.dmDesignation?.trim())
      newErrors.dmDesignation = "DM Designation is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle Form Submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) return; // Stop if validation fails
    handleModelClose();
    setLoading(true);
    try {
      const response = await companyDataApi(orgId, formData);
      if (response) {
        navigate(`/employer/paymentPage/${subscriptionId}`);

      }
    } catch (error) {
        handleError(error.message || "Failed To Submit Subscription Form Try Again")
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledDialog
      open={open}
      onClose={() => {
        onClose();
        setErrors({});
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 1,
        }}
      >
        <DialogTitle
          sx={{
            p: 0,
            fontSize: 20,
            fontWeight: 500,
            fontFamily: "poppins",
            color: "#423bfa",
          }}
        >
          Free Subscription Form
        </DialogTitle>
        <Typography
          color="text.primary"
          sx={{
            fontFamily: "poppins",
            fontSize: "14px",
            fontweight: "bold",
            color: "#423bfa",
          }}
        >
          - For 30 days
        </Typography>
        <IconButton
          onClick={() => {
            onClose();
            setErrors({});
          }}
          size="small"
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <Typography
        color="text.primary"
        sx={{ fontFamily: "poppins", fontSize: "18px" }}
      >
        (HR/TA Head Details)
      </Typography>

      <DialogContent sx={{ p: 0 }}>
        {/* Name Field */}
        <Typography
          variant="body2"
          sx={{ fontFamily: "poppins", fontWeight: 500 }}
        >
          Name
        </Typography>
        <StyledTextField
          fullWidth
          name="hrName"
          placeholder="Enter Name"
          value={formData.hrName}
          onChange={handleChange}
        />
        {errors.hrName && (
          <Typography color="error" sx={{ fontSize: "13px" }}>
            {errors.hrName}
          </Typography>
        )}

        {/* Contact Number Field */}
        <Typography
          variant="body2"
          sx={{ fontFamily: "poppins", fontWeight: 500 }}
        >
          Contact Number
        </Typography>
        <StyledTextField
          fullWidth
          name="hrPhone"
          placeholder="Enter Contact Number"
          type="text" // Change to "text" to handle input restrictions properly
          value={formData.hrPhone}
          onChange={(e) => {
            const value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
            if (value.length <= 10) {
              setFormData({ ...formData, hrPhone: value });
            }
          }}
          inputProps={{ maxLength: 10 }} // Prevents entering more than 10 characters
        />

        {errors.hrPhone && (
          <Typography color="error" sx={{ fontSize: "13px" }}>
            {errors.hrPhone}
          </Typography>
        )}

        {/* Email Field */}
        <Typography
          variant="body2"
          sx={{ fontFamily: "poppins", fontWeight: 500 }}
        >
          Email
        </Typography>
        <StyledTextField
          fullWidth
          name="hrEmail"
          placeholder="Enter Email Id"
          value={formData.hrEmail}
          onChange={handleChange}
        />
        {errors.hrEmail && (
          <Typography color="error" sx={{ fontSize: "13px" }}>
            {errors.hrEmail}
          </Typography>
        )}
        {/* Designation Field */}
        <Typography
          variant="body2"
          sx={{ fontFamily: "poppins", fontWeight: 500, mb: 0.5 }}
        >
          Designation
        </Typography>
        <FormControl fullWidth>
          <StyledSelect
            name="hrDesignation"
            value={formData.hrDesignation}
            onChange={handleChange}
            displayEmpty
            renderValue={(value) => value || "Select Designation"}
          >
            <MenuItem value="HR Head">HR Head</MenuItem>
            <MenuItem value="HR Manager">HR Manager</MenuItem>
            <MenuItem value="Talents Acquisition Head">
              Talents Acquisition
            </MenuItem>
            <MenuItem value="Recruiter">Recruiter</MenuItem>
          </StyledSelect>
        </FormControl>
        {errors.hrDesignation && (
          <Typography color="error" sx={{ fontSize: "13px" }}>
            {errors.hrDesignation}
          </Typography>
        )}
        {/* Authorized Person Details */}
        <Typography
          color="text.primary"
          gutterBottom
          sx={{ fontFamily: "poppins", fontSize: "18px" }}
        >
          (Director/CEO/Founder Details)
        </Typography>

        {/* Name Field */}
        <Typography
          variant="body2"
          sx={{ fontFamily: "poppins", fontWeight: 500 }}
        >
          Name
        </Typography>
        <StyledTextField
          fullWidth
          name="dmName"
          placeholder="Enter Name"
          value={formData.dmName}
          onChange={handleChange}
        />
        {errors.dmName && (
          <Typography color="error" sx={{ fontSize: "13px" }}>
            {errors.dmName}
          </Typography>
        )}
        {/* Contact Number Field */}
        <Typography
          variant="body2"
          sx={{ fontFamily: "poppins", fontWeight: 500 }}
        >
          Contact Number
        </Typography>
        <StyledTextField
          fullWidth
          name="dmPhone"
          placeholder="Enter Contact Number"
          type="text" // Change to "text" to handle restrictions properly
          value={formData.dmPhone}
          onChange={(e) => {
            const value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
            if (value.length <= 10) {
              setFormData({ ...formData, dmPhone: value });
            }
          }}
          inputProps={{ maxLength: 10 }} // Restricts input to 10 characters
        />
        {errors.dmPhone && (
          <Typography color="error" sx={{ fontSize: "13px" }}>
            {errors.dmPhone}
          </Typography>
        )}

        {/* Email Field */}
        <Typography
          variant="body2"
          sx={{ fontFamily: "poppins", fontWeight: 500 }}
        >
          Email
        </Typography>
        <StyledTextField
          fullWidth
          name="dmEmail"
          placeholder="Enter Email"
          value={formData.dmEmail}
          onChange={handleChange}
        />
        {errors.dmEmail && (
          <Typography color="error" sx={{ fontSize: "13px" }}>
            {errors.dmEmail}
          </Typography>
        )}
        {/* Designation Field */}
        <Typography
          variant="body2"
          sx={{ fontFamily: "poppins", fontWeight: 500, mb: 0.5 }}
        >
          Designation
        </Typography>
        <FormControl fullWidth>
          <StyledSelect
            name="dmDesignation"
            value={formData.dmDesignation}
            onChange={handleChange}
            displayEmpty
            renderValue={(value) => value || "Select Designation"}
          >
            <MenuItem value="Founder">Founder</MenuItem>
            <MenuItem value="Managing Director">Managing Director</MenuItem>
            <MenuItem value="Director">Director</MenuItem>
            <MenuItem value="CTO">CTO</MenuItem>
          </StyledSelect>
        </FormControl>
        {errors.dmDesignation && (
          <Typography color="error" sx={{ fontSize: "13px" }}>
            {errors.dmDesignation}
          </Typography>
        )}
        {/* Submit Button */}
        <Button
          fullWidth
          variant="contained"
          onClick={handleSubmit}
          sx={{
            py: 0.5,
            borderRadius: "0.7rem",
            textTransform: "none",
            fontFamily: "poppins",
            fontSize: 16,
            backgroundColor: "#000000",
            "&:hover": {
              backgroundColor: "#000000",
            },
          }}
        >
          {loading ? (
            <ThreeDot color="#FFFFFFFF" size="small" text="" textColor="" />
          ) : (
            "Submit Now"
          )}  
        </Button>
      </DialogContent>

    </StyledDialog>
  );
}
