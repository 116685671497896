import React, { lazy, useCallback, useEffect, useState } from "react";
import { Box, Container,} from "@mui/system";
import { getJobseekerProfileApi } from "../../../api/jobseeker";
import {  useNavigate, useParams } from "react-router-dom";
import ReleaseJobPostModel from "../SubscriptionStatus/ReleaseJobPostModel";

import { Suspense } from "react";
import SimilarProfilesSkeleton from "../../../utils/lazy/SimilarProfilesSkeleton";
import VideoCardSkeleton from "../../../utils/lazy/VideoCardSkeleton";
import DescriptionBox from "../../../utils/lazy/DescriptionBox";
import ArrowBackIcon from "@mui/icons-material/ArrowBack"; // Import the back arrow icon
import { IconButton } from "@mui/material";

const SimilarProfiles = lazy(() =>
  import(
    "../../../components/EmployerComponents/JobSeekerProfile/SimilarProfiles"
  )
);
const JobSeekerVideo = lazy(() =>
  import(
    "../../../components/EmployerComponents/JobSeekerProfile/JobSeekerVideo"
  )
);
const JobSeekerDetails = lazy(() =>
  import(
    "../../../components/EmployerComponents/JobSeekerProfile/JobSeekerDetails"
  )
);

const ViewJobSeekerProfile = () => {
  const [jobSeekerData, setJobseekerData] = useState(null);
    const [errorOpen, setErrorOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
  const { id } = useParams();
  const token = localStorage.getItem("token");
  // const location = useLocation();  
  // const [storedData, setStoredData] = useState(null);


  const navigate = useNavigate();
  const [currentId, setCurrentId] = useState(id);

    const handleError = useCallback((message) => {
      setErrorMessage(message);
      setErrorOpen(true);
      setTimeout(() => {
        setErrorOpen(false);
        setErrorMessage("");
      }, 2000);
    }, []);

  // useEffect(() => {
  //   const storedProfile = JSON.parse(localStorage.getItem("jobSeekerProfile"));
  //   setStoredData(storedProfile);
  // }, [id]);



  const handleSelectProfile = (newId) => {
    setCurrentId(newId);
    window.scrollTo({ top: 0, behavior: "smooth" })
  };

  const handleGoBack = () => {
    navigate(-1); // Go back to the previous page
  };

  useEffect(() => {
    if (currentId) {
      const candidateProfile = async () => {
        try {
          const response = await getJobseekerProfileApi(currentId, token);
          setJobseekerData(response?.user); // Set the fetched data
        } catch (error) {
          handleError(error.message || "Failed to fetch candidate profile");
        }
      };
      candidateProfile();
    }
  }, [currentId, token, navigate, handleError]);

  console.log("jobseekerdata", jobSeekerData);

  return (
    <>
      <Container
        maxWidth="lg"
        sx={{
          padding: 2,
          minHeight: "100vh",
          position: "relative", // Add relative positioning for absolute positioned back button
        }}
      >
        {/* Back Button */}
        <IconButton
          onClick={handleGoBack}
          sx={{
            position: "absolute",
            left: 16,
            top: 0,
            
            zIndex: 1,
            color: "primary.main",
            backgroundColor: "background.paper",
            "&:hover": {
              backgroundColor: "action.hover",
            },
          }}
        >
          <ArrowBackIcon />
        </IconButton>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            gap: 0.5,
            fontFamily: "IBM Plex Sans, sans-serif",
            flexDirection: { xs: "column", md: "row" },
            pt: 4, // Add padding top to make space for the back button
          }}
        >
          {/* Left Side: Title, Video, Video Carousel */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 0.5,
              width: { xs: "100%", md: "70%" },
            }}
          >
            {/* Conditional rendering for JobSeekerVideo and JobSeekerDetails */}
            {jobSeekerData && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    gap: 0.5,
                    flexDirection: { xs: "column", md: "row" },
                  }}
                >
                  {/* Video Card */}
                  <Box
                    sx={{
                      flex: 1,
                      maxWidth: { xs: "100%", md: "50%" },
                    }}
                  >
                    <Suspense fallback={<VideoCardSkeleton />}>
                      <JobSeekerVideo data={jobSeekerData} />
                    </Suspense>
                  </Box>
                    
                  {/* Description */}
                  <Box
                    sx={{
                      flex: 1,
                      maxWidth: { xs: "100%", md: "50%" },
                    }}
                  >
                    <Suspense fallback={<DescriptionBox />}>
                      <JobSeekerDetails data={jobSeekerData} />
                    </Suspense>
                  </Box>
                </Box>

                {/* Video Carousel */}
                <Box
                  sx={{
                    width: "100%",
                    marginTop: 1,
                  }}
                >
                  <Suspense fallback={<SimilarProfilesSkeleton />}>
                    <SimilarProfiles
                      currentId={currentId}
                      handleSelectProfile={handleSelectProfile}
                    />
                  </Suspense>
                </Box>
              </>
            )}
          </Box>
        </Box>
        <ReleaseJobPostModel
        modelOpen={errorOpen}
        onClose={() => setErrorOpen(false)}
        message={errorMessage}
      />
      </Container>
    </>
  );
};

export default ViewJobSeekerProfile;