import React, { useEffect, useState, useCallback} from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
  Stack,
  Avatar,
} from "@mui/material";
import { getjobSeekerChats, getjobSeekerChatById } from "../../../api/jobseekerchat";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { getUserApi } from "../../../api/jobseeker";
import JobSeekerToJobSeekerChatList from "../../../components/JobSeekerComponents/JobSeekerToJobSeekerChat/JobSeekerToJobSeekerChatList";
import JobSeekerToJobSeekerWindow from "../../../components/JobSeekerComponents/JobSeekerToJobSeekerChat/JobSeekerToJobSeekerChatWindow";
import JobSeekerCounsellorChatWindow from "../../../components/JobSeekerComponents/JobSeekerToJobSeekerChat/JobSeekerCounsellorChatWindow";
import initializeSocket, { getSocket } from "../../../utils/socket/socket";
import { getCunsellorsApi } from "../../../api/common";

const JobSeekerToJobSeekerSupport = ({ inviteChatId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedChatId, setSelectedChatId] = useState(inviteChatId);
  const [chatList, setChatList] = useState([]);
  const [selectedChatData, setSelectedChatData] = useState(null);
  const [selectedChat, setSelectedChat] = useState(null);
  const [selectedCounsellor, setSelectedCounsellor] = useState(null); // For counsellor chat
  const [isLoadingChats, setIsLoadingChats] = useState(true);

  const token = localStorage.getItem("authToken");
  const userId = localStorage.getItem("userId");
  const [userData, setUserData] = useState();
  const [counsellors, setCounsellors] = useState();

  useEffect(() => {
    const getAllCounsellors = async () => {
      try {
        const response = await getCunsellorsApi();
        setCounsellors(response);
      } catch (error) {
        console.log(error.message);
      }
    };
    getAllCounsellors();
  }, []);

  const fetchChatList = useCallback(async () => {
    setIsLoadingChats(true);
    try {
      const response = await getjobSeekerChats();
      setChatList(response?.chats || []);
    } catch (error) {
      console.error("Failed to fetch chat list:", error.message);
    }
    finally{
      setIsLoadingChats(false);

    }
  }, []);

  const fetchSelectedChat = useCallback(async (chatId) => {
    if (!chatId) return;
    try {
      const chatData = await getjobSeekerChatById(chatId);
      setSelectedChat(chatData?.messages || chatData || []);
    } catch (error) {
      console.error("Failed to fetch chat details:", error.message);
    }
  }, []);

  useEffect(() => {
    if (userId && token) {
      initializeSocket(userId, "User", token);
    }
    fetchChatList();

    const socket = getSocket();
    if (socket) {
      const handleNewMessage = (newMessage) => {
        setChatList((prevChatList) => {
          const updatedChatList = [...prevChatList];
          const chatIndex = updatedChatList.findIndex((chat) => chat._id === newMessage.chatId);

          if (chatIndex !== -1) {
            const updatedChat = { ...updatedChatList[chatIndex] };
            updatedChat.messages = updatedChat.messages
              ? [...updatedChat.messages.filter((msg) => msg._id !== newMessage._id), newMessage]
              : [newMessage];
            updatedChat.lastMessage = newMessage;
            updatedChat.lastMessageTimestamp = newMessage.timestamp;
            updatedChatList.splice(chatIndex, 1);
            updatedChatList.unshift(updatedChat);
            if (selectedChatId === newMessage.chatId) {
              setSelectedChatData(updatedChat);
            }
          } else {
            const newChat = {
              _id: newMessage.chatId,
              participants: [
                { userId: newMessage.senderId, userModel: newMessage.senderModel },
                { userId: newMessage.receivers[0]?.userId, userModel: newMessage.receivers[0]?.userModel },
              ],
              messages: [newMessage],
              lastMessage: newMessage,
              lastMessageTimestamp: newMessage.timestamp,
            };
            updatedChatList.unshift(newChat);
            if (selectedChatId === newMessage.chatId) {
              setSelectedChatData(newChat);
            }
          }
          return updatedChatList;
        });

        if (selectedChatId === newMessage.chatId) {
          setSelectedChat((prevChat) => {
            const safePrev = Array.isArray(prevChat) ? prevChat : [];
            const messageExists = safePrev.some((msg) => msg._id === newMessage._id);
            if (messageExists) return safePrev;
            return [...safePrev.filter((msg) => !msg._id?.startsWith("temp-")), newMessage];
          });
        }
      };

      socket.on("newMessage", handleNewMessage);
      socket.on("connect_error", (error) => console.error("Socket.IO connection error:", error));

      return () => {
        socket.off("newMessage", handleNewMessage);
        socket.off("connect_error");
      };
    }
  }, [fetchChatList, selectedChatId, userId, token]);

  useEffect(() => {
    if (selectedChatId) fetchSelectedChat(selectedChatId);
  }, [selectedChatId,fetchSelectedChat, fetchChatList]);

  useEffect(() => {
    if (inviteChatId) {
      setSelectedChatId(inviteChatId);
      const chat = chatList?.find((c) => c._id === inviteChatId);
      if (chat) setSelectedChatData(chat);
      setIsOpen(true);
    }
  }, [inviteChatId, chatList]);

  useEffect(() => {
    if (token && userId) {
      const getUserDetails = async () => {
        try {
          const response = await getUserApi(userId, token);
          setUserData(response?.user);
        } catch (error) {
          console.log(error.message);
        }
      };
      getUserDetails();
    }
  }, [token, userId]);

console.log("userdata",userData)

  const handleChatSelection = (chat) => {
    if (chat.chatId) {
      // Counsellor chat
      setSelectedCounsellor(chat);
      setSelectedChatId(chat.chatId);
      fetchSelectedChat(chat.chatId); // Fetch messages using chatId
      setSelectedChatData(null); // Clear connection chat data
    } else {
      // Connection chat
      setSelectedChatId(chat._id);
      setSelectedChatData(chat);
      setSelectedCounsellor(null); // Clear counsellor selection
      fetchSelectedChat(chat._id);
    }
    setIsOpen(true);
  };

  const handleAccordionChange = (_, expanded) => {
    setIsOpen(expanded);
    if (!expanded) {
      setSelectedChatId(null);
      setSelectedChatData(null);
      setSelectedChat(null);
      setSelectedCounsellor(null);
    }
  };

  const handleNewMessage = (newMessage) => {
    setSelectedChat((prevChat) => {
      const safePrev = Array.isArray(prevChat) ? prevChat : [];
      const messageExists = safePrev.some((msg) => msg._id === newMessage._id);
      if (messageExists) return safePrev;
      return [...safePrev, newMessage];
    });
  };



  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        right: 16,
        zIndex: 1000,
        width: { xs: "34%", sm: "30%", md: "25%", lg: "21%", xl: "16%" },
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Accordion
        expanded={isOpen}
        onChange={handleAccordionChange}
        sx={{
          borderRadius: 2,
          boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
          overflow: "hidden",
          height: isOpen ? "67vh" : "8vh",
          transition: "height 0.3s ease-in-out",
        }}
      >
        <AccordionSummary
          expandIcon={
            <Stack direction="row" spacing={1} alignItems="center">
              {isOpen ? (
                <ExpandLessIcon sx={{ color: "#000", fontSize: 26 }} />
              ) : (
                <ExpandMoreIcon sx={{ color: "#000", fontSize: "26" }} />
              )}
            </Stack>
          }
          sx={{
            backgroundColor: "#f5f5f5",
            color: "#000",
            borderRadius: isOpen ? "8px 8px 0 0" : "8px",
            padding: "4px 12px",
            minHeight: "8vh",
            "& .MuiAccordionSummary-content": {
              margin: 0,
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            },
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Avatar
              alt="User"
              src={userData?.profileImage || ""}
              sx={{ height: 50, width: 50 }}
            />
            <Typography variant="body2" sx={{ fontWeight: 800, fontFamily: "satoshi", fontSize: "18px" }}>
              Network
            </Typography>
          </Box>
        </AccordionSummary>

        <AccordionDetails
          sx={{
            padding: 0,
            backgroundColor: "#f9f9f9",
            height: "calc(67vh - 8vh)",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              overflowY: "auto",
              backgroundColor: "#fff",
              borderRadius: "0 0 8px 8px",
              padding: 1,
              "&::-webkit-scrollbar": { width: "0px" },
              "&::-webkit-scrollbar-thumb": { backgroundColor: "transparent" },
              scrollbarWidth: "none",
              "-ms-overflow-style": "none",
            }}
          >
            <JobSeekerToJobSeekerChatList
              connectionsChatList={chatList}
              counsellors={counsellors}
              onChatSelect={handleChatSelection}
              isLoading={isLoadingChats}
            />
          </Box>
        </AccordionDetails>
      </Accordion>

      {selectedChat && (
        <Box
          sx={{
            position: "absolute",
            right: "350px",
            width: "450px",
            backgroundColor: "#fff",
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
            height: "600px",
          }}
        >
          {selectedCounsellor ? (
            <JobSeekerCounsellorChatWindow
              selectedChatData={selectedCounsellor} // Pass counsellor data
              chatData={selectedChat}
              onMessageSent={handleNewMessage}
              handleAccordionChange={() => handleAccordionChange(null, false)}
              socket={getSocket()}
              senderImage={userData?.profileImage}
            />
          ) : (
            <JobSeekerToJobSeekerWindow
              selectedChatData={selectedChatData}
              senderImage={userData?.profileImage}
              chatData={selectedChat}
              onMessageSent={handleNewMessage}
              handleAccordionChange={() => handleAccordionChange(null, false)}
              socket={getSocket()}

            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default JobSeekerToJobSeekerSupport;