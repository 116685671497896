import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Box,
  Typography,
} from "@mui/material";
import JobSeekerOtpModel from "../../OTPBox/JobSeekerOtpModel";
import baseURL from "../../../api/baseUrl";

const EmailEdit = ({
  initialEmail,
  handleVerifyOtp,
  handleSendOtp,
  onClose,
}) => {
  const [email, setEmail] = useState(initialEmail || "");
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [otp, setOtp] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(60);
  const [canResend, setCanResend] = useState(false);

  const handleDialogOpen = () => setOpen(true);
  const handleDialogClose = () => {
    setOpen(false);
    setError("");
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleUpdate = () => {
    if (!validateEmail(email)) {
      setError("Please enter a valid email address");
      return;
    }
    handleSendOtp(email);
    setShowOtpModal(true);
    handleDialogClose();
  };

  const handleFieldChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,4}$/.test(value)) {
      setOtp(value);
    }
  };

  const handleOtpSubmit = async (otp) => {
    setLoading(true);
    try {
      await handleVerifyOtp(otp); // Assuming this returns a promise or can be awaited
      setShowOtpModal(false); // Close OTP modal
      setOpen(false); // Close email dialog
      if (onClose) onClose(); // Notify parent to close if needed
    } catch (error) {
      setErrors({ otp: "Invalid OTP or verification failed" });
    } finally {
      setLoading(false);
    }
  };

  const handleResendOtp = async () => {
    const token = localStorage.getItem("authToken")?.replace(/"/g, "");

    setCanResend(false);
    setTimer(60);
    setErrors({});
    try {
      const response = await fetch(`${baseURL}/emailPhoneOtp`, {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });
      const data = await response.json();
      if (!response.ok) {
        setErrors({ otp: data.message || "Failed to resend OTP" });
      }
    } catch (error) {
      console.error("Error resending OTP:", error);
      setErrors({ otp: "Something went wrong. Please try again." });
    }
  };

  useEffect(() => {
    let countdown;
    if (timer > 0) {
      countdown = setInterval(() => setTimer((prev) => prev - 1), 1000);
    } else {
      setCanResend(true);
    }
    return () => clearInterval(countdown);
  }, [timer]);

  return (
    <div>
      <Button
        variant="contained"
        onClick={handleDialogOpen}
        sx={{
          backgroundColor: "black",
          color: "white",
          borderRadius: "0.7rem",
          textTransform: "none",
          fontFamily: "IBM Plex Sans",
          fontWeight: "bold",
          padding: "8px 16px",
          "&:hover": { backgroundColor: "#333" },
        }}
      >
        Edit
      </Button>

      <Dialog
        open={open}
        onClose={handleDialogClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="edit-email-dialog"
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "15px",
            fontFamily: "IBM Plex Sans",
          },
          "& .MuiDialogContent-root": {
            "&::-webkit-scrollbar": { display: "none" },
            scrollbarWidth: "none",
          },
        }}
      >
        <DialogTitle sx={{ fontFamily: "IBM Plex Sans", fontWeight: "bold" }}>
          Update Email
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mb: 2 }}>
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", mb: 1, fontFamily: "IBM Plex Sans" }}
            >
              Email ID
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setError("");
              }}
              error={!!error}
              helperText={error}
              InputProps={{
                style: { fontFamily: "IBM Plex Sans", fontSize: "0.875rem" },
              }}
              sx={{ "& .MuiOutlinedInput-root": { borderRadius: "8px" } }}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: "16px", justifyContent: "flex-end" }}>
          <Button
            onClick={handleDialogClose}
            sx={{
              borderRadius: "0.7rem",
              textTransform: "none",
              fontWeight: "bold",
              fontFamily: "IBM Plex Sans",
              backgroundColor: "black",
              color: "white",
              padding: "8px 16px",
              "&:hover": { backgroundColor: "#333" },
            }}
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleUpdate}
            sx={{
              borderRadius: "10px",
              textTransform: "none",
              fontFamily: "IBM Plex Sans",
              fontWeight: "bold",
              backgroundColor: "#423bfa",
              "&:hover": { backgroundColor: "#362fd9" },
            }}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>

      {showOtpModal && (
        <JobSeekerOtpModel
          email={email}
          open={showOtpModal}
          handleClose={() => setShowOtpModal(false)}
          handleBack={() => setShowOtpModal(false)}
          handleFieldChange={handleFieldChange}
          otp={otp}
          handleResendOtp={handleResendOtp}
          handleOtpSubmit={handleOtpSubmit}
          canResend={canResend}
          timer={timer}
          errors={errors}
          loading={loading}
        />
      )}
    </div>
  );
};

export default EmailEdit;
