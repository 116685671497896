import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import VacanziLogo from "../../assets/icons/logos/vacanzi.png";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import { Box, styled } from "@mui/system";
import { Menu, MenuItem } from "@mui/material"; // Import Menu and MenuItem
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";


// Styled components
const ContactSection = styled(Box)(({ theme }) => ({
  padding: "16px 20px",
  "&:not(:last-child)": {
    borderBottom: "1px solid #eaeaea",
  },
}));

const ContactItem = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "8px",
  marginTop: 8,
  color: "#666",
  "&:hover": {
    color: "#423BFA",
  },
  "& svg": {
    fontSize: 18,
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  color: "#333",
}));

function NonRegisteredAppBar({ phone }) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation()

  // Create light theme
  const lightTheme = createTheme({
    palette: {
      mode: "light",
      background: {
        default: "#f5faf5",
        paper: "#f5faf5",
      },
      primary: {
        main: "#000000",
      },
    },
    typography: {
      button: {
        textTransform: "none",
        fontWeight: 500,
      },
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: "#f5faf5",
            boxShadow: "none",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "6px",
            padding: "6px 12px",
          },
        },
      },
    },
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const route = location.pathname;

  const isJobseekerReg = route.includes("jobSeekerRegestrationProfile");
  

  return (
    <ThemeProvider theme={lightTheme}>
      <AppBar position="sticky">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Avatar
              src={VacanziLogo}
              onClick={() => navigate("/")}
              alt="Vacanzi Logo"
              sx={{
                width: 56,
                height: 56,
                cursor: "pointer",
                display: { xs: "none", md: "flex" },
              }}
            />

            <Typography
              onClick={() => navigate("/")}
              variant="h4"
              noWrap
              component="a"
              sx={{
                mr: 1,
                display: { xs: "none", md: "flex" },
                fontFamily: "GlanceSansBold",
                color: "#423BFA",
                cursor: "pointer",
                textDecoration: "none",
              }}
            >
              Vacanzi
            </Typography>
            <Avatar
              src={VacanziLogo}
              alt="Vacanzi Logo"
              sx={{
                width: 46,
                height: 46,
                display: { xs: "block", md: "none" },
              }}
            />

            <Typography
              variant="h6"
              noWrap
              component="a"
              href="#app-bar-with-responsive-menu"
              sx={{
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "GlanceSansBold",
                fontWeight: 700,
                color: "#423BFA",
                textDecoration: "none",
              }}
            >
              Vacanzi
            </Typography>

            {/* Display the phone number with an icon */}
            <Box
              onClick={handleClick}
              sx={{
                ml: "auto",
                display: "flex",
                alignItems: "center",
                gap: 1,
                mr: "100px",
                cursor: "pointer",
                position: "relative",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  color: "#000000",
                  cursor: "pointer",
                }}
              >
                {isJobseekerReg ? "For Jobseeker Enquiry":"For Sales Enquiry"}
              </Typography>
              <PhoneIcon sx={{ color: "#000000", fontSize: "20px" }} />
              <Typography
                onMouseEnter={handleClick}
                variant="body1"
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  color: "#000000",
                  cursor: "pointer",
                }}
              >
              {isJobseekerReg ? " 8904241444":"8904241555"}  
              </Typography>

              {anchorEl ? (
                <KeyboardArrowUpRoundedIcon sx={{ color: "black" }} />
              ) : (
                <KeyboardArrowDownRoundedIcon sx={{ color: "black" }} />
              )}
            </Box>

            {/* Menu Component */}
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              onMouseLeave={handleClose}
              PaperProps={{
                sx: {
                  borderRadius: "12px",
                  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
                  width: 320,
                  backgroundColor: "#fff",
                  padding: "16px", // Added padding
                  position: "relative", // For the triangle
                  "&::before": {
                    // Sharp edge (triangle) pointing upwards
                    content: '""',
                    position: "absolute",
                    top: "-10px", // Adjust based on your design
                    left: "50%",
                    transform: "translateX(-50%)",
                    width: 0,
                    height: 0,
                    borderLeft: "10px solid transparent",
                    borderRight: "10px solid transparent",
                    borderBottom: "10px solid #fff", // Match the background color
                  },
                },
              }}
            >
             

              {/* Parent div wrapping all menu items */}
              <div onMouseLeave={handleClose}>
                {/* Menu Item for Employer Support */}
                <MenuItem onClick={handleClose}>
                  <ContactSection>
                    <Title
                      variant="body1"
                      sx={{ fontFamily: "poppins", fontSize: "14px" }}
                    >
                    {isJobseekerReg ? "For Jobseeker Support" : "For Employer Support"}  
                    </Title>
                    <ContactItem>
                      <EmailIcon />
                      <Typography
                        variant="body2"
                        sx={{ fontFamily: "poppins", fontSize: "14px" }}
                      >
                        jobseekersupport@vacanzi.com
                      </Typography>
                    </ContactItem>
                  </ContactSection>
                </MenuItem>

                {/* Menu Item for Payment Support */}
                <MenuItem onClick={handleClose}>
                  <ContactSection>
                    <Title
                      variant="body1"
                      sx={{ fontFamily: "poppins", fontSize: "14px" }}
                    >
                      For Payment Support
                    </Title>
                    <ContactItem>
                      <EmailIcon />
                      <Typography
                        variant="body2"
                        sx={{ fontFamily: "poppins", fontSize: "14px" }}
                      >
                        paymentsupport@vacanzi.com
                      </Typography>
                    </ContactItem>
                  </ContactSection>
                </MenuItem>

                {/* Menu Item for Tech Support */}
                <MenuItem onClick={handleClose}>
                  <ContactSection>
                    <Title
                      variant="body1"
                      sx={{ fontFamily: "poppins", fontSize: "14px" }}
                    >
                      For Tech Support
                    </Title>
                    <ContactItem>
                      <EmailIcon />
                      <Typography
                        variant="body2"
                        sx={{ fontFamily: "poppins", fontSize: "14px" }}
                      >
                        techsupport@vacanzi.com
                      </Typography>
                    </ContactItem>
                  </ContactSection>
                </MenuItem>
              </div>
            </Menu>
          </Toolbar>
        </Container>
      </AppBar>
    </ThemeProvider>
  );
}

export default NonRegisteredAppBar;
