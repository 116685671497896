import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function ConfirmationDialogBox({ open, onClose,cancelPlan}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
    >
      <DialogTitle id="confirmation-dialog-title" sx={{fontFamily:"poppins"}}>
        Confirm Subscription
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="confirmation-dialog-description" sx={{fontFamily:'poppins'}}>
          Would you like to continue the plan subscription?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancelPlan} color="secondary" sx={{backgroundColor:"#000",fontFamily:"poppins",textTransform:"none",borderRadius:"0.7rem"}} variant='contained' >
          Cancel
        </Button>
        <Button onClick={onClose} color="primary" autoFocus sx={{backgroundColor:"#000",fontFamily:"poppins",textTransform:"none",borderRadius:"0.7rem"}} variant='contained' >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialogBox;