import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import EmployerLoginSignUp from "../../../components/EmployerComponents/EmployerLoginSignup/EmployerLoginSignUp";
import OTPModal from "../../../components/OTPBox/OTPModal";
import {
  signupEmployer,
  loginEmployer,
  verifySignupOtp,
  verifyOtp,
  resendOtp,
} from "../../../api/employerAuth";
import { Box } from "@mui/system";
import NewNavBar from "../../../components/JobSeekerComponents/NavBar/NewNavbar";
import Footer from "../../../components/JobSeekerComponents/Footer/Footer";
import PopupSuccess from "../../../utils/popup/PopupSuccess";
import ReleaseJobPostModel from "../SubscriptionStatus/ReleaseJobPostModel";

const initialErrors = {
  loginEmail: "",
  loginPassword: "",
  signupName: "",
  signupEmail: "",
  signupPhone: "",
  signupCompany: "",
  otp: "",
  verifyEmail: "",
};

export default function EmployerSignIn() {
  const navigate = useNavigate();
  const [value, setValue] = useState(0); // 0 for login, 1 for signup
  const [formData, setFormData] = useState({
    loginEmail: "",
    loginPassword: "",
    signupName: "",
    signupEmail: "",
    signupPhone: "",
    signupCompany: "",
    otp: "", // Keep this empty initially
    verifyEmail: "",
  });
  const [errors, setErrors] = useState(initialErrors);
  const [timer, setTimer] = useState(30);
  const [canResend, setCanResend] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
    const [successOpen, setSuccessOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorOpen, setErrorOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

  const [loading, setLoading] = useState(false);

  const handleSuccess = useCallback((message) => {
    setSuccessMessage(message);
    setSuccessOpen(true);
    setTimeout(() => {
      setSuccessOpen(false);
      setSuccessMessage("");
    }, 2000);
  }, []);

  const handleError = useCallback((message) => {
    setErrorMessage(message);
    setErrorOpen(true);
    setTimeout(() => {
      setErrorOpen(false);
      setErrorMessage("");
    }, 2000);
  }, []);

  useEffect(() => {
    let countdown;
    if (showOtpModal && timer > 0) {
      countdown = setInterval(() => setTimer((prev) => prev - 1), 1000);
    } else if (timer === 0) {
      setCanResend(true);
    }
    return () => clearInterval(countdown);
  }, [showOtpModal, timer]);

  const validateField = (name, value) => {
    const validationRules = {
      loginEmail: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      signupEmail: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      verifyEmail: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      loginPassword: /.+/,
      signupName: /.+/,
      signupCompany: /.+/,
      signupPhone: /^\d{10}$/,
      loginPhone: /^\d{10}$/,
    };
    const errorMessages = {
      loginEmail: "Invalid email format",
      signupEmail: "Invalid email format",
      verifyEmail: "Invalid email format",
      loginPassword: "Password is required",
      signupName: "Full Name is required",
      signupCompany: "Company Name is required",
      signupPhone: "Phone number must be exactly 10 digits",
      loginPhone: "Phone number must be exactly 10 digits",
    };

    return !value?.match(validationRules[name]) ? errorMessages[name] : "";
  };

  const handleFieldChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: validateField(name, value),
    }));
  };

  const validateForm = (fields) => {
    const newErrors = fields.reduce((acc, field) => {
      acc[field] = validateField(field, formData[field]);
      return acc;
    }, {});
    setErrors(newErrors);
    return !Object.values(newErrors).some((error) => error);
  };

  const handleLoginSubmit = async () => {
    const fields = ["loginEmail", "loginPassword"];
    if (validateForm(fields)) {
      setLoading(true);
      try {
        const loginData = await loginEmployer(
          formData.loginEmail,
          formData.loginPassword
        );
        setFormData((prev) => ({ ...prev, signupPhone: loginData.phone })); // Set phone, not OTP
        setShowOtpModal(true);
        setTimer(30); // Reset timer when modal opens
        setCanResend(false); // Disable resend until timer runs out
      } catch (error) {
        console.error("Login error:", error);
        setErrors((prev) => ({
          ...prev,
          loginEmail: error.message,
          loginPassword: error.message,
        }));
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSignUpSubmit = async () => {
    const fields = ["signupName", "signupEmail", "signupPhone"];
    if (validateForm(fields)) {
      setLoading(true);
      try {
        await signupEmployer(
          formData.signupName,
          formData.signupEmail,
          formData.signupPhone
        );
        // Do not set otp from response here
        setShowOtpModal(true);
        setTimer(30); // Reset timer when modal opens
        setCanResend(false); // Disable resend until timer runs out
      } catch (error) {
        console.error("Signup error:", error);
        setErrors((prev) => ({
          ...prev,
          signupEmail: error.message || "Signup failed. Please try again.",
          signupPhone: error.message || "Signup failed. Please try again.",
        }));
      } finally {
        setLoading(false);
      }
    }
  };

  const handleOtpSubmit = async () => {
    if (formData.otp.length === 4) {
      setLoading(true);
      try {
        if (value === 0) {
          const otpData = await verifyOtp(formData.loginEmail, formData.otp);
          const { organizationId, role, token } = otpData;
          const recruiterId = otpData.recruiter._id;
          localStorage.setItem("recruiterId", recruiterId);
          localStorage.setItem("organizationId", organizationId);
          localStorage.setItem("role", role);
          localStorage.setItem("token", token);
          if (otpData?.recruiter?.role !== "recruiter_admin") {
            navigate("/search-candidates", { replace: true });
          } else if (otpData?.recruiter?.orgCreated) {
            const redirectUrl = localStorage.getItem("redirectUrl");
            navigate(redirectUrl || "/search-candidates", { replace: true });
          } else {
            navigate("/employerRegistration", {
              state: {
                signupEmail: otpData.recruiter.rcEmail,
                signupName: formData.recruiter?.rcName,
                signupPhone: formData.recruiter?.rcPhone,
              },
            });
          }
        } else {
          const otpData = await verifySignupOtp(
            formData.signupPhone,
            formData.otp
          );
          const { role, token } = otpData;
          localStorage.setItem("role", role);
          localStorage.setItem("token", token);
          navigate("/employerRegistration", {
            state: {
              signupEmail: formData.signupEmail,
              signupName: formData.signupName,
              signupPhone: formData.signupPhone,
            },
          });
        }
      } catch (error) {
        console.error("OTP verification error:", error);
        setErrors((prev) => ({ ...prev, otp: "Invalid OTP" }));
      } finally {
        setLoading(false);
      }
    } else {
      setErrors((prev) => ({ ...prev, otp: "OTP must be 4 digits" }));
    }
  };

  const handleResendOtp = async () => {
    setLoading(true);
    try {
      const identifier =
        value === 0 ? formData.loginEmail : formData.signupEmail;
      await resendOtp(identifier);
      setCanResend(false);
      setTimer(30);
      setErrors((prev) => ({ ...prev, otp: "" })); // Clear any previous OTP errors
      setFormData((prev) => ({ ...prev, otp: "" })); // Reset OTP field
    } catch (error) {
      console.error("Resend OTP error:", error);
      setErrors((prev) => ({
        ...prev,
        otp: error.message || "Failed to resend OTP",
      }));
    } finally {
      setLoading(false);
    }
  };

  console.log(formData);

  return (
    <>
      <NewNavBar />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: 0,
          padding: 0,
          width: "100%",
          maxWidth: "100vw",
          overflowX: "hidden",
          position: "relative",
        }}
      >
        <EmployerLoginSignUp
          handleFieldChange={handleFieldChange}
          errors={errors}
          handleSuccess={handleSuccess}
          handleError={handleError}
          setErrors={setErrors}
          value={value}
          loginEmail={formData.loginEmail}
          loginPassword={formData.loginPassword}
          handleLoginSubmit={handleLoginSubmit}
          handleSignupSubmit={handleSignUpSubmit}
          setValue={setValue}
          signupEmail={formData.signupEmail}
          signupName={formData.signupName}
          signupCompany={formData.signupCompany}
          signupPhone={formData.signupPhone}
          loading={loading}
          setLoading={setLoading}
        />

        {showOtpModal && (
          <OTPModal
            phone={formData.signupPhone || formData.loginPhone}
            email={formData.signupEmail || formData.loginEmail}
            open={showOtpModal}
            handleClose={() => setShowOtpModal(false)}
            handleBack={() => setShowOtpModal(false)}
            handleFieldChange={handleFieldChange}
            handleResendOtp={handleResendOtp}
            canResend={canResend}
            timer={timer}
            errors={errors}
            handleOtpSubmit={handleOtpSubmit}
            loading={loading}
            otp={formData.otp}
            formData={formData}
          />
        )}
      </Box>
      <Footer />
      <PopupSuccess
        modelOpen={successOpen}
        onClose={() => setSuccessOpen(false)}
        message={successMessage}
      />
      <ReleaseJobPostModel
        modelOpen={errorOpen}
        onClose={() => setErrorOpen(false)}
        message={errorMessage}
      />
    </>
  );
}
